import { format } from 'date-fns';

export const safeFormatDate = (d: string) => {
  return d ? format(new Date(d), 'PPp') : '';
};

export const safeFormatDateString = (d: string) => {
  try {
    return format(new Date(d), 'yyyy-MM-dd');
  } catch (error) {
    return d;
  }
};
