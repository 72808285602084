export interface ICustomGroupFormValue {
  name: string;
  sources: string[];
}

export function validate(values: ICustomGroupFormValue) {
  const errors: { [key in keyof ICustomGroupFormValue]?: string } = {};
  if (!values.name) {
    errors.name = 'Group Name cannot be empty';
  } else if (values.name.length > 20) {
    errors.name = 'Group Name cannot be more than 20 characters';
  }
  if (values.sources.length === 0) {
    errors.sources = 'You must select at least one content source';
  }

  return errors;
}
