import * as Types from '@amgen/core';

import { IDataAssetForSpotlightFragment } from './search';
import { gql } from '@apollo/client';
import { DataAssetForSpotlightFragmentDoc } from './search';
import * as Apollo from '@apollo/client';
export type ISearchWithOpenNlpOrBertQueryVariables = Types.Exact<{
  applicationName?: Types.Maybe<Types.ApplicationName>;
  query: Types.Scalars['String'];
  filters?: Types.Maybe<Array<Types.Maybe<Types.Scalars['String']>> | Types.Maybe<Types.Scalars['String']>>;
  start?: Types.Maybe<Types.Scalars['Int']>;
  page?: Types.Maybe<Types.Scalars['Int']>;
  count?: Types.Maybe<Types.Scalars['Int']>;
  sort?: Types.Maybe<Types.SortField>;
  order?: Types.Maybe<Types.Direction>;
  addPersonalizationDetails?: Types.Maybe<Types.Scalars['Boolean']>;
  requestHandler?: Types.Maybe<Types.SolrRequestHandler>;
  solrHandler?: Types.Maybe<Types.Scalars['String']>;
  noShortContent?: Types.Maybe<Types.Scalars['Boolean']>;
  noSpotlight?: Types.Maybe<Types.Scalars['Boolean']>;
  includeDocs: Types.Scalars['Boolean'];
  cosine?: Types.Maybe<Types.Scalars['Boolean']>;
  searchType?: Types.Maybe<Types.Scalars['String']>;
}>;

export type ISearchWithOpenNlpOrBertQuery = {
  searchWithOpenNLPOrBERT?: Types.Maybe<
    Types.MakeOptional<Pick<Types.IDataAssetCollection, 'QTime'>, 'QTime'> & {
      docs?: Types.Maybe<
        Array<
          Types.Maybe<{
            dataAsset?: Types.Maybe<IDataAssetForSpotlightFragment>;
            personalizationDetails?: Types.Maybe<{
              bookmarkDetails?: Types.Maybe<Pick<Types.IBookmarkDetails, 'isBookmarked' | 'bookmarkId'>>;
            }>;
          }>
        >
      >;
    }
  >;
};

export const SearchWithOpenNlpOrBertDocument = gql`
  query SearchWithOpenNLPOrBERT(
    $applicationName: ApplicationName = EEA
    $query: String!
    $filters: [String]
    $start: Int = 0
    $page: Int
    $count: Int
    $sort: SortField
    $order: Direction
    $addPersonalizationDetails: Boolean
    $requestHandler: SolrRequestHandler
    $solrHandler: String = "/search-opennlp-select"
    $noShortContent: Boolean = false
    $noSpotlight: Boolean = true
    $includeDocs: Boolean!
    $cosine: Boolean = true
    $searchType: String
  ) {
    searchWithOpenNLPOrBERT(
      applicationName: $applicationName
      query: $query
      filters: $filters
      start: $start
      page: $page
      count: $count
      sort: $sort
      order: $order
      addPersonalizationDetails: $addPersonalizationDetails
      requestHandler: $requestHandler
      solrHandler: $solrHandler
      noShortContent: $noShortContent
      noSpotlight: $noSpotlight
      cosine: $cosine
      searchType: $searchType
    ) {
      QTime @include(if: $includeDocs)
      docs @include(if: $includeDocs) {
        dataAsset {
          ...DataAssetForSpotlight
        }
        personalizationDetails {
          bookmarkDetails {
            isBookmarked
            bookmarkId
          }
        }
      }
    }
  }
  ${DataAssetForSpotlightFragmentDoc}
`;
export function useSearchWithOpenNlpOrBertQuery(
  baseOptions: Apollo.QueryHookOptions<ISearchWithOpenNlpOrBertQuery, ISearchWithOpenNlpOrBertQueryVariables>
) {
  return Apollo.useQuery<ISearchWithOpenNlpOrBertQuery, ISearchWithOpenNlpOrBertQueryVariables>(
    SearchWithOpenNlpOrBertDocument,
    baseOptions
  );
}
export function useSearchWithOpenNlpOrBertLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ISearchWithOpenNlpOrBertQuery, ISearchWithOpenNlpOrBertQueryVariables>
) {
  return Apollo.useLazyQuery<ISearchWithOpenNlpOrBertQuery, ISearchWithOpenNlpOrBertQueryVariables>(
    SearchWithOpenNlpOrBertDocument,
    baseOptions
  );
}
export type SearchWithOpenNlpOrBertQueryHookResult = ReturnType<typeof useSearchWithOpenNlpOrBertQuery>;
export type SearchWithOpenNlpOrBertLazyQueryHookResult = ReturnType<typeof useSearchWithOpenNlpOrBertLazyQuery>;
export type SearchWithOpenNlpOrBertQueryResult = Apollo.QueryResult<
  ISearchWithOpenNlpOrBertQuery,
  ISearchWithOpenNlpOrBertQueryVariables
>;
