import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { DateRange } from 'react-date-range';

import './date-slider.scss';

import { DateFilterContext } from '../date-filter/date-filter-provider/date-filter-context';

/* eslint-disable-next-line */
export interface DateSliderProps {}

export const DateSlider = (props: DateSliderProps) => {
  const { handleApplyFilters, handleResetFilter, category } = useContext(DateFilterContext);
  const [dateRangeValue, setDateRangeValue] = useState([
    {
      startDate: new Date('1960'),
      endDate: category === 'contract_expiry_date' ? new Date('2060') : new Date(),
      key: 'selection',
    },
  ]);

  const extractDateValue = dateString => {
    // Parse the date string into a Date object
    const date = new Date(dateString);

    // Extract the year, month, and day from the Date object
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1 and pad with zero
    const day = String(date.getDate()).padStart(2, '0'); // Pad day with zero if needed

    // Format the date as yyyy-MM-d
    return `${year}-${month}-${day}`;
  };

  const handleDateChange = item => {
    setDateRangeValue([item.selection]);
  };

  return (
    <>
      <DateRange
        // color={props.active ? '#0063c3' : '#87a3bf'}
        maxDate={category === 'contract_expiry_date' ? new Date('2060') : new Date()}
        minDate={new Date('1960')}
        editableDateInputs={false}
        onChange={item => handleDateChange(item)}
        moveRangeOnFirstSelection={false}
        retainEndDateOnFirstSelection={true}
        ranges={dateRangeValue}
        className="w-100"
      />
      <div className="d-flex justify-content-end">
        <Button
          className="apply-button mx-2"
          onClick={() =>
            handleApplyFilters([
              extractDateValue(dateRangeValue[0].startDate),
              extractDateValue(dateRangeValue[0].endDate),
            ])
          }
        >
          Apply
        </Button>
        <Button className="apply-button mx-2" onClick={() => handleResetFilter()}>
          Reset
        </Button>
      </div>
    </>
  );
};

export default DateSlider;
