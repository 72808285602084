import { IProduct } from '@amgen/core';
import { useMemo } from 'react';

import { useAPIConfig } from '../context';
import { usePost } from './http';

interface IKnowledgePanelPayload {
  payload: {
    token: string;
  };
}

type IKnowledgePanelData = IProduct;

export function useKnowledgePanelQuery(query: string) {
  const { nlpUrl } = useAPIConfig();
  const payload = useMemo(() => ({ payload: { token: query } }), [query]);

  if (sessionStorage.getItem('employeeType') === 'FTE') {
    const { data, loading, error } = usePost<IKnowledgePanelData, IKnowledgePanelPayload>(
      `${nlpUrl}/knowledge-graph-service`,
      payload
    );

    // Note: This is because if no results are available, the response is an empty object.
    // having null instead of `{}` makes the downstream code cleaner.
    return { loading, error, product: Object.keys(data ?? {}).length === 0 ? null : data };
  } else {
    return { loading: false, error: '', product: null };
  }
}
