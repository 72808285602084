import { IGD_ACTIONS, MatomoContext, SEARCH_APP_FEATURE_LIST } from '@amgen/shared';
import { faChevronLeft, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState } from 'react';
import { Button, Col, Nav, Spinner } from 'react-bootstrap';
import { NavLink, Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

import './governance-dashboard-page.scss';

import { NewRequestModal } from '../components';
import { TABS } from '../config';
import AdminPage from './admin-page/admin-page';
import ExistingSitesPage from './my-existing-sites-page/my-existing-sites-page';
import MyRequestsPage from './my-requests-page/my-requests-page';

/* eslint-disable-next-line */
export interface GovernanceDashboardPageProps {}

export const GovernanceDashboardPage = (props: GovernanceDashboardPageProps) => {
  const { path, url } = useRouteMatch();
  const { goBack, push } = useHistory();
  const [show, setShow] = useState(false);
  let authTabs = [''];
  if (sessionStorage.authTabs) {
    authTabs = JSON.parse(sessionStorage.authTabs)
  }
  const loading = JSON.parse(sessionStorage.loading)

  const { trackApplicationFeature } = useContext(MatomoContext);

  const authorizedTabs = TABS.filter(x => authTabs?.includes(x.id));
  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh', width: '100vw' }}>
        <Spinner animation="border" className="p-4 text-primary" />
      </div>
    );
  }
  return (
    <Col xs="12" className="mt-3">
      <div className="d-flex justify-content-between">
        <Button variant="link" className="h2 ml-1 my-auto" onClick={goBack}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <span className="pl-2 d-none d-sm-inline">Back</span>
        </Button>

        <Nav className="ag-navbar align-items-center justify-content-end" variant="tabs">
          <Nav.Item
            className="text-center"
            key="My Request"
            onClick={() => {
              setShow(true);
              trackApplicationFeature(SEARCH_APP_FEATURE_LIST.IGD, IGD_ACTIONS.ADD_REQ_FROM_TAB);
            }}
          >
            <Nav.Link className="nav-link h2 mb-0 align-items-center d-flex justify-content-center">
              <FontAwesomeIcon icon={faPlusSquare} />
              <span className="pl-2 d-none d-md-block">New Request</span>
            </Nav.Link>
          </Nav.Item>
          {authorizedTabs.map(tab => (
            <Nav.Item className="text-center " key={tab.title}>
              <NavLink
                exact
                to={`${url}${tab.pathname}`}
                className="nav-link h2 mb-0 align-items-center d-flex justify-content-center"
                activeClassName="active"
              >
                <FontAwesomeIcon icon={tab.icon} />
                <span className="pl-2 d-none d-md-block">{tab.title}</span>
              </NavLink>
            </Nav.Item>
          ))}
        </Nav>
      </div>

      <NewRequestModal show={show} onHide={() => setShow(false)} onSuccess={() => push('data-requests/my-requests')} />

      <Col xs="12" className="bg-white shadow-sm border border-top-0 mb-3 pb-3 px-0">
        <Switch>
          <Route path={`${path}/my-requests`}>
            <MyRequestsPage update={!show} />
          </Route>
          <Route path={`${path}/existing-sites`} component={ExistingSitesPage} />
          {authorizedTabs.find(x => x.id === 'IGD Admin') ? (
            <Route path={`${path}/admin`} component={AdminPage} />
          ) : null}
          <Redirect to={`${url}/my-requests`} />
        </Switch>
      </Col>
    </Col>
  );
};

export default GovernanceDashboardPage;
