import { PageType } from '@amgen/core';
import React from 'react';
import { Modal } from 'react-bootstrap';

import './export-requests-popup.scss';

import ExportRequestsForm from '../export-requests-form/export-requests-form';
import SuggestedDestinationsExportForm from '../suggested-destinations-export-form/suggested-destinations-export-form';

export interface ExportRequestsPopupProps {
  show: boolean;
  onHide: () => void;
  pageType: PageType;
  page?: string;
}

export const ExportRequestsPopup = (props: ExportRequestsPopupProps) => {
  return (
    <Modal centered show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title as="h2">Export Requests</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props?.page === 'suggested' ? (
          <SuggestedDestinationsExportForm onCancel={props.onHide} />
        ) : (
          <ExportRequestsForm onCancel={props.onHide} pageType={props.pageType} page={props.page} />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ExportRequestsPopup;
