import React from 'react';
import { Modal } from 'react-bootstrap';

export interface FullContentPopupProps {
  content: string;
  show: boolean;
  onHide: () => void;
}

export const FullContentPopup = (props: FullContentPopupProps) => {
  return (
    <Modal show={props.show} onHide={props.onHide} centered scrollable size="lg">
      <Modal.Header closeButton className="border-0 mx-2">
        <Modal.Title as="h2" className="font-weight-bold">
          Content
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="mb-5 mx-2 clammped-content-body">
        <ul className="p-0">
          <li className="list-unstyled">
            <pre>
              <p
                dangerouslySetInnerHTML={{
                  __html: props.content,
                }}
              />
            </pre>
          </li>
        </ul>
      </Modal.Body>
    </Modal>
  );
};

export default FullContentPopup;
