import { amgNumFormatter, isAmgNum } from '@amgen/utils';
import React from 'react';

import './knowledge-panel-title.scss';

export interface KnowledgePanelTitleProps {
  title: string | undefined;
}

export const KnowledgePanelTitle: React.FC<KnowledgePanelTitleProps> = ({ title }) => {
  return title ? (
    <div className="col-6 p-0">
      <h1
        title="Wikipedia-like panel capturing Amgen products information"
        className="d-inline-block pl-0 mr-2 text-capitalize knowledge-panel-title mt-2"
        style={{ fontWeight: 600 }}
      >
        {isAmgNum(title) ? amgNumFormatter(title) : title}
      </h1>
    </div>
  ) : null;
};

export default KnowledgePanelTitle;
