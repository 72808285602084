import React, { useState } from 'react';
import { Button, Col, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import './setting-action-footer.scss';

export interface SettingActionFooterProps {
  onSave: () => void;
  isLoading?: boolean;
  isDisabledMessage?: string;
}

export const SettingActionFooter = (props: SettingActionFooterProps) => {
  const { goBack } = useHistory();
  const [getUrl, setgetUrl] = useState(localStorage.getItem('current_new_url'));
  return (
    <Col xs="12" className="mt-2 d-flex  justify-content-end mb-3 button-stlye ">
      <Button
        className="px-5 "
        onClick={props.onSave}
        disabled={props.isLoading || !!props.isDisabledMessage}
        title={props.isDisabledMessage}
      >
        {props.isLoading ? <Spinner animation="border" size="sm" /> : 'Save'}
      </Button>
      {/* <a href={getUrl ? getUrl : '/home'}> */}
      <Button variant="outline-primary" className="px-md-5 ml-3" onClick={goBack}>
        Cancel
      </Button>
      {/* </a> */}
    </Col>
  );
};

export default SettingActionFooter;
