import { useCallback, useEffect, useState } from 'react';

import { useAPIConfig } from '../context';

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
export function usePost<D, P>(url: string, payload: P) {
  const appName = sessionStorage.getItem('appName') === 'OpsKM' ? 'OPsKM' : 'EEA';
  //[todo] remove user based headers to usePost fucntion call params and move knowledge-panel,qna-feedbacks to respective feature lib
  // const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>(null);
  const [data, setData] = useState<D | null>(null);

  useEffect(() => {
    setLoading(true);

    const headers = {
      user_name: sessionStorage.getItem('username') || 'user',
      cost_center: sessionStorage.getItem('costcenter') || 'costcenter',
      application_name: `${appName.toLowerCase()}-search`,
    };

    post<D>(url, payload, headers)
      .then(setData)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [payload]);

  return { loading, error, data };
}

export function usePostCallback<D, P>() {
  const { appName } = useAPIConfig();
  const user = JSON.parse(sessionStorage.user);
  const postFn = useCallback(
    (url: string, payload: P) => {
      const headers = {
        user_name: user.fullName,
        cost_center: user.costCenter,
        application_name: `${appName.toLowerCase()}-search`,
      };

      post<D>(url, payload, headers)
        .then(() => console.log('--post successful--'))
        .catch(() => console.log('--post failed--'));
    },
    [appName, user]
  );

  return postFn;
}

async function post<T>(url: string, payload: unknown, requestHeaders: Record<string, string>) {
  const headers = new Headers({
    'Content-Type': 'application/json',
    ...requestHeaders,
  });

  const response = await fetch(url, {
    method: 'POST',
    headers,
    redirect: 'follow',
    body: JSON.stringify(payload),
  });

  return (await response.json()) as T;
}
