import { returnADeepClone } from '@amgen/utils';

//convert items of csv cell from string, array to excel readable strings
const properString = (item: string | []) => {
  switch (typeof item) {
    case 'string':
      return item.replace(/,/g, '  ').replace(/(\r\n|\n|\r)/gm, '');
    case 'object':
      return item
        ? item instanceof Array
          ? item
              .join('    ##        ') // SPACES DELIBERATELY GIVEN
              .replace(/,/g, ' ') // replaces ' ,' in each string of array (delimiter for csv is ',')
              .replace(/(\r\n|\n|\r)/gm, '') // replaces ' \N\R' in each string of array
          : item
        : 'NA';
    default:
      return 'NA-not handled';
  }
};

/**
 * object to csv convertor
 * @param objArray an array of objects into csv string
 */
export const convertToCSV = (objArray: object | [] | string) => {
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  let str = '';
  for (let i = 0; i < array.length; i++) {
    let line = '';
    for (const index in array[i]) {
      if (line !== '') line += ',';

      line += properString(array[i][index]);
    }

    str += line + '\r\n';
  }
  return str;
};

/**
 * ensure no. and shape of keys are similar as of first object in the while array
 * export data to .csv from array of objects (create link <a> and delete)
 * @param items
 * @param fileTitle
 */
export const exportCSVFile = (items: object[] | [], fileTitle: string) =>
  new Promise<void>((resolve, reject) => {
    try {
      // defaultly takes keys from first object as headers
      const headers = Object.keys(returnADeepClone(items[0] ? items[0] : [])) as never;
      items.unshift(headers);

      // Convert Object to JSON
      const jsonObject = JSON.stringify(items);

      const csv = convertToCSV(jsonObject);

      const exportedFilenmae = fileTitle + '.csv' || 'export.csv';

      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      if ((navigator as any)?.msSaveBlob) {
        // IE 10+
        (navigator as any)?.msSaveBlob(blob, exportedFilenmae);
      } else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', exportedFilenmae);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
      resolve();
    } catch (err) {
      console.error(err);
      reject('Something went wrong');
    }
  });
