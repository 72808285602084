import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';

import './navigate-icon.scss';

import { getCurrentQuarterIndex } from '../../hooks/feature-utils';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface NavigateProps {
  position: number;
  setPosition: () => void;
}

export const NavigateNext: React.FC<NavigateProps> = props => {
  return props.position < getCurrentQuarterIndex() ? (
    <Button
      variant="link"
      className="position-absolute bg-white shadow roadmap-navigate-btn right-roadmap-navigate rounded-circle navigation"
      onClick={() => props.setPosition()}
    >
      <FontAwesomeIcon icon={faChevronRight} title="click to scroll right" />
    </Button>
  ) : null;
};

export const NavigatePrev: React.FC<NavigateProps> = props => {
  return props.position > 0 ? (
    <Button
      variant="link"
      className="position-absolute bg-white shadow roadmap-navigate-btn left-roadmap-navigate rounded-circle navigation"
      onClick={() => props.setPosition()}
    >
      <FontAwesomeIcon icon={faChevronLeft} title="click to scroll left" />
    </Button>
  ) : null;
};

export default { NavigateNext, NavigatePrev };
