import { IInteractionLineChartSeries, MagicLineChartType } from '@amgen/shared';
import { EChartOption } from 'echarts';
import ReactEcharts from 'echarts-for-react';
import React, { useMemo } from 'react';

import './magic-line-chart.scss';

export interface MagicLineChartProps {
  onChartClick: (...args: any) => any;
  onChartLegendSelectChanged: (...args: any) => any;
  lineChartData: IInteractionLineChartSeries;
  chartName: string;
  legendData: string[];
  onChartReady?: (...args: any) => any;
  onChangeChartType: (chartType: MagicLineChartType) => void;
  chartType: MagicLineChartType;
  series: any[];
  onDataZoom?: (start: number, end: number) => void;
}

export const MagicLineChart: React.FC<MagicLineChartProps> = props => {
  const onEvents: Record<string, (...args: any) => any> = useMemo(
    () => ({
      click: props.onChartClick,
      legendselectchanged: props.onChartLegendSelectChanged,
      magictypechanged: e => props.onChangeChartType(e.currentType),
      dataZoom: e => {
        if (props.onDataZoom) props.onDataZoom(e.batch[0].startValue, e.batch[0].endValue);
      },
      selectDataRange: e => console.log(e),
    }),
    [props]
  );

  const chartConfig: EChartOption<EChartOption.Series> = useMemo(
    () => ({
      tooltip: {
        trigger: 'axis',
        backgroundColor: '#ffffff',
        borderWidth: 1,
        textStyle: {
          color: '#000000',
        },
      },
      title: {
        text: props.chartName,
        left: 'center',
        itemGap: 30,
      },
      legend: {
        data: props.legendData,
        bottom: 0,
      },
      xAxis: {
        type: 'category',
        data: props.lineChartData.xAxisData,
      },
      yAxis: {
        type: 'value',
        name: 'Document Count',
        nameLocation: 'middle',
        nameGap: 55,
        nameTextStyle: {
          fontWeight: 'bold',
        },
      },
      toolbox: {
        show: true,
        orient: 'vertical',
        top: 'center',
        itemSize: 20,
        right: '1%',
        itemGap: 20,
        feature: {
          saveAsImage: { show: true, title: 'download', name: props.chartName, pixelRatio: 2 },
          magicType: {
            show: true,
            type: ['line', 'bar'],
            title: { line: 'Line', bar: 'Bar' },
          },
          dataZoom: {
            yAxisIndex: 'none',
            title: {
              zoom: 'area zooming',
              back: 'restore zoom',
            },
          },
        },
      },
      series: props.series,
    }),
    [props.chartName, props.chartType, props.legendData, props.lineChartData.xAxisData]
  );

  return (
    <div className="p-4">
      {props.lineChartData.xAxisData.length < 1 ? (
        <div className="p-2 text-center">
          <h2>{props.chartName}</h2>
          <p>No data available</p>
        </div>
      ) : (
        <ReactEcharts
          option={chartConfig}
          notMerge
          lazyUpdate
          onChartReady={props.onChartReady}
          onEvents={onEvents}
          style={{ height: '400px', width: '100%' }}
        />
      )}
    </div>
  );
};

export default MagicLineChart;
