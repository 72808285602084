import { faExternalLinkAlt, faFilter, faStar } from '@fortawesome/free-solid-svg-icons';

export const TABS = [
  { pathname: '', title: 'Favorite Data Source(s)', icon: faStar, id: '' },
  { pathname: '/filter-settings', title: 'Filter Settings', icon: faFilter, id: '' },
];

export const TABSINTEGRATION = [
  { pathname: '', title: 'Filter Settings', icon: faFilter, id: '' },
  // { pathname: '/export-settings', title: 'Export Settings', icon: faExternalLinkAlt, id: '' },
];

export const TABSCONTRACT = [
  { pathname: '', title: 'Favorite Data Source(s)', icon: faStar, id: '' },
  { pathname: '/filter-settings', title: 'Filter Settings', icon: faFilter, id: '' },
  { pathname: '/export-settings', title: 'Export Settings', icon: faExternalLinkAlt, id: '' },
];
