import { useEnvironment } from '@amgen/core';
import { IPageTab, Navbar } from '@amgen/feature-navigation';
import { faBookmark } from '@fortawesome/free-regular-svg-icons';
import React from 'react';

import './eea-navbar.scss';

/* eslint-disable-next-line */
export interface EEANavbarProps {}

export const EEANavbar: React.FC<EEANavbarProps> = props => {
  const { clusterSearch } = useEnvironment();

  const tabs: IPageTab[] = [
    //{ pathname: '/', title: 'Results', icon: faHdd, isMobileOnly: false, hasExport: true },

    // { pathname: '/dashboard', title: 'Interactive Filters', icon: faChartPie, isMobileOnly: false, hasExport: false },

    // TODO: It was requested that cluser search page to be toggled between environments
    // This condition should be removed when cluster search page is finalized
    // ...(clusterSearch
    //   ? [{ pathname: '/topics', title: 'Topics', icon: faLayerGroup, isMobileOnly: false, hasExport: false }]
    //   : []),
    // { pathname: '/reports', title: 'Waters Reports', icon: faFile, isMobileOnly: false, hasExport: true },
    { pathname: '/bookmarks', title: 'Bookmarks', icon: faBookmark, isMobileOnly: true, hasExport: false },
  ];

  return <Navbar {...props} tabs={tabs} />;
};

export default EEANavbar;
