import * as Types from '@amgen/core';

import { IGenericResponseFragment } from './generic-response';
import { gql } from '@apollo/client';
import { GenericResponseFragmentDoc } from './generic-response';
import * as Apollo from '@apollo/client';
export type IRoadmapFeatureCommentFragment = Pick<Types.IRoadmapFeatureComment, 'id' | 'timestamp' | 'comment'> & {
  user?: Types.Maybe<Pick<Types.IUser, 'username' | 'firstName' | 'lastName' | 'costCenter'>>;
};

export type IGetRoadmapCommentsQueryVariables = Types.Exact<{
  start?: Types.Maybe<Types.Scalars['Int']>;
  parentId: Types.Scalars['String'];
  page?: Types.Maybe<Types.Scalars['Int']>;
  count?: Types.Maybe<Types.Scalars['Int']>;
}>;

export type IGetRoadmapCommentsQuery = {
  getRoadmapComments?: Types.Maybe<
    Pick<Types.IRoadmapCommentResponse, 'resultCount'> & {
      comments?: Types.Maybe<Array<Types.Maybe<IRoadmapFeatureCommentFragment>>>;
    }
  >;
};

export type IAddRoadmapFeatureCommentMutationVariables = Types.Exact<{
  id?: Types.Maybe<Types.Scalars['String']>;
  parentId: Types.Scalars['String'];
  comment: Types.Scalars['String'];
  startDate?: Types.Maybe<Types.Scalars['String']>;
  endDate?: Types.Maybe<Types.Scalars['String']>;
  themeName?: Types.Maybe<Types.Scalars['String']>;
  featureName?: Types.Maybe<Types.Scalars['String']>;
}>;

export type IAddRoadmapFeatureCommentMutation = { addRoadmapFeatureComment?: Types.Maybe<IGenericResponseFragment> };

export const RoadmapFeatureCommentFragmentDoc = gql`
  fragment RoadmapFeatureComment on RoadmapFeatureComment {
    id
    timestamp
    comment
    user {
      username
      firstName
      lastName
      costCenter
    }
  }
`;
export const GetRoadmapCommentsDocument = gql`
  query getRoadmapComments($start: Int = 0, $parentId: String!, $page: Int = 0, $count: Int = 10) {
    getRoadmapComments(parentId: $parentId, page: $page, start: $start, count: $count) {
      resultCount
      comments {
        ...RoadmapFeatureComment
      }
    }
  }
  ${RoadmapFeatureCommentFragmentDoc}
`;
export function useGetRoadmapCommentsQuery(
  baseOptions: Apollo.QueryHookOptions<IGetRoadmapCommentsQuery, IGetRoadmapCommentsQueryVariables>
) {
  return Apollo.useQuery<IGetRoadmapCommentsQuery, IGetRoadmapCommentsQueryVariables>(
    GetRoadmapCommentsDocument,
    baseOptions
  );
}
export function useGetRoadmapCommentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetRoadmapCommentsQuery, IGetRoadmapCommentsQueryVariables>
) {
  return Apollo.useLazyQuery<IGetRoadmapCommentsQuery, IGetRoadmapCommentsQueryVariables>(
    GetRoadmapCommentsDocument,
    baseOptions
  );
}
export type GetRoadmapCommentsQueryHookResult = ReturnType<typeof useGetRoadmapCommentsQuery>;
export type GetRoadmapCommentsLazyQueryHookResult = ReturnType<typeof useGetRoadmapCommentsLazyQuery>;
export type GetRoadmapCommentsQueryResult = Apollo.QueryResult<
  IGetRoadmapCommentsQuery,
  IGetRoadmapCommentsQueryVariables
>;
export const AddRoadmapFeatureCommentDocument = gql`
  mutation addRoadmapFeatureComment(
    $id: String
    $parentId: String!
    $comment: String!
    $startDate: String
    $endDate: String
    $themeName: String
    $featureName: String
  ) {
    addRoadmapFeatureComment(
      id: $id
      parentId: $parentId
      comment: $comment
      startDate: $startDate
      endDate: $endDate
      themeName: $themeName
      featureName: $featureName
    ) {
      ...GenericResponse
    }
  }
  ${GenericResponseFragmentDoc}
`;
export type IAddRoadmapFeatureCommentMutationFn = Apollo.MutationFunction<
  IAddRoadmapFeatureCommentMutation,
  IAddRoadmapFeatureCommentMutationVariables
>;
export function useAddRoadmapFeatureCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IAddRoadmapFeatureCommentMutation,
    IAddRoadmapFeatureCommentMutationVariables
  >
) {
  return Apollo.useMutation<IAddRoadmapFeatureCommentMutation, IAddRoadmapFeatureCommentMutationVariables>(
    AddRoadmapFeatureCommentDocument,
    baseOptions
  );
}
export type AddRoadmapFeatureCommentMutationHookResult = ReturnType<typeof useAddRoadmapFeatureCommentMutation>;
export type AddRoadmapFeatureCommentMutationResult = Apollo.MutationResult<IAddRoadmapFeatureCommentMutation>;
export type AddRoadmapFeatureCommentMutationOptions = Apollo.BaseMutationOptions<
  IAddRoadmapFeatureCommentMutation,
  IAddRoadmapFeatureCommentMutationVariables
>;
