import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { faAngleDoubleDown, faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './knowledge-panel-expand.scss';

/* eslint-disable-next-line */
export interface KnowledgePanelExpandProps {
  expandPanel: boolean;
  handleExpandButtonClicked: () => void;
}

export function KnowledgePanelExpand(props: KnowledgePanelExpandProps) {
  const onExpnadButtonClicked = () => {
    props.handleExpandButtonClicked();
  };

  return (
    <div className="col-10">
      <div className="expand-icon-div">
        {props.expandPanel ? (
          <div className="expand-icon-up expand-icon-up-bounce">
            <FontAwesomeIcon
              icon={faAngleDoubleUp}
              title="Collapse Knowledge Panel"
              color="#0063c3"
              size="lg"
              onClick={onExpnadButtonClicked}
            />
          </div>
        ) : (
          <div className="expand-icon-down expand-icon-down-bounce">
            <FontAwesomeIcon
              icon={faAngleDoubleDown}
              title="Expand Knowledge Panel"
              color="#0063c3"
              size="lg"
              onClick={onExpnadButtonClicked}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default KnowledgePanelExpand;
