import { useFacetQuery } from '@amgen/api';
import { BarChart, LoaderErrorContainer } from '@amgen/components';
import { ApplicationName, IAssetAttribute } from '@amgen/core';
import { AppContext, useCleanSearchQuery } from '@amgen/shared';
import React, { useContext } from 'react';

import './facet-bar-chart.scss';

import { DASHBOARD_FILTER_COUNT } from '../../config';

export interface FacetBarChartProps {
  filterString: string[];
  filter: IAssetAttribute;
  chartTitle: string;
  isHorizontal?: boolean;
  isDate?: boolean;
  onFilterSelect?: (key: string) => void;
}

export const FacetBarChart = (props: FacetBarChartProps) => {
  const appName: ApplicationName = sessionStorage.getItem('appName')
    ? ApplicationName[sessionStorage.getItem('appName')!]
    : ApplicationName.EEA;
  const cleanQuery = useCleanSearchQuery();
  const { currentSource } = useContext(AppContext);
  const { data, loading, error } = useFacetQuery({
    variables: {
      solrHandler: appName === 'DVIEW' ? '/dview-search-select' : '/search-select',
      query: cleanQuery,
      filters: props.filterString,
      fields: [{ name: props.filter, facetLimit: DASHBOARD_FILTER_COUNT }],
      excludeCount: false,
      appName,
      userType: sessionStorage.getItem('employeeType') || '',
      sourceType: currentSource,
    },
    fetchPolicy: 'network-only',
  });

  return (
    <LoaderErrorContainer loading={loading} errors={error ? [error] : []}>
      {data?.facet?.[0]?.values && (
        <BarChart
          chartName={props.chartTitle}
          barChartData={data?.facet?.[0]?.values?.map(item => {
            return { value: item.value, count: item.count };
          })}
          onChartClick={key => props.onFilterSelect && props.onFilterSelect(key.name)}
          isHorizontal={props.isHorizontal}
          isDate={props.isDate}
        />
      )}
    </LoaderErrorContainer>
  );
};

export default FacetBarChart;
