import { IAssetAttribute } from '@amgen/core';

// NOTE: Please keep these constants alphabetically sorted.
export const ALIGN_DISPLAY_ATTRIBUTES: IAssetAttribute[] = [
  'account_name',
  'extracted_therapeutic_areas',
  'discussion_delivered_aux_id',
  'account_sub_type',
  'specialty',
  'tier',
  'ims_id',
  'interaction_medium',
  'medical_event_name',
  'medical_event_type',
  'product',
  'doc_last_modified',
  'intid',
  'inqid',
  'resid',
  'short_content',
  'content_source',
];

export const ALIGN_SURVEY_DISPLAY_ATTRIBUTES: IAssetAttribute[] = [
  'doc_last_modified',
  'question_text',
  'account_name',
  'specialty',
  'tier',
  'intid',
  'inqid',
  'resid',
  'discussion_delivered_aux_id',
  'short_content',
  'content_source',
];

export const AMGEN_RIM_DISPLAY_ATTRIBUTES: IAssetAttribute[] = [
  'account_name',
  'doc_last_modified',
  'short_content',
  'thumbnail_large',
  'department',
  'author_s',
  'display_id',
  'drug_product',
  'product_number',
  'extracted_indications',
  'fastlane',
  'document_approved_date',
  'document_number',
  'doc_version',
  'doc_owner_s',
  'doc_unit',
  'doc_status',
  'doc_nav_prefix',
];

export const AMGEN_WEBSITES_DISPLAY_ATTRIBUTES: IAssetAttribute[] = [
  'doc_last_modified',
  'short_content',
  'thumbnail_large',
];

export const BOX_DISPLAY_ATTRIBUTES: IAssetAttribute[] = ['doc_last_modified', 'short_content', 'thumbnail_large'];

export const C3_DISPLAY_ATTRIBUTES: IAssetAttribute[] = [
  'product',
  'extracted_therapeutic_areas',
  'doc_last_modified',
  'attesting_rae__c',
  'bu_c',
  'source_type',
  'content_type',
  'intid',
  'inqid',
  'resid',
  'inqdescription',
  'question_text',
  'specialty',
  'attachment_content',
  'short_content',
  'content_source',
];

export const CDOCS_DIAPLAY_ATTTRIBUTE: IAssetAttribute[] = [
  'document_approved_date',
  'document_effective_date',
  'author_s',
  'object_name',
  'doc_version',
  'doc_owner_s',
  'doc_status',
  'department',
  'doc_unit',
  'business_process',
  'short_content',
  'thumbnail_large',
  'doc_nav_prefix',
];

export const CONFLUENCE_DISPLAY_ATTRIBUTES: IAssetAttribute[] = [
  'doc_last_modified',
  'created_on',
  'author_s',
  'space_name',
  'short_content',
  'doc_nav_prefix',
];

export const CORTELLIS_DISPLAY_ATTRIBUTES: IAssetAttribute[] = [
  'doc_last_modified',
  'created_on',
  'author_s',
  'status',
  'phase',
  'company_partner',
  'technologies',
  'doc_type',
  'display_id',
  'name',
  'start_date',
  'document_id',
  'summary',
  'recruitment_status',
  'duration',
  'doc_subtype',
  'thematic_groups',
  'short_content',
  'thumbnail_large',
  'doc_nav_prefix',
];

export const IDBS_GXP_DISPLAY_ATTRIBUTES: IAssetAttribute[] = [
  'doc_last_modified',
  'author_s',
  'content_source',
  'short_content',
  'experiment_id',
];

export const IDBS_NON_GXP_DISPLAY_ATTRIBUTES: IAssetAttribute[] = [
  'doc_last_modified',
  'author_s',
  'content_source',
  'short_content',
  'experiment_id',
];

export const IRPC_DISPLAY_ATTRIBUTES: IAssetAttribute[] = [
  'extracted_therapeutic_areas',
  'intid',
  'inqid',
  'resid',
  'product',
  'doc_last_modified',
  'inqdescription',
  'account_name',
  'specialty',
  'tier',
  'discussion_delivered_aux_id',
  'short_content',
  'content_source',
];

export const PERKIN_ELMER_DISPLAY_ATTRIBUTES: IAssetAttribute[] = [
  'doc_last_modified',
  'created_on',
  'author_s',
  'space_name',
  'content_source',
  'short_content',
];

export const PUBMED_DISPLAY_ATTRIBUTES: IAssetAttribute[] = [
  'author_s',
  'doc_last_modified',
  'doc_status',
  'doc_subtype',
  'drug_product',
  'product_number',
  'extracted_indications',
  'fastlane',
  'extracted_amg_diseases_s',
  'extracted_amg_drugs',
  'extracted_amg_genes',
  'extracted_drug_targets',
  'extracted_modalities',
  'extracted_therapeutic_areas',
  // 'grant_id', // Temporarily removed until solr values are cleaned
  'investigator',
  'issn',
  'phase',
  'supplementary_list',
  'doc_nav_prefix',
];

export const SHAREPOINT_DISPLAY_ATTRIBUTES: IAssetAttribute[] = [
  'extracted_therapeutic_areas',
  'product',
  'doc_last_modified',
  'doc_status',
  'drug_product',
  'product_number',
  'extracted_indications',
  'fastlane',
  'author_s',
  'region',
  'account_name',
  'specialty',
  'tier',
  'intid',
  'inqid',
  'resid',
  'discussion_delivered_aux_id',
  'short_content',
  'thumbnail_large',
  'doc_nav_prefix',
  'contract_reference_number',
  'contract_parties',
  'contract_effective_date',
  'contract_expiry_date',
  'contract_title',
  'extracted_amg_drugs',
];

export const SMARTSHEET_DISPLAY_ATTRIBUTES: IAssetAttribute[] = [
  'doc_last_modified',
  'author_s',
  'short_content',
  'thumbnail_large',
  'doc_nav_prefix',
];

export const TWITTER_DISPLAY_ATTRIBUTES: IAssetAttribute[] = [
  'tags',
  'doc_last_modified',
  'author_s',
  'short_content',
  'doc_nav_prefix',
];

// TODO: Waters data will eventually appear under a single data source. Delete these then.
export const WATERS_ACO_DISPLAY_ATTRIBUTES: IAssetAttribute[] = [
  'title',
  'experiment_id',
  'created_on',
  'extracted_lot_number', // double check this for lot number
  'extracted_molecules',
  'author_s',
  'molecule',
  'doc_last_modified',
  'author_s',
];

export const WATERS_AML_DISPLAY_ATTRIBUTES: IAssetAttribute[] = [
  'title',
  'experiment_id',
  'created_on',
  'extracted_lot_number', // double check this for lot number
  'extracted_molecules',
  'author_s',
  'molecule',
  'doc_last_modified',
  'author_s',
  'project',
];

export const WATERS_ARI_DISPLAY_ATTRIBUTES: IAssetAttribute[] = [
  'title',
  'experiment_id',
  'created_on',
  'extracted_lot_number', // double check this for lot number
  'extracted_molecules',
  'author_s',
  'molecule',
  'doc_last_modified',
  'author_s',
  'project',
];

export const WATERS_ELN_ACO_DISPLAY_ATTRIBUTES: IAssetAttribute[] = [
  'title',
  'experiment_id',
  'created_on',
  'extracted_lot_number', // double check this for lot number
  'extracted_molecules',
  'author_s',
  'molecule',
  'doc_last_modified',
  'author_s',
  'project',
];

export const WATERS_ELN_AML_DISPLAY_ATTRIBUTES: IAssetAttribute[] = [
  'title',
  'experiment_id',
  'created_on',
  'extracted_lot_number', // double check this for lot number
  'extracted_molecules',
  'author_s',
  'molecule',
  'doc_last_modified',
  'author_s',
  'project',
];

export const WATERS_ELN_ARI_DISPLAY_ATTRIBUTES: IAssetAttribute[] = [
  'title',
  'experiment_id',
  'created_on',
  'extracted_lot_number', // double check this for lot number
  'extracted_molecules',
  'author_s',
  'molecule',
  'doc_last_modified',
  'author_s',
  'project',
];

export const WATERS_ELN_DISPLAY_ATTRIBUTES: IAssetAttribute[] = [
  'title',
  'experiment_id',
  'created_on',
  'extracted_lot_number', // double check this for lot number
  'extracted_molecules',
  'author_s',
  'molecule',
  'doc_last_modified',
  'author_s',
  'project',
];

export const ELN_DISPLAY_ATTRIBUTES: IAssetAttribute[] = [
  'experiment_id',
  'version_number',
  'created_on',
  'author_s',
  'project',
  'doc_last_modified',
  'doc_nav_prefix',
];

export const CLINICAL_TRIALS_DISPLAY_ATTRIBUTES: IAssetAttribute[] = [
  'conditions',
  'display_id',
  'drug_product',
  'product_number',
  'extracted_indications',
  'fastlane',
  'extracted_amg_diseases_s',
  'intervention_type',
  'tags',
  'sponsor',
  'doc_nav_prefix',
  'doc_last_modified',
  'result_type',
  'request_type',
  'study_status',
  'start_date',
  'author_s',
];

export const MY_AMGEN_ATTRIBUTES: IAssetAttribute[] = [
  'createdBy',
  'doc_last_modified',
  'doc_nav_prefix',
  'last_modified_by',
  'location',
  'start_date',
  'thumbnail_large',
  'type',
];

export const KMOPS_DISPLAY_ATTRIBUTES: IAssetAttribute[] = [
  'account_name',
  'author_s',
  'discussion_delivered_aux_id',
  'doc_last_modified',
  'doc_nav_prefix',
  'extracted_therapeutic_areas',
  'inqid',
  'intid',
  'product',
  'region',
  'resid',
  'short_content',
  'specialty',
  'thumbnail_large',
  'tier',
];

export const ETMF_DISPLAY_ATTRIBUTES: IAssetAttribute[] = [
  'author_s',
  'doc_status',
  'content',
  'extracted_amg_diseases_s',
  'product',
  'doc_last_modified',
  'path',
  'source_id',
  'source_type',
  'doc_nav_prefix',
  // 'content_source',
];

export const SERVICE_NOW_ATTRIBUTE: IAssetAttribute[] = [
  'doc_last_modified',
  'doc_nav_prefix',
  'tags',
  'author_s',
  'content',
];

export const GENERIC_DISPLAY_ATTRIBUTES: IAssetAttribute[] = ['doc_last_modified'];
