import * as Types from '@amgen/core';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type IGetFeatureCommentFacetQueryVariables = Types.Exact<{
  appName?: Types.Maybe<Types.ApplicationName>;
  query: Types.Scalars['String'];
  filters?: Types.Maybe<Array<Types.Maybe<Types.Scalars['String']>> | Types.Maybe<Types.Scalars['String']>>;
  fields?: Types.Maybe<
    Array<Types.Maybe<Types.IFacetFieldParametersInput>> | Types.Maybe<Types.IFacetFieldParametersInput>
  >;
  solrHandler?: Types.Maybe<Types.Scalars['String']>;
  collectionName?: Types.Maybe<Types.Scalars['String']>;
  skipAuthorization?: Types.Maybe<Types.Scalars['Boolean']>;
}>;

export type IGetFeatureCommentFacetQuery = {
  facet?: Types.Maybe<
    Array<
      Types.Maybe<
        Pick<Types.IFacetField, 'field'> & {
          values?: Types.Maybe<Array<Types.Maybe<Pick<Types.IFacetValue, 'value' | 'count'>>>>;
        }
      >
    >
  >;
};

export type IGetFeatureLikesFacetQueryVariables = Types.Exact<{
  appName?: Types.Maybe<Types.ApplicationName>;
  query: Types.Scalars['String'];
  filters?: Types.Maybe<Array<Types.Maybe<Types.Scalars['String']>> | Types.Maybe<Types.Scalars['String']>>;
  fields?: Types.Maybe<
    Array<Types.Maybe<Types.IFacetFieldParametersInput>> | Types.Maybe<Types.IFacetFieldParametersInput>
  >;
  solrHandler?: Types.Maybe<Types.Scalars['String']>;
  collectionName?: Types.Maybe<Types.Scalars['String']>;
  skipAuthorization?: Types.Maybe<Types.Scalars['Boolean']>;
}>;

export type IGetFeatureLikesFacetQuery = {
  facet?: Types.Maybe<
    Array<
      Types.Maybe<
        Pick<Types.IFacetField, 'field'> & {
          values?: Types.Maybe<Array<Types.Maybe<Pick<Types.IFacetValue, 'value' | 'count'>>>>;
        }
      >
    >
  >;
};

export type IGetUserProfileFacetQueryVariables = Types.Exact<{
  appName?: Types.Maybe<Types.ApplicationName>;
  query: Types.Scalars['String'];
  filters?: Types.Maybe<Array<Types.Maybe<Types.Scalars['String']>> | Types.Maybe<Types.Scalars['String']>>;
  fields?: Types.Maybe<
    Array<Types.Maybe<Types.IFacetFieldParametersInput>> | Types.Maybe<Types.IFacetFieldParametersInput>
  >;
  solrHandler?: Types.Maybe<Types.Scalars['String']>;
  collectionName?: Types.Maybe<Types.Scalars['String']>;
  skipAuthorization?: Types.Maybe<Types.Scalars['Boolean']>;
}>;

export type IGetUserProfileFacetQuery = {
  facet?: Types.Maybe<
    Array<
      Types.Maybe<
        Pick<Types.IFacetField, 'field'> & {
          values?: Types.Maybe<Array<Types.Maybe<Pick<Types.IFacetValue, 'value' | 'count'>>>>;
        }
      >
    >
  >;
};

export const GetFeatureCommentFacetDocument = gql`
  query getFeatureCommentFacet(
    $appName: ApplicationName
    $query: String!
    $filters: [String] = ["type:ROADMAP_COMMENT"]
    $fields: [FacetFieldParametersInput]
    $solrHandler: String = "/select"
    $collectionName: String = "user_profile"
    $skipAuthorization: Boolean = true
  ) {
    facet(
      applicationName: $appName
      query: $query
      filters: $filters
      fields: $fields
      solrHandler: $solrHandler
      collectionName: $collectionName
      skipAuthorization: $skipAuthorization
    ) {
      field
      values {
        value
        count
      }
    }
  }
`;
export function useGetFeatureCommentFacetQuery(
  baseOptions: Apollo.QueryHookOptions<IGetFeatureCommentFacetQuery, IGetFeatureCommentFacetQueryVariables>
) {
  return Apollo.useQuery<IGetFeatureCommentFacetQuery, IGetFeatureCommentFacetQueryVariables>(
    GetFeatureCommentFacetDocument,
    baseOptions
  );
}
export function useGetFeatureCommentFacetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetFeatureCommentFacetQuery, IGetFeatureCommentFacetQueryVariables>
) {
  return Apollo.useLazyQuery<IGetFeatureCommentFacetQuery, IGetFeatureCommentFacetQueryVariables>(
    GetFeatureCommentFacetDocument,
    baseOptions
  );
}
export type GetFeatureCommentFacetQueryHookResult = ReturnType<typeof useGetFeatureCommentFacetQuery>;
export type GetFeatureCommentFacetLazyQueryHookResult = ReturnType<typeof useGetFeatureCommentFacetLazyQuery>;
export type GetFeatureCommentFacetQueryResult = Apollo.QueryResult<
  IGetFeatureCommentFacetQuery,
  IGetFeatureCommentFacetQueryVariables
>;
export const GetFeatureLikesFacetDocument = gql`
  query getFeatureLikesFacet(
    $appName: ApplicationName
    $query: String!
    $filters: [String] = ["type:ROADMAP_LIKE"]
    $fields: [FacetFieldParametersInput]
    $solrHandler: String = "/select"
    $collectionName: String = "user_profile"
    $skipAuthorization: Boolean = true
  ) {
    facet(
      applicationName: $appName
      query: $query
      filters: $filters
      fields: $fields
      solrHandler: $solrHandler
      collectionName: $collectionName
      skipAuthorization: $skipAuthorization
    ) {
      field
      values {
        value
        count
      }
    }
  }
`;
export function useGetFeatureLikesFacetQuery(
  baseOptions: Apollo.QueryHookOptions<IGetFeatureLikesFacetQuery, IGetFeatureLikesFacetQueryVariables>
) {
  return Apollo.useQuery<IGetFeatureLikesFacetQuery, IGetFeatureLikesFacetQueryVariables>(
    GetFeatureLikesFacetDocument,
    baseOptions
  );
}
export function useGetFeatureLikesFacetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetFeatureLikesFacetQuery, IGetFeatureLikesFacetQueryVariables>
) {
  return Apollo.useLazyQuery<IGetFeatureLikesFacetQuery, IGetFeatureLikesFacetQueryVariables>(
    GetFeatureLikesFacetDocument,
    baseOptions
  );
}
export type GetFeatureLikesFacetQueryHookResult = ReturnType<typeof useGetFeatureLikesFacetQuery>;
export type GetFeatureLikesFacetLazyQueryHookResult = ReturnType<typeof useGetFeatureLikesFacetLazyQuery>;
export type GetFeatureLikesFacetQueryResult = Apollo.QueryResult<
  IGetFeatureLikesFacetQuery,
  IGetFeatureLikesFacetQueryVariables
>;
export const GetUserProfileFacetDocument = gql`
  query getUserProfileFacet(
    $appName: ApplicationName
    $query: String!
    $filters: [String]
    $fields: [FacetFieldParametersInput]
    $solrHandler: String = "/select"
    $collectionName: String = "user_profile"
    $skipAuthorization: Boolean = true
  ) {
    facet(
      applicationName: $appName
      query: $query
      filters: $filters
      fields: $fields
      solrHandler: $solrHandler
      collectionName: $collectionName
      skipAuthorization: $skipAuthorization
    ) {
      field
      values {
        value
        count
      }
    }
  }
`;
export function useGetUserProfileFacetQuery(
  baseOptions: Apollo.QueryHookOptions<IGetUserProfileFacetQuery, IGetUserProfileFacetQueryVariables>
) {
  return Apollo.useQuery<IGetUserProfileFacetQuery, IGetUserProfileFacetQueryVariables>(
    GetUserProfileFacetDocument,
    baseOptions
  );
}
export function useGetUserProfileFacetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetUserProfileFacetQuery, IGetUserProfileFacetQueryVariables>
) {
  return Apollo.useLazyQuery<IGetUserProfileFacetQuery, IGetUserProfileFacetQueryVariables>(
    GetUserProfileFacetDocument,
    baseOptions
  );
}
export type GetUserProfileFacetQueryHookResult = ReturnType<typeof useGetUserProfileFacetQuery>;
export type GetUserProfileFacetLazyQueryHookResult = ReturnType<typeof useGetUserProfileFacetLazyQuery>;
export type GetUserProfileFacetQueryResult = Apollo.QueryResult<
  IGetUserProfileFacetQuery,
  IGetUserProfileFacetQueryVariables
>;
