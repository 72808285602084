import { useOutsideClick } from '@amgen/shared';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { ReactChild, ReactElement, ReactNode, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import './slider.scss';

export interface SliderProps {
  show: boolean;
  onToggle: (isShown: boolean) => void;
  variant: 'dark' | 'light';
  position: 'left' | 'right';
  header?: ReactNode;
}

export const Slider: React.FC<SliderProps> = props => {
  const [hasBackdrop, setHasBackdrop] = useState(false);
  const rootEl = document.getElementsByTagName('body')[0];
  const containerRef = useRef<any>(null);

  useOutsideClick(containerRef, () => props.onToggle(false));

  return ReactDOM.createPortal(
    <CSSTransition
      in={props.show}
      timeout={400}
      unmountOnExit
      classNames={`slider-outer-container`}
      onEnter={() => props.onToggle(true)}
      onEntered={() => setHasBackdrop(true)}
      onExit={() => setHasBackdrop(false)}
      onExited={() => props.onToggle(false)}
    >
      <div className={classNames('slider-outer-container', props.position, { 'slider-backdrop': hasBackdrop })}>
        <Container className={classNames('slider-container', 'px-0', props.variant)} ref={containerRef}>
          <Row noGutters>
            <Col xs="12" as="h1" className="pb-5">
              {props.header}
              <span className="cursor-pointer btn-close" onClick={() => props.onToggle(false)}>
                <FontAwesomeIcon icon={faTimes} />
              </span>
            </Col>
            <Col xs="12" className="slider-inner-container pt-2 overflow-auto thin-scrollbar">
              {props.children}
            </Col>
          </Row>
        </Container>
      </div>
    </CSSTransition>,
    rootEl
  );
};

export default Slider;
