import React from 'react';

import './news-context-provider.module.scss';

import { NewsContext } from './news-context';

/* eslint-disable-next-line */
export interface NewsContextProviderProps {
  news: any;
  loading: boolean;
}

export const NewsContextProvider: React.FC<NewsContextProviderProps> = ({ children, news, loading }) => {
  return (
    <NewsContext.Provider value={{ news: news.results ? news.results : [], loading: loading }}>
      {children}
    </NewsContext.Provider>
  );
};

export default NewsContextProvider;
