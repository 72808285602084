import { useEnvironment } from '@amgen/core';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { useOktaAuth } from '@okta/okta-react';
import React, { useMemo, useRef } from 'react';

import { APIContext, IAPIConfig } from './context';

function useApolloClientWithAuth(uri: string) {
  // Note: using okta auth directly here as all auth depends on Apollo Client. This is one of the two exceptions.
  const { authState } = useOktaAuth();
  const { current: cache } = useRef(new InMemoryCache());

  const client = useMemo(() => {
    return new ApolloClient({
      uri,
      cache,
      headers: {
        authorization: `Bearer ${authState.accessToken?.accessToken}`,
      },
    });
  }, [authState.accessToken, cache, uri]);

  return client;
}

export const APIProvider: React.FC<IAPIConfig> = ({ children, ...config }) => {
  const { appName, requestHandler, suggestHandler, envGraphqlUrl } = useEnvironment();
  const { current: value } = useRef({ ...config, reqHandler: requestHandler, suggestHandler, appName });
  const client = useApolloClientWithAuth(envGraphqlUrl);
  return (
    <APIContext.Provider value={value}>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </APIContext.Provider>
  );
};
