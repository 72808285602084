import { DataSource } from '@amgen/core';
import { useField } from 'formik';
import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

import './data-source-input.scss';

import { IGD_HELP_TEXT } from '../../help-text';
import { formConfig } from '../../models';
import { NewRequestFormLabel } from '../new-request-form-label/new-request-form-label';
import SmartsheetPopup from './smartsheet-popup/smartsheet-popup';

/* eslint-disable-next-line */
export interface DataSourceInputProps {}

export const DataSourceInput = (props: DataSourceInputProps) => {
  const [contentSourceField, contentSourceMeta, contentSourceHelpers] = useField('contentSource');
  const [sourcePathField, sourcePathMeta, sourcePathHelpers] = useField('sourcePath');

  const [show, setShow] = useState(false);
  useEffect(() => setShow(contentSourceField.value === DataSource.SMARTSHEET), [contentSourceField.value]);

  return (
    <Row className="pb-3">
      <Form.Group as={Col} xs="12" md="6" controlId={contentSourceField.name}>
        <NewRequestFormLabel
          name={contentSourceField.name}
          label="Data Source"
          helpText={IGD_HELP_TEXT['contentSource']}
        />
        <Form.Control
          as="select"
          placeholder="Select DataSource"
          {...contentSourceField}
          onChange={e => {
            contentSourceField.onChange(e);
            contentSourceHelpers.setTouched(true);
          }}
          isInvalid={contentSourceMeta.touched && !!contentSourceMeta.error}
        >
          <option value={DataSource.SHAREPOINT}>SharePoint Online (SPO)</option>
          {/* <option value={DataSource.SMARTSHEET}>SmartSheet</option>
          <option value={DataSource.CONFLUENCE}>Confluence</option>
          <option value={DataSource.BOX}>Box</option> */}
        </Form.Control>
        <Form.Control.Feedback type="invalid">{contentSourceMeta.error}</Form.Control.Feedback>
      </Form.Group>

      <SmartsheetPopup
        show={show}
        onHide={() => {
          setShow(false);
          contentSourceHelpers.setValue(DataSource.SHAREPOINT);
        }}
      />

      <Form.Group as={Col} xs="12" md="6" controlId={sourcePathField.name}>
        <NewRequestFormLabel
          name={sourcePathField.name}
          helpText={`Enter the ${contentSourceField.value} ${
            formConfig.get(contentSourceField.value)?.sourcePath.label
          }`}
        />
        <Form.Control
          type="string"
          placeholder={`${formConfig.get(contentSourceField.value)?.sourcePath.prefix}sites/145b56fc`}
          {...sourcePathField}
          onChange={e => {
            sourcePathHelpers.setTouched(true);
            sourcePathField.onChange(e);
          }}
          isInvalid={sourcePathMeta.touched && !!sourcePathMeta.error}
        />
        <Form.Control.Feedback type="invalid">{sourcePathMeta.error}</Form.Control.Feedback>
      </Form.Group>
    </Row>
  );
};

export default DataSourceInput;
