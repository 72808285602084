import { useConsentCheck } from '@amgen/auth';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';

import './consent-acceptor.scss';

import ConsentParagraph from '../consent-paragraph/consent-paragraph';

/* eslint-disable-next-line */
export interface ConsentAcceptorProps {
  show?: boolean;
  isClosable?: boolean;
  setShow?: (s: boolean) => void;
}

export const ConsentAcceptor: React.FC<ConsentAcceptorProps> = ({ children, show, isClosable, setShow }) => {
  const { hasAgreedToConsent, handleAgreedConsent } = useConsentCheck();
  if (isClosable || !hasAgreedToConsent) {
    return (
      <Modal
        backdrop={isClosable ?? 'static'}
        keyboard={isClosable ?? 'static'}
        show={isClosable ? show : !hasAgreedToConsent}
        onHide={() => setShow?.(false)}
        size="lg"
        aria-labelledby="accept-consent-modal"
        centered
      >
        <Modal.Header className="bg-custom-info" closeButton={isClosable}>
          <Modal.Title as="h1" id="accept-consent-modal" className="text-white">
            Terms and Conditions
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="lead">
          <ConsentParagraph />
        </Modal.Body>
        {!isClosable && (
          <Modal.Footer>
            <Button
              variant="custom-info"
              className="text-white"
              onClick={() => {
                handleAgreedConsent();
              }}
            >
              Accept
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    );
  }
  return <>{children}</>;
};

export default ConsentAcceptor;
