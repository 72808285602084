import { IFacetValue } from '@amgen/core';
import { splitRetain } from '@amgen/utils';
import { FixedSizeNodeMetadata } from 'react-vtree';

import { ITree } from './trees';

// TODO: Clean up and refractor to multilevel tree
// TODO: Replace these with `content-tree.ts`
export enum Hierarchy {
  PARENT = 0,
  CHILD = 1,
  GRANDCHILD = 2,
}

interface IFilterValue {
  id: string;
  count: number;
  level: Hierarchy;
}

export interface IFilterNode extends IFilterValue {
  name: string;
  children: IFilterNode[];
}

export interface IFilterExtendedData extends Readonly<IFilterValue> {
  readonly isLeaf: boolean;
  readonly name: string;
  readonly nestingLevel: number;
}

export interface IFilterStackElement {
  nestingLevel: number;
  node: IFilterNode;
}

type IFilterTree = ITree<[number, number, string]>;

function convertFiltersToTree(filters: IFacetValue[]) {
  const result: IFilterTree = { value: [0, 0, ''], children: {} };
  filters.forEach(filter => {
    // eslint-disable-next-line prefer-const
    let [count, depth, trunk, branch, leaf] = [`${filter.count}`].concat(
      // 2 -> accounts for count and depth; 3 -> accounts for country, state, city
      // this ensures that all elements will have between 2 and 5 elements.
      splitRetain(filter.value ?? '', '/', 2 + Math.min(3, +(filter.value?.[0] ?? '0')))
    );

    const value: [number, number, string] = [+count, +depth, filter.value ?? ''];

    switch (depth) {
      case '0':
        // a country => state and city will be undefined. If not ignore them, it's probably incorrect data.
        if (!trunk) return;
        if (result.children[trunk]) {
          // may be this filterNode was added earlier by parsing a state belonging to this filterNode.
          // in this case, the value needs to be updated to reflect the correct one.
          result.children[trunk].value = value;
        } else {
          result.children[trunk] = { value, children: {} };
        }
        break;
      case '1':
        // a state => filterNode may be missing => assume 'Unknown'.
        // city will be undefined. If not ignore it, it's probably incorrect data.
        if (!branch) return;
        if (!trunk) trunk = 'Unknown';
        if (!result.children[trunk]) result.children[trunk] = { value, children: {} };
        if (result.children[trunk].children[branch]) {
          // may be this state was added earlier by parsing a city belonging to this state.
          // in this case, the value needs to be updated to reflect the correct one.
          result.children[trunk].children[branch].value = value;
        } else {
          result.children[trunk].children[branch] = { value, children: {} };
        }
        break;
      case '2':
        // a city => either filterNode or state or both may be missing.
        if (!leaf) return;
        if (!trunk) trunk = 'Unknown';
        if (!result.children[trunk]) result.children[trunk] = { value, children: {} };
        if (!branch) {
          // some countries (specially smaller ones) may not have states. eg: UAE - Dubai, Abu Dhabi, etc
          result.children[trunk].children[leaf] = { value, children: {} };
        } else {
          if (result.children[trunk].children[branch]) {
            result.children[trunk].children[branch].children[leaf] = { value, children: {} };
          } else {
            result.children[trunk].children[branch] = { value, children: { [leaf]: { value, children: {} } } };
          }
        }
        break;
    }
  });

  return result;
}

function createFilterNode(name: string, filters: IFilterTree) {
  const {
    children,
    value: [count, level, id],
  } = filters;

  const node: IFilterNode = { id, name, count, level, children: [] };

  if (Object.keys(children).length === 0) return node;

  (Object.entries(children) as [string, IFilterTree][]).forEach(([name, children]) =>
    node.children.push(createFilterNode(name, children))
  );

  return node;
}

export function createFilterTreeWalker(filters: IFacetValue[]) {
  const rootNode = createFilterNode('rootFilter', convertFiltersToTree(filters));

  return function* (refresh: boolean): Generator<FixedSizeNodeMetadata<IFilterExtendedData> | string> {
    const stack: IFilterStackElement[] = [];
    stack.push({ nestingLevel: 0, node: rootNode });

    while (stack.length !== 0) {
      const { node, nestingLevel } = stack.pop()!;

      const isOpened = yield refresh
        ? {
            id: node.id,
            isLeaf: node.children.length === 0,
            isOpenByDefault: true,
            name: node.name,
            nestingLevel,
            count: node.count,
            level: node.level,
          }
        : node.id;

      if (node.children.length !== 0 && isOpened) {
        for (let i = node.children.length - 1; i >= 0; i--) {
          stack.push({ nestingLevel: nestingLevel + 1, node: node.children[i] });
        }
      }
    }
  };
}
