import React from 'react';

import './custom-data-source-toggle-button.scss';

import AddCustomDataSourceButton from '../add-custom-data-source-button/add-custom-data-source-button';
import RemoveCustomDataSourceButton from '../remove-custom-data-source-button/remove-custom-data-source-button';

export interface CustomDataSourceToggleButtonProps {
  isCustomDataSource: boolean;
  onCustomDataSourceToggle: () => void;
}

export const CustomDataSourceToggleButton: React.FC<CustomDataSourceToggleButtonProps> = ({
  isCustomDataSource,
  onCustomDataSourceToggle,
}) => {
  return isCustomDataSource ? (
    <RemoveCustomDataSourceButton handleClick={onCustomDataSourceToggle} />
  ) : (
    <AddCustomDataSourceButton handleClick={onCustomDataSourceToggle} />
  );
};

export default CustomDataSourceToggleButton;
