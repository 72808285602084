import { EXPORT_BUTTON_CONTAINER } from '@amgen/feature-csv-export';
import { SAVE_SEARCH_BUTTON_CONTAINER, VIEW_SAVED_SEARCH_BUTTON_CONTAINER } from '@amgen/feature-saved-search';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';
import {
  AppContext,
} from '@amgen/shared';
import './navbar.scss';

import SubHeaderNavigation from '../sub-header-navigation/sub-header-navigation';

export interface IPageTab {
  pathname: string;
  title: string;
  icon: IconProp;
  isMobileOnly: boolean;
  hasExport: boolean;
}

export interface NavbarProps {
  tabs: IPageTab[];
}

export const Navbar: React.FC<NavbarProps> = props => {
  const { pathname, search } = useLocation();
  const {
    setSelectedResultTab,
    setActiveId,
    setIsModalOpen,
  } = useContext(AppContext);
  const currentTab = props.tabs.find(tab => tab.pathname === pathname);
  const searchPersistTabs = ['/dashboard', '/topics', '/'];



  const abTestingGroup = JSON.parse(sessionStorage.getItem('abTestingGroup') as string);

  if (abTestingGroup) {
    setSelectedResultTab('All Results');
    setActiveId(1);
  }

  return (
    <Nav className="d-flex">
      {props.tabs
        .filter(tab => !tab.isMobileOnly)
        .map(tab => (
          <Nav.Item className="text-center d-none d-md-block" key={tab.title}>
            <NavLink
              exact
              to={searchPersistTabs.includes(tab.pathname) ? { pathname: tab.pathname, search } : tab.pathname}
              className="nav-link h2 mb-0"
              activeClassName="active"
            >
              <FontAwesomeIcon icon={tab.icon} />
              <span className="pl-2 ">{tab.title}</span>
            </NavLink>
          </Nav.Item>
        ))}
      <h1 className="my-2 d-md-none header-font">
        {currentTab?.icon && <FontAwesomeIcon icon={currentTab?.icon!} />}
        <span className="pl-2">{currentTab?.title}</span>
      </h1>

      {!abTestingGroup && <SubHeaderNavigation />}
      <div className="icon-navigation pt-2 pr-3">
        {<span className="icon-navigation-item cursor-pointer pr-3" id={SAVE_SEARCH_BUTTON_CONTAINER} />}
        {<span className="icon-navigation-item cursor-pointer pr-3" id={VIEW_SAVED_SEARCH_BUTTON_CONTAINER} />}
        <span className="icon-navigation-item cursor-pointer pr-3" >
          <img title="Download" alt="" onClick={() => setIsModalOpen(true)} src="assets/images/download.svg" />
        </span>
      </div>
    </Nav>
  );
};

export default Navbar;
