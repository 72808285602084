import { AssetContext } from '@amgen/shared';
import React, { useContext } from 'react';
import { Col } from 'react-bootstrap';

import './image.scss';

import { AssetThumbnail } from '../../asset-thumbnail/asset-thumbnail';

export interface ImageProps {
  showThumbnail?: boolean;
}

export const Image: React.FC<ImageProps> = props => {
  const { docSolrAsset } = useContext(AssetContext);

  return props.showThumbnail ? (
    <Col xs="12" lg="2">
      {!!docSolrAsset.dataAsset && <AssetThumbnail asset={docSolrAsset.dataAsset} />}
    </Col>
  ) : null;
};

export default Image;
