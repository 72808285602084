import { useEffect, useLayoutEffect, useState } from 'react';

import { getZoom } from '../utils/display';

export function useWindowSizeListener(callback?: () => void, limit = 768) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < limit);

  useEffect(() => {
    const handleResize = () => {
      const flag = window.innerWidth < limit;
      if (flag && callback) callback();
      setIsMobile(flag);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  });

  return isMobile;
}

export function useWindowScrollListener(height: number, callback?: () => void) {
  const [crossedThresholdHeight, setCrossedThresholdHeight] = useState(window.pageYOffset >= height);

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > height) {
        setCrossedThresholdHeight(true);
        if (callback) callback();
      } else {
        setCrossedThresholdHeight(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [callback, height]);

  return crossedThresholdHeight;
}

export function useWindowZoomListener(limit = 1, callback?: () => void) {
  const [zoomLevel, setZoomLevel] = useState(getZoom() > limit);
  useEffect(() => {
    const getSizes = () => {
      const zoomLevel = getZoom() > limit;
      if (zoomLevel && callback) callback();
      setZoomLevel(zoomLevel);
    };

    window.addEventListener('resize', getSizes, false);

    return () => window.removeEventListener('resize', getSizes);
  });
  return zoomLevel;
}

export function useWindowHeightListener(limit: number, callback?: () => void) {
  const [short, setShort] = useState(window.innerHeight < limit);

  useEffect(() => {
    const handleResize = () => {
      const short = window.innerHeight < limit;
      setShort(short);
      if (short && callback) callback();
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [callback, limit]);

  return short;
}
