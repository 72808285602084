import { useSearchQuery } from '@amgen/api';
import { LoaderErrorContainer } from '@amgen/components';
import { DEFAULT_SEARCH_QUERY, IAssetAttribute, useEnvironment } from '@amgen/core';
import { AssetAttributeRow } from '@amgen/feature-asset-attributes';
import { FiltersObject, useAppliedFilters } from '@amgen/shared';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Alert } from 'react-bootstrap';

import './doc-attachment-links.scss';

import AttachmentDownloadButton from '../attachment-download-button/attachment-download-button';

export interface DocAttachmentLinksProps {
  docName: string;
  docId?: string;
  attachmentParentField?: IAssetAttribute;
  showDate?: boolean;
  restrictCount?: number;
  onTotalCountChange?: (count: number) => void;
}

export const DocAttachmentLinks: React.FC<DocAttachmentLinksProps> = props => {
  const filters = useAppliedFilters(
    props.docId
      ? new FiltersObject()
          .add('attachment', 'content_type')
          .add(props.docId, props.attachmentParentField || 'parent_object_id')
          .add('*', 'filename')
      : new FiltersObject()
  );

  const { appName, requestHandler } = useEnvironment();
  const { loading, data, error } = useSearchQuery({
    variables: {
      solrHandler: appName === 'DVIEW' ? '/dview-search-select' : '/search-select',
      query: DEFAULT_SEARCH_QUERY,
      filters,
      start: 0,
      page: 0,
      count: 10,
      includeDocs: true,
      noShortContent: false,
      appName,
    },
    onCompleted: () => {
      if (props.onTotalCountChange) {
        props.onTotalCountChange(data?.search?.docs?.length ?? props.restrictCount!);
      }
    },
    skip: !props.docId,
  });

  if (!props.docId) {
    return (
      <Alert variant="warning" className="d-flex align-items-center py-0">
        <span className="mr-3" style={{ fontSize: 'x-large' }}>
          <FontAwesomeIcon icon={faFolderOpen} />
        </span>
        No Attachment Found
      </Alert>
    );
  }

  return (
    <LoaderErrorContainer errors={error ? [error] : []} loading={loading}>
      <ol className="list-unstyled">
        {!props.showDate && !props.restrictCount
          ? data?.search?.docs?.map(docSolrAsset =>
              docSolrAsset?.dataAsset?.filename ? (
                <li key={docSolrAsset.dataAsset.id}>
                  <AttachmentDownloadButton url={docSolrAsset.dataAsset.filename} fileName={props.docName} />
                </li>
              ) : null
            )
          : data?.search?.docs?.slice(0, props.restrictCount).map(asset =>
              asset?.dataAsset?.filename ? (
                <li key={asset.dataAsset.id} className="date-align-left px-2 py-3">
                  <AttachmentDownloadButton
                    url={asset.dataAsset.filename}
                    fileName={props.docName}
                    className="text-left"
                  />
                  <AssetAttributeRow
                    asset={asset.dataAsset}
                    displayRatio={[5, 6]}
                    attribute="doc_last_modified"
                    clickableAttributes={[]}
                    onApplyFilter={() => {}}
                    mobileViewCol={3}
                  />
                </li>
              ) : null
            )}
      </ol>

      {(data?.search?.docs?.length ?? 0) < 1 && (
        <Alert variant="warning" className="d-flex align-items-center py-0">
          <span className="mr-3" style={{ fontSize: 'x-large' }}>
            <FontAwesomeIcon icon={faFolderOpen} />
          </span>
          No Attachment Found
        </Alert>
      )}
    </LoaderErrorContainer>
  );
};

export default DocAttachmentLinks;
