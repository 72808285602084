import { SavedSearchPanel, SaveSubscribeModal, VIEW_SAVED_SEARCH_BUTTON_CONTAINER } from '@amgen/feature-saved-search';
import { AppContext, MatomoContext, SAVING_CONFIGURATIONS_ACTIONS, SEARCH_APP_FEATURE_LIST } from '@amgen/shared';
import React, { useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import './view-saved-button.scss';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ViewSavedButtonProps {
  className?: string;
  openSaveModal?: () => void;
}

export const ViewSavedButton: React.FC<ViewSavedButtonProps> = props => {
  const { setSavedSearch } = useContext(AppContext);
  const [isSavedSearchPanelOpen, setIsSavedSearchPanelOpen] = useState(false);
  const [isSaveSearchModalOpen, setIsSaveSearchModalOpen] = useState(false);
  const user = JSON.parse(sessionStorage.user);
  const { trackApplicationFeature } = useContext(MatomoContext);
  const [container, setContainer] = useState<HTMLElement | null>(null);
  useEffect(() => setContainer(document.getElementById(VIEW_SAVED_SEARCH_BUTTON_CONTAINER)), []);

  return (
    container &&
    ReactDOM.createPortal(
      <>
        <img
          src="assets/images/list.svg"
          alt=""
          title="Saved Searches"
          onClick={() => {
            setIsSavedSearchPanelOpen(true);
            trackApplicationFeature(
              SEARCH_APP_FEATURE_LIST.SAVING_CONFIGURATIONS,
              SAVING_CONFIGURATIONS_ACTIONS.VIEW_SAVED_SEARCHES
            );
          }}
        />

        {isSavedSearchPanelOpen && (
          <SavedSearchPanel
            isOpen={isSavedSearchPanelOpen}
            onClose={isSaveEdit => {
              setIsSavedSearchPanelOpen(false);
              if (isSaveEdit) {
                setIsSaveSearchModalOpen(true);
              }
            }}
          />
        )}

        {isSaveSearchModalOpen && (
          <SaveSubscribeModal
            isOpen={isSaveSearchModalOpen}
            onCancel={() => {
              setIsSaveSearchModalOpen(false);
              setSavedSearch(null);
            }}
            onSuccess={() => {
              setIsSaveSearchModalOpen(false);
              setSavedSearch(null);
            }}
            user={user}
            hideUpdateBtn
          />
        )}
      </>,
      container
    )
  );
};

export default ViewSavedButton;
