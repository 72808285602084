import classnames from 'classnames';
import React, { useEffect } from 'react';

import './infinite-scroll-loader.scss';

import LoaderErrorContainer from '../loader-error-container/loader-error-container';

export interface InfiniteScrollLoaderProps {
  className?: string;
  loading: boolean;
  hasNext: boolean;
  page: number;
  hasScrolledToEnd: (page: number) => void;
  isEmpty: boolean;
  emptyImgSrc?: string;
}

export const InfiniteScrollLoader: React.FC<InfiniteScrollLoaderProps> = props => {
  useEffect(() => {
    window.onscroll = (event: any) => {
      const document = event.target;
      if (
        document.documentElement.scrollTop + document.documentElement.clientHeight + 100 >=
          document.body.scrollHeight &&
        props.hasNext &&
        !props.loading
      ) {
        props.hasScrolledToEnd(props.page + 1);
      }
    };
  }, [props.loading, props.page]);

  return (
    <div className={classnames('overflow-auto w-100', props.className)}>
      {props.children}
      <LoaderErrorContainer
        loading={props.loading}
        showEmpty={props.isEmpty}
        spinnerSize="sm"
        emptyImgSrc={props.emptyImgSrc}
      ></LoaderErrorContainer>
    </div>
  );
};

export default InfiniteScrollLoader;
