import { IAssetAttribute, IFacetValue } from '@amgen/core';
import { FacetsPanelContext } from '@amgen/shared-kmi';
import React, { ChangeEvent, useContext, useMemo, useState } from 'react';
import { FormControl } from 'react-bootstrap';
import { FixedSizeTree } from 'react-vtree';
import { debounce } from 'ts-debounce';

import './tree-facet-dropdown-items.scss';

import { createFilterTreeWalker, IFilterExtendedData } from '../../utils';
import FacetValue from '../facet-value/facet-value';

export interface TreeFacetDropdownItemsProps {
  facetValues: IFacetValue[];
  category: IAssetAttribute;
  onFilterToggle?: (category: IAssetAttribute, value: string) => void;
  onClear?: (category: IAssetAttribute) => void;
  categoryName?: string;
  excludeCount?: boolean;
  isLoading?: boolean;
}

export const TreeFacetDropdownItems: React.FC<TreeFacetDropdownItemsProps> = props => {
  const { selectedFilters } = useContext(FacetsPanelContext);
  const categoryItems = selectedFilters.filters?.[props.category] || new Set<string>();
  const ITEM_HEIGHT = 32;

  const [searchText, setSearchText] = useState('');

  const filteredValues = useMemo(() => {
    const regex = new RegExp(searchText, 'i');
    return props.facetValues.filter(value => !searchText || regex.test(value.value ?? '')) ?? [];
  }, [props.facetValues, searchText]);

  const treeWalker = useMemo(() => createFilterTreeWalker(filteredValues), [filteredValues]);

  return (
    <>
      {(props.facetValues.length ?? 0) > 10 && (
        <div className="px-3 pb-2">
          <FormControl
            placeholder="Search"
            onChange={(e: ChangeEvent<HTMLInputElement>) => debounce(setSearchText, 300)(e.target.value.trim())}
          />
        </div>
      )}

      {(props.facetValues.length ?? 0) > 0 ? (
        <FixedSizeTree<IFilterExtendedData>
          className="thin-scrollbar"
          treeWalker={treeWalker}
          itemSize={ITEM_HEIGHT}
          height={250}
        >
          {({ style, data }) => {
            // Ignore the root, we care only about location values.
            if (data.value === 'location') return null;

            return (
              <div
                style={{
                  ...style,
                  paddingLeft: (data.nestingLevel - 1) * 20, // because the root is ignored, nesting level is one less
                  top: (style.top as any) - ITEM_HEIGHT, // adjust for first rendered element (which is null)
                }}
              >
                <FacetValue
                  facet={{ count: data.count, value: data.name }}
                  isSelected={categoryItems.has(data.id)}
                  eventKey={data.id}
                />
              </div>
            );
          }}
        </FixedSizeTree>
      ) : (
        <div className="px-3">No Items</div>
      )}
    </>
  );
};
export default TreeFacetDropdownItems;
