import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useField } from 'formik';
import React, { useCallback } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

import './space-owner-input.scss';

import { returnFileSize } from '../../models';
import DisplayNameInput from '../display-name-input/display-name-input';
import { NewRequestFormLabel } from '../new-request-form-label/new-request-form-label';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SpaceOwnerInputProps {}

export const SpaceOwnerInput = (props: SpaceOwnerInputProps) => {
  const [field] = useField<'Yes' | 'No'>('owner');
  const [fileField, fileMeta, fileFieldHelper] = useField<File | undefined>('file');

  const handleFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      fileFieldHelper.setTouched(true);
      const inputFile = e.target.files?.[0];
      fileFieldHelper.setValue(inputFile);
    },
    [fileFieldHelper]
  );

  const removeAddedfile = () => {
    fileFieldHelper.setValue(undefined);
  };

  return (
    <Row className="pb-3">
      <Form.Group as={Col} xs="12" md="3">
        <NewRequestFormLabel name={field.name} />
        <div className="d-flex">
          <Form.Check
            className="mr-4 cursor-pointer"
            type="radio"
            label="Yes"
            {...field}
            onClick={() => {
              fileFieldHelper.setTouched(false);
              fileFieldHelper.setValue(undefined);
            }}
            value="Yes"
          />
          <Form.Check className="cursor-pointer" type="radio" label="No" defaultChecked {...field} value="No" />
        </div>
      </Form.Group>

      {field.value === 'No' ? (
        <Form.Group as={Col} xs="12" md="3" controlId="fileDetails">
          <NewRequestFormLabel
            name="fileDetails"
            label="Owner's Approval"
            helpText="Please attach a file (pdf, jpg, png, docx, zip, or email)"
          />
          <Form.File>
            {fileField?.value?.name ? (
              <Form.File.Label data-browse="Browse">
                {fileField?.value?.name + '  '}
                <FontAwesomeIcon
                  title="Remove added file"
                  onClick={removeAddedfile}
                  className="cursor-pointer text-primary fa-sm ml-2"
                  icon={faTimes}
                />
              </Form.File.Label>
            ) : (
              <Form.File.Input
                name={fileField.name}
                onChange={handleFileChange}
                // onBlur={fileField.onBlur}
                isInvalid={!!fileMeta.error}
                accept=".pdf,.png,.jpg,.docx,.msg,.zip"
              />
            )}

            <Form.Control.Feedback type="invalid">{fileMeta.error}</Form.Control.Feedback>
            {fileField?.value?.size && !fileMeta.error && returnFileSize(fileField?.value.size) && (
              <Form.Control.Feedback type="valid" className="text-muted float-right">
                Upload Size: {returnFileSize(fileField?.value.size)}
              </Form.Control.Feedback>
            )}
          </Form.File>
        </Form.Group>
      ) : (
        <Form.Group as={Col} xs="12" md="3"></Form.Group>
      )}
      <DisplayNameInput />
    </Row>
  );
};

export default SpaceOwnerInput;
