import { AssetContext } from '@amgen/shared';
import React, { useContext } from 'react';

import './view-doc-content-button.scss';

import AssetActionsMenu from '../asset-actions-menu/asset-actions-menu';

/* eslint-disable-next-line */
export interface ViewDocContentButtonProps {}

export const ViewDocContentButton: React.FC<ViewDocContentButtonProps> = props => {
  const { docSolrAsset } = useContext(AssetContext);
  const { attachment_content } = docSolrAsset.dataAsset!;

  return (
    <AssetActionsMenu.Item actionType={'docContent-btn'} key={`docContent-btn${docSolrAsset.dataAsset?.id}`}>
      <AssetActionsMenu.Item.Name>{`View Attachment-Content`}</AssetActionsMenu.Item.Name>
      <AssetActionsMenu.Item.Popup>
        <AssetActionsMenu.Item.Popup.Heading />
        <AssetActionsMenu.Item.Popup.Body>
          <h3 className="bg-grey px-1">Attachment/Doc Content</h3>
          {attachment_content?.map((contentString, index) => (
            <h4>
              {index + 1}. {contentString}
            </h4>
          ))}
        </AssetActionsMenu.Item.Popup.Body>
      </AssetActionsMenu.Item.Popup>
    </AssetActionsMenu.Item>
  );
};

export default ViewDocContentButton;
