import { faInfoCircle } from '@amgen/assets';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { Alert } from 'react-bootstrap';

import './toast.scss';

import { IToast } from './toast-model';

export interface IToastAlertProps {
  toast: IToast;
  keyName: number | string;
  onToastClose: (toast: IToast) => void;
}

export const ToastAlert: React.FC<IToastAlertProps> = ({ toast, onToastClose, keyName }) => {
  const { current: iconMap } = useRef(
    new Map([
      ['primary', faCheckCircle],
      ['success', faCheckCircle],
      ['info', faInfoCircle],
      ['danger', faInfoCircle],
    ])
  );

  return (
    <Alert
      key={keyName}
      variant={toast.type}
      className={classnames('d-flex toaster', toast.className)}
      onClose={() => onToastClose(toast)}
      dismissible
    >
      <FontAwesomeIcon icon={iconMap.get(toast.type) ?? faInfoCircle} size="lg" />
      <span className="px-3 toaster-message">{toast.message}</span>
    </Alert>
  );
};

export default ToastAlert;
