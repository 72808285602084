import { useWindowSizeListener } from '@amgen/shared';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';

import './navigate-icon.scss';

import { ScrollableCardSectionContext } from '../virtual-container/scrollable-card-context';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface NavigateProps {}

export const NavigateNext: React.FC<NavigateProps> = () => {
  const { scrollRight, scrollLeft, maxScrollableWidth, loading } = useContext(ScrollableCardSectionContext);
  const isMobile = useWindowSizeListener();
  const scrollwidth = isMobile ? 310 : 440;

  return !loading && maxScrollableWidth > scrollLeft ? (
    <Button
      variant="link"
      className="position-absolute bg-white shadow navigate-btn right-navigate-btn"
      onClick={() => scrollRight(+scrollwidth)}
    >
      <FontAwesomeIcon icon={faChevronRight} title="click to scroll right" />
    </Button>
  ) : null;
};

export const NavigatePrev: React.FC<NavigateProps> = () => {
  const { scrollRight, scrollLeft, loading } = useContext(ScrollableCardSectionContext);
  const isMobile = useWindowSizeListener();
  const reverseScrollwidth = isMobile ? -310 : -440;
  return !loading && scrollLeft > 0 ? (
    <Button
      variant="link"
      className="position-absolute bg-white shadow navigate-btn left-navigate-btn"
      onClick={() => scrollRight(reverseScrollwidth)}
    >
      <FontAwesomeIcon icon={faChevronLeft} title="click to scroll left" />
    </Button>
  ) : null;
};

export default { NavigateNext, NavigatePrev };
