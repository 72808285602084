import { IProductAttribute, IStudyAttribute } from '@amgen/core';

import { IKnowledgeGraphConfig } from '../models';

export const PRODUCT_ATTRIBUTE_NAMES: NonNullable<{ [key in IProductAttribute]?: string }> = {
  amgenOtherNames: 'Other Names',
  amgNumber: 'AMG Number',
  description: 'Description',
  drugBank: 'Drug Bank',
  drugName: 'Drug Name',
  imageUrl: 'Image URL',
  medicalUse: 'Medical Use',
  medlinePlus: 'Medline Plus',
  product: 'Product',
  relatedProduct: 'Related Products',
  roles: 'Roles',
  routesOfAdministration: 'Routes of Administration',
  studyInformation: 'Study Information',
  therapeuticAreas: 'Therapeutic Areas',
  tradeNames: 'Trade Names',
  biosimilar: 'Biosimilar',
  // catalystLink: 'Catalyst Link',
  device: 'Device',
  dosage: 'Dosage',
  mechanismOfAction: 'Mechanism Of Action',
  modality: 'Modality',
  modalityType: 'Modality Type',
  target: 'Target',
  prescribingInformationEU: 'Prescribing Information EU',
  prescribingInformationUS: 'Prescribing Information US',
  country: 'Country',
};

export const KNOWLEDGE_PANEL_PRODUCT_ATTRIBUTES: IProductAttribute[] = [
  // 'product',
  'amgNumber',
  'drugName',
  'tradeNames',
  'medicalUse',
  'routesOfAdministration',
  'therapeuticAreas',
  'roles',
  'biosimilar',
  // 'catalystLink',
  'device',
  'dosage',
  'mechanismOfAction',
  'modality',
  'modalityType',
  'target',
  'prescribingInformationEU',
  'prescribingInformationUS',
  'country',
];

export const STUDY_ATTRIBUTE_NAMES: { [key in IStudyAttribute]?: string } = {
  // deliverable: 'Deliverable',
  // number: 'Study Number',
  // product: 'Product',
  // title: 'Title',

  studyconditions: 'Study Conditions',
  studydescription: 'Title',
  studynumber: 'Study Number',
  studyphase: 'Study Phase',
  studytriallink: 'Study Trial Link',
};

export const KNOWLEDGE_PANEL_STUDY_ATTRIBUTES: IStudyAttribute[] = [
  'studynumber',
  'studydescription',
  'studyphase',
  'studyconditions',
  // 'studytriallink',
];

export const KNOWLEDGE_GRAPH_PRODUCT_DISPLAY_ATTRIBUTES: IProductAttribute[] = [
  'product',
  'amgNumber',
  'medicalUse',
  'routesOfAdministration',
  'studyInformation',
  'therapeuticAreas',
  'biosimilar',
  'device',
  'dosage',
  'mechanismOfAction',
  'modality',
  'modalityType',
  'target',
  'country',
];

export const KNOWLEDGE_GRAPH_STUDY_DISPLAY_ATTRIBUTES: IStudyAttribute[] = [
  'studynumber',
  'studydescription',
  'studyphase',
  'studyconditions',
  'studytriallink',
];

// Describes nodes that can be clicked to display
// extra informatino
// category 0: product
// category 1: study
// category 2: other

export const KNOWLEDGE_GRAPH_CONFIG: IKnowledgeGraphConfig = {
  amgenOtherNames: {
    linkName: 'also known as',
  },
  amgNumber: {
    linkName: 'has AMG number',
  },
  atcCode: {
    linkName: 'prescribed using',
  },
  casNumber: {
    linkName: 'researched with',
  },
  drugBank: {
    linkName: 'has information',
  },
  marketNames: {
    linkName: 'sold as',
  },
  medicalUse: {
    linkName: 'used for',
  },
  medlinePlus: {
    linkName: 'used for advice on',
  },
  product: {
    nodeSize: 70,
    nodeStyle: {
      color: '#0063c3',
    },
    category: 0,
    label: { position: 'inside', color: 'white' },
    linkName: 'has studies',
    lineColor: '#00BCE4',
  },
  relatedProduct: {
    linkName: 'is related to',
  },
  roles: {
    category: 2,
    nodeStyle: { color: '#00BCE4' },
    hasMoreInfo: true,
    displayName: 'Roles',
    linkName: 'has roles',
    linkSymbols: ['arrow', 'none'],
  },
  routesOfAdministration: {
    linkName: 'is through',
  },
  sideEffects: {
    linkName: 'can cause',
  },
  studyInformation: {
    category: 1,
    nodeStyle: { color: '#88C765' },
    hasMoreInfo: true,
    displayName: 'Study Information',
    linkName: 'has studies',
    linkSymbols: ['arrow', 'none'],
    lineColor: '#00BCE4',
    linkSymbolSize: 20,
  },
  biosimilar: {
    linkName: 'has ',
  },
  device: {
    linkName: 'is administered through',
  },
  dosage: {
    linkName: 'administered dosage',
  },
  mechanismOfAction: {
    linkName: 'has mechanism of',
  },
  modality: {
    linkName: 'has modality',
  },
  modalityType: {
    linkName: 'has modality type',
  },
  target: {
    linkName: 'targets',
  },
  country: {
    linkName: 'is manufactured in',
  },
  therapeuticAreas: {
    linkName: 'has therapeutic areas',
  },
};

export const KNOWLEDGE_GRAPH_STUDY_CONFIG: IKnowledgeGraphConfig = {
  product: {
    nodeSize: 70,
    nodeStyle: { color: '#0063c3' },
    category: 0,
    label: { position: 'inside', color: 'white' },
    linkName: 'has studies',
  },
  studyInformation: {
    nodeStyle: { color: '#88C765' },
    hasMoreInfo: true,
    displayName: 'Study Information',
  },
};
