import { CLUSTER_SEARCH_FEATURE_ACTIONS, MatomoContext, SEARCH_APP_FEATURE_LIST } from '@amgen/shared';
import classnames from 'classnames';
import React, { useContext } from 'react';
import { Badge, Row } from 'react-bootstrap';

import './topics-list.scss';

import { ClusterSearchContext } from '../cluster-search-context-provider/cluster-search-context';

/* eslint-disable-next-line */
export interface TopicsListProps {}

export function TopicsList(props: TopicsListProps) {
  const { clusters, selectedCluster, setSelectedCluster } = useContext(ClusterSearchContext);
  const { trackApplicationFeature } = useContext(MatomoContext);

  return (
    <Row noGutters className="cluster-search-cluster-bar mx-2">
      {clusters?.map(cluster => (
        <Badge
          className={classnames(
            'd-inline-flex align-items-center cluster-button mb-1 px-3 cursor-pointer',
            selectedCluster?.clusterName === cluster.clusterName ? 'active text-primary' : 'bg-light link-grey border'
          )}
          onClick={() => {
            setSelectedCluster(cluster);
            trackApplicationFeature(
              SEARCH_APP_FEATURE_LIST.CLUSTER_SEARCH,
              CLUSTER_SEARCH_FEATURE_ACTIONS.TOPIC_SELECTED
            );
          }}
          title={`cluster: ${cluster.clusterName}`}
        >
          <span className="text-overflow-ellipsis">{cluster.clusterName}</span>
        </Badge>
      ))}
    </Row>
  );
}

export default TopicsList;
