import React from 'react';
import { Modal } from 'react-bootstrap';

import './footer.scss';

/* eslint-disable-next-line */
export interface FooterProps {}

export const Footer: React.FC<FooterProps> = ({ children }) => {
  return <Modal.Body>{children}</Modal.Body>;
};

export default Footer;
