import { createContext } from 'react';

import { INews } from './models';

export interface INewsContext {
  news: INews[];
  loading?: boolean;
}

export const NewsContext = createContext<INewsContext>(null!);
