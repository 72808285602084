import {
  useConvertionalMultiSearchQuery,
  useGetConsolidatedAPIQuery,
  useGetQueryFeedbackQuery,
  useGetQueryGenerativeAIList,
  useGetSearchCountQuery,
  useGetSearchObjectsAppsQuery,
  useGetSearchObjectsQuery,
  useMultiSearchQuery,
  useSearchQuery,
} from '@amgen/api';
import { DEFAULT_SEARCH_QUERY, Direction, IAsset, IAssetAttribute, SortField, useEnvironment } from '@amgen/core';
import { format, subYears } from 'date-fns';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import './search-context-provider.scss';

import { AppContext } from '../../config';
import { SEARCH_HIERARCHICAL_FACETS } from '../../config/hierarchical-facets';
import { useWindowSizeListener } from '../../hooks/window-size-listener';
import {
  FACET_FEATURE_ACTIONS,
  MatomoContext,
  PAGE_LOAD_FEATURE_ACTIONS,
  RESULT_VIEW_CONFIGURATIONS_ACTIONS,
  SEARCH_APP_FEATURE_LIST,
} from '../../matomo';
import { ResultViewType } from '../../models';
import { useToast } from '../../toast';
import { FacetContext } from '../facet-context-provider/facet-context';
import { FacetsPanelContext } from '../facets-panel-provider/facets-panel-context';
import { ISearchState, SearchContext } from './search-context';

export interface SearchContextProviderProps {
  query?: string;
  trackMatomoPage?: string;
  filters?: string[];
  isExpandable?: boolean;
}

enum ApplicationName {
  KMI = 'KMI',
  EEA = 'EEA',
  OpsKM = 'OpsKM',
  Catalyst = 'Catalyst',
  DVIEW = 'DVIEW',
}

//[todo] cleanup this context provider
export const SearchContextProvider: React.FC<SearchContextProviderProps> = ({
  children,
  query = '',
  trackMatomoPage,
  filters,
  isExpandable,
}) => {
  const history = useHistory();
  const location = useLocation();
  const searchPath = ['/reports', '/surveys', '/dashboard'].includes(location.pathname) ? location.pathname : '/';
  const {
    pageNo,
    setResultCount,
    setPageNo,
    viewType,
    setViewType,
    sortOption,
    setSortOption,
    sortOrder,
    setSortOrder,
    itemsPerPage,
    setItemsPerPage,
    setCustomSourceActive,
    isCustomSourceActive,
    setSelectedCustomSourceName,
    currentSource,
    setCurrentSource,
    backButtonClicked,
    landingParent,
    resultCount,
    selectedCustomSourceName,
    // horizonCompanyCode,
  } = useContext(AppContext);
  const { path, url } = useRouteMatch();

  const { appliedFilters, setAppliedFilters, setDropdownSearch, setCustomFilterSearch } = useContext(FacetContext);
  const toast = useToast();
  const { selectedFilters, setSelectedFilters } = useContext(FacetsPanelContext);
  const { trackSiteSearch, trackCustomDimensions, dimensions, trackApplicationFeature } = useContext(MatomoContext);
  const [isSliderShown, setIsSliderShown] = useState(false);
  const { requestHandler } = useEnvironment();
  const appName: ApplicationName = sessionStorage.getItem('appName')
    ? ApplicationName[sessionStorage.getItem('appName')!]
    : ApplicationName.EEA;
  const [userPseudoId, setUserPseudoId] = useState('');
  const [followUpQuery, setFollowUpQuery] = useState('');
  const [expandPanel, setExpandPanel] = useState(false);
  const [multiTurnBinaryFeedback, setMultiTurnBinaryFeedback] = useState('');
  const [conversationalQnAInvoked, setConversationalQnAInvoked] = useState(false);
  const [conversationalQnAEngaged, setConversationalQnAEngaged] = useState(false);
  const [finalFollowupQuery, setFinalFollowupQuery] = useState('');
  const [multiTurnAPICompleted, setMultiTurnAPICompleted] = useState(true);
  const [multiTurnConversationList, setMultiTurnConversationList] = useState<any[]>([]);
  const [multiSummaryFilterApplied, setMultiSummaryFilterApplied] = useState(false);
  const [count, setCount] = useState(2);
  const [selectedTopFilter, setSelectedTopFilter] = useState('All');
  const [isAllSelected, setAllSelected] = useState(true);
  const [isIframeRendered, setIsIframeRendered] = useState(true);
  const [isUserFeedbackCompleted, setIsUserFeedbackCompleted] = useState(false);
  const [recommendedResultCount, setRecommendedResultCount] = useState(0);
  const [filterRemoved, setFilterRemoved] = useState(false);
  const [topResultCount, setTopResultCount] = useState(0);
  const [DetailedFeedbackFlag, setFlag] = useState(false);
  const [selectedMood, selectMood] = useState('none');
  const [openFeedbackFromSearchQuery, setOpenFeedbackFromSearchQuery] = useState(false);
  const [typeOfFeedback, setTypeOfFeedback] = useState('');
  const [selectedAppMood, selectAppMood] = useState('none');
  const [showFeedbackBtn, setShowFeedbackBtn] = useState<boolean>(true);
  const sourceType = isCustomSourceActive ? 'all' : backButtonClicked ? 'external' : currentSource;
  const selectedSources: string[] = [];
  let duplicateArray: string[] = [];
  let otherDataSources: string[] = [];
  const [selectedParentFilter, setSelectedParentFilter] = useState('Internal');
  const [modifiedFilters, setModifiedFilters] = useState(filters);
  const [clearAllFilters, setClearAllFilters] = useState(false);
  const [spotLightData, setSpotLightData] = useState([]);
  const [recommendedTopData, setRecommendedTopData] = useState<any[]>([]);
  const [isApplicationGenerativeAIVariable, setIsApplicationGenerativeAIVariable] = useState(false);
  const [genAIDeeplinkData, setGenAIDeeplinkData] = useState<any[]>([]);
  const [genAISpotlightData, setGenAISpotlightData] = useState<any[]>([]);
  const abTestingGroup = JSON.parse(sessionStorage.getItem('abTestingGroup') as string);
  const currentCustomsource = sessionStorage.getItem('current-source-selected');
  const [searchResultsData, setSearchResultsData] = useState<any[]>([]);
  const [isSearchAPIcompleted, setIsSearchAPIcompleted] = useState(false);
  // const DefaultQuery = [
  //   '( *:* AND -content_source:SharePoint) OR (content_source:SharePoint AND source_filename:(*aspx OR *ASPX))',
  // ];
  // const sharepointArray = ['(content_source:SharePoint AND source_filename:(*aspx OR *ASPX))'];

  useEffect(() => {
    setCount(15);
    window.onbeforeunload = function (event) {
      appliedFilters.clear();
      selectedFilters.clear();
    };
    // appliedFilters.removeAll('doc_nav_prefix');
    //setCurrentSource('internal');
    // setCustomFilterSearch?.(false);
    window.scrollTo(0, 0);
    setDropdownSearch?.(false);
    setIsUserFeedbackCompleted(false);
    setRecommendedTopData([]);
    setGenAIDeeplinkData([]);
  }, [query]);

  const categoryArray: string[] = [];
  Object.keys(appliedFilters.filters!).forEach(function (key, index) {
    categoryArray.push(key);
  });

  Object.entries(appliedFilters.filters!)
    .filter(([category, _]) => categoryArray.includes(category))

    .map(([category, values]) =>
      [...values!].map(v => {
        if (category === 'doc_nav_prefix') {
          duplicateArray.push(v.split('/')?.[1]);
        } else {
          otherDataSources.push(v);
        }

        return duplicateArray;
      })
    );

  otherDataSources = [...new Set(duplicateArray)];
  duplicateArray = [...new Set(duplicateArray)];

  useEffect(() => {
    if (sessionStorage.getItem('externalFilter') === 'All') {
      //setAppliedFilters(appliedFilters.add('0/Ops KM Assets', 'doc_nav_prefix'));
      //setAppliedFilters(appliedFilters.add('0/CDOCS', 'doc_nav_prefix'));
      setAllSelected(false);
    } else if (sessionStorage.getItem('externalFilter')) {
      setAppliedFilters(appliedFilters.add(`0/${sessionStorage.getItem('externalFilter')}`, 'doc_nav_prefix'));
      setAllSelected(false);
    }

    if (sessionStorage.getItem('externalAssetType')) {
      setAppliedFilters(appliedFilters.add(sessionStorage.getItem('externalAssetType')!, 'asset_type'));
      setAllSelected(false);
    }

    if (sessionStorage.getItem('externalFunction')) {
      setAppliedFilters(appliedFilters.add(sessionStorage.getItem('externalFunction')!, 'function'));
      setAllSelected(false);
    }

    if (sessionStorage.getItem('externalSite')) {
      setAppliedFilters(appliedFilters.add(`1/SharePoint/${sessionStorage.getItem('externalSite')}`, 'doc_nav_prefix'));
      setAllSelected(false);
    }
  }, []);

  const acronymTitleVariables = useMemo(
    () => ({
      query,
      filters: abTestingGroup ? [`myamgen_object_name:"Acronyms"`] : ['object_name:Acronyms'],
      count: 2,
      start: 0,
      sort: 'created_on',
      appName,
      solrHandler: '/amgen-title-search-select',
      order: Direction.ASC,
    }),
    [appName, query, count]
  );

  const today = format(new Date(), "yyyy-MM-dd'T'HH:mm").toString() + ':00Z';

  const eventVariables = useMemo(
    () => ({
      query,
      filters: ['object_name:Events', 'end_date:[' + today + ' TO *]'],
      count: 2,
      appName,
      solrHandler: '/spotlight-search-select',
    }),
    [appName, query]
  );

  const appVariables = useMemo(
    () => ({
      query,
      filters: [`object_name:"Apps"`],
      count: 2,
      start: 0,
      sort: 'created_on',
      appName,
      solrHandler: '/amgen-title-text-search-select',
      order: Direction.ASC,
    }),
    [appName, query]
  );

  const getFeedbackVariables = useMemo(
    () => ({
      searchTerm: query,
      applicationName: appName,
    }),
    [appName, query]
  );

  const getConsolidatedAPIVariables = useMemo(
    () => ({
      applicationName: appName,
      start: 0,
      noShortContent: true,
      noSpotlight: false,
      cosine: true,
      query,
      page: 0,
      bertCount: 10,
      keywordCount: 7,
      sort: SortField.RELEVANCY,
      order: Direction.DESC,
      addPersonalizationDetails: false,
      filters: [],
      count: 5,
    }),
    [appName, query]
  );

  const getGenerativeAIVariables = useMemo(
    () => ({
      query: query,
      content: recommendedTopData
        ?.slice(0, 3)
        ?.map(i => `Title:${i.title}\nContent:${i.content}`)
        .flat(2)
        .join('\n\n'),
      qaNum: 3,
      is_application: isApplicationGenerativeAIVariable,
    }),
    [query, JSON.stringify(recommendedTopData)]
  );

  //const PassArray = [sharepointArray + ' OR ' + filters[0]];
  const before2yearsDate = format(subYears(new Date(), 2), 'yyyy-MM-dd') + 'T00:00:00Z';

  useEffect(() => {
    if (
      !query.includes('id:') &&
      !isCustomSourceActive &&
      appName === ApplicationName.EEA &&
      duplicateArray.length === 0 &&
      !abTestingGroup
    ) {
      const tempFilters = filters;
      tempFilters &&
        tempFilters.push(
          `( *:* AND -content_source:SharePoint) OR (content_source:SharePoint AND source_filename:(*aspx OR *ASPX OR *pdf OR *PDF  OR  *doc  OR  *DOC  OR  *docx  OR  *DOCX  OR  *docm  OR  *DOCM  OR  *pptm  OR  *PPTM  OR  *ppt  OR  *PPT  OR  *pptx  OR  *PPTX  OR  *Pdf)  AND doc_last_modified:[${
            before2yearsDate + ' TO NOW'
          }])`
        );
      setModifiedFilters(tempFilters);
    } else if (
      appName === ApplicationName.KMI ||
      appName === ApplicationName.OpsKM ||
      appName === ApplicationName.Catalyst ||
      appName === ApplicationName.DVIEW ||
      isCustomSourceActive ||
      query.includes('id:')
    ) {
      setModifiedFilters(filters);
    }
  }, [sourceType]);

  useEffect(() => {
    if (
      ((duplicateArray.length === 1 && !duplicateArray.includes('SharePoint')) || duplicateArray.length > 1) &&
      !query.includes('id:') &&
      !isCustomSourceActive &&
      appName === ApplicationName.EEA &&
      !abTestingGroup
    ) {
      const tempFilters = filters;
      tempFilters &&
        tempFilters.push(
          `( *:* AND -content_source:SharePoint) OR (content_source:SharePoint AND source_filename:(*aspx OR *ASPX OR *pdf OR *PDF  OR  *doc  OR  *DOC  OR  *docx  OR  *DOCX  OR  *docm  OR  *DOCM  OR  *pptm  OR  *PPTM  OR  *ppt  OR  *PPT  OR  *pptx  OR  *PPTX  OR  *Pdf)  AND doc_last_modified:[${
            before2yearsDate + ' TO NOW'
          }])`
        );
      setModifiedFilters(tempFilters);
    } else if (
      duplicateArray.length === 1 &&
      duplicateArray.includes('SharePoint') &&
      appName === ApplicationName.EEA
    ) {
      setModifiedFilters(filters);
    } else if (duplicateArray.length === 0 && clearAllFilters && appName === ApplicationName.EEA && !abTestingGroup) {
      const tempFilters = filters;
      tempFilters &&
        tempFilters.push(
          `( *:* AND -content_source:SharePoint) OR (content_source:SharePoint AND source_filename:(*aspx OR *ASPX OR *pdf OR *PDF  OR  *doc  OR  *DOC  OR  *docx  OR  *DOCX  OR  *docm  OR  *DOCM  OR  *pptm  OR  *PPTM  OR  *ppt  OR  *PPT  OR  *pptx  OR  *PPTX  OR  *Pdf)  AND doc_last_modified:[${
            before2yearsDate + ' TO NOW'
          }])`
        );
      setModifiedFilters(tempFilters);
    } else if (
      appName === ApplicationName.KMI ||
      appName === ApplicationName.OpsKM ||
      appName === ApplicationName.Catalyst ||
      appName === ApplicationName.DVIEW ||
      isCustomSourceActive ||
      query.includes('id:') ||
      clearAllFilters
    ) {
      setModifiedFilters(filters);
    } else if (otherDataSources.length > 0) {
      setModifiedFilters(filters);
    }
  }, [filters, clearAllFilters]);

  const variables = useMemo(
    () => ({
      solrHandler: appName === 'DVIEW' ? '/dview-search-select' : '/search-select',
      query,
      filters: modifiedFilters,
      start: 0,
      page: pageNo - 1,
      count: itemsPerPage,
      sort: sortOption,
      order: sortOrder,
      includeDocs: true,
      noShortContent: false,
      addPersonalizationDetails: true,
      appName,
      requestHandler,
      userType: sessionStorage.getItem('employeeType') || '',
      sourceType,
    }),
    [appName, filters, modifiedFilters, itemsPerPage, pageNo, query, requestHandler, sortOption, sortOrder, sourceType]
  );

  const getConversationalSearchPseudoIdVariables = useMemo(
    () => ({
      applicationName: appName,
      query: followUpQuery === '' ? query : followUpQuery,
      filters: modifiedFilters,
      userPseudoId: userPseudoId === '' ? '' : userPseudoId,
      userType: sessionStorage.getItem('employeeType') || '',
    }),
    [appName, filters, modifiedFilters, followUpQuery, query]
  );

  const getCountVariables = useMemo(
    () => ({
      solrHandler: appName === 'DVIEW' ? '/dview-search-select' : '/search-select',
      query,
      filters: modifiedFilters,
      applicationName: appName,
      sourceType: ['all', 'internal', 'external'],
      userType: sessionStorage.getItem('employeeType') || '',
    }),
    [query, filters, modifiedFilters, appName]
  );

  const { data: countData } = useGetSearchCountQuery({
    variables: getCountVariables,
    skip: abTestingGroup && currentCustomsource !== 'Contract Search',
  });

  const updatemultiTurnList = newItems => {
    // Combine the existing list with the last item from the conversationSearch API
    const updatedItems = [...multiTurnConversationList, ...newItems.slice(-1)];
    setMultiTurnConversationList(updatedItems);
  };

  const updatemultiTurnListFilterApplied = () => {
    // Remove the last item from the list of conversationSearch
    multiTurnConversationList.splice(-1, 1);
    setMultiTurnConversationList(multiTurnConversationList);
  };

  useEffect(() => {
    setPageNo(1);
    if (abTestingGroup) {
      setMultiTurnAPICompleted(false);
      setMultiSummaryFilterApplied(true);
      updatemultiTurnListFilterApplied();
    }
    setModifiedFilters(filters);
  }, [JSON.stringify(appliedFilters)]);

  const startTime = new Date().getTime();

  const { data: multiSearchData, loading: multiSearchLoader } = useMultiSearchQuery({
    variables,
    fetchPolicy: 'no-cache',
    skip:
      followUpQuery !== '' || !abTestingGroup || currentCustomsource === 'Contract Search' || location.pathname !== '/',
    onCompleted: () => {
      setSearchResultsData(multiSearchData?.search?.docs ?? []);
      setResultCount(multiSearchData?.search?.resultCount ?? 0);
      setIsSearchAPIcompleted(true);
      setMultiSummaryFilterApplied(false);
      trackApplicationFeature(
        SEARCH_APP_FEATURE_LIST.PAGE_LOAD_TRACKING,
        PAGE_LOAD_FEATURE_ACTIONS.SEARCH_RESULTS,
        (new Date().getTime() - startTime) / 1000
      );
      if (trackMatomoPage) {
        trackSiteSearch(query, trackMatomoPage, multiSearchData?.search?.resultCount ?? 0);
        trackCustomDimensions(pageNo, dimensions.PAGINATION);
        trackCustomDimensions(sortOption, dimensions.SORT);
      }
      if (!multiSearchData?.search?.resultCount && !/^".*"$/.test(query)) {
        const params = new URLSearchParams();
        if (query?.includes('id:')) {
          const modQuery = query.split('id:');
          setCurrentSource('all');
          params.append('q', `id:"${modQuery[1] ?? ''}"`);
        } else {
          //Neha - add quotes in single word search term - after 0 results match initially
          if (query.split(' ').length > 1) {
            params.append('q', query !== DEFAULT_SEARCH_QUERY ? `"${query}"` : '');
          } else {
            //Don't add quotes in single word search term - after 0 results match initially
            params.append('q', query !== DEFAULT_SEARCH_QUERY ? `${query}` : '');
          }
        }
        // params.append('q', query !== DEFAULT_SEARCH_QUERY ? `"${query}"` : '');

        history.push({ pathname: searchPath, search: params.toString() });
      }
    },
    onError: () => {
      toast.danger({
        message: 'Internal Server Error, please try again later',
        timeout: 10000,
      });
      setSearchResultsData([]);
    },
  });

  const { data: searchQueryData, loading } = useSearchQuery({
    variables,
    //fetchPolicy: 'no-cache',
    skip: (abTestingGroup && currentCustomsource !== 'Contract Search') || location.pathname !== '/',
    onCompleted: () => {
      setResultCount(searchQueryData?.search?.resultCount ?? 0);
      setSearchResultsData(searchQueryData?.search?.docs ?? []);
      setIsSearchAPIcompleted(true);
      trackApplicationFeature(
        SEARCH_APP_FEATURE_LIST.PAGE_LOAD_TRACKING,
        PAGE_LOAD_FEATURE_ACTIONS.SEARCH_RESULTS,
        (new Date().getTime() - startTime) / 1000
      );
      if (trackMatomoPage) {
        trackSiteSearch(query, trackMatomoPage, searchQueryData?.search?.resultCount ?? 0);
        trackCustomDimensions(pageNo, dimensions.PAGINATION);
        trackCustomDimensions(sortOption, dimensions.SORT);
      }
      if (!searchQueryData?.search?.resultCount && !/^".*"$/.test(query)) {
        const params = new URLSearchParams();
        if (query?.includes('id:')) {
          const modQuery = query.split('id:');
          setCurrentSource('all');
          params.append('q', `id:"${modQuery[1] ?? ''}"`);
        } else {
          //Neha - add quotes in single word search term - after 0 results match initially
          if (query.split(' ').length > 1) {
            params.append('q', query !== DEFAULT_SEARCH_QUERY ? `"${query}"` : '');
          } else {
            //Don't add quotes in single word search term - after 0 results match initially
            params.append('q', query !== DEFAULT_SEARCH_QUERY ? `${query}` : '');
          }
        }
        // params.append('q', query !== DEFAULT_SEARCH_QUERY ? `"${query}"` : '');

        history.push({ pathname: searchPath, search: params.toString() });
      }
    },
    onError: () => {
      toast.danger({
        message: 'Internal Server Error, please try again later',
        timeout: 10000,
      });
      setSearchResultsData([]);
    },
  });

  const { data: conversationalSearchData } = useConvertionalMultiSearchQuery({
    variables: getConversationalSearchPseudoIdVariables,
    //fetchPolicy: 'no-cache',
    skip:
      !abTestingGroup ||
      multiTurnAPICompleted ||
      location.pathname !== '/' ||
      currentCustomsource === 'Contract Search',
    onCompleted: () => {
      if (followUpQuery !== '') {
        setResultCount(conversationalSearchData?.answerSearch?.dataAssetCollection?.resultCount ?? 0);
        setSearchResultsData(
          conversationalSearchData?.answerSearch?.dataAssetCollection?.docs ?? searchResultsData ?? []
        );
      }
      setMultiTurnAPICompleted(true);

      if (conversationalSearchData?.answerSearch?.conversations) {
        updatemultiTurnList(conversationalSearchData?.answerSearch?.conversations ?? []);
      }
      if (conversationalSearchData?.answerSearch?.conversations?.length === 0 && followUpQuery === '') {
        setMultiTurnConversationList([]);
      }
      if (
        conversationalSearchData?.answerSearch?.conversations &&
        conversationalSearchData?.answerSearch?.conversations?.length > 0 &&
        conversationalSearchData?.answerSearch?.conversations[0]?.citations?.length === 0 &&
        followUpQuery === ''
      ) {
        setMultiTurnConversationList([]);
      }
      if (userPseudoId === '' && conversationalSearchData?.answerSearch?.userPseudoId) {
        setUserPseudoId(conversationalSearchData?.answerSearch?.userPseudoId);
      }
      setMultiSummaryFilterApplied(false);
    },
    onError: () => {
      toast.danger({
        message: 'Internal Server Error, please try again later',
        timeout: 10000,
      });
      if (followUpQuery === '') {
        setMultiTurnConversationList([]);
        setMultiTurnAPICompleted(true);
      }
    },
  });

  // useeffect function to call consolidated api for every search term.
  useEffect(() => {
    if (appName === ApplicationName.EEA && query !== '*:*' && !abTestingGroup) {
      refetch();
    }
  }, [query]);

  const { refetch, data: consolidatedData } = useGetConsolidatedAPIQuery({
    fetchPolicy: 'no-cache',
    variables: getConsolidatedAPIVariables,
    skip:
      query === '*:*' ||
      abTestingGroup ||
      appName === ApplicationName.KMI ||
      appName === ApplicationName.OpsKM ||
      appName === ApplicationName.Catalyst ||
      appName === ApplicationName.DVIEW,
  });

  const { data: eventData } = useGetSearchObjectsQuery({
    variables: eventVariables,
    skip:
      query === '*:*' ||
      currentSource === 'external' ||
      abTestingGroup ||
      appName === ApplicationName.KMI ||
      appName === ApplicationName.OpsKM ||
      appName === ApplicationName.Catalyst ||
      appName === ApplicationName.DVIEW,
  });

  const { data: acronymTitle } = useGetSearchObjectsQuery({
    variables: acronymTitleVariables,
    // skip: query === '*:*' || currentSource === 'external' || currentSource === 'all',
    skip:
      query === '*:*' ||
      viewType === ResultViewType.Table ||
      appName === ApplicationName.OpsKM ||
      appName === ApplicationName.Catalyst ||
      appName === ApplicationName.DVIEW ||
      appName === ApplicationName.KMI,
  });

  const { data: appsData } = useGetSearchObjectsAppsQuery({
    variables: appVariables,
    fetchPolicy: 'no-cache',
    skip:
      query === '*:*' ||
      currentSource === 'external' ||
      appName === ApplicationName.KMI ||
      appName === ApplicationName.OpsKM ||
      appName === ApplicationName.Catalyst ||
      appName === ApplicationName.DVIEW ||
      !abTestingGroup,
  });

  const { data: searchFeedbackData } = useGetQueryFeedbackQuery({
    variables: getFeedbackVariables,
    skip: query === '*:*',
  });

  const results: IAsset[] = searchResultsData ?? [];

  // prev condition  (recommendedTopData.length <= 2 && genAIDeeplinkData.length === 0) ||
  const { data: generativeAIData, loading: generativeAILoading } = useGetQueryGenerativeAIList({
    variables: getGenerativeAIVariables,
    skip:
      query === '*:*' ||
      abTestingGroup ||
      recommendedTopData.length < 1 ||
      getGenerativeAIVariables.content === '' ||
      appName === ApplicationName.KMI ||
      appName === ApplicationName.OpsKM ||
      appName === ApplicationName.Catalyst ||
      appName === ApplicationName.DVIEW,
    // horizonCompanyCode,
  });

  useEffect(() => {
    sessionStorage.setItem(
      'feedbackData',
      JSON.stringify({
        already_reported: searchFeedbackData?.getQueryFeedback?.already_reported,
        days_from_last_feedback: searchFeedbackData?.getQueryFeedback?.days_from_last_feedback,
        hideSearchQuery: false,
      })
    );
    if (userPseudoId !== '') {
      setUserPseudoId('');
    }
    setFollowUpQuery('');
    setMultiTurnConversationList([]);
    setMultiTurnAPICompleted(false);
    setSearchResultsData([]);
    setFinalFollowupQuery('');
    setIsSearchAPIcompleted(false);
    setConversationalQnAEngaged(false);
  }, [query]);

  useEffect(() => {
    localStorage.removeItem('multiTurnBinaryFeedback');
  }, [query, followUpQuery, appName]);

  const isMobile = useWindowSizeListener(() => setViewType(ResultViewType.List));
  const pageOptionsCount = Math.min(Math.ceil((searchQueryData?.search?.resultCount ?? 0) / 10), isMobile ? 2 : 10);

  /// removes parent key from the value of the child keys
  const removeParentFilter = useCallback(
    (depth: number, parentCategory: IAssetAttribute, val: string) => {
      // filter is compared here, e.g 0/India/Andhra/Vizag is prefix_location key, but city is vizag (depth 3)
      // TODO: This is added to fix build issue. Remove this later.
      const filter = [...(appliedFilters.filters as any)?.[parentCategory]].find(
        loc => loc.split('/')[depth + 1] === val
      );
      // if filter exists we remove the 0/India/Andhra/Vizag key from  prefix_location
      if (filter) {
        setAppliedFilters(appliedFilters.remove(filter, parentCategory));
      }
    },
    [appliedFilters, setAppliedFilters]
  );

  // **DO NOT REMOVE THE COMMNETED CODE**
  // const onRemoveAppliedFilters = useCallback(
  //   (val: string, category: IAssetAttribute) => {
  //     setPageNo(1);
  //     setAppliedFilters(appliedFilters.remove(val, category));
  //     //setSelectedTopFilter('');
  //     setFilterRemoved(true);
  //     Object.keys(SEARCH_HIERARCHICAL_FACETS).forEach(key => {
  //       // checks wether category is child of hierarchal facets i.e (city is  child category of prefix_location)
  //       // depth shows 0/depth1/depth2/depth3
  //       const depth = SEARCH_HIERARCHICAL_FACETS[key as IAssetAttribute]?.indexOf(category) ?? -1;
  //       if (depth !== -1) {
  //         removeParentFilter(depth, key as IAssetAttribute, val);
  //       }
  //     });
  //   },
  //   [appliedFilters, removeParentFilter, setAppliedFilters, setPageNo]
  // );

  const onRemoveAppliedFilters = useCallback(
    (val: string, category: IAssetAttribute) => {
      setPageNo(1);
      // setAppliedFilters(appliedFilters.remove(val, category));  Neha - moved this line in else
      setFilterRemoved(true);

      //Neha - added this conditon to fix the issue when we removing the filter form chip, results were not getting filterred
      if (appliedFilters.count == 1) {
        // setAppliedFilters(appliedFilters.clear());
        // setSelectedFilters(selectedFilters.clear());
        onClearAllFilters();
      } else {
        setAppliedFilters(appliedFilters.remove(val, category));
      }

      Object.keys(SEARCH_HIERARCHICAL_FACETS).forEach(key => {
        // checks wether category is child of hierarchal facets i.e (city is  child category of prefix_location)
        // depth shows 0/depth1/depth2/depth3
        const depth = SEARCH_HIERARCHICAL_FACETS[key as IAssetAttribute]?.indexOf(category) ?? -1;
        if (depth !== -1) {
          removeParentFilter(depth, key as IAssetAttribute, val);
        }
      });
    },
    [appliedFilters, removeParentFilter, setAppliedFilters, setPageNo]
  );

  const onViewTypeChange = useCallback(
    (viewType: ResultViewType) => {
      setViewType(viewType);
      trackApplicationFeature(
        SEARCH_APP_FEATURE_LIST.RESULT_VIEW_CONFIGURATIONS,
        RESULT_VIEW_CONFIGURATIONS_ACTIONS.RESULT_VIEW_STYLE
      );
    },
    [setViewType, trackApplicationFeature]
  );

  const onPageSort = useCallback(
    (option: SortField) => {
      setSortOption(option);
      setPageNo(1);
      trackCustomDimensions(option, dimensions.SORT);
      trackApplicationFeature(
        SEARCH_APP_FEATURE_LIST.RESULT_VIEW_CONFIGURATIONS,
        RESULT_VIEW_CONFIGURATIONS_ACTIONS.RESULT_VIEW_SORTING
      );
    },
    [dimensions.SORT, setPageNo, setSortOption, trackApplicationFeature, trackCustomDimensions]
  );

  const onItemsPerPageChange = useCallback(
    (size: number) => {
      setItemsPerPage(size);
      setPageNo(1);
      trackApplicationFeature(
        SEARCH_APP_FEATURE_LIST.RESULT_VIEW_CONFIGURATIONS,
        RESULT_VIEW_CONFIGURATIONS_ACTIONS.RESULT_VIEW_RESULTS_PER_PAGE
      );
    },
    [setItemsPerPage, setPageNo, trackApplicationFeature]
  );

  const onClearAllFilters = useCallback(() => {
    if (isCustomSourceActive) {
      //setCustomSourceActive(false);
      setSelectedCustomSourceName('All Sources');
    }
    setSelectedTopFilter('All');
    setPageNo(1);
    setAppliedFilters(appliedFilters.clear());
    setSelectedFilters(selectedFilters.clear());
    setSortOption(SortField.RELEVANCY);
    setSortOrder(Direction.ASC);
    //setCustomFilterSearch?.(false);
    trackApplicationFeature(SEARCH_APP_FEATURE_LIST.FACET_FEATURE, FACET_FEATURE_ACTIONS.CLEAR_ALL);
    sessionStorage.setItem('externalFilter', '');
    sessionStorage.setItem('externalAssetType', '');
    sessionStorage.setItem('externalFunction', '');
    setClearAllFilters(true);
  }, [appliedFilters, selectedFilters, setAppliedFilters, setPageNo, setSelectedFilters, trackApplicationFeature]);

  const onPageSelect = useCallback(
    (pageNo: number) => {
      setPageNo(pageNo);
      trackCustomDimensions(pageNo, dimensions.PAGINATION);
      trackApplicationFeature(
        SEARCH_APP_FEATURE_LIST.RESULT_VIEW_CONFIGURATIONS,
        RESULT_VIEW_CONFIGURATIONS_ACTIONS.RESULT_VIEW_PAGINATION
      );
      window.scrollTo(0, 0);
    },
    [dimensions.PAGINATION, setPageNo, trackApplicationFeature, trackCustomDimensions]
  );

  const [showTable, setShowTable] = useState(false);
  const providedValue = useMemo<ISearchState>(
    () => ({
      selectedParentFilter,
      setSelectedParentFilter,
      showTable,
      setShowTable,
      isSliderShown,
      setIsSliderShown,
      isMobile,
      pageOptionsCount,
      selectedViewType: viewType,
      onViewTypeChange,
      results,
      query,
      sortOption,
      selectedSort: sortOption,
      setSelectedSort: setSortOption,
      sortOrder,
      setSortOrder,
      loading: abTestingGroup && currentCustomsource !== 'Contract Search' ? multiSearchLoader : loading,
      onPageSort,
      itemsPerPage,
      onItemsPerPageChange,
      onClearAllFilters,
      removeParentFilter,
      onRemoveAppliedFilters,
      onPageSelect,
      searchQueryVariables: variables,
      isExpandable,
      expandPanel,
      setExpandPanel,
      appData: appsData,
      eventData: eventData,
      acronymTitleData: acronymTitle,
      searchFeedbackData: searchFeedbackData,
      generativeAIData: generativeAIData,
      consolidatedData: consolidatedData,
      generativeAILoading: generativeAILoading,
      setCount,
      pageNo,
      setSelectedTopFilter,
      selectedTopFilter,
      isAllSelected,
      setAllSelected,
      isIframeRendered,
      setIsIframeRendered,
      isUserFeedbackCompleted,
      setIsUserFeedbackCompleted,
      countData,
      conversationalSearchData,
      filterRemoved,
      setFilterRemoved,
      selectedSources,
      topResultCount,
      setTopResultCount,
      recommendedResultCount,
      setRecommendedResultCount,
      spotLightData,
      setSpotLightData,
      DetailedFeedbackFlag,
      setFlag,
      selectedMood,
      selectedAppMood,
      selectAppMood,
      openFeedbackFromSearchQuery,
      setOpenFeedbackFromSearchQuery,
      selectMood,
      showFeedbackBtn,
      setShowFeedbackBtn,
      typeOfFeedback,
      setTypeOfFeedback,
      recommendedTopData,
      setRecommendedTopData,
      isApplicationGenerativeAIVariable,
      setIsApplicationGenerativeAIVariable,
      genAIDeeplinkData,
      setGenAIDeeplinkData,
      genAISpotlightData,
      setGenAISpotlightData,
      userPseudoId,
      setUserPseudoId,
      followUpQuery,
      setFollowUpQuery,
      multiTurnConversationList,
      setMultiTurnConversationList,
      multiTurnAPICompleted,
      setMultiTurnAPICompleted,
      conversationalQnAInvoked,
      setConversationalQnAInvoked,
      conversationalQnAEngaged,
      setConversationalQnAEngaged,
      finalFollowupQuery,
      setFinalFollowupQuery,
      multiTurnBinaryFeedback,
      setMultiTurnBinaryFeedback,
      isSearchAPIcompleted,
      setIsSearchAPIcompleted,
    }),
    [
      selectedSources,
      multiTurnConversationList,
      setMultiTurnConversationList,
      multiTurnAPICompleted,
      setMultiTurnAPICompleted,
      isSliderShown,
      isMobile,
      pageOptionsCount,
      viewType,
      onViewTypeChange,
      results,
      query,
      sortOption,
      openFeedbackFromSearchQuery,
      setOpenFeedbackFromSearchQuery,
      setSortOption,
      sortOrder,
      setSortOrder,
      // data,
      loading,
      onPageSort,
      itemsPerPage,
      onItemsPerPageChange,
      onClearAllFilters,
      removeParentFilter,
      onRemoveAppliedFilters,
      onPageSelect,
      variables,
      isExpandable,
      expandPanel,
      setExpandPanel,
      appsData,
      eventData,
      acronymTitle,
      searchFeedbackData,
      generativeAIData,
      consolidatedData,
      pageNo,
      setSelectedTopFilter,
      selectedTopFilter,
      isAllSelected,
      setAllSelected,
      countData,
      conversationalSearchData,
      filterRemoved,
      setFilterRemoved,
      isUserFeedbackCompleted,
      setIsUserFeedbackCompleted,
      recommendedResultCount,
      setRecommendedResultCount,
      selectedParentFilter,
      setSelectedParentFilter,
      topResultCount,
      setTopResultCount,
      spotLightData,
      setSpotLightData,
      DetailedFeedbackFlag,
      setFlag,
      selectedMood,
      selectedAppMood,
      selectAppMood,
      selectMood,
      showFeedbackBtn,
      setShowFeedbackBtn,
      typeOfFeedback,
      setTypeOfFeedback,
      recommendedTopData,
      setRecommendedTopData,
      isApplicationGenerativeAIVariable,
      setIsApplicationGenerativeAIVariable,
      genAIDeeplinkData,
      setGenAIDeeplinkData,
      genAISpotlightData,
      setGenAISpotlightData,
      userPseudoId,
      setUserPseudoId,
      followUpQuery,
      setFollowUpQuery,
      conversationalQnAInvoked,
      setConversationalQnAInvoked,
      conversationalQnAEngaged,
      setConversationalQnAEngaged,
      finalFollowupQuery,
      setFinalFollowupQuery,
      multiTurnBinaryFeedback,
      setMultiTurnBinaryFeedback,
      isSearchAPIcompleted,
      setIsSearchAPIcompleted,
    ]
  );

  return <SearchContext.Provider value={providedValue}>{children}</SearchContext.Provider>;
};

export default SearchContextProvider;
