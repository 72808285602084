/** https://github.com/guillaumeparis2000/react-piwik */

declare global {
  interface Window {
    _paq: any;
  }
}

export interface MatomoOptions {
  url: string;
  siteId: number;
  enableLinkTracking?: boolean;
  trackDocumentTitle?: boolean;
  jsFilename?: string;
  phpFilename?: string;
  pathBasename?: string;
}

export class AmgenMatomoTracker {
  options: MatomoOptions;
  previousPath: string | undefined;
  unListenFromHistory: (() => void) | undefined;

  constructor(opts: MatomoOptions) {
    const options = opts;

    options.enableLinkTracking = options.enableLinkTracking !== undefined ? options.enableLinkTracking : true;
    options.trackDocumentTitle = options.trackDocumentTitle !== undefined ? options.trackDocumentTitle : true;
    options.jsFilename = options.jsFilename ?? 'piwik.js';
    options.phpFilename = options.phpFilename ?? 'piwik.php';

    this.options = options;

    if (this.options.url === undefined || this.options.siteId === undefined) {
      throw new Error('AmgenMatomoTrackerTracker cannot be initialized! SiteId and url are mandatory.');
    }

    this.initMatomo();
  }

  //initialize AmgenMatomoTracker on each page
  initMatomo(): any {
    if (typeof window !== 'undefined') {
      let url = this.options.url;

      if (url.indexOf('http://') !== -1 || url.indexOf('https://') !== -1) {
        url = `${url}/`;
      } else {
        url = document.location.protocol === 'https:' ? `https://${url}/` : `http://${url}/`;
      }

      window._paq = window._paq || []; // eslint-disable-line  no-underscore-dangle

      this.push(['setSiteId', this.options.siteId]);
      this.push(['setTrackerUrl', `${url + this.options.phpFilename}`]);

      if (this.options.enableLinkTracking) {
        this.push(['enableLinkTracking']);
      }

      const scriptElement = document.createElement('script');
      const refElement = document.getElementsByTagName('script')[0];

      scriptElement.type = 'text/javascript';
      scriptElement.defer = true;
      scriptElement.async = true;

      let jsFilename = this.options.jsFilename!;

      if (jsFilename.indexOf('http://') !== 0 && jsFilename.indexOf('https://') !== 0) {
        jsFilename = url + jsFilename;
      }

      scriptElement.src = jsFilename;
      refElement.parentNode!.insertBefore(scriptElement, refElement);
    }

    return {
      push: this.push,
      track: this.track,
      connectToHistory: this.connectToHistory,
      disconnectFromHistory: this.disconnectFromHistory,
    };
  }

  push(args: any) {
    window._paq.push(args);
  }

  connectToHistory(history: any) {
    const prevLoc = typeof history.getCurrentLocation === 'undefined' ? history.location : history.getCurrentLocation();
    this.previousPath = prevLoc.path || (prevLoc.pathname + prevLoc.search).replace(/^\//, '');
    this.unListenFromHistory = history.listen((loc: any) => {
      this.track(loc);
    });

    return history;
  }

  disconnectFromHistory() {
    if (this.unListenFromHistory) {
      this.unListenFromHistory();

      return true;
    }

    return false;
  }

  getBasePath() {
    if (typeof this.options.pathBasename !== 'undefined') {
      return `${window.location.origin}/${this.options.pathBasename}/`;
    }
    return `${window.location.origin}/`;
  }

  track(loc: any) {
    if (typeof window === 'undefined') {
      return;
    }
    const currentPath = loc.path || (loc.pathname + loc.search).replace(/^\//, '');

    if (this.previousPath === currentPath) {
      return;
    }

    if (this.options.trackDocumentTitle) {
      this.push(['setDocumentTitle', document.title]);
    }

    if (this.previousPath) {
      this.push(['deleteCustomVariables', 'page']);
      this.push(['setPagePerformanceTiming', 0]);
      this.push(['setReferrerUrl', `${this.getBasePath()}${this.previousPath}`]);
    }
    this.push(['setCustomUrl', `${this.getBasePath()}${currentPath}`]);

    this.push(['trackPageView']);

    this.previousPath = currentPath;
  }
}
