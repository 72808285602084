import * as Types from '@amgen/core';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type IGetQueryFeedbackQueryVariables = Types.Exact<{
  applicationName: Types.ApplicationName;
  searchTerm: Types.Scalars['String'];
}>;

export type IGetQueryFeedbackQuery = {
  getQueryFeedback?: Types.Maybe<Pick<Types.IQueryFeedbackCollection, 'already_reported' | 'days_from_last_feedback'>>;
};

export type IAddQueryFeedbackMutationVariables = Types.Exact<{
  queryFeedback?: Types.Maybe<Types.IQueryFeedbackInput>;
}>;

export type IAddQueryFeedbackMutation = {
  addQueryFeedback?: Types.Maybe<Pick<Types.IGenericResponse, 'status' | 'message' | 'error'>>;
};

export const GetQueryFeedbackDocument = gql`
  query GetQueryFeedback($applicationName: ApplicationName!, $searchTerm: String!) {
    getQueryFeedback(applicationName: $applicationName, searchTerm: $searchTerm) {
      already_reported
      days_from_last_feedback
    }
  }
`;
export function useGetQueryFeedbackQuery(
  baseOptions: Apollo.QueryHookOptions<IGetQueryFeedbackQuery, IGetQueryFeedbackQueryVariables>
) {
  return Apollo.useQuery<IGetQueryFeedbackQuery, IGetQueryFeedbackQueryVariables>(
    GetQueryFeedbackDocument,
    baseOptions
  );
}
export function useGetQueryFeedbackLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetQueryFeedbackQuery, IGetQueryFeedbackQueryVariables>
) {
  return Apollo.useLazyQuery<IGetQueryFeedbackQuery, IGetQueryFeedbackQueryVariables>(
    GetQueryFeedbackDocument,
    baseOptions
  );
}
export type GetQueryFeedbackQueryHookResult = ReturnType<typeof useGetQueryFeedbackQuery>;
export type GetQueryFeedbackLazyQueryHookResult = ReturnType<typeof useGetQueryFeedbackLazyQuery>;
export type GetQueryFeedbackQueryResult = Apollo.QueryResult<IGetQueryFeedbackQuery, IGetQueryFeedbackQueryVariables>;
export const AddQueryFeedbackDocument = gql`
  mutation AddQueryFeedback($queryFeedback: QueryFeedbackInput) {
    addQueryFeedback(queryFeedback: $queryFeedback) {
      status
      message
      error
    }
  }
`;
export type IAddQueryFeedbackMutationFn = Apollo.MutationFunction<
  IAddQueryFeedbackMutation,
  IAddQueryFeedbackMutationVariables
>;
export function useAddQueryFeedbackMutation(
  baseOptions?: Apollo.MutationHookOptions<IAddQueryFeedbackMutation, IAddQueryFeedbackMutationVariables>
) {
  return Apollo.useMutation<IAddQueryFeedbackMutation, IAddQueryFeedbackMutationVariables>(
    AddQueryFeedbackDocument,
    baseOptions
  );
}
export type AddQueryFeedbackMutationHookResult = ReturnType<typeof useAddQueryFeedbackMutation>;
export type AddQueryFeedbackMutationResult = Apollo.MutationResult<IAddQueryFeedbackMutation>;
export type AddQueryFeedbackMutationOptions = Apollo.BaseMutationOptions<
  IAddQueryFeedbackMutation,
  IAddQueryFeedbackMutationVariables
>;
