import * as Types from '@amgen/core';
import * as Apollo from '@apollo/client';

export type IGenericResponseFragment = Pick<Types.IGenericResponse, 'error' | 'message' | 'status'>;

export const GenericResponseFragmentDoc = Apollo.gql`
  fragment GenericResponse on GenericResponse {
    error
    message
    status
  }
`;

export type IValidateConsentAgreementQueryVariables = Types.Exact<{
  applicationName: Types.ApplicationName;
}>;

export type IValidateConsentAgreementQuery = Pick<Types.IQuery, 'validateConsentAgreement'>;

export type IAddConsentAgreementMutationVariables = Types.Exact<{
  applicationName: Types.ApplicationName;
  consents: Types.Scalars['String'];
}>;

export type IAddConsentAgreementMutation = { addConsentAgreement?: Types.Maybe<IGenericResponseFragment> };

export const ValidateConsentAgreementDocument = Apollo.gql`
  query ValidateConsentAgreement($applicationName: ApplicationName!) {
    validateConsentAgreement(applicationName: $applicationName)
  }
`;
export function useValidateConsentAgreementQuery(
  baseOptions: Apollo.QueryHookOptions<IValidateConsentAgreementQuery, IValidateConsentAgreementQueryVariables>
) {
  return Apollo.useQuery<IValidateConsentAgreementQuery, IValidateConsentAgreementQueryVariables>(
    ValidateConsentAgreementDocument,
    baseOptions
  );
}
export function useValidateConsentAgreementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IValidateConsentAgreementQuery, IValidateConsentAgreementQueryVariables>
) {
  return Apollo.useLazyQuery<IValidateConsentAgreementQuery, IValidateConsentAgreementQueryVariables>(
    ValidateConsentAgreementDocument,
    baseOptions
  );
}
export type ValidateConsentAgreementQueryHookResult = ReturnType<typeof useValidateConsentAgreementQuery>;
export type ValidateConsentAgreementLazyQueryHookResult = ReturnType<typeof useValidateConsentAgreementLazyQuery>;
export type ValidateConsentAgreementQueryResult = Apollo.QueryResult<
  IValidateConsentAgreementQuery,
  IValidateConsentAgreementQueryVariables
>;
export const AddConsentAgreementDocument = Apollo.gql`
  mutation AddConsentAgreement($applicationName: ApplicationName!, $consents: String!) {
    addConsentAgreement(applicationName: $applicationName, consents: $consents) {
      ...GenericResponse
    }
  }
  ${GenericResponseFragmentDoc}
`;
export type IAddConsentAgreementMutationFn = Apollo.MutationFunction<
  IAddConsentAgreementMutation,
  IAddConsentAgreementMutationVariables
>;
export function useAddConsentAgreementMutation(
  baseOptions?: Apollo.MutationHookOptions<IAddConsentAgreementMutation, IAddConsentAgreementMutationVariables>
) {
  return Apollo.useMutation<IAddConsentAgreementMutation, IAddConsentAgreementMutationVariables>(
    AddConsentAgreementDocument,
    baseOptions
  );
}
export type AddConsentAgreementMutationHookResult = ReturnType<typeof useAddConsentAgreementMutation>;
export type AddConsentAgreementMutationResult = Apollo.MutationResult<IAddConsentAgreementMutation>;
export type AddConsentAgreementMutationOptions = Apollo.BaseMutationOptions<
  IAddConsentAgreementMutation,
  IAddConsentAgreementMutationVariables
>;
