import { useGetTypeaheadResultUrlQuery } from '@amgen/api';
import { MatomoContext } from '@amgen/shared';
import React, { useContext, useEffect, useState } from 'react';

import { SearchBoxContext } from '../../search-box-context-provider/search-box-context';

export const GetTypeaheadURL = () => {
  const { payload, setPayload } = useContext(SearchBoxContext);
  const { trackLinkClickEvent } = useContext(MatomoContext);
  const user = JSON.parse(sessionStorage.user);
  const [clicked, setClicked] = useState(true);
  const { data, refetch } = useGetTypeaheadResultUrlQuery({
    variables: {
      solrHandler: '/search-select',
      filters: [`title_s : "${payload.title}"`, `autosuggest_object: "${payload.object}"`],
      userId: user.username,
      userType: sessionStorage.getItem('employeeType') || '',
    },
    onCompleted: data => {
      if (data && data.getTypeaheadResultUrl) {
        window.open(data?.getTypeaheadResultUrl, '_blank');
        trackLinkClickEvent(data?.getTypeaheadResultUrl);
      }
      setClicked(false);
    },
    fetchPolicy: 'no-cache',
  });

  // useEffect(() => {
  //   if (data && data.getTypeaheadResultUrl) {
  //     window.open(data?.getTypeaheadResultUrl, '_blank');
  //     trackLinkClickEvent(data?.getTypeaheadResultUrl);
  //   }
  // }, [payload, refetch, data]);

  return <>{}</>;
};

// export default GetTypeaheadURL;
