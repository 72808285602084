import { IRoadmapTheme } from '@amgen/core';
import React, { useMemo } from 'react';

import './theme-context-provider.scss';

import { IThemeState, ThemeContext } from './theme-context';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export type ThemeContextProviderProps = Pick<IRoadmapTheme, 'color' | 'id' | 'name'>;

export const ThemeContextProvider: React.FC<ThemeContextProviderProps> = ({ children, id, color, name }) => {
  const providedValue = useMemo<IThemeState>(() => ({ id, color, name }), [id, color, name]);

  return <ThemeContext.Provider value={providedValue}>{children}</ThemeContext.Provider>;
};

export default ThemeContextProvider;
