import { APIProvider } from '@amgen/api';
import { Authenticator, Authorizer } from '@amgen/auth';
import { useEnvironment } from '@amgen/core';
import React, { useContext } from 'react';
import { BrowserRouter } from 'react-router-dom';

import './search-app-container.scss';

import ConsentAcceptor from '../consent-acceptor/consent-acceptor';
import { MatomoProvider } from '../matomo';
import { ToastProvider } from '../toast/toast-context';

/* eslint-disable-next-line */
export interface SearchAppContainerProps {
  showConsent?: boolean;
}

export const SearchAppContainer: React.FC<SearchAppContainerProps> = ({ children, showConsent }) => {
  const { oAuth, api, production } = useEnvironment();
  let ConsentWrapper: React.FC = React.Fragment; //fallback in case you dont want to wrap your components

  if (showConsent) {
    ConsentWrapper = ConsentAcceptor;
  }
  return (
    <BrowserRouter basename={production ? '/search' : undefined}>
      <Authenticator {...oAuth}>
        <APIProvider {...api}>
          <Authorizer>
            <ToastProvider>
              <ConsentWrapper>
                <MatomoProvider>{children}</MatomoProvider>
              </ConsentWrapper>
            </ToastProvider>
          </Authorizer>
        </APIProvider>
      </Authenticator>
    </BrowserRouter>
  );
};

export default SearchAppContainer;
