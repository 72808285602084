import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';

import './metric-icon.scss';

import NotifyBadge from '../notify-badge/notify-badge';

/* eslint-disable-next-line */
export interface MetricIconProps extends FontAwesomeIconProps {
  count?: number;
  parentclassname?: string;
}

export function MetricIcon(props: MetricIconProps) {
  return (
    <span className={classNames('metric-icon position-relative cursor-pointer', props.parentclassname)}>
      <FontAwesomeIcon {...props} style={{ fontSize: 25 }} />
      {props.count ? <NotifyBadge count={props.count} /> : null}
    </span>
  );
}

export default MetricIcon;
