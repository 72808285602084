import { DEFAULT_SEARCH_QUERY, ITypeaheadOptionsByType, ITypeaheadSuggestion } from '@amgen/core';
import { AppContext, useWindowSizeListener } from '@amgen/shared';
import { faExternalLinkAlt, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useCallback, useContext, useEffect } from 'react';
import { Menu, MenuItem, TypeaheadResult } from 'react-bootstrap-typeahead';
import { useHistory, useLocation } from 'react-router-dom';

import './search-box-typeahead-menu.scss';

import QuickSourceFilter from '../../quick-source-filter/quick-source-filter';
import { SearchBoxContext } from '../../search-box-context-provider/search-box-context';
import Suggestion from '../../suggestion/suggestion';

export interface SearchBoxTypeaheadMenuProps {
  results: TypeaheadResult<ITypeaheadSuggestion>[];
  menuProps: any;
}

export const SearchBoxTypeaheadMenu: React.FC<SearchBoxTypeaheadMenuProps> = ({ results, menuProps }) => {
  const { setPageNo } = useContext(AppContext);
  const history = useHistory();
  const location = useLocation();
  const searchPath = ['/reports', '/surveys', '/dashboard'].includes(location.pathname) ? location.pathname : '/';
  const { hasSearchBoxFacetingExperience, showQuickFilters, searchQuery } = useContext(SearchBoxContext);
  // / This groups suggestions by their respective option types: suggestions, filters, or filter values

  const dropdownOptionsByType = results
    .filter(
      dropdownOption =>
        (!!searchQuery && searchQuery !== DEFAULT_SEARCH_QUERY) || dropdownOption.suggestionType !== 'Filters'
    )
    .reduce<ITypeaheadOptionsByType>((dropdownOptionsMap, dropdownOption) => {
      if (!dropdownOptionsMap[dropdownOption.suggestionType]) {
        dropdownOptionsMap[dropdownOption.suggestionType] = [];
      }
      dropdownOptionsMap[dropdownOption.suggestionType].push(dropdownOption);

      if (searchQuery.length < 3) {
        delete dropdownOptionsMap['MyAmgen Content'];
        delete dropdownOptionsMap['Suggested Destinations'];
      } else if (searchQuery.length > 2) {
        delete dropdownOptionsMap['Previous Search'];
      }
      return dropdownOptionsMap;
    }, {});

  let pos = -1;
  const isMobile = useWindowSizeListener();

  const onUserInputClick = useCallback(
    (query: string) => {
      const params = new URLSearchParams();
      params.append('q', query !== DEFAULT_SEARCH_QUERY ? query : query);
      setPageNo(1);
      history.push({ pathname: searchPath, search: params.toString() });
    },

    [history, searchPath, setPageNo]
  );

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {Object.entries(dropdownOptionsByType).length > 0 || showQuickFilters ? (
        <Menu
          {...menuProps}
          className={classNames(
            'position-absolute typeahead-menu',
            isMobile ? 'typeahead-mobile-menu-width' : 'w-100 typeahead-menu-width'
          )}
          style={isMobile ? { left: '-27px' } : { left: '5px' }}
        >
          {showQuickFilters && <QuickSourceFilter />}
          {/* commented below typeahead filters bar that was shown earlier to de-select few of the favorite sources in advanced search typeahead */}
          {/* <TypeaheadFiltersBar /> */}
          {/* <h1>{isNotInput?searchQuery:"ee"}</h1> */}
          {searchQuery.length !== 0 ? (
            <div
              className="font-weight-bold mt-2 fs-14 text-truncate cursor-pointer dropdown-item"
              onClick={() => onUserInputClick(searchQuery)}
            >
              {/* <span className="px-2 mx-1"></span> */}
              <FontAwesomeIcon icon={faSearch} className="mr-1" color="grey" />
              <span className="px-2"></span>
              {'"' + searchQuery + '"'}
            </div>
          ) : (
            ' '
          )}

          {Object.entries(dropdownOptionsByType).map(([optionType, suggestions], i) => (
            <div id={`${optionType}-options`} key={`${optionType}-optionType${i}`}>
              {hasSearchBoxFacetingExperience && (
                <div className={classNames('suggestion-header pl-3 font-weight-bold ', isMobile ? 'pt-2' : '')}>
                  {optionType}

                  {optionType === 'Suggested Destinations' || optionType === 'MyAmgen Content' ? (
                    <>
                      {' '}
                      <FontAwesomeIcon icon={faExternalLinkAlt} className={classNames('dropdown-redirect')} />
                      <span className={classNames('dropdown-redirect')}> (Opens in New Tab)</span>
                    </>
                  ) : (
                    ''
                  )}
                </div>
              )}
              {suggestions.map((suggestion, index) => {
                // this limits list length per section for eea,
                // maintains no limit for kmi
                if (index < 5 || !hasSearchBoxFacetingExperience) {
                  pos += 1;
                  return (
                    <MenuItem option={suggestion} position={pos} key={`${suggestion.term}${pos}`}>
                      <Suggestion
                        suggestion={suggestion}
                        keyWord={searchQuery}
                        optionType={suggestion.suggestionType}
                      />
                    </MenuItem>
                  );
                } else {
                  return ' ';
                }
              })}
            </div>
          ))}
        </Menu>
      ) : (
        ''
      )}
    </>
  );
};
// thin-scrollbar
export default SearchBoxTypeaheadMenu;
