import { useGetContentQuery } from '@amgen/api';
import { useEnvironment } from '@amgen/core';
import { AppContext, useSearchQueryValue } from '@amgen/shared';
import DOMPurify from 'dompurify';
import React, { useContext } from 'react';
import { Modal, Spinner } from 'react-bootstrap';

import './full-content-popup.scss';

export interface FullContentPopupProps {
  assetId: string;
  show: boolean;
  onHide: () => void;
  content?: string;
}

export const FullContentPopup = (props: FullContentPopupProps) => {
  const { appName } = useEnvironment();
  const { currentSource } = useContext(AppContext);
  const query = useSearchQueryValue();
  const abTestingGroup = JSON.parse(sessionStorage.getItem('abTestingGroup') as string);
  const currentCustomsource = sessionStorage.getItem('current-source-selected');
  //  query: abTestingGroup ? props.assetId || '' : '*:*',
  const { data, loading, error } = useGetContentQuery({
    variables: {
      query: '*:*',
      appName,
      filters: [`id : "${props.assetId || ''}"`],
      count: 1,
      noShortContent: true,
      sourceType: currentSource,
      userType: sessionStorage.getItem('employeeType') || '',
    },
    skip: abTestingGroup && currentCustomsource !== 'Contract Search',
  });

  return !abTestingGroup || (abTestingGroup && currentCustomsource === 'Contract Search') ? (
    <Modal show={props.show} onHide={props.onHide} centered scrollable size="lg">
      <Modal.Header closeButton className="border-0">
        <Modal.Title as="h2" className="font-weight-bold">
          Content
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="mb-5 mx-2 clammped-content-body">
        {error ? (
          <p className="text-center">Sorry something went wrong. Please try again later.</p>
        ) : loading ? (
          <div className="d-flex justify-content-center w-100">
            <Spinner className="text-primary" animation="border" />
          </div>
        ) : (
          <ul className="p-0">
            {data?.search?.docs?.[0]?.dataAsset?.content?.map((content, index) => (
              <li className="list-unstyled" key={index}>
                <pre>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(content),
                    }}
                  />
                </pre>
              </li>
            ))}
          </ul>
        )}
      </Modal.Body>
    </Modal>
  ) : (
    <Modal show={props.show} onHide={props.onHide} centered scrollable size="lg">
      <Modal.Header closeButton className="border-0 mx-2">
        <Modal.Title as="h2" className="font-weight-bold">
          Content
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="mb-5 mx-2 clammped-content-body">
        <ul className="p-0">
          <li className="list-unstyled">
            <pre>
              <p
                dangerouslySetInnerHTML={{
                  __html: props.content ?? '',
                }}
              />
            </pre>
          </li>
        </ul>
      </Modal.Body>
    </Modal>
  );
};

export default FullContentPopup;
