import { IAssetAttribute } from '@amgen/core';
import { AppContext } from '@amgen/shared';
import classNames from 'classnames';
import React, { useContext } from 'react';
import { Badge } from 'react-bootstrap';

import './applied-filter.scss';

import { DATE_FACET_CATEGORIES } from '../../config';

// import { faTimes } from '@fortawesome/free-regular-svg-icons';
export interface AppliedFilterProps {
  category: IAssetAttribute;
  value: string;
  onRemove: () => void;
  isClearable?: boolean;
  className?: string;
}

export const AppliedFilter: React.FC<AppliedFilterProps> = props => {
  const { attributeDisplayNames } = useContext(AppContext);
  const categoryName = attributeDisplayNames[props.category];

  // TODO: Remove special cases. Each applied value should be an object with 'actualValue and displayValue'.
  const value = DATE_FACET_CATEGORIES.includes(props.category)
    ? `${new Date(props.value.slice(0, 20).replace(/-/g, '/').replace(/T.+/, '')).toDateString().slice(4)} ~ ${new Date(
        props.value.slice(23, 43).replace(/-/g, '/').replace(/T.+/, '')
      )
        .toDateString()
        .slice(4)}`
    : props.category === 'doc_nav_prefix'
    ? props.value.split('/').slice(-1)[0] // eg: '/3/A/B/C' => C
    : props.value?.toString() ?? '';

  return (
    <Badge
      className={classNames(
        'eea-applied-filter d-inline-flex align-items-center mx-1 mb-1 px-2',
        { 'py-1': !props.isClearable },
        props.className
      )}
      title={`${categoryName}: ${value}`}
    >
      <span style={{ maxWidth: '500px' }} className="text-overflow-ellipsis">
        {value}
      </span>
      {props.isClearable && (
        <span
          className={classNames('eea-btn-clear cursor-pointer ml-1', props.className)}
          onClick={props.onRemove}
        >
          <img src="assets/images/close-button.svg" alt="" />
        </span>
      )}
    </Badge>
  );
};

export default AppliedFilter;
