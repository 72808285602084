import { useAddDocumentInteractionMutation } from '@amgen/api';
import { useEnvironment } from '@amgen/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import './doc-interaction-context-provider.scss';

import { useSearchQueryValue } from '../../hooks';
import { SearchTermDocClick } from '../../models';
import { DocInteractionContext, IDocInteractionFunction, IDocInteractionState } from './doc-interaction-context';

/* eslint-disable-next-line */
export interface DocInteractionContextProviderProps {}

export const DocInteractionContextProvider: React.FC<DocInteractionContextProviderProps> = ({ children }) => {
  const [searchKeyDocIds, setSearchKeyDocIds] = useState(new SearchTermDocClick());

  useEffect(() => setSearchKeyDocIds(new SearchTermDocClick()), []);

  const [trackDocumentTitleClickInSolr] = useAddDocumentInteractionMutation();

  const { appName } = useEnvironment();
  const searchTerm = useSearchQueryValue();

  const onDocTitleClick: IDocInteractionFunction = useCallback(
    id => {
      if (!searchKeyDocIds.values(searchTerm)?.has(id)) {
        trackDocumentTitleClickInSolr({
          variables: {
            applicationName: appName,
            assetId: id,
            searchTerm,
          },
        });
        setSearchKeyDocIds(searchKeyDocIds.clone().add(id, searchTerm));
      }
    },
    [appName, searchKeyDocIds, searchTerm, trackDocumentTitleClickInSolr]
  );

  const onDocReviewRate: IDocInteractionFunction = useCallback(id => console.log({ id }), []);

  const onDocHover: IDocInteractionFunction = useCallback(id => console.log({ id }), []);

  const onDocViewDetails: IDocInteractionFunction = useCallback(id => console.log({ id }), []);

  const providedValue = useMemo<IDocInteractionState>(
    () => ({
      onDocTitleClick,
      onDocReviewRate,
      onDocHover,
      onDocViewDetails,
    }),
    [onDocTitleClick, onDocReviewRate, onDocHover, onDocViewDetails]
  );

  return <DocInteractionContext.Provider value={providedValue}>{children}</DocInteractionContext.Provider>;
};

export default DocInteractionContextProvider;
