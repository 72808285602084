import { IAssetAttribute, IFacetValue } from '@amgen/core';
import { FacetsPanelContext } from '@amgen/shared-kmi';
import React, { ChangeEvent, useContext } from 'react';
import { FormControl } from 'react-bootstrap';
import { debounce } from 'ts-debounce';

import './infinite-facet-dropdown-items.scss';

import InfiniteLoaderList from '../infinite-loader-list/infinite-loader-list';

export interface InfiniteFacetDropdownItemsProps {
  facetValues: IFacetValue[];
  category: IAssetAttribute;
  hasNextPage: boolean;
  isNextPageLoading: boolean;
  onSearch: (term: string) => void;
  loadNextPage: () => Promise<void> | null;
}

export const InfiniteFacetDropdownItems: React.FC<InfiniteFacetDropdownItemsProps> = props => {
  const { selectedFilters } = useContext(FacetsPanelContext);
  const categoryItems = selectedFilters.filters?.[props.category] ?? new Set<string>();
  // const [searchText, setSearchText] = useState('');

  return (
    <>
      <div className="px-3 mb-2">
        <FormControl
          placeholder="Search"
          onChange={(e: ChangeEvent<HTMLInputElement>) => debounce(props.onSearch, 300)(e.target.value.trim())}
        />
      </div>

      {props.facetValues.length > 0 ? (
        <InfiniteLoaderList
          categoryItems={categoryItems}
          hasNextPage={props.hasNextPage}
          items={props.facetValues}
          isNextPageLoading={props.isNextPageLoading}
          loadNextPage={props.loadNextPage}
        />
      ) : (
        <div className="px-3">No Items</div>
      )}
    </>
  );
};
export default InfiniteFacetDropdownItems;
