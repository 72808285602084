import { getEChartSeriesObjectForLine, MagicLineChart } from '@amgen/components';
import { IFacetValue } from '@amgen/core';
import { IInteractionLineChartSeries, ILineMerge, MagicLineChartType } from '@amgen/shared';
import React from 'react';

import './interaction-chart.scss';

export interface InteractionChartProps {
  interactionWithoutComments: IFacetValue[] | undefined;
  interactionsWithComments: IFacetValue[] | undefined;
  chartType: MagicLineChartType;
  setChartType: (chartType: MagicLineChartType) => void;
  hasAreaGradient?: boolean;
}

export const InteractionChart: React.FC<InteractionChartProps> = props => {
  const reduceToMatchingLineData = () => {
    const twoLineMerge: ILineMerge = {};
    if (props.interactionWithoutComments && props.interactionsWithComments) {
      for (let i = 0; i < props.interactionWithoutComments.length; i++) {
        //eIWtC -> each Interaction Without Comment
        const eIWtC = props.interactionWithoutComments[i];
        twoLineMerge[eIWtC.value!] = [eIWtC.count ?? 0, 0];
      }
      for (let j = 0; j < props.interactionsWithComments.length; j++) {
        //eIWC -> each Interaction With Comment
        const eIWC = props.interactionsWithComments[j];
        if (eIWC.value! in twoLineMerge) {
          twoLineMerge[eIWC.value!][1] = eIWC.count ?? 0;
        } else {
          twoLineMerge[eIWC.value!] = [0, eIWC.count ?? 0];
        }
      }
    }
    return convertToLineChartData(twoLineMerge);
  };

  const convertToLineChartData = (twoLineMerge: ILineMerge) => {
    const LineChart: IInteractionLineChartSeries = {
      firstLineSeries: [],
      secondLineSeries: [],
      xAxisData: [],
    };
    Object.keys(twoLineMerge).forEach(key => {
      LineChart.firstLineSeries!.push(twoLineMerge[key][0]);
      LineChart.secondLineSeries.push(twoLineMerge[key][1]);
      LineChart.xAxisData.push(new Date(key).toDateString());
    });
    return LineChart;
  };

  return (
    <MagicLineChart
      chartName="INTERACTIONS"
      onChartLegendSelectChanged={key => reduceToMatchingLineData()}
      lineChartData={reduceToMatchingLineData()}
      legendData={['Interaction with Comments', 'Interaction without Comments']}
      chartType={props.chartType}
      onChangeChartType={props.setChartType}
      onChartClick={key => {
        console.log(key);
        //todo this is because click prop in bar-chart
      }}
      series={[
        getEChartSeriesObjectForLine(
          'Interaction with Comments',
          props.chartType,
          'Interaction with Comments',
          reduceToMatchingLineData().secondLineSeries,
          '#00cc99',
          props.hasAreaGradient
        ),
        getEChartSeriesObjectForLine(
          'Interaction without Comments',
          props.chartType,
          'Interaction without Comments',
          reduceToMatchingLineData().firstLineSeries!,
          '#3399ff',
          props.hasAreaGradient
        ),
      ]}
    />
  );
};

export default InteractionChart;
