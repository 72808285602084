import { AppContext, ClinicalContext, FacetsPanelContext, ResultViewType, SearchContext } from '@amgen/shared';
import classnames from 'classnames';
import { NewsContext } from 'libs/shared/src/lib/contexts/news-context-provider/news-context';
import React, { useContext } from 'react';
import { Row } from 'react-bootstrap';

import './knowledge-panel-container.scss';

export interface NewsAndKnowledgePanelContainerProps {
  className?: string;
  mobileView?: boolean;
  hideInTableView?: boolean;
}

export const NewsAndKnowledgePanelContainer: React.FC<NewsAndKnowledgePanelContainerProps> = ({
  children,
  className,
  hideInTableView,
  mobileView,
}) => {
  const { selectedViewType } = useContext(SearchContext);
  const { selectedResultTab } = useContext(AppContext);
  const { product } = useContext(ClinicalContext);
  const { news } = useContext(NewsContext);
  const { isExpandButtonClicked } = useContext(FacetsPanelContext);

  return (
    <Row
      className={
        !isExpandButtonClicked
          ? classnames(
              className ?? '',
              mobileView
                ? 'pb-4 mx-auto align-items-center d-block d-lg-none'
                : selectedResultTab === 'Chat'
                ? 'right-side-container-chat-tab'
                : selectedViewType === ResultViewType.Table
                ? 'right-side-container-full'
                : 'right-side-container'
            )
          : classnames(
              className ?? '',
              mobileView ? 'pb-4 mx-auto align-items-center d-block d-lg-none' : 'right-side-container-full'
            )
      }
    >
      {children}
    </Row>
  );
};

export default NewsAndKnowledgePanelContainer;
