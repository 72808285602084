import { KnowledgePanelTabSwitch } from '@amgen/feature-knowledge-panel';
import { ClinicalContext } from '@amgen/shared';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import './clinical-details-related-section.scss';

import ClinicalDetailsExploreSection from '../clinical-details-explore-section/clinical-details-explore-section';
import ClinicalDetailsTable from '../clinical-details-table/clinical-details-table';

/* eslint-disable-next-line */
export interface ClinicalDetailsRelatedSectionProps {}

export const ClinicalDetailsRelatedSection: React.FC<ClinicalDetailsRelatedSectionProps> = () => {
  const { clinicalType } = useContext(ClinicalContext);
  const sessionActiveKGTab = sessionStorage.getItem('activeKGTab') || 'activeKGTab';
  const [activeOption, setActiveOption] = useState(sessionActiveKGTab as any);

  return (
    <>
      <KnowledgePanelTabSwitch
        activeOption={activeOption}
        setActiveOption={tab => setActiveOption(tab)}
        isExploreExpanded={true}
      />
      {activeOption === 'Overview' ? (
        <div className="scroll-bar-overview thin-scrollbar-gray">
          <ClinicalDetailsExploreSection />
        </div>
      ) : (
        <Col xs="12" className="mb-4">
          <Row>
            {/* <SelectedStudy /> */}
            <ClinicalDetailsTable />
          </Row>
        </Col>
      )}
    </>
  );
};

export default ClinicalDetailsRelatedSection;
