import { IGetCrawlRequestsQueryVariablesCopy, useGetCrawlRequestsQuery } from '@amgen/api';
import { Paginator, ResultsPerPageDropdown } from '@amgen/components';
import { DEFAULT_SEARCH_QUERY, PageType, RequestStatusType, SortField, useEnvironment } from '@amgen/core';
import {
  IGD_ACTIONS,
  MatomoContext,
  PAGES,
  SEARCH_APP_FEATURE_LIST,
  SearchContext,
  usePageTrackingWithTitle,
  useWindowSizeListener,
} from '@amgen/shared';
import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import './my-requests-page.scss';

import { ExportRequestsButton, MyRequestTable, RequestSearchBox } from '../../components';
import RequestStatusTab from '../../components/request-status-tab/request-status-tab';
import { POLL_INTERVAL } from '../../config/constants';
import { SortOrderObject } from '../../models/sort';

/* eslint-disable-next-line */
export interface MyRequestsPageProps {
  update: boolean;
}

export const MyRequestsPage = (props: MyRequestsPageProps) => {
  const { trackApplicationFeature } = useContext(MatomoContext);
  const { conversationalQnAEngaged, results } = useContext(SearchContext);
  const [query, setQuery] = useState('');
  const { appName } = useEnvironment();
  const [requestStatus, setRequestStatus] = useState(RequestStatusType.PENDING);
  const [count, setCount] = useState(10);
  const [pageNo, setPageNo] = useState(1);

  const defaultSort = (s?: RequestStatusType) => {
    const sort = s || requestStatus;
    return sort === RequestStatusType.PENDING
      ? new SortOrderObject().toggleSort(SortField.CREATEDON)
      : new SortOrderObject().toggleSort(SortField.REVIEWEDON);
  };

  const [sortObject, setSortObject] = useState(defaultSort());

  const variables = {
    type: PageType.IGD_MY_REQUEST,
    query: query || DEFAULT_SEARCH_QUERY,
    appName,
    sortOrder: sortObject.toSortOrder(),
    requestStatus,
    count,
    page: pageNo - 1,
    start: 0,
  };
  const { data, loading, refetch, stopPolling, startPolling, error } = useGetCrawlRequestsQuery({
    variables,
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (props.update) {
      setRequestStatus(RequestStatusType.PENDING);
    }
  }, [props.update, refetch]);

  const isMobile = useWindowSizeListener();
  const pageOptionsCount = Math.min(Math.ceil((data?.getContentOnboarding?.resultCount ?? 0) / 10), isMobile ? 2 : 5);

  const refreshStatus = (s: RequestStatusType) => {
    setRequestStatus(s);
    setPageNo(1);
    setSortObject(defaultSort(s));
    restartPolling({ ...variables, requestStatus: s, page: 0, sortOrder: defaultSort(s).toSortOrder() });
  };

  const restartPolling = (v: IGetCrawlRequestsQueryVariablesCopy) => {
    stopPolling();
    refetch(v);
    startPolling(POLL_INTERVAL);
  };

  const onCountChange = (c: number) => {
    setCount(c);
    setPageNo(1);
    restartPolling({ ...variables, page: 0, count: c });
  };

  usePageTrackingWithTitle(PAGES.ALL_REQUESTS);

  return (
    <Row noGutters className="px-3">
      <Col xs="12" className="mb-2 d-flex align-items-center justify-content-between pt-4">
        <h1 className="d-flex flex-column">
          <span className={classNames('mb-1', { 'fs-16': isMobile })}>All Request</span>
          <small className={classNames('text-grey', { 'fs-14': isMobile })}>View all requests raised by you</small>
        </h1>
        <ExportRequestsButton pageType={PageType.IGD_MY_REQUEST} />
      </Col>

      <Col xs="12" lg="3" className="mb-2">
        <RequestSearchBox
          placeholder="Search for Data Source, Site URL, Space Name"
          query={query}
          onSearch={setQuery}
          isMobile={isMobile}
        />
      </Col>
      <Col
        xs="12"
        lg={{ span: 6, offset: 3 }}
        className="d-flex align-items-center justify-content-between justify-content-lg-end mb-2"
      >
        <RequestStatusTab
          className="mr-lg-2"
          loading={loading}
          status={requestStatus}
          onChange={refreshStatus}
          resultCount={data?.getContentOnboarding?.resultCount}
        />
        {isMobile ? '' : <ResultsPerPageDropdown className="py-2" option={count} onChange={onCountChange} />}
      </Col>

      <Col xs="12">
        <>
          <MyRequestTable
            data={data?.getContentOnboarding?.requests ?? []}
            loading={loading}
            status={requestStatus}
            sort={sortObject}
            setSort={setSortObject}
          />
          <Paginator
            itemsPerPage={count}
            onPageSelect={p => {
              setPageNo(p);
              restartPolling({ ...variables, page: p - 1 });
              trackApplicationFeature(SEARCH_APP_FEATURE_LIST.IGD, IGD_ACTIONS.IGD_PAGINATE);
            }}
            pageNo={pageNo}
            pageOptionsCount={pageOptionsCount}
            totalItems={data?.getContentOnboarding?.resultCount ?? 0}
            conversationalQnAEngaged={conversationalQnAEngaged}
            results={results}
          />
        </>
      </Col>
    </Row>
  );
};

export default MyRequestsPage;
