import { NewDestinationData } from '@amgen/core';

export const MAX_WORD_COUNT = 200;
export const ALLOWED_FILE_EXTENSIONS = /(\.jpg|\.jpeg|\.png|\.msg|\.docx|\.pdf|\.zip)$/i;
export const FILE_SIZE_LIMIT = 20 * 1048576; // 20mb

export function returnFileSize(number: number) {
  if (number < 1024) {
    return number + 'bytes';
  } else if (number >= 1024 && number < 1048576) {
    return (number / 1024).toFixed(1) + 'KB';
  } else if (number >= 1048576) {
    return (number / 1048576).toFixed(1) + 'MB';
  }
}
export function isValidDestURL(destUrl: string) {
  const isValid = destUrl.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  return isValid !== null;
}

export function validate(values: NewDestinationData) {
  const errors: { [key in keyof NewDestinationData]?: string } = {};

  if (!values.title?.trim()) {
    errors.title = 'Title is required';
  }

  if (!values.content?.trim()) {
    errors.content = 'Description is required';
  }

  // if (values.content) {
  //   const wordCount = values.content.split(/\s+/g).filter(t => !!t).length;
  //   if (wordCount > MAX_WORD_COUNT) {
  //     errors.content = `You have exceeded the word limit (${wordCount} / ${MAX_WORD_COUNT})`;
  //   }
  // } else {
  //   errors.content = 'description is required';
  // }
  if (!values.url?.trim()) {
    errors.url = 'Destination URL is required';
  } else {
    if (!isValidDestURL(values.url?.trim())) {
      errors.url = 'URL is not valid';
    }
  }

  if (!values.requesterId?.trim()) {
    errors.requesterId = 'Requester Amgen Id is required';
  }
  return errors;
}
