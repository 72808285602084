import { KNOWLEDGE_PANEL_FEATURE_ACTIONS, MatomoContext, SEARCH_APP_FEATURE_LIST } from '@amgen/shared';
import React, { useContext, useEffect } from 'react';
import './knowledge-panel-asset.scss';

import { KnowledgePanelBody, KnowledgePanelBodyProps } from '../knowledge-panel-body/knowledge-panel-body';
import { KnowledgePanelHeader, KnowledgePanelHeaderProps } from '../knowledge-panel-header/knowledge-panel-header';
import { KnowledgePanelIcon, KnowledgePanelIconProps } from '../knowledge-panel-icon/knowledge-panel-icon';
import { KnowledgePanelImage, KnowledgePanelImageProps } from '../knowledge-panel-image/knowledge-panel-image';
import { KnowledgePanelTitle, KnowledgePanelTitleProps } from '../knowledge-panel-title/knowledge-panel-title';
import { KnowledgePanelExpand, KnowledgePanelExpandProps } from '../knowledge-panel-expand/knowledge-panel-expand';

export interface IKnowledgePanelComposition {
  Title: React.FC<KnowledgePanelTitleProps>;
  Header: React.FC<KnowledgePanelHeaderProps>;
  Image: React.FC<KnowledgePanelImageProps>;
  Icon: React.FC<KnowledgePanelIconProps>;
  Body: React.FC<KnowledgePanelBodyProps>;
  Expand: React.FC<KnowledgePanelExpandProps>;
}

/* eslint-disable-next-line */
export interface KnowledgePanelAssetProps {}

export const KnowledgePanelAsset: React.FC & IKnowledgePanelComposition = ({ children }) => {
  const { trackApplicationFeature } = useContext(MatomoContext);
  return (
    <div
      className="no-gutters shadow-sm knowledge-panel-asset mt-4"
      onMouseEnter={() => {
        trackApplicationFeature(
          SEARCH_APP_FEATURE_LIST.KNOWLEDGE_PANEL,
          KNOWLEDGE_PANEL_FEATURE_ACTIONS.KNOWLEDGE_PANEL_VISITED
        );
      }}
    >
      {children}
    </div>
  );
};

KnowledgePanelAsset.Header = KnowledgePanelHeader;
KnowledgePanelAsset.Title = KnowledgePanelTitle;
KnowledgePanelAsset.Image = KnowledgePanelImage;
KnowledgePanelAsset.Icon = KnowledgePanelIcon;
KnowledgePanelAsset.Body = KnowledgePanelBody;
KnowledgePanelAsset.Expand = KnowledgePanelExpand;

export default KnowledgePanelAsset;
