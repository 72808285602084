import React from 'react';
import { Spinner } from 'react-bootstrap';

export interface FullPageCenterLoaderProps {
  className?: string;
}

export const FullPageCenterLoader: React.FC<FullPageCenterLoaderProps> = ({ className }) => {
  return (
    <div
      className={`d-flex justify-content-center align-items-center ${className}`}
      style={{ height: '100vh', width: '100vw' }}
    >
      <Spinner animation="border" className="p-4 text-primary" />
    </div>
  );
};

export default FullPageCenterLoader;
