import React from 'react';
import { Pagination } from 'react-bootstrap';

import './first-page.scss';

import { useFirstPage } from '../hooks';

/* eslint-disable-next-line */
export interface FirstPageProps {}

export const FirstPage = (props: FirstPageProps) => {
  const [canFirstPage, handleFirstPage] = useFirstPage();

  return canFirstPage ? (
    <>
      <Pagination.Item onClick={handleFirstPage}>1</Pagination.Item>
      <Pagination.Ellipsis disabled />
    </>
  ) : null;
};

export default FirstPage;
