import { TimeGap } from '@amgen/shared-kmi';
import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Handles, Rail, Slider, Tracks } from 'react-compound-slider';

import './date-slider.scss';

import { DateFilterContext } from '../date-filter/date-filter-provider/date-filter-context';
import { Handle, Track } from './date-slider-components/date-slider-components';
import { monthsMap } from './months-maps';

/* eslint-disable-next-line */
export interface DateSliderProps {}

export const DateSlider = (props: DateSliderProps) => {
  const { sliderValues, currentView, handleApplyFilters } = useContext(DateFilterContext);
  const [hideSlider, setHideSlider] = useState(false);
  const [selectedSliderRange, setSelectedSliderRange] = useState<readonly number[]>([]);

  const domain =
    sliderValues && sliderValues.length > 0
      ? currentView === TimeGap.YEAR
        ? [parseInt(sliderValues[0]), parseInt(sliderValues.slice(-1)[0])]
        : [monthsMap[sliderValues[0].split('-')[1]], monthsMap[sliderValues.slice(-1)[0].split('-')[1]]]
      : [];

  // hide slider if only one month is applied as filter
  useEffect(() => {
    if (domain[0] === domain[1]) {
      setHideSlider(true);
    } else {
      setHideSlider(false);
    }
  }, [domain]);

  return domain.length > 0 && !hideSlider ? (
    <>
      {/* https://codesandbox.io/s/zl8nrlp9x */}
      <Slider
        className={'date-slider d-flex m-auto position-relative'}
        domain={domain} // [min, max]
        values={domain} // position of each handle
        mode={1}
        step={1}
        onChange={values => setSelectedSliderRange(values)}
      >
        <Rail>{({ getRailProps }) => <div className="slider-rail position-absolute w-100" {...getRailProps()} />}</Rail>
        <Handles>
          {({ handles, getHandleProps }) => {
            return (
              <div className="slider-handles">
                {handles.map(handle => (
                  <Handle
                    key={handle.id}
                    domain={domain}
                    handle={handle}
                    getHandleProps={getHandleProps}
                    currentView={currentView}
                  />
                ))}
              </div>
            );
          }}
        </Handles>
        <Tracks left={false} right={false}>
          {({ tracks, getTrackProps }) => (
            <div className="slider-tracks">
              {tracks.map(({ id, source, target }) => (
                <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
              ))}
            </div>
          )}
        </Tracks>
      </Slider>
      <Button className="apply-button mx-2" onClick={() => handleApplyFilters(selectedSliderRange)}>
        Apply
      </Button>
    </>
  ) : null;
};

export default DateSlider;
