import classNames from 'classnames';
import React from 'react';
import { Pagination } from 'react-bootstrap';

import './previous-page.scss';

import { usePreviousPage } from '../hooks';

/* eslint-disable-next-line */
export interface PreviousPageProps {}

export const PreviousPage = (props: PreviousPageProps) => {
  const [canPreviousPage, handlePreviousPage] = usePreviousPage();

  return (
    <Pagination.Prev
      disabled={canPreviousPage}
      className={classNames({ 'disabled-cursor': canPreviousPage })}
      onClick={handlePreviousPage}
      title="Previous Page"
    />
  );
};

export default PreviousPage;
