import { NewRequestFormValue } from '@amgen/core';

export const IGD_HELP_TEXT: { [key in keyof NewRequestFormValue]: string } = {
  owner: 'Please attach an approval from the owner',
  consent: '',
  contentSource: 'Select the source system for your data',
  sourcePath: 'Enter either the SharePoint URL OR Smartsheet URL Or Confluence Space Name',
  siteDescription: 'Please provide brief description for the site', //helpText="Describe the type of data in the source system"
  requestDescription: 'This information will help Admin to make a decision whether to Approve or Decline the request', //helpText="Describe the reason behind raising this request"
  file: '',
};
