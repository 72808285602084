import React, { useContext } from 'react';
import { Col } from 'react-bootstrap';

import './roadmap-grid.scss';

import { RoadmapContext } from '../../contexts';

/* eslint-disable-next-line */
export interface RoadmapGridProps {
  width?: number;
}

export function RoadmapGrid(props: RoadmapGridProps) {
  const { width } = useContext(RoadmapContext);

  return (
    <Col
      xs="12"
      className="roadmap-grid simple-elements mx-0 px-0 custom-roadmap-height position-fixed"
      style={{ width }}
    >
      <div className="roadmap-ranges h-100 position-relative px-0">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map((x, i) => (
          <div
            key={x + 'grid'}
            className="verticals simple-elements h-100 position-absolute"
            style={{ left: `${(i + 1) * 8.33333}%` }}
          ></div>
        ))}
      </div>
    </Col>
  );
}

export default RoadmapGrid;
