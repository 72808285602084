import { IBookmark } from '@amgen/core';
import { faFolderOpen } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Alert, Button, ListGroup, Spinner } from 'react-bootstrap';

import './bookmark-list.scss';

import BookmarkItemContainer from '../bookmark-item-container/bookmark-item-container';

const LoadingListItem = () => (
  <ListGroup.Item className="text-center text-primary" style={{ height: '90px' }}>
    <Spinner animation="border" size="sm" />
  </ListGroup.Item>
);

export interface BookmarkListProps {
  onScroll: (event: React.UIEvent<HTMLDivElement>) => void;
  isLoading: boolean;
  bookmarks: IBookmark[];
  onRetry: () => void;
  error: any;
  onRefresh: (bookmark: IBookmark) => Promise<boolean>;
}

export const BookmarkList: React.FC<BookmarkListProps> = props => {
  return (
    <ListGroup
      className="overflow-auto thin-scrollbar w-100 mt-n4"
      style={{ maxHeight: '500px' }}
      onScroll={props.onScroll}
    >
      {(() => {
        if (props.isLoading && !props.bookmarks.length) {
          return <LoadingListItem />;
        }

        if (props.error) {
          return (
            <ListGroup.Item className="text-center text-primary">
              <Alert variant="danger">
                <span>Something went wrong. Please try again later.</span>
                <Button variant="link" className="ml-2" onClick={props.onRetry}>
                  Try Now
                </Button>
              </Alert>
            </ListGroup.Item>
          );
        }

        if (props.bookmarks.length > 0) {
          return (
            <>
              {props.bookmarks.map(bookmark => (
                <ListGroup.Item key={bookmark.assetId}>
                  <BookmarkItemContainer bookmark={bookmark} refetch={() => props.onRefresh(bookmark)} />
                </ListGroup.Item>
              ))}
              {props.isLoading && <LoadingListItem />}
            </>
          );
        } else if (!props.isLoading) {
          return (
            <ListGroup.Item className="text-center">
              <Alert variant="warning" className="d-flex align-items-center py-0">
                <span className="mr-3" style={{ fontSize: 'x-large' }}>
                  <FontAwesomeIcon icon={faFolderOpen} />
                </span>
                No Documents Bookmarked
              </Alert>
            </ListGroup.Item>
          );
        }
      })()}
    </ListGroup>
  );
};

export default BookmarkList;
