import { useEffect, useState } from 'react';

export function useDelayedReset() {
  const [handler, setHandler] = useState<ReturnType<typeof setTimeout> | null>(null);
  // componentDidUnmount: clear timeout to avoid error when modal is closed before
  useEffect(() => {
    return () => {
      if (handler) {
        clearTimeout(handler);
      }
    };
  }, [handler]);
  return setHandler;
}
