import { IGetRoadmaps } from '@amgen/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col } from 'react-bootstrap';

import './roadmap-theme-row.scss';

import { THEME_ICONS } from '../../config/theme-icon';
import { ThemeContextProvider } from '../../contexts';
import { useGroupedRoadmapFeatures } from '../../hooks/feature-utils';
import RoadmapFeatureRow from '../roadmap-feature-row/roadmap-feature-row';

/* eslint-disable-next-line */
export interface RoadmapThemeRowProps {
  theme: IGetRoadmaps;
  quarterIndex: number;
}

export function RoadmapThemeRow(props: RoadmapThemeRowProps) {
  const quarterNumberFeatures = useGroupedRoadmapFeatures(props.theme.features ?? [], props.quarterIndex);
  const prevQuarterFeatures = Object.fromEntries(
    Object.entries(quarterNumberFeatures).filter(([key, _]) => parseInt(key) < props.quarterIndex)
  );
  const maxCountOfLargestQuarter =
    Math.max(...Object.values(quarterNumberFeatures).map(el => el.length)) +
    Object.values(prevQuarterFeatures).flat().length;

  return Object.keys(quarterNumberFeatures).length ? (
    <>
      <Col
        xs="2"
        className="roadmap-theme d-inline-block text-white p-3 mb-0 d-flex align-items-center h1 position-sticky sticky-elements fixed-top"
        style={{ backgroundColor: props.theme.color }}
      >
        <span>
          {THEME_ICONS.get(props.theme.id) && (
            <FontAwesomeIcon className="mr-1 mr-lg-3" icon={THEME_ICONS.get(props.theme.id)!} color="white" />
          )}
          <span className="d-none d-lg-inline">{props.theme.name}</span>
        </span>
      </Col>
      <ThemeContextProvider {...props.theme}>
        <Col
          xs="10"
          className="roadmap-feature-row  py-2 px-0 d-inline-block position-relative roadmap-elements"
          style={{ height: maxCountOfLargestQuarter * 72 }}
        >
          <RoadmapFeatureRow
            {...props.theme}
            quarterIndex={props.quarterIndex}
            quarterNumberFeatures={quarterNumberFeatures}
          />
        </Col>
      </ThemeContextProvider>
    </>
  ) : null;
}

export default RoadmapThemeRow;
