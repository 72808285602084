export default function WebWorker(this: any, args: any) {
  this.onmessage = (e: any) => {
    // eslint-disable-line no-restricted-globals
    if (!e) return;
    const data = e.data;
    const rawData = data.data;
    const exportFields = data.exportFields;
    const attributeDisplayNames = data.attributeDisplayNames;

    const modifyData = rawData.map((doc: any) =>
      Object.fromEntries(
        Array.from(exportFields).map(field => [attributeDisplayNames[field as any], doc.dataAsset[field as any]])
      )
    );

    //convert items of csv cell from string, array to excel readable strings
    const properString = (item: string | []) => {
      switch (typeof item) {
        case 'string':
          return item.replace(/,/g, ' & ').replace(/(\r\n|\n|\r)/gm, '');
        case 'number':
          return item;
        case 'object':
          return item
            ? item instanceof Array
              ? item
                  .join(' ; ') // SPACES DELIBERATELY GIVEN
                  .replace(/,/g, ' ') // replaces ' ,' in each string of array (delimiter for csv is ',')
                  .replace(/(\r\n|\n|\r)/gm, ' ') // replaces ' \N\R' in each string of array
              : item
            : ' ';
        default:
          return ' ';
      }
    };

    /**
     * object to csv convertor
     * @param objArray an array of objects into csv string
     */
    const convertToCSV = (objArray: object | [] | string) => {
      const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
      let str = '';
      for (let i = 0; i < array.length; i++) {
        let line = '';
        for (const index in array[i]) {
          if (line !== '') line += ',';

          line += properString(array[i][index]);
        }

        str += line + '\r\n';
      }
      return str;
    };

    /**
     * ensure no. and shape of keys are similar as of first object in the while array
     * export data to .csv from array of objects (create link <a> and delete)
     * @param items
     * @param fileTitle
     */
    const postCSVBlobBack = (items: object[] | []) => {
      // default takes keys from first object as headers
      const headers = Object.keys(returnADeepClone(items[0] ? items[0] : [])) as never;
      items.unshift(headers);

      // Convert Object to JSON
      const jsonObject = JSON.stringify(items);
      const csv = convertToCSV(jsonObject);
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      e.currentTarget.postMessage([blob, items.length]);
    };

    const returnADeepClone = <T>(input: T): T => {
      return JSON.parse(JSON.stringify(input));
    };

    postCSVBlobBack(modifyData);
  };
}
