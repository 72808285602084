import { IAssetAttribute } from '@amgen/core';
import {
  ATTRIBUTE_NAMES,
  CDOCS_DIAPLAY_ATTTRIBUTE,
  CONFLUENCE_DISPLAY_ATTRIBUTES,
  ELN_DISPLAY_ATTRIBUTES,
  MY_AMGEN_ATTRIBUTES,
  PUBMED_DISPLAY_ATTRIBUTES,
  SHAREPOINT_DISPLAY_ATTRIBUTES,
  SMARTSHEET_DISPLAY_ATTRIBUTES,
  TWITTER_DISPLAY_ATTRIBUTES,
} from '@amgen/feature-asset-attributes';

export const ASSET_ATTRIBUTE_NAMES: NonNullable<{ [key in IAssetAttribute]: string }> = {
  ...ATTRIBUTE_NAMES,
  doc_nav_prefix: 'Content Source',
  extracted_amg_diseases_s: 'Diseases',
  extracted_amg_drugs: 'Drugs',
  extracted_amg_genes: 'Genes',
};

export const ALL_DISPLAY_ATTRIBUTES: IAssetAttribute[] = Array.from(
  new Set([
    ...CDOCS_DIAPLAY_ATTTRIBUTE,
    ...CONFLUENCE_DISPLAY_ATTRIBUTES,
    ...SHAREPOINT_DISPLAY_ATTRIBUTES,
    ...SMARTSHEET_DISPLAY_ATTRIBUTES,
    ...TWITTER_DISPLAY_ATTRIBUTES,
    ...ELN_DISPLAY_ATTRIBUTES,
    ...PUBMED_DISPLAY_ATTRIBUTES,
    ...MY_AMGEN_ATTRIBUTES,
  ])
).sort();
