import { SearchBar } from '@amgen/feature-search';
import classNames from 'classnames';
import React from 'react';
import { useState } from 'react';

import './search-box-section.scss';

import SourcesMetric from '../sources-metric/sources-metric';

export interface SearchBoxSectionProps {
  isMobile: boolean;
  currentPageNumber: number;
  setBlockScroll: (value: boolean) => void;
}

export const SearchBoxSection = (props: SearchBoxSectionProps) => {
  const [isSearchExpanded, setIsSearchExpanded] = useState(window.innerWidth >= 992);

  return (
    <>
      <h1 className="mb-0">
        <div className="d-flex justify-content-center logo-card">
          <img src="assets/images/search-logo.svg" alt="logo-amgen" />
        </div>
      </h1>

      <div
        className={classNames('py-2 text-primary text-center', {
          'landing-section-subtitle': !props.isMobile,
        })}
      >
        Find, Explore and Discover
      </div>
      <SourcesMetric />
      <h1 className="d-flex justify-content-center mt-md-2">
        <SearchBar
          className="landing-page-search-bar"
          isSearchExpanded
          showAdvanceSearchBtn
          showQuickFilters
          searchSize="lg"
          enableSuggestingPlaceholder
          setBlockScroll={props.setBlockScroll}
        />
      </h1>
    </>
  );
};

export default SearchBoxSection;
