import { IAssetAttribute } from '@amgen/core';
import * as Types from '@amgen/core';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export const ABUSER_FACET_CATEGORIES: IAssetAttribute[] = [
  'tags',
  'study_number',
  'sponsor_network',
  'site',
  'product',
  'plant',
  'kc_topic',
  'function',
  'file_type_normalized',
  'external_material',
  'drug_product',
  'doc_owner_s',
  'doc_nav_prefix',
  'country',
  'author_s',
  'asset_type',
];

export const EEA_FACET_CATEGORIES: IAssetAttribute[] = [
  'application_type',
  'amgen_attorney',
  'amgen_paralegal',
  'contract_amgen_company',
  'asset_type',
  'author_s',
  'collaborator',
  'conditions',
  'created_on',
  'country',
  'content_type',
  'contract_ac_contact',
  'contract_effective_date',
  'contract_expiry_date',
  'contract_title',
  'contract_parties',
  'contract_investigator',
  'amgen_law_contacts',
  'department',
  'doc_last_modified',
  'doc_nav_prefix',
  'doc_owner_s',
  'doc_status',
  'doc_subtype',
  'doc_subunit',
  'doc_type',
  'doc_unit',
  'document_approved_date',
  'document_effective_date',
  'drug_product',
  'extracted_indications',
  'product_number',
  'fastlane',
  'end_date',
  'external_material',
  'extracted_amg_diseases_s',
  'extracted_amg_drugs',
  'extracted_amg_genes',
  'extracted_drug_targets',
  'extracted_modalities',
  'extracted_molecules',
  'extracted_therapeutic_areas',
  'file_type_normalized',
  'function',
  'intervention_type',
  'investigator',
  'issn',
  'kc_topic',
  'lang',
  'meddra_indication_nav_prefix',
  'method_nav_prefix',
  'pd_academic_research',
  'pd_innovation',
  'phase',
  'plant',
  'process',
  'region_l',
  'regulation',
  'result_type',
  'request_type',
  'site_location',
  'site',
  'page',
  'sponsor_network',
  'start_date',
  'status',
  'study_number',
  'study_status',
  'supplementary_list',
  'tags',
  'technologies',
  // 'grant_id', // Temporarily removed until solr values are cleaned
];

export const KMOPS_CDOCS_FACET_CATEGORIES: IAssetAttribute[] = [
  'author_s',
  'asset_type',
  'content_type',
  'content_source',
  'created_on',
  'collaborator',
  'department',
  'document_approved_date',
  'document_effective_date',
  'doc_last_modified',
  'doc_owner_s',
  'doc_status',
  'doc_subtype',
  'doc_subunit',
  'drug_product',
  'product_number',
  'extracted_indications',
  'fastlane',
  'external_material',
  'extracted_amg_drugs',
  'extracted_amg_genes',
  'extracted_drug_targets',
  'extracted_modalities',
  'extracted_molecules',
  'extracted_therapeutic_areas',
  'file_type_normalized',
  'function',
  'kc_topic',
  'lang',
  'pd_innovation',
  'pd_academic_research',
  'plant',
  'process',
  'regulation',
  'site',
  'site_location',
  'space_name',
  'sponsor_network',
  'status',
];

export type IGetQueryFilterListVariables = Types.Exact<{
  appName: Types.ApplicationName;
}>;

export type IGetQueryFilterList = {
  getFilterList?: Types.Maybe<
    Pick<Types.IQueryFilterList, 'filterList' | 'defaultFilters' | 'id' | 'parentObjectID' | 'type'>
  >;
};

export const GetFilterList = gql`
  query GetFilterList($appName: ApplicationName!) {
    getFilterList(applicationName: $appName) {
      filterList
      defaultFilters
      id
      parentObjectID
      type
      __typename
    }
  }
`;

export function useGetQueryFilterList(
  baseOptions: Apollo.QueryHookOptions<IGetQueryFilterList, IGetQueryFilterListVariables>
) {
  return Apollo.useQuery<IGetQueryFilterList, IGetQueryFilterListVariables>(GetFilterList, baseOptions);
}
