import { ClinicalContext } from '@amgen/shared';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useContext } from 'react';
import { Badge } from 'react-bootstrap';
import { useHistory } from 'react-router';

import './selected-study.module.scss';

/* eslint-disable-next-line */
export interface SelectedStudyProps {}

export function SelectedStudy(props: SelectedStudyProps) {
  const history = useHistory();
  const { clinicalType, clinicalId, product } = useContext(ClinicalContext);

  return clinicalType === 'study' ? (
    <Badge
      className={classNames('applied-filter d-inline-flex align-items-center border rounded-pill mx-3 mb-1 px-3 ')}
      title={`${clinicalType}: ${clinicalId}`}
    >
      <span className="text-overflow-ellipsis">{clinicalId}</span>
      <span
        className={classNames('btn-clear cursor-pointer rounded-circle ml-2 mr-n2')}
        onClick={() => history.push(`/clinical/product/${product?.product}`)}
      >
        <FontAwesomeIcon className="m-1" icon={faTimes} />
      </span>
    </Badge>
  ) : null;
}

export default SelectedStudy;
