import React, { useContext } from 'react';

import './date-filter.scss';

/* eslint-disable-next-line */
export interface DateFilterProps {}

export const DateFilter = (props: DateFilterProps) => {
  // const { handleDateBarSelected, dateFilterOption, loading } = useContext(DateFilterContext);

  // const onEvents = {
  //   click: (...rest: any) => {
  //     handleDateBarSelected(rest[0].name);
  //   },
  // };

  return <></>;
};

export default DateFilter;
