import { FiltersObject } from '@amgen/shared';
import React from 'react';

import './search-box.scss';

import SearchBoxContextProvider from '../search-box-context-provider/search-box-context-provider';
import SearchBoxAdvanceSearchDropdown, {
  SearchBoxAdvanceSearchDropdownProps,
} from './search-box-advance-search-dropdown/search-box-advance-search-dropdown';
import SearchBoxClearButton, { SearchBoxClearButtonProps } from './search-box-clear-button/search-box-clear-button';
import SearchBoxFilterPill, { SearchBoxFilterPillProps } from './search-box-filter-pill/search-box-filter-pill';
import SearchBoxInputGroup, {
  ISearchBoxInputGroupComposition,
  SearchBoxInputGroupProps,
} from './search-box-input-group/search-box-input-group';
import SearchBoxMobileCloseButton, {
  SearchBoxMobileCloseButtonProps,
} from './search-box-mobile-close-button/search-box-mobile-close-button';
import SearchBoxMobileSearchButton from './search-box-mobile-search-button/search-box-mobile-search-button';
import SearchBoxQueryTerm, { SearchBoxQueryTermProps } from './search-box-query-term/search-box-query-term';
import SearchBoxSearchButton, { SearchBoxSearchButtonProps } from './search-box-search-button/search-box-search-button';
import SearchBoxTypeahead, { SearchBoxTypeaheadProps } from './search-box-typeahead/search-box-typeahead';

export interface SearchBoxProps {
  isSearchExpanded?: boolean;
  setIsSearchExpanded?: (value: boolean) => void;
  searchSize?: 'sm' | 'lg';
  showAdvanceSearch?: boolean;
  showQuickFilters?: boolean;
  enableSuggestingPlaceholder?: boolean;
  setBlockScroll?: (value: boolean) => void;
  onExternalSearch?: (keyword: string, filters: FiltersObject) => void;
}

export interface ISearchBoxComposition {
  AdvanceSearchDropdown: React.FC<SearchBoxAdvanceSearchDropdownProps>;
  ClearButton: React.FC<SearchBoxClearButtonProps>;
  InputGroup: React.FC<SearchBoxInputGroupProps> & ISearchBoxInputGroupComposition;
  MobileCloseButton: React.FC<SearchBoxMobileCloseButtonProps>;
  MobileSearchButton: React.FC<SearchBoxSearchButtonProps>;
  QueryTerm: React.FC<SearchBoxQueryTermProps>;
  FilterPill: React.FC<SearchBoxFilterPillProps>;
  SearchButton: React.FC<SearchBoxSearchButtonProps>;
  Typeahead: React.FC<SearchBoxTypeaheadProps>;
}

export const SearchBox: React.FC<SearchBoxProps> & ISearchBoxComposition = ({
  children,
  isSearchExpanded,
  setIsSearchExpanded,
  searchSize,
  showAdvanceSearch,
  enableSuggestingPlaceholder,
  setBlockScroll,
  onExternalSearch,
  showQuickFilters,
}) => {
  return (
    <SearchBoxContextProvider
      isSearchExpanded={isSearchExpanded}
      setIsSearchExpanded={setIsSearchExpanded}
      searchSize={searchSize}
      showAdvanceSearch={showAdvanceSearch}
      enableSuggestingPlaceholder={enableSuggestingPlaceholder}
      setBlockScroll={setBlockScroll}
      onExternalSearch={onExternalSearch}
      showQuickFilters={showQuickFilters}
    >
      {children}
    </SearchBoxContextProvider>
  );
};

SearchBox.AdvanceSearchDropdown = SearchBoxAdvanceSearchDropdown;
SearchBox.ClearButton = SearchBoxClearButton;
SearchBox.InputGroup = SearchBoxInputGroup;
SearchBox.MobileCloseButton = SearchBoxMobileCloseButton;
SearchBox.MobileSearchButton = SearchBoxMobileSearchButton;
SearchBox.FilterPill = SearchBoxFilterPill;
SearchBox.SearchButton = SearchBoxSearchButton;
SearchBox.Typeahead = SearchBoxTypeahead;
SearchBox.QueryTerm = SearchBoxQueryTerm;

export default SearchBox;
