import { useLogoutApp } from '@amgen/auth';
import {
  HamburgerMenu,
  HamburgerMenuItem,
  HamburgerMenuItemChild,
  Header,
  HeaderCenter,
  HeaderLeft,
  HeaderRight,
  Logo,
} from '@amgen/components';
import { useEnvironment } from '@amgen/core';
import { BookmarkPopover } from '@amgen/feature-bookmarks';
import { DataRequestSubmenu, NewRequestModal } from '@amgen/feature-governance-dashboard';
import { ProfileMenu } from '@amgen/feature-navigation';
import { SearchBar } from '@amgen/feature-search';
import { IGD_ACTIONS, MatomoContext, SEARCH_APP_FEATURE_LIST } from '@amgen/shared';
import { faBookmark } from '@fortawesome/free-regular-svg-icons';
import {
  faCog,
  faExternalLinkAlt,
  faHdd,
  faSignOutAlt,
  faUpload,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import './eea-header.scss';

/* eslint-disable-next-line */
export interface EEAHeaderProps {}

export const EEAHeader = (props: EEAHeaderProps) => {
  const isContentOnboarding = !!useRouteMatch({ path: '/data-requests', exact: false, strict: false });
  const isSettingPage = !!useRouteMatch({ path: '/settings', exact: false, strict: false });
  const { logout } = useLogoutApp();
  const history = useHistory();

  const { support } = useEnvironment();
  let authTabs = [''];
  if (sessionStorage.authTabs) {
    authTabs = JSON.parse(sessionStorage.authTabs);
  }
  const user = JSON.parse(sessionStorage?.user);
  const authorizedTabs = authTabs?.includes('IGD Admin');
  const { trackApplicationFeature } = useContext(MatomoContext);
  const [isSearchExpanded, setIsSearchExpanded] = useState(window.innerWidth >= 992);
  const [showRequestModal, setShowRequestModal] = useState(false);
  const { push } = useHistory();
  useEffect(() => {
    const handler = () => setIsSearchExpanded(window.innerWidth >= 992);
    window.addEventListener('resize', handler);
    return () => window.removeEventListener('resize', handler);
  }, [isSearchExpanded]);
  const userGuide = () => {
    window.open(support.guideUrl);
  };

  const onSettingClick = () => {
    localStorage.setItem('current_url1', window.location.href);
  };

  return (
    <>
      <div className="sticky-eea-Header">
        <Header className="pr-0">
          <HeaderLeft>
            <HamburgerMenu className="d-lg-none">
              <HamburgerMenuItem
                content={<Logo className="d-lg-flex" img="assets/images/eea-logo.svg" appName="EEA Search" />}
              />
              <HamburgerMenuItem icon={faUserCircle} content={user.fullName} />
              <HamburgerMenuItem to="/" icon={faHdd} content={'Results'} />
              {/* <HamburgerMenuItem to="/reports" icon={faFile}>
            Reports
          </HamburgerMenuItem> */}
              <HamburgerMenuItem to="/bookmarks" icon={faBookmark} content={'Bookmarks'} />
              <HamburgerMenuItem to="/data-requests" icon={faUpload} hasDropdown content={'Content Onboarding'}>
                <HamburgerMenuItemChild
                  onClick={() => {
                    setShowRequestModal(true);
                    trackApplicationFeature(SEARCH_APP_FEATURE_LIST.IGD, IGD_ACTIONS.ADD_REQ_FROM_MENU);
                  }}
                  content="New Request"
                />
                <HamburgerMenuItemChild to="/data-requests/" content="Request Tracker" />
              </HamburgerMenuItem>
              <HamburgerMenuItem
                to="/settings/"
                icon={faCog}
                content={<span onClick={onSettingClick}>Settings</span>}
              />
              <HamburgerMenuItem
                icon={faExternalLinkAlt}
                content={<span onClick={userGuide}>User Guide</span>}
              ></HamburgerMenuItem>
              <HamburgerMenuItem icon={faSignOutAlt} content={<span onClick={logout}>Logout</span>} />
            </HamburgerMenu>

            <Logo className="d-none d-lg-flex" img="assets/images/eea-logo.svg" appName="EEA Search" />
            {/* {sessionStorage.getItem('externalAppName') && (
            <CustomSwitch externalAppName={sessionStorage.getItem('externalAppName') || ''} appDetails={appDetails} />
          )} */}
          </HeaderLeft>

          <HeaderCenter className="justify-content-center header-center">
            {isContentOnboarding ? (
              <h1 className="mb-0 text-center text-white content-onboarding-title">Content Onboarding</h1>
            ) : isSettingPage ? (
              <h1 className="mb-0 text-center text-white content-onboarding-title">Settings</h1>
            ) : (
              <>
                <SearchBar
                  isSearchExpanded={isSearchExpanded}
                  setIsSearchExpanded={setIsSearchExpanded}
                  className="d-none d-lg-block"
                />
                {!isSearchExpanded && (
                  <Logo
                    className="d-flex d-lg-none justify-content-center"
                    img="assets/images/eea-logo.svg"
                    appName="EEA Search"
                  />
                )}
              </>
            )}
          </HeaderCenter>

          <HeaderRight
            className={classNames('justify-content-end', {
              'justify-content-end flex-lg-grow-0 flex-sm-grow-1 flex-grow-1 flex-md-grow-1': isSearchExpanded,
            })}
          >
            {/* <BookmarkPopover className="d-none d-lg-flex" /> Neha - DO NOT REMOVE THIS CODE. only hiding for may release. Will enable this later*/}

            <ProfileMenu className="d-none d-lg-flex" user={user.fullName} onLogOut={logout}>
              <DataRequestSubmenu />
            </ProfileMenu>

            {(!isContentOnboarding || !isSettingPage) && (
              <SearchBar
                className="d-flex d-lg-none mobile-header"
                isSearchExpanded={isSearchExpanded}
                setIsSearchExpanded={setIsSearchExpanded}
              />
            )}
          </HeaderRight>
        </Header>
      </div>
      <NewRequestModal
        show={showRequestModal}
        onHide={() => setShowRequestModal(false)}
        onSuccess={() => push('data-requests/my-requests')}
      />
    </>
  );
};

export default EEAHeader;
