import { useFacetQuery, useSearchUiConfigsQuery } from '@amgen/api';
import {
  ABUSERTopFacetValuesCatalyst,
  ABUSERTopFacetValuesCW,
  ABUSERTopFacetValuesFTE,
  ABUSERTopFacetValuesOpsKM,
  ApplicationName,
  useEnvironment,
} from '@amgen/core';
import { FeatureAppSection } from '@amgen/feature-app-section';
import { FeatureChatbotContainer } from '@amgen/feature-chatbot';
import { AppliedFiltersBar } from '@amgen/feature-facets';
import { ApplicationFeedback } from '@amgen/feature-feedbacks';
import { KnowledgePanel, NewsAndKnowledgePanelContainer } from '@amgen/feature-knowledge-panel';
import { ViewSavedButton } from '@amgen/feature-navigation';
import { TempResultPageHeader } from '@amgen/feature-result-sections';
import { SaveSearchButton } from '@amgen/feature-saved-search';
import { SearchResultPaginator, useClearFiltersOnNavFromBookmark } from '@amgen/feature-search';
import {
  AppContext,
  ClinicalContextProvider,
  FacetsPanelProvider,
  MatomoContext,
  NewsContextProvider,
  SEARCH_APP_FEATURE_LIST,
  useSearchQueryValue,
} from '@amgen/shared';
import { useOktaAuth } from '@okta/okta-react';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { useMemo } from 'react';
import { Button, Modal, Row } from 'react-bootstrap';

import './search-page.scss';

import { AcronymSearchResult } from '../acronym-search-result/acronym-search-result';
import { ClusterSearchButton } from '../cluster-search-button/cluster-search-button';
import ResultView from '../result-view/result-view';
import SearchFacetContainer from '../search-facet-container/search-facet-container';
import { SpellcheckLink } from '../spellcheck-link/spellcheck-link';
import MobileTopSearchFilters from '../top-search-filters/mobile-top-search-filters';
import TopSearchFilters from '../top-search-filters/top-search-filters';
import { SearchPageContainer } from './search-page-container/search-page-container';

// import { MSALAuthPOC } from '../msal-auth-poc/msal-auth-poc';
// import { FeatureBannerMessage } from '@amgen/feature-myamgen-news';
// import { FeatureAcronym } from '../../../../../feature-acronym/src/lib/feature-acronym';

export interface TempSearchPageProps {
  exportButton?: ReactNode;
  trackMatomoPage?: string;
  hasClusterSearch?: boolean;
}
function checkIpadMobile() {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor);
  return check;
}

export const TempSearchPage: React.FC<TempSearchPageProps> = props => {
  const { selectedResultTab, setSearchQuery, setHorizonCompanyCode } = useContext(AppContext);
  const { trackApplicationFeature } = useContext(MatomoContext);
  const [news, setNews] = useState([]);
  const [newsLoading, setnewsLoading] = useState(true);
  const query = useSearchQueryValue();
  const searchTerm = useSearchQueryValue();
  //const cleanQuery = cleanQueryFunc();
  const configData = useSearchUiConfigsQuery();
  const data = configData?.response?.searchui_configs;
  useClearFiltersOnNavFromBookmark();
  // const filters = useAppliedFiltersWithPersistentValues();
  const { authState } = useOktaAuth();
  const { api, newsToken } = useEnvironment();
  const appName: ApplicationName = sessionStorage.getItem('appName')
    ? ApplicationName[sessionStorage.getItem('appName')!]
    : ApplicationName.EEA;
  const isMobile = checkIpadMobile();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showTopFilters, setTopFilters] = useState(false);
  const [enableChatbot, setChatbotEnable] = useState(false);
  const [chatbotChannel, setChatbotChannel] = useState('');
  const [chatbotURL, setChatbotURL] = useState('');
  const abTestingGroup = JSON.parse(sessionStorage.getItem('abTestingGroup') as string);
  const userEmployeeType = sessionStorage.getItem('employeeType');

  const cleanSearchQuery = () => {
    let modifiedQuery = query;
    const isKeyword =
      (query?.startsWith('"') && query.endsWith('"')) || (query?.startsWith("'") && query.endsWith("'"));
    // modifiedQuery = modifiedQuery ? modifiedQuery.replaceAll('\\', '') : '';
    // modifiedQuery = modifiedQuery?.replaceAll('"', '');
    // modifiedQuery = modifiedQuery?.replaceAll("'", '');
    // if (isKeyword) {
    //   return '"' + modifiedQuery + '"';
    // } else {
    //   return modifiedQuery;
    // }
    if (modifiedQuery.includes('AND') || modifiedQuery.includes('OR')) {
      return modifiedQuery;
    } else {
      modifiedQuery = modifiedQuery ? modifiedQuery.replaceAll('\\', '') : '';
      modifiedQuery = modifiedQuery?.replaceAll('"', '');
      modifiedQuery = modifiedQuery?.replaceAll("'", '');
      if (isKeyword) {
        return '"' + modifiedQuery + '"';
      } else {
        return modifiedQuery;
      }
    }
  };

  const cleanQuery = cleanSearchQuery();

  const user = JSON.parse(sessionStorage.user);
  const handleClose = () => setIsModalOpen(false);

  useEffect(() => {
    setSearchQuery(query);
  }, [query]);

  useEffect(() => {
    if (isMobile) {
      setIsModalOpen(true);
    }
    // if the user is coming from KG page , then browser scroll is not visible for abusers due to removing of chatbot from the page
    document.body.style.overflow = 'scroll';
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    //Remove amgen news api calls

    // if (query !== '*:*' && query !== '') {
    //   setnewsLoading(true);
    //   getNews(authState, api.newsUrl, newsToken?.token, query).then(res => {
    //     setNews(res);
    //     setnewsLoading(false);
    //   });
    // }
    //enableABtestforTopFilters();
    setFilters();
    enableABTestforChatbot();
  }, [authState, query, data]);

  const variables = useMemo(
    () => ({
      solrHandler: appName === 'DVIEW' ? '/dview-search-select' : '/search-select',
      query: cleanQuery,
      filters: [],
      excludeCount: false,
      fields: [{ facetLimit: -1, minCount: 0, name: 'content_source' }],
      appName,
      userType: sessionStorage.getItem('employeeType') || '',
    }),
    [appName, cleanQuery]
  );

  const { data: topFilterData, loading } = useFacetQuery({
    variables,
    skip: abTestingGroup && appName !== 'OpsKM' && appName !== 'Catalyst',
  });

  const setFilters = () => {
    // const topFilters = { 'isInTestGroup:top-filters': '1', 'variation:top-filters': 'top-filters-included' };
    // const original = { 'isInTestGroup:top-filters': '1', 'variation:top-filters': 'original' };
    data?.forEach(item => {
      if (
        Object.keys(item).includes('top_filters_config') &&
        item['top_filters_config']['enable_top_filters'] === 'false'
      ) {
        if (
          item['top_filters_config']['cost_centers'].includes(user.costCenter) ||
          item['top_filters_config']['functional_groups'].includes(user.functionalArea)
        ) {
          //localStorage.setItem('PiwikAbTesting', JSON.stringify(topFilters));
          setTopFilters(true);
        } else {
          // localStorage.setItem('PiwikAbTesting', JSON.stringify(original));
          setTopFilters(false);
        }
      } else if (
        Object.keys(item).includes('top_filters_config') &&
        item['top_filters_config']['enable_top_filters'] === 'true'
      ) {
        // localStorage.setItem('PiwikAbTesting', JSON.stringify(topFilters));
        setTopFilters(true);
      }
    });
  };

  const checkChatbotAuth = () => {
    const chatbotPiwik = { 'isInTestGroup:chatbot': '1', 'variation:chatbot': 'top-chatbot-enabled' };
    const original = { 'isInTestGroup:chatbot': '1', 'variation:chatbot': 'original' };
    // user details from getapplication api stored in session storage
    const sessionUserData = sessionStorage.getItem('user') || 'user';
    const userDetails = {
      companyCode: JSON.parse(sessionUserData)?.companyCode,
      costCenter: JSON.parse(sessionUserData)?.costCenter,
      adGroups: JSON.parse(sessionUserData)?.adGroups,
    };
    // in case if the multiple_chatbot_configs is entirely removed from  json
    if (data?.filter(i => Object.keys(i).includes('multiple_chatbot_configs')).length !== 0) {
      const chatbotChannelType = currentChatbot => {
        // if the enable for all is false then check if the conditions are matched for chatbot
        if (currentChatbot && userDetails) {
          const companyCodeMatched =
            currentChatbot.chatbot_channel === 'horizon' && currentChatbot.companyCode && userDetails.companyCode
              ? currentChatbot.companyCode.filter(obj => userDetails.companyCode.indexOf(obj) !== -1)
              : [];
          const adGroupsMatched =
            currentChatbot.adGroups && userDetails.adGroups
              ? currentChatbot.adGroups.filter(obj => userDetails.adGroups.indexOf(obj) !== -1)
              : [];
          const costCenterMatched =
            currentChatbot.cost_centers && userDetails.costCenter
              ? currentChatbot.cost_centers.filter(obj => userDetails.costCenter.indexOf(obj) !== -1)
              : [];
          // if any of the conditions are matched then the current chatbot is selected for chatbot channel
          if (companyCodeMatched.length > 0 || adGroupsMatched.length > 0 || costCenterMatched.length > 0) {
            if (currentChatbot.chatbot_channel === 'horizon') {
              setHorizonCompanyCode(true);
            }
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      };
      const multipleChatbotData =
        data?.filter(i => Object.keys(i).includes('multiple_chatbot_configs')).length === 0
          ? false
          : data?.filter(i => Object.keys(i).includes('multiple_chatbot_configs'))[0]['multiple_chatbot_configs'];
      // iterate through the objects and the ones which are in consideration for being the chatbot channel is appended with isActive key
      const updatedChatbotDataActive = () => {
        multipleChatbotData &&
          multipleChatbotData.map((item, index) => {
            const func = () => {
              if (item) {
                if (item['display_chatbot'] === 'false') {
                  return false;
                } else if (item['display_chatbot'] === 'true') {
                  if (item['enable_for_all'] === 'true') {
                    return true;
                  } else if (item['enable_for_all'] === 'false') {
                    return chatbotChannelType(item);
                  }
                }
              } else {
                return false;
              }
            };
            func() ? (item['isActive'] = true) : (item['isActive'] = false);
          });
        return multipleChatbotData;
      };
      // if more than one chatbot is in consideration for chatbot channel then checked with preference key
      const chatBotActiveFilteredData =
        updatedChatbotDataActive() !== undefined &&
        updatedChatbotDataActive()
          .filter(i => i.isActive === true)
          .sort((a, b) => a.preference - b.preference);
      if (chatBotActiveFilteredData && chatBotActiveFilteredData.length > 0) {
        if (chatBotActiveFilteredData.filter(i => i.chatbot_channel === 'horizon').length > 0) {
          setChatbotChannel(
            chatBotActiveFilteredData.filter(i => i.chatbot_channel === 'horizon')[0]['chatbot_channel']
          );
          setChatbotURL(chatBotActiveFilteredData.filter(i => i.chatbot_channel === 'horizon')[0]['url']);
          trackApplicationFeature(
            SEARCH_APP_FEATURE_LIST.ENTERPRISE_VA_CHANNEL,
            chatBotActiveFilteredData.filter(i => i.chatbot_channel === 'horizon')[0]['chatbot_channel']
          );
        } else {
          setChatbotChannel(chatBotActiveFilteredData[0]['chatbot_channel']);
          setChatbotURL(chatBotActiveFilteredData[0]['url']);
          trackApplicationFeature(
            SEARCH_APP_FEATURE_LIST.ENTERPRISE_VA_CHANNEL,
            chatBotActiveFilteredData[0]['chatbot_channel']
          );
        }

        localStorage.setItem('PiwikAbTesting', JSON.stringify(chatbotPiwik));
        setChatbotEnable(true);
      } else {
        localStorage.setItem('PiwikAbTesting', JSON.stringify(original));
        setChatbotEnable(false);
      }
    }
  };

  const enableABtestforTopFilters = () => {
    window._paq = window._paq || [];
    window._paq?.push([
      'AbTesting::create',
      {
        name: 'top-filters', // you can also use '2' (ID of the experiment) to hide the name
        percentage: 100,
        includedTargets: [{ attribute: 'url', inverted: '0', type: 'any', value: '' }],
        excludedTargets: [],
        variations: [
          {
            name: 'original',
            activate: function () {
              setFilters();
            },
          },
          {
            name: 'top-filters-included', // you can also use '3' (ID of the variation) to hide the name
            activate: function () {
              setFilters();
            },
          },
        ],
        trigger: function () {
          return true; // here you can further customize which of your visitors will participate in this experiment
        },
      },
    ]);
  };

  const enableABTestforChatbot = () => {
    window._paq = window._paq || [];
    window._paq?.push([
      'AbTesting::create',
      {
        name: 'chatbot', // you can also use '5' (ID of the experiment) to hide the name
        percentage: 100,
        includedTargets: [{ attribute: 'url', inverted: '0', type: 'any', value: '' }],
        excludedTargets: [],
        startDateTime: '2022/08/03 00:00:00 UTC',
        variations: [
          {
            name: 'original',
            activate: function (event) {
              checkChatbotAuth();
              // usually nothing needs to be done here
            },
          },
          {
            name: 'chatbot-enabled', // you can also use '6' (ID of the variation) to hide the name
            activate: function (event) {
              checkChatbotAuth();
              // eg $('#btn').attr('style', 'color: ' + this.name + ';');
            },
          },
        ],
        trigger: function () {
          return true; // here you can further customize which of your visitors will participate in this experiment
        },
      },
    ]);
  };

  const MobileModal = props => (
    <Modal
      show={props.show}
      onHide={props.hide}
      scrollable
      centered
      restoreFocus={false}
      animation={false}
      backdrop="static"
    >
      <Modal.Header className="flex-column border-primary px-4" style={{ backgroundColor: '#f4f8fc' }} closeButton>
        <Modal.Title as="h1" className="text-primary justify-center"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          For best results, use Intelligent Search from your computer. A more mobile-friendly release is coming soon!
        </p>

        <p>
          To search for employee information, click{' '}
          <a href="https://myamgen.amgen.com/myAmgen/s/directory" target="_blank" rel="noreferrer">
            here
          </a>{' '}
          for the Directory on MyAmgen
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.hide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
  return (
    <>
      {isMobile && <MobileModal show={isModalOpen} hide={handleClose} />}
      {/* <SearchContextProvider query={cleanQuery} filters={filters} trackMatomoPage={props.trackMatomoPage} isExpandable> */}
      <ClinicalContextProvider>
        <NewsContextProvider news={news} loading={newsLoading}>
          <FacetsPanelProvider>
            <SearchPageContainer>
              {props.children}

              <SaveSearchButton />
              <ViewSavedButton />
              <SearchFacetContainer />
              <SearchPageContainer.LeftFlank>
                <TempResultPageHeader showNote={true} />
                {showTopFilters && selectedResultTab === 'All Results' ? (
                  isMobile ? (
                    <MobileTopSearchFilters classnames="mt-4 pl-3" />
                  ) : (
                    <TopSearchFilters
                      topFilterData={
                        appName === 'OpsKM'
                          ? ABUSERTopFacetValuesOpsKM
                          : appName === 'Catalyst'
                          ? ABUSERTopFacetValuesCatalyst
                          : abTestingGroup && appName === 'EEA'
                          ? userEmployeeType === 'FTE'
                            ? ABUSERTopFacetValuesFTE
                            : ABUSERTopFacetValuesCW
                          : topFilterData?.facet?.[0].values ?? []
                      }
                      // classnames="mt-4 pl-3"
                      classnames="pl-3"
                    />
                  )
                ) : (
                  <></>
                )}
                <FeatureAppSection classnames="py-3 pl-3" container="search-page" />
                {/* <Row noGutters className="px-3"> */}
                <Row noGutters>
                  {/* <SearchFacetContainer /> */}
                  <SearchPageContainer.Results>
                    <NewsAndKnowledgePanelContainer hideInTableView mobileView>
                      {/* {selectedResultTab !== 'Chat' && <FeatureBannerMessage />}  Neha - Hiding the old  accountment banner for now */}
                      {/* <FeatureMyamgenNews /> */}
                      {isMobile ? <KnowledgePanel /> : null}
                      {/* <FeatureChatbotContainer /> */}
                    </NewsAndKnowledgePanelContainer>
                    <TempResultPageHeader showNote={false} />
                    <SpellcheckLink />
                    {(selectedResultTab === 'Recommended' || selectedResultTab === 'All Results') && (
                      <AppliedFiltersBar isClearable parentSource="homePage" />
                    )}
                    {props.hasClusterSearch && <ClusterSearchButton />}
                    <ResultView pageNo={0} />
                    {selectedResultTab === 'All Results' && <SearchResultPaginator />}
                  </SearchPageContainer.Results>
                  {selectedResultTab !== 'Chat' ? (
                    <SearchPageContainer.EmptyResult>
                      <SpellcheckLink />
                      {(selectedResultTab === 'Recommended' || selectedResultTab === 'All Results') && (
                        <AppliedFiltersBar isClearable parentSource="homePage" />
                      )}
                    </SearchPageContainer.EmptyResult>
                  ) : (
                    ''
                  )}
                </Row>
              </SearchPageContainer.LeftFlank>

              <SearchPageContainer.RightFlank>
                <NewsAndKnowledgePanelContainer hideInTableView>
                  {appName !== 'OpsKM' &&
                    appName !== 'Catalyst' &&
                    appName !== 'DVIEW' &&
                    !abTestingGroup &&
                    enableChatbot &&
                    chatbotChannel &&
                    chatbotURL && <FeatureChatbotContainer chatbotChannel={chatbotChannel} chatbotURL={chatbotURL} />}

                  {/* {appName !== 'OpsKM' && appName !== 'Catalyst' && appName !== 'DVIEW' && <MSALAuthPOC />} */}

                  {searchTerm !== '*:*' && appName !== 'OpsKM' && appName !== 'Catalyst' && appName !== 'DVIEW' && (
                    <AcronymSearchResult />
                  )}
                  {/* {selectedResultTab !== 'Chat' && <FeatureBannerMessage />}    Neha - Hiding the old  accountment banner for now */}
                  {/* <FeatureMyamgenNews /> */}
                  <div id="knowledge-panel-container">
                    <KnowledgePanel />
                  </div>
                </NewsAndKnowledgePanelContainer>
              </SearchPageContainer.RightFlank>
            </SearchPageContainer>
          </FacetsPanelProvider>
        </NewsContextProvider>
      </ClinicalContextProvider>
      <ApplicationFeedback />
      {/* </SearchContextProvider> */}
    </>
  );
};

export default TempSearchPage;
