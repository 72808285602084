import { useEnvironment } from '@amgen/core';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DOMPurify from 'dompurify';
import React from 'react';
import { Badge } from 'react-bootstrap';

import './unauthorized-error.scss';

/* eslint-disable-next-line */
export interface UnauthorizedErrorProps {}

export const UnauthorizedError = (props: UnauthorizedErrorProps) => {
  const isAuthorized = JSON.parse(sessionStorage.isAuthorized);
  const appDetails = JSON.parse(sessionStorage.appDetails);
  const { support, appHosts, appName } = useEnvironment();
  const sanitizedMail = DOMPurify.sanitize(support.email);
  const hasOtherAccessibleApps = appDetails?.filter(appInfo => appInfo.accessible).length > 0;

  const errorMessage =
    appName === 'EEA'
      ? 'Sorry, access to Intelligent Search is only restricted to Amgen Staff (FTE’s) at this point of time.'
      : `Sorry, you don't have permission to access ${appName} Application.`;

  return (
    <div className="d-flex justify-content-center align-items-center py-3" style={{ height: '100vh' }}>
      <div className="text-center shadow-lg bg-white px-5 pb-4 w-50 rounded">
        <img className="access-denied mb-3" src="assets/images/access-denied.png" alt="no access/locked" />

        <div className="my-4">
          <h1>
            <Badge pill className="access-denied-pill ml-n4">
              Access Denied
            </Badge>
          </h1>

          <h1 className="font-weight-bolder py-2">{errorMessage}</h1>
          {appName !== 'EEA' ? (
            <a
              className="h2 d-block"
              href={DOMPurify.sanitize(`mailto: ${sanitizedMail}?subject=Request for access to ${appName} Application`)}
            >
              Drop an email for access
              <FontAwesomeIcon className="mx-2" icon={faEnvelope} />
            </a>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default UnauthorizedError;
