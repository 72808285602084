import { useSearchQuery } from '@amgen/api';
import { DEFAULT_SEARCH_QUERY, useEnvironment } from '@amgen/core';
import { useAssetAttachmentField } from '@amgen/feature-search';
import { AppContext, AssetContext, FiltersObject, useAppliedFilters } from '@amgen/shared';
import React, { useContext, useMemo, useState } from 'react';
import { Dropdown } from 'react-bootstrap';

import './item.scss';

import { IAssetActionsMenuItemState, ItemContext } from './item-context';
import { Name, NameProps } from './name/name';
import { Popup, PopupComposition, PopupProps } from './popup/popup';

export interface ItemProps {
  actionType: string;
  href?: string;
  target?: string;
  rel?: string;
}

export interface ItemComposition {
  Name: React.FC<NameProps>;
  Popup: React.FC<PopupProps> & PopupComposition;
}

export const Item: React.FC<ItemProps> & ItemComposition = ({ children, actionType, ...props }) => {
  const { internalSources } = useContext(AppContext);

  const { docSolrAsset } = useContext(AssetContext);
  const { dataAsset: asset } = docSolrAsset;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { attachmentField } = useAssetAttachmentField(docSolrAsset.dataAsset!);
  const attachmentId = (docSolrAsset?.dataAsset?.[attachmentField] as string) ?? '';
  const filters = useAppliedFilters(
    attachmentId
      ? new FiltersObject()
          .add('attachment', 'content_type')
          .add(attachmentId, attachmentField || 'parent_object_id')
          .add('*', 'filename')
      : new FiltersObject()
  );
  const providedValue = useMemo<IAssetActionsMenuItemState>(() => ({ isModalOpen, setIsModalOpen }), [
    isModalOpen,
    setIsModalOpen,
  ]);
  const { appName } = useEnvironment();

  const { data } = useSearchQuery({
    variables: {
      solrHandler: appName === 'DVIEW' ? '/dview-search-select' : '/search-select',
      query: DEFAULT_SEARCH_QUERY,
      filters,
      start: 0,
      page: 0,
      count: 10,
      includeDocs: false,
      noShortContent: false,
    },
    skip: !attachmentId || !asset?.has_attachment,
    fetchPolicy: 'cache-first',
  });

  const isInternalSource =
    docSolrAsset.dataAsset?.content_source && internalSources?.includes(docSolrAsset.dataAsset?.content_source);

  const AssetActionsMenuItemsMap = new Map<string, boolean>([
    ['content-btn', !!asset?.short_content ?? false],
    ['attachments-btn', (data?.search?.resultCount ?? 0) > 0 ?? asset?.has_attachment ?? false],
    ['attendee-btn', asset?.has_attendee ?? false],
    ['docContent-btn', (asset?.has_attachment && !!asset?.attachment_content) ?? false],
    ['downloads-btn', (!!asset?.filename && !isInternalSource) ?? false],
    ['inquiry-btn', !!asset?.survey_id_account_id ?? false],
    ['inquiry-link', !!asset?.inqlink ?? false],
    ['email-link', !!asset?.linktoemailresponse ?? false],
    ['postal-link', !!asset?.linktopostalresponse ?? false],
  ]);

  return AssetActionsMenuItemsMap.get(actionType) ? (
    <ItemContext.Provider value={providedValue}>
      <Dropdown.Item key={`${actionType}${asset?.id}`} href={props.href} target={props.target} rel={props.rel}>
        {children}
      </Dropdown.Item>
    </ItemContext.Provider>
  ) : null;
};

Item.Name = Name;
Item.Popup = Popup;

export default Item;
