import { useFacetQuery } from '@amgen/api';
import { IAssetAttribute, IFacetValue, useEnvironment } from '@amgen/core';
import { FacetContext, FacetsPanelContext, useSearchQueryValue, useToast } from '@amgen/shared-kmi';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import './facet-dropdown.scss';

import { useAppliedFiltersWithPersistentValues } from '../../persistent-filter-values';
import { useFilterCategoryOperations, useFilterOperations } from '../facets-panel/hooks';
import { useFacetComponents } from '../use-facet-components';

export interface FacetDropdownProps {
  category: IAssetAttribute;
  categoryName: string | undefined;
  excludeCount?: boolean;
  isExpand?: boolean;
  currentSelectedCategory?: string;
}

export const FacetDropdown: React.FC<FacetDropdownProps> = props => {
  const { onCategoryClear } = useFilterCategoryOperations();
  const { handleFilterToggle } = useFilterOperations();
  const { appliedFilters } = useContext(FacetContext);
  const { selectedFilters } = useContext(FacetsPanelContext);
  const { DropdownItems, FacetContainer } = useFacetComponents(props.category);
  const query = useSearchQueryValue();
  const filters = useAppliedFiltersWithPersistentValues();
  const [hasNext, setHasNext] = useState(true);
  const [hasValue, setHasValue] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>();
  const [facetValues, setFacetValues] = useState<IFacetValue[]>([]);
  const [count, setCount] = useState(1);
  const toast = useToast();
  const [page, setPage] = useState(0);
  const [showMore, setShowMore] = useState(false);
  const [updatedFilters, setUpdatedFilters] = useState(filters);
  useEffect(() => {
    setPage(0);
    setHasNext(true);
  }, [appliedFilters, query, count]);
  const categoryItems = selectedFilters.filters?.[props.category] ?? new Set<string>();
  const filtersKey = filters.map(item => item.split(' :')[0]);
  const { appName } = useEnvironment();
  const { loading } = useFacetQuery({
    variables: {
      query,
      //filters: updatedFilters,
      solrHandler: '/search-select',
      filters: showMore ? updatedFilters : filters,
      fields: [{ name: props.category, facetLimit: 10, page, searchTerm, minCount: count }],
      excludeCount: !!props.excludeCount,
      appName,
      userType: sessionStorage.getItem('employeeType') || '',
    },
    onCompleted: data => {
      if (data?.facet?.[0]?.values) {
        //To show toaster message if a user selects a filter and it has zero values
        if (data?.facet?.[0]?.values.length <= 0) {
          if (props.category === props.currentSelectedCategory && !hasValue) {
            toast.danger({
              message: 'Your selected filter will not be shown in the filter panel if no valid search results match',
              timeout: 10000,
            });
          }
        }
        page ? setFacetValues([...facetValues, ...data?.facet?.[0].values]) : setFacetValues(data?.facet?.[0].values);
        setHasNext(true);
        //for the edge case when the API gets called for next pages and returns 0 results
        //here we set that the facet category has some values atleast to ignore the 0 results return for next pages
        setHasValue(true);
      }
    },
  });

  const loadMoreItems = () => {
    if (loading) return null;
    setPage(page + 1);
    return Promise.resolve();
  };

  const handleSearch = (term: string) => {
    setPage(0);
    setHasNext(true);
    setSearchTerm(term);
  };

  const ShowMoreActions = category => {
    const filtersForShowMoreAction = filters.filter(item => {
      return item.split(' :')[0] !== category;
    });
    setUpdatedFilters(filtersForShowMoreAction);
    setShowMore(true);
    setCount(0);
  };

  const showLessActions = () => {
    setUpdatedFilters(filters);
    setShowMore(false);
    setCount(1);
  };

  return facetValues.length > 0 ? (
    <FacetContainer
      title={`${props.categoryName} (${categoryItems.size})`}
      onValueToggle={value => handleFilterToggle(props.category, value)}
      onClear={() => onCategoryClear(props.category)}
      isLoading={loading}
      isExpand={props.isExpand}
    >
      <DropdownItems
        facetValues={facetValues}
        category={props.category}
        hasNextPage={hasNext}
        isNextPageLoading={loading}
        loadNextPage={loadMoreItems}
        onSearch={handleSearch}
      />

      {facetValues.length !== 0 &&
        !props.categoryName?.includes('Content Source') &&
        !props.categoryName?.includes('Date') &&
        filtersKey.includes(props.category) &&
        (count > 0 ? (
          <span
            className="pl-4 float-right text-primary fs-13 m-2"
            onClick={() => {
              ShowMoreActions(props.category);
            }}
          >
            + Show More
          </span>
        ) : (
          <span
            className="pl-4 float-right text-primary fs-13 m-2"
            onClick={() => {
              showLessActions();
            }}
          >
            - Show Less
          </span>
        ))}
    </FacetContainer>
  ) : null;
};

export default FacetDropdown;
