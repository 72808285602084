import { useLikeRoadmapFeatureMutation } from '@amgen/api';
import { MatomoContext, ROADMAP_ACTIONS, SEARCH_APP_FEATURE_LIST } from '@amgen/shared';
import { faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import { faThumbsUp as solidFaThumbsUp } from '@fortawesome/free-solid-svg-icons';
import React, { useContext, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';

import './popup-metric-section.scss';

import { RoadmapContext } from '../../contexts';
import FeatureComments from '../feature-comments/feature-comments';
import MetricIcon from '../metric-icon/metric-icon';

/* eslint-disable-next-line */
export interface PopupMetricSectionProps {
  featureId: string;
  startDate: string;
  endDate: string;
  featureName: string;
  themeName: string;
}

export function PopupMetricSection(props: PopupMetricSectionProps) {
  const user = JSON.parse(sessionStorage?.user);
  const { trackApplicationFeature } = useContext(MatomoContext);

  const { featureIdUserLikesMap: userLikes, featureIdLikeCountMap, refetchUserMetrics } = useContext(RoadmapContext);
  const abTestingGroup = JSON.parse(sessionStorage.getItem('abTestingGroup') as string);
  const [addLike, { loading }] = useLikeRoadmapFeatureMutation();

  return (
    <Row className="mt-5">
      <Col xs="4">
        {!abTestingGroup ? (
          !loading ? (
            <MetricIcon
              icon={userLikes.get(props.featureId) ? solidFaThumbsUp : faThumbsUp}
              count={0}
              className={userLikes.get(props.featureId) ? 'text-primary' : 'text-squid'}
              onClick={() => {
                trackApplicationFeature(SEARCH_APP_FEATURE_LIST.ROADMAP, ROADMAP_ACTIONS.LIKE_FROM_POPUP);
                addLike({
                  variables: {
                    parentId: props.featureId,
                  },
                })
                  .then(refetchUserMetrics)
                  .catch(error => console.log(error));
              }}
            />
          ) : (
            <Spinner animation="border" size="sm" />
          )
        ) : (
          <></>
        )}
        {(featureIdLikeCountMap.get(props.featureId) ?? 0) > 0 ? (
          <h5 className="col-12 text-primary px-0">{featureIdLikeCountMap.get(props.featureId)} Likes</h5>
        ) : null}
      </Col>

      <FeatureComments
        featureId={props.featureId}
        user={user}
        startDate={props.startDate}
        endDate={props.endDate}
        themeName={props.themeName}
        featureName={props.featureName}
      />
    </Row>
  );
}

export default PopupMetricSection;
