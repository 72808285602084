import { IGD_ACTIONS, MatomoContext, SEARCH_APP_FEATURE_LIST } from '@amgen/shared';
import React, { useContext, useState } from 'react';
import { Dropdown, Spinner } from 'react-bootstrap';
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu';
import { Link, useHistory } from 'react-router-dom';

import './data-request-submenu.scss';

import {
  ReactComponent as ContentOnboardingSVG,
} from '../../../../../../apps/eea-search/src/assets/images/content-onboarding.svg';
import { TABS } from '../../config';
import NewRequestModal from '../new-request/new-request-modal/new-request-modal';

/* eslint-disable-next-line */
export interface DataRequestSubmenuProps {}

export function DataRequestSubmenu(props: DataRequestSubmenuProps) {
  const mainLink = '/data-requests';
  const { push } = useHistory();
  const [show, setShow] = useState(false);

  // const { tabs: authTabs, authLoading: loading } = useAuth();
  let authTabs = [''];
  if (sessionStorage.authTabs) {
    authTabs = JSON.parse(sessionStorage.authTabs);
  }
  const loading = JSON.parse(sessionStorage?.loading);

  const { trackApplicationFeature } = useContext(MatomoContext);

  const authorizedTabs = TABS.filter(x => authTabs?.includes(x.id));
  if (loading) {
    return <Spinner animation="border" className="text-primary ml-4" size="sm" />;
  }
  if (!authorizedTabs.length) {
    return null;
  }

  return (
    <>
      <Dropdown.Item as="li" className="data-request-submenu pl-3">
        <ContentOnboardingSVG className="svg-icon" />
        <Link className="text-dark text-decoration-none" to={mainLink}>
          Content Onboarding
        </Link>
        <DropdownMenu className="submenu-left m-1">
          <Dropdown.Item
            onClick={() => {
              setShow(true);
              trackApplicationFeature(SEARCH_APP_FEATURE_LIST.IGD, IGD_ACTIONS.ADD_REQ_FROM_MENU);
            }}
          >
            New Request
          </Dropdown.Item>
          {authorizedTabs.map(tab => (
            <Dropdown.Item as={Link} to={mainLink + tab.pathname} eventKey={tab.pathname} key={tab.pathname}>
              {tab.title}
            </Dropdown.Item>
          ))}
        </DropdownMenu>
      </Dropdown.Item>

      <NewRequestModal show={show} onHide={() => setShow(false)} onSuccess={() => push('data-requests/my-requests')} />
    </>
  );
}

export default DataRequestSubmenu;
