import { IRoadmapFeatureFragmentCopy } from '@amgen/api';
import { MatomoContext, ROADMAP_ACTIONS, SEARCH_APP_FEATURE_LIST } from '@amgen/shared';
import React, { useContext, useMemo, useState } from 'react';

import './roadmap-feature.module.scss';

import { ThemeContext } from '../../contexts';
import { formColorBasedOnIndex } from '../../hooks/color';
import { getPercentage, getStartPosition, getWidth } from '../../hooks/feature-utils';
import FeaturePopup from '../feature-popup/feature-popup';
import RoadmapFeatureMetricBox from '../roadmap-feature-metric-box/roadmap-feature-metric-box';

/* eslint-disable-next-line */
export interface RoadmapFeatureProps extends IRoadmapFeatureFragmentCopy {
  index: number;
  quarterIndex: number;
}

export function RoadmapFeature(props: RoadmapFeatureProps) {
  const [show, setShow] = useState(false);
  const { color } = useContext(ThemeContext);
  const { trackApplicationFeature } = useContext(MatomoContext);

  // create a custom hook
  const width = useMemo(() => getWidth(props.startDate, props.endDate), [props]);
  const marginLeft = useMemo(() => getStartPosition(props.startDate, props.quarterIndex), [props]);
  const percentage = useMemo(() => getPercentage(props.startDate, props.endDate), [props]);

  return (
    <div
      className="roadmap-feature mb-2 roadmap-custom-rounded text-white shadow-sm d-flex justify-content-between position-absolute roadmap-elements"
      style={{
        width: '50%',
        backgroundColor: formColorBasedOnIndex(color, props.index),
        marginLeft,
        marginTop: props.index * 72 + 5,
      }}
    >
      <div
        className="p-2 flex-fill cursor-pointer text-overflow-ellipsis"
        onClick={() => {
          setShow(true);
          trackApplicationFeature(SEARCH_APP_FEATURE_LIST.ROADMAP, ROADMAP_ACTIONS.VIEW_POPUP_FEATURE_BAR);
        }}
        title="Click to view more"
      >
        {props.name}
      </div>
      <span className="text-white h3 mb-0 my-auto">{percentage}%</span>
      <RoadmapFeatureMetricBox {...props} setShow={setShow} />
      {show ? <FeaturePopup show={show} onHide={() => setShow(false)} {...props} /> : null}
    </div>
  );
}

export default RoadmapFeature;
