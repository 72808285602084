import ReactEcharts from 'echarts-for-react';
import React, { useContext } from 'react';
import { Row, Spinner } from 'react-bootstrap';

import './date-filter.scss';

import { DateFilterContext } from './date-filter-provider/date-filter-context';

/* eslint-disable-next-line */
export interface DateFilterProps {}

export const DateFilter = (props: DateFilterProps) => {
  const { handleDateBarSelected, dateFilterOption, loading } = useContext(DateFilterContext);

  const onEvents = {
    click: (...rest: any) => {
      handleDateBarSelected(rest[0].name);
    },
  };

  return loading ? (
    <Row className="justify-content-center">
      <Spinner animation="border" size="sm" />
    </Row>
  ) : (
    <ReactEcharts option={dateFilterOption} onEvents={onEvents} className="date-filter" />
  );
};

export default DateFilter;
