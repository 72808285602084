import { useFacetQuery } from '@amgen/api';
import { ApplicationName, DataSource, DEFAULT_SEARCH_QUERY } from '@amgen/core';
import { useContext } from 'react';

export const useContentSourceMetrics = () => {
  //const { currentSource } = useContext(SearchContext);
  const appName: ApplicationName = sessionStorage.getItem('appName')
    ? ApplicationName[sessionStorage.getItem('appName')!]
    : ApplicationName.EEA;
  const { loading, data } = useFacetQuery({
    variables: {
      solrHandler: appName === 'DVIEW' ? '/dview-search-select' : '/search-select',
      query: DEFAULT_SEARCH_QUERY,
      fields: [{ name: 'content_source', facetLimit: -1, minCount: 0 }],
      excludeCount: false,
      appName,
      skipAuthorization: true,
      userType: sessionStorage.getItem('employeeType') || '',
      //sourceType: SOURCE_TYPE.type[currentSource],
    },
  });
  const countMap = new Map(data?.facet?.[0].values?.map(f => [f.value as DataSource, f.count as number]));
  const totalDocs = data?.facet?.[0].values?.flatMap(n => (n.count ? n.count : [])).reduce((a, b) => a + b, 0);

  return { loading, countMap, totalDocs };
};
