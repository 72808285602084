import React, { createContext } from 'react';

export interface IPaginationConfig {
  pageOptionsCount: number;
  itemsPerPage: number;
  pageNo: number;
  totalItems: number;
  onPageSelect: (pageNo: number) => void;
  isSearchResultPage?: boolean;
  conversationalQnAEngaged?: boolean;
  results?: any;
}

interface PaginationContextValue extends IPaginationConfig {
  activePageNo: number;
  setActivePageNo: React.Dispatch<React.SetStateAction<number>>;
  startPageNo: number;
  setStartPageNo: React.Dispatch<React.SetStateAction<number>>;
  pages: number[];
  nPages: number;
  endPageNo: number;
}

export const PaginationContext = createContext<PaginationContextValue>(null!);
