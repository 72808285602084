import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

import './custom-group-button.module.scss';

import CustomGroupModal from '../custom-group-modal/custom-group-modal';

export interface CustomGroupButtonProps {
  toggleBlockScroll: (show: boolean) => void;
}

export function CustomGroupButton(props: CustomGroupButtonProps) {
  const [show, setShow] = useState(false);
  return (
    <>
      <div className="p-0">
        <div
          className="app-card text-muted py-0"
          title="Create Custom Group"
          onClick={() => {
            setShow(true);
            props.toggleBlockScroll(true);
          }}
        >
          <div className="mt-4" />
          <div className="d-flex justify-content-center">
            <FontAwesomeIcon
              icon={faPlus}
              className="app-card-img app-card-img-apps d-flex justify-content-center p-3 shadow-sm cursor-pointer"
            />
          </div>
          <h2 className="pt-2 title text-blue w-100 f-90">Create Custom Group</h2>
        </div>
      </div>
      {show ? <CustomGroupModal show={show} onHide={() => setShow(false)} /> : ''}
    </>
  );
}

export default CustomGroupButton;
