import { DataSource, NewRequestFormValue } from '@amgen/core';

export const CONSENT_TEXTS = ["I've read, understand and agree to comply with the above"];

export const OWNER_ERROR_MSG = `Owner's Approval is required if you are not the owner`;

export const ALLOWED_FILE_EXTENSIONS = /(\.jpg|\.jpeg|\.png|\.msg|\.docx|\.pdf|\.zip)$/i;

export const FILE_SIZE_LIMIT = 20 * 1048576; // 20mb

export const initialValue: NewRequestFormValue = {
  consent: Array(CONSENT_TEXTS.length).fill(undefined),
  contentSource: DataSource.SHAREPOINT,
  owner: 'No',
  requestDescription: '',
  siteDescription: '',
  sourcePath: '',
  file: null,
  displayName: '',
};

interface IFormConfig {
  sourcePath: { label: string; prefix: string; regex?: RegExp; format?: string };
  siteDescription: { label: string };
  owner: { label: string };
}

export const formConfig = new Map<DataSource, IFormConfig>([
  [
    DataSource.BOX,
    {
      sourcePath: {
        label: 'Site URL',
        prefix: 'https://amgen.box.com/',
        regex: /^(https?:\/\/(amgen.box.com\/))/,
        format: 'http(s)://amgen.box.com/<<siteName>>',
      },
      siteDescription: { label: 'Site Description' },
      owner: { label: 'Are you the Site Owner?' },
    },
  ],
  [
    DataSource.CONFLUENCE,
    {
      sourcePath: {
        label: 'Space URL',
        prefix: 'https://confluence.devops.amgen.com/',
        regex: /^(https?:\/\/(confluence.devops.amgen.com\/)).+/,
        format: 'http(s)://confluence.devops.amgen.com/<<siteName>>',
      },
      siteDescription: { label: 'Space Description' },
      owner: { label: 'Are you the Space Owner?' },
    },
  ],
  [
    DataSource.SHAREPOINT,
    {
      sourcePath: {
        label: 'Site URL',
        prefix: 'https://amgen.sharepoint.com/',
        regex: /^(https?:\/\/(amgen.sharepoint.com\/sites\/))[a-zA-Z0-9_ %]*[\w-]+\/?[a-zA-Z0-9_ %]*$/,
        format: 'http(s)://amgen.sharepoint.com/sites/<<siteName>>',
      },
      siteDescription: { label: 'Site Description' },
      owner: { label: 'Are you the Site Owner?' },
    },
  ],
  [
    DataSource.SMARTSHEET,
    {
      sourcePath: {
        label: 'Site URL',
        prefix: 'https://smartsheet.amgen.com/',
        regex: /^(https?:\/\/(smartsheet.amgen.com\/))/,
        format: 'http(s)://smartsheet.amgen.com/<siteName>>',
      },
      siteDescription: { label: 'Site Description' },
      owner: { label: 'Are you the Site Owner?' },
    },
  ],
]);

export const MAX_WORD_COUNT = 200;

export function validate(values: NewRequestFormValue) {
  const errors: { [key in keyof NewRequestFormValue]?: string } = {};

  if (!values.consent.reduce((c, cc) => cc && c, true)) {
    errors.consent = 'You must accept all this conditions';
  }

  if (!values.contentSource) {
    errors.contentSource = 'Data Source is required';
  }

  if (values.requestDescription) {
    const wordCount = values.requestDescription.split(/\s+/g).filter(t => !!t).length;
    if (wordCount > MAX_WORD_COUNT) {
      errors.requestDescription = `You have exceeded the word limit (${wordCount} / ${MAX_WORD_COUNT})`;
    }
  } else {
    errors.requestDescription = 'Request description is required';
  }

  if (values.siteDescription) {
    const wordCount = values.siteDescription.split(/\s+/g).filter(t => !!t).length;
    if (wordCount > MAX_WORD_COUNT) {
      errors.siteDescription = `You have exceeded the word limit (${wordCount} / ${MAX_WORD_COUNT})`;
    }
  } else {
    errors.siteDescription = 'Site description is required';
  }

  if (values.sourcePath) {
    const sourceConfig = formConfig.get(values.contentSource as DataSource)?.sourcePath;
    if (sourceConfig && !sourceConfig.regex?.test(values.sourcePath)) {
      errors.sourcePath = `${sourceConfig.label} must be in the format ${sourceConfig.format}`;
    }
  } else {
    const sourcePath = formConfig.get(values.contentSource as DataSource)?.sourcePath.label ?? 'Source Path';
    errors.sourcePath = `${sourcePath} is required`;
  }

  if (values.owner === 'No') {
    if (!values.file?.name) {
      errors.file = OWNER_ERROR_MSG;
    } else if (!ALLOWED_FILE_EXTENSIONS.exec(values.file.name)) {
      errors.file = 'Please attach a file (pdf, jpg, zip, or email)';
    } else if (values.file.size >= FILE_SIZE_LIMIT) {
      errors.file = 'File size limit < 20 MB';
    }
  }

  if (!values.displayName || values.displayName.trim() === '') {
    errors.displayName = 'Display Name is required';
  }

  return errors;
}

export function returnFileSize(number: number) {
  if (number < 1024) {
    return number + 'bytes';
  } else if (number >= 1024 && number < 1048576) {
    return (number / 1024).toFixed(1) + 'KB';
  } else if (number >= 1048576) {
    return (number / 1048576).toFixed(1) + 'MB';
  }
}
