import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import './feature-roadmap.scss';

import RoadmapBody from './components/roadmap-body/roadmap-body';
import RoadmapGrid from './components/roadmap-grid/roadmap-grid';
import RoadmapTimeline from './components/roadmap-timeline/roadmap-timeline';
import RoadmapContextProvider from './contexts/roadmap-context-provider/roadmap-context-provider';
import { getCurrentQuarterIndex } from './hooks/feature-utils';

/* eslint-disable-next-line */
export interface FeatureRoadmapProps {
  setBlockScroll: (value: boolean) => void;
  currentPage: number;
  setCurrentPage: (value: number) => void;
}

export function FeatureRoadmap(props: FeatureRoadmapProps) {
  const [quarterIndex, setQuarterIndex] = useState(getCurrentQuarterIndex());
  const myRef = useRef<HTMLDivElement>(null);

  const handleScroll = ({ currentTarget }: React.UIEvent<HTMLDivElement>) => {
    let t;
    if (currentTarget && currentTarget.scrollTop === 0) {
      t = setTimeout(() => {
        props.setBlockScroll(false);
      }, 1000);
    } else if (currentTarget) {
      clearTimeout(t);
      props.setBlockScroll(true);
    }
  };

  useEffect(() => {
    if (props.currentPage === 3) {
      props.setBlockScroll(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentPage]);

  useEffect(() => {
    if (myRef.current?.scrollTop === 0) {
      props.setBlockScroll(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myRef.current?.scrollTop]);

  return (
    <Container fluid>
      <RoadmapContextProvider setBlockScroll={props.setBlockScroll} width={myRef.current?.clientWidth}>
        <Row className="justify-content-sm-center">
          <Col xs="12">
            <h1
              className="section-heading p-3 mt-1 text-center font-weight-bold text-landing-page-blue"
              style={{ fontSize: 'xx-large' }}
            >
              Intelligent Search Roadmap
            </h1>
          </Col>
        </Row>
        <RoadmapTimeline position={quarterIndex} setPosition={setQuarterIndex} />
        <Row
          key={quarterIndex}
          ref={myRef}
          className="thin-scrollbar custom-roadmap-height shadow-sm roadmap-elements position-relative"
          style={{ overflowY: 'auto', overflowX: 'hidden' }}
          onScroll={handleScroll}
        >
          <RoadmapBody quarterIndex={quarterIndex} />
          <RoadmapGrid />
        </Row>
      </RoadmapContextProvider>
    </Container>
  );
}
