import { IPieSlice } from '@amgen/shared';
import { EChartOption } from 'echarts';
import ReactEcharts from 'echarts-for-react';
import React, { useMemo } from 'react';

import './pie-chart.scss';

export interface PieChartProps {
  onChartClick: (...args: any) => any;
  chartName: string;
  colors?: string[];
  onChartReadyCallback?: (...args: any) => any;
  coordinates: [string[], IPieSlice[]];
}

export const PieChart: React.FC<PieChartProps> = props => {
  const onEvents: Record<string, (...args: any) => any> = {
    click: props.onChartClick,
  };

  const option: EChartOption<EChartOption.Series> = useMemo(
    () => ({
      color: props.colors, // colorPalette(props.coordinates[0])
      title: {
        text: props.chartName,
        left: 'center',
      },
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b}: {c} ({d}%)',
        backgroundColor: '#ffffff',
        borderWidth: 1,
        textStyle: {
          color: '#000000',
        },
      },
      legend: {
        orient: 'vertical',
        align: 'left',
        data: props.coordinates[0],
        show: false,
      },
      toolbox: {
        show: true,
        orient: 'vertical',
        top: 'center',
        right: '1%',
        itemGap: 20,
        itemSize: 25,
        feature: {
          saveAsImage: { show: true, title: 'download', name: props.chartName, pixelRatio: 2 },
        },
      },
      series: [
        {
          name: props.chartName,
          type: 'pie',
          top: '10%',
          selectedMode: 'single',
          data: props.coordinates[1],
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    }),
    [props.colors, props.chartName, props.coordinates]
  );

  return (
    <div className="p-4">
      {props.coordinates[1].length < 1 ? (
        <div className="p-2 text-center">
          <h2>{props.chartName}</h2>
          <p>No data available</p>
        </div>
      ) : (
        <ReactEcharts option={option} onEvents={onEvents} style={{ height: '500px', width: '100%' }} />
      )}
    </div>
  );
};

export default PieChart;
