import React, { ReactElement } from 'react';
import { Col, ColProps } from 'react-bootstrap';

import './scrollable-card-section.scss';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ScrollableCardSectionProps extends ColProps {}

export const ScrollableCardSection: React.FC<ScrollableCardSectionProps> = props => {
  return (
    <>
      {React.Children.toArray(props.children).map((child, index) => {
        return (
          <Col
            xl={props.xl}
            xs={props.xs}
            md={props.md}
            lg={props.lg}
            key={index}
            className="wrapper-card pr-4 pl-0 pb-2"
          >
            {React.cloneElement(child as ReactElement)}
          </Col>
        );
      })}
    </>
  );
};

export default ScrollableCardSection;
