import { EChartOption } from 'echarts';
import { createContext } from 'react';

export interface IDateFilterState {
  handleDateBarSelected: (period: string) => void;
  dateFilterOption: EChartOption;
  currentView: string;
  sliderValues: string[];
  handleApplyFilters: (values: readonly number[]) => void;
  loading: boolean;
}

export const DateFilterContext = createContext<IDateFilterState>(null!);
