export enum TimeGap {
  MONTH = 'MONTH',
  YEAR = 'YEAR',
  DAY = 'DAY',
}

export enum QuickDateRange {
  ALLTIME = 'All Time',
  PASTWEEK = 'Last 7 Days',
  PASTMONTH = 'Last 30 Days',
  POSTQUARTER = 'Last 90 Days',
  CUSTOM = 'CUSTOM',
}

export interface IQuickRangeOption {
  label: QuickDateRange;
  monthAgo: number;
  yearAgo: number;
  daysAgo: number;
  gap: TimeGap;
}

//can be customized for more quick-ranges - x month-Ago is x months ago from now..etc..time gap is for the part-division for x-axis range
export const QUICK_RANGE_OPTIONS: IQuickRangeOption[] = [
  { label: QuickDateRange.ALLTIME, monthAgo: 0, yearAgo: 10, daysAgo: 0, gap: TimeGap.YEAR },
  { label: QuickDateRange.PASTWEEK, monthAgo: 0, yearAgo: 0, daysAgo: 7, gap: TimeGap.DAY },
  { label: QuickDateRange.PASTMONTH, monthAgo: 0, yearAgo: 0, daysAgo: 30, gap: TimeGap.DAY },
  { label: QuickDateRange.POSTQUARTER, monthAgo: 0, yearAgo: 0, daysAgo: 90, gap: TimeGap.DAY },
  { label: QuickDateRange.CUSTOM, monthAgo: 0, yearAgo: 0, daysAgo: 0, gap: TimeGap.DAY },
];
