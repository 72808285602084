import { useGetTemporaryS3FileAccessLazyQuery, useGetTemporaryS3FileAccessQuery } from '@amgen/api';
import { IDataAsset } from '@amgen/core';
import classnames from 'classnames';
import DOMPurify from 'dompurify';
import React, { useState } from 'react';
import LazyLoad from 'react-lazyload';

import './asset-thumbnail.scss';

import PreviewPopup from '../preview-popup/preview-popup';

export interface AssetThumbnailProps {
  asset: IDataAsset;
  maxWidth?: string | number;
  heightAuto?: boolean;
}

export const AssetThumbnail: React.FC<AssetThumbnailProps> = ({ asset, maxWidth, heightAuto }) => {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [previewImages, setPreviewImages] = useState<string[]>([]);

  const { data } = useGetTemporaryS3FileAccessQuery({ variables: { paths: [asset!.thumbnail_large!] } });

  const [loadImages, { data: previewImageData, loading }] = useGetTemporaryS3FileAccessLazyQuery({
    variables: { paths: asset?.preview_images ?? [] },
    onCompleted: () => {
      setPreviewImages(
        previewImageData?.getTemporaryS3FileAccess?.map(fileAccess => fileAccess.preSignedUrl ?? '') ?? []
      );
    },
    fetchPolicy: 'network-only',
  });

  return (
    <>
      {!!data?.getTemporaryS3FileAccess?.[0]?.preSignedUrl && (
        <LazyLoad height={maxWidth ?? 140}>
          <img
            onClick={() => {
              setIsPreviewOpen(true);
              loadImages();
            }}
            className={classnames(
              'img-fluid mw-85 d-block cursor-pointer mx-auto',
              heightAuto ? 'thumbnail-image-height' : 'main-preview-image'
            )}
            src={DOMPurify.sanitize(data?.getTemporaryS3FileAccess[0]?.preSignedUrl) ?? ''}
            alt=""
            loading="lazy"
            style={{ maxWidth: maxWidth ?? '140px' }}
          />
        </LazyLoad>
      )}

      {!asset.thumbnail_large && (
        <img
          className={classnames(
            'img-fluid mw-85 d-block mx-auto cursor-not-allowed',
            heightAuto ? 'thumbnail-image-height' : 'main-preview-image'
          )}
          src="assets/images/default-thumbnail.svg"
          alt="No Thumbnail"
          style={{ maxWidth: maxWidth ?? '140px' }}
        />
      )}

      {isPreviewOpen && (
        <PreviewPopup
          isOpen={isPreviewOpen}
          previewImages={previewImages}
          asset={asset}
          hasPreviewImages={!!asset?.preview_images}
          loading={loading}
          onCancel={() => {
            setIsPreviewOpen(false);
            setPreviewImages([]);
          }}
        />
      )}
    </>
  );
};

export default AssetThumbnail;
