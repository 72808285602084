import { useSearchQuery } from '@amgen/api';
import { DEFAULT_SEARCH_QUERY, Direction, IClusterData, SortField, useEnvironment } from '@amgen/core';
import { useAppliedFiltersWithPersistentValues } from '@amgen/feature-facets';
import { useSearchQueryValue } from '@amgen/shared';
import { useMemo } from 'react';

export const useClusteringSearchQuery = () => {
  const query = useSearchQueryValue();
  const filters = useAppliedFiltersWithPersistentValues();
  const { appName, clusterSearch } = useEnvironment();

  const { data } = useSearchQuery({
    variables: {
      solrHandler: appName === 'DVIEW' ? '/dview-search-select' : '/search-select',
      query,
      filters,
      page: 0,
      count: 250,
      sort: SortField.MODIFIED_DATE,
      order: Direction.ASC,
      includeDocs: true,
      noShortContent: false,
      addPersonalizationDetails: true,
      appName,
      clustering: true,
    },
    skip: !clusterSearch,
  });

  const clusters = useMemo(() => data?.search?.clusters, [data]);
  const resultCount = useMemo(() => data?.search?.resultCount, [data]);

  return { clusters, resultCount };
};

export const useSelectedClusterResults = (sCluster: IClusterData | null) => {
  const { appName } = useEnvironment();

  const selectedClusterResultIds = sCluster?.docs?.map(doc => doc);
  const filterIds = selectedClusterResultIds?.join(' OR ');

  const { data, loading } = useSearchQuery({
    variables: {
      solrHandler: appName === 'DVIEW' ? '/dview-search-select' : '/search-select',
      query: DEFAULT_SEARCH_QUERY,
      filters: [`id : ${filterIds}`],
      page: 0,
      count: 100,
      sort: SortField.MODIFIED_DATE,
      order: Direction.ASC,
      includeDocs: true,
      noShortContent: false,
      addPersonalizationDetails: true,
      appName,
    },
    skip: !filterIds,
  });

  const selectedClusterResults = useMemo(() => data?.search?.docs ?? [], [data]);

  return { selectedClusterResults, selectedClusterResultsLoading: loading };
};
