import { IDataCrawlRequest, RequestStatusType } from '@amgen/core';

export type IDataCrawlField = keyof Omit<IDataCrawlRequest, 'consents'>;

export interface IExportRequestFormValue {
  fields: IDataCrawlField[];
  status: RequestStatusType;
}

export const CRAWL_FIELD_NAMES: Record<IDataCrawlField, string> = {
  applicationName: 'Application Name',
  consent: 'Consent',
  contentSource: 'Data Source',
  crawlStatus: 'Crawl Status',
  createdOn: 'Request Date',
  id: 'ID',
  reviewedOn: 'Approved / Declined Date',
  reviewerName: 'Reviewer Name',
  owner: 'Owner',
  requestDescription: 'Request Description',
  requestStatus: 'Request Status',
  s3Location: 'S3 Location',
  siteDescription: 'Site Description',
  sourcePath: 'Source URL / Site Name',
  requesterName: 'Requestor Name',
  dynamodbInsertFlag: 'DynamoFlag',
  type: 'Type',
  requesterId: 'Requestor ID',
  reviewerId: 'Reviewer ID',
  reviewerRemarks: 'Remarks',
  displayName: 'Display Name',
};

export const ADMIN_EXPORT_FIELDS: IDataCrawlField[] = [
  // 'applicationName',
  'contentSource',
  'sourcePath',
  'createdOn',
  'id',
  'requesterId',
  'requesterName',
  'owner',
  's3Location',
  'requestStatus',
  'requestDescription',
  'siteDescription',
  'reviewedOn',
  'reviewerName',
  'reviewerId',
  'reviewerRemarks',
  'consent',
  'displayName',
];

export const MY_REQ_EXPORT_FIELDS: IDataCrawlField[] = [
  //'applicationName',
  'contentSource',
  'sourcePath',
  'createdOn',
  'id',
  'requesterId',
  'requesterName',
  'owner',
  'requestStatus',
  'requestDescription',
  'siteDescription',
  'reviewedOn',
  'reviewerName',
  'reviewerId',
  'reviewerRemarks',
  'consent',
  'displayName',
];

export const MY_EXISTING_SITES_EXPORT_FIELDS: IDataCrawlField[] = [
  //'applicationName',
  'contentSource',
  'sourcePath',
  'displayName',
  'createdOn',
  'requestDescription',
  'siteDescription',
];

export function validate(values: IExportRequestFormValue) {
  const errors: { [key in keyof IExportRequestFormValue]?: string } = {};

  if (values.fields.length === 0) {
    errors.fields = 'You must select at least one field';
  }

  if (values.status.length === 0) {
    errors.status = 'You must select at least one status';
  }

  return errors;
}
