import { format } from 'date-fns';
import { EChartOption } from 'echarts';
import ReactEcharts from 'echarts-for-react';
import React, { useMemo } from 'react';

import './bar-chart.scss';

export interface BarChartProps {
  onChartClick: (...args: any) => any;
  barChartData: { value?: string; count?: number }[];
  chartName: string;
  isHorizontal?: boolean;
  isDate?: boolean;
  onChartReady?: (...args: any) => any;
}

export const BarChart = (props: BarChartProps) => {
  const onEvents: Record<string, (...args: any) => any> = useMemo(
    () => ({
      click: props.onChartClick,
      dataZoom: _ => {},
    }),
    [props]
  );

  const barAxis: EChartOption.BasicComponents.CartesianAxis = !props.isDate
    ? {
        data: props.barChartData.map(item => item.value!),
        axisLabel: {
          interval: 0,
          formatter: (value: string) =>
            !props.isHorizontal && props.barChartData.length > 5
              ? value.slice(0, 7) + '\n' + value.slice(7, 16) + '\n' + value.slice(16)
              : value,
        },
      }
    : {
        data: props.barChartData.map(item => item.value!),
        axisLabel: {
          formatter: (value: string) => format(new Date(value), 'MM-dd-yyyy'),
          rotate: 60,
        },
      };

  const valueAxis: EChartOption.BasicComponents.CartesianAxis = {
    name: 'Document Count',
    nameLocation: 'middle',
    nameGap: 50,
    nameTextStyle: {
      fontWeight: 'bold',
    },
  };

  const chartConfig: EChartOption<EChartOption.Series> = useMemo(
    () => ({
      color: ['#3398DB'],
      title: {
        text: props.chartName,
        left: 'center',
        itemGap: 30,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      grid: {
        left: '10%',
        right: '10%',
        top: '15%',
        bottom: '15%',
        containLabel: true,
      },
      xAxis: props.isHorizontal ? { ...valueAxis, type: 'value' } : { ...barAxis, type: 'category' },
      yAxis: props.isHorizontal ? { ...barAxis, type: 'category' } : { ...valueAxis, type: 'value' },
      toolbox: {
        show: true,
        orient: 'vertical',
        top: '56%',
        right: '1%',
        itemGap: 20,
        itemSize: 25,
        feature: {
          saveAsImage: { show: true, title: 'download', name: props.chartName, pixelRatio: 2 },
        },
      },
      series: [
        {
          name: 'Doc. Count',
          type: 'bar',
          data: props.barChartData.map(item => item.count),
          barMaxWidth: '75%',
          barMinHeight: '20',
          barGap: '60%',
        },
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: (idx: number) => idx * 5,
    }),
    [props.chartName]
  );

  return (
    <div className="py-4">
      {props.barChartData.length < 1 ? (
        <div className="p-2 text-center">
          <h2>{props.chartName}</h2>
          <p>No data available</p>
        </div>
      ) : (
        <ReactEcharts
          option={chartConfig}
          notMerge
          lazyUpdate
          onChartReady={props.onChartReady}
          onEvents={onEvents}
          style={{ height: '425px', width: '100%' }}
        />
      )}
    </div>
  );
};

export default BarChart;
