export function toTitleCase(s: string): string {
  return s
    .toLowerCase()
    .split('_')
    .map(str => str[0].toUpperCase() + str.slice(1))
    .join(' ');
}

/**
 * Split a string using a seperator, up to a limit number of seperator found. The last value will contain
 * the rest of the string. The inbuilt `String.prototype.split()` does not retain rest of the string.
 *
 * @param s string to split
 * @param sep seperator to use to split the string
 * @param limit number of elements in final array
 */
export function splitRetain(s: string, sep: string, limit: number) {
  const splits = s.split(sep);
  if (splits.length > limit) {
    const lastEl = splits.splice(limit - 1);
    splits.push(lastEl.join(sep));
  }
  return splits;
}

export function removeSpecialChars(str: string) {
  return str.replace(/[:*+"\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}
