import { IAssetAttribute, IDataAsset } from '@amgen/core';
import React from 'react';

import './asset.scss';

import { Actions, ActionsProps } from './actions/actions';
import { Attributes, AttributesProps } from './attributes/attributes';
import { Body, BodyProps } from './body/body';
import { Header, HeaderProps } from './header/header';
import { Image, ImageProps } from './image/image';
import { RedirectUrl, RedirectUrlProps } from './redirect-url/RedirectUrl';
import { Title, TitleProps } from './title/title';

export interface AssetComposition {
  Actions: React.FC<ActionsProps>;
  Attributes: React.FC<AttributesProps>;
  Body: React.FC<BodyProps>;
  Header: React.FC<HeaderProps>;
  Image: React.FC<ImageProps>;
  Title: React.FC<TitleProps>;
  RedirectUrl: React.FC<RedirectUrlProps>;
}

export interface AssetProps {
  asset: IDataAsset;
  displayAllAttributes?: boolean;
  displayRatio: [number, number];
  onApplyFilter: (value: string, attribute: IAssetAttribute) => void;
  clickableAttributes: IAssetAttribute[];
  showThumbnail?: boolean;
}

export const Asset: React.FC<AssetProps> & AssetComposition = props => {
  return null;
};

Asset.Actions = Actions;
Asset.Attributes = Attributes;
Asset.Body = Body;
Asset.Header = Header;
Asset.Image = Image;
Asset.Title = Title;
Asset.RedirectUrl = RedirectUrl;

export default Asset;
