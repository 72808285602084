import { LinkToggler } from '@amgen/components';
import { Direction, SortField } from '@amgen/core';
import { AppContext, FacetContext, MatomoContext, SearchContext } from '@amgen/shared';
import { toTitleCase } from '@amgen/utils';
import React, { useContext } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

import './sort-dropdown.scss';

export interface SortDropdownProps {
  option: SortField;
  onSortChange: (option: SortField) => void;
  order: Direction;
  onOrderChange: (order: Direction) => void;
}

export const SortDropdown: React.FC<SortDropdownProps> = props => {
  const { setIsModalOpen } = useContext(AppContext);
  const { setExpandPanel } = useContext(SearchContext);
  const { trackApplicationFeature } = useContext(MatomoContext);
  const { appliedFilters, isCustomFilterSearch, setCustomFilterSearch, setAppliedFilters } = useContext(FacetContext);
  const abTestingGroup = JSON.parse(sessionStorage.getItem('abTestingGroup') as string);
  const currentCustomsource = sessionStorage.getItem('current-source-selected');

  return (
    <>
      {abTestingGroup && (
        <div className="filters-text">
          {/* <span
            className='filter-options'
            onClick={() => {
              setExpandPanel?.(true);
              trackApplicationFeature(
                SEARCH_APP_FEATURE_LIST.FACET_FEATURE,
                FACET_FEATURE_ACTIONS.ADVANCED_FILTERS_CLICKED
              );
            }}>
            <span className="ml-2">
              {appliedFilters?.count > 0 && (
                <div className="bg-info rounded-circle filter-count-true">{appliedFilters?.count}</div>
              )}
              <img
                alt="advance filter"
                src={'assets/images/advacne-filter-logo.svg'}
                className="advance-filter-icon icon-labels"
                // onClick={() => setExpandPanel?.(true)}
                title="Advanced Filter(s)"
              ></img>
            </span>
            <span>Filter(s)</span>
          </span> */}
          {currentCustomsource === 'Contract Search' ? (
            <span className="filter-options">
              <span onClick={() => setIsModalOpen(true)}>
                <span className="mr-1">
                  <img title="Download" alt="" src="assets/images/download.svg" className="download-icon" />
                </span>
                <span>Export</span>
              </span>{' '}
            </span>
          ) : (
            <></>
          )}
        </div>
      )}
      <div className="sort-by-text">Sort By</div>
      <div
        className="sort-dropdown-container d-flex align-items-center"
        title={`Sorted by ${
          props.option === 'RELEVANCY'
            ? toTitleCase(props.option)
            : props.order === 'desc'
            ? 'Modified Latest'
            : 'Modified Oldest'
        }`}
      >
        <Dropdown
          className="p-1"
          alignRight
          onSelect={(value, _) => {
            props.onSortChange(value === 'RELEVANCY' ? (value as SortField) : (SortField.MODIFIED_DATE as SortField));
            if (value === SortField.RELEVANCY) {
              props.onOrderChange(Direction.ASC);
            }

            if (value === 'modified_latest') {
              props.onOrderChange(Direction.DESC);
            }

            if (value === 'modified_oldest') {
              props.onOrderChange(Direction.ASC);
            }
          }}
        >
          <Dropdown.Toggle className="dropdown-selected-option" as={LinkToggler} id="sort-dropdown-toggle">
            {props.option === 'RELEVANCY'
              ? toTitleCase(props.option)
              : props.order === 'desc'
              ? 'Modified Latest'
              : 'Modified Oldest'}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item className="dropdown-options" eventKey={SortField.RELEVANCY}>
              Relevancy
            </Dropdown.Item>
            <Dropdown.Item className="dropdown-options" eventKey={'modified_latest'}>
              Modified Latest
            </Dropdown.Item>
            <Dropdown.Item className="dropdown-options" eventKey={'modified_oldest'}>
              Modified Oldest
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        {/* {props.order === Direction.ASC && (
        <span
          className="sort-order cursor-pointer pl-2"
          title="Change to Descending Order"
          onClick={() => props.onOrderChange(Direction.DESC)}
          style={{ display: props.option === SortField.RELEVANCY ? 'none' : 'flex' }}
        >
          <FontAwesomeIcon icon={faArrowUp} />
        </span>
      )}

      {props.order === Direction.DESC && (
        <span
          className="sort-order cursor-pointer pl-2"
          title="Change to Ascending Order"
          onClick={() => props.onOrderChange(Direction.ASC)}
          style={{ visibility: props.option === SortField.RELEVANCY ? 'hidden' : 'visible' }}
        >
          <FontAwesomeIcon icon={faArrowDown} />
        </span>
      )} */}
      </div>
    </>
  );
};

export default SortDropdown;
