import { Direction, ISortOrder, SortField } from '@amgen/core';

export class SortOrderObject {
  constructor(public sortOrder?: { [sortField in SortField]?: Direction }) {
    // Create a deep copy if something is passed as parameter
    this.sortOrder = sortOrder
      ? Object.fromEntries(Object.entries(sortOrder).map(([field, direction]) => [field, direction]))
      : {};
  }

  toggleSort = (category: SortField) => {
    let direction;
    const copy = { ...this.sortOrder } as { [sortField in SortField]?: Direction };
    if (!this.sortOrder?.[category]) {
      direction = Direction.DESC;
    } else if (this.sortOrder?.[category] === Direction.DESC) {
      direction = Direction.ASC;
    }
    if (direction) {
      copy[category] = direction;
    } else {
      delete copy[category];
    }
    return new SortOrderObject(copy);
  };

  toSortOrder = (): ISortOrder[] => {
    return this.sortOrder
      ? Object.entries(this.sortOrder).map(([sortField, order]) => {
          return { sortField, order } as ISortOrder;
        })
      : [];
  };

  getDirection = (sortField: string) => {
    return this.sortOrder?.[sortField as SortField];
  };
}
