import { useGetRoadmapCommentsQuery } from '@amgen/api';
import { InfiniteScrollDiv } from '@amgen/components';
import { IRoadmapFeatureComment, IUserInfo } from '@amgen/core';
import React, { useContext, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import './feature-comments.scss';

import { RoadmapContext } from '../../contexts';
import AddComment from '../add-comment/add-comment';
import Comment from '../comment/comment';

/* eslint-disable-next-line */
export interface FeatureCommentsProps {
  featureId: string;
  user: IUserInfo;
  startDate: string;
  endDate:string;
  featureName:string;
  themeName:string;
}

export function FeatureComments(props: FeatureCommentsProps) {
  const [comments, setComments] = useState<IRoadmapFeatureComment[]>([]);
  const [page, setPage] = useState(0);
  const [dummyLoading, setDummyLoading] = useState(false);
  const [hasNext, setHasNextPage] = useState(true);

  const { refetchUserComments } = useContext(RoadmapContext);

  const reset = async (): Promise<boolean> => {
    setPage(0);
    setHasNextPage(true);
    setDummyLoading(true);
    setTimeout(() => {
      refetchUserComments();
      setDummyLoading(false);
    }, 2000);
    await refetch();
    return true;
  };

  const { loading, refetch } = useGetRoadmapCommentsQuery({
    variables: {
      start: 0,
      page,
      count: 10,
      parentId: props.featureId,
    },
    onCompleted: data => {
      if (data?.getRoadmapComments?.comments) {
        page
          ? setComments([...comments, ...data?.getRoadmapComments?.comments])
          : setComments(data?.getRoadmapComments?.comments);
        if (data?.getRoadmapComments?.comments.length === 0) {
          setHasNextPage(false);
        }
      }
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  return (
    <>
      <Col xs="12" className="mt-2">
        <Row>
          <InfiniteScrollDiv
            className="thin-scrollbar h-100 feature-comments-container"
            loading={loading || dummyLoading}
            isEmpty={(comments.length ?? -1) <= 0}
            hasNext={hasNext}
            emptyImgSrc="assets/images/no-comments.png"
            page={page}
            hasScrolledToEnd={p => {
              setPage(p);
              if (p >= 1) refetch();
            }}
          >
            {comments.map(comment => (
              <Comment
                key={comment.id}
                {...comment}
                featureId={props.featureId}
                onEdit={() => {
                  setPage(0);
                  refetch();
                }}
                myUser={props.user}
              />
            ))}
          </InfiniteScrollDiv>
        </Row>
      </Col>

      <AddComment user={props.user} featureId={props.featureId} onPost={reset} startDate={props.startDate} endDate={props.endDate} themeName={props.themeName} featureName={props.featureName} />
    </>
  );
}

export default FeatureComments;
