import React, { useContext, useEffect, useState } from 'react';

import './generative-ai.scss';

import GenerativeAIQA from './generative-ai-q&a';
import GenerativeAISimilarQuestions from './generative-ai-similar-questions';

const GenerativeAI = () => {
  return (
    <div className="generative-container col ml-2 pb-2 pt-3 mb-3 ">
      <div className="fs-14">
        <span className="text-rainbow-animation">BETA </span>
        <span className="font-italic font-weight-bold beta-header-text">
          - Created using Generative AI and is experimental.
        </span>
        <span className="font-italic font-weight-bold beta-header-text">
          <span> Please refer Amgen trustworthy </span>
          <span>
            <a
              href={`https://amgencdocs.veevavault.com/ui/#doc_info/2499463`}
              target="_blank"
              rel="noreferrer"
              className="link-horizon-day-guide"
            >
              AI Tools SOP
            </a>
          </span>{' '}
          for more details.
        </span>
      </div>
      <GenerativeAIQA />
      <GenerativeAISimilarQuestions />
    </div>
  );
};

export default GenerativeAI;
