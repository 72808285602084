import { useGetTemporaryS3FileAccessLazyQuery } from '@amgen/api';
import { downloadFile } from '@amgen/utils';
import React from 'react';
import { Button } from 'react-bootstrap';

import './download-file-button.scss';

export interface DownloadFileButtonProps {
  url: string;
  fileName: string;
}

export const DownloadFileButton: React.FC<DownloadFileButtonProps> = props => {
  const [getPreSignedURL, { data }] = useGetTemporaryS3FileAccessLazyQuery({
    onCompleted: () => {
      const fileAccess = data?.getTemporaryS3FileAccess?.[0];
      if (fileAccess?.status?.toLowerCase() === 'success') {
        downloadFile(fileAccess.preSignedUrl!, props.fileName);
      }
    },
  });

  const parts = props.url.split('/');
  const fileName = parts[parts?.length - 1];

  return (
    <Button
      className="text-justify"
      onClick={() => getPreSignedURL({ variables: { paths: [props.url] } })}
      title={fileName}
    >
      <span>Download File</span>
    </Button>
  );
};

export default DownloadFileButton;
