import { useAssetAttachmentField, useAssetTitleUrl } from '@amgen/feature-search';
import { AssetContext } from '@amgen/shared';
import React, { useContext } from 'react';

import './view-attachments-button.scss';

import AssetActionsMenu from '../asset-actions-menu/asset-actions-menu';
import { DocAttachmentLinks } from '../doc-attachment-links/doc-attachment-links';

/* eslint-disable-next-line */
export interface ViewAttachmentsButtonProps {}

export const ViewAttachmentsButton: React.FC<ViewAttachmentsButtonProps> = props => {
  const { docSolrAsset } = useContext(AssetContext);
  const { title: assetTitle } = useAssetTitleUrl(docSolrAsset.dataAsset!);
  const { attachmentField } = useAssetAttachmentField(docSolrAsset.dataAsset!);

  return (
    <AssetActionsMenu.Item
      actionType={'attachments-btn'}
      key={`attachments-btn${docSolrAsset.dataAsset?.parent_object_id}`}
    >
      <AssetActionsMenu.Item.Name>{`View Attachments`}</AssetActionsMenu.Item.Name>
      <AssetActionsMenu.Item.Popup>
        <AssetActionsMenu.Item.Popup.Heading />
        <AssetActionsMenu.Item.Popup.Body>
          <h3 className="bg-grey px-1">Attachment(s)</h3>
          <div className="mx-2">
            <DocAttachmentLinks
              docName={assetTitle?assetTitle:""}
              docId={docSolrAsset.dataAsset?.[attachmentField] as string}
              attachmentParentField={attachmentField}
            />
          </div>
        </AssetActionsMenu.Item.Popup.Body>
      </AssetActionsMenu.Item.Popup>
    </AssetActionsMenu.Item>
  );
};

export default ViewAttachmentsButton;
