import { ITypeaheadSuggestion } from '@amgen/core';
import { FacetContext } from '@amgen/shared';
import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { Badge, Col, Row } from 'react-bootstrap';
import { Highlighter } from 'react-bootstrap-typeahead';

import './suggestion.scss';

export interface SuggestionProps {
  suggestion: ITypeaheadSuggestion;
  keyWord: string;
  optionType: string;
}

export const Suggestion: React.FC<SuggestionProps> = ({ suggestion, keyWord, optionType }) => {
  const { setDropdownSearch } = useContext(FacetContext);

  return (
    <Row
      onClick={() => {
        setDropdownSearch?.(true);
      }}
    >
      <Col className="text-truncate flex-grow fs-14" title={suggestion.term}>
        <FontAwesomeIcon icon={optionType === 'Filters' ? faPlus : faSearch} className="mr-1" color="grey" />
        <span className="px-2"></span>
        <Highlighter search={keyWord}>{suggestion.term}</Highlighter>
      </Col>
      {(suggestion.count ?? 0) > 0 && (
        <Col xs="3" className="d-flex align-items-center">
          <Badge className="rounded-pill suggestion-count position-absolute ml-auto">{suggestion.count ?? null}</Badge>
        </Col>
      )}
    </Row>
  );
};

export default Suggestion;
