import React, { useEffect, useRef, useState } from 'react';

import './virtual-container.scss';

import { NavigateNext, NavigatePrev, NavigateProps } from '../navigate-icon/navigate-icon';
import ScrollableCardSection, { ScrollableCardSectionProps } from '../scrollable-card-section';
import { ScrollableCardSectionContext } from './scrollable-card-context';

interface SearchPageProps {
  loading: boolean;
}

interface ISettingsComposition {
  NextIcon: React.FC<NavigateProps>;
  PreviousIcon: React.FC<NavigateProps>;
  Body: React.FC<ScrollableCardSectionProps>;
  Footer?: React.FC;
}

export const VirtualContainer: React.FC<SearchPageProps> & ISettingsComposition = props => {
  const scrollBody = useRef<HTMLDivElement>(null);

  const [maxScrollableWidth, setMaxScrollableWidth] = useState<number>(0);
  const [scrollLeft, setScrollLeft] = useState<number>(0);

  const scrollRight = (offset: number) => {
    const container = scrollBody.current;
    const containerScrollPosition = container?.scrollLeft ?? 0;
    setMaxScrollableWidth((container?.scrollWidth ?? 0) - (container?.clientWidth ?? 0));
    container!.scrollTo({
      top: 0,
      left: containerScrollPosition + offset,
      behavior: 'smooth',
    });
    setScrollLeft(containerScrollPosition + offset);
  };

  useEffect(() => {
    const container = scrollBody.current;

    setScrollLeft(container?.scrollLeft ?? 0);
    setMaxScrollableWidth((container?.scrollWidth ?? 0) - (container?.clientWidth ?? 0));
  }, [scrollBody]);

  const providerContextValue = {
    loading: props.loading,
    scrollLeft,
    maxScrollableWidth,
    scrollRight,
  };

  return (
    <div className="position-relative">
      <div ref={scrollBody} className="scrolling-wrapper mx-md-2 m-0">
        <ScrollableCardSectionContext.Provider value={providerContextValue}>
          {props.children}
        </ScrollableCardSectionContext.Provider>
      </div>
    </div>
  );
};

VirtualContainer.NextIcon = NavigateNext;
VirtualContainer.PreviousIcon = NavigatePrev;
VirtualContainer.Body = ScrollableCardSection;

export default { CardScroller: VirtualContainer };
