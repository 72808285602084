export interface IPieSlice {
  value: string | number;
  name: string;
}

export interface IInteractionLineChartSeries {
  firstLineSeries?: number[];
  secondLineSeries: number[];
  xAxisData: string[];
}

export interface ILineMerge {
  [key: string]: number[];
}

export enum MagicLineChartType {
  LINE = 'line',
  BAR = 'bar',
}
