import { Logo, Slider } from '@amgen/components';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import './mobile-nav.scss';

export interface MobileNavProps {
  user: string;
  onLogOut: () => Promise<any>;
  onToggle: (isShown: boolean) => void;
  show: boolean;
  logo?: string;
}

export const MobileNav: React.FC<MobileNavProps> = props => {
  return (
    <>
      <span
        className="text-white cursor-pointer mx-2"
        style={{ fontSize: 'x-large' }}
        onClick={() => props.onToggle(true)}
      >
        <FontAwesomeIcon icon={faBars} />
      </span>

      <Slider
        variant="dark"
        show={props.show}
        position="left"
        onToggle={props.onToggle}
        header={
          <div className="d-flex mb-0 pl-4 pt-4">
            <Logo img={props.logo ?? ''} appName="" />
          </div>
        }
      >
        {props.children}
      </Slider>
    </>
  );
};

export default MobileNav;
