import { faEdit, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import './save-search-item-action-panel.scss';

/* eslint-disable-next-line */
export interface SaveSearchItemActionPanelProps {
  onDelete(): void;
  subscrptnIsactive?: boolean;
  openSavedSearchModal(): void;
}

export function SaveSearchItemActionPanel(props: SaveSearchItemActionPanelProps) {
  return (
    <span className="d-flex justify-content-between">
      <span
        onClick={() => props.openSavedSearchModal()}
        className="text-primary cursor-pointer ml-3"
        style={{ fontSize: 'large' }}
        title="Edit Saved Search"
      >
        <FontAwesomeIcon icon={faEdit} />
      </span>

      <span
        onClick={() => props.onDelete()}
        className="text-primary cursor-pointer ml-3"
        style={{ fontSize: 'large' }}
        title="Delete Saved Search"
      >
        <FontAwesomeIcon icon={faTrashAlt} />
      </span>
    </span>
  );
}

export default SaveSearchItemActionPanel;
