import React from 'react';
import { Col } from 'react-bootstrap';

import './roadmap-skeleton-load.module.scss';

/* eslint-disable-next-line */
export interface RoadmapSkeletonLoadProps {}

export function RoadmapSkeletonLoad(props: RoadmapSkeletonLoadProps) {
  return (
    <>
      <Col
        xs="2"
        className="roadmap-theme skeleton text-white h1 p-3 mb-0 position-sticky sticky-elements"
        style={{ height: 145 }}
      ></Col>
      <Col xs="10" className="py-2 px-0 roadmap-feature-row d-flex flex-fill position-relative">
        <div
          className="skeleton position-absolute roadmap-custom-rounded"
          style={{ height: 42, width: '49%', marginLeft: '51% ' }}
        ></div>
        <div
          className="skeleton position-absolute roadmap-custom-rounded"
          style={{ height: 42, width: '50%', marginLeft: '0.1% ' }}
        ></div>
        <div
          className="skeleton position-absolute roadmap-custom-rounded"
          style={{ height: 42, width: '50%', marginTop: '4%', marginLeft: '20% ' }}
        ></div>
      </Col>
    </>
  );
}

export default RoadmapSkeletonLoad;
