import { LinkToggler } from '@amgen/components';
import { RequestStatusType } from '@amgen/core';
import React from 'react';
import { ButtonGroup, Dropdown } from 'react-bootstrap';

import './request-status-dropdown.scss';

export interface RequestStatusDropdownProps {
  status: RequestStatusType;
  onChange: (status: RequestStatusType) => void;
}

export const RequestStatusDropdown = (props: RequestStatusDropdownProps) => {
  return (
    <div className="results-per-page-container d-flex bg-light px-3 py-2 rounded mx-3">
      <Dropdown as={ButtonGroup} alignRight onSelect={(value, _) => props.onChange(value as RequestStatusType)}>
        <Dropdown.Toggle as={LinkToggler} title="Request Status" id="sort-dropdown-toggle">
          {props.status}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {Object.values(RequestStatusType).map(option => (
            <Dropdown.Item eventKey={`${option}`} key={option}>
              {option}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default RequestStatusDropdown;
