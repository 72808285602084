import { useGetTemporaryS3FileAccessQuery } from '@amgen/api';
import DOMPurify from 'dompurify';
import React from 'react';
import { Spinner } from 'react-bootstrap';

import './attachment-download-link.module.scss';

/* eslint-disable-next-line */
export interface AttachmentDownloadLinkProps {
  s3Location: string;
  showFileName?: boolean;
}

const AttachmentDownloadLink: React.FC<AttachmentDownloadLinkProps> = props => {
  const { data, loading } = useGetTemporaryS3FileAccessQuery({
    variables: { paths: [props.s3Location] },
  });

  if (loading) {
    return <Spinner animation="border" variant="primary" style={{ width: '1rem', height: '1rem' }} />;
  }

  const signedUrl = data?.getTemporaryS3FileAccess?.[0].preSignedUrl;
  const parts = props.s3Location?.split('/');
  const fileName = parts[parts.length - 1];

  return (
    <a href={DOMPurify.sanitize(signedUrl)} target="_blank" rel="noreferrer">
      {props.children} {props.showFileName ? <span className="pl-2">{fileName}</span> : null}
    </a>
  );
};

export default AttachmentDownloadLink;
