import { IRole } from '@amgen/core';
import React from 'react';
import { Row } from 'react-bootstrap';

import './clinical-details-roles.scss';

import ClinicalDetailsRoleBtn from '../clinical-details-role-btn/clinical-details-role-btn';

export interface ClinicalDetailsRolesProps {
  roles?: IRole[];
}

export const ClinicalDetailsRoles: React.FC<ClinicalDetailsRolesProps> = ({ roles }) => {
  return roles ? (
    <>
      {roles
        // .sort((a, b) => {
        //   // this sorts first by roleName, then by endDate
        //   if (a.roleName === b.roleName) {
        //     const aEndDate = a.endDate === 'nan' ? new Date() : parse(a.endDate!, 'M/d/yyyy', new Date());
        //     const bEndDate = b.endDate === 'nan' ? new Date() : parse(b.endDate!, 'M/d/yyyy', new Date());
        //     return aEndDate < bEndDate ? 1 : -1;
        //   } else {
        //     return a.roleName > b.roleName ? 1 : -1;
        //   }
        // })
        .map((role, i) => {
          const userRoleName = role.roleFullForm
            ? role.roleFullForm.replaceAll('Manager', 'Mngr')
            : role.roleName.replaceAll('Manager', 'Mngr');
          // const activeDates = role.endDate === 'nan' ? '(Current)' : `(${role.startDate} - ${role.endDate})`;
          return (
            <Row noGutters key={`${i}${role.person}:${role.roleFullForm}`}>
              <ClinicalDetailsRoleBtn role={role} />
              <span className="mr-2">{`${userRoleName}`}</span>
              {/* <span>{`${activeDates}`}</span> */}
            </Row>
          );
        })}
    </>
  ) : null;
};

export default ClinicalDetailsRoles;
