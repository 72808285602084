import { AssetContext } from '@amgen/shared';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DOMPurify from 'dompurify';
import React, { useContext } from 'react';

import './view-inquiry-button.scss';

import AssetActionsMenu from '../asset-actions-menu/asset-actions-menu';

/* eslint-disable-next-line */
export interface ViewInquiryButtonProps {}

export const ViewInquiryButton: React.FC<ViewInquiryButtonProps> = props => {
  const { docSolrAsset } = useContext(AssetContext);

  return (
    <AssetActionsMenu.Item
      actionType={'inquiry-link'}
      href={DOMPurify.sanitize(docSolrAsset.dataAsset?.inqlink)}
      target="_blank"
      rel="noopener noreferrer"
    >
      {`View Inquiry `}
      <FontAwesomeIcon icon={faExternalLinkAlt} className="text-primary" />
    </AssetActionsMenu.Item>
  );
};

export default ViewInquiryButton;
