import React, { useEffect, useState } from 'react';

import './gen-ai-multi-turn.scss';

const GenerativeAIMultiLoader = () => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const loadingMessages = [
    'Processing query...',
    'Generating response from top documents…',
    'Consolidating results from search results…',
    'Result found!',
    'All set! Here is the result.',
  ];
  const shimerAnimatedBars = [...Array(4)].map((_, i) => (
    <div className={`shine-multi-turn box-shine-multi-turn-${i + 1}`} />
  ));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentMessageIndex(prevIndex => (prevIndex + 1) % loadingMessages.length);
    }, 5000);

    return () => clearInterval(intervalId);
  }, [loadingMessages.length]);

  return (
    <div className="genrative-ai-loading col ml-4 pb-3 pt-3 mb-3 ">
      <div className="d-flex">
        <div className="circle circle-half mr-2" />
        <div className="font-italic font-weight-bold animated-note">{loadingMessages[currentMessageIndex]}</div>
      </div>

      {shimerAnimatedBars}
    </div>
  );
};

export default GenerativeAIMultiLoader;
