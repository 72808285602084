import { DropdownArrayField } from '@amgen/components';
import React from 'react';

import './export-requests-field-input.scss';

import { CRAWL_FIELD_NAMES, IDestionationFields } from '../models';

/* eslint-disable-next-line */
export interface ExportRequestsFieldInputProps {
  exportFields: IDestionationFields[];
  setAllowSubmit: any
}

export const ExportRequestsFieldInput = (props: ExportRequestsFieldInputProps) => {
  return (
    <DropdownArrayField<IDestionationFields>
      name="fields"
      placeholder="Select Fields"
      label="Fields"
      helpText="Fields in your export .csv"
      options={props.exportFields}
      optionSortFn={(a, b) => CRAWL_FIELD_NAMES[a].localeCompare(CRAWL_FIELD_NAMES[b])}
      optionKeyFn={option => option}
      optionLabelFn={option => CRAWL_FIELD_NAMES[option]}
      setAllowSubmit = {props.setAllowSubmit}
    />
  );
};

export default ExportRequestsFieldInput;
