import { ApplicationName, useEnvironment } from '@amgen/core';
import { AppliedFiltersBar } from '@amgen/feature-facets';
import { AppContext, FacetContext, FiltersObject, useSearchQueryValue } from '@amgen/shared';
import { getDisplayValueOfSearch } from '@amgen/utils';
import { format, isAfter } from 'date-fns';
import { useField } from 'formik';
import React, { useContext } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';

import './save-search-details.scss';

import {
  KmiAppliedFiltersBar,
} from '../../../../../../apps/kmi-search/src/app/components/kmi-applied-filters-bar/kmi-applied-filters-bar';

export interface SaveSearchDetailsProps {
  onToggleSubscribe: (toggle: boolean) => void;
}
export const SaveSearchDetails: React.FC<SaveSearchDetailsProps> = props => {
  const { appName } = useEnvironment();
  const { savedSearch } = useContext(AppContext);
  const { appliedFilters } = useContext(FacetContext);
  const query = useSearchQueryValue();
  const [nameField, nameMetaData] = useField<string>('name');
  const [startDateField, , startDateFieldHelper] = useField<string>('subscrptnStartDate');
  const [subscrptnIsactiveField, , subscrptnIsactiveFieldHelper] = useField<boolean>('subscrptnIsactive');

  const filters = FiltersObject.fromJSONString(savedSearch?.filters?.[0] ?? '{}');
  return (
    <Card.Body className="px-4 pt-4 pb-0">
      <Form.Group as={Row} noGutters>
        <Form.Label column xs="12" md="3" className="font-weight-bold pr-3">
          Search Name:
        </Form.Label>

        <Col xs="12" md="9" className="pl-1">
          <Form.Control
            type="text"
            placeholder="Enter Search Name"
            {...nameField}
            isInvalid={nameMetaData.touched && !!nameMetaData.error}
          />
          <Form.Control.Feedback type="invalid" className="text-left">
            {nameMetaData.error}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Form.Group as={Row} noGutters>
        <Form.Label column xs="12" md="3" className="font-weight-bold pr-3">
          Search Keyword:
        </Form.Label>

        <Col xs="12" md="9" className="pl-1">
          <Form.Control plaintext readOnly defaultValue={getDisplayValueOfSearch(query)} className="f-90" />
        </Col>
      </Form.Group>

      <Form.Group as={Row} noGutters>
        <Form.Label column xs="12" md="3" className="font-weight-bold">
          Applied Filters:
        </Form.Label>

        <Col xs="12" md="9">
          {appName === ApplicationName.KMI ? (
            <KmiAppliedFiltersBar
              filters={savedSearch ? filters : appliedFilters}
              message="No Applied Filters"
              messageClassName="form-control-plaintext"
              isClearable={false}
            />
          ) : (
            <AppliedFiltersBar
              filters={savedSearch ? filters : appliedFilters}
              message="No Applied Filters"
              messageClassName="form-control-plaintext"
              isClearable={false}
              page="save-search"
            />
          )}
        </Col>
      </Form.Group>

      <Form.Group as={Row} noGutters>
        <Form.Label column xs="12" md="3" className="font-weight-bold">
          Subscribe:
        </Form.Label>

        <Col xs="12" md="9" style={{ marginTop: '0.15rem' }}>
          <Form.Check
            type="switch"
            id="isActiveSubscribe"
            checked={subscrptnIsactiveField.value}
            onChange={() => {
              const sDate = format(new Date(startDateField.value), 'yyyy-MM-dd');
              subscrptnIsactiveFieldHelper.setValue(!subscrptnIsactiveField.value);
              if (!subscrptnIsactiveField.value && isAfter(new Date(), new Date(sDate))) {
                startDateFieldHelper.setValue(format(new Date(), 'yyyy-MM-dd'));
              }
              props.onToggleSubscribe(!subscrptnIsactiveField.value);
            }}
          />
        </Col>
      </Form.Group>
    </Card.Body>
  );
};
export default SaveSearchDetails;
