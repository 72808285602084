import { BaseSettingsPage, DataSourcePreference, ExportSetting, FilterCategoryPreference } from '@amgen/feature-settings';
import { PAGES, usePageTrackingWithTitle } from '@amgen/shared';
import React from 'react';

import './settings-page.scss';

/* eslint-disable-next-line */
export interface SettingsPageProps {}

export const SettingsPage = (props: SettingsPageProps) => {
  usePageTrackingWithTitle(PAGES.SETTINGS);

  return (
    <BaseSettingsPage>
      <FilterCategoryPreference />
      <ExportSetting />
      <DataSourcePreference />
    </BaseSettingsPage>
  );
};

export default SettingsPage;
