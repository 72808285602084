import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import React, { useContext } from 'react';

import './search-box-mobile-search-button.scss';

import { SearchBoxContext } from '../../search-box-context-provider/search-box-context';

/* eslint-disable-next-line */
export interface SearchBoxMobileSearchButtonProps {}

export const SearchBoxMobileSearchButton = (props: SearchBoxMobileSearchButtonProps) => {
  const { isSearchExpanded, setIsSearchExpanded = () => {} } = useContext(SearchBoxContext);
  return (
    <span // used this span for mobile screen icon
      className={classnames({
        'd-none': isSearchExpanded,
        'fa-stack fa-2x cursor-pointer mr-2 mr-md-2 d-flex justify-content-center align-items-center mobile-search rounded-circle': !isSearchExpanded,
      })}
      onClick={() => (!isSearchExpanded ? setIsSearchExpanded(true) : '')}
      style={{ position: 'absolute', right: '0', fontSize: '19px' }}
    >
      <FontAwesomeIcon icon={faSearch} color={'white'} />
    </span>
  );
};

export default SearchBoxMobileSearchButton;
