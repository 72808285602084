import { useMemo } from 'react';

import { usePost } from './http';

interface IDonwtimeFilePayload {
  payload: string;
}

interface IDowntimeData {
  title: string;
  description: string;
  image_name: string;
  image_bytes?: string;
}

interface IBannerData {
  message_type: string;
  title: string;
  description: string;
  link: string;
  image_name: string;
  image_bytes: string;
  title_link: string;
  ab_testing_user: boolean | string;
}

interface IConfigData {
  show_banner: boolean | string;
  site_down: boolean | string;
  downtime_date: IDowntimeData;
  banner_data: Array<IBannerData>;
  show_banner_popup: boolean | string;
}

export function useDowntimeConfigQuery(envProps, flag: boolean) {
  const payload = useMemo(() => ({ payload: '' }), []);
  const url = flag
    ? `${envProps}/get-announcements-banner-configs`
    : `${envProps.api.adminUrl}/get-announcements-banner-configs`;
  //const { adminUrl } = useAPIConfig();
  const { data, loading, error } = usePost<IConfigData, IDonwtimeFilePayload>(url, payload);

  return {
    loading,
    error,
    response: Object.keys(data ?? {}).length === 0 ? null : data,
  };
}
