import * as Types from '@amgen/core';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type IGetDeepLinksQueryVariables = Types.Exact<{
  applicationName?: Types.Maybe<Types.ApplicationName>;
  query: Types.Scalars['String'];
  count?: Types.Maybe<Types.Scalars['Int']>;
  solrHandler?: Types.Maybe<Types.Scalars['String']>;
  cosine?: Types.Maybe<Types.Scalars['Boolean']>;
}>;

export type IGetDeepLinksQuery = {
  getDeepLinks?: Types.Maybe<
    Pick<
      Types.IObjectDeepLinkCollection,
      'title' | 'url' | 'id' | 'thumbnail_large' | 'content' | 'parent_object_id' | 'category' | 'is_deeplink'
    > & { toplinks?: Types.Maybe<Array<Types.Maybe<Pick<Types.IToplinksField, 'title' | 'url' | 'id' | 'content'>>>> }
  >;
};

export const GetDeepLinksDocument = gql`
  query GetDeepLinks(
    $applicationName: ApplicationName = EEA
    $query: String!
    $count: Int
    $solrHandler: String
    $cosine: Boolean
  ) {
    getDeepLinks(
      applicationName: $applicationName
      query: $query
      count: $count
      solrHandler: $solrHandler
      cosine: $cosine
    ) {
      title
      url
      id
      thumbnail_large
      content
      parent_object_id
      category
      is_deeplink
      toplinks {
        title
        url
        id
        content
      }
    }
  }
`;
export function useGetDeepLinksQuery(
  baseOptions: Apollo.QueryHookOptions<IGetDeepLinksQuery, IGetDeepLinksQueryVariables>
) {
  return Apollo.useQuery<IGetDeepLinksQuery, IGetDeepLinksQueryVariables>(GetDeepLinksDocument, baseOptions);
}
export function useGetDeepLinksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetDeepLinksQuery, IGetDeepLinksQueryVariables>
) {
  return Apollo.useLazyQuery<IGetDeepLinksQuery, IGetDeepLinksQueryVariables>(GetDeepLinksDocument, baseOptions);
}
export type GetDeepLinksQueryHookResult = ReturnType<typeof useGetDeepLinksQuery>;
export type GetDeepLinksLazyQueryHookResult = ReturnType<typeof useGetDeepLinksLazyQuery>;
export type GetDeepLinksQueryResult = Apollo.QueryResult<IGetDeepLinksQuery, IGetDeepLinksQueryVariables>;
