import { useSearchLazyQuery } from '@amgen/api';
import { LoaderErrorContainer } from '@amgen/components';
import { DEFAULT_SEARCH_QUERY, useEnvironment } from '@amgen/core';
import { AssetContext, FiltersObject, useAppliedFilters } from '@amgen/shared';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { Alert, Col, Container, Row } from 'react-bootstrap';

import './view-attendee-button.scss';

import AssetActionsMenu from '../asset-actions-menu/asset-actions-menu';
import Attendee from '../attendee/attendee';

/* eslint-disable-next-line */
export interface ViewAttendeeButtonProps {}

export const ViewAttendeeButton: React.FC<ViewAttendeeButtonProps> = props => {
  const { appName } = useEnvironment();
  const { docSolrAsset } = useContext(AssetContext);

  const filters = useAppliedFilters(
    new FiltersObject()
      .add('attendee', 'content_type')
      .add(docSolrAsset.dataAsset?.parent_object_id!, 'parent_object_id')
  );

  const [loadQuestionAndAnswers, { loading, data, error }] = useSearchLazyQuery({
    variables: {
      query: DEFAULT_SEARCH_QUERY,
      filters,
      start: 0,
      page: 0,
      count: 10,
      includeDocs: true,
      noShortContent: false,
      appName,
    },
  });

  return (
    <AssetActionsMenu.Item actionType={'attendee-btn'}>
      <AssetActionsMenu.Item.Name onClick={() => loadQuestionAndAnswers()}>View Attendees</AssetActionsMenu.Item.Name>
      <AssetActionsMenu.Item.Popup>
        <AssetActionsMenu.Item.Popup.Heading />
        <AssetActionsMenu.Item.Popup.Body>
          <h3 className="bg-grey px-1">Attendee</h3>
          <Container className="px-0">
            <LoaderErrorContainer errors={error ? [error] : []} loading={loading} reload={loadQuestionAndAnswers}>
              <Row className="show-grid" noGutters>
                {data?.search?.docs?.map(docSolrAsset =>
                  docSolrAsset.dataAsset ? (
                    <Col sm="12" md="6" lg="4" className="p-2" key={docSolrAsset.dataAsset.id}>
                      <Attendee
                        attendee={{
                          name: docSolrAsset.dataAsset.attendee_full_name,
                          city: docSolrAsset.dataAsset.attendee_city__c,
                          country: docSolrAsset.dataAsset.attendee_country__c,
                          address: docSolrAsset.dataAsset.attendee_address__c,
                          type: docSolrAsset.dataAsset.attendee_type__c,
                        }}
                      />
                    </Col>
                  ) : null
                )}

                {(data?.search?.docs?.length ?? 0) === 0 && (
                  <Alert variant="warning" className="d-flex align-items-center py-0 ml-auto px-5">
                    <span className="mr-3" style={{ fontSize: 'x-large' }}>
                      <FontAwesomeIcon icon={faFolderOpen} />
                    </span>
                    <span>There are no Attendes.</span>
                  </Alert>
                )}
              </Row>
            </LoaderErrorContainer>
          </Container>
        </AssetActionsMenu.Item.Popup.Body>
      </AssetActionsMenu.Item.Popup>
    </AssetActionsMenu.Item>
  );
};

export default ViewAttendeeButton;
