import React, { useContext } from 'react';
import { Col } from 'react-bootstrap';

import './topics-description.module.scss';

import { ClusterSearchContext } from '../cluster-search-context-provider/cluster-search-context';

/* eslint-disable-next-line */
export interface TopicsDescriptionProps {}

export function TopicsDescription(props: TopicsDescriptionProps) {
  const { resultCount } = useContext(ClusterSearchContext);

  return (
    <Col xs="12" md="auto" className="link-grey px-0 m-2">
      {resultCount ? `Displaying suggested topics from top ${Math.min(250, resultCount)} matching results` : null}
    </Col>
  );
}

export default TopicsDescription;
