import React, { useContext } from 'react';
import { Button, Col, Row } from 'react-bootstrap';

import './custom-group-form.module.scss';

import ContentSourceGroupItems from './content-source-group-items/content-source-group-items';
import CustomGroupNameInput from './custom-group-name-input/custom-group-name-input';
import { ICustomGroupFormValue } from './models';

export interface CustomGroupFormProps {
  values: ICustomGroupFormValue;
  handleCancel: () => void;
}

export function CustomGroupForm(props: CustomGroupFormProps) {
  return (
    <div className="px-2">
      <Row className="pb-3 ">
        <CustomGroupNameInput name={props.values.name} />
        <ContentSourceGroupItems category="doc_nav_prefix" values={props.values} />
        <Col xs="12" className="mt-3 d-flex justify-content-end">
          <Button type="submit" variant="primary" className="px-5 mr-3" disabled={false}>
            Submit
          </Button>
          <Button
            type="button"
            variant="outline-secondary"
            className="px-5"
            disabled={false}
            onClick={() => {
              props.handleCancel();
            }}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default CustomGroupForm;
