import { LoaderErrorContainer } from '@amgen/components';
import classnames from 'classnames';
import React, { useContext } from 'react';
import { Row } from 'react-bootstrap';

import './topics-page-body.module.scss';

import { ClusterSearchContext } from '../cluster-search-context-provider/cluster-search-context';

/* eslint-disable-next-line */
export interface TopicsPageBodyProps {}

export const TopicsPageBody: React.FC<TopicsPageBodyProps> = ({ children }) => {
  const { selectedClusterResultsLoading } = useContext(ClusterSearchContext);

  return (
    <Row className={classnames('ml-0 my-3 w-100', { 'justify-content-center': selectedClusterResultsLoading })}>
      <LoaderErrorContainer loading={selectedClusterResultsLoading ?? false}>{children}</LoaderErrorContainer>
    </Row>
  );
};

export default TopicsPageBody;
