import React, { useContext } from 'react';
import { Col, Spinner } from 'react-bootstrap';

import './search-page-body.scss';

import { SearchPageContext } from '../search-page-container/context';

/* eslint-disable-next-line */
export interface SearchPageBodyProps {}

export const SearchPageBody: React.FC<SearchPageBodyProps> = props => {
  const { loading } = useContext(SearchPageContext);

  return loading ? (
    <Col className="d-flex justify-content-center align-items-start mt-5">
      <Spinner animation="border" className="text-primary p-4 m-5" />
    </Col>
  ) : null;
};

export default SearchPageBody;
