import { ClinicalContext } from '@amgen/shared';
import { amgNumFormatter, isAmgNum } from '@amgen/utils';
import DOMPurify from 'dompurify';
import React, { useContext } from 'react';
import { Col } from 'react-bootstrap';

import './knowledge-panel-attributes.scss';

import { KNOWLEDGE_PANEL_PRODUCT_ATTRIBUTES, PRODUCT_ATTRIBUTE_NAMES } from '../../config';

/* eslint-disable-next-line */
export interface KnowledgePanelAttributesProps {
  isexploreExpanded: boolean;
}

export const KnowledgePanelAttributes: React.FC<KnowledgePanelAttributesProps> = ({ isexploreExpanded }) => {
  //commenting this line and settting product = true, so UI will not break for conditional applied CSS
  const { product } = useContext(ClinicalContext);
  // const product = true;

  const checkForEmpty = value => {
    return value == null || value.length === 0;
  };

  return product ? (
    <>
      {KNOWLEDGE_PANEL_PRODUCT_ATTRIBUTES.map(attribute => {
        const value = product[attribute];
        // const isEmpty = checkForEmpty(value) || attribute === 'roles';
        const isEmpty = checkForEmpty(value);
        // const isEmptyExpanded = checkForEmpty(value);
        // return !isEmptyExpanded && isexploreExpanded ? (
        return !isEmpty ? (
          <Col
            xs="12"
            className={
              isexploreExpanded
                ? attribute === 'roles'
                  ? 'd-flex flex-wrap my-1 text-capitalize knowledge-panel-attribute'
                  : 'my-1 text-capitalize knowledge-panel-attribute'
                : attribute === 'roles'
                ? 'd-flex flex-wrap my-1 text-capitalize knowledge-panel-attribute p-0'
                : 'my-1 text-capitalize knowledge-panel-attribute p-0'
            }
            key={attribute}
          >
            <span className="mr-2 font-weight-bold attribute-key-text">{PRODUCT_ATTRIBUTE_NAMES[attribute]}:</span>
            <span>
              {attribute === 'prescribingInformationEU' || attribute === 'prescribingInformationUS' ? (
                // <ClinicalDetailsRoles roles={product[attribute]} />
                <a href={DOMPurify.sanitize(`${value}`)} target="_blank" rel="noreferrer">
                  {value}
                </a>
              ) : typeof value === 'string' ? (
                isAmgNum(value) ? (
                  amgNumFormatter(value)
                ) : (
                  value
                )
              ) : (
                value?.join(', ')
              )}
            </span>
          </Col>
        ) : (
          <></>
        );
        // !isEmpty && !isexploreExpanded ? (
        //   <Col
        //     xs="12"
        //     className={
        //       isexploreExpanded
        //         ? 'my-1 text-capitalize knowledge-panel-attribute'
        //         : 'my-1 text-capitalize knowledge-panel-attribute p-0'
        //     }
        //     key={attribute}
        //   >
        //     <span className="mr-2 font-weight-bold attribute-key-text">{PRODUCT_ATTRIBUTE_NAMES[attribute]}:</span>
        //     <span>
        //       {typeof value === 'string' ? (isAmgNum(value) ? amgNumFormatter(value) : value) : value?.join(', ')}
        //     </span>
        //   </Col>
        // ) : null;
      })}
      {/* {!isexploreExpanded ? (
        <Row>
          <Col xs="12" className="kp-view-more-btn">
            <NavLink to={`/clinical/product/${product?.product}`}>{`View More`}</NavLink>
          </Col>
        </Row>
      ) : (
        <></>
      )} */}
    </>
  ) : null;
};

export default KnowledgePanelAttributes;
