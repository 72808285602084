import { IAssetAttribute } from '@amgen/core';
import { AppContext, FacetContext, FiltersObject, SearchContext } from '@amgen/shared';
import React, { useContext, useEffect } from 'react';
import { Button, Col } from 'react-bootstrap';

import './applied-filters-bar.scss';

import {
  SearchPageContext,
} from '../../../../../feature-result-views/src/lib/components/search-page/search-page-container/context';
import AppliedFilter from '../applied-filter/applied-filter';

export interface AppliedFiltersBarProps {
  filters?: FiltersObject;
  onRemove?: (val: string, category: IAssetAttribute) => void;
  onClearAll?: () => void;
  isClearable?: boolean; // manages the clear all and remove buttons on each tag
  message?: string; // message to display when no filters are applied
  messageClassName?: string;
  customClassOnFilter?: (filterCategory: IAssetAttribute) => string;
  clearMessage?: string;
  small?: boolean;
  page?: string;
  sourceType?: string;
  parentSource?: any;
  isExportOpen?: boolean; //when export popup is open to show the filter chips in the popup in all the tabs
}

export const AppliedFiltersBar: React.FC<AppliedFiltersBarProps> = props => {
  const { onClearAllFilters, onRemoveAppliedFilters, results } = useContext(SearchContext);
  const {
    currentSource,
    selectedResultTab,
    selectedCustomSourceName,
    setShowIntegrationModal,
    integrationModalConfirm,
    setIntegrationModalConfirm,
  } = useContext(AppContext);
  const { appliedFilters } = useContext(FacetContext);
  const filters = props.filters ?? appliedFilters;
  // const clearFilters = props.onClearAll ?? onClearAllFilters;
  const onRemove = props.onRemove ?? onRemoveAppliedFilters;
  const { loading, emptySearchResult } = useContext(SearchPageContext);
  // const [show, setShow] = useState(false);
  const abTestingGroup = JSON.parse(sessionStorage.getItem('abTestingGroup') as string);
  const handleClearFilters = () => {
    if (selectedCustomSourceName === 'Contract Search' && abTestingGroup) {
      setShowIntegrationModal(true);
    } else {
      if (props.onClearAll) {
        props.onClearAll();
      } else {
        onClearAllFilters();
      }
    }
  };

  const handleConfirm = () => {
    if (props.onClearAll) {
      props.onClearAll();
    } else {
      onClearAllFilters();
    }
  };

  useEffect(() => {
    if (integrationModalConfirm) {
      handleConfirm();
      setIntegrationModalConfirm(false);
    }
  }, [integrationModalConfirm]);

  if (filters.count > 0) {
    return selectedResultTab === 'Recommended' || props.isExportOpen ? (
      <Col xs="12" className={`px-0 fixed-height thin-scrollbar ${props.small ? '' : 'my-2'}`}>
        <span className="applied-filters">Applied Filters: </span>
        {Object.entries(filters.filters!)
          .filter(([category, _]) => !['prefix_location', 'prefix_class'].includes(category))
          .map(([category, values]) =>
            [...values!].map(v => (
              <AppliedFilter
                key={`${category}:${v}`}
                category={category as IAssetAttribute}
                value={v}
                onRemove={() => onRemove(v, category as IAssetAttribute)}
                isClearable={props.isClearable}
                className={props.customClassOnFilter && props.customClassOnFilter(category as IAssetAttribute)}
              />
            ))
          )}

        {props.isClearable && (
          <Button
            variant="link"
            className="p-0 ml-2 text-overflow-ellipsis"
            onClick={handleClearFilters}
            style={{ color: '#000000', textDecoration: 'underline' }}
            title="Press Alt+c to clear all filters"
          >
            {props.clearMessage || 'Clear All'}
          </Button>
        )}
      </Col>
    ) : (emptySearchResult || ((results?.length ?? 0) <= 0 && selectedCustomSourceName === 'Contract Search')) &&
      !loading &&
      props.parentSource === 'homePage' ? (
      <Col xs="12" className={`px-0 fixed-height thin-scrollbar ${props.small ? '' : 'my-2'}`}>
        <span className="applied-filters">Applied Filters: </span>
        {Object.entries(filters.filters!)
          .filter(([category, _]) => !['prefix_location', 'prefix_class'].includes(category))
          .map(([category, values]) =>
            [...values!].map(v => (
              <AppliedFilter
                key={`${category}:${v}`}
                category={category as IAssetAttribute}
                value={v}
                onRemove={() => onRemove(v, category as IAssetAttribute)}
                isClearable={props.isClearable}
                className={props.customClassOnFilter && props.customClassOnFilter(category as IAssetAttribute)}
              />
            ))
          )}

        {props.isClearable && (
          <Button
            variant="link"
            className="p-0 ml-2 text-overflow-ellipsis"
            onClick={handleClearFilters}
            style={{ color: '#000000', textDecoration: 'underline' }}
            title="Press Alt+c to clear all filters"
          >
            {props.clearMessage || 'Clear All'}
          </Button>
        )}
      </Col>
    ) : (
      <div>
        {props.isClearable && selectedResultTab === 'All Results' && props.parentSource === 'topFilter' ? (
          <Button
            variant="link"
            className="btn btn-link clear-filter pb-0 text-overflow-ellipsis"
            onClick={handleClearFilters}
            title="Press Alt+c to clear all filters"
          >
            {props.clearMessage || 'Clear All'}
          </Button>
        ) : (
          ''
        )}
      </div>
    );
  }

  return props.sourceType ? (
    <span className="align-items-center applied-filter badge border d-inline-flex px-3 py-1 rounded-pill">
      {props.sourceType === 'internal'
        ? 'Amgen Sources'
        : props.sourceType.charAt(0).toUpperCase() + props.sourceType.slice(1) + ' Sources'}
    </span>
  ) : props.page === 'save-search' ? (
    <span className="align-items-center applied-filter badge border d-inline-flex px-3 py-1 rounded-pill">
      {currentSource === 'internal'
        ? 'Amgen Sources'
        : currentSource.charAt(0).toUpperCase() + currentSource.slice(1) + ' Sources'}
    </span>
  ) : props.message ? (
    <span className={props.messageClassName}>{props.message}</span>
  ) : (
    <></>
  );
};

export default AppliedFiltersBar;
