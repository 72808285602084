import React from 'react';
import { Col } from 'react-bootstrap';

import './setting-header.scss';

export interface SettingHeaderProps {
  heading: any;
  infoContent: any;
}

export const SettingHeader = (props: SettingHeaderProps) => {
  return (
    <Col xs="12" as="h2" className="p-3 rounded bg-grey">
      <span className="d-block">{props.heading}</span>
      <small className="mt-2 text-secondary font-weight-light">{props.infoContent}</small>
    </Col>
  );
};

export default SettingHeader;
