import * as Types from '@amgen/core';

import { IGenericResponseFragment } from './generic-response';
import { gql } from '@apollo/client';
import { GenericResponseFragmentDoc } from './generic-response';
import * as Apollo from '@apollo/client';
export type IReportDocumentMutationVariables = Types.Exact<{
  reportDocumentInput?: Types.Maybe<Types.IReportDocumentInput>;
}>;

export type IReportDocumentMutation = { reportDocument?: Types.Maybe<IGenericResponseFragment> };

export const ReportDocumentDocument = gql`
  mutation reportDocument($reportDocumentInput: ReportDocumentInput) {
    reportDocument(reportDocumentInput: $reportDocumentInput) {
      ...GenericResponse
    }
  }
  ${GenericResponseFragmentDoc}
`;
export type IReportDocumentMutationFn = Apollo.MutationFunction<
  IReportDocumentMutation,
  IReportDocumentMutationVariables
>;
export function useReportDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<IReportDocumentMutation, IReportDocumentMutationVariables>
) {
  return Apollo.useMutation<IReportDocumentMutation, IReportDocumentMutationVariables>(
    ReportDocumentDocument,
    baseOptions
  );
}
export type ReportDocumentMutationHookResult = ReturnType<typeof useReportDocumentMutation>;
export type ReportDocumentMutationResult = Apollo.MutationResult<IReportDocumentMutation>;
export type ReportDocumentMutationOptions = Apollo.BaseMutationOptions<
  IReportDocumentMutation,
  IReportDocumentMutationVariables
>;
