import React from 'react';
import { Col, Container, Jumbotron, Row } from 'react-bootstrap';

import './auth-error.scss';

export interface AuthErrorProps {
  error: Error;
  supportEmail: string;
}

export const AuthError = (props: AuthErrorProps) => {
  return (
    <Container fluid>
      <Row className="justify-content-center">
        <Col xs="12" sm="10" md="8" lg="6" className="mt-5">
          <Jumbotron className="border border-danger shadow text-center mt-5">
            <h1 className="display-4 text-danger">{props.error.name ?? 'AuthError'}</h1>
            <h1 className="text-danger">{props.error.message ?? 'Something went wrong please try again later'}</h1>
            <p className="my-4 text-grey">
              Try clearing your browser's cookies, cache and localstorage. If your issue is not resolved, please get in
              touch with the <a href={`mailto:${props.supportEmail}`}>support team</a> with a screenshot of this error
              message.
            </p>
          </Jumbotron>
        </Col>
      </Row>
    </Container>
  );
};

export default AuthError;
