import React, { useContext } from 'react';
import { Col } from 'react-bootstrap';

import './search-page-footer.scss';

import { SearchPageContext } from '../search-page-container/context';

/* eslint-disable-next-line */
export interface SearchPageFooterProps {}

export const SearchPageFooter: React.FC<SearchPageFooterProps> = props => {
  const { emptySearchResult } = useContext(SearchPageContext);
  return !emptySearchResult ? (
    <Col xs="12" md={{ offset: 4, order: 8 }} lg={{ offset: 3, order: 9 }} className="d-flex justify-content-center">
      {props.children}
    </Col>
  ) : null;
};

export default SearchPageFooter;
