import { ClinicalContext, MatomoContext } from '@amgen/shared';
import classnames from 'classnames';
import React, { useContext, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

import './knowledge-panel-tab-switch.scss';

import { KNOWLEDGE_PANEL_OPTIONS, KnowledgePanelOption } from '../../config/knowledge-panel-tabs';
import KnowledgePanelAsset from '../knowledge-panel-asset/knowledge-panel-asset';

export interface KnowledgePanelTabSwitchProps {
  activeOption: KnowledgePanelOption;
  setActiveOption: (tab: KnowledgePanelOption) => void;
  isExploreExpanded?: boolean;
}

export const KnowledgePanelTabSwitch: React.FC<KnowledgePanelTabSwitchProps> = ({
  activeOption,
  setActiveOption,
  isExploreExpanded,
}) => {
  const { trackApplicationFeature } = useContext(MatomoContext);
  const { product, setOverviewContainerHeight } = useContext(ClinicalContext);

  useEffect(() => {
    if (activeOption === 'Overview') {
      const overviewContainerId = document.getElementById('knowledge-panel-overview');
      const overviewHeight = overviewContainerId?.clientHeight;
      setOverviewContainerHeight(overviewHeight);
    }
  }, [activeOption]);
  return (
    <>
      {KNOWLEDGE_PANEL_OPTIONS.map(option => (
        <Col
          xs={isExploreExpanded ? '3' : '6'}
          className={
            option === activeOption
              ? isExploreExpanded
                ? 'd-flex kp-tabs align-items-center bg-grey ml-3'
                : 'd-flex kp-tabs align-items-center bg-grey'
              : isExploreExpanded
              ? 'd-flex kp-tabs align-items-center ml-3'
              : 'd-flex kp-tabs align-items-center'
          }
        >
          <Row noGutters className="w-100">
            <Col
              className={classnames('cursor-pointer', option === activeOption ? 'kp-tab-active' : 'kp-tab-inactive')}
              onClick={() => {
                // if (option === KnowledgePanelOption.STUDIES) {
                //   trackApplicationFeature(
                //     SEARCH_APP_FEATURE_LIST.KNOWLEDGE_PANEL,
                //     KNOWLEDGE_PANEL_FEATURE_ACTIONS.VIEW_CLINICAL_STUDIES_TAB
                //   );
                // }
                setActiveOption(option);
              }}
              key={option}
            >
              <Row
                noGutters
                className="justify-content-center font-weight-bold mx-auto py-3 fs-14 font-family-arial-sans-serif"
              >
                {option}
              </Row>
            </Col>
          </Row>
        </Col>
      ))}
      {isExploreExpanded && product?.imageUrl ? (
        <div className="product-image-align">
          <KnowledgePanelAsset.Image image={product?.imageUrl} />
        </div>
      ) : isExploreExpanded && !product?.imageUrl ? (
        <div className="product-text-align">
          <KnowledgePanelAsset.Title title={product?.product} />
        </div>
      ) : (
        <></>
      )}
      <div className={isExploreExpanded ? 'border-bottom-container-kg-page' : 'border-bottom-container'} />
    </>
  );
};

export default KnowledgePanelTabSwitch;
