import { useFacetQuery } from '@amgen/api';
import { IAssetAttribute, IFacetValue, useEnvironment } from '@amgen/core';
import { FacetContext, TimeGap } from '@amgen/shared-kmi';
import { isWithinInterval, parse } from 'date-fns';
import { EChartOption } from 'echarts';
import { useContext, useMemo } from 'react';
import { useState } from 'react';

import { useAppliedFiltersWithPersistentValues } from '../../persistent-filter-values';
import { IDateMap, IEchartsBarChartOptionData, IRecordDate } from './models';

export const useDateBarChartData = (key: IAssetAttribute, query: string) => {
  const [today] = useState<Date>(new Date());
  const { appliedFilters } = useContext(FacetContext);
  const filters = useAppliedFiltersWithPersistentValues(appliedFilters);

  const facetRangeStart = '2000-01-01T00:00:00Z';

  const facetRangeEnd = `${today.toISOString().substring(0, 19) + 'Z'}`;

  const fields = [
    {
      facetRange: key,
      facetRangeStart,
      facetRangeEnd,
      facetRangeGap: '+1MONTH',
      facetLimit: -1,
    },
  ];

  const { appName, requestHandler } = useEnvironment();
  const { data, loading, error } = useFacetQuery({
    variables: {
      query,
      filters,
      solrHandler: '/search-select',
      fields,
      excludeCount: false,
      appName,
      userType: sessionStorage.getItem('employeeType') || '',
    },
  });

  return {
    data,
    loading,
    error,
  };
};

export const useChartValues = (
  currentView: TimeGap,
  startDate: Date,
  endDate: Date,
  selectedYear: string,
  facetData: IFacetValue[]
) => {
  const getYearData = (records: IRecordDate[], startDate: Date, endDate: Date): IEchartsBarChartOptionData => {
    const yearsMap: IDateMap = records.reduce((obj: IDateMap, record) => {
      const year = `${record.date.getFullYear()}`;
      return { ...obj, [year]: (obj[year] ?? 0) + record.count };
    }, {});

    const yearsArray = Object.entries(yearsMap).map(([year, count]) => ({ year, count }));

    const xData = yearsArray
      .filter(({ year }) => parseInt(year) >= startDate.getFullYear() && parseInt(year) <= endDate.getFullYear())
      .map(({ year }) => year);

    const yData = yearsArray
      .filter(({ year }) => parseInt(year) >= startDate.getFullYear() && parseInt(year) <= endDate.getFullYear())
      .map(({ count }) => count);

    return { xData, yData };
  };

  const getMonthData = (
    records: IRecordDate[],
    startDate: Date,
    endDate: Date,
    selectedYear: string
  ): IEchartsBarChartOptionData => {
    const monthsMap: IDateMap = records.reduce((obj: IDateMap, record) => {
      const month = `${record.date.getFullYear()}-${record.date.toLocaleString('default', { month: 'long' })}`;
      return { ...obj, [month]: (obj[month] ?? 0) + record.count };
    }, {});

    const monthsArray = Object.entries(monthsMap).map(([month, count]) => ({ month, count }));

    const xData = monthsArray
      .filter(rec => rec.month.slice(0, 4) === selectedYear)
      .filter(rec => {
        return isWithinInterval(parse(`${rec.month}-01`, 'yyyy-MMMM-d', new Date()), {
          start: startDate,
          end: endDate,
        });
      })
      .map(({ month }) => month);

    const yData = monthsArray
      .filter(rec => rec.month.slice(0, 4) === selectedYear)
      .filter(rec =>
        isWithinInterval(parse(`${rec.month}-01`, 'yyyy-MMMM-d', new Date()), { start: startDate, end: endDate })
      )
      .map(({ count }) => count);

    return { xData, yData };
  };

  const records: { date: Date; count: number }[] = facetData.map(d => ({
    date: parse(d.value?.slice(0, 10) ?? '', 'yyyy-MM-dd', new Date()),
    count: d.count ?? 0,
  }));

  const { xData, yData } =
    currentView === TimeGap.YEAR
      ? getYearData(records, startDate, endDate)
      : getMonthData(records, startDate, endDate, selectedYear);

  return { xData, yData };
};

export const useEchartsBarChartOption = (
  categoryName: string,
  xData: string[],
  yData: number[]
): { dateFilterOption: EChartOption } => {
  const dateFilterOption: EChartOption = useMemo(
    () => ({
      color: ['#0063c3'],
      tooltip: {
        trigger: 'axis',
      },
      grid: {
        containLabel: false,
        left: '10%',
        right: '10%',
        top: '0%',
        bottom: '25%',
      },
      xAxis: {
        data: xData ?? [],
      },
      yAxis: {
        show: false,
      },
      series: [
        {
          name: categoryName,
          type: 'bar',
          data: yData ?? [],
        },
      ],
    }),
    [categoryName, xData, yData]
  );

  return { dateFilterOption };
};
