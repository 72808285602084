import { AppContext, ResultViewType, SearchContext, useWindowSizeListener } from '@amgen/shared';
import React, { useContext } from 'react';
import { Col } from 'react-bootstrap';

import './search-page-left-flank.scss';

/* eslint-disable-next-line */
export interface SearchPageLeftFlankProps {}

export const SearchPageLeftFlank: React.FC<SearchPageLeftFlankProps> = props => {
  const { selectedResultTab } = useContext(AppContext);
  const isMobile = useWindowSizeListener();
  const { selectedViewType } = useContext(SearchContext);
  return (
    // <Col xs="12" lg={selectedViewType === ResultViewType.Table ? '11' : '8'} className={isMobile ? '' : 'ml-5'}>
    <Col
      xs="12"
      lg={selectedResultTab === 'Chat' ? 0 : selectedViewType === ResultViewType.Table ? 9 : 6}
      className={isMobile ? '' : 'left-pane-container'}
    >
      {props.children}
    </Col>
  );
};

export default SearchPageLeftFlank;
