import { DropdownArrayField } from '@amgen/components';
import { PageType, RequestStatusType } from '@amgen/core';
import React from 'react';

import './request-status-input.scss';

/* eslint-disable-next-line */
export interface RequestStatusInputProps {
  pageType?: PageType;
  setAllowSubmit?: any;
}

export const RequestStatusInput = (props: RequestStatusInputProps) => {
  return (
    <DropdownArrayField<RequestStatusType>
      name="status"
      placeholder="Select Request Status"
      label="Request Status"
      helpText="Status of the requests to be exported"
      options={
        props.pageType === PageType.IGD_EXISTING_SITES
          ? Object.values(RequestStatusType).filter(item => item === RequestStatusType.APPROVED)
          : Object.values(RequestStatusType)
      }
      //defaultOptions={Object.values(RequestStatusType.PENDING)}
      optionSortFn={(a, b) => a.localeCompare(b)}
      optionKeyFn={option => option}
      optionLabelFn={option => option}
      setAllowSubmit={props.setAllowSubmit}
    />
  );
};

export default RequestStatusInput;
