import { useOutsideClick } from '@amgen/shared';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ChangeEvent, FocusEvent, KeyboardEvent, useRef, useState } from 'react';
import { Button, FormControl, InputGroup } from 'react-bootstrap';

import './inline-editable.scss';

export interface InlineEditableProps {
  placeholder?: string;
  initialValue: string;
  onSave: (updatedText: string) => void;
  onCancel: () => void;
}

export const InlineEditable: React.FC<InlineEditableProps> = props => {
  const [text, setText] = useState(props.initialValue);
  const inputRef = useRef<any>(null);

  useOutsideClick(inputRef, props.onCancel);

  return (
    <InputGroup ref={inputRef}>
      <FormControl
        type="text"
        placeholder={props.placeholder || 'Enter Text'}
        value={text}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setText(e.target.value)}
        onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
          switch (e.key) {
            case 'Enter':
              props.onSave(text);
              break;
            case 'Escape':
              props.onCancel();
              break;
          }
        }}
        onFocus={(e: FocusEvent<HTMLInputElement>) => e.target.select()}
        autoFocus
      />
      <InputGroup.Append>
        <Button variant="outline-primary" title="Save" onClick={() => props.onSave(text)}>
          <FontAwesomeIcon icon={faCheck} />
        </Button>

        <Button variant="outline-danger" title="Cancel" onClick={props.onCancel}>
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      </InputGroup.Append>
    </InputGroup>
  );
};

export default InlineEditable;
