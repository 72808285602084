import { useSearchLazyQuery } from '@amgen/api';
import { Direction, SortField, useEnvironment } from '@amgen/core';
import { useAppliedFiltersWithPersistentValues } from '@amgen/feature-facets';
import { ClusterSearchContext } from '@amgen/feature-topics';
import { CLUSTER_SEARCH_FEATURE_ACTIONS, MatomoContext, SEARCH_APP_FEATURE_LIST, useSearchQueryValue } from '@amgen/shared';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import React, { useContext, useState } from 'react';
import { Badge, Button, Row, Spinner } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';

import './cluster-search-button.scss';

/* eslint-disable-next-line */
export interface ClusterSearchButtonProps {}

export function ClusterSearchButton(props: ClusterSearchButtonProps) {
  const query = useSearchQueryValue();
  const filters = useAppliedFiltersWithPersistentValues();
  const { appName } = useEnvironment();
  const [expanded, setExpanded] = useState(false);
  const { search } = useLocation();
  const { setSelectedCluster } = useContext(ClusterSearchContext);
  const { trackApplicationFeature } = useContext(MatomoContext);

  const [loadClusters, { loading, data, error }] = useSearchLazyQuery({
    variables: {
      query,
      filters,
      page: 0,
      count: 250,
      sort: SortField.MODIFIED_DATE,
      order: Direction.ASC,
      includeDocs: true,
      noShortContent: false,
      addPersonalizationDetails: true,
      appName,
      clustering: true,
    },
  });

  return (
    <Row noGutters>
      <Button
        className="d-flex p-0 my-auto"
        variant="link"
        onClick={() => {
          if (!expanded) {
            loadClusters();
            setExpanded(true);
          } else {
            setExpanded(false);
          }
          trackApplicationFeature(
            SEARCH_APP_FEATURE_LIST.CLUSTER_SEARCH,
            CLUSTER_SEARCH_FEATURE_ACTIONS.SUGGESTED_TOPICS_BUTTON
          );
        }}
      >
        <span className="d-none d-lg-flex mt-1">Suggested topics for search results</span>
        {loading ? (
          <Spinner className="text-primary mx-2 my-auto" animation="border" size="sm" />
        ) : (
          <FontAwesomeIcon icon={expanded ? faChevronLeft : faChevronRight} className="my-auto mx-2" size={'xs'} />
        )}
      </Button>

      {expanded &&
        !loading &&
        data?.search?.clusters?.map(cluster => (
          <Link to={{ pathname: '/topics', search }}>
            <Badge
              className={classnames(
                'd-inline-flex align-items-center cluster-button mb-1 px-3 cursor-pointer bg-light link-grey border'
              )}
              onClick={() => {
                setSelectedCluster(cluster);
                trackApplicationFeature(
                  SEARCH_APP_FEATURE_LIST.CLUSTER_SEARCH,
                  CLUSTER_SEARCH_FEATURE_ACTIONS.TOPIC_SELECTED
                );
              }}
              title={`cluster: ${cluster.clusterName}`}
            >
              <span className="text-overflow-ellipsis">{cluster.clusterName}</span>
            </Badge>
          </Link>
        ))}
    </Row>
  );
}

export default ClusterSearchButton;
