import { IAssetAttributeTypeProps } from '@amgen/shared';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'react-bootstrap';

import './hierarchal-attribute.module.scss';

export const HierarchalAttribute: React.FC<IAssetAttributeTypeProps> = props => {
  const values = [...(props.value as string[])];
  const length = values.length;
  return length ? (
    <span>
      {values?.map((q, i) => (
        <React.Fragment key={q + i + 'hierarchal_attribute'}>
          <Button variant="link" className="p-0" onClick={() => props.onApplyFilter?.(q)}>
            {q.split('/').pop()}
          </Button>
          {length - 1 !== i ? <FontAwesomeIcon icon={faArrowRight} className="text-primary mx-1" size="sm" /> : null}
        </React.Fragment>
      ))}
    </span>
  ) : null;
};

export default HierarchalAttribute;
