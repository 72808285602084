import { ApplicationName, IGlobalApplicationAccessDetails, useEnvironment } from '@amgen/core';
import classnames from 'classnames';
import React from 'react';
import { ButtonGroup, Dropdown } from 'react-bootstrap';

import './application-switch.scss';

import { IButtonVariant } from './button-variant';

export interface ApplicationSwitchProps {
  appName: ApplicationName;
  onAppNameChange: (appName: ApplicationName) => void;
  appDetails: IGlobalApplicationAccessDetails[];
  variant?: IButtonVariant;
  className?: string;
}

export const ApplicationSwitch: React.FC<ApplicationSwitchProps> = props => {
  const { appName: globalAppName, setAppName } = useEnvironment();

  const isAuthorized = props.appDetails.find(appInfo => appInfo.applicationName === props.appName)?.accessible;
  const hasMultipleAccess = props.appDetails.filter(appDetail => appDetail.accessible).length > 1;

  return hasMultipleAccess ? (
    <Dropdown
      as={ButtonGroup}
      className={classnames('app-switcher', props.className)}
      onSelect={(appName, e) => {
        //[todo] clear this logic
        if (
          (globalAppName.toString() === 'EEA' &&
            props.appDetails.find(x => x.applicationName === appName && x.isExternal)) ||
          (appName === 'EEA' &&
            props.appDetails.find(x => x.applicationName === globalAppName.toString() && x.isExternal))
        ) {
          setAppName(appName as ApplicationName);
        } else {
          props.onAppNameChange(appName as ApplicationName);
        }
      }}
    >
      <Dropdown.Toggle id="app-change-toggle" style={{ background: 'none' }}>
        <span className="text-left px-0">{isAuthorized ? props.appName : 'Select'}</span>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {props.appDetails
          .filter(appInfo => appInfo.accessible)
          .map(appInfo => appInfo.applicationName)
          .sort()
          .map(appName => (
            <Dropdown.Item eventKey={appName} key={appName} active={appName === props.appName}>
              {appName}
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  ) : null;
};

export default ApplicationSwitch;
