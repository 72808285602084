import * as Types from '@amgen/core';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ITempFileAccessFragment = Pick<Types.ITempFileAccess, 'status' | 'error' | 'preSignedUrl'>;

export type IGetTemporaryS3FileAccessQueryVariables = Types.Exact<{
  paths?: Types.Maybe<Array<Types.Maybe<Types.Scalars['String']>> | Types.Maybe<Types.Scalars['String']>>;
}>;

export type IGetTemporaryS3FileAccessQuery = {
  getTemporaryS3FileAccess?: Types.Maybe<Array<Types.Maybe<ITempFileAccessFragment>>>;
};

export const TempFileAccessFragmentDoc = gql`
  fragment TempFileAccess on TempFileAccess {
    status
    error
    preSignedUrl
  }
`;
export const GetTemporaryS3FileAccessDocument = gql`
  query GetTemporaryS3FileAccess($paths: [String]) {
    getTemporaryS3FileAccess(s3Paths: $paths) {
      ...TempFileAccess
    }
  }
  ${TempFileAccessFragmentDoc}
`;
export function useGetTemporaryS3FileAccessQuery(
  baseOptions?: Apollo.QueryHookOptions<IGetTemporaryS3FileAccessQuery, IGetTemporaryS3FileAccessQueryVariables>
) {
  return Apollo.useQuery<IGetTemporaryS3FileAccessQuery, IGetTemporaryS3FileAccessQueryVariables>(
    GetTemporaryS3FileAccessDocument,
    baseOptions
  );
}
export function useGetTemporaryS3FileAccessLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetTemporaryS3FileAccessQuery, IGetTemporaryS3FileAccessQueryVariables>
) {
  return Apollo.useLazyQuery<IGetTemporaryS3FileAccessQuery, IGetTemporaryS3FileAccessQueryVariables>(
    GetTemporaryS3FileAccessDocument,
    baseOptions
  );
}
export type GetTemporaryS3FileAccessQueryHookResult = ReturnType<typeof useGetTemporaryS3FileAccessQuery>;
export type GetTemporaryS3FileAccessLazyQueryHookResult = ReturnType<typeof useGetTemporaryS3FileAccessLazyQuery>;
export type GetTemporaryS3FileAccessQueryResult = Apollo.QueryResult<
  IGetTemporaryS3FileAccessQuery,
  IGetTemporaryS3FileAccessQueryVariables
>;
