import { useMemo } from 'react';

import { useAPIConfig } from '../context';
import { usePost } from './http';

interface IConfigData {
  searchui_configs: [IConfigFeatures];
}

interface IConfigFeatures {
  top_filters_config: IQuickFilterConfig;
  settings_config: string;
}
interface ISearchUiConfigPayload {
  payload: string;
}

interface IQuickFilterConfig {
  enable_top_filters: string;
  cost_centers: Array<string>;
  functional_groups: Array<string>;
}

export function useSearchUiConfigsQuery() {
  const payload = useMemo(() => ({ payload: '' }), []);
  const { adminUrl } = useAPIConfig();
  const { data, loading, error } = usePost<IConfigData, ISearchUiConfigPayload>(
    `${adminUrl}/get-searchui-configs`,
    payload
  );

  return {
    loading,
    error,
    response: Object.keys(data ?? {}).length === 0 ? null : data,
  };
}
