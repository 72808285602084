import { IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { ListGroup } from 'react-bootstrap';

import './sidenav-item.scss';

export interface SidenavItemProps {
  name: string;
  icon: IconDefinition;
  handler: () => void;
}

export const SidenavItem: React.FC<SidenavItemProps> = props => {
  return (
    <ListGroup.Item className="bg-transparent border-0" onClick={props.handler} action>
      <div>
        <FontAwesomeIcon icon={props.icon} className="fa-fw mr-3" />
        {props.name}
      </div>
    </ListGroup.Item>
  );
};

export default SidenavItem;
