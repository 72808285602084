import { ResultViewType, SearchContext } from '@amgen/shared';
import React, { ReactNode, useContext, useState } from 'react';

import './multiple-attribute-container.scss';

export interface MultipleAttributeContainerProps {
  children: (value: any) => ReactNode;
  value: any;
  byPassArrayCheck?: boolean;
}

export const MultipleAttributeContainer: React.FC<MultipleAttributeContainerProps> = ({
  value,
  children,
  byPassArrayCheck,
}) => {
  const { selectedViewType } = useContext(SearchContext);
  const [addLineBreak, setLineBreak] = useState(selectedViewType === ResultViewType.Table ? true : false);
  return value instanceof Array && !byPassArrayCheck ? (
    <>
      {value.map((v, index) => (
        // using index as key as uniqueness of values is not guranteed.
        <>
          <React.Fragment key={index}>{children(v)}</React.Fragment>
          {addLineBreak && <br></br>}
        </>
      ))}
    </>
  ) : (
    <>{children(value)}</>
  );
};

export default MultipleAttributeContainer;
