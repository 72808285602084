import classnames from 'classnames';
import React from 'react';
import Navbar from 'react-bootstrap/Navbar';

import './header.scss';

export interface HeaderProps {
  className?: string;
  bg?: string;
}

export const Header: React.FC<HeaderProps> = props => {
  return (
    <Navbar
      className={classnames('d-flex justify-content-between', props.className)}
      bg={props.bg ?? 'primary'}
      expand="lg"
    >
      {props.children}
    </Navbar>
  );
};

export default Header;
