import { IProduct } from '@amgen/core';
import { createContext } from 'react';

export interface IClinicalState {
  product: IProduct | null;
  loading: boolean;
  clinicalType: string;
  clinicalId: string;
  addGraph: (type: string, id: string) => void;
  nextGraph: () => void;
  prevGraph: () => void;
  hasNext: boolean;
  hasPrev: boolean;
  historyCount: number;
}

export const ClinicalContext = createContext<IClinicalState>(null!);
