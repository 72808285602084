import * as Types from '@amgen/core';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type IGetPreviousSearchesQueryVariables = Types.Exact<{
  appName: Types.ApplicationName;
  dimensionId?: Types.Maybe<Types.Scalars['Int']>;
  count?: Types.Maybe<Types.Scalars['Int']>;
}>;

export type IGetPreviousSearchesQuery = Pick<Types.IQuery, 'getPreviousSearches'>;

export type IGetContentQueryVariables = Types.Exact<{
  appName?: Types.Maybe<Types.ApplicationName>;
  query: Types.Scalars['String'];
  filters?: Types.Maybe<Array<Types.Maybe<Types.Scalars['String']>> | Types.Maybe<Types.Scalars['String']>>;
  start?: Types.Maybe<Types.Scalars['Int']>;
  page?: Types.Maybe<Types.Scalars['Int']>;
  count?: Types.Maybe<Types.Scalars['Int']>;
  sort?: Types.Maybe<Types.SortField>;
  order?: Types.Maybe<Types.Direction>;
  addPersonalizationDetails?: Types.Maybe<Types.Scalars['Boolean']>;
  solrHandler?: Types.Maybe<Types.Scalars['String']>;
  noShortContent?: Types.Maybe<Types.Scalars['Boolean']>;
  sourceType?: Types.Maybe<Types.Scalars['String']>;
  userType?: Types.Maybe<Types.Scalars['String']>;
}>;

export type IGetContentQuery = {
  search?: Types.Maybe<{
    docs?: Types.Maybe<Array<Types.Maybe<{ dataAsset?: Types.Maybe<Pick<Types.IDataAsset, 'content'>> }>>>;
  }>;
};

export type ISearchQueryVariables = Types.Exact<{
  appName?: Types.Maybe<Types.ApplicationName>;
  query: Types.Scalars['String'];
  filters?: Types.Maybe<Array<Types.Maybe<Types.Scalars['String']>> | Types.Maybe<Types.Scalars['String']>>;
  start?: Types.Maybe<Types.Scalars['Int']>;
  page?: Types.Maybe<Types.Scalars['Int']>;
  count?: Types.Maybe<Types.Scalars['Int']>;
  sort?: Types.Maybe<Types.SortField>;
  order?: Types.Maybe<Types.Direction>;
  addPersonalizationDetails?: Types.Maybe<Types.Scalars['Boolean']>;
  includeDocs: Types.Scalars['Boolean'];
  noShortContent?: Types.Maybe<Types.Scalars['Boolean']>;
  solrHandler?: Types.Maybe<Types.Scalars['String']>;
  clustering?: Types.Maybe<Types.Scalars['Boolean']>;
  userType?: Types.Maybe<Types.Scalars['String']>;
  sourceType?: Types.Maybe<Types.Scalars['String']>;
}>;

export type IConversationalMultiSearchQueryVariables = Types.Exact<{
  applicationName?: Types.Maybe<Types.ApplicationName>;
  query: Types.Scalars['String'];
  filters?: Types.Maybe<Array<Types.Maybe<Types.Scalars['String']>> | Types.Maybe<Types.Scalars['String']>>;
  userPseudoId?: Types.Scalars['String'];
  userType?: Types.Maybe<Types.Scalars['String']>;
}>;

export type ISearchQuery = {
  search?: Types.Maybe<
    Types.MakeOptional<Pick<Types.IDataAssetCollection, 'resultCount' | 'QTime'>, 'QTime'> & {
      summary?: Types.Maybe<Types.Scalars['String']>;
      citations?: Types.Maybe<Array<Types.Maybe<Pick<Types.IMultiTurnCitation, 'title' | 'uri'>>>>;
      docs?: Types.Maybe<
        Array<
          Types.Maybe<{
            dataAsset?: Types.Maybe<IDataAssetFragment>;
            personalizationDetails?: Types.Maybe<{
              bookmarkDetails?: Types.Maybe<Pick<Types.IBookmarkDetails, 'isBookmarked' | 'bookmarkId'>>;
            }>;
          }>
        >
      >;
      clusters?: Types.Maybe<Array<Types.Maybe<Pick<Types.IClusterData, 'clusterName' | 'docs'>>>>;
    }
  >;
};

export type IConversationalMultiSearchQuery = {
  answerSearch?: Types.Maybe<
    Types.MakeOptional<Pick<Types.IDataAssetMultiCollection, 'userPseudoId'>, 'userPseudoId'> & {
      dataAssetCollection?: Types.Maybe<
        Types.MakeOptional<Pick<Types.IDataAssetCollection, 'resultCount' | 'QTime'>, 'QTime'> & {
          docs?: Types.Maybe<
            Array<
              Types.Maybe<{
                dataAsset?: Types.Maybe<IDataAssetFragment>;
                personalizationDetails?: Types.Maybe<{
                  bookmarkDetails?: Types.Maybe<Pick<Types.IBookmarkDetails, 'isBookmarked' | 'bookmarkId'>>;
                }>;
              }>
            >
          >;
          clusters?: Types.Maybe<Array<Types.Maybe<Pick<Types.IClusterData, 'clusterName' | 'docs'>>>>;
        }
      >;
      conversations?: Types.Maybe<
        Array<
          Types.Maybe<{
            term?: Types.Maybe<Types.Scalars['String']>;
            summary?: Types.Maybe<Types.Scalars['String']>;
            citations?: Types.Maybe<
              Array<Types.Maybe<Pick<Types.IMultiTurnCitation, 'title' | 'uri' | 'pageNo' | 'source'>>>
            >;
          }>
        >
      >;
    }
  >;
};

export type IGetTypeaheadResultUrlQueryVariables = Types.Exact<{
  appName?: Types.Maybe<Types.ApplicationName>;
  filters?: Types.Maybe<Array<Types.Maybe<Types.Scalars['String']>> | Types.Maybe<Types.Scalars['String']>>;
  solrHandler?: Types.Maybe<Types.Scalars['String']>;
  userId?: Types.Maybe<Types.Scalars['String']>;
  userType?: Types.Maybe<Types.Scalars['String']>;
}>;

export type IGetTypeaheadResultUrlQuery = Pick<Types.IQuery, 'getTypeaheadResultUrl'>;

export type IGetSearchCountQueryVariables = Types.Exact<{
  applicationName?: Types.Maybe<Types.ApplicationName>;
  query: Types.Scalars['String'];
  filters?: Types.Maybe<Array<Types.Maybe<Types.Scalars['String']>> | Types.Maybe<Types.Scalars['String']>>;
  solrHandler?: Types.Maybe<Types.Scalars['String']>;
  sourceType?: Types.Maybe<Array<Types.Maybe<Types.Scalars['String']>> | Types.Maybe<Types.Scalars['String']>>;
  userType?: Types.Maybe<Types.Scalars['String']>;
}>;

export type IGetSearchCountQuery = Pick<Types.IQuery, 'getSearchCount'>;

export type IDataAssetFragment = Types.MakeOptional<
  Pick<
    Types.IDataAsset,
    | 'account_id'
    | 'account_name'
    | 'account_sub_type'
    | 'activity_type'
    | 'application_type'
    | 'amgen_current_law_contacts'
    | 'amgen_paralegal'
    | 'contract_amgen_company'
    | 'amgen_attorney'
    | 'contract_ac_contact'
    | 'asset_type'
    | 'attachment_content'
    | 'attachments'
    | 'attendee_address__c'
    | 'attendee_city__c'
    | 'attendee_country__c'
    | 'attendee_full_name'
    | 'attendee_type__c'
    | 'attendees'
    | 'attesting_rae__c'
    | 'author'
    | 'author_s'
    | 'body'
    | 'bu_c'
    | 'business_process'
    | 'city'
    | 'classification'
    | 'collaborator'
    | 'comments'
    | 'company_partner'
    | 'conditions'
    | 'contains_comments'
    | 'content_source'
    | 'content_type'
    | 'country'
    | 'create_ts'
    | 'created_on'
    | 'confidentiality_expiry_date'
    | 'contract_reference_number'
    | 'contract_parties'
    | 'contract_effective_date'
    | 'contract_expiry_date'
    | 'contract_number'
    | 'contract_protocol'
    | 'contract_summary_terms'
    | 'contract_title'
    | 'contract_type'
    | 'amgen_law_contacts'
    | 'contract_investigator'
    | 'department'
    | 'discussion_delivered_aux_id'
    | 'discussion_delivered_id'
    | 'discussion_heard_aux_id'
    | 'discussion_heard_id'
    | 'display_id'
    | 'doc_last_modified'
    | 'doc_nav_prefix'
    | 'doc_owner_s'
    | 'doc_status'
    | 'doc_subtype'
    | 'doc_subunit'
    | 'doc_type'
    | 'doc_unit'
    | 'doc_version'
    | 'document_approved_date'
    | 'document_effective_date'
    | 'document_id'
    | 'document_number'
    | 'drug_product'
    | 'product_number'
    | 'extracted_indications'
    | 'duration'
    | 'end_date'
    | 'experiment_id'
    | 'external_material'
    | 'extracted_amg_diseases_s'
    | 'extracted_amg_drugs'
    | 'extracted_amg_genes'
    | 'extracted_drug_targets'
    | 'extracted_lot_number'
    | 'extracted_modalities'
    | 'extracted_molecules'
    | 'extracted_therapeutic_areas'
    | 'fastlane'
    | 'feedback'
    | 'file_size'
    | 'file_type_normalized'
    | 'filename'
    | 'function'
    | 'has_attachment'
    | 'has_attendee'
    | 'id'
    | 'ims_id'
    | 'inqdescription'
    | 'inqid'
    | 'inqlink'
    | 'interaction_date'
    | 'interaction_medium'
    | 'intervention_type'
    | 'intid'
    | 'investigator'
    | 'issn'
    | 'kc_topic'
    | 'lang'
    | 'law_external_contacts'
    | 'linktoemailresponse'
    | 'linktopostalresponse'
    | 'location'
    | 'meddra_indication_nav_prefix'
    | 'medical_event_name'
    | 'medical_event_type'
    | 'method_nav_prefix'
    | 'mirlink'
    | 'molecule'
    | 'name'
    | 'notes'
    | 'object_name'
    | 'parent_object_id'
    | 'parent_url'
    | 'path'
    | 'path_prefix'
    | 'pd_academic_research'
    | 'pd_innovation'
    | 'phase'
    | 'plant'
    | 'prefix_class'
    | 'prefix_location'
    | 'preview_images'
    | 'process'
    | 'product'
    | 'product_display_name'
    | 'product_s'
    | 'product_text'
    | 'project'
    | 'question'
    | 'question_order'
    | 'question_response'
    | 'question_text'
    | 'record_type_name'
    | 'recruitment_status'
    | 'region'
    | 'region_l'
    | 'regulation'
    | 'resid'
    | 'result_type'
    | 'request_type'
    | 'score'
    | 'short_content'
    | 'site'
    | 'site_location'
    | 'site_name'
    | 'page'
    | 'source_type'
    | 'source_id'
    | 'source_filename'
    | 'space_name'
    | 'specialty'
    | 'sponsor'
    | 'sponsor_network'
    | 'spotlight_view_url'
    | 'start_date'
    | 'state'
    | 'status'
    | 'steady_state_url'
    | 'study_number'
    | 'study_status'
    | 'subclassification'
    | 'summary'
    | 'supplementary_list'
    | 'survey_id_account_id'
    | 'survey_name'
    | 'tags'
    | 'technologies'
    | 'thematic_groups'
    | 'therapeutic_area'
    | 'thumbnail_large'
    | 'tier'
    | 'timestamp'
    | 'title'
    | 'topic'
    | 'url'
    | 'version_number'
    | 'year'
    | 'yield'
    | 'pageNo'
    | 'zip'
  >,
  'short_content'
>;

export type IDataAssetForGetSearchResultsFragment = Pick<
  Types.IDataAsset,
  'title' | 'id' | 'content_source' | 'content_type' | 'country' | 'url'
>;

export type IDataAssetForSpotlightFragment = Types.MakeOptional<
  Pick<
    Types.IDataAsset,
    | 'content'
    | 'doc_last_modified'
    | 'file_size'
    | 'id'
    | 'parent_url'
    | 'short_content'
    | 'site_name'
    | 'spotlight_view_url'
    | 'thumbnail_large'
    | 'title'
  >,
  'short_content'
>;

export type IGetTopLinksQueryVariables = Types.Exact<{
  applicationName?: Types.Maybe<Types.ApplicationName>;
  query: Types.Scalars['String'];
  count?: Types.Maybe<Types.Scalars['Int']>;
  solrHandler?: Types.Maybe<Types.Scalars['String']>;
  cosine?: Types.Maybe<Types.Scalars['Boolean']>;
}>;

export type IGetTopLinksQuery = {
  getTopLinks?: Types.Maybe<
    { __typename: 'ObjectDataAssetCollection' } & Pick<
      Types.IObjectDataAssetCollection,
      'resultCount' | 'QTime' | 'bertScore' | 'isResponseFromKeyword'
    > & { docs?: Types.Maybe<Array<Types.Maybe<{ __typename: 'DataAsset' } & IDataAssetForSearchObjectsFragment>>> }
  >;
};

export type IGetConsolidatedAPIQueryVariables = Types.Exact<{
  applicationName?: Types.Maybe<Types.ApplicationName>;
  start?: Types.Maybe<Types.Scalars['Int']>;
  noShortContent?: Types.Maybe<Types.Scalars['Boolean']>;
  noSpotlight?: Types.Maybe<Types.Scalars['Boolean']>;
  cosine?: Types.Maybe<Types.Scalars['Boolean']>;
  query: Types.Scalars['String'];
  page?: Types.Maybe<Types.Scalars['Int']>;
  bertCount?: Types.Maybe<Types.Scalars['Int']>;
  keywordCount?: Types.Maybe<Types.Scalars['Int']>;
  sort?: Types.Maybe<Types.SortField>;
  order?: Types.Maybe<Types.Direction>;
  addPersonalizationDetails?: Types.Maybe<Types.Scalars['Boolean']>;
  filters?: Types.Maybe<Array<Types.Maybe<Types.Scalars['String']>> | Types.Maybe<Types.Scalars['String']>>;
  count?: Types.Maybe<Types.Scalars['Int']>;
}>;

export type IGetConsolidatedAPIQuery = {
  getAllLinks?: Types.Maybe<
    { __typename: '"UnifiedDataAssetCollection"' } & Pick<
      Types.IObjectConsolidationAPIAssetCollection,
      'resultCount' | 'QTime'
    > & { docsData?: Types.Maybe<Array<Types.Maybe<{ __typename: 'DataAsset' } & IDataAssetForSearchObjectsFragment>>> }
  >;
};
export type IDataAssetForConsolidatedAPIFragment = Pick<
  Types.IDataAsset,
  | 'content'
  | 'dataType'
  | 'doc_last_modified'
  | 'file_size'
  | 'id'
  | 'parent_url'
  | 'short_content'
  | 'site_name'
  | 'spotlight_view_url'
  | 'thumbnail_large'
  | 'title'
  | 'url'
  | 'parent_object_id'
  | 'category'
  | 'is_deeplink'
  | 'isResponseFromKeyword'
  | 'toplinks'
  | 'description'
  | 'preview_images'
  | 'start_date'
  | 'score'
>;

export type IDataAssetForSearchObjectsFragment = Pick<
  Types.IDataAsset,
  | 'content'
  | 'description'
  | 'end_date'
  | 'id'
  | 'location'
  | 'preview_images'
  | 'start_date'
  | 'thumbnail_large'
  | 'title'
  | 'url'
  | 'score'
>;

export const DataAssetFragmentDoc = gql`
  fragment DataAsset on DataAsset {
    content
    dataType
    category
    is_deeplink
    isResponseFromKeyword
    toplinks {
      title
      url
      id
      content
      __typename
    }
    description
    account_id
    account_name
    account_sub_type
    activity_type
    application_type
    amgen_current_law_contacts
    amgen_paralegal
    contract_amgen_company
    amgen_attorney
    contract_ac_contact
    asset_type
    attachment_content
    attachments
    attendee_address__c
    attendee_city__c
    attendee_country__c
    attendee_full_name
    attendee_type__c
    attendees
    attesting_rae__c
    author
    author_s
    body
    bu_c
    business_process
    city
    classification
    collaborator
    comments
    company_partner
    conditions
    contains_comments
    content_source
    content_type
    country
    create_ts
    created_on
    confidentiality_expiry_date
    contract_reference_number
    contract_parties
    contract_effective_date
    contract_expiry_date
    contract_number
    contract_protocol
    contract_summary_terms
    contract_title
    contract_type
    component_type
    amgen_law_contacts
    contract_investigator
    department
    discussion_delivered_aux_id
    discussion_delivered_id
    discussion_heard_aux_id
    discussion_heard_id
    display_id
    doc_last_modified
    doc_nav_prefix
    doc_owner_s
    doc_status
    doc_subtype
    doc_subunit
    doc_type
    doc_unit
    doc_version
    document_approved_date
    document_effective_date
    document_id
    document_number
    drug_product
    product_number
    extracted_indications
    duration
    end_date
    experiment_id
    external_material
    extracted_amg_diseases_s
    extracted_amg_drugs
    extracted_amg_genes
    extracted_drug_targets
    extracted_lot_number
    extracted_modalities
    extracted_molecules
    extracted_therapeutic_areas
    fastlane
    feedback
    file_size
    file_type_normalized
    filename
    function
    has_attachment
    has_attendee
    id
    ims_id
    inqdescription
    inqid
    inqlink
    interaction_date
    interaction_medium
    intervention_type
    intid
    investigator
    issn
    kc_topic
    lang
    law_external_contacts
    linktoemailresponse
    linktopostalresponse
    location
    meddra_indication_nav_prefix
    medical_event_name
    medical_event_type
    method_nav_prefix
    mirlink
    molecule
    name
    notes
    object_name
    parent_object_id
    parent_url
    path
    path_prefix
    pd_academic_research
    pd_innovation
    phase
    plant
    prefix_class
    prefix_location
    preview_images
    process
    product
    product_display_name
    product_s
    product_text
    project
    question
    question_order
    question_response
    question_text
    record_type_name
    recruitment_status
    region
    region_l
    regulation
    resid
    result_type
    request_type
    score
    short_content @skip(if: $noShortContent)
    site
    site
    site_location
    site_name
    page
    source_type
    source_id
    space_name
    specialty
    sponsor
    sponsor_network
    source_filename
    spotlight_view_url
    start_date
    state
    status
    status
    steady_state_url
    study_number
    study_status
    subclassification
    summary
    supplementary_list
    survey_id_account_id
    survey_name
    tags
    technologies
    thematic_groups
    therapeutic_area
    thumbnail_large
    tier
    timestamp
    title
    topic
    url
    version_number
    year
    yield
    zip
  }
`;

export const DataAssetFragmentDocMulti = gql`
  fragment DataAsset on DataAsset {
    content
    dataType
    category
    is_deeplink
    isResponseFromKeyword
    toplinks {
      title
      url
      id
      content
      __typename
    }
    description
    account_id
    account_name
    account_sub_type
    activity_type
    application_type
    amgen_current_law_contacts
    amgen_paralegal
    contract_amgen_company
    amgen_attorney
    contract_ac_contact
    asset_type
    attachment_content
    attachments
    attendee_address__c
    attendee_city__c
    attendee_country__c
    attendee_full_name
    attendee_type__c
    attendees
    attesting_rae__c
    author
    author_s
    body
    bu_c
    business_process
    city
    classification
    collaborator
    comments
    company_partner
    conditions
    contains_comments
    content_source
    content_type
    country
    create_ts
    created_on
    confidentiality_expiry_date
    contract_reference_number
    contract_parties
    contract_effective_date
    contract_expiry_date
    contract_number
    contract_protocol
    contract_summary_terms
    contract_title
    contract_type
    component_type
    amgen_law_contacts
    contract_investigator
    department
    discussion_delivered_aux_id
    discussion_delivered_id
    discussion_heard_aux_id
    discussion_heard_id
    display_id
    doc_last_modified
    doc_nav_prefix
    doc_owner_s
    doc_status
    doc_subtype
    doc_subunit
    doc_type
    doc_unit
    doc_version
    document_approved_date
    document_effective_date
    document_id
    document_number
    drug_product
    product_number
    extracted_indications
    duration
    end_date
    experiment_id
    external_material
    extracted_amg_diseases_s
    extracted_amg_drugs
    extracted_amg_genes
    extracted_drug_targets
    extracted_lot_number
    extracted_modalities
    extracted_molecules
    extracted_therapeutic_areas
    fastlane
    feedback
    file_size
    file_type_normalized
    filename
    function
    has_attachment
    has_attendee
    id
    ims_id
    inqdescription
    inqid
    inqlink
    interaction_date
    interaction_medium
    intervention_type
    intid
    investigator
    issn
    kc_topic
    lang
    law_external_contacts
    linktoemailresponse
    linktopostalresponse
    location
    meddra_indication_nav_prefix
    medical_event_name
    medical_event_type
    method_nav_prefix
    mirlink
    molecule
    name
    notes
    object_name
    parent_object_id
    parent_url
    path
    path_prefix
    pd_academic_research
    pd_innovation
    phase
    plant
    prefix_class
    prefix_location
    preview_images
    process
    product
    product_display_name
    product_s
    product_text
    project
    question
    question_order
    question_response
    question_text
    record_type_name
    recruitment_status
    region
    region_l
    regulation
    resid
    result_type
    request_type
    score
    short_content @skip(if: $noShortContent)
    site
    site
    site_location
    site_name
    page
    source_type
    source_id
    space_name
    specialty
    sponsor
    sponsor_network
    source_filename
    spotlight_view_url
    start_date
    state
    status
    status
    steady_state_url
    study_number
    study_status
    subclassification
    summary
    supplementary_list
    survey_id_account_id
    survey_name
    tags
    technologies
    thematic_groups
    therapeutic_area
    thumbnail_large
    tier
    timestamp
    title
    topic
    url
    version_number
    year
    yield
    pageNo
    zip
  }
`;

export const DataAssetForGetSearchResultsFragmentDoc = gql`
  fragment DataAssetForGetSearchResults on DataAsset {
    title
    id
    content_source
    content_type
    country
    url
  }
`;
export const DataAssetForSpotlightFragmentDoc = gql`
  fragment DataAssetForSpotlight on DataAsset {
    content
    doc_last_modified
    file_size
    id
    parent_url
    short_content @skip(if: $noShortContent)
    site_name
    spotlight_view_url
    thumbnail_large
    title
    title
  }
`;
export const DataAssetForSearchObjectsFragmentDoc = gql`
  fragment DataAssetForSearchObjects on DataAsset {
    content
    description
    end_date
    id
    location
    preview_images
    start_date
    thumbnail_large
    title
    url
    score
  }
`;
export const GetPreviousSearchesDocument = gql`
  query GetPreviousSearches($appName: ApplicationName!, $dimensionId: Int, $count: Int = 5) {
    getPreviousSearches(applicationName: $appName, dimensionId: $dimensionId, count: $count)
  }
`;
export function useGetPreviousSearchesQuery(
  baseOptions: Apollo.QueryHookOptions<IGetPreviousSearchesQuery, IGetPreviousSearchesQueryVariables>
) {
  return Apollo.useQuery<IGetPreviousSearchesQuery, IGetPreviousSearchesQueryVariables>(
    GetPreviousSearchesDocument,
    baseOptions
  );
}
export function useGetPreviousSearchesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetPreviousSearchesQuery, IGetPreviousSearchesQueryVariables>
) {
  return Apollo.useLazyQuery<IGetPreviousSearchesQuery, IGetPreviousSearchesQueryVariables>(
    GetPreviousSearchesDocument,
    baseOptions
  );
}
export type GetPreviousSearchesQueryHookResult = ReturnType<typeof useGetPreviousSearchesQuery>;
export type GetPreviousSearchesLazyQueryHookResult = ReturnType<typeof useGetPreviousSearchesLazyQuery>;
export type GetPreviousSearchesQueryResult = Apollo.QueryResult<
  IGetPreviousSearchesQuery,
  IGetPreviousSearchesQueryVariables
>;
export const GetContentDocument = gql`
  query GetContent(
    $appName: ApplicationName
    $query: String!
    $filters: [String]
    $start: Int = 0
    $page: Int
    $count: Int
    $sort: SortField
    $order: Direction
    $addPersonalizationDetails: Boolean
    $solrHandler: String = "/search-select"
    $noShortContent: Boolean = true
    $sourceType: String
    $userType: String
  ) {
    search(
      applicationName: $appName
      query: $query
      filters: $filters
      start: $start
      page: $page
      count: $count
      sort: $sort
      order: $order
      addPersonalizationDetails: $addPersonalizationDetails
      solrHandler: $solrHandler
      noShortContent: $noShortContent
      sourceType: $sourceType
      userType: $userType
    ) {
      docs {
        dataAsset {
          content
        }
      }
    }
  }
`;
export function useGetContentQuery(baseOptions: Apollo.QueryHookOptions<IGetContentQuery, IGetContentQueryVariables>) {
  return Apollo.useQuery<IGetContentQuery, IGetContentQueryVariables>(GetContentDocument, baseOptions);
}
export function useGetContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetContentQuery, IGetContentQueryVariables>
) {
  return Apollo.useLazyQuery<IGetContentQuery, IGetContentQueryVariables>(GetContentDocument, baseOptions);
}
export type GetContentQueryHookResult = ReturnType<typeof useGetContentQuery>;
export type GetContentLazyQueryHookResult = ReturnType<typeof useGetContentLazyQuery>;
export type GetContentQueryResult = Apollo.QueryResult<IGetContentQuery, IGetContentQueryVariables>;
export const SearchDocument = gql`
  query Search(
    $appName: ApplicationName
    $query: String!
    $filters: [String]
    $start: Int = 0
    $page: Int
    $count: Int
    $sort: SortField
    $order: Direction
    $addPersonalizationDetails: Boolean
    $includeDocs: Boolean!
    $noShortContent: Boolean = false
    $solrHandler: String
    $clustering: Boolean = false
    $userType: String
    $sourceType: String
  ) {
    search(
      applicationName: $appName
      query: $query
      filters: $filters
      start: $start
      page: $page
      count: $count
      sort: $sort
      order: $order
      noShortContent: $noShortContent
      addPersonalizationDetails: $addPersonalizationDetails
      solrHandler: $solrHandler
      clustering: $clustering
      userType: $userType
      sourceType: $sourceType
    ) {
      resultCount
      QTime @include(if: $includeDocs)
      docs @include(if: $includeDocs) {
        dataAsset {
          ...DataAsset
        }
        personalizationDetails {
          bookmarkDetails {
            isBookmarked
            bookmarkId
          }
        }
      }
      clusters @include(if: $includeDocs) {
        clusterName
        docs
      }
    }
  }
  ${DataAssetFragmentDoc}
`;
export function useSearchQuery(baseOptions: Apollo.QueryHookOptions<ISearchQuery, ISearchQueryVariables>) {
  return Apollo.useQuery<ISearchQuery, ISearchQueryVariables>(SearchDocument, baseOptions);
}
export function useSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ISearchQuery, ISearchQueryVariables>) {
  return Apollo.useLazyQuery<ISearchQuery, ISearchQueryVariables>(SearchDocument, baseOptions);
}
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchQueryResult = Apollo.QueryResult<ISearchQuery, ISearchQueryVariables>;

export const DataAssetMultiFragmentDoc = gql`
  fragment DataAsset on DataAsset {
    account_id
    account_name
    account_sub_type
    activity_type
    application_type
    amgen_current_law_contacts
    amgen_paralegal
    contract_amgen_company
    amgen_attorney
    contract_ac_contact
    asset_type
    attachment_content
    attachments
    attendee_address__c
    attendee_city__c
    attendee_country__c
    attendee_full_name
    attendee_type__c
    attendees
    attesting_rae__c
    author
    author_s
    body
    bu_c
    business_process
    city
    classification
    collaborator
    comments
    company_partner
    conditions
    contains_comments
    content_source
    content_type
    country
    create_ts
    created_on
    confidentiality_expiry_date
    contract_reference_number
    contract_parties
    contract_effective_date
    contract_expiry_date
    contract_number
    contract_protocol
    contract_summary_terms
    contract_title
    contract_type
    amgen_law_contacts
    contract_investigator
    department
    discussion_delivered_aux_id
    discussion_delivered_id
    discussion_heard_aux_id
    discussion_heard_id
    display_id
    doc_last_modified
    doc_nav_prefix
    doc_owner_s
    doc_status
    doc_subtype
    doc_subunit
    doc_type
    doc_unit
    doc_version
    document_approved_date
    document_effective_date
    document_id
    document_number
    drug_product
    product_number
    extracted_indications
    duration
    end_date
    experiment_id
    external_material
    extracted_amg_diseases_s
    extracted_amg_drugs
    extracted_amg_genes
    extracted_drug_targets
    extracted_lot_number
    extracted_modalities
    extracted_molecules
    extracted_therapeutic_areas
    fastlane
    feedback
    file_size
    file_type_normalized
    filename
    function
    has_attachment
    has_attendee
    id
    ims_id
    inqdescription
    inqid
    inqlink
    interaction_date
    interaction_medium
    intervention_type
    intid
    investigator
    issn
    kc_topic
    lang
    law_external_contacts
    linktoemailresponse
    linktopostalresponse
    location
    meddra_indication_nav_prefix
    medical_event_name
    medical_event_type
    method_nav_prefix
    mirlink
    molecule
    name
    notes
    object_name
    parent_object_id
    parent_url
    path
    path_prefix
    pd_academic_research
    pd_innovation
    phase
    plant
    prefix_class
    prefix_location
    preview_images
    process
    product
    product_display_name
    product_s
    product_text
    project
    question
    question_order
    question_response
    question_text
    record_type_name
    recruitment_status
    region
    region_l
    regulation
    resid
    result_type
    request_type
    score
    short_content
    site
    site
    site_location
    site_name
    page
    source_type
    source_id
    space_name
    specialty
    sponsor
    sponsor_network
    source_filename
    spotlight_view_url
    start_date
    state
    status
    status
    steady_state_url
    study_number
    study_status
    subclassification
    summary
    supplementary_list
    survey_id_account_id
    survey_name
    tags
    technologies
    thematic_groups
    therapeutic_area
    thumbnail_large
    tier
    timestamp
    title
    topic
    url
    version_number
    year
    yield
    pageNo
    zip
    __typename
  }
`;

export const ConversationalDataFragmentDoc = gql`
  fragment ConversationData on ConversationData {
    term
    summary
    citations {
      ...ConversationCitation
    }
  }
`;

export const ConversationalCitationFragmentDoc = gql`
  fragment ConversationCitation on ConversationCitation {
    title
    uri
  }
`;

export const ConversationalMultiCitationFragmentDoc = gql`
  fragment ConversationCitation on ConversationCitation {
    title
    uri
    pageNo
    source
  }
`;

export const MultiSearchDocument = gql`
  query Search(
    $appName: ApplicationName
    $query: String!
    $filters: [String]
    $start: Int = 0
    $page: Int
    $count: Int
    $sort: SortField
    $order: Direction
    $addPersonalizationDetails: Boolean
    $includeDocs: Boolean!
    $noShortContent: Boolean = false
    $solrHandler: String = "/search-select"
    $clustering: Boolean = false
    $userType: String
    $sourceType: String
  ) {
    search(
      applicationName: $appName
      query: $query
      filters: $filters
      start: $start
      page: $page
      count: $count
      sort: $sort
      order: $order
      noShortContent: $noShortContent
      addPersonalizationDetails: $addPersonalizationDetails
      solrHandler: $solrHandler
      clustering: $clustering
      userType: $userType
      sourceType: $sourceType
    ) {
      resultCount
      QTime @include(if: $includeDocs)
      summary @include(if: $includeDocs)
      citations @include(if: $includeDocs) {
        ...ConversationCitation
      }
      docs @include(if: $includeDocs) {
        dataAsset {
          ...DataAsset
          __typename
        }
        personalizationDetails {
          bookmarkDetails {
            isBookmarked
            bookmarkId
            __typename
          }
          __typename
        }
        __typename
      }
      clusters @include(if: $includeDocs) {
        clusterName
        docs
        __typename
      }
      __typename
    }
  }

  ${ConversationalCitationFragmentDoc}
  ${DataAssetFragmentDocMulti}
`;
export function useMultiSearchQuery(baseOptions: Apollo.QueryHookOptions<ISearchQuery, ISearchQueryVariables>) {
  return Apollo.useQuery<ISearchQuery, ISearchQueryVariables>(MultiSearchDocument, baseOptions);
}
export function useMultiSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ISearchQuery, ISearchQueryVariables>
) {
  return Apollo.useLazyQuery<ISearchQuery, ISearchQueryVariables>(MultiSearchDocument, baseOptions);
}
export type MultiSearchQueryHookResult = ReturnType<typeof useMultiSearchQuery>;
export type MultiSearchLazyQueryHookResult = ReturnType<typeof useMultiSearchLazyQuery>;
export type MultiSearchQueryResult = Apollo.QueryResult<ISearchQuery, ISearchQueryVariables>;

export const ConversationalMultiSearch = gql`
  query answerSearch(
    $applicationName: ApplicationName = EEA
    $query: String!
    $userPseudoId: String
    $filters: [String]
    $userType: String
  ) {
    answerSearch(
      applicationName: $applicationName
      query: $query
      userPseudoId: $userPseudoId
      filters: $filters
      userType: $userType
    ) {
      userPseudoId
      conversations {
        ...ConversationData
        __typename
      }
      dataAssetCollection {
        resultCount
        QTime
        docs {
          dataAsset {
            ...DataAsset
            __typename
          }
          __typename
        }
        clusters {
          clusterName
          docs
          __typename
        }
        __typename
      }
    }
  }

  ${ConversationalDataFragmentDoc}
  ${ConversationalMultiCitationFragmentDoc}
  ${DataAssetMultiFragmentDoc}
`;

export function useConvertionalMultiSearchQuery(
  baseOptions: Apollo.QueryHookOptions<IConversationalMultiSearchQuery, IConversationalMultiSearchQueryVariables>
) {
  return Apollo.useQuery<IConversationalMultiSearchQuery, IConversationalMultiSearchQueryVariables>(
    ConversationalMultiSearch,
    baseOptions
  );
}
export function useConvertionalMultiSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IConversationalMultiSearchQuery, IConversationalMultiSearchQueryVariables>
) {
  return Apollo.useLazyQuery<IConversationalMultiSearchQuery, IConversationalMultiSearchQueryVariables>(
    ConversationalMultiSearch,
    baseOptions
  );
}
export type ConvertionalMultiSearchQueryHookResult = ReturnType<typeof useConvertionalMultiSearchQuery>;
export type ConvertionalMultiSearchLazyQueryHookResult = ReturnType<typeof useConvertionalMultiSearchLazyQuery>;
export type ConvertionalMultiSearchQueryResult = Apollo.QueryResult<
  IConversationalMultiSearchQuery,
  IConversationalMultiSearchQueryVariables
>;

export const GetTypeaheadResultUrlDocument = gql`
  query GetTypeaheadResultUrl(
    $appName: ApplicationName = EEA
    $filters: [String]
    $solrHandler: String
    $userId: String
    $userType: String
  ) {
    getTypeaheadResultUrl(
      applicationName: $appName
      filters: $filters
      solrHandler: $solrHandler
      userId: $userId
      userType: $userType
    )
  }
`;
export function useGetTypeaheadResultUrlQuery(
  baseOptions?: Apollo.QueryHookOptions<IGetTypeaheadResultUrlQuery, IGetTypeaheadResultUrlQueryVariables>
) {
  return Apollo.useQuery<IGetTypeaheadResultUrlQuery, IGetTypeaheadResultUrlQueryVariables>(
    GetTypeaheadResultUrlDocument,
    baseOptions
  );
}
export function useGetTypeaheadResultUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetTypeaheadResultUrlQuery, IGetTypeaheadResultUrlQueryVariables>
) {
  return Apollo.useLazyQuery<IGetTypeaheadResultUrlQuery, IGetTypeaheadResultUrlQueryVariables>(
    GetTypeaheadResultUrlDocument,
    baseOptions
  );
}
export type GetTypeaheadResultUrlQueryHookResult = ReturnType<typeof useGetTypeaheadResultUrlQuery>;
export type GetTypeaheadResultUrlLazyQueryHookResult = ReturnType<typeof useGetTypeaheadResultUrlLazyQuery>;
export type GetTypeaheadResultUrlQueryResult = Apollo.QueryResult<
  IGetTypeaheadResultUrlQuery,
  IGetTypeaheadResultUrlQueryVariables
>;
export const GetSearchCountDocument = gql`
  query GetSearchCount(
    $applicationName: ApplicationName
    $query: String!
    $filters: [String]
    $solrHandler: String
    $sourceType: [String]
    $userType: String
  ) {
    getSearchCount(
      applicationName: $applicationName
      query: $query
      filters: $filters
      solrHandler: $solrHandler
      sourceType: $sourceType
      userType: $userType
    )
  }
`;
export function useGetSearchCountQuery(
  baseOptions: Apollo.QueryHookOptions<IGetSearchCountQuery, IGetSearchCountQueryVariables>
) {
  return Apollo.useQuery<IGetSearchCountQuery, IGetSearchCountQueryVariables>(GetSearchCountDocument, baseOptions);
}
export function useGetSearchCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetSearchCountQuery, IGetSearchCountQueryVariables>
) {
  return Apollo.useLazyQuery<IGetSearchCountQuery, IGetSearchCountQueryVariables>(GetSearchCountDocument, baseOptions);
}
export type GetSearchCountQueryHookResult = ReturnType<typeof useGetSearchCountQuery>;
export type GetSearchCountLazyQueryHookResult = ReturnType<typeof useGetSearchCountLazyQuery>;
export type GetSearchCountQueryResult = Apollo.QueryResult<IGetSearchCountQuery, IGetSearchCountQueryVariables>;
export const GetTopLinksDocument = gql`
  query GetTopLinks(
    $applicationName: ApplicationName = EEA
    $query: String!
    $count: Int
    $solrHandler: String
    $cosine: Boolean
  ) {
    getTopLinks(
      applicationName: $applicationName
      query: $query
      count: $count
      solrHandler: $solrHandler
      cosine: $cosine
    ) {
      resultCount
      QTime
      bertScore
      isResponseFromKeyword
      docs {
        ...DataAssetForSearchObjects
        __typename
      }
      __typename
    }
  }
  ${DataAssetForSearchObjectsFragmentDoc}
`;
export function useGetTopLinksQuery(
  baseOptions: Apollo.QueryHookOptions<IGetTopLinksQuery, IGetTopLinksQueryVariables>
) {
  return Apollo.useQuery<IGetTopLinksQuery, IGetTopLinksQueryVariables>(GetTopLinksDocument, baseOptions);
}
export function useGetTopLinksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetTopLinksQuery, IGetTopLinksQueryVariables>
) {
  return Apollo.useLazyQuery<IGetTopLinksQuery, IGetTopLinksQueryVariables>(GetTopLinksDocument, baseOptions);
}
export type GetTopLinksQueryHookResult = ReturnType<typeof useGetTopLinksQuery>;
export type GetTopLinksLazyQueryHookResult = ReturnType<typeof useGetTopLinksLazyQuery>;
export type GetTopLinksQueryResult = Apollo.QueryResult<IGetTopLinksQuery, IGetTopLinksQueryVariables>;

export const DataAssetForConsolidatedAPIFragmentDoc = gql`
  fragment UnifiedDataAsset on DataAsset {
    content
    dataType
    doc_last_modified
    file_size
    id
    parent_url
    short_content @skip(if: $noShortContent)
    site_name
    spotlight_view_url
    thumbnail_large
    title
    url
    parent_object_id
    category
    is_deeplink
    isResponseFromKeyword
    toplinks {
      title
      url
      id
      content
      __typename
    }
    description
    preview_images
    start_date
    score
    __typename
  }
`;

export const GetConsolidatedAPIData = gql`
  query getAllLinks(
    $applicationName: ApplicationName
    $query: String!
    $filters: [String]
    $count: Int = 5
    $start: Int = 0
    $page: Int
    $bertCount: Int
    $keywordCount: Int
    $sort: SortField
    $order: Direction
    $cosine: Boolean = true
    $addPersonalizationDetails: Boolean
    $noShortContent: Boolean = false
    $noSpotlight: Boolean = true
    $userCode: String
  ) {
    getAllLinks(
      applicationName: $applicationName
      query: $query
      filters: $filters
      count: $count
      start: $start
      page: $page
      bertCount: $bertCount
      keywordCount: $keywordCount
      sort: $sort
      order: $order
      cosine: $cosine
      addPersonalizationDetails: $addPersonalizationDetails
      noShortContent: $noShortContent
      noSpotlight: $noSpotlight
      userCode: $userCode
    ) {
      qTime
      resultCount
      docsData {
        ...UnifiedDataAsset
        __typename
      }
      __typename
    }
  }
  ${DataAssetForConsolidatedAPIFragmentDoc}
`;
export function useGetConsolidatedAPIQuery(
  baseOptions: Apollo.QueryHookOptions<IGetConsolidatedAPIQuery, IGetConsolidatedAPIQueryVariables>
) {
  return Apollo.useQuery<IGetConsolidatedAPIQuery, IGetConsolidatedAPIQueryVariables>(
    GetConsolidatedAPIData,
    baseOptions
  );
}
export function useGetConsolidatedAPILazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetConsolidatedAPIQuery, IGetConsolidatedAPIQueryVariables>
) {
  return Apollo.useLazyQuery<IGetConsolidatedAPIQuery, IGetConsolidatedAPIQueryVariables>(
    GetConsolidatedAPIData,
    baseOptions
  );
}
export type GetConsolidatedAPIQueryHookResult = ReturnType<typeof useGetConsolidatedAPIQuery>;
export type GetConsolidatedAPILazyQueryHookResult = ReturnType<typeof useGetConsolidatedAPILazyQuery>;
export type GetConsolidatedAPIQueryResult = Apollo.QueryResult<
  IGetConsolidatedAPIQuery,
  IGetConsolidatedAPIQueryVariables
>;
