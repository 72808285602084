import { AppContext } from '@amgen/shared';
import React, { useContext } from 'react';
import { Badge } from 'react-bootstrap';

import './search-box-filter-pill.scss';

import { SearchBoxContext } from '../../search-box-context-provider/search-box-context';

/* eslint-disable-next-line */
export interface SearchBoxFilterPillProps {}

export const SearchBoxFilterPill: React.FC<SearchBoxFilterPillProps> = () => {
  const { attributeDisplayNames } = useContext(AppContext);
  const { selectedFilter, showAdvanceSearch } = useContext(SearchBoxContext);

  return selectedFilter ? (
    <Badge
      className="rounded-pill my-auto"
      style={{
        backgroundColor: '#e0e0e0',
        color: '#797979',
        fontSize: '14px',
        right: showAdvanceSearch ? '2rem' : '0',
        top: 'calc(50% - .5rem)',
      }}
    >
      {attributeDisplayNames[selectedFilter]}:
    </Badge>
  ) : null;
};
export default SearchBoxFilterPill;
