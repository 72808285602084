import { IAssetAttribute } from '@amgen/core';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import './facets-panel-provider.scss';

import { FiltersObject } from '../../models';
import { FacetContext } from '../facet-context-provider/facet-context';
import { FacetsPanelContext, IFacetsPanelState } from './facets-panel-context';

export interface FacetsPanelProviderProps {
  isNotConfigurable?: boolean;
}

export const FacetsPanelProvider: React.FC<FacetsPanelProviderProps> = ({ children, ...props }) => {
  const { appliedFilters, appliedFilterCategories } = useContext(FacetContext);
  const [selectedFilters, setSelectedFilters] = useState(new FiltersObject());
  const [selectedFilterCategories, setSelectedFilterCategories] = useState(appliedFilterCategories);
  const [currentSelectedKeys, setCurrentSelectedKeys] = useState<Set<IAssetAttribute>>(new Set());
  const [isChatbotInvoke, setIsChatbotInvoke] = useState(false);
  const [isExpandButtonClicked, setIsExpandButtonClicked] = useState(false);

  useEffect(() => {
    const copy = new Set(appliedFilterCategories);
    setSelectedFilterCategories(copy);
  }, [appliedFilterCategories]);

  useEffect(() => {
    const copy = appliedFilters.clone();
    setSelectedFilters(copy);
  }, [appliedFilters]);

  const providedValue = useMemo<IFacetsPanelState>(
    () => ({
      selectedFilterCategories,
      setSelectedFilterCategories,
      selectedFilters,
      setSelectedFilters,
      isNotConfigurable: props.isNotConfigurable,
      currentSelectedKeys,
      setCurrentSelectedKeys,
      isChatbotInvoke,
      setIsChatbotInvoke,
      isExpandButtonClicked,
      setIsExpandButtonClicked,
    }),
    [
      selectedFilterCategories,
      selectedFilters,
      props.isNotConfigurable,
      currentSelectedKeys,
      isChatbotInvoke,
      isExpandButtonClicked,
    ]
  );

  return <FacetsPanelContext.Provider value={providedValue}>{children}</FacetsPanelContext.Provider>;
};

export default FacetsPanelProvider;
