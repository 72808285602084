import React from 'react';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import './infinite-loader-list.scss';

import FacetValue from '../facet-value/facet-value';

export interface InfiniteLoaderListProps {
  hasNextPage: boolean;
  isNextPageLoading: boolean;
  items: any[];
  loadNextPage: (startIndex: any, stopIndex: any) => Promise<void> | null;
  categoryItems: any;
}

export const InfiniteLoaderList: React.FC<InfiniteLoaderListProps> = props => {
  const ITEM_HEIGHT = 32;

  // If there are more items to be loaded then add an extra row to hold a loading indicator.
  const itemCount = props.hasNextPage ? props.items.length + 1 : props.items.length;
  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreItems = props.loadNextPage;

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = (index: number) => {
    return !props.hasNextPage || index < props.items.length;
  };

  return (
    <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={itemCount} loadMoreItems={loadMoreItems}>
      {({ onItemsRendered, ref }) => (
        <FixedSizeList
          className="thin-scrollbar"
          height={Math.min(props.items.length, 9) * ITEM_HEIGHT}
          itemCount={props.items.length}
          itemSize={ITEM_HEIGHT}
          width="100%"
          onItemsRendered={onItemsRendered}
          ref={ref}
        >
          {({ index, style }) =>
            !isItemLoaded(index) ? (
              <div style={style}>loading...</div>
            ) : (
              <div style={style}>
                <FacetValue
                  key={`${props.items[index].value}_${index}`}
                  facet={props.items[index]}
                  isSelected={props.categoryItems.has(props.items[index].value!)}
                />
              </div>
            )
          }
        </FixedSizeList>
      )}
    </InfiniteLoader>
  );
};

export default InfiniteLoaderList;
