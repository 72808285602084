import { useLazySearchPromiseQuery } from '@amgen/api';
import { DEFAULT_SEARCH_QUERY, Direction, IBookmark, SortField, useEnvironment } from '@amgen/core';
import { useAssetTitleUrl } from '@amgen/feature-search';
import { AssetContext, DOCUMENT_INTERACTIONS_ACTIONS, MatomoContext, SEARCH_APP_FEATURE_LIST } from '@amgen/shared';
import { SOLR_REFRESH_TIME } from '@amgen/utils';
import React, { useContext, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import ReactDOM from 'react-dom';

import './bookmark-toggle-button.scss';

import { BookmarkContext } from '../../context';
import BookmarkAddButton from '../bookmark-add-button/bookmark-add-button';
import BookmarkRemoveButton from '../bookmark-remove-button/bookmark-remove-button';

export interface BookmarkToggleButtonProps {
  isDisabled?: boolean;
  isCustomBookmark?: boolean;
  isAssetDetail?: boolean;
  buttonText?: string;
}

export const BookmarkToggleButton = (props: BookmarkToggleButtonProps) => {
  const { deleted, removeFromDeleted, addToDeleted, current, addToCurrent, removeFromCurrent } = useContext(
    BookmarkContext
  );
  const { docSolrAsset, isBookmarksDisabled } = useContext(AssetContext);
  const { trackApplicationFeature, trackCustomDimensions, dimensions } = useContext(MatomoContext);
  const { title: assetTitle } = useAssetTitleUrl(docSolrAsset.dataAsset!);
  const { appName } = useEnvironment();

  const bookmark: IBookmark = {
    applicationName: appName,
    name: assetTitle,
    assetId: docSolrAsset.dataAsset?.id,
    id: docSolrAsset.personalizationDetails?.bookmarkDetails?.bookmarkId,
  };

  const [isBookmark, setIsBookmark] = useState(!!bookmark.id || current.has(bookmark.assetId ?? ''));
  const [bookmarkId, setBookmarkId] = useState(bookmark.id);
  // to compensate for inbetween load experience
  const [isLoading, setIsLoading] = useState(false);

  const refetch = useLazySearchPromiseQuery({
    variables: {
      query: DEFAULT_SEARCH_QUERY,
      filters: [`id : "${bookmark.assetId || ''}"`],
      page: 0,
      count: 1,
      sort: SortField.MODIFIED_DATE,
      order: Direction.ASC,
      includeDocs: true,
      noShortContent: false,
      addPersonalizationDetails: true,
    },
  });

  const toggleBookmarks = (isAddBookmark: boolean) => {
    setIsLoading(true);
    let bookmarkedInSolr = isBookmark;
    setTimeout(() => {
      refetch().then(({ data }) => {
        setBookmarkId(data?.search?.docs?.[0]?.personalizationDetails?.bookmarkDetails?.bookmarkId);
        bookmarkedInSolr = data?.search?.docs?.[0]?.personalizationDetails?.bookmarkDetails?.isBookmarked ?? false;
        setIsBookmark(bookmarkedInSolr);
        //[todo] taking bookmarked or not from the  opposite toggled state rather than solr value
        ReactDOM.unstable_batchedUpdates(() => {
          if (isAddBookmark) {
            addToCurrent(bookmark.assetId!);
            removeFromDeleted(bookmark.assetId!);
          } else {
            removeFromCurrent(bookmark.assetId!);
            addToDeleted(bookmark.assetId!);
          }
        });
        setIsLoading(false);
        if (isAddBookmark) {
          trackApplicationFeature(
            SEARCH_APP_FEATURE_LIST.DOCUMENT_INTERACTIONS,
            DOCUMENT_INTERACTIONS_ACTIONS.BOOKMARK
          );
        }
      });
    }, SOLR_REFRESH_TIME);
  };

  return isLoading ? (
    <Spinner animation="border" size="sm" />
  ) : !isBookmarksDisabled && isBookmark && !deleted.has(bookmark.assetId!) ? (
    <BookmarkRemoveButton
      bookmark={{ ...bookmark, id: bookmarkId ?? undefined }}
      onSuccess={() => toggleBookmarks(false)}
      isDisabled={isBookmarksDisabled}
      isCustomBookmark={props.isCustomBookmark}
      isBookmarked={isBookmark}
      isAssetDetail={props.isAssetDetail}
      buttonText={props.buttonText}
    />
  ) : (
    <BookmarkAddButton
      bookmark={bookmark}
      onSuccess={() => {
        toggleBookmarks(true);
        trackCustomDimensions(bookmark.name!, dimensions.BOOKMARK);
      }}
      isDisabled={isBookmarksDisabled}
      isCustomBookmark={props.isCustomBookmark}
      isAssetDetail={props.isAssetDetail}
      buttonText={props.buttonText}
    />
  );
};

export default BookmarkToggleButton;
