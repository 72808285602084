import { ClinicalContext } from '@amgen/shared';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router';

import './clinical-details-back-btn.scss';

/* eslint-disable-next-line */
export interface ClinicalDetailsBackBtnProps {}

export function ClinicalDetailsBackBtn(props: ClinicalDetailsBackBtnProps) {
  const { go } = useHistory();
  const { historyCount } = useContext(ClinicalContext);

  return (
    <Button
      variant="link"
      className="px-0 ml-3 h2 position-absolute"
      style={{ left: 0, fontSize: '14px', color: 'black' }}
      onClick={() => go(-historyCount - 1)}
    >
      <FontAwesomeIcon icon={faChevronLeft} />
      <span className="pl-2 d-none fs-13 d-sm-inline">Back to Search Result(s)</span>
    </Button>
  );
}

export default ClinicalDetailsBackBtn;
