import { IAssetAttribute } from '@amgen/core';
import React from 'react';

import './date-filter-items.scss';

import DateFilter from '../date-filter/date-filter';
import DateFilterProvider from '../date-filter/date-filter-provider/date-filter-provider';
import DateSlider from '../date-slider/date-slider';

export interface DateFilterItemsProps {
  category: IAssetAttribute;
}

export const DateFilterItems: React.FC<DateFilterItemsProps> = ({ category }) => {
  return (
    <DateFilterProvider category={category}>
      <DateFilter />
      <DateSlider />
    </DateFilterProvider>
  );
};

export default DateFilterItems;
