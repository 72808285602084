import { IAssetAttributeTypeProps } from '@amgen/shared';
import React from 'react';

import './text-attribute.scss';

export const TextAttribute: React.FC<IAssetAttributeTypeProps> = props => {
  const value = props.value?.toString() ?? '';

  return (
    <span className="p-0 w-100 second-line-text-overflow-ellipsis" style={{ wordBreak: 'break-word' }}>
      {value}
    </span>
  );
};

export default TextAttribute;
