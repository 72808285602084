import * as Types from '@amgen/core';

import { IGenericResponseFragment } from './generic-response';
import { gql } from '@apollo/client';
import { GenericResponseFragmentDoc } from './generic-response';
import * as Apollo from '@apollo/client';
export type IDestinationsDataFragment = { __typename: 'DestinationsData' } & Pick<
  Types.IDestinationsData,
  | 'id'
  | 'title'
  | 'content'
  | 'createdOn'
  | 'createdBy'
  | 'url'
  | 'requesterId'
  | 'thumbnailLarge'
  | 'applicationType'
  | 'ownwerId'
  | 'users_and_groups_allowed'
  | 'contentSource'
  | 'objectName'
  | 'previewImages'
  | 'docLastModified'
  | 'lastModifiedBy'
  | 'tags'
>;

export type IAddDestinationsMutationVariables = Types.Exact<{
  appName: Types.ApplicationName;
  destinationsData?: Types.Maybe<Types.IDestinationsDataInput>;
}>;

export type IAddDestinationsMutation = { addDestinations?: Types.Maybe<Pick<Types.ICrawlerDestination, 'id'>> };

export type IGetDestinationsQueryVariables = Types.Exact<{
  appName?: Types.Maybe<Types.ApplicationName>;
  query?: Types.Maybe<Types.Scalars['String']>;
  filters?: Types.Maybe<Array<Types.Maybe<Types.Scalars['String']>> | Types.Maybe<Types.Scalars['String']>>;
  start?: Types.Maybe<Types.Scalars['Int']>;
  page?: Types.Maybe<Types.Scalars['Int']>;
  count?: Types.Maybe<Types.Scalars['Int']>;
  sortOrder?: Types.Maybe<Array<Types.Maybe<Types.ISortOrder>> | Types.Maybe<Types.ISortOrder>>;
  solrHandler?: Types.Maybe<Types.Scalars['String']>;
}>;

export type IGetDestinationsQuery = {
  getDestinations?: Types.Maybe<
    { __typename: 'DestinationsRequestCollection' } & Pick<
      Types.IDestinationsRequestCollection,
      'resultCount' | 'QTime'
    > & { requests?: Types.Maybe<Array<Types.Maybe<{ __typename: 'DestinationsData' } & IDestinationsDataFragment>>> }
  >;
};

export type IDeleteDestinationMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type IDeleteDestinationMutation = {
  deleteDestination?: Types.Maybe<{ __typename: 'GenericResponse' } & IGenericResponseFragment>;
};

export const DestinationsDataFragmentDoc = gql`
  fragment DestinationsData on DestinationsData {
    id
    title
    content
    createdOn
    createdBy
    url
    requesterId
    thumbnailLarge
    applicationType
    ownwerId
    users_and_groups_allowed
    contentSource
    objectName
    previewImages
    docLastModified
    lastModifiedBy
    tags
    __typename
  }
`;
export const AddDestinationsDocument = gql`
  mutation AddDestinations($appName: ApplicationName!, $destinationsData: DestinationsDataInput) {
    addDestinations(applicationName: $appName, destinationsData: $destinationsData) {
      id
    }
  }
`;
export type IAddDestinationsMutationFn = Apollo.MutationFunction<
  IAddDestinationsMutation,
  IAddDestinationsMutationVariables
>;
export function useAddDestinationsMutation(
  baseOptions?: Apollo.MutationHookOptions<IAddDestinationsMutation, IAddDestinationsMutationVariables>
) {
  return Apollo.useMutation<IAddDestinationsMutation, IAddDestinationsMutationVariables>(
    AddDestinationsDocument,
    baseOptions
  );
}
export type AddDestinationsMutationHookResult = ReturnType<typeof useAddDestinationsMutation>;
export type AddDestinationsMutationResult = Apollo.MutationResult<IAddDestinationsMutation>;
export type AddDestinationsMutationOptions = Apollo.BaseMutationOptions<
  IAddDestinationsMutation,
  IAddDestinationsMutationVariables
>;
export const GetDestinationsDocument = gql`
  query GetDestinations(
    $appName: ApplicationName
    $query: String
    $filters: [String]
    $start: Int = 0
    $page: Int
    $count: Int
    $sortOrder: [SortOrder]
    $solrHandler: String = "/search-select"
  ) {
    getDestinations(
      applicationName: $appName
      query: $query
      filters: $filters
      start: $start
      page: $page
      count: $count
      sortOrder: $sortOrder
      solrHandler: $solrHandler
    ) {
      resultCount
      QTime
      requests {
        ...DestinationsData
        __typename
      }
      __typename
    }
  }
  ${DestinationsDataFragmentDoc}
`;
export function useGetDestinationsQuery(
  baseOptions?: Apollo.QueryHookOptions<IGetDestinationsQuery, IGetDestinationsQueryVariables>
) {
  return Apollo.useQuery<IGetDestinationsQuery, IGetDestinationsQueryVariables>(GetDestinationsDocument, baseOptions);
}
export function useGetDestinationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetDestinationsQuery, IGetDestinationsQueryVariables>
) {
  return Apollo.useLazyQuery<IGetDestinationsQuery, IGetDestinationsQueryVariables>(
    GetDestinationsDocument,
    baseOptions
  );
}
export type GetDestinationsQueryHookResult = ReturnType<typeof useGetDestinationsQuery>;
export type GetDestinationsLazyQueryHookResult = ReturnType<typeof useGetDestinationsLazyQuery>;
export type GetDestinationsQueryResult = Apollo.QueryResult<IGetDestinationsQuery, IGetDestinationsQueryVariables>;
export const DeleteDestinationDocument = gql`
  mutation deleteDestination($id: String!) {
    deleteDestination(id: $id) {
      ...GenericResponse
      __typename
    }
  }
  ${GenericResponseFragmentDoc}
`;
export type IDeleteDestinationMutationFn = Apollo.MutationFunction<
  IDeleteDestinationMutation,
  IDeleteDestinationMutationVariables
>;
export function useDeleteDestinationMutation(
  baseOptions?: Apollo.MutationHookOptions<IDeleteDestinationMutation, IDeleteDestinationMutationVariables>
) {
  return Apollo.useMutation<IDeleteDestinationMutation, IDeleteDestinationMutationVariables>(
    DeleteDestinationDocument,
    baseOptions
  );
}
export type DeleteDestinationMutationHookResult = ReturnType<typeof useDeleteDestinationMutation>;
export type DeleteDestinationMutationResult = Apollo.MutationResult<IDeleteDestinationMutation>;
export type DeleteDestinationMutationOptions = Apollo.BaseMutationOptions<
  IDeleteDestinationMutation,
  IDeleteDestinationMutationVariables
>;
