import { faInfoCircle } from '@amgen/assets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import './info-tooltip.scss';

export interface InfoTooltipProps {
  className?: string;
  title?: string;
  trigger?: 'hover' | 'click' | 'focus';
  style?: React.CSSProperties;
}

export const InfoTooltip: React.FC<InfoTooltipProps> = ({ children, className, title, trigger, style }) => {
  return (
    <OverlayTrigger
      trigger={trigger || 'hover'}
      placement="right"
      overlay={
        <Popover id="popover-basic">
          {title && <Popover.Title as="h3">Popover right</Popover.Title>}
          <Popover.Content>{children}</Popover.Content>
        </Popover>
      }
    >
      <FontAwesomeIcon className={className} icon={faInfoCircle} color="info" style={style} />
    </OverlayTrigger>
  );
};

export default InfoTooltip;
