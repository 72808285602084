import { createContext } from 'react';

export type IDocInteractionFunction = (id: string) => void;

export interface IDocInteractionState {
  onDocTitleClick: IDocInteractionFunction;
  onDocReviewRate: IDocInteractionFunction;
  onDocHover: IDocInteractionFunction;
  onDocViewDetails: IDocInteractionFunction;
}

export const DocInteractionContext = createContext<IDocInteractionState>(null!);
