import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import './hamburger-menu-item-child.module.scss';

import { HamburgerMenuContext } from '../hamburger-menu-context';

export interface HamburgerMenuItemChildProps {
  to?: string;
  icon?: IconDefinition;
  onClick?: () => void;
  hasDropdown?: boolean;
  content: string | React.ReactNode;
}

export function HamburgerMenuItemChild(props: HamburgerMenuItemChildProps) {
  const { setShow } = useContext(HamburgerMenuContext);

  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
    }
    setShow(false);
  };

  return props.to ? (
    <NavLink exact to={props.to} className={classnames('d-flex no-gutters mt-3 text-light')} onClick={handleClick}>
      <Col xs="2">{props.icon && <FontAwesomeIcon icon={props.icon} className="fa-fw mr-3" />}</Col>
      <Col xs="10">{props.content}</Col>
    </NavLink>
  ) : (
    <Row noGutters className="empty-route-hamburger-menu-item cursor-pointer mt-3" onClick={handleClick}>
      <Col xs="2">{props.icon && <FontAwesomeIcon icon={props.icon} className="fa-fw mr-3" />}</Col>
      <Col xs="10">{props.content}</Col>
    </Row>
  );
}

export default HamburgerMenuItemChild;
