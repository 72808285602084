import { usePivotFacetLazyQuery } from '@amgen/api';
import { IAssetAttribute, IFacetFieldParametersInput, IPivotDetail, useEnvironment } from '@amgen/core';
import { useSearchQueryValue } from '@amgen/shared';
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import React, { useState } from 'react';
import { Badge, Dropdown, FormCheck, Spinner } from 'react-bootstrap';

import './pivot-facet.scss';

import { useAppliedFiltersWithPersistentValues } from '../../../persistent-filter-values';
import { convertFacetToTree, ITreeFacet, returnSubFieldValues } from '../pivot-tree';

interface IPivotFacetFieldProps {
  key: string;
  categoryItems: Set<string>;
  categoryTree: IAssetAttribute[];
  className?: string;
  eventKey?: string;
  excludeCount?: boolean;
  field: ITreeFacet;
  index: number;
  isSelected: boolean;
  itemHeight: number;
  level: number;
  facetFieldParams: IFacetFieldParametersInput;
  onScrollBarToggle: (state: boolean) => void;
}

const PivotFacetField: React.FC<IPivotFacetFieldProps> = props => {
  const [facetTreeValues, setFacetTreeValues] = useState<ITreeFacet[]>([]);
  const { appName, requestHandler } = useEnvironment();

  const [openChild, setOpenChild] = useState(false);
  const query = useSearchQueryValue();
  const filters = useAppliedFiltersWithPersistentValues();
  const [page, setPage] = useState(0);
  const [hasNext, setHasNext] = useState(true);

  // TODO make it props.level driven
  const recursivePivotField: IPivotDetail =
    props.level === 0
      ? {
          name: props.facetFieldParams.pivot!.name,
          facetLimit: 1,
          page: props.index,
          minCount: 0,
          pivotDetail: {
            name: props.categoryTree[props.level + 1],
            facetLimit: 6,
            page,
            minCount: 0,
          },
        }
      : {
          name: props.facetFieldParams.pivot!.name,
          facetLimit: 1,
          page: props.facetFieldParams.pivot!.page,
          minCount: 0,
          pivotDetail: {
            name: props.facetFieldParams.pivot!.pivotDetail!.name,
            facetLimit: 1,
            page: props.index,
            minCount: 0,
            pivotDetail: {
              name: props.categoryTree[props.level + 1],
              facetLimit: 6,
              page,
              minCount: 0,
            },
          },
        };

  const [getFacetField, { loading }] = usePivotFacetLazyQuery({
    variables: {
      query,
      filters,
      fields: [
        {
          pivot: recursivePivotField,
        },
      ],
      excludeCount: !!props.excludeCount,
      appName,
    },
    fetchPolicy: 'network-only',
    onCompleted: data => {
      const responseChildren = data?.facet?.[0]?.values
        ? returnSubFieldValues(data?.facet?.[0]?.values, props.level)
        : [];
      if (responseChildren) {
        const cSub = convertFacetToTree(responseChildren, props.field);
        const children = page ? [...facetTreeValues, ...cSub] : cSub;

        if (responseChildren.length === 0) {
          setHasNext(false);
        } else {
          setFacetTreeValues(children);
        }
      } else {
        setHasNext(false);
      }
    },
  });

  return (
    <>
      <span
        className="d-flex justify-content-between align-items-center mx-2"
        onMouseEnter={() => props.onScrollBarToggle(true)}
        onMouseLeave={() => props.onScrollBarToggle(false)}
      >
        {props.level < props.categoryTree.length - 1 ? (
          <FontAwesomeIcon
            icon={openChild ? faCaretDown : faCaretRight}
            className="filter-caret text-grey mr-1"
            onClick={() => {
              setOpenChild(!openChild);
              if (!openChild) {
                getFacetField();
              } else {
                setHasNext(true);
              }
            }}
          />
        ) : null}
        <Dropdown.Item
          className={`pl-1 d-flex justify-content-between align-items-center ${props.className}`}
          eventKey={props.eventKey ?? props.field.path}
          title={props.field.value}
        >
          <FormCheck className="w-75">
            <FormCheck.Input type="checkbox" checked={props.isSelected} onChange={() => {}} />
            <FormCheck.Label className="d-block mr-3 w-100 text-overflow-ellipsis">{props.field.value}</FormCheck.Label>
          </FormCheck>

          <Badge className="rounded-pill" style={{ backgroundColor: '#e0e0e0', color: '#797979' }}>
            {new Intl.NumberFormat('en-US').format(props.field.count ?? 0)}
          </Badge>
        </Dropdown.Item>
      </span>

      <div className="d-flex ml-4">
        {openChild &&
          (facetTreeValues ? (
            <PivotFieldList
              hasNextPage={hasNext}
              items={facetTreeValues}
              isNextPageLoading={loading}
              onLoadNextPage={() => setPage(page + 1)}
              level={props.level + 1}
              pivotDetail={{ pivot: recursivePivotField }}
              categoryItems={props.categoryItems}
              categoryTree={props.categoryTree}
              itemHeight={props.itemHeight}
            />
          ) : null)}
      </div>
    </>
  );
};

export interface PivotFieldListProps {
  categoryItems: Set<string>;
  categoryTree: IAssetAttribute[];
  hasNextPage: boolean;
  isNextPageLoading: boolean;
  itemHeight: number;
  items: ITreeFacet[];
  level: number;
  onLoadNextPage: () => void;
  pivotDetail: IFacetFieldParametersInput;
}

export const PivotFieldList: React.FC<PivotFieldListProps> = props => {
  const [isScrollBarVisible, setScrollBarVisible] = useState(true);
  const handleScroll = ({ currentTarget }: any) => {
    if (
      currentTarget &&
      currentTarget.scrollTop + currentTarget.clientHeight >= currentTarget.scrollHeight - 2 * props.itemHeight &&
      !props.isNextPageLoading &&
      props.hasNextPage
    ) {
      props.onLoadNextPage();
    }
  };

  const loadingListItem = () => {
    return (
      <div className="text-center text-primary w-100" style={{ height: props.itemHeight }}>
        <Spinner animation="border" size="sm" />
      </div>
    );
  };

  return (
    <div
      className={classnames('thin-scrollbar w-100 pivot-scroll px-2', { 'overflow-auto': isScrollBarVisible })}
      style={{ maxHeight: 320 / (props.level + 1) }}
      onScroll={handleScroll}
    >
      {props.items.map((item, index) => (
        <PivotFacetField
          key={`${item.value}+${index}`}
          isSelected={props.categoryItems.has(item.value!)}
          field={item}
          level={props.level}
          index={index}
          facetFieldParams={props.pivotDetail}
          categoryItems={props.categoryItems}
          categoryTree={props.categoryTree}
          itemHeight={props.itemHeight}
          onScrollBarToggle={state => setScrollBarVisible(state)}
        />
      ))}
      {props.isNextPageLoading && loadingListItem()}
    </div>
  );
};

export default PivotFieldList;
