import { CircularBar } from '@amgen/components';
import { IDataAsset, IFacetValue } from '@amgen/core';
import { useWindowSizeListener } from '@amgen/shared';
import { ApolloError } from '@apollo/client';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as faStarSolid, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { Alert, Col, ProgressBar, Row, Spinner } from 'react-bootstrap';
import Rating from 'react-rating';

import './doc-feedback-metrics.scss';

import { useAssetTitleUrl } from '../../hooks';

/* eslint-disable-next-line */
export interface DocFeedbackMetricsProps {
  loading: boolean;
  avgRating: number;
  totalReviewCount: number;
  inPopover?: boolean;
  asset: IDataAsset;
  feedbackMetrics: IFacetValue[];
  error?: ApolloError;
}

export const DocFeedbackMetrics = (props: DocFeedbackMetricsProps) => {
  const { title, detailUrl } = useAssetTitleUrl(props.asset);
  const getPercentage = (count?: number) => {
    const percentage = ((count ?? 0) * 100) / props.totalReviewCount;
    return isNaN(percentage) ? 0 : Number(percentage.toFixed(2));
  };
  const avgRating = isNaN(props.avgRating) ? 0 : props.avgRating;
  const progressVariants = ['success', 'secondary', 'info', 'warning', 'danger'];

  return (
    <>
      <h2 className="font-weight-bold">Review Summary</h2>
      <div className="text-center">
        {props.loading && <Spinner animation="border" className="p-4 m-4" />}
        {props.error && (
          <Alert variant="secondary" className="d-flex align-items-center py-2">
            <span>Something went wrong. Please try again later.</span>
          </Alert>
        )}
        {!props.loading && !props.error && (
          <Row className="pt-2">
            <MetricAvgRating {...props} avgRating={avgRating} />
            <Col xs={12} lg={8}>
              {props.feedbackMetrics
                .slice()
                .sort((n1, n2) => Number(n2.value) - Number(n1.value))
                .map((rating, i) => (
                  <Row key={i} className="py-2 my-1">
                    <Col xs="1" className="center-label d-flex">
                      <span className="mx-1">{rating.value}</span>
                      <FontAwesomeIcon icon={faStarSolid} className="text-dark-gold pr-2" />
                    </Col>
                    <Col className="mx-1">
                      <ProgressBar
                        className="rounded-pill"
                        now={getPercentage(rating.count)}
                        label={
                          <span>
                            <FontAwesomeIcon icon={faUser} size="sm" className="pr-1" /> {rating.count}
                          </span>
                        }
                        variant={progressVariants[i]}
                        title={`${rating.count} Review(s)`}
                      />
                    </Col>
                  </Row>
                ))}
            </Col>

            {/* {props.inPopover ? (
              <Col xs={12} className="d-flex justify-content-end">
                {detailUrl ? (
                  <Link to={detailUrl} title={title}>
                    See all Reviews
                  </Link>
                ) : null}
              </Col>
            ) : null} */}
          </Row>
        )}
      </div>
    </>
  );
};

export default DocFeedbackMetrics;
export interface MetricAvgRatingProps {
  avgRating: number;
  totalReviewCount: number;
  inPopover?: boolean;
}
export const MetricAvgRating: React.FC<MetricAvgRatingProps> = ({ avgRating, inPopover, totalReviewCount }) => {
  const isMobile = useWindowSizeListener();
  return (
    <Col
      xs={12}
      lg={4}
      className={classNames('text-primary text-center my-auto p-0', {
        'pl-4': !inPopover,
      })}
    >
      <CircularBar
        strokeWidth={inPopover || isMobile ? 5 : 10}
        sqSize={inPopover || isMobile ? 115 : 200}
        percentage={(avgRating * 100) / 5}
      >
        <div className="p-2">
          <h1
            className={classNames('text-primary font-bold', {
              'mb-0': (isMobile && !inPopover) || inPopover,
              'display-3 mt-2': !inPopover && !isMobile,
            })}
            title={`Average Rating (${Math.round(avgRating * 100) / 100}/5)`}
          >
            {Math.round(avgRating * 100) / 100}
          </h1>
          <Rating
            initialRating={avgRating}
            fullSymbol={
              <FontAwesomeIcon
                icon={faStarSolid}
                className={classNames('text-dark-gold', { 'mb-2': !inPopover && !isMobile })}
                size={inPopover ? 'xs' : 'sm'}
              />
            }
            emptySymbol={
              <FontAwesomeIcon
                icon={faStar}
                className={classNames('text-dark-gold', { 'mb-2': !inPopover && !isMobile })}
                size={inPopover ? 'xs' : 'sm'}
              />
            }
            readonly
          />

          <p
            className={classNames('text-primary', { ' fs-24': !inPopover && !isMobile })}
            title={`${totalReviewCount} review(s)`}
          >
            <FontAwesomeIcon icon={faUser} size="sm" className="pr-1" />
            {totalReviewCount} total
          </p>
        </div>
      </CircularBar>
    </Col>
  );
};
