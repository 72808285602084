import { IToast } from '@amgen/shared-kmi';
import React, { createContext, useCallback, useMemo, useState } from 'react';

import { ToastContainer } from './toast-container';

interface IToastContext {
  addToast(toast: IToast): void;
  onToastClose(toast: IToast): void;
  removeAll: () => void;
}

export const ToastContext = createContext<IToastContext>(null!);

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IToastProviderProps {}

export const ToastProvider: React.FC<IToastProviderProps> = ({ children }) => {
  const [toasts, setToasts] = useState<Map<string, IToast>>(new Map());

  const onToastClose = useCallback(
    (toast: IToast) => {
      const copiedToast = new Map(toasts);
      if (toast.id && copiedToast.has(toast.id)) {
        copiedToast.delete(toast.id);
        setToasts(copiedToast);
      }
    },
    [toasts]
  );

  const removeWithTimer = useCallback(
    (toast: IToast) => {
      const copiedToast = new Map(toasts);
      if (toast.id) copiedToast.delete(toast.id);
      setToasts(copiedToast);
    },
    [setToasts, toasts]
  );

  const addToast = useCallback(
    (toast: IToast) => {
      const copiedToast = new Map(toasts);
      if (toast.id) copiedToast.set(toast.id, toast);
      setToasts(copiedToast);
      if (toast.timeout) {
        setTimeout(() => {
          removeWithTimer(toast);
        }, toast.timeout);
      }
    },
    [removeWithTimer, toasts]
  );

  const removeAll = useCallback(() => {
    setToasts(new Map());
  }, []);

  const providedValue = useMemo<IToastContext>(
    () => ({
      addToast,
      onToastClose,
      removeAll,
    }),
    [addToast, onToastClose, removeAll]
  );

  return (
    <ToastContext.Provider value={providedValue}>
      {/* use props to component in order to avoid circular dependency */}
      <ToastContainer onToastClose={(toast: IToast) => onToastClose(toast)} toasts={[...toasts.values()]} />
      {children}
    </ToastContext.Provider>
  );
};

export default ToastProvider;
