import { IconProp } from '@fortawesome/fontawesome-svg-core';

export enum ResultViewType {
  List = 'List',
  Table = 'Table',
}

export interface IResultViewOption {
  view: ResultViewType;
  icon: IconProp;
}
