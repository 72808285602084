import { faClock } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import TimeAgo from 'react-timeago';

import './time-ago-item.scss';

export interface TimeAgoItemProps {
  dateString: string;
  hideIcon?: boolean;
}

export const TimeAgoItem: React.FC<TimeAgoItemProps> = ({ dateString, hideIcon }) => {
  const date = new Date(dateString);

  return (
    <span title={date.toDateString()}>
      {!hideIcon ? (
        <span className="text-primary">
          <FontAwesomeIcon icon={faClock} className="mr-1" />
        </span>
      ) : null}
      <TimeAgo date={date} live={false} />
    </span>
  );
};

export default TimeAgoItem;
