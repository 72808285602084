import React, { useContext, useEffect, useState } from 'react';

import './generative-ai.scss';

const GenerativeAILoader = () => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  const loadingMessages = [
    'Processing query...',
    'Generating response from top documents…',
    'Consolidating results from search results…',
    'Result found!',
    'All set! Here is the result.',
  ];
  const shimerAnimatedBars = [...Array(4)].map((_, i) => <div className={`shine box-${i}`} />);
  useEffect(() => {
    const intervalIdOlder = setInterval(() => {
      setCurrentMessageIndex(prevIndex => (prevIndex + 1) % loadingMessages.length);
    }, 5000);

    return () => clearInterval(intervalIdOlder);
  }, [loadingMessages.length]);

  return (
    <div className="generative-container col ml-2 pb-3 pt-3 mb-3 ">
      <div className="d-flex">
        {/* <div className="generate-loader mb-4 ml-2 mr-3" /> */}
        <div className="circle circle-half mr-2" />
        <div className="font-italic font-weight-bold animated-note">{loadingMessages[currentMessageIndex]}</div>
      </div>

      {shimerAnimatedBars}
    </div>
  );
};

export default GenerativeAILoader;
