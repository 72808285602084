import { CONVERSATIONAL_SEARCH_QnA_ACTIONS, MatomoContext, SEARCH_APP_FEATURE_LIST, SearchContext } from '@amgen/shared';
import React, { useContext, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

import './gen-ai-multi-turn.scss';

export interface GenAISummaryProps {
  item: any;
}

const GenAISummary: React.FC<GenAISummaryProps> = ({ item }) => {
  const { conversationalQnAInvoked, setConversationalQnAInvoked } = useContext(SearchContext);
  const { trackApplicationFeature } = useContext(MatomoContext);
  const [showmore, setShowMore] = useState(true);

  useEffect(() => {
    if (!conversationalQnAInvoked) {
      trackApplicationFeature(
        SEARCH_APP_FEATURE_LIST.GEN_AI_CONVERSATIONAL_SEARCH,
        CONVERSATIONAL_SEARCH_QnA_ACTIONS.CONVERSATIONAL_SEARCH_QnA_INVOKED
      );
      setConversationalQnAInvoked(true);
    }
  }, [conversationalQnAInvoked]);

  const showMoreCitations = () => {
    setShowMore(!showmore);
  };

  const handleReferenceClick = () => {
    trackApplicationFeature(
      SEARCH_APP_FEATURE_LIST.GEN_AI_CONVERSATIONAL_SEARCH,
      CONVERSATIONAL_SEARCH_QnA_ACTIONS.CONVERSATIONAL_SEARCH_CITATION_CLICKS
    );
  };

  return (
    <div>
      {item?.citations?.length > 0 ? (
        <>
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
            children={item.summary}
            className=" px-3"
            components={{
              a: ({ node, ...props }) => <a {...props} onClick={handleReferenceClick} />,
            }}
          />

          <div className=" px-3 mt-2">
            <div className=" mb-1 d-flex">
              <span className="mr-1 font-weight-bold">{item?.citations?.length}</span>
              <span className="font-weight-bold">Reference(s) </span>
              <span onClick={showMoreCitations}>
                {!showmore ? (
                  <div className="citation-arrow ml-2" dangerouslySetInnerHTML={{ __html: '&#x25bc;' }}></div>
                ) : (
                  <div className="citation-arrow ml-2" dangerouslySetInnerHTML={{ __html: '&#x25b2;' }}></div>
                )}
              </span>
            </div>
            {/* <div className="note-reference font-weight-bold my-3">
              Note : Kindly use the search results to navigate to exact page number, as it's temporarily disabled in the
              references section.
            </div> */}
            {showmore ? (
              <div className="font-italic fs-14 citation-link mb-1">
                {item?.citations?.map((citation, citationIndex) => (
                  <div key={citationIndex}>
                    <span className="mr-1">[{citationIndex + 1}] </span>

                    <a href={citation.uri} target="_blank" rel="noreferrer" onClick={handleReferenceClick}>
                      {citation.source !== '' && citation.source !== null && <span>{citation.source}: </span>}
                      <span className=" mr-1">{citation.title}</span>
                      {citation.pageNo !== '' && citation.pageNo !== null && (
                        <span>{` (Page ${citation.pageNo})`}</span>
                      )}
                    </a>
                  </div>
                ))}
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="note-reference font-weight-bold my-3 ml-3">
            Note : Kindly use the search results to navigate to exact page number, as it's temporarily disabled in the
            references section.
          </div>
        </>
      ) : (
        <div className="px-3">Don't have enough information to generate the summary for the search query .</div>
      )}
    </div>
  );
};

export default GenAISummary;
