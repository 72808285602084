import { SearchContext, useSearchQueryValue, useWindowSizeListener } from '@amgen/shared';
import React, { useContext } from 'react';

import './feature-app-section.scss';

/* eslint-disable-next-line */
export interface FeatureAppSectionProps {
  classnames?: string;
  container?: string;
}

export function FeatureAppSection({ classnames, container }: FeatureAppSectionProps) {
  const { expandPanel, isAllSelected } = useContext(SearchContext);

  const searchTerm = useSearchQueryValue();
  const isMobile = useWindowSizeListener();
  // let filteredApps = [];

  // if (container === 'search-page') {
  //   filteredApps =
  //     appData?.getSearchObjects?.isResponseFromKeyword || appData?.getSearchObjects?.bertScore >= 0.75
  //       ? appData?.getSearchObjects?.docs
  //       : [];
  // } else {
  //   filteredApps =
  //     !appData?.getSearchObjects?.isResponseFromKeyword && appData?.getSearchObjects?.bertScore < 0.75
  //       ? appData?.getSearchObjects?.docs
  //       : [];
  // }
  return searchTerm !== '*:*' && isAllSelected ? (
    <div
    // className={classNames(
    //   classnames,
    //   container === 'search-page' ? 'suggested-application' : '',
    //   container === 'search-page' && !expandPanel && !isMobile ? 'expanded-filter' : ''
    // )}
    >
      {/* <span className="event-font break-word font-weight-bold ml-1">Suggested Destination(s) </span>
      {filteredApps.map(item => (
        <AppSearchResult item={item} />
      ))} */}
    </div>
  ) : null;
}

export default FeatureAppSection;
