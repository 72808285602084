import { useAuth } from '@amgen/auth';
import { useEnvironment } from '@amgen/core';
import { useContext, useEffect } from 'react';

import { MatomoContext } from './context';
import { IPages } from './pages';

export function usePageTrackingWithUserDimensions() {
  const { appName, trackingAppName } = useEnvironment();
  const { trackPageView, trackCustomDimensions, dimensions, trackMultipleCustomDimensions } = useContext(MatomoContext);
  const { user } = useAuth();
  const formattedUserName = `${user.fullName} (${user.username})`;
  useEffect(() => {
    trackPageView(user.username); // this is usrId tracking
    if (dimensions.FULL_NAME && user.fullName) {
      const userDimensionMap: Map<string, string> = new Map();
      userDimensionMap.set(dimensions.COST_CENTER, user.costCenter);
      userDimensionMap.set(dimensions.COMPANY_CODE, user.companyCode);
      userDimensionMap.set(dimensions.FUNCTIONAL_AREA, user.functionalArea);
      userDimensionMap.set(dimensions.APP_NAME, trackingAppName ? trackingAppName : appName);
      userDimensionMap.set(dimensions.COST_CENTER_NAME, user.subFunctionalArea);
      userDimensionMap.set(dimensions.FULL_NAME, formattedUserName);
      userDimensionMap.set(dimensions.USER_GROUP as string, user.abTestingGroup ? "GenAI Search" : "Legacy Search");
      trackMultipleCustomDimensions(userDimensionMap);
    }
  }, [
    appName,
    dimensions,
    trackCustomDimensions,
    trackPageView,
    trackMultipleCustomDimensions,
    user,
    formattedUserName,
  ]);
}

export function usePageTrackingWithTitle(page: IPages[keyof IPages]) {
  const { trackCustomDimensions, dimensions } = useContext(MatomoContext);
  useEffect(() => {
    trackCustomDimensions(page, dimensions.PAGE_NAME);
  }, [trackCustomDimensions, dimensions, page]);
}
