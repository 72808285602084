import * as Types from '@amgen/core';

import { IGenericResponseFragment } from './generic-response';
import { gql } from '@apollo/client';
import { GenericResponseFragmentDoc } from './generic-response';
import * as Apollo from '@apollo/client';
export type ISavedPreferenceFragment = Pick<
  Types.ISavedPreference,
  'id' | 'values' | 'applicationName' | 'timestamp' | 'type' | 'group'
>;

export type IGetSavedPreferenceQueryVariables = Types.Exact<{
  appName: Types.ApplicationName;
}>;

export type IGetSavedPreferenceQuery = {
  tableViewColumns?: Types.Maybe<Array<Types.Maybe<ISavedPreferenceFragment>>>;
  facetCategories?: Types.Maybe<Array<Types.Maybe<ISavedPreferenceFragment>>>;
  exportCategories?: Types.Maybe<Array<Types.Maybe<ISavedPreferenceFragment>>>;
  dataSources?: Types.Maybe<Array<Types.Maybe<ISavedPreferenceFragment>>>;
  view?: Types.Maybe<Array<Types.Maybe<ISavedPreferenceFragment>>>;
};

export type ISavePreferenceMutationVariables = Types.Exact<{
  appName: Types.ApplicationName;
  type?: Types.Maybe<Types.SettingsPreferenceType>;
  values: Array<Types.Maybe<Types.Scalars['String']>> | Types.Maybe<Types.Scalars['String']>;
  queryId?: Types.Maybe<Types.Scalars['String']>;
  group?: Types.Maybe<Types.Scalars['String']>;
}>;

export type ISavePreferenceMutation = { savePreference?: Types.Maybe<ISavedPreferenceFragment> };

export type IDeletePreferenceMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
  isSoft?: Types.Maybe<Types.Scalars['Boolean']>;
  applicationName: Types.ApplicationName;
}>;

export type IDeletePreferenceMutation = { deletePreference?: Types.Maybe<IGenericResponseFragment> };

export type IGetSavedDataSourcesQueryVariables = Types.Exact<{
  appName: Types.ApplicationName;
}>;

export type IGetSavedDataSourcesQuery = {
  getSavedPreference?: Types.Maybe<Array<Types.Maybe<ISavedPreferenceFragment>>>;
};

export const SavedPreferenceFragmentDoc = gql`
  fragment SavedPreference on SavedPreference {
    id
    values
    applicationName
    timestamp
    type
    group
  }
`;
export const GetSavedPreferenceDocument = gql`
  query GetSavedPreference($appName: ApplicationName!) {
    tableViewColumns: getSavedPreference(applicationName: $appName, type: TABLE_VIEW_COLUMN) {
      ...SavedPreference
    }
    facetCategories: getSavedPreference(applicationName: $appName, type: FACET_CATEGORY) {
      ...SavedPreference
    }
    exportCategories: getSavedPreference(applicationName: $appName, type: EXPORT_CATEGORY) {
      ...SavedPreference
    }
    dataSources: getSavedPreference(applicationName: $appName, type: DATA_SOURCE) {
      ...SavedPreference
    }
    view: getSavedPreference(applicationName: $appName, type: VIEW) {
      ...SavedPreference
    }
  }
  ${SavedPreferenceFragmentDoc}
`;
export function useGetSavedPreferenceQuery(
  baseOptions: Apollo.QueryHookOptions<IGetSavedPreferenceQuery, IGetSavedPreferenceQueryVariables>
) {
  return Apollo.useQuery<IGetSavedPreferenceQuery, IGetSavedPreferenceQueryVariables>(
    GetSavedPreferenceDocument,
    baseOptions
  );
}
export function useGetSavedPreferenceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetSavedPreferenceQuery, IGetSavedPreferenceQueryVariables>
) {
  return Apollo.useLazyQuery<IGetSavedPreferenceQuery, IGetSavedPreferenceQueryVariables>(
    GetSavedPreferenceDocument,
    baseOptions
  );
}
export type GetSavedPreferenceQueryHookResult = ReturnType<typeof useGetSavedPreferenceQuery>;
export type GetSavedPreferenceLazyQueryHookResult = ReturnType<typeof useGetSavedPreferenceLazyQuery>;
export type GetSavedPreferenceQueryResult = Apollo.QueryResult<
  IGetSavedPreferenceQuery,
  IGetSavedPreferenceQueryVariables
>;
export const SavePreferenceDocument = gql`
  mutation SavePreference(
    $appName: ApplicationName!
    $type: SettingsPreferenceType
    $values: [String]!
    $queryId: String
    $group: String
  ) {
    savePreference(applicationName: $appName, type: $type, values: $values, queryId: $queryId, group: $group) {
      ...SavedPreference
    }
  }
  ${SavedPreferenceFragmentDoc}
`;
export type ISavePreferenceMutationFn = Apollo.MutationFunction<
  ISavePreferenceMutation,
  ISavePreferenceMutationVariables
>;
export function useSavePreferenceMutation(
  baseOptions?: Apollo.MutationHookOptions<ISavePreferenceMutation, ISavePreferenceMutationVariables>
) {
  return Apollo.useMutation<ISavePreferenceMutation, ISavePreferenceMutationVariables>(
    SavePreferenceDocument,
    baseOptions
  );
}
export type SavePreferenceMutationHookResult = ReturnType<typeof useSavePreferenceMutation>;
export type SavePreferenceMutationResult = Apollo.MutationResult<ISavePreferenceMutation>;
export type SavePreferenceMutationOptions = Apollo.BaseMutationOptions<
  ISavePreferenceMutation,
  ISavePreferenceMutationVariables
>;
export const DeletePreferenceDocument = gql`
  mutation DeletePreference($id: String!, $isSoft: Boolean, $applicationName: ApplicationName!) {
    deletePreference(id: $id, isSoft: $isSoft, applicationName: $applicationName) {
      ...GenericResponse
    }
  }
  ${GenericResponseFragmentDoc}
`;
export type IDeletePreferenceMutationFn = Apollo.MutationFunction<
  IDeletePreferenceMutation,
  IDeletePreferenceMutationVariables
>;
export function useDeletePreferenceMutation(
  baseOptions?: Apollo.MutationHookOptions<IDeletePreferenceMutation, IDeletePreferenceMutationVariables>
) {
  return Apollo.useMutation<IDeletePreferenceMutation, IDeletePreferenceMutationVariables>(
    DeletePreferenceDocument,
    baseOptions
  );
}
export type DeletePreferenceMutationHookResult = ReturnType<typeof useDeletePreferenceMutation>;
export type DeletePreferenceMutationResult = Apollo.MutationResult<IDeletePreferenceMutation>;
export type DeletePreferenceMutationOptions = Apollo.BaseMutationOptions<
  IDeletePreferenceMutation,
  IDeletePreferenceMutationVariables
>;
export const GetSavedDataSourcesDocument = gql`
  query GetSavedDataSources($appName: ApplicationName!) {
    getSavedPreference(applicationName: $appName, type: DATA_SOURCE) {
      ...SavedPreference
    }
  }
  ${SavedPreferenceFragmentDoc}
`;
export function useGetSavedDataSourcesQuery(
  baseOptions: Apollo.QueryHookOptions<IGetSavedDataSourcesQuery, IGetSavedDataSourcesQueryVariables>
) {
  return Apollo.useQuery<IGetSavedDataSourcesQuery, IGetSavedDataSourcesQueryVariables>(
    GetSavedDataSourcesDocument,
    baseOptions
  );
}
export function useGetSavedDataSourcesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetSavedDataSourcesQuery, IGetSavedDataSourcesQueryVariables>
) {
  return Apollo.useLazyQuery<IGetSavedDataSourcesQuery, IGetSavedDataSourcesQueryVariables>(
    GetSavedDataSourcesDocument,
    baseOptions
  );
}
export type GetSavedDataSourcesQueryHookResult = ReturnType<typeof useGetSavedDataSourcesQuery>;
export type GetSavedDataSourcesLazyQueryHookResult = ReturnType<typeof useGetSavedDataSourcesLazyQuery>;
export type GetSavedDataSourcesQueryResult = Apollo.QueryResult<
  IGetSavedDataSourcesQuery,
  IGetSavedDataSourcesQueryVariables
>;
