import { IAssetAttribute } from '@amgen/core';
import React, { useEffect, useMemo, useState } from 'react';

import './facet-context-provider.scss';

import { IPersistentFilters } from '../../models';
import { FacetContext, IFacetState } from './facet-context';
import { useDashboardState, useGlobalFilters } from './hooks';

export interface FacetContextProviderProps {
  facetCategories: IAssetAttribute[];
  clickableAttributes: IAssetAttribute[];
  persistentFilters: IPersistentFilters;
}

export const FacetContextProvider: React.FC<FacetContextProviderProps> = ({ children, ...props }) => {
  const [isCustomFilterSearch, setCustomFilterSearch] = useState(false);
  const [isDropdownSearch, setDropdownSearch] = useState(false);
  const {
    appliedFilterCategories,
    setAppliedFilterCategories,
    addFilterCategory,
    appliedFilters,
    setAppliedFilters,
  } = useGlobalFilters(props.facetCategories);
  const {
    dashboardFilters,
    setDashboardFilters,
    selectedQuickRange,
    setSelectedQuickRange,
    chartType,
    setChartType,
  } = useDashboardState();


  // useEffect(() => {
  //   if (sessionStorage.getItem('externalFilter') === 'All') {
  //     //setAppliedFilters(appliedFilters.add('0/Ops KM Assets', 'doc_nav_prefix'));
  //     //setAppliedFilters(appliedFilters.add('0/CDOCS', 'doc_nav_prefix'));
  //   } else if (sessionStorage.getItem('externalFilter')) {
  //     setAppliedFilters(appliedFilters.add(`0/${sessionStorage.getItem('externalFilter')}`, 'doc_nav_prefix'));
  //   }

  //   if (sessionStorage.getItem('externalAssetType')) {
  //     setAppliedFilters(appliedFilters.add(sessionStorage.getItem('externalAssetType')!, 'asset_type'));
  //   }

  //   if (sessionStorage.getItem('externalFunction')) {
  //     setAppliedFilters(appliedFilters.add(sessionStorage.getItem('externalFunction')!, 'function'));
  //   }

  //   if (sessionStorage.getItem('externalSite')) {
  //     setAppliedFilters(appliedFilters.add(`1/SharePoint/${sessionStorage.getItem('externalSite')}`, 'doc_nav_prefix'));
  //   }
  // }, []);

  const providedValue = useMemo<IFacetState>(
    () => ({
      appliedFilters,
      setAppliedFilters,
      facetCategories: props.facetCategories,
      clickableAttributes: props.clickableAttributes,
      persistentFilters: props.persistentFilters,
      dashboardFilters,
      setDashboardFilters,
      selectedQuickRange,
      setSelectedQuickRange,
      chartType,
      setChartType,
      appliedFilterCategories,
      setAppliedFilterCategories,
      addFilterCategory,
      isCustomFilterSearch,
      setCustomFilterSearch,
      isDropdownSearch,
      setDropdownSearch,
    }),
    [
      appliedFilters,
      setAppliedFilters,
      props.facetCategories,
      props.clickableAttributes,
      props.persistentFilters,
      dashboardFilters,
      setDashboardFilters,
      selectedQuickRange,
      setSelectedQuickRange,
      chartType,
      setChartType,
      appliedFilterCategories,
      setAppliedFilterCategories,
      addFilterCategory,
      isCustomFilterSearch,
      setCustomFilterSearch,
      isDropdownSearch,
      setDropdownSearch,
    ]
  );

  return <FacetContext.Provider value={providedValue}>{children}</FacetContext.Provider>;
};

export default FacetContextProvider;
