import classnames from 'classnames';
import React from 'react';
import { Nav } from 'react-bootstrap';

import './header-right.scss';

export interface HeaderRightProps {
  className?: string;
}

export const HeaderRight: React.FC<HeaderRightProps> = props => {
  return (
    <Nav className={classnames('ml-auto align-items-lg-center', props.className)}>
      {React.Children.map(props.children, child => (
        <Nav.Item>{child}</Nav.Item>
      ))}
    </Nav>
  );
};

export default HeaderRight;
