import * as Types from '@amgen/core';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type IGetBinaryFeedbackQueryVariables = Types.Exact<{
  appName: Types.ApplicationName;
  searchTerm?: Types.Maybe<Types.Scalars['String']>;
  assetId?: Types.Maybe<Array<Types.Maybe<Types.Scalars['String']>> | Types.Maybe<Types.Scalars['String']>>;
}>;

export type IGetBinaryFeedbackQuery = {
  getBinaryFeedback?: Types.Maybe<
    Array<
      Types.Maybe<
        Pick<Types.IBinaryFeedback, 'id' | 'type' | 'searchTerm' | 'binaryRating' | 'assetId' | 'applicationName'>
      >
    >
  >;
};

export type IAddBinaryFeedbackMutationVariables = Types.Exact<{
  feedback?: Types.Maybe<Types.IBinaryFeedbackInput>;
}>;

export type IAddBinaryFeedbackMutation = {
  addBinaryFeedback?: Types.Maybe<Pick<Types.IGenericResponse, 'status' | 'message' | 'error'>>;
};

export const GetBinaryFeedbackDocument = gql`
  query GetBinaryFeedback($appName: ApplicationName!, $searchTerm: String, $assetId: [String]) {
    getBinaryFeedback(applicationName: $appName, searchTerm: $searchTerm, assetId: $assetId) {
      id
      type
      searchTerm
      binaryRating
      assetId
      applicationName
    }
  }
`;
export function useGetBinaryFeedbackQuery(
  baseOptions: Apollo.QueryHookOptions<IGetBinaryFeedbackQuery, IGetBinaryFeedbackQueryVariables>
) {
  return Apollo.useQuery<IGetBinaryFeedbackQuery, IGetBinaryFeedbackQueryVariables>(
    GetBinaryFeedbackDocument,
    baseOptions
  );
}
export function useGetBinaryFeedbackLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetBinaryFeedbackQuery, IGetBinaryFeedbackQueryVariables>
) {
  return Apollo.useLazyQuery<IGetBinaryFeedbackQuery, IGetBinaryFeedbackQueryVariables>(
    GetBinaryFeedbackDocument,
    baseOptions
  );
}
export type GetBinaryFeedbackQueryHookResult = ReturnType<typeof useGetBinaryFeedbackQuery>;
export type GetBinaryFeedbackLazyQueryHookResult = ReturnType<typeof useGetBinaryFeedbackLazyQuery>;
export type GetBinaryFeedbackQueryResult = Apollo.QueryResult<
  IGetBinaryFeedbackQuery,
  IGetBinaryFeedbackQueryVariables
>;
export const AddBinaryFeedbackDocument = gql`
  mutation AddBinaryFeedback($feedback: BinaryFeedbackInput) {
    addBinaryFeedback(feedback: $feedback) {
      status
      message
      error
    }
  }
`;
export type IAddBinaryFeedbackMutationFn = Apollo.MutationFunction<
  IAddBinaryFeedbackMutation,
  IAddBinaryFeedbackMutationVariables
>;
export function useAddBinaryFeedbackMutation(
  baseOptions?: Apollo.MutationHookOptions<IAddBinaryFeedbackMutation, IAddBinaryFeedbackMutationVariables>
) {
  return Apollo.useMutation<IAddBinaryFeedbackMutation, IAddBinaryFeedbackMutationVariables>(
    AddBinaryFeedbackDocument,
    baseOptions
  );
}
export type AddBinaryFeedbackMutationHookResult = ReturnType<typeof useAddBinaryFeedbackMutation>;
export type AddBinaryFeedbackMutationResult = Apollo.MutationResult<IAddBinaryFeedbackMutation>;
export type AddBinaryFeedbackMutationOptions = Apollo.BaseMutationOptions<
  IAddBinaryFeedbackMutation,
  IAddBinaryFeedbackMutationVariables
>;
