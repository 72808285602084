import { useGetDataSourceDetailsQuery } from '@amgen/api';
import { LoaderErrorContainer } from '@amgen/components';
import { DataSource, useEnvironment } from '@amgen/core';
import { FiltersObject, useWidthListener } from '@amgen/shared';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';

import './advance-search.scss';

import DataSourceDropdown from '../data-source-dropdown/data-source-dropdown';
import { IDateRangeValue } from '../date-range-input/date-range-dropdown/date-range-dropdown';
import DateRangeInput from '../date-range-input/date-range-input';

export interface AdvanceSearchProps {
  setContentSelected: (dataSources: DataSource[]) => void;
  searchSize?: 'sm' | 'lg';
  selectedFilters: FiltersObject;
  showAdvSearch: boolean;
  onFilter: () => void;
  onSearch: () => void;
  onCancel: () => void;
  dateFilterSelected: (value: boolean) => void;
  setSelectedFilters: (selectedFilters: FiltersObject) => void;
  setBlockScroll?: (value: boolean) => void;
}

export const AdvanceSearch: React.FC<AdvanceSearchProps> = props => {
  const { appName } = useEnvironment();
  const width = useWidthListener('search-input-group', props.searchSize ? true : false);
  const [selectedContentSources, setSelectedContentSources] = useState<Set<DataSource>>(new Set());
  const [dateRange, setDateRange] = useState<IDateRangeValue | null>(null);
  const [isActive, setIsActive] = useState(false);
  const { data, loading, error } = useGetDataSourceDetailsQuery({
    variables: { appName, page: 0, count: 10, userType: sessionStorage.getItem('employeeType') || '' },
  });

  const handleCancel = () => {
    reset();
    props.onCancel();
  };

  const reset = () => {
    setSelectedContentSources(new Set([]));
    setDateRange({
      label: 'Date Range ',
      value: [{ startDate: new Date(), endDate: new Date(), key: 'selection' }],
    });
    setIsActive(false);
    props.setSelectedFilters(props.selectedFilters.removeAll('doc_nav_prefix'));
    props.setSelectedFilters(props.selectedFilters.removeAll('doc_last_modified'));
  };

  useEffect(() => {
    !props.showAdvSearch && reset();
  }, [props.showAdvSearch]);

  return (
    <Card className="border-0" id="advance-search-panel" style={{ width: width - 30 }}>
      <Card.Body as={Row} className="advance-search-panel-body">
        <Col xs={12} sm={3} className="align-self-center">
          <label className="display-5 text-primary">
            <strong>Select Content Source</strong>
          </label>
        </Col>
        <Col
          xs={12}
          sm={9}
          onMouseEnter={() => props.setBlockScroll && props.setBlockScroll(true)}
          onMouseLeave={() => props.setBlockScroll && props.setBlockScroll(false)}
          className="datasources-dropdown-div"
        >
          <LoaderErrorContainer loading={loading} errors={error ? [error] : []}>
            <DataSourceDropdown
              dataSources={data?.getDataSourceDetails.items?.map(x => x.id as DataSource).sort() || []}
              onChange={dataSources => props.setContentSelected(dataSources)}
              selectedContentSources={selectedContentSources}
              setSelectedContentSources={sources => setSelectedContentSources(sources)}
            />
          </LoaderErrorContainer>
        </Col>
        <Col xs={12} sm="3" className="mt-3 align-self-center">
          <label className="display-5 text-primary">
            <strong>Select Date Range</strong>
          </label>
        </Col>
        <Col xs={12} sm="9">
          <DateRangeInput
            selectedFilters={props.selectedFilters}
            setSelectedFilters={() => props.setSelectedFilters(props.selectedFilters)}
            searchSize={props.searchSize}
            dateFilterSelected={(value: boolean) => props.dateFilterSelected(value)}
            dateRange={dateRange}
            setDateRange={(dateRange: IDateRangeValue | null) => setDateRange(dateRange)}
            isActive={isActive}
            setIsActive={(value: boolean) => setIsActive(value)}
          />
        </Col>
      </Card.Body>

      <Card.Footer as={Row} className="bg-transparent border-0 text-right justify-content-end">
        <Col xs={12} sm={{ span: 9, offset: 3 }}>
          <Row noGutters>
            <Col xs={11}>
              <Button variant="primary" className="mr-md-3 mr-lg-0 buttonwidth" onClick={() => props.onFilter()}>
                Search
              </Button>
              <Button variant="outline-primary" className="ml-lg-3 pt-1 buttonwidth" onClick={() => handleCancel()}>
                Cancel
              </Button>
            </Col>
          </Row>
        </Col>
      </Card.Footer>
    </Card>
  );
};

export default AdvanceSearch;
