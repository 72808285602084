import { DEFAULT_SEARCH_QUERY, IAssetAttribute } from '@amgen/core';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { FiltersObject, IQuickRangeOption, MagicLineChartType, QUICK_RANGE_OPTIONS } from '../../models';
import { PreferenceContext } from '../preference-context-provider/preference-context';

export function useGlobalFilters(facetCategories: IAssetAttribute[]) {
  const history = useHistory();
  const location = useLocation();

  // the filtere category list from the saved preference(facet-config) (add filter dropdown)
  const { selectedFacetConfig } = useContext(PreferenceContext);
  const [appliedFilterCategories, setAppliedFilterCategories] = useState(new Set(selectedFacetConfig));

  // applied filters (filters in the query ) and selected-filters (differentiating filter state from facet panel & actual fetch query)
  const [appliedFilters, setAppliedFilters] = useState(new FiltersObject());

  useEffect(() => {
    setAppliedFilterCategories(new Set(selectedFacetConfig));
  }, [JSON.stringify(selectedFacetConfig)]);

  useEffect(() => {
    const urlFilters = new FiltersObject();
    const searchParam = new URLSearchParams(decodeURIComponent(location.search));
    const q = searchParam.get('q');
    Array.from(searchParam.entries())
      .filter((param: any) => facetCategories.includes(param[0] as IAssetAttribute))
      .forEach((param: any) => {
        let values = param[1];
        if (urlFilters.rangeKeys().includes(param[0] as IAssetAttribute)) {
          values = param[1].split(',');
        }
        urlFilters.add(values, param[0] as IAssetAttribute);
      });

    if (urlFilters.count) {
      setAppliedFilters(urlFilters);
      setAppliedFilterCategories(
        new Set([...appliedFilterCategories, ...urlFilters.categories.filter(x => facetCategories.includes(x))])
      );
      const params = new URLSearchParams();
      params.append('q', q !== DEFAULT_SEARCH_QUERY ? `"${q}"` : '');
      history.push({ pathname: location.pathname, search: params.toString() });
    }
  }, [appliedFilterCategories, facetCategories, history, location, setAppliedFilters]);

  const addFilterCategory = useCallback(
    (category: IAssetAttribute) => {
      if (facetCategories.includes(category)) {
        const copy = new Set(appliedFilterCategories);
        if (!copy.has(category)) {
          copy.add(category);
        }
        setAppliedFilterCategories(copy);
      }
    },
    [facetCategories, appliedFilterCategories, setAppliedFilterCategories]
  );

  return {
    appliedFilterCategories,
    setAppliedFilterCategories,
    addFilterCategory,
    appliedFilters,
    setAppliedFilters,
  };
}

export function useDashboardState() {
  const [dashboardFilters, setDashboardFilters] = useState(new FiltersObject());
  const [selectedQuickRange, setSelectedQuickRange] = useState<IQuickRangeOption>(QUICK_RANGE_OPTIONS[0]);
  const [chartType, setChartType] = useState(MagicLineChartType.LINE);

  return { dashboardFilters, setDashboardFilters, selectedQuickRange, setSelectedQuickRange, chartType, setChartType };
}
