import { IAsset } from '@amgen/core';
import { createContext } from 'react';

import { ResultViewType } from '../../models';

export interface IAssetState {
  docSolrAsset: IAsset;
  isBookmarksDisabled: boolean;
  viewType?: ResultViewType;
  isToggleEnabled: boolean;
  showReadMoreFlag: boolean;
  setToggleEnabled: (flag: boolean) => void;
  setShowReadMoreFlag: (flag: boolean) => void;
}

export const AssetContext = createContext<IAssetState>(null!);
