import React, { useContext } from 'react';

import './name.scss';

import { ItemContext } from '../item-context';

export interface NameProps {
  onClick?: () => void;
}

export const Name: React.FC<NameProps> = ({ children, onClick }) => {
  const { setIsModalOpen } = useContext(ItemContext);

  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
    setIsModalOpen(true);
  };

  return (
    <span onClick={handleOnClick} className="d-block">
      {children}
    </span>
  );
};

export default Name;
