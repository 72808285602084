import { faChevronLeft, faCommentDots } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import './base-feedback-page.scss';

/* eslint-disable-next-line */
export interface BaseFeedbackPageProps {}

export const BaseFeedbackPage: React.FC<BaseFeedbackPageProps> = ({ children }) => {
  const { goBack } = useHistory();

  return (
    <Col xs="12" className="bg-white shadow-sm my-3">
      <Row noGutters>
        <Col xs="12" className="px-3 py-2">
          <Button
            variant="link"
            className="px-0 ml-3 h2 position-absolute"
            style={{ left: 0, top: 25 }}
            onClick={goBack}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
            <span className="pl-2 d-none d-sm-inline">Back</span>
          </Button>

          <h1 className="p-3 mb-0 font-weight-normal text-center">
            <FontAwesomeIcon icon={faCommentDots} />
            <span className="px-2">Feedbacks</span>
          </h1>
        </Col>

        {children}
      </Row>
    </Col>
  );
};

export default BaseFeedbackPage;
