import { useGetDocumentFeedbacksQuery } from '@amgen/api';
import { Direction, IDataAsset, useEnvironment } from '@amgen/core';
import { useWindowSizeListener } from '@amgen/shared';
import React, { useEffect, useRef, useState } from 'react';
import { Overlay, Popover } from 'react-bootstrap';

import './doc-rating-btn.scss';

import DocRatingWrapper from '../doc-rating-wrapper/doc-rating-wrapper';
import RatingBtn from '../rating-btn/rating-btn';

export interface DocRatingBtnProps {
  asset: IDataAsset;
  className?: string;
  buttonText?: string;
}

export const DocRatingBtn = (props: DocRatingBtnProps) => {
  const isMobile = useWindowSizeListener();
  const [avgRating, setAvgRating] = useState(0);
  const { appName } = useEnvironment();
  const [showPopover, setShowPopover] = useState(false);
  const childNode = useRef<HTMLElement | null>(null);
  const delay = 900;
  const timerHandle = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    const handle = timerHandle.current;

    return () => {
      if (handle) {
        clearTimeout(handle);
      }
    };
  });

  useGetDocumentFeedbacksQuery({
    onCompleted: data => {
      const feedbackMetrics = data?.getDocumentFeedback?.feedbackMetrics;
      if (feedbackMetrics) {
        const count = feedbackMetrics[0].values?.reduce((total, value) => total + (value?.count ?? 0), 0) ?? 1;
        const rating =
          (feedbackMetrics[0].values?.reduce((total, value) => total + Number(value.value) * (value?.count ?? 0), 0) ??
            0) / count;
        const ratingClean = isNaN(rating) ? 0 : rating;

        setAvgRating(ratingClean);
      }
    },
    variables: {
      appName,
      reviewPage: 0,
      reviewCount: 0,
      reviewStart: 0,
      sortField: 'rating',
      sortOrder: Direction.DESC,
      assetId: props.asset.id!,
    },
    //fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  // Adapted from https://gist.github.com/lou/571b7c0e7797860d6c555a9fdc0496f9
  // Also see: https://github.com/react-bootstrap/react-bootstrap/issues/850#issuecomment-112821798
  // And this: https://github.com/react-bootstrap/react-bootstrap/issues/1622#issuecomment-173414114
  return (
    <>
      <span
        title="Keep hovering to view more Ratings"
        ref={childNode}
        onMouseEnter={() => {
          timerHandle.current = setTimeout(() => {
            setShowPopover(true);
          }, delay);
        }}
        onMouseLeave={() => {
          timerHandle.current = setTimeout(() => {
            setShowPopover(false);
          }, delay);
        }}
      >
        <RatingBtn className={props.className} avgRating={avgRating} buttonText={props.buttonText} />
      </span>
      <Overlay show={showPopover} placement="left" target={childNode}>
        <Popover
          id={`popover-position-${props.asset.id}`}
          className="card border-0 shadow-lg feedback-popover"
          onMouseEnter={() => {
            if (timerHandle.current) {
              clearTimeout(timerHandle.current);
            }
            setShowPopover(true);
          }}
          onMouseLeave={() => {
            if (timerHandle.current) {
              clearTimeout(timerHandle.current);
            }
            setShowPopover(false);
          }}
        >
          <Popover.Content>
            <div
              className="doc-metrics-popover-holder overflow-y-auto thin-scrollbar"
              style={isMobile ? { height: 575 } : {}}
            >
              <DocRatingWrapper asset={props.asset} inPopOver />
            </div>
          </Popover.Content>
        </Popover>
      </Overlay>
    </>
  );
};

export default DocRatingBtn;
