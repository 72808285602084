import { AppContext, SearchContext } from '@amgen/shared';
import React, { useContext, useMemo } from 'react';
import { Row } from 'react-bootstrap';

import './search-page-container.scss';

import SearchPageBody from '../search-page-body/search-page-body';
import SearchPageEmptyResult from '../search-page-empty-result/search-page-empty-result';
import SearchPageFooter from '../search-page-footer/search-page-footer';
import SearchPageLeftFlank from '../search-page-left-flank/search-page-left-flank';
import { SearchPageResults } from '../search-page-results/search-page-results';
import SearchPageRightFlank from '../search-page-right-flank/search-page-right-flank';
import { ISearchPageContext, SearchPageContext } from './context';

interface SearchPageProps {
  minHeight?: string;
  hideInTableView?: boolean;
}

interface ISettingsComposition {
  RightFlank: React.FC;
  LeftFlank: React.FC;
  EmptyResult: React.FC;
  Body: React.FC;
  Results: React.FC;
  Footer: React.FC;
}

export const SearchPageContainer: React.FC<SearchPageProps> & ISettingsComposition = props => {
  const { results, loading, eventData, acronymTitleData, consolidatedData, appData } = useContext(SearchContext);
  const { selectedCustomSourceName } = useContext(AppContext);
  const abTestingGroup = JSON.parse(sessionStorage.getItem('abTestingGroup') as string);
  const getAllLinksData = consolidatedData?.getAllLinks[0]?.docsData;
  // const deepLinksData = (getAllLinksData && getAllLinksData.filter(item => item.dataType === 'toplinks')) || [];
  // const appSectionData = (getAllLinksData && getAllLinksData.filter(item => item.dataType === 'apps')) || [];
  // const spotLightResults = (getAllLinksData && getAllLinksData.filter(item => item.dataType === 'spotlight')) || [];
  // const { results: spotLightResults } = useSpotlightResults();
  const isEmpty =
    (results?.length ?? 0) <= 0 &&
    (acronymTitleData?.getSearchObjects?.docs?.length ?? 0) <= 0 &&
    (eventData?.getSearchObjects?.docs?.length ?? 0) <= 0 &&
    (getAllLinksData?.length ?? 0) <= 0;

  const isEmptyABUser =
    (results?.length ?? 0) <= 0 &&
    (acronymTitleData?.getSearchObjects?.docs?.length ?? 0) <= 0 &&
    (appData?.getSearchObjectsApps?.docs?.length ?? 0) <= 0 &&
    selectedCustomSourceName !== 'Contract Search';

  const providerContextValue = useMemo<ISearchPageContext>(() => {
    return { emptySearchResult: abTestingGroup ? isEmptyABUser : isEmpty, loading: loading };
  }, [isEmpty, loading, isEmptyABUser]);

  return (
    <SearchPageContext.Provider value={providerContextValue}>
      <Row noGutters style={{ minHeight: props.minHeight }} className={'d-flex'}>
        {props.children}
      </Row>
    </SearchPageContext.Provider>
  );
};

SearchPageContainer.RightFlank = SearchPageRightFlank;
SearchPageContainer.LeftFlank = SearchPageLeftFlank;
SearchPageContainer.Body = SearchPageBody;
SearchPageContainer.Results = SearchPageResults;
SearchPageContainer.EmptyResult = SearchPageEmptyResult;
SearchPageContainer.Footer = SearchPageFooter;

export default { SearchPage: SearchPageContainer };
