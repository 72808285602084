import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import './remove-custom-data-source-button.scss';

export interface RemoveCustomDataSourceButtonProps {
  handleClick: () => void;
}

export const RemoveCustomDataSourceButton: React.FC<RemoveCustomDataSourceButtonProps> = ({ handleClick }) => {
  return (
    <div className="w-100 cursor-pointer postion-absolute">
      <FontAwesomeIcon
        icon={faStar}
        className="ml-5 text-gold"
        onClick={e => {
          e.stopPropagation();
          handleClick();
        }}
      />
    </div>
  );
};

export default RemoveCustomDataSourceButton;
