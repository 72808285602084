import DOMPurify from 'dompurify';
import React from 'react';
import { Badge } from 'react-bootstrap';

import './downtime-error.scss';

/* eslint-disable-next-line */
export interface UnauthorizedErrorProps {
  configData: any;
}

export const DownTimeErrorPage = (props: UnauthorizedErrorProps) => {
  const errorMessage = props.configData?.response?.downtime_data?.description;
  const pageHeading = props.configData?.response?.downtime_data?.title;
  return (
    <div className="d-flex justify-content-center align-items-center py-3" style={{ height: '100vh' }}>
      <div className="text-center shadow-lg bg-white px-5 pb-4 w-50 rounded">
        <img
          className="access-denied mb-3 mt-3"
          src={DOMPurify.sanitize(`data:image/jpeg;base64,${props.configData?.response?.downtime_data?.image_bytes}`)}
          alt="site down"
        />

        <div className="my-4">
          <h1>
            <Badge pill className="access-denied-pill ml-n4">
              {pageHeading}
            </Badge>
          </h1>

          <h1 className="font-weight-bolder py-2">{errorMessage}</h1>
        </div>
      </div>
    </div>
  );
};

export default DownTimeErrorPage;
