import { useGetTemporaryS3FileAccessLazyQuery, useSearchQuery } from '@amgen/api';
import { DEFAULT_SEARCH_QUERY, IAssetAttribute, useEnvironment } from '@amgen/core';
import { useAssetAttachmentField } from '@amgen/feature-search';
import {
  AppContext,
  AssetContext,
  DOCUMENT_INTERACTIONS_ACTIONS,
  FiltersObject,
  MatomoContext,
  SEARCH_APP_FEATURE_LIST,
  useAppliedFilters,
  useWindowSizeListener,
} from '@amgen/shared';
import { downloadFile } from '@amgen/utils';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

import './direct-download-button.module.scss';

/* eslint-disable-next-line */

export interface DirectDownloadButtonProps {
  url?: string;
  fileName?: string;
  isHideFileName?: boolean;
  buttonText?: string;
  className?: string;
}

export function DirectDownloadButton(props: DirectDownloadButtonProps) {
  const { internalSources } = useContext(AppContext);
  const { docSolrAsset } = useContext(AssetContext);
  const { trackApplicationFeature } = useContext(MatomoContext);
  const actionableAttributes: IAssetAttribute[] = [
    'short_content',
    'attachment_content',
    'attendees',
    'has_attendee',
    'has_attachment',
    'filename',
    'attachments',
    'question',
    'survey_id_account_id',
    'linktoemailresponse',
    'inqlink',
    'linktopostalresponse',
  ];
  const isInternalSource =
    docSolrAsset?.dataAsset?.content_source && internalSources?.includes(docSolrAsset?.dataAsset?.content_source);
  const { attachmentField } = useAssetAttachmentField(docSolrAsset?.dataAsset!);
  const attachmentId = (docSolrAsset?.dataAsset?.[attachmentField] as string) ?? '';
  const filters = useAppliedFilters(
    attachmentId
      ? new FiltersObject()
        .add('attachment', 'content_type')
        .add(attachmentId, attachmentField || 'parent_object_id')
        .add('*', 'filename')
      : new FiltersObject()
  );
  const isMobile = useWindowSizeListener();
  const { appName } = useEnvironment();
  const { data: downloaddata } = useSearchQuery({
    variables: {
      solrHandler: appName === 'DVIEW' ? '/dview-search-select' : '/search-select',
      query: DEFAULT_SEARCH_QUERY,
      filters,
      start: 0,
      page: 0,
      count: 10,
      includeDocs: false,
      noShortContent: false,
    },
    skip: !attachmentId || !docSolrAsset?.dataAsset?.has_attachment,
    fetchPolicy: 'cache-first',
  });

  const isActionAvailable = actionableAttributes.some(attr => {
    const value = docSolrAsset?.dataAsset?.[attr];
    if (!value) return false;
    if ((downloaddata?.search?.resultCount ?? 0) < 0 && attr === 'has_attachment') return false;
    if (isInternalSource && attr === 'filename') return false;
    if (!isInternalSource && !docSolrAsset?.dataAsset?.filename) return false;
    if (Array.isArray(value)) return value.length !== 0 && value.some((v: any) => !!v);
    return true;
  });
  const [getPreSignedURL, { data }] = useGetTemporaryS3FileAccessLazyQuery({
    onCompleted: () => {
      const fileAccess = data?.getTemporaryS3FileAccess?.[0];
      if (fileAccess?.status?.toLowerCase() === 'success') {
        downloadFile(fileAccess.preSignedUrl!, props.fileName);
      }
    },
    fetchPolicy: 'network-only',
  });
  return (
    <span
      className={isActionAvailable && !isInternalSource ? 'cursor-pointer' : 'cursor-not-allowed'}
      title={isActionAvailable && !isInternalSource ? 'Download attachment' : 'No Options are Available'}
      onClick={() => {
        if (trackApplicationFeature)
          trackApplicationFeature(
            SEARCH_APP_FEATURE_LIST.DOCUMENT_INTERACTIONS,
            DOCUMENT_INTERACTIONS_ACTIONS.MORE_ACTION_OPTIONS
          );
      }}
    >
      {!isInternalSource ? (
        <span>
          {[docSolrAsset?.dataAsset?.filename]?.map(url =>
            url ? (
              <Button
                variant={props.buttonText ? 'link' : 'link'}
                className={classNames(props.className, {
                  'w-100': props.isHideFileName && !props.buttonText,
                  'px-0 mt-1 ml-0 text-justify px-1': !props.isHideFileName,
                })}
                onClick={() => getPreSignedURL({ variables: { paths: [url] } })}
                style={{ boxShadow: 'none' }}
              >
                <FontAwesomeIcon icon={faDownload} className={props.buttonText ? 'ml-0 ' : ''} />
                {isMobile && <span className="ml-2 button-text">{props.buttonText}</span>}
              </Button>
            ) : null
          )}
        </span>
      ) : (
        ''
      )}
    </span>
  );
}

export default DirectDownloadButton;
