import { IAssetAttribute, ISelectedDefaultSources } from '@amgen/core';
import { ApolloError } from '@apollo/client';
import { createContext } from 'react';

import { ResultViewType } from '../../models';

export interface IPreferenceState {
  defaultFacets: IAssetAttribute[];
  selectedFacetConfig: IAssetAttribute[];
  selectedResultView: ResultViewType;
  selectedDefaultColumns: IAssetAttribute[];
  selectedDefaultDataSources: ISelectedDefaultSources[];
  refetchDataSources: () => void;
  refetchPreferences: () => void;
  dataSourceLoading: boolean;
  loading: boolean;
  error: ApolloError | undefined;
  isFavSourcesSearch: boolean;
  setIsFavSourcesSearch: (flag: boolean) => void;
  selectedExportFacets: IAssetAttribute[];
  createdCustomSource: string;
  setCreatedCustomSource: (value: string) => void;
  createdCustomDataSourceName: any;
  setCreatedCustomDataSourceName: (value: any) => void;
}

export const PreferenceContext = createContext<IPreferenceState>(null!);
