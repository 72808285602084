import { useGetDocumentFeedbacksQuery } from '@amgen/api';
import { Direction, IDataAsset, IDocumentFeedback, IFacetValue, useEnvironment } from '@amgen/core';
import { IGetDocumentFeedbacksQuery } from 'libs/api/src/lib/graphql/__generated__';
import React, { useState } from 'react';
import { Col } from 'react-bootstrap';

import './doc-rating-wrapper.scss';

import { DocFeedbackAddForm } from '../doc-feedback-add-form/doc-feedback-add-form';
import { DocFeedbackMetrics } from '../doc-feedback-metrics/doc-feedback-metrics';

/* eslint-disable-next-line */
export interface DocRatingWrapperProps {
  inPopOver?: boolean;
  asset?: IDataAsset;
  ratingFromButton?: number;
}

export const DocRatingWrapper = (props: DocRatingWrapperProps) => {
  const [docFeedbacks, setDocFeedbacks] = useState<IDocumentFeedback[]>([]);
  const [reviewPage, setReviewPage] = useState(0);
  const [reviewCount, setReviewCount] = useState(0);
  const [avgRating, setAvgRating] = useState(0);
  const [userRating, setUserRating] = useState<IDocumentFeedback>();
  const [feedbackMetrics, setFeedbackMetrics] = useState<IFacetValue[]>();
  const [hasNext, setHasNextPage] = useState(true);
  const PAGE_COUNT = 10;
  const handleFeedbackData = (data: IGetDocumentFeedbacksQuery) => {
    const feedbacks = data?.getDocumentFeedback?.assetFeedbacks;
    const feedbackMetrics = data?.getDocumentFeedback?.feedbackMetrics;
    if (feedbacks && feedbackMetrics) {
      if (!reviewPage) {
        const count = feedbackMetrics[0].values?.reduce((total, value) => total + (value?.count ?? 0), 0) ?? 0;
        setReviewCount(count);
        setUserRating(data?.getDocumentFeedback?.userAssetFeedback);
        setAvgRating(
          (feedbackMetrics[0].values?.reduce((total, value) => total + Number(value.value) * (value?.count ?? 0), 0) ??
            0) / count ?? 0
        );
        setFeedbackMetrics(feedbackMetrics[0].values);
      }

      reviewPage ? setDocFeedbacks([...docFeedbacks, ...feedbacks]) : setDocFeedbacks(feedbacks);

      if (feedbacks.length < 5) {
        setHasNextPage(false);
      }
    }
  };
  const { appName } = useEnvironment();
  const { loading, error, refetch } = useGetDocumentFeedbacksQuery({
    onCompleted: handleFeedbackData,
    variables: {
      appName,
      reviewPage,
      reviewCount: PAGE_COUNT,
      reviewStart: 0,
      sortField: 'rating',
      sortOrder: Direction.DESC,
      assetId: props.asset?.id!,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  return (
    <div className="asset-rating-container">
      <Col
        xs="12"
        md={props.inPopOver ? 12 : { order: 1, span: 12 }}
        className={props.inPopOver ? 'pt-1 px-2 pb-2' : ''}
      >
        {props.asset?.id && (
          <DocFeedbackAddForm
            inPopover={props.inPopOver}
            refetchFeedbackMetrics={() => {
              setReviewPage(0);
              setHasNextPage(true);
              refetch().then(({ data }) => handleFeedbackData(data));
            }}
            userRating={userRating}
            assetId={props.asset?.id}
            ratingFromButton={props.ratingFromButton}
          />
        )}
      </Col>
      <Col
        xs="12"
        md={props.inPopOver ? 12 : { order: 2, span: 12 }}
        className={props.inPopOver ? 'px-2  mt-4' : 'px-2 mt-5'}
      >
        {props.asset && (
          <DocFeedbackMetrics
            loading={loading}
            error={error}
            asset={props.asset}
            inPopover={props.inPopOver}
            avgRating={avgRating}
            totalReviewCount={reviewCount}
            feedbackMetrics={feedbackMetrics ?? []}
          />
        )}
      </Col>
      {/* {!props.inPopOver ? (
        <Col xs="12" md={{ order: 3, span: 10 }}>
          {props.asset?.id && (
            <DocFeedbackList
              loading={loading}
              pageCount={PAGE_COUNT}
              error={error}
              docFeedbacks={docFeedbacks}
              showMore={() => setReviewPage(reviewPage + 1)}
              hasMore={hasNext}
            />
          )}
        </Col>
      ) : null} */}
    </div>
  );
};

export default DocRatingWrapper;
