import { useDeletePreferenceMutation, useUpdateSavedSearchMutation } from '@amgen/api';
import { ISavedSearch, useEnvironment } from '@amgen/core';
import { AppContext, useToast } from '@amgen/shared';
import { removeSpecialChars } from '@amgen/utils';
import { format } from 'date-fns';
import { useContext } from 'react';

import { SUBSCRIPTION_TYPE, TOP_RESULT_COUNT } from '../model';

export const useSaveSubscribeItemMutation = () => {
  const [updateSavedSearch, { loading: updateLoading }] = useUpdateSavedSearchMutation();
  const { appName } = useEnvironment();
  const toast = useToast();
  const [deletePreference, { loading: deleteLoading }] = useDeletePreferenceMutation();
  const { currentSource } = useContext(AppContext);

  const todayString = format(new Date(), 'yyyy-MM-dd') + 'T00:00:00Z';

  const handleUpdate = (name: string, saveSearchItem: ISavedSearch, onEditSuccess: (name: string) => void) => {
    updateSavedSearch({
      variables: {
        appName,
        issubscribe: true,
        saveSearch: {
          name,
          id: saveSearchItem.id,
          query: saveSearchItem.query,
          filters: saveSearchItem.filters,
          subscrptnIsactive: saveSearchItem.subscrptnIsactive,
          subscrptnMailIds: saveSearchItem.subscrptnMailIds,
          subscrptnRecordCount: saveSearchItem.subscrptnRecordCount,
          subscrptnType: saveSearchItem.subscrptnType,
          subscrptnStartDate: saveSearchItem.subscrptnStartDate,
          subscrptnEndDate: saveSearchItem.subscrptnEndDate,
          sourceType: currentSource,
          userType: sessionStorage.getItem('employeeType') || '',
        },
      },
    })
      .then(() => {
        if (saveSearchItem.name) onEditSuccess(saveSearchItem.name);
        toast.success({
          message: `Your Saved search "${saveSearchItem.name}" is successfully renamed to "${name}"`,
          id: 'saved-search-item',
          timeout: 5000,
        });
      })
      .catch(err =>
        toast.danger({
          message: err?.graphQLErrors?.[0]?.exception?.message ?? 'Unable to update. Please try again later.',
          id: 'saved-search-item',
          timeout: 5000,
        })
      );
  };

  const handleDelete = (saveSearchItem: ISavedSearch, onDeleteSuccess: () => void) => {
    deletePreference({
      variables: { id: removeSpecialChars(saveSearchItem.id!), isSoft: false, applicationName: appName },
    })
      .then(() => {
        onDeleteSuccess();
        toast.success({
          message: `Saved search "${saveSearchItem.name}" deleted successfully.`,
          id: 'saved-search-item',
          timeout: 5000,
        });
      })
      .catch(err =>
        toast.danger({
          message:
            err?.graphQLErrors?.[0]?.exception?.message ??
            `Unable to delete "${saveSearchItem.name}". Please try again later.`,
          id: 'saved-search-item',
          timeout: 5000,
        })
      );
  };

  const handleUnsubscribe = (
    savedSearch: ISavedSearch,
    defaultEmailId: string,
    onEditSuccess: (name: string) => void
  ) => {
    updateSavedSearch({
      variables: {
        appName,
        issubscribe: true,
        saveSearch: {
          name: savedSearch.name,
          id: savedSearch.id,
          query: savedSearch.query,
          filters: savedSearch.filters,
          subscrptnIsactive: !savedSearch.subscrptnIsactive,
          subscrptnMailIds: savedSearch.subscrptnMailIds ?? (defaultEmailId ? [defaultEmailId] : []),
          subscrptnRecordCount: savedSearch.subscrptnRecordCount
            ? savedSearch.subscrptnRecordCount
            : TOP_RESULT_COUNT[0],
          subscrptnType: savedSearch.subscrptnType ?? SUBSCRIPTION_TYPE.Daily,
          subscrptnStartDate: savedSearch.subscrptnStartDate ?? todayString,
          subscrptnEndDate: savedSearch.subscrptnEndDate ?? undefined,
        },
      },
    })
      .then(() => {
        if (savedSearch.name) onEditSuccess(savedSearch.name);

        toast.success({
          message: `Save Search ${savedSearch.subscrptnIsactive ? 'unsubscribed' : 'subscribed'} successfully.`,
          id: 'saved-search-item',
          timeout: 5000,
        });
      })
      .catch(err =>
        toast.danger({
          message: err?.graphQLErrors?.[0]?.exception?.message ?? 'Unable to update. Please try again later.',
          id: 'saved-search-item',
          timeout: 5000,
        })
      );
  };
  return { handleUpdate, updateLoading, deleteLoading, handleDelete, handleUnsubscribe };
};
