import { IAssetAttribute } from '@amgen/core';
import { FacetContext, FiltersObject, useAppliedFilters } from '@amgen/shared-kmi';
import { useContext } from 'react';

export function usePersistentFilters() {
  const { persistentFilters } = useContext(FacetContext);

  // these filters are included for IRPC.
  const includedFilters = new FiltersObject(persistentFilters.INCLUDE).toQueryParam();

  // these filters are to be excluded for C3.
  const excludedFilters = new FiltersObject(persistentFilters.EXCLUDE).toQueryParam().map(f => `-(${f})`);

  return [...includedFilters, ...excludedFilters];
}

export function useAppliedFiltersWithPersistentValues(filtersObj?: FiltersObject) {
  const filters = useAppliedFilters(filtersObj);
  const [shouldUse, hierarchicalFilters] = useHierarchicalValues('doc_nav_prefix');
  const persistentFilters = usePersistentFilters();

  return (shouldUse ? hierarchicalFilters : filters)
    .filter(f => !!f && !f.includes('prefix_location'))
    .concat(persistentFilters);
}

/**
 * Removes parent values from applied filters. This ensures that more specific filters are preferred.
 *
 * eg: '0/A', '1/A/B', '2/A/B/C' are selected by user.
 * Since, '0/A' includes results from all its children, applying other two filters would not have any effect.
 * Thus, whenever a more specific filter is applied, the parents must be not be part of api call.
 */
function removeParentValue(values: Set<string>) {
  const valuesCopy = new Set(values);
  values.forEach(value => {
    const [depth, ...splits] = value.split('/');
    splits.pop();
    const finalArr = splits.map(function (element, index) {
      return index + '/' + splits.slice(0, index + 1).join('/');
    });
    //const parentValue = [+depth - 1, ...splits].join('/');
    finalArr.forEach(parentValue => {
      valuesCopy.delete(parentValue);
    });
  });

  return valuesCopy;
}

/**
 * A special case for hierarchical facets. This is an alternative to `useAppliedFiltersWithPersistentValues`.
 *
 * It ensures that hierarchical facets are sent to api correctly. This also returns a boolean value representing
 * if the modifed values hosuld be used.
 */
export function useHierarchicalValues(facetCategory: IAssetAttribute): [boolean, string[]] {
  const { appliedFilters } = useContext(FacetContext);
  const values = appliedFilters.filters?.[facetCategory];
  const hasValues = !!values && values.size > 0;
  const filters = hasValues
    ? appliedFilters
        .clone()
        .removeAll(facetCategory)
        .add([...removeParentValue(values!)], facetCategory)
    : appliedFilters.clone();

  return [hasValues, filters.toQueryParam()];
}
