import { useCallback, useContext } from 'react';

import { PaginationContext } from './context';

export const usePaginationConfig = () => useContext(PaginationContext);

export function usePreviousPages() {
  const ctx = useContext(PaginationContext);

  const canPreviousPages = ctx.activePageNo <= ctx.pageOptionsCount;

  const handler = useCallback(() => {
    const pageNo = ctx.activePageNo - ctx.pageOptionsCount;

    ctx.setStartPageNo(pageNo - ctx.pageOptionsCount > 0 ? ctx.startPageNo - ctx.pageOptionsCount : 1);
    ctx.setActivePageNo(pageNo);
    ctx.onPageSelect(pageNo);
  }, [ctx]);

  return [canPreviousPages, handler] as [boolean, () => void];
}

export function usePreviousPage() {
  const ctx = useContext(PaginationContext);

  const canPreviousPage = ctx.activePageNo === 1;

  const handler = useCallback(() => {
    const pageNo = ctx.activePageNo - 1;
    if (ctx.activePageNo === ctx.startPageNo) {
      ctx.setStartPageNo(pageNo - ctx.pageOptionsCount > 0 ? ctx.startPageNo - ctx.pageOptionsCount : 1);
    }
    ctx.setActivePageNo(pageNo);
    ctx.onPageSelect(pageNo);
  }, [ctx]);

  return [canPreviousPage, handler] as [boolean, () => void];
}

export function useFirstPage() {
  const ctx = useContext(PaginationContext);

  const canFirstPage = false;

  const handler = useCallback(() => {
    ctx.setActivePageNo(1);
    ctx.setStartPageNo(1);
    ctx.onPageSelect(1);
  }, [ctx]);

  return [canFirstPage, handler] as [boolean, () => void];
}

export function usePages() {
  const ctx = useContext(PaginationContext);

  const isActive = (page: number) => page === ctx.activePageNo;

  const handlePage = useCallback(
    (page: number) => {
            ctx.setActivePageNo(page);
      ctx.onPageSelect(page);
    },
    [ctx]
  );

  return { pages: ctx.pages, isActive, handlePage, activePageNo: ctx.activePageNo };
}

export function useLastPage() {
  const ctx = useContext(PaginationContext);

  const canLastPage = ctx.endPageNo < ctx.nPages;

  const handler = useCallback(() => {
    ctx.setActivePageNo(ctx.nPages);
    ctx.setStartPageNo(ctx.nPages - ctx.pageOptionsCount + 1);
    ctx.onPageSelect(ctx.nPages);
  }, [ctx]);

  return [canLastPage, ctx.nPages, handler] as [boolean, number, () => void];
}

export function useNextPage() {
  const ctx = useContext(PaginationContext);

  const canNextPage = ctx.activePageNo === ctx.nPages || ctx.nPages === 0;

  const handler = useCallback(() => {
    const pageNo = ctx.activePageNo + 1;
    if (ctx.activePageNo === ctx.endPageNo) {
      ctx.setStartPageNo(
        pageNo + ctx.pageOptionsCount <= ctx.nPages
          ? ctx.startPageNo + ctx.pageOptionsCount
          : ctx.nPages - ctx.pageOptionsCount + 1
      );
    }
    ctx.setActivePageNo(pageNo);
    ctx.onPageSelect(pageNo);
  }, [ctx]);

  return [canNextPage, handler] as [boolean, () => void];
}

export function useNextPages() {
  const ctx = useContext(PaginationContext);

  const canNextPages = Math.abs(ctx.activePageNo - ctx.nPages) < ctx.pageOptionsCount;

  const handler = useCallback(() => {
    const pageNo = ctx.activePageNo + ctx.pageOptionsCount;
    const startPage =
      pageNo + ctx.pageOptionsCount <= ctx.nPages
        ? ctx.startPageNo + ctx.pageOptionsCount
        : ctx.nPages - ctx.pageOptionsCount + 1;

    ctx.setStartPageNo(startPage);
    ctx.setActivePageNo(pageNo);
    ctx.onPageSelect(pageNo);
  }, [ctx]);

  return [canNextPages, handler] as [boolean, () => void];
}
