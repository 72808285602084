import { Direction, IDataCrawlRequest } from '@amgen/core';
import { IGD_ACTIONS, MatomoContext, SEARCH_APP_FEATURE_LIST, useWindowSizeListener } from '@amgen/shared';
import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useContext } from 'react';
import { HeaderGroup } from 'react-table';

import './igd-table-head.scss';

import { IDataCrawlSortableField, SORT_FIELD_MAP } from '../../config';
import { SortOrderObject } from '../../models/sort';

/* eslint-disable-next-line */
export interface IgdTableHeadProps {
  headerGroups: HeaderGroup<IDataCrawlRequest>[];
  sort: SortOrderObject;
  setSort: (sort: SortOrderObject) => void;
}

export function IgdTableHead(props: IgdTableHeadProps) {
  const { trackApplicationFeature } = useContext(MatomoContext);
  const isMobile = useWindowSizeListener();
  const getHeaderWidth = useCallback((id: string) => {
    if (id === 'sourcePath' || id === 'siteDescription' || id === 'requestDescription') {
      return { minWidth: '200px' };
    } else if (id === 'contentSource' || id === 'requesterName') {
      return { minWidth: '120px' };
    } else if (id === 'createdOn' || id === 'displayName') {
      return { minWidth: '150px' };
    } else {
      return {};
    }
  }, []);
  return (
    <>
      {props.headerGroups.map(headerGroup => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map(header => (
            <th
              className="position-sticky"
              {...header.getHeaderProps()}
              onClick={() => {
                if (header.id in SORT_FIELD_MAP) {
                  props.setSort(props.sort.toggleSort(SORT_FIELD_MAP[header.id as IDataCrawlSortableField]));
                  trackApplicationFeature(SEARCH_APP_FEATURE_LIST.IGD, IGD_ACTIONS.IGD_SORT);
                }
              }}
              title={header.id in SORT_FIELD_MAP ? 'Click to Sort' : ''}
              // className={header.id in SORT_FIELD_MAP ? 'cursor-pointer' : ''}
              style={isMobile ? getHeaderWidth(header.id) : {}}
            >
              <div className={header.id in SORT_FIELD_MAP ? 'd-flex w-100 cursor-pointer' : ''}>
                {header.render('Header')}
                <div className="sort-icon">
                  {header.id in SORT_FIELD_MAP &&
                    (props.sort.getDirection(SORT_FIELD_MAP[header.id as IDataCrawlSortableField]) ? (
                      props.sort.getDirection(SORT_FIELD_MAP[header.id as IDataCrawlSortableField]) ===
                      Direction.DESC ? (
                        <div>
                          <FontAwesomeIcon icon={faSortDown} title="desc" className="ml-1 ml-lg-2 text-grey" />
                        </div>
                      ) : (
                        <div>
                          <FontAwesomeIcon icon={faSortUp} title="asc" className="ml-1 ml-lg-2 text-grey" />
                        </div>
                      )
                    ) : (
                      <div>
                        <FontAwesomeIcon icon={faSort} title="Click to Sort" className="ml-1 ml-lg-2 text-grey" />
                      </div>
                    ))}
                </div>
              </div>
            </th>
          ))}
        </tr>
      ))}
    </>
  );
}

export default IgdTableHead;
