import { AssetContext } from '@amgen/shared';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DOMPurify from 'dompurify';
import React, { useContext } from 'react';

import './view-postal-response-button.scss';

import AssetActionsMenu from '../asset-actions-menu/asset-actions-menu';

/* eslint-disable-next-line */
export interface ViewPostalResponseButtonProps {}

export const ViewPostalResponseButton = (props: ViewPostalResponseButtonProps) => {
  const { docSolrAsset } = useContext(AssetContext);

  return (
    <AssetActionsMenu.Item
      actionType={'postal-link'}
      href={DOMPurify.sanitize(docSolrAsset.dataAsset?.linktopostalresponse)}
      target="_blank"
      rel="noopener noreferrer"
    >
      {`View Postal Response  `}
      <FontAwesomeIcon icon={faExternalLinkAlt} className="text-primary" />
    </AssetActionsMenu.Item>
  );
};

export default ViewPostalResponseButton;
