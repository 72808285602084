import { DEFAULT_SEARCH_QUERY, ITypeaheadSuggestion } from '@amgen/core';
import { DEFAULT_PLACEHOLDER } from '@amgen/utils';
import React, { useContext } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';

import './search-box-typeahead.scss';

import { SearchBoxContext } from '../../search-box-context-provider/search-box-context';
import SearchBoxTypeaheadMenu from '../search-box-typeahead-menu/search-box-typeahead-menu';
import { GetTypeaheadURL } from './get-typeahead-url';
import { useTypeaheadHooks } from './hooks';

/* eslint-disable-next-line */
export interface SearchBoxTypeaheadProps {}

export const SearchBoxTypeahead: React.FC<SearchBoxTypeaheadProps> = () => {
  const {
    keyUpdate,
    loading,
    dropdownOptions,
    placeholder,
    openSuggestionDrop,
    searchQuery,
    typeaheadRef,
    selectedFilter,
    callAPI,
  } = useContext(SearchBoxContext);

  const {
    handleBlur,
    handleFocus,
    handleInputChange,
    hideTyping,
    handleSearch,
    handleChange,
    handleKeyDown,
  } = useTypeaheadHooks();

  return (
    <>
      {callAPI && <GetTypeaheadURL></GetTypeaheadURL>}
      <Typeahead<ITypeaheadSuggestion>
        ref={typeaheadRef}
        key={keyUpdate} // key is used to update the internal state of async typeAhead in unconrolled version
        //https://medium.com/@albertogasparin/forcing-state-reset-on-a-react-component-by-using-the-key-prop-14b36cd7448e
        id="search-box"
        minLength={1}
        autoFocus={!!selectedFilter || openSuggestionDrop}
        isLoading={loading}
        options={dropdownOptions}
        labelKey={suggestion => suggestion.term!}
        placeholder={hideTyping ? DEFAULT_PLACEHOLDER : placeholder}
        // searchText="Loading..."
        defaultInputValue={!!searchQuery && searchQuery !== DEFAULT_SEARCH_QUERY ? searchQuery : ''}
        onBlur={handleBlur}
        onInputChange={handleInputChange}
        // onSearch={handleSearch}
        onChange={query => {
          handleChange(query);
        }}
        onKeyDown={handleKeyDown}
        emptyLabel={'No suggestions found'}
        onFocus={handleFocus}
        open={openSuggestionDrop}
        renderMenu={(results, menuProps) => <SearchBoxTypeaheadMenu results={results} menuProps={menuProps} />}
      />
    </>
  );
};

export default SearchBoxTypeahead;
