import { DeleteConfirmPanel, TimeAgoItem } from '@amgen/components';
import { IBookmark } from '@amgen/core';
import { AppContext } from '@amgen/shared';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import './bookmark-item.scss';

export interface BookmarkItemProps {
  bookmark: IBookmark;
  isDeletePanelShown: boolean;
  isLoading: boolean;
  onDeleteConfirm: () => void;
  onDeletePanelToggle: (show: boolean) => void;
}

export const BookmarkItem = (props: BookmarkItemProps) => {
  const params = new URLSearchParams();
  params.append('q', `id:"${props.bookmark?.assetId ?? ''}"`);
  const { setCustomSourceActive, setSelectedCustomSourceName, setCurrentCustomsources, setCurrentSource } = useContext(
    AppContext
  );
  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="mb-0 text-overflow-ellipsis">
          <Link
            to={{
              pathname: '/',
              search: params.toString(),
              state: { clearFilters: true, bookmarkClicked: true },
            }}
            title={props.bookmark.name}
            onClick={() => {
              setCustomSourceActive(false);
              setSelectedCustomSourceName('Custom Sources');
              setCurrentCustomsources?.([]);
              setCurrentSource('all');
            }}
          >
            {props.bookmark.name}
          </Link>
          <br />
          <small title={props.bookmark.assetId}>{props.bookmark.assetId}</small>
          <br />
          <small style={{ fontSize: 'small' }} className="text-grey" title={props.bookmark.timestamp}>
            {props.bookmark.timestamp ? <TimeAgoItem dateString={props.bookmark.timestamp} /> : null}
          </small>
        </h2>

        <span
          className="ml-3 cursor-pointer"
          style={{ fontSize: 'x-large' }}
          onClick={() => props.onDeletePanelToggle(true)}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </span>
      </div>

      {props.isDeletePanelShown && (
        <DeleteConfirmPanel
          onCancel={() => props.onDeletePanelToggle(false)}
          onDelete={props.onDeleteConfirm}
          message={`Are you sure you want to delete this bookmark?`}
          isLoading={props.isLoading}
        />
      )}
    </>
  );
};

export default BookmarkItem;
