export interface IPages {
  RESULTS_PAGE: string;
  DASHBOARD: string;
  BOOKMARKS: string;
  SETTINGS: string;
  FEEDBACKS: string;
  ALL_REQUESTS: string;
  NEW_REQUEST: string;
  EXISTING_SITES: string;
  ADMIN_PAGE: string;
  DOCUMENT_DETAILS: string;
  HOME: string;
  CLINICAL_DETAIL: string;
  TOPICS_PAGE: string;
}

export const PAGES: IPages = {
  RESULTS_PAGE: 'Result',
  DASHBOARD: 'Interactive Filters',
  BOOKMARKS: 'Bookmarks',
  SETTINGS: 'Settings',
  FEEDBACKS: 'Feedbacks',
  ALL_REQUESTS: 'My Requests',
  NEW_REQUEST: 'New Request',
  EXISTING_SITES: 'Existing Sites',
  ADMIN_PAGE: 'Admin Page',
  DOCUMENT_DETAILS: 'Documents Details',
  HOME: 'Home',
  CLINICAL_DETAIL: 'Clinical Detail',
  TOPICS_PAGE: 'Topics Page',
};
