import React from 'react';
import { Modal } from 'react-bootstrap';

import './body.scss';

/* eslint-disable-next-line */
export interface BodyProps {}

export const Body: React.FC<BodyProps> = ({ children }) => {
  return <Modal.Body className="pt-0">{children}</Modal.Body>;
};

export default Body;
