import * as Types from '@amgen/core';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type ISuggestionFragment = Pick<Types.ISuggestion, 'term'>;

export type ITypeaheadQueryVariables = Types.Exact<{
  appName?: Types.Maybe<Types.ApplicationName>;
  query: Types.Scalars['String'];
  count?: Types.Maybe<Types.Scalars['Int']>;
  solrHandler?: Types.Maybe<Types.Scalars['String']>;
  userType?: Types.Maybe<Types.Scalars['String']>;
}>;

export type ITypeaheadQuery = { typeahead?: Types.Maybe<Array<Types.Maybe<ISuggestionFragment>>> };

export const SuggestionFragmentDoc = gql`
  fragment Suggestion on Suggestion {
    term
  }
`;
export const TypeaheadDocument = gql`
  query Typeahead(
    $appName: ApplicationName
    $query: String!
    $count: Int = 10
    $solrHandler: String
    $userType: String
  ) {
    typeahead(applicationName: $appName, query: $query, count: $count, solrHandler: $solrHandler, userType: $userType) {
      ...Suggestion
    }
  }
  ${SuggestionFragmentDoc}
`;
export function useTypeaheadQuery(baseOptions: Apollo.QueryHookOptions<ITypeaheadQuery, ITypeaheadQueryVariables>) {
  return Apollo.useQuery<ITypeaheadQuery, ITypeaheadQueryVariables>(TypeaheadDocument, baseOptions);
}
export function useTypeaheadLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ITypeaheadQuery, ITypeaheadQueryVariables>
) {
  return Apollo.useLazyQuery<ITypeaheadQuery, ITypeaheadQueryVariables>(TypeaheadDocument, baseOptions);
}
export type TypeaheadQueryHookResult = ReturnType<typeof useTypeaheadQuery>;
export type TypeaheadLazyQueryHookResult = ReturnType<typeof useTypeaheadLazyQuery>;
export type TypeaheadQueryResult = Apollo.QueryResult<ITypeaheadQuery, ITypeaheadQueryVariables>;
