import { ApplicationName } from '@amgen/core';
import {
  AppContext,
  FacetContext,
  SearchContext,
  useCleanSearchQuery,
  useSearchQueryValue,
  useWindowSizeListener,
} from '@amgen/shared';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import './recommended-results.scss';

import { IRecommendedResultProps } from '../../models';
import { AppSearchCard } from '../app-search-result/app-search-card';
import UserFeedback from '../search-user-feedback/user-feedback';
import { useGetBinaryFeedbackQuery } from '@amgen/api';

const RecommendedResults: React.FC<IRecommendedResultProps> = ({ results, pageNo, hideUserFeedback }) => {
  const {
    eventData,
    // appData,
    // deepLinksData,
    isAllSelected,
    selectedTopFilter,
    searchFeedbackData,
    isUserFeedbackCompleted,
    recommendedResultCount,
    setRecommendedResultCount,
    setRecommendedTopData,
    consolidatedData,
    setIsApplicationGenerativeAIVariable,
    setGenAIDeeplinkData,
    setGenAISpotlightData,
    appData,
  } = useContext(SearchContext);
  const searchTerm = useSearchQueryValue();
  // const { results: spotLightResults, loading } = useSpotlightResults();
  const { isCustomSourceActive, selectedResultTab } = useContext(AppContext);
  const { appliedFilters, setAppliedFilters } = useContext(FacetContext);
  const [showUserFeedback, setShowUserFeeback] = useState(true);

  const applicationName: ApplicationName = sessionStorage.getItem('appName')
    ? ApplicationName[sessionStorage.getItem('appName')!]
    : ApplicationName.EEA;
  const getAllLinksData = consolidatedData?.getAllLinks[0]?.docsData;
  const allLinksDataWithoutApps = (getAllLinksData && getAllLinksData.filter(item => item.dataType !== 'apps')) || [];
  const filteredDeepLinksData = (getAllLinksData && getAllLinksData.filter(item => item.dataType === 'toplinks')) || [];
  const eventSectionData = eventData?.getSearchObjects?.docs;
  const appSectionData = (getAllLinksData && getAllLinksData.filter(item => item.dataType === 'apps')) || [];
  const filteredArray = results?.slice(0, 10 - recommendedResultCount);
  const spotLightResults = (getAllLinksData && getAllLinksData.filter(item => item.dataType === 'spotlight')) || [];
  const ModifiedhighScoreAppsData =
    appSectionData &&
    appSectionData.filter(appData => !filteredDeepLinksData.some(linkData => appData.id === linkData.id));

  const abTestingGroup = JSON.parse(sessionStorage.getItem('abTestingGroup') as string);
  // need to check what to use inplace of the below line
  // const isResponseFromKeywordAppData = appData?.getSearchObjects?.isResponseFromKeyword;
  const appDataABUSER = appData?.getSearchObjectsApps?.docs;
  const appsDataWithoutFilter = appSectionData;
  const cleanQuery = useCleanSearchQuery();
  const isMobile = useWindowSizeListener();
  const location = useLocation();
  const history = useHistory();
  const modifiedAllLinksData = [...ModifiedhighScoreAppsData, ...allLinksDataWithoutApps];
  const idArray: any = [];
  const idResult = abTestingGroup && appDataABUSER?.length ? appDataABUSER?.map(res => idArray.push(res?.id)) : [];

  const { data: ratingData, refetch } = useGetBinaryFeedbackQuery({
    variables: {
      appName: applicationName,
      searchTerm: cleanQuery,
      assetId: idArray ? idArray : ['result'],
    },
    skip: !abTestingGroup,
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    refetch();
  }, [refetch]);
  useEffect(() => {
    if (ModifiedhighScoreAppsData && filteredDeepLinksData && eventData?.getSearchObjects?.docs && spotLightResults) {
      let count = 0;
      if (applicationName !== 'OpsKM' && applicationName !== 'Catalyst' && appliedFilters?.count === 0) {
        count =
          ModifiedhighScoreAppsData?.length +
          filteredDeepLinksData?.length +
          eventData?.getSearchObjects?.docs?.length +
          spotLightResults?.length;
      } else {
        count = 0;
      }

      setRecommendedResultCount(count);
    }
    if (abTestingGroup) {
      setRecommendedResultCount(appDataABUSER?.length);
    }
  }, [
    ModifiedhighScoreAppsData,
    filteredDeepLinksData,
    eventData?.getSearchObjects?.docs,
    spotLightResults,
    appliedFilters?.count,
    selectedResultTab,
    appDataABUSER,
  ]);

  useEffect(() => {
    if (isUserFeedbackCompleted === false) {
      setShowUserFeeback(true);
    } else {
      setShowUserFeeback(false);
    }
  }, [selectedResultTab]);

  useEffect(() => {
    const newState = location.state as any;
    if (newState?.groupName == 'Contract Search') {
      history.replace({ ...location, state: {} });
      setAppliedFilters(appliedFilters.clear());
    }
  }, []);

  const modifyDeeplinkContentData = item => {
    const subDeeplinkData = item.toplinks
      .map(i => `Title:${i.title}\nContent:${i.content}`)
      .flat(2)
      .join('/n/n');

    const combinedContentData = item.content.flat(2).concat(subDeeplinkData).join('/n/n');
    const modifiedItem = Object.assign({}, item, {
      content: [combinedContentData],
    });
    return modifiedItem;
  };

  useEffect(() => {
    if (modifiedAllLinksData && modifiedAllLinksData.length > 0) {
      const deeplinkDataWithSubLink = modifiedAllLinksData.map(i =>
        i.is_deeplink === true ? modifyDeeplinkContentData(i) : i
      );
      const combinedTopData = [...deeplinkDataWithSubLink];
      setRecommendedTopData([...combinedTopData]);
      const appIsResponseFromKeyword =
        appSectionData.length > 0 && appSectionData.filter(item => item.isResponseFromKeyword === true);
      setIsApplicationGenerativeAIVariable((appIsResponseFromKeyword as any[]).length > 0 ? true : false);
      setGenAIDeeplinkData([...filteredDeepLinksData]);
    }
  }, [
    searchTerm,
    JSON.stringify(appsDataWithoutFilter),
    JSON.stringify(filteredDeepLinksData),
    JSON.stringify(spotLightResults),
  ]);

  const exceuteConditions = () => {
    if (
      !isCustomSourceActive &&
      searchTerm !== '*:*' &&
      selectedTopFilter === 'All' &&
      appliedFilters?.count === 0 &&
      applicationName !== 'DVIEW' &&
      applicationName !== 'OpsKM' &&
      applicationName !== 'Catalyst'
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <ol className="list-unstyled">
      <div className="mt-3">
        {exceuteConditions() && modifiedAllLinksData.length > 0 ? (
          modifiedAllLinksData?.map((item, index) => {
            return <AppSearchCard appData={item} type={item?.dataType} docPosition={index + 1} />;
          })
        ) : (
          <> </>
        )}

        {exceuteConditions() && selectedResultTab === 'All Results' && abTestingGroup ? (
          appDataABUSER?.map((item, index) => {
            return (
              <AppSearchCard
                appData={item}
                type="apps"
                docPosition={index + 1}
                applicationName={applicationName}
                ratingData={ratingData}
                refetch={refetch}
              />
            );
          })
        ) : (
          <> </>
        )}
        {/* {exceuteConditions() && ModifiedhighScoreAppsData.length > 0 ? (
          ModifiedhighScoreAppsData.map(item => {
            return <AppSearchCard appData={item} type="apps" />;
          })
        ) : (
          <></>
        )}

        {exceuteConditions() && filteredDeepLinksData.length > 0 ? (
          filteredDeepLinksData.map(item => {
            return <AppSearchCard appData={item} type="toplinks" />;
          })
        ) : (
          <></>
        )}

        {exceuteConditions() && spotLightResults ? (
          spotLightResults.map(item => {
            return <AppSearchCard appData={item} type="spotlight" />;
          })
        ) : (
          <></>
        )} */}

        {exceuteConditions() && eventData && eventSectionData.length > 0 ? (
          // eventSectionData.map(item => {
          //   return <AppSearchCard appData={item} type="events" />;    Neha - Commented this code to show 2 Events side by side
          // })

          <AppSearchCard appData={eventSectionData} type="events" />
        ) : (
          <></>
        )}

        {filteredArray?.map((item, index) => {
          return <AppSearchCard appData={item} type="all" docPosition={modifiedAllLinksData?.length + index + 1} />;
        })}

        {searchTerm !== '*:*' &&
        (searchFeedbackData?.getQueryFeedback?.already_reported === false ||
          searchFeedbackData?.getQueryFeedback?.days_from_last_feedback > 30) &&
        showUserFeedback &&
        !hideUserFeedback ? (
          <UserFeedback assetId={''} value={undefined} />
        ) : (
          <></>
        )}
      </div>
    </ol>
  );
};

export default RecommendedResults;
