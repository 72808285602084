import { IDestinationsData } from '@amgen/core';
import React from 'react';
import { TableInstance } from 'react-table';

import './suggested-app-table-body.module.scss';

/* eslint-disable-next-line */
export interface SuggestedAppTableBodyProps {
  loading: boolean;
  table: TableInstance<IDestinationsData>;
}

export function SuggestedAppTableBody(props: SuggestedAppTableBodyProps) {
  return (
    <tbody {...props.table.getTableBodyProps()}>
      {props.loading
        ? [1, 2, 3, 4, 5].map(x => (
            <tr key={`loader-row-${x}`}>
              {props.table.headerGroups[0].headers.map((cell, i) => (
                <td key={i} className="p-4">
                  <div className="skeleton rounded-pill" style={{ height: 20 }}></div>
                </td>
              ))}
            </tr>
          ))
        : props.table.rows.map(row => {
            props.table.prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()} className="text-break" style={{ maxWidth: 400 }}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
    </tbody>
  );
}

export default SuggestedAppTableBody;
