import { IDestinationsData, NewDestinationData, useEnvironment } from '@amgen/core';
import { useOktaAuth } from '@okta/okta-react';
import { useCallback, useState } from 'react';

// import { useToast } from '@amgen/shared';
const ADD_DESTINATIONS_REQUEST = `
mutation AddDestinations($appName: ApplicationName!, $destinationsData: DestinationsDataInput) {addDestinations(applicationName: $appName,destinationsData: $destinationsData) { id }}
`;
const UPDATE_DESTINATIONS_ReQUEST = `
mutation updateDestinations($appName: ApplicationName!, $destinationsData: DestinationsDataInput) {updateDestinations(applicationName: $appName,destinationsData: $destinationsData) { id }}

`;
type DestinationsDataReturnType = [
  (formValue: NewDestinationData, file?: File) => Promise<IDestinationsData | null | undefined>,
  { loading: boolean; error: string }
];

export const useAddDestionationsRequestMutation = (mode: string): DestinationsDataReturnType => {
  // Using oktaAuth directly here. as this is a fetch api call and `useAuth` doesn't expose token by design.
  const { authState } = useOktaAuth();
  const { envGraphqlUrl } = useEnvironment();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>('');
  // const toast = useToast();

  const mutate = useCallback(
    async (formValue: NewDestinationData) => {
      const { fileDetails, ...values } = formValue;
      const variables = {
        ...values,
        fileDetails: fileDetails ? { filename: fileDetails.name } : undefined,
      };

      const formData = new FormData();
      if (fileDetails) {
        formData.set(fileDetails.name, fileDetails);
      }
      formData.set('variables', JSON.stringify({ appName: 'EEA', destinationsData: variables }, null, 2));
      formData.set('query', mode === 'add' ? ADD_DESTINATIONS_REQUEST : UPDATE_DESTINATIONS_ReQUEST);

      setLoading(true);

      try {
        const resp = await fetch(envGraphqlUrl, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${authState.accessToken?.accessToken}`,
          },
          body: formData,
        });
        const data = await resp.json();
        if (data.error || data.errors) {
          const err = new Error('Request Failed');
          err.message = data.errors[0].message;
          throw err;
        }
        return data;
      } catch (err: any) {
        setError('Network Error');
        throw new Error(err.message || 'Network Error');
      } finally {
        setLoading(false);
      }
    },
    [envGraphqlUrl, authState.accessToken?.accessToken]
  );

  return [mutate, { loading, error }];
};
