import { useGetSurveysLazyQuery } from '@amgen/api';
import { LoaderErrorContainer } from '@amgen/components';
import { DataSource, DEFAULT_SEARCH_QUERY, useEnvironment } from '@amgen/core';
import { AssetContext, FiltersObject, useAppliedFilters } from '@amgen/shared';
import React, { useContext } from 'react';

import './view-qa-button.scss';

import AssetActionsMenu from '../asset-actions-menu/asset-actions-menu';

/* eslint-disable-next-line */
export interface ViewQaButtonProps {}

export const ViewQaButton: React.FC<ViewQaButtonProps> = () => {
  const { appName } = useEnvironment();

  const { docSolrAsset } = useContext(AssetContext);

  const filters = useAppliedFilters(
    new FiltersObject()
      .add(DataSource.ALIGN_SURVEY, 'content_source')
      .add(docSolrAsset.dataAsset?.account_name!, 'account_name')
      .add(docSolrAsset.dataAsset?.survey_name!, 'survey_name')
  );

  const [loadQuestionAndAnswers, { loading, data, error }] = useGetSurveysLazyQuery({
    variables: {
      query: DEFAULT_SEARCH_QUERY,
      filters,
      start: 0,
      page: 0,
      count: 10,
      fieldName: 'survey_name', // very specific to this query
      groupLimit: -1, // very specific to this page
      appName,
    },
  });

  return (
    <AssetActionsMenu.Item actionType={'inquiry-btn'}>
      <AssetActionsMenu.Item.Name onClick={() => loadQuestionAndAnswers()}>{`View Q&A`}</AssetActionsMenu.Item.Name>
      <AssetActionsMenu.Item.Popup>
        <AssetActionsMenu.Item.Popup.Heading />
        <AssetActionsMenu.Item.Popup.Body>
          <h3 className="bg-grey px-1">Q&A</h3>
          <LoaderErrorContainer errors={error ? [error] : []} loading={loading} reload={loadQuestionAndAnswers}>
            <ol>
              {data?.groupByField?.docs
                ?.slice()
                ?.sort((docA, docB) => (docA.dataAsset?.question_order ?? 0) - (docB.dataAsset?.question_order ?? 0))
                ?.map(doc => (
                  <li className="mb-1" key={doc.dataAsset?.id!}>
                    <h4 className="mb-0" style={{ fontWeight: 'bold' }}>
                      {doc.dataAsset?.question_text}
                    </h4>
                    <h5 style={{ fontWeight: 'normal' }}>{doc.dataAsset?.question_response}</h5>
                  </li>
                ))}
            </ol>
          </LoaderErrorContainer>
        </AssetActionsMenu.Item.Popup.Body>
      </AssetActionsMenu.Item.Popup>
    </AssetActionsMenu.Item>
  );
};

export default ViewQaButton;
