import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

export const faInfoCircle: IconDefinition = {
  prefix: 'far',
  iconName: 'info-circle',
  icon: [
    24,
    24,
    [],
    'f0000',
    'M12 1.92C13.9936 1.92 15.9425 2.51118 17.6002 3.61879C19.2578 4.72639 20.5498 6.30067 21.3127 8.14255C22.0756 9.98443 22.2753 12.0112 21.8863 13.9665C21.4974 15.9218 20.5374 17.7179 19.1276 19.1276C17.7179 20.5374 15.9218 21.4974 13.9665 21.8863C12.0112 22.2753 9.98444 22.0756 8.14256 21.3127C6.30068 20.5498 4.7264 19.2578 3.61879 17.6001C2.51119 15.9425 1.92001 13.9936 1.92001 12C1.92001 9.32662 2.982 6.76273 4.87237 4.87236C6.76274 2.982 9.32662 1.92 12 1.92ZM12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21509 0.913451 7.4078C0.00519939 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.8071 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0866C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2349 1.26428 15.1826 0 12 0V0Z M12 1.875C17.5604 1.875 22.125 6.37852 22.125 12C22.125 17.5918 17.5964 22.125 12 22.125C6.41044 22.125 1.875 17.5987 1.875 12C1.875 6.41236 6.40322 1.875 12 1.875ZM12 0.375C5.58014 0.375 0.375 5.58202 0.375 12C0.375 18.4217 5.58014 23.625 12 23.625C18.4199 23.625 23.625 18.4217 23.625 12C23.625 5.58202 18.4199 0.375 12 0.375ZM10.3125 16.5H10.875V10.875H10.3125C10.0019 10.875 9.75 10.6231 9.75 10.3125V9.9375C9.75 9.62686 10.0019 9.375 10.3125 9.375H12.5625C12.8731 9.375 13.125 9.62686 13.125 9.9375V16.5H13.6875C13.9981 16.5 14.25 16.7519 14.25 17.0625V17.4375C14.25 17.7481 13.9981 18 13.6875 18H10.3125C10.0019 18 9.75 17.7481 9.75 17.4375V17.0625C9.75 16.7519 10.0019 16.5 10.3125 16.5ZM12 5.25C11.1716 5.25 10.5 5.92158 10.5 6.75C10.5 7.57842 11.1716 8.25 12 8.25C12.8284 8.25 13.5 7.57842 13.5 6.75C13.5 5.92158 12.8284 5.25 12 5.25Z',
  ],
};
