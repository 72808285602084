import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { ReactNode, useEffect, useState } from 'react';
import { Col, Dropdown, Row, Spinner } from 'react-bootstrap';

import './accordion-container.scss';

export interface AccordionContainerProps {
  title: ReactNode;
  onValueToggle: (val: string) => void;
  onClear?: () => void;
  isLoading?: boolean;
  isOpen?: boolean;
  closeAfterSelection?: boolean;
  isAddFilter?: boolean;
  isExpand?: boolean;
}

export const AccordionContainer: React.FC<AccordionContainerProps> = props => {
  const [show, setShow] = useState(props.isExpand);

  useEffect(() => {
    setShow(props.isExpand);
  }, [props.isExpand]);

  return (
    <Row noGutters>
      <Col xs="12" className={classNames('bg-light shadow-sm border', { 'border-bottom-0': !props.isAddFilter })}>
        <Dropdown
          className="w-100 cursor-pointer"
          onSelect={(value, _) => props.onValueToggle(value ?? '')}
          alignRight
          show={show}
        >
          <div
            id="accordian-dropdown"
            className={classNames('d-flex justify-content-between align-items-center w-100 p-3 cursor-pointer', {
              'bg-light-blue ': props.isAddFilter,
            })}
            onClick={() => setShow(!show)}
          >
            <span
              title={props.isAddFilter ? 'Include additional criteria for optimizing search results' : ''}
              className={classNames(
                'custom-font-title',
                {
                  'text-primary  custom-font-title': props.isAddFilter,
                },
                props.isAddFilter ? 'font-weight-bold' : ''
              )}
            >
              {props.title}
            </span>
            <span>
              {props.isLoading ? (
                <Spinner className="text-primary" animation="border" size="sm" />
              ) : (
                <FontAwesomeIcon icon={show ? faMinus : faPlus} />
              )}
            </span>
          </div>
          {show ? <div className="bg-white py-2">{props.children}</div> : null}
        </Dropdown>
      </Col>
    </Row>
  );
};

export default AccordionContainer;
