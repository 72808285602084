export const convertBlobToCSV = ([blob, itemCount]: [any, number], fileTitle?: string) => {
  const exportFileName = fileTitle ? fileTitle + '.csv' : 'export.csv';

  if ((navigator as any)?.msSaveBlob) {
    // IE 10+
    (navigator as any)?.msSaveBlob(blob, exportFileName);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', exportFileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
