import { RequestStatusType } from '@amgen/core';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Badge, Nav } from 'react-bootstrap';

import './request-status-tab.scss';

/* eslint-disable-next-line */
export interface RequestStatusTabProps {
  className?: string;
  status: RequestStatusType;
  loading: boolean;
  onChange: (status: RequestStatusType) => void;
  resultCount?: number;
}

export function RequestStatusTab(props: RequestStatusTabProps) {
  const REQUEST_TABS = Object.values(RequestStatusType);

  return (
    <Nav variant="pills" defaultActiveKey={props.status} className={classNames('request-status-nav', props.className)}>
      {REQUEST_TABS.map((option, i) => (
        <Nav.Item
          key={option}
          className={props.status === option ? 'active' : ''}
          onClick={() => props.onChange(REQUEST_TABS[i])}
        >
          <Nav.Link eventKey={option} className="d-flex align-items-center text-rich-green px-1 px-lg-2">
            <span>{option}</span>
            {props.status === option && !props.loading ? (
              <Badge variant="light" className="ml-2">
                {props.resultCount}
              </Badge>
            ) : null}
          </Nav.Link>
        </Nav.Item>
      ))}
    </Nav>
  );
}

export default RequestStatusTab;
