import { IDataAssetFragmentCopy } from '@amgen/api';
import { IBookmarkDetails, IClusterData } from '@amgen/core';
import { createContext } from 'react';

export interface IClusterSearchState {
  clusters?: IClusterData[];
  resultCount?: number;
  selectedCluster: IClusterData | null;
  setSelectedCluster: (cluster: IClusterData | null) => void;
  selectedClusterResults?: IResult[];
  selectedClusterResultsLoading?: boolean;
  selectedResult: IResult | null;
  setSelectedResult: (result: IResult | null) => void;
}

export interface IResult {
  dataAsset?: IDataAssetFragmentCopy | undefined;
  personalizationDetails?: {
    bookmarkDetails?: Pick<IBookmarkDetails, 'isBookmarked' | 'bookmarkId'> | undefined;
  };
}

export const ClusterSearchContext = createContext<IClusterSearchState>(null!);
