import { useCrawlExportQuery } from '@amgen/api';
import { DEFAULT_SEARCH_QUERY, PageType, RequestStatusType } from '@amgen/core';
import { exportCSVFile } from '@amgen/feature-csv-export';
import { IGD_ACTIONS, MatomoContext, SEARCH_APP_FEATURE_LIST, useToast } from '@amgen/shared';
import { Form, Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { Button, Col, Spinner } from 'react-bootstrap';

import './export-requests-form.scss';

import { IGDFiltersObject } from '../../../models/igd-facet-object';
import ExportRequestsFieldInput from './export-requests-field-input/export-requests-field-input';
import {
   ADMIN_EXPORT_FIELDS,
   CRAWL_FIELD_NAMES,
   IExportRequestFormValue,
   MY_EXISTING_SITES_EXPORT_FIELDS,
   MY_REQ_EXPORT_FIELDS,
   validate,
} from './models';
import RequestStatusInput from './request-status-input/request-status-input';

export interface ExportRequestsFormProps {
  onCancel: () => void;
  pageType: PageType;
  page?: string;
}

export const ExportRequestsForm = (props: ExportRequestsFormProps) => {
  const getRequests = useCrawlExportQuery();
  const user = JSON.parse(sessionStorage.user);
  const toast = useToast();
  const { trackApplicationFeature } = useContext(MatomoContext);
  const [allowSubmit, setAllowSubmit] = useState(true);
  const [selectedValues, setSelectedValues] = useState([]);
  const exportFileName = () =>
    props.pageType === PageType.IGD_MY_REQUEST ? `My Requests (${user.username})` : `All Requests`;

  const exportFields =
    props.pageType === PageType.IGD_MY_REQUEST
      ? MY_REQ_EXPORT_FIELDS
      : props.pageType === PageType.IGD_EXISTING_SITES
      ? MY_EXISTING_SITES_EXPORT_FIELDS
      : ADMIN_EXPORT_FIELDS;
  return (
    <Formik<IExportRequestFormValue>
      initialValues={{
        fields: exportFields,
        status: props.pageType === PageType.IGD_EXISTING_SITES ? RequestStatusType.APPROVED : RequestStatusType.PENDING,
      }}
      onSubmit={async values => {
        let exportedFields = [] as any;
        const index = values.fields.length;
        if (values.fields[0].includes('selectAll')) {
          exportedFields = exportFields.filter(x => values.fields.indexOf(x) !== -1);
        } else {
          exportedFields = exportFields.filter(x => values.fields.indexOf(x) === -1);
        }

        const filters = new IGDFiltersObject().add(values.status, 'request_status').toQueryParam();
        const data = await getRequests({
          type: props.pageType,
          filters,
          query: DEFAULT_SEARCH_QUERY,
          count: 5000,
        });

        const exportData = data?.requests?.map(req =>
          exportedFields.length > 0
            ? exportedFields.reduce(
                (obj, field) => ({
                  ...obj,
                  [CRAWL_FIELD_NAMES[field]]:
                    typeof req[field] === 'boolean' ? (req[field] ? 'Yes' : 'No') : req[field],
                }),
                {}
              )
            : values.fields.reduce(
                (obj, field) => ({
                  ...obj,
                  [CRAWL_FIELD_NAMES[field]]:
                    typeof req[field] === 'boolean' ? (req[field] ? 'Yes' : 'No') : req[field],
                }),
                {}
              )
        );
        if (exportData?.length) {
          trackApplicationFeature(
            SEARCH_APP_FEATURE_LIST.IGD,
            props.pageType === PageType.IGD_MY_REQUEST ? IGD_ACTIONS.IGD_EXPORT_REQUEST : IGD_ACTIONS.IGD_EXPORT_ADMIN,
            exportData?.length - 1
          );
          await exportCSVFile(exportData ?? [], exportFileName());
        } else {
          toast.warning({
            message: 'No requests found at present to export, Please try again after sometime.',
            timeout: 5000,
          });
        }
      }}
      validate={validate}
    >
      {({ isSubmitting }) => (
        <Form>
          {props.pageType !== PageType.IGD_EXISTING_SITES ? (
            <RequestStatusInput pageType={props.pageType} setAllowSubmit={setAllowSubmit} />
          ) : (
            ''
          )}

          <ExportRequestsFieldInput exportFields={exportFields} setAllowSubmit={setAllowSubmit} />

          <Col xs="12" className="text-center">
            <Button variant="primary" type="submit" className="px-lg-5 mr-3" disabled={isSubmitting || !allowSubmit}>
              {isSubmitting ? <Spinner animation="border" size="sm" className="text-white" /> : 'Export'}
            </Button>
            <Button
              variant="outline-secondary"
              type="button"
              className="px-lg-5"
              onClick={props.onCancel}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
          </Col>
        </Form>
      )}
    </Formik>
  );
};

export default ExportRequestsForm;
