import * as Types from '@amgen/core';

import { IDataAssetFragment } from './search';
import { gql } from '@apollo/client';
import { DataAssetFragmentDoc } from './search';
import * as Apollo from '@apollo/client';
export type ISearchWithOpenNlpQueryVariables = Types.Exact<{
  applicationName?: Types.Maybe<Types.ApplicationName>;
  query: Types.Scalars['String'];
  filters?: Types.Maybe<Array<Types.Maybe<Types.Scalars['String']>> | Types.Maybe<Types.Scalars['String']>>;
  start?: Types.Maybe<Types.Scalars['Int']>;
  page?: Types.Maybe<Types.Scalars['Int']>;
  count?: Types.Maybe<Types.Scalars['Int']>;
  sort?: Types.Maybe<Types.SortField>;
  order?: Types.Maybe<Types.Direction>;
  addPersonalizationDetails?: Types.Maybe<Types.Scalars['Boolean']>;
  requestHandler?: Types.Maybe<Types.SolrRequestHandler>;
  solrHandler?: Types.Maybe<Types.Scalars['String']>;
  noShortContent?: Types.Maybe<Types.Scalars['Boolean']>;
  noSpotlight?: Types.Maybe<Types.Scalars['Boolean']>;
  includeDocs: Types.Scalars['Boolean'];
}>;

export type ISearchWithOpenNlpQuery = {
  searchWithOpenNLP?: Types.Maybe<
    Types.MakeOptional<Pick<Types.IDataAssetCollection, 'QTime'>, 'QTime'> & {
      docs?: Types.Maybe<
        Array<
          Types.Maybe<{
            dataAsset?: Types.Maybe<IDataAssetFragment>;
            personalizationDetails?: Types.Maybe<{
              bookmarkDetails?: Types.Maybe<Pick<Types.IBookmarkDetails, 'isBookmarked' | 'bookmarkId'>>;
            }>;
          }>
        >
      >;
    }
  >;
};

export const SearchWithOpenNlpDocument = gql`
  query SearchWithOpenNLP(
    $applicationName: ApplicationName = EEA
    $query: String!
    $filters: [String]
    $start: Int = 0
    $page: Int
    $count: Int
    $sort: SortField
    $order: Direction
    $addPersonalizationDetails: Boolean
    $requestHandler: SolrRequestHandler
    $solrHandler: String = "/search-opennlp-select"
    $noShortContent: Boolean = false
    $noSpotlight: Boolean = true
    $includeDocs: Boolean!
  ) {
    searchWithOpenNLP(
      applicationName: $applicationName
      query: $query
      filters: $filters
      start: $start
      page: $page
      count: $count
      sort: $sort
      order: $order
      addPersonalizationDetails: $addPersonalizationDetails
      requestHandler: $requestHandler
      solrHandler: $solrHandler
      noShortContent: $noShortContent
      noSpotlight: $noSpotlight
    ) {
      QTime @include(if: $includeDocs)
      docs @include(if: $includeDocs) {
        dataAsset {
          ...DataAsset
        }
        personalizationDetails {
          bookmarkDetails {
            isBookmarked
            bookmarkId
          }
        }
      }
    }
  }
  ${DataAssetFragmentDoc}
`;
export function useSearchWithOpenNlpQuery(
  baseOptions: Apollo.QueryHookOptions<ISearchWithOpenNlpQuery, ISearchWithOpenNlpQueryVariables>
) {
  return Apollo.useQuery<ISearchWithOpenNlpQuery, ISearchWithOpenNlpQueryVariables>(
    SearchWithOpenNlpDocument,
    baseOptions
  );
}
export function useSearchWithOpenNlpLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ISearchWithOpenNlpQuery, ISearchWithOpenNlpQueryVariables>
) {
  return Apollo.useLazyQuery<ISearchWithOpenNlpQuery, ISearchWithOpenNlpQueryVariables>(
    SearchWithOpenNlpDocument,
    baseOptions
  );
}
export type SearchWithOpenNlpQueryHookResult = ReturnType<typeof useSearchWithOpenNlpQuery>;
export type SearchWithOpenNlpLazyQueryHookResult = ReturnType<typeof useSearchWithOpenNlpLazyQuery>;
export type SearchWithOpenNlpQueryResult = Apollo.QueryResult<
  ISearchWithOpenNlpQuery,
  ISearchWithOpenNlpQueryVariables
>;
