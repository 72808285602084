import { ApplicationName, EnvironmentProvider } from '@amgen/core';
import { BookmarkProvider } from '@amgen/feature-bookmarks';
import { SearchAppContainer } from '@amgen/shared';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';

import App from './app/app';
import { environment } from './environments/environment';
import * as serviceWorker from './service-worker';

//const appName = sessionStorage.getItem('appName');
const applicationName: ApplicationName = sessionStorage.getItem('appName')
  ? ApplicationName[sessionStorage.getItem('appName')!]
  : ApplicationName.EEA;

// skip console command in
if (environment.production) {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  console.log = () => {}
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  console.error = () => {}
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  console.debug = () => {}
}
ReactDOM.render(
  <EnvironmentProvider {...environment} appName={applicationName}>
    <SearchAppContainer showConsent {...environment}>
      <BookmarkProvider>
        <App />
      </BookmarkProvider>
    </SearchAppContainer>
  </EnvironmentProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
