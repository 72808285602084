import { Direction, IAsset, IAssetAttribute, SortField } from '@amgen/core';
import { createContext } from 'react';

import { ResultViewType } from '../../models';

export interface ISearchState {
  isSliderShown: boolean;
  setIsSliderShown: (value: boolean) => void;
  isMobile: boolean;
  pageOptionsCount: number;
  selectedViewType: ResultViewType;
  onViewTypeChange: (viewType: ResultViewType) => void;
  results: IAsset[];
  conversationalSearchData: any;
  query: string;
  sortOption: SortField;
  selectedSort: SortField;
  setSelectedSort: (sortOption: SortField) => void;
  sortOrder: Direction;
  setSortOrder: (sortOrder: Direction) => void;
  loading: boolean;
  onPageSort: (sortOption: SortField) => void;
  onItemsPerPageChange: (size: number) => void;
  itemsPerPage: number;
  onClearAllFilters: () => void;
  removeParentFilter: (depth: number, parentCategory: IAssetAttribute, val: string) => void;
  onRemoveAppliedFilters: (val: string, category: IAssetAttribute) => void;
  onPageSelect: (pageNo: number) => void;
  searchQueryVariables: any;
  isExpandable?: boolean;
  expandPanel?: boolean;
  setExpandPanel?: (open: boolean) => void;
  setCount: (count: number) => void;
  appData: any;
  eventData: any;
  acronymTitleData: any;
  searchFeedbackData: any;
  generativeAIData: any;
  generativeAILoading: any;
  consolidatedData: any;
  pageNo: number;
  setSelectedTopFilter: (filter: string) => void;
  selectedTopFilter: string;
  setAllSelected: (flag: boolean) => void;
  multiTurnBinaryFeedback: string;
  setMultiTurnBinaryFeedback: (feedback: string) => void;
  isAllSelected: boolean;
  isIframeRendered: boolean;
  setIsIframeRendered: (flag: boolean) => void;
  countData: any;
  filterRemoved: boolean;
  setFilterRemoved: (flag: boolean) => void;
  showTable: boolean;
  setShowTable: (flag: boolean) => void;
  //sharepointArray:any;
  //DefaultQuery:any;
  multiTurnAPICompleted?: boolean;
  setMultiTurnAPICompleted: (flag: boolean) => void;
  selectedSources: any;
  selectedParentFilter: string;
  setSelectedParentFilter: (filter: string) => void;
  topResultCount: number;
  setTopResultCount: (count: number) => void;
  isUserFeedbackCompleted?: boolean;
  setIsUserFeedbackCompleted: (flag: boolean) => void;
  recommendedResultCount: number;
  setRecommendedResultCount: (count: number) => void;
  spotLightData: any;
  setSpotLightData: (spotlight: any) => void;
  DetailedFeedbackFlag?: boolean;
  setFlag: (flag: boolean) => void;
  selectedMood?: string;
  selectedAppMood?: string;
  userPseudoId?: string;
  setUserPseudoId: (pseudoId: string) => void;
  followUpQuery?: string;
  setFollowUpQuery: (term: string) => void;
  selectAppMood: (appMood: string) => void;
  openFeedbackFromSearchQuery?: boolean;
  setOpenFeedbackFromSearchQuery: (flag: boolean) => void;
  selectMood: (mood: string) => void;
  showFeedbackBtn?: boolean;
  setShowFeedbackBtn: (flag: boolean) => void;
  typeOfFeedback?: string;
  setTypeOfFeedback: (feedback: string) => void;
  recommendedTopData: any;
  setRecommendedTopData: (topData: any) => void;
  isApplicationGenerativeAIVariable?: boolean;
  setIsApplicationGenerativeAIVariable: (flag: boolean) => void;
  genAIDeeplinkData: any;
  setGenAIDeeplinkData: (deepData: any) => void;
  multiTurnConversationList: any;
  setMultiTurnConversationList: (conversationList: any) => void;
  genAISpotlightData: any;
  setGenAISpotlightData: (deepData: any) => void;
  conversationalQnAInvoked?: boolean;
  setConversationalQnAInvoked: (flag: boolean) => void;
  conversationalQnAEngaged?: boolean;
  setConversationalQnAEngaged: (flag: boolean) => void;
  finalFollowupQuery: string;
  setFinalFollowupQuery: (term: string) => void;
  isSearchAPIcompleted?: boolean;
  setIsSearchAPIcompleted: (flag: boolean) => void;
}

export const SearchContext = createContext<ISearchState>(null!);
