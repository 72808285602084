import { useDeletePreferenceMutation } from '@amgen/api';
import { IBookmark, useEnvironment } from '@amgen/core';
import { useDelayedReset, useToast } from '@amgen/shared';
import { removeSpecialChars, SOLR_REFRESH_TIME } from '@amgen/utils';
import React, { useState } from 'react';

import './bookmark-item-container.scss';

import { BookmarkItem } from '../../components';

export interface BookmarkItemContainerProps {
  bookmark: IBookmark;
  refetch: () => Promise<boolean>;
}

export const BookmarkItemContainer = (props: BookmarkItemContainerProps) => {
  const [isDeletePanelShown, setIsDeletePanelShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const { appName } = useEnvironment();
  const [deleteBookmark] = useDeletePreferenceMutation();

  const delayedReset = useDelayedReset();

  const handleDelete = () => {
    setIsLoading(true);
    deleteBookmark({
      variables: { id: removeSpecialChars(props.bookmark.id!), isSoft: true, applicationName: appName },
    })
      .then(_ => {
        const handler = setTimeout(
          () => props.refetch().then(hasFinished => setIsLoading(!hasFinished)),
          SOLR_REFRESH_TIME
        );
        delayedReset(handler);
      })
      .catch(err => {
        toast.danger({
          message: err?.graphQLErrors?.[0]?.exception?.message ?? 'Unable to bookmark item. Please try again later.',
          timeout: 5000,
        });
        setIsLoading(false);
        setIsDeletePanelShown(false);
      });
  };

  return (
    <BookmarkItem
      bookmark={props.bookmark}
      isDeletePanelShown={isDeletePanelShown}
      isLoading={isLoading}
      onDeleteConfirm={handleDelete}
      onDeletePanelToggle={show => setIsDeletePanelShown(show)}
    />
  );
};

export default BookmarkItemContainer;
