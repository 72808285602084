import { Paginator } from '@amgen/components';
import { ApplicationName } from '@amgen/core';
import { AppContext, SearchContext } from '@amgen/shared';
import React, { useContext } from 'react';

import './search-result-paginator.scss';

/* eslint-disable-next-line */
export interface SearchResultPaginatorProps {}

export const SearchResultPaginator = (props: SearchResultPaginatorProps) => {
  const { pageOptionsCount, itemsPerPage, onPageSelect, showTable, conversationalQnAEngaged, results } = useContext(
    SearchContext
  );
  const { pageNo, resultCount } = useContext(AppContext);

  const appName: ApplicationName = sessionStorage.getItem('appName')
    ? ApplicationName[sessionStorage.getItem('appName')!]
    : ApplicationName.EEA;

  // const totalItems = data?.search?.resultCount ?? 0;
  const EEATotalItems = appName === ApplicationName.EEA ? 100 : resultCount;
  const legalSearch = showTable === true ? resultCount : EEATotalItems;

  return (
    <Paginator
      isSearchResultPage={true}
      itemsPerPage={itemsPerPage}
      onPageSelect={onPageSelect}
      pageNo={pageNo}
      pageOptionsCount={pageOptionsCount}
      totalItems={legalSearch}
      conversationalQnAEngaged={conversationalQnAEngaged}
      results={results}
    />
  );
};

export default SearchResultPaginator;
