import React, { useRef } from 'react';

import './toast.scss';

import ToastAlert from './toast-alert';
import { IToast } from './toast-model';

export interface IToastContainerProps {
  toasts: IToast[];
  onToastClose: (toast: IToast) => void;
}

export const ToastContainer: React.FC<IToastContainerProps> = props => {
  return (
    <div className="d-flex toaster-container position-fixed flex-column-reverse m-4 align-items-end">
      {props.toasts.length > 0 &&
        props.toasts.map((toast, index) => (
          <ToastAlert key={toast.type + index} keyName={index} toast={toast} onToastClose={props.onToastClose} />
        ))}
    </div>
  );
};

export default ToastContainer;
