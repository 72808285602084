import { IAssetAttribute } from '@amgen/core';
import { createContext } from 'react';

export interface IDateFilterState {
  handleApplyFilters: (values: readonly string[]) => void;
  handleResetFilter: () => void;
  category: IAssetAttribute;
}

export const DateFilterContext = createContext<IDateFilterState>(null!);
