import { IAssetAttribute } from '@amgen/core';
import { AppliedFilter } from '@amgen/feature-facets-kmi';
import { FacetContext, FiltersObject } from '@amgen/shared-kmi';
import React, { useContext } from 'react';
import { Button, Col } from 'react-bootstrap';

import './kmi-applied-filters-bar.scss';

import { KmiSearchContext } from '../kmi-search-context-provider/kmi-search-context';

export interface KmiAppliedFiltersBarProps {
  filters?: FiltersObject;
  onRemove?: (val: string, category: IAssetAttribute) => void;
  onClearAll?: () => void;
  isClearable?: boolean; // manages the clear all and remove buttons on each tag
  message?: string; // message to display when no filters are applied
  messageClassName?: string;
  customClassOnFilter?: (filterCategory: IAssetAttribute) => string;
  clearMessage?: string;
  small?: boolean;
}

export const KmiAppliedFiltersBar: React.FC<KmiAppliedFiltersBarProps> = props => {
  const { onClearAllFilters, onRemoveAppliedFilters } = useContext(KmiSearchContext);
  const { appliedFilters } = useContext(FacetContext);
  const filters = props.filters ?? appliedFilters;
  const clearFilters = props.onClearAll ?? onClearAllFilters;
  const onRemove = props.onRemove ?? onRemoveAppliedFilters;

  if (filters.count > 0) {
    return (
      <Col xs="12" className={`px-0 fixed-height thin-scrollbar ${props.small ? '' : 'my-2'}`}>
        {Object.entries(filters.filters!)
          .filter(([category, _]) => !['prefix_location', 'prefix_class'].includes(category))
          .map(([category, values]) =>
            [...values!].map(v => (
              <AppliedFilter
                key={`${category}:${v}`}
                category={category as IAssetAttribute}
                value={v}
                onRemove={() => onRemove(v, category as IAssetAttribute)}
                isClearable={props.isClearable}
                className={props.customClassOnFilter && props.customClassOnFilter(category as IAssetAttribute)}
              />
            ))
          )}

        {props.isClearable && (
          <Button
            variant="link"
            className="p-0 text-overflow-ellipsis"
            onClick={clearFilters}
            title="Press Alt+c to clear all filters"
          >
            {props.clearMessage || 'Clear All'}
          </Button>
        )}
      </Col>
    );
  }

  return props.message ? <span className={props.messageClassName}>{props.message}</span> : null;
};

export default KmiAppliedFiltersBar;
