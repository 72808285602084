export const CUSTOM_SOURCES = [
  {
    customGroupName: 'OpsKM Search',
    customDataSourceImages: ['assets/images/data-sources/kmops.png', 'assets/images/data-sources/cdocs.png'],
    dataSource: 'OpsKmCustom',
    customDataSources: ['0/Ops KM Assets', '0/CDOCS'],
  },
  {
    customGroupName: 'Contract Search',
    customDataSourceImages: ['assets/images/data-sources/sharepoint.png'],
    dataSource: 'LegalSPO',
    customDataSources: ['1/SharePoint/LCR', '1/SharePoint/ILGDaW', '1/SharePoint/ILGHQContracts'],
  },
];
