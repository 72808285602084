import DOMPurify from 'dompurify';
import React from 'react';

import './knowledge-panel-image.scss';

export interface KnowledgePanelImageProps {
  image?: string;
}

export const KnowledgePanelImage: React.FC<KnowledgePanelImageProps> = ({ image }) => {
  return image ? (
    <div className="col-6 p-0">
      <img src={DOMPurify.sanitize(image)} alt={' '} className="knowledge-panel-product-image" />
    </div>
  ) : null;
};

export default KnowledgePanelImage;
