import { useFacetQuery, useSearchQuery } from '@amgen/api';
import { IAssetAttribute, useEnvironment } from '@amgen/core';
import { useAppliedFiltersWithPersistentValues } from '@amgen/feature-facets';
import {
  FacetContext,
  IQuickRangeOption,
  MatomoContext,
  QUICK_RANGE_OPTIONS,
  useSearchQueryValue,
} from '@amgen/shared';
import { add, format } from 'date-fns';
import { useContext, useEffect, useState } from 'react';

import { getDateAgoFromNow } from '../utils';

export const useDashBoard = (dateKey?: IAssetAttribute) => {
  const quickRangeDateKey = dateKey ?? 'doc_last_modified';
  const {
    facetCategories,
    setAppliedFilters,
    appliedFilters,
    selectedQuickRange,
    setSelectedQuickRange,
    chartType,
    setChartType,
    appliedFilterCategories: selectedFilterCategories,
    setAppliedFilterCategories: setSelectedFilterCategories,
    addFilterCategory,
  } = useContext(FacetContext);
  const { trackApplicationFeature } = useContext(MatomoContext);

  const [today] = useState<Date>(new Date());
  const filters = useAppliedFiltersWithPersistentValues(appliedFilters);
  const filterStartDate = new Date();
  const filterEndDate = new Date();

  const setDashboardFilterAndCategory = (value: string | string[], category: IAssetAttribute, removeAll?: boolean) => {
    addFilterCategory(category);

    setAppliedFilters(
      removeAll ? appliedFilters.removeAll(category).add(value, category) : appliedFilters.add(value, category)
    );
  };

  useEffect(() => {
    if (!appliedFilters.filters?.[quickRangeDateKey]?.size) {
      setSelectedQuickRange(QUICK_RANGE_OPTIONS[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    setSelectedFilterCategories,
    addFilterCategory,
    facetCategories,
    selectedFilterCategories,
    setAppliedFilters,
    dashboardFilters: appliedFilters, // deliberate connection to applied filters
    setDashboardFilters: setAppliedFilters,
    setDashboardFilterAndCategory,
    selectedQuickRange,
    setSelectedQuickRange,
    chartType,
    setChartType,
    trackApplicationFeature,
    today,
    filterStartDate,
    filterEndDate,
    filters,
    quickRangeDateKey,
  };
};

export const useDashboardFilterFunctions = () => {
  const {
    dashboardFilters,
    setDashboardFilters,
    setSelectedQuickRange,
    today,
    quickRangeDateKey,
    addFilterCategory,
  } = useDashBoard();

  const setDateFiltersFromRange = (rangeOption: IQuickRangeOption) => {
    if (QUICK_RANGE_OPTIONS[0].label !== rangeOption.label) {
      addFilterCategory(quickRangeDateKey);
      setDashboardFilters(
        dashboardFilters.removeAll(quickRangeDateKey).add(
          [
            format(
              add(new Date(), {
                years: -rangeOption.yearAgo,
                months: -rangeOption.monthAgo,
                days: -rangeOption.daysAgo,
              }),
              'yyyy-MM-dd'
            ) + 'T00:00:00Z',
            today.toISOString().substring(0, 19) + 'Z', // even if iso string gives out a timestamp, this is to remove the milliseconds it gives in the timestamp
          ],
          quickRangeDateKey
        )
      );
    } else {
      setDashboardFilters(dashboardFilters.removeAll(quickRangeDateKey));
    }
    setSelectedQuickRange(rangeOption);
  };

  const setDateFiltersFromCustomRange = (startDate: string, endDate: string) => {
    addFilterCategory(quickRangeDateKey);
    setDashboardFilters(dashboardFilters.removeAll(quickRangeDateKey).add([startDate, endDate], quickRangeDateKey));
    setSelectedQuickRange(QUICK_RANGE_OPTIONS[4]);
  };

  const resetDashboardFilters = () => {
    const copy = dashboardFilters.clone().clear();
    setDashboardFilters(copy);
    setSelectedQuickRange(QUICK_RANGE_OPTIONS[0]);
  };

  const onRemoveDashboardFilter = (value: string, category: IAssetAttribute) => {
    const copy = dashboardFilters.remove(value, category);
    setDashboardFilters(copy);
    if (category === quickRangeDateKey) {
      setSelectedQuickRange(QUICK_RANGE_OPTIONS[0]);
    }
  };

  return { setDateFiltersFromRange, resetDashboardFilters, onRemoveDashboardFilter, setDateFiltersFromCustomRange };
};

export const useSearchDataFromDashboardFilters = () => {
  const { filters } = useDashBoard();
  const query = useSearchQueryValue();

  // search api call for getting the count from the applied filters (filters)
  const { appName, requestHandler } = useEnvironment();
  const { data: searchData } = useSearchQuery({
    variables: {
      solrHandler: appName === 'DVIEW' ? '/dview-search-select' : '/search-select',
      query,
      filters,
      includeDocs: false,
      noShortContent: true,
      appName,
    },
  });
  return searchData;
};

export const useKMIDashboardData = () => {
  const query = useSearchQueryValue();
  const { today, filters, selectedQuickRange, quickRangeDateKey } = useDashBoard();
  const { appName, requestHandler } = useEnvironment();
  const searchData = useSearchDataFromDashboardFilters();
  const fields = [
    {
      facetRange: quickRangeDateKey, //chart date facet key
      facetRangeStart: `${getDateAgoFromNow(
        selectedQuickRange.yearAgo,
        selectedQuickRange.monthAgo,
        selectedQuickRange.daysAgo,
        today
      )}`,
      facetRangeEnd: `${today.toISOString().substring(0, 19) + 'Z'}`,
      facetRangeGap: `+1${selectedQuickRange.gap}`,
      facetLimit: -1,
    },
  ];

  const chartQueryVariables = (containsComments: boolean) => ({
    solrHandler: appName === 'DVIEW' ? '/dview-search-select' : '/search-select',
    query,
    filters: [`contains_comments:${containsComments}`].concat(filters),
    fields,
    excludeCount: false,
    appName,
    requestHandler,
    userType: sessionStorage.getItem('employeeType') || '',
  });

  // query for getting interactions without comments
  const { data: withoutCommentsData, loading: withoutCommentsLoading, error: withoutCommentsError } = useFacetQuery({
    variables: chartQueryVariables(false),
  });

  // query for getting interactions with comments
  const { data: withCommentsData, loading: withCommentsLoading, error: withCommentsError } = useFacetQuery({
    variables: chartQueryVariables(true),
  });

  return {
    searchData,
    withoutCommentsData,
    withoutCommentsLoading,
    withoutCommentsError,
    withCommentsData,
    withCommentsLoading,
    withCommentsError,
  };
};

export const useEEADashboardData = (key: IAssetAttribute) => {
  const { today, filters, dashboardFilters } = useDashBoard();
  const query = useSearchQueryValue();
  const range = [...dashboardFilters.getValues(key)]?.[0]?.split('~');
  const facetRangeStart =
    range?.[0] ??
    format(
      add(new Date(), {
        years: -10,
      }),
      'yyyy-MM-dd'
    ) + 'T00:00:00Z';
  const facetRangeEnd = range?.[1] ?? `${today.toISOString().substring(0, 19) + 'Z'}`;

  const fields = [
    {
      facetRange: key, //chart date facet key
      facetRangeStart,
      facetRangeEnd,
      facetRangeGap: `+1MONTH`, //[TODO] cleverly use gap depending on start and end range
      facetLimit: -1,
    },
  ];

  const { appName, requestHandler } = useEnvironment();
  const { data, loading, error } = useFacetQuery({
    variables: {
      solrHandler: appName === 'DVIEW' ? '/dview-search-select' : '/search-select',
      query,
      filters,
      fields,
      excludeCount: false,
      appName,
      userType: sessionStorage.getItem('employeeType') || '',
    },
  });

  return {
    data,
    loading,
    error,
  };
};
