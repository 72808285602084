import { ApplicationName, SolrRequestHandler } from '@amgen/core';
import { createContext, useContext } from 'react';

export interface IAPIConfig {
  nlpUrl: string;
  graphqlUrl: string;
  adminUrl?: string;
}

interface IAPIContextValue extends IAPIConfig {
  appName: ApplicationName;
  reqHandler: SolrRequestHandler;
  suggestHandler: SolrRequestHandler;
}

export const APIContext = createContext<IAPIContextValue>(null!);

export const useAPIConfig = () => useContext(APIContext);
