import { GENAI_QnA_ACTIONS, MatomoContext, SEARCH_APP_FEATURE_LIST, SearchContext } from '@amgen/shared';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';

import './generative-ai.scss';

const GenerativeAISimilarQuestions = () => {
  const { generativeAIData } = useContext(SearchContext);
  const { trackApplicationFeature } = useContext(MatomoContext);
  const generativeQnAData = generativeAIData && generativeAIData?.generateQA?.filter(i => i?.additionalQA === true);
  const [detailAccordionKey, setDetailAccordionKey] = useState('');
  return generativeQnAData?.length > 0 ? (
    <div className="similar-questions mt-3 ">
      <div className="fs-14 mb-2">
        <span className="interested-in-text font-weight-bold">Are you interested in:</span>
      </div>
      <Accordion
        className="fs-13"
        onSelect={(e: any) => {
          if (e !== null) {
            trackApplicationFeature(SEARCH_APP_FEATURE_LIST.GEN_AI_QnA, GENAI_QnA_ACTIONS.GENAI_QnA_ACCORDIAN_CLICKS);
          }
          setDetailAccordionKey(e);
        }}
      >
        {generativeQnAData.map((item, index) => (
          <Card>
            <Accordion.Toggle
              as={Card.Header}
              className={detailAccordionKey === index.toString() ? 'font-weight-bold pb-1' : ''}
              variant="link"
              eventKey={index.toString()}
            >
              {item?.question}
              <FontAwesomeIcon
                className="dropdown-accordian"
                icon={detailAccordionKey === index.toString() ? faChevronUp : faChevronDown}
              />
            </Accordion.Toggle>

            <Accordion.Collapse eventKey={index.toString()}>
              <Card.Body>{item?.answer}</Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
      </Accordion>
    </div>
  ) : (
    <></>
  );
};

export default GenerativeAISimilarQuestions;
