import { useGetSavedPreferenceQuery } from '@amgen/api';
import { DataSource, IAssetAttribute, ISavedSearch, useEnvironment } from '@amgen/core';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import './app-context-provider.scss';

import { useURLParams } from '../../hooks';
import { IAttributeComponentConfig, ResultViewType } from '../../models';
import { AppContext, IAppState } from './app-context';

export interface AppContextProviderProps {
  dataSources?: DataSource[];
  internalSources?: DataSource[];
  facetCategories: IAssetAttribute[];
  attributeDisplayNames: NonNullable<{ [key in IAssetAttribute]: string }>;
  displayAttributes: IAssetAttribute[];
  excludeColumnsInTable?: IAssetAttribute[];
  suggestingPlaceholders?: string[];
  attributeConfig: IAttributeComponentConfig;
}

export const AppContextProvider: React.FC<AppContextProviderProps> = ({ children, ...props }) => {
  const { sort, sortDirection, page, view } = useURLParams();
  const [resultCount, setResultCount] = useState(-1);
  const [suggestionDoc, setSuggestionDoc] = useState(false);
  const [savedSearch, setSavedSearch] = useState<ISavedSearch | null>(null);
  const [facetCategories, setFacetCategories] = useState(props.facetCategories);
  const [pageNo, setPageNo] = useState(page);
  const [dataSources, setDataSources] = useState(props.dataSources);
  const [viewType, setViewType] = useState(view);
  const [sortOption, setSortOption] = useState(sort);
  const [sortOrder, setSortOrder] = useState(sortDirection);
  const { current: perPageOptions } = useRef([15, 30, 50]);
  const [itemsPerPage, setItemsPerPage] = useState(perPageOptions[0]);
  const { appName } = useEnvironment();

  // TODO: Remove this when using apollo local state. This is a terrible solution to persist / initialize
  // view type across page navigation.
  const { data: preferences } = useGetSavedPreferenceQuery({ variables: { appName } });
  useEffect(() => setViewType(view ?? (preferences?.view?.values?.[0] as ResultViewType) ?? ResultViewType.List), [
    preferences?.view?.values,
    view,
  ]);

  const providedValue = useMemo<IAppState>(
    () => ({
      resultCount,
      setResultCount,
      savedSearch,
      setSavedSearch,
      suggestionDoc,
      setSuggestionDoc,
      searchFacetCategories: facetCategories,
      setSearchFacetCategories: setFacetCategories,
      attributeDisplayNames: props.attributeDisplayNames,
      displayAttributes: props.displayAttributes,
      pageNo,
      setPageNo,
      dataSources,
      setDataSources,
      attributeConfig: props.attributeConfig,
      excludeColumnsInTable: props.excludeColumnsInTable,
      suggestingPlaceholders: props.suggestingPlaceholders,
      viewType,
      setViewType,
      sortOption,
      setSortOption,
      sortOrder,
      setSortOrder,
      perPageOptions,
      itemsPerPage,
      setItemsPerPage,
      internalSources: props.internalSources,
    }),
    [
      resultCount,
      savedSearch,
      suggestionDoc,
      setSuggestionDoc,
      facetCategories,
      props.attributeDisplayNames,
      props.displayAttributes,
      props.attributeConfig,
      props.excludeColumnsInTable,
      props.suggestingPlaceholders,
      props.internalSources,
      pageNo,
      dataSources,
      viewType,
      sortOption,
      sortOrder,
      perPageOptions,
      itemsPerPage,
    ]
  );

  return <AppContext.Provider value={providedValue}>{children}</AppContext.Provider>;
};

export default AppContextProvider;
