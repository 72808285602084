import * as Types from '@amgen/core';
import { gql, QueryHookOptions, useQuery } from '@apollo/client';

// import { IApplicationAccessDetails, IApplicationPagesAccessDetails, ITempFileAccess, Types } from '@amgen/core';
export interface IGetApplicationAccessDetails {
  getApplicationAccessDetails?: Types.IApplicationAccessDetails;
}

export interface IGetTemporaryS3FileAccess {
  getTemporaryS3FileAccess?: Types.ITempFileAccess;
}

export type IGetSearchCountQueryVariables = Types.Exact<{
  appName?: Types.Maybe<Types.ApplicationName>;
}>;

export const APPLICATION_ACCESS_DETAILS = gql`
  query GetApplicationAccessDetails($appName: ApplicationName!) {
    getApplicationAccessDetails(applicationName: $appName) {
      username
      costCenter
      functionalArea
      subFunctionalArea
      companyCode
      employeeType
      adGroups
      abTestingGroup
      applicationDetails {
        applicationName
        accessible
        applicationURL
      }
    }
  }
`;

export function useGetApplicationAccessDetails(
  baseOptions: QueryHookOptions<IGetApplicationAccessDetails, IGetSearchCountQueryVariables>
) {
  return useQuery<IGetApplicationAccessDetails, IGetSearchCountQueryVariables>(APPLICATION_ACCESS_DETAILS, baseOptions);
}

export const APPLICATION_PAGE_ACCESS_DETAILS = gql`
  query GetAuthorizationDetails {
    getAuthorizationDetails {
      applications {
        parent
        pages
      }
    }
  }
`;

export interface IGetApplicationPageAccessDetails {
  getAuthorizationDetails?: Types.IApplicationPagesAccessDetails;
}

export function useGetAuthorizationPageQuery(options?: QueryHookOptions<IGetApplicationPageAccessDetails>) {
  return useQuery(APPLICATION_PAGE_ACCESS_DETAILS, options);
}

export const S3_FILE_ACCESS = gql`
  query GetTemporaryS3FileAccess($paths: [String]) {
    getTemporaryS3FileAccess(s3Paths: $paths) {
      error
      status
      preSignedUrl
    }
  }
`;

export function useGetTemporaryS3FileAccess(options?: QueryHookOptions<IGetTemporaryS3FileAccess>) {
  return useQuery(S3_FILE_ACCESS, options);
}
