import { useEnvironment } from '@amgen/core';
import { OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js';
import { LoginCallback, SecureRoute, Security as OktaProvider } from '@okta/okta-react';
import React, { Suspense, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { Route, Switch, useHistory } from 'react-router-dom';

import './authenticator.scss';

import AuthError from '../auth-error/auth-error';
import SessionTimeoutError from '../session-timout-error/session-timeout';


/* eslint-disable-next-line */
export interface AuthenticatorProps extends OktaAuthOptions {
}

export const Authenticator: React.FC<AuthenticatorProps> = ({ children, ...config }) => {
  const history = useHistory();

  const { current: oktaAuth } = useRef(
    new OktaAuth({
      scopes: ['openid', 'profile', 'email'],
      pkce: false,
      responseType: ['id_token', 'token'],
      tokenManager: {
        expireEarlySeconds: 120,
        autoRenew: true,
      },
      restoreOriginalUri: async (_, originalUri) => {
        const basename = history.createHref({ pathname: '/' });
        const path = originalUri.replace(`${window.location.origin}${basename}`, '/');
        history.replace(path);
      },
      ...config,
    })
  );

  const { support } = useEnvironment();

  const { current: ErrorComponent } = useRef((props: { error: Error }) => (
    <AuthError {...props} supportEmail={support.email} />
  ));

  return oktaAuth ? (
    <OktaProvider oktaAuth={oktaAuth}>
      <Suspense
        fallback={
          <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh', width: '100vw' }}>
            <Spinner animation="border" className="p-4 text-primary" />
          </div>
        }
      >
        <Switch>
          <Route exact={true} path="/callback">
            <LoginCallback errorComponent={ErrorComponent} />
          </Route>
          <Route exact={true} path="/logout">
            <SessionTimeoutError />
          </Route>
          <SecureRoute path="/">{children}</SecureRoute>;
        </Switch>
      </Suspense>
    </OktaProvider >
  ) : null;
};

export default Authenticator;
