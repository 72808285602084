import React from 'react';
import { Button, Modal } from 'react-bootstrap';

import './smartsheet-popup.scss';

export interface SmartsheetPopupProps {
  show: boolean;
  onHide: () => void;
}

export const SmartsheetPopup = (props: SmartsheetPopupProps) => {
  return (
    <Modal centered scrollable show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title as="h2">SmartSheet Data</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center" style={{ minHeight: 'unset' }}>
        <p>
          You don't need to raise a request for SmartSheet data. Please give <strong>enterprise_search_admins</strong>{' '}
          read access to your smartsheet and it will automatically be included after some time.
        </p>
        <Button className="px-5" onClick={props.onHide}>
          OK
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default SmartsheetPopup;
