import { SearchContext, useWindowSizeListener } from '@amgen/shared';
import React, { useContext } from 'react';

import './user-feedback.scss';

export const FeedbackSuccess = props => {
  const isMobile = useWindowSizeListener();
  const { openFeedbackFromSearchQuery, selectedMood } = useContext(SearchContext);
  return openFeedbackFromSearchQuery ? (
    <>
      <img
        className="feedback-sent-img"
        src={'assets/images/done-feed.gif'}
        alt="feedback sent successfully"
        loading="lazy"
      />
      <div className="text-center feedback-img-text-thanks">
        {selectedMood === 'Satisfied' ? (
          <div className="font-weight-bold">Thank you for your feedback !</div>
        ) : (
          <>
            <div className="font-weight-bold">Thank you for your feedback.</div>
            <div> Our support team will review it for further action.</div>
          </>
        )}
      </div>
    </>
  ) : (
    <>
      <img
        className="feedback-sent-img"
        src={'assets/images/done-feed.gif'}
        alt="feedback sent successfully"
        loading="lazy"
      />
      <div className="text-center feedback-img-text-thanks">
        <div className="font-weight-bold">Your Feedback has already been recorded.</div>
        <div> Our support team will review it for further action.</div>
      </div>
    </>
  );
};

export default FeedbackSuccess;
