import {
  SearchContext,
  useSearchQueryValue,
  MatomoContext,
  SEARCH_APP_FEATURE_LIST,
  ACRONYMS_ACTIONS,
} from '@amgen/shared';
import React, { useContext, useState } from 'react';

import './acronym-search-result.scss';

/* eslint-disable-next-line */
// export interface AcronymSearchResultProps {
//   seperateTitles: any;
//   resultCount: number;
// }
export interface AcronymSearchResultProps {}

// export function AcronymSearchResult({ resultCount, seperateTitles }: AcronymSearchResultProps) {
export function AcronymSearchResult({}: AcronymSearchResultProps) {
  // const { setCount } = useContext(SearchContext);
  // const [displayCount, setDisplayCount] = useState(2);
  const { trackApplicationFeature } = useContext(MatomoContext);
  const { isAllSelected, acronymTitleData } = useContext(SearchContext);
  const searchTerm = useSearchQueryValue();

  let seperateTitles: any;
  if (acronymTitleData && acronymTitleData.getSearchObjects && acronymTitleData.getSearchObjects.docs) {
    seperateTitles = Object.values(
      acronymTitleData?.getSearchObjects?.docs.reduce((acc, x) => {
        acc[x.title] = [...(acc[x.title] || []), x];
        return acc;
      }, {})
    );
  }

  return (
    //Neha - This is old UI for acronym
    // <div className="app-box mb-1 mx-0 p-2 mt-1">
    //   {seperateTitles?.map(acronymData => {
    //     return (
    //       <div>
    //         <span className={'event-font  font-weight-bold'}>Expansion for {acronymData?.[0]?.title} : </span>
    //         {acronymData.map((item, index) => {
    //           return (
    //             <>
    //               <span className={'event-font'}>{item.description}</span>
    //               {acronymData.length !== 1 && index < acronymData.length - 1 && <span className="ml-2 mr-2">|</span>}
    //             </>
    //           );
    //         })}
    //       </div>
    //     );
    //   })}
    // </div>
    <>
      {/* {searchTerm !== '*:*' && isAllSelected ? ( */}
      {searchTerm !== '*:*' ? (
        <>
          {seperateTitles?.length == 1 ? (
            <div className="acronym-container mt-4">
              {seperateTitles?.map(acronymData => {
                return (
                  <>
                    <div className="container">
                      <span className="acronym-heading pb-1">Acronym(s)</span>

                      <span
                        className="view-wiif"
                        onClick={() => {
                          window.open('https://myamgen.amgen.com/myAmgen/s/amgen-acronym-wiki', '_blank');
                          trackApplicationFeature(SEARCH_APP_FEATURE_LIST.ACRONYMS, ACRONYMS_ACTIONS.ACRONYM_WIKI_LINK);
                        }}
                      >
                        View Acronym Wiki
                      </span>
                    </div>
                    <div className="acronym-title  pt-2">{acronymData?.[0]?.title}</div>
                    {acronymData.map((item, index) => {
                      return (
                        <div className="acronym-result pb-2">
                          {/* “{acronymData?.[0]?.title}” stands for <strong>{item.description}</strong> */}
                          {item.description}
                        </div>
                      );
                    })}
                  </>
                );
              })}
            </div>
          ) : (
            <>
              {seperateTitles?.length == 2 && (
                <div className="acronym-container mt-4">
                  <div className="container">
                    <span className="acronym-heading pb-1">Acronym(s)</span>
                    <span
                      className="view-wiif"
                      onClick={() => {
                        window.open('https://myamgen.amgen.com/myAmgen/s/amgen-acronym-wiki', '_blank');
                        trackApplicationFeature(SEARCH_APP_FEATURE_LIST.ACRONYMS, ACRONYMS_ACTIONS.ACRONYM_WIKI_LINK);
                      }}
                    >
                      View Acronym Wiki
                    </span>
                  </div>
                  {seperateTitles?.map(acronymData => {
                    return (
                      <>
                        <div className="acronym-title pt-3">{acronymData?.[0]?.title}</div>
                        {acronymData.map((item, index) => {
                          return <div className="acronym-result">{item.description}</div>;
                        })}
                      </>
                    );
                  })}
                </div>
              )}
            </>
          )}
        </>
      ) : (
        ''
      )}
    </>
  );
}

export default AcronymSearchResult;
