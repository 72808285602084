import { IDocumentFeedback } from '@amgen/core';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Alert, Button, Spinner } from 'react-bootstrap';

import './doc-feedback-list.scss';

import DocFeedback from '../doc-feedback/doc-feedback';

/* eslint-disable-next-line */
export interface DocFeedbackListProps {
  loading: boolean;
  error: any;
  docFeedbacks: IDocumentFeedback[];
  showMore: () => void;
  hasMore: boolean;
  pageCount: number;
}

export const DocFeedbackList = (props: DocFeedbackListProps) => {
  return (
    <div className="my-4">
      <h2 className="font-weight-bold">User Reviews</h2>

      {props.docFeedbacks.map((feedback, index) => (
        <DocFeedback key={index} {...feedback} />
      ))}
      {!props.loading && props.hasMore && props.docFeedbacks?.length >= props.pageCount && (
        <Button variant="link" onClick={props.showMore}>
          Show more
        </Button>
      )}
      {props.loading && (
        <div className="py-2 px-4 mx-auto">
          <Spinner animation="border" className="text-primary" />
        </div>
      )}
      {!props.loading && (props.error || !props.docFeedbacks.length) && (
        <Alert variant="warning" className="d-flex align-items-center py-0  m-4">
          <span className="mr-3" style={{ fontSize: 'x-large' }}>
            <FontAwesomeIcon icon={faComment} />
          </span>
          No comments Available
        </Alert>
      )}
    </div>
  );
};

export default DocFeedbackList;
