import { AlertMessageType } from '@amgen/utils';
import { useContext } from 'react';

import { ToastContext } from './toast-context';
import { IToast } from './toast-model';

type IToastWithoutType = Omit<IToast, 'type'>;

export const generateRandomId = () => {
  return btoa(Math.random().toString()).substring(0, 12);
};

export function useToast() {
  const { addToast, onToastClose, removeAll } = useContext(ToastContext);

  const createToastByType = (type: AlertMessageType) => (toastWithoutType: IToastWithoutType) =>
    addToast({
      ...toastWithoutType,
      type,
      timeout: type === 'success' && !toastWithoutType.timeout ? 3500 : toastWithoutType.timeout,
      id: toastWithoutType.id ?? generateRandomId(),
    });

  const toast = (toast: IToast, id?: string) =>
    addToast({
      ...toast,
      timeout: toast.type === 'success' && !toast.timeout ? 3500 : toast.timeout,
      id: id ?? generateRandomId(),
    });

  toast.success = createToastByType('success');
  toast.info = createToastByType('info');
  toast.danger = createToastByType('danger');
  toast.warning = createToastByType('warning');
  toast.dark = createToastByType('dark');
  toast.close = onToastClose;
  toast.removeAll = removeAll;

  return toast;
}
