import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useLocation } from 'react-router-dom';

import './save-search-button.scss';

import { SAVE_SEARCH_BUTTON_CONTAINER } from '../../constants';
import SaveSubscribeModal from '../save-subscribe-modal/save-subscribe-modal';

/* eslint-disable-next-line */
export interface SaveSearchButtonProps {}

export const SaveSearchButton: React.FC<SaveSearchButtonProps> = props => {
  const [isSaveSearchModalOpen, setIsSaveSearchModalOpen] = useState(false);
  const user = JSON.parse(sessionStorage.user);

  const location = useLocation();
  const isSaveDisabled = location.pathname === '/surveys' || location.pathname === '/reports';

  const [container, setContainer] = useState<HTMLElement | null>(null);
  useEffect(() => setContainer(document.getElementById(SAVE_SEARCH_BUTTON_CONTAINER)), []);

  return (
    container &&
    ReactDOM.createPortal(
      <>
        <span title={isSaveDisabled ? 'Save is Unavailable on Survey Page' : 'Save Search'}>
          <img
            alt=""
            onClick={() => setIsSaveSearchModalOpen(true)}
            src="assets/images/save.svg"
            // disabled={isSaveDisabled}
          />
        </span>
        {isSaveSearchModalOpen && (
          <SaveSubscribeModal
            isOpen={isSaveSearchModalOpen}
            onCancel={() => setIsSaveSearchModalOpen(false)}
            onSuccess={() => setIsSaveSearchModalOpen(false)}
            user={user}
          />
        )}
      </>,
      container
    )
  );
};

export default SaveSearchButton;
