import React from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import './suggested-destination.scss';
import gotoSuggestedDestinationIcon from './../../../../../assets/src/lib/icons/goto-icon.svg';
import defaultAppImage from './../../../../../assets/src/lib/images/default-suggested-image.png';

export interface SuggestedDestinationProps {
  content: string;
  title: string;
  image: any;
  url?: any;
  SEARCH_APP_FEATURE_LIST: any;
  SUGGESTED_DESTINATIONS_ACTIONS: any;
  handleClick: any;
  loading: any;
  docPosition: any;
}

export function SuggestedDestination({
  content,
  title,
  image,
  url,
  SEARCH_APP_FEATURE_LIST,
  SUGGESTED_DESTINATIONS_ACTIONS,
  handleClick,
  loading,
  docPosition,
}: SuggestedDestinationProps) {
  return (
    <>
      <Col xs="12" className="mt-2 pl-0 apps-content-container">
        <Row>
          {image ? (
            <Col xs="2" md="1">
              {loading ? (
                <Spinner animation="border" variant="primary" style={{ width: '1rem', height: '1rem' }} />
              ) : (
                <a
                  href={url}
                  target={'blank'}
                  onClick={() => {
                    handleClick(SEARCH_APP_FEATURE_LIST, SUGGESTED_DESTINATIONS_ACTIONS, url, docPosition);
                  }}
                >
                  <img
                    // className={image ? 'suggested-destination-image' : 'suggested-destination-image-default'}
                    // src={image ? image : defaultAppImage}
                    className={'suggested-destination-image'}
                    src={image}
                  ></img>
                </a>
              )}
            </Col>
          ) : (
            ''
          )}
          <Col xs="11" md="11">
            <a
              href={url}
              target={'blank'}
              className="suggested-destination-title"
              onClick={() => {
                handleClick(SEARCH_APP_FEATURE_LIST, SUGGESTED_DESTINATIONS_ACTIONS, url, docPosition);
              }}
            >
              {title}
            </a>
            <span className="suggested-destination-content" dangerouslySetInnerHTML={{ __html: content }}></span>
            <a
              className="suggested-destination-url d-block"
              href={url}
              target={'blank'}
              onClick={() => {
                handleClick(SEARCH_APP_FEATURE_LIST, SUGGESTED_DESTINATIONS_ACTIONS, url, docPosition);
              }}
            // title={title}
            >
              Link to Application
              <span className="pl-2">
                <img src={gotoSuggestedDestinationIcon}></img>
              </span>
            </a>
          </Col>
        </Row>
      </Col>
    </>
  );
}

export default SuggestedDestination;
