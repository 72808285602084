import { DropdownContainer } from '@amgen/components';
import { DataSource } from '@amgen/core';
import { useFilterCategoryOperations } from '@amgen/feature-facets';
import { FacetsPanelContext, PreferenceContext } from '@amgen/shared';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ButtonGroup, Dropdown, FormCheck } from 'react-bootstrap';

import './data-source-dropdown.scss';

export interface DataSourceDropdownProps {
  dataSources: DataSource[];
  onChange: (dataSources: DataSource[]) => void;
  selectedContentSources: Set<DataSource>;
  setSelectedContentSources: (item: Set<DataSource>) => void;
}

export const DataSourceDropdown = (props: DataSourceDropdownProps) => {
  const { selectedDefaultDataSources } = useContext(PreferenceContext);
  const { selectedFilterCategories } = useContext(FacetsPanelContext);
  const [isFavoritesSelected, setIsFavoritesSelected] = useState(false);
  const { toggleFilterCategory } = useFilterCategoryOperations();
  const { selectedContentSources, setSelectedContentSources } = props;
  const [numSelectedContentSources, setNumSelectedContentSources] = useState(selectedContentSources.size);

  const favoriteDataSourcesValue = selectedDefaultDataSources.filter(item => item?.group === null)?.[0]?.values;
  const customDataSources = props.dataSources?.filter(item => favoriteDataSourcesValue?.includes(item as string)) ?? [];

  useEffect(() => {
    if (isFavoritesSelected) {
      if (favoriteDataSourcesValue.length === 0) {
        selectedContentSources.clear();
        setIsFavoritesSelected(false);
      }
      setNumSelectedContentSources(favoriteDataSourcesValue.length);
    } else {
      setNumSelectedContentSources(selectedContentSources.size);
    }
  }, [isFavoritesSelected, selectedContentSources, selectedContentSources.size, favoriteDataSourcesValue]);

  const user = JSON.parse(sessionStorage.user);

  const handleToggle = useCallback(
    (contentSource: DataSource) => {
      if ((contentSource as string) === 'Favorites') {
        selectedContentSources.clear();
        if (!isFavoritesSelected) {
          customDataSources.forEach(dataSource => {
            selectedContentSources.add(dataSource);
          });
        }
        setIsFavoritesSelected(prev => !prev);
        if (!selectedFilterCategories.has('doc_nav_prefix')) {
          toggleFilterCategory('doc_nav_prefix');
        }
      } else if (contentSource) {
        if (isFavoritesSelected && (contentSource as string) !== 'Favorites') {
          selectedContentSources.clear();
          setIsFavoritesSelected(false);
        }
        if (selectedContentSources.has(contentSource)) {
          selectedContentSources.delete(contentSource);
        } else {
          selectedContentSources.add(contentSource);
        }
        if (!selectedFilterCategories.has('doc_nav_prefix')) {
          toggleFilterCategory('doc_nav_prefix');
        }
      } else {
        setIsFavoritesSelected(false);
        selectedContentSources.clear();
      }
      setSelectedContentSources(new Set(selectedContentSources));
      props.onChange([...selectedContentSources]);
    },
    [
      customDataSources,
      isFavoritesSelected,
      props,
      selectedContentSources,
      selectedFilterCategories,
      setSelectedContentSources,
      toggleFilterCategory,
    ]
  );

  return (
    <DropdownContainer
      title={`Content Sources${numSelectedContentSources > 0 ? ` (${numSelectedContentSources})` : ''}`}
      onValueToggle={item => handleToggle(item as DataSource)}
      onClear={() => handleToggle('' as DataSource)}
      closeAfterSelection={false}
      hideClearButton={numSelectedContentSources === 0}
    >
      <Dropdown.Item
        as={ButtonGroup}
        className="user-favourites-checkbox px-3 pb-2 mb-1"
        eventKey="Favorites"
        key="Favorites"
        disabled={customDataSources.length === 0}
      >
        <FormCheck>
          <FormCheck.Input type="checkbox" checked={isFavoritesSelected} readOnly />
          <FormCheck.Label className="text-capitalize">
            {user?.firstName ? `${user.firstName}'s Favorites` : 'Favorites'}
          </FormCheck.Label>
        </FormCheck>
      </Dropdown.Item>
      {props.dataSources.map(dataSource => (
        <Dropdown.Item as={ButtonGroup} className="px-3" eventKey={dataSource} key={dataSource}>
          <FormCheck>
            <FormCheck.Input
              type="checkbox"
              checked={selectedContentSources.has(dataSource) && !isFavoritesSelected}
              readOnly
            />
            <FormCheck.Label>{dataSource}</FormCheck.Label>
          </FormCheck>
        </Dropdown.Item>
      ))}
    </DropdownContainer>
  );
};

export default DataSourceDropdown;
