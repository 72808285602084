import { useAddCrawlRequestMutation } from '@amgen/api';
import { CONSENT_GROUP, useUsersNames } from '@amgen/auth';
import { NewRequestFormValue } from '@amgen/core';
import { useToast } from '@amgen/shared';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { Button, Col, Row, Spinner } from 'react-bootstrap';

import './new-request-form.scss';

import { initialValue, validate } from '../models';
import ConsentInput from './consent-input/consent-input';
import DataSourceInput from './data-source-input/data-source-input';
import DescriptionInput from './description-input/description-input';
import SpaceOwnerInput from './space-owner-input/space-owner-input';

export interface NewRequestFormProps {
  onCancel?: () => void;
  onSubmit?: (values: NewRequestFormValue) => void;
}

export const NewRequestForm = (props: NewRequestFormProps) => {
  const { fullName } = useUsersNames();
  const [createNewRequest] = useAddCrawlRequestMutation(CONSENT_GROUP, fullName);
  const toast = useToast();

  return (
    <Formik<NewRequestFormValue>
      initialValues={initialValue}
      onSubmit={async values => {
        try {
          values.sourcePath = values?.sourcePath?.trim();
          values.sourcePath = values?.sourcePath?.replaceAll(' ', '%20');
          const resp = await createNewRequest(values);
          toast.success({
            message: `Your request has successfully been submitted. You can view its status on your My Request tracker`,
            id: 'new-request-toast',
            timeout: 5000,
          });
          props.onSubmit?.(values);
        } catch (error: any) {
          toast.danger({
            message: error.message.includes('Site URL already exist')
              ? 'Site URL already exist'
              : 'Something went wrong. Please try again later.',
            id: 'new-request-toast',
            timeout: 5000,
          });
        }
      }}
      validate={v => validate(v)}
    >
      {({ isSubmitting, validateForm }) => (
        <Form className="mx-auto" noValidate>
          <DataSourceInput />
          <SpaceOwnerInput />
          <DescriptionInput />
          <ConsentInput />

          <Row>
            <Col xs="12" as="p">
              <strong>NOTE: </strong> The Search portal inherits permissions of that of the source system and the search
              result set will not include any data that users do not have permissions to.
            </Col>

            <Col xs="12" className="mt-3 d-flex justify-content-end">
              <Button type="submit" variant="primary" className="px-5 mr-3" disabled={isSubmitting}>
                {isSubmitting ? <Spinner animation="border" size="sm" className="text-white" /> : 'Submit'}
              </Button>
              <Button
                type="button"
                variant="outline-secondary"
                className="px-5"
                disabled={isSubmitting}
                onClick={() => {
                  props.onCancel?.();
                }}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default NewRequestForm;
