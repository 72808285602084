import {
  AssetActionsMenu,
  ViewAssetContentButton,
  ViewAttachmentsButton,
  ViewAttendeeButton,
  ViewDocContentButton,
  ViewDownloadsButton,
  ViewEmailResponseButton,
  ViewInquiryButton,
  ViewPostalResponseButton,
  ViewQaButton,
} from '@amgen/feature-asset-actions';
import { BookmarkToggleButton } from '@amgen/feature-bookmarks';
import { DocRatingBtn } from '@amgen/feature-feedbacks';
import { Asset } from '@amgen/feature-search';
import { AssetContextProvider } from '@amgen/shared';
import classnames from 'classnames';
import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';

import './selected-asset.scss';

import { ClusterSearchContext } from '../cluster-search-context-provider/cluster-search-context';

/* eslint-disable-next-line */
export interface SelectedAssetProps {}

export function SelectedAsset(props: SelectedAssetProps) {
  const { selectedResult } = useContext(ClusterSearchContext);
  return (
    <Col xs="6" className="ml-3">
      {selectedResult && (
        <Row as="li" className={classnames(false ?? 'shadow-sm mx-0', 'search-result-item pt-3 pb-2 rounded h-100')}>
          <Col xs="12" className="px-3">
            <AssetContextProvider docSolrAsset={selectedResult} isBookmarksDisabled={false}>
              <Col
                xs="12"
                className="d-flex justify-content-between px-3 mb-3 align-items-baseline font-weight-bold"
                style={{ fontSize: '1.25em' }}
              >
                <Asset.Title docPosition={0} />
                <div className="d-flex align-items-center">
                  <DocRatingBtn asset={selectedResult.dataAsset!} />
                  <Asset.Actions>
                    <BookmarkToggleButton />
                    {/* TODO - Jed: handle circular dependency here */}
                    {/* <ReportButton /> */}
                    <AssetActionsMenu>
                      <ViewAssetContentButton />
                      <ViewAttachmentsButton />
                      <ViewAttendeeButton />
                      <ViewDocContentButton />
                      <ViewDownloadsButton />
                      <ViewQaButton />
                      <ViewInquiryButton />
                      <ViewEmailResponseButton />
                      <ViewPostalResponseButton />
                    </AssetActionsMenu>
                  </Asset.Actions>
                </div>
              </Col>

              <Row className={classnames('overflow-hidden')}>
                <Asset.Image showThumbnail />
                <Asset.Attributes displayRatio={[4, 8]} showThumbnail />
              </Row>
            </AssetContextProvider>
          </Col>
        </Row>
      )}
    </Col>
  );
}

export default SelectedAsset;
