import { TEXT_SHOW_LIMIT } from '@amgen/utils';
import React from 'react';

import './highlighted-keywords.scss';

import { STOPWORDS } from './stopwords';

export interface HighlightedKeywordsProps {
  text: string;
  query: string;
}

export const HighlightedKeywords: React.FC<HighlightedKeywordsProps> = ({ text, query }) => {
  const keywords = new Set(
    query
      .replace(/^"|"$/g, '')
      .split(' ')
      .filter(word => !STOPWORDS.has(word.toLowerCase()))
      .filter(word => {
        try {
          RegExp(word, 'i');
          return true;
        } catch {
          return false;
        }
      })
  );

  const splitOutput = (output: string[], keywords: Set<string>): string[] => {
    let parts = [...output];
    let newParts: string[] = [];

    keywords.forEach(keyword => {
      parts.forEach(part => {
        newParts = newParts.concat(part.split(new RegExp(`(${keyword})`, 'gi')));
      });
      parts = [...newParts];
      newParts = [];
    });

    return parts.slice(1);
  };

  const firstIndexOfEachKeyword = [...keywords]
    .map(word => text.search(new RegExp(word, 'i')))
    .filter(index => index > -1);

  const keywordFound = firstIndexOfEachKeyword && firstIndexOfEachKeyword.length > 0;

  const paragraphStartIndex =
    firstIndexOfEachKeyword && firstIndexOfEachKeyword.length ? Math.min(...firstIndexOfEachKeyword) : 0;

  const textExcerpt = text.substring(paragraphStartIndex - 73, paragraphStartIndex + TEXT_SHOW_LIMIT);

  const sentences = textExcerpt.split(/[.]/);

  return (
    <>
      {keywords.size > 0 && keywordFound
        ? splitOutput(sentences, keywords).map((part, index) => (
            <span key={index}>
              {keywords.has(part.toLowerCase()) ? (
                <span style={{ backgroundColor: 'yellow', fontWeight: 'bold' }}>{part}</span>
              ) : (
                part
              )}
            </span>
          ))
        : textExcerpt}
    </>
  );
};

export default HighlightedKeywords;
