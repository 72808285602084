import { useAddQueryFeedbackMutation } from '@amgen/api';
import { MatomoContext, SEARCH_APP_FEATURE_LIST, SearchContext, useSearchQueryValue } from '@amgen/shared';
import { faFrown, faMeh, faSmile } from '@fortawesome/free-regular-svg-icons';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FeedbackModal from 'libs/feature-feedbacks/src/lib/components/feedback-modal/feedback-modal';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import './user-feedback.scss';

export const UserFeedback = props => {
  const {
    DetailedFeedbackFlag,
    setFlag,
    selectedMood,
    selectMood,
    showFeedbackBtn,
    setShowFeedbackBtn,
    setTypeOfFeedback,
    setIsUserFeedbackCompleted,
    setOpenFeedbackFromSearchQuery,
  } = useContext(SearchContext);

  const [successFlag, setSuccessFlag] = useState(false);
  const [addFeedback, { loading }] = useAddQueryFeedbackMutation();
  const abTestingGroup = JSON.parse(sessionStorage.getItem('abTestingGroup') as string);
  const query = useSearchQueryValue();
  const { trackApplicationFeature } = useContext(MatomoContext);

  const searchFeedbackDataCopy = sessionStorage.getItem('feedbackData') || 'feedbackData';
  const searchFeedbackDetails = {
    already_reported: JSON.parse(searchFeedbackDataCopy)?.already_reported,
    days_from_last_feedback: JSON.parse(searchFeedbackDataCopy)?.days_from_last_feedback,
    hideSearchQuery: JSON.parse(searchFeedbackDataCopy)?.hideSearchQuery,
  };

  return searchFeedbackDetails?.hideSearchQuery === false ? (
    <Row as="li" className="mx-4 py-2 pb-2 my-3 feedback-border mt-4 mb-4">
      <Col className="px-3 d-flex align-items-center">
        <FontAwesomeIcon
          title="thumbsup"
          icon={faThumbsUp}
          className="fa-3x cursor-pointer thumbs-up-feedback animating-thumbs-up-icon"
        />
        <div>
          <span className="d-flex justify-content-between px-3 align-items-baseline mb-0 col-12 font-weight-bold">
            Did you find what you were looking for?
          </span>
          <span className="d-flex justify-content-between px-3 align-items-baseline mb-0 col-12">
            Please provide your feedback:
          </span>
        </div>
      </Col>
      <Col xs="5" className=" row  col-5  text-center justify-content-center">
        <div
          onClick={() => {
            addFeedback({
              variables: {
                queryFeedback: {
                  feedback: 'Satisfied',
                  feedbackCategories: [''],
                  feedbackComment: '',
                  applicationName: 'EEA',
                  searchTerm: query,
                  userConsent: false,
                  userFunctionalGroup: [''],
                  abGroup: abTestingGroup,
                },
              },
            }).catch(e => console.log(e));
            sessionStorage.setItem(
              'feedbackData',
              JSON.stringify({ already_reported: true, days_from_last_feedback: 0, hideSearchQuery: true })
            );
            setFlag(true);
            selectMood('Satisfied');
            setShowFeedbackBtn(false);
            setTypeOfFeedback('searchResultsFeedback');
            setOpenFeedbackFromSearchQuery(true);
            setIsUserFeedbackCompleted(true);
            trackApplicationFeature(SEARCH_APP_FEATURE_LIST.SEARCH_RESULT_FEEDBACK_RATING, 'Satisfied');
          }}
          className="cursor-pointer Satisfied"
        >
          <FontAwesomeIcon title="Satisifed" icon={faSmile} className="fa-5x pl-3 cursor-pointer pr-2" />
          {/* <HappyOutlinedSmiley className="fa-5x pl-3 cursor-pointer pr-2" /> */}
        </div>
        <div
          onClick={() => {
            setFlag(true);
            selectMood('Neutral');
            setShowFeedbackBtn(false);
            setTypeOfFeedback('searchResultsFeedback');
            setOpenFeedbackFromSearchQuery(true);
          }}
          className="cursor-pointer Neutral"
        >
          <FontAwesomeIcon title="Neutral" icon={faMeh} className="fa-5x pl-3 cursor-pointer pr-2" />
          {/* <NeutralOutlinedSmiley className="fa-5x pl-3 cursor-pointer pr-2" /> */}
        </div>
        <div
          onClick={() => {
            setFlag(true);
            selectMood('Disappointed');
            setShowFeedbackBtn(false);
            setTypeOfFeedback('searchResultsFeedback');
            setOpenFeedbackFromSearchQuery(true);
          }}
          className="cursor-pointer Disappointed"
        >
          {/* <SadOutlinedSmiley className="fa-5x pl-3 cursor-pointer pr-2" /> */}
          <FontAwesomeIcon title="Disappointed" icon={faFrown} className="fa-5x pl-3 cursor-pointer pr-2" />
        </div>
      </Col>
    </Row>
  ) : DetailedFeedbackFlag && showFeedbackBtn ? (
    <FeedbackModal showFeedbackBtn={showFeedbackBtn} setShowFeedbackBtn={setShowFeedbackBtn} />
  ) : (
    <></>
  );
};

export default UserFeedback;
