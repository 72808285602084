import { DataSource, IAssetAttribute, ITypeaheadSuggestion } from '@amgen/core';
import { FiltersObject } from '@amgen/shared';
import { createContext } from 'react';

export interface ISearchBoxState {
  isSearchExpanded?: boolean;
  setIsSearchExpanded?: (value: boolean) => void;
  searchSize?: 'sm' | 'lg';
  showAdvanceSearch?: boolean;
  contentSelected: DataSource[] | [];
  setContentSelected: (dataSources: DataSource[]) => void;
  dateFilterSelected: boolean;
  setDateFilterSelected: (value: boolean) => void;
  onSearch: (query: string, selectedTypeName: string) => void;
  applyFilter: (term: string) => void;
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  keyUpdate: string;
  setKeyUpdate: React.Dispatch<React.SetStateAction<string>>;
  reusedQuery: boolean;
  setReusedQuery: (value: boolean) => void;
  loading: boolean;
  selectedFilter?: IAssetAttribute | null;
  setSelectedFilter: (modifier: IAssetAttribute | null) => void;
  dropdownOptions: ITypeaheadSuggestion[];
  hasSearchBoxFacetingExperience: boolean;
  openSuggestionDrop: boolean;
  setOpenSuggestionDrop: (value: boolean) => void;
  placeholder: string;
  showAdvSearch: boolean;
  setShowAdvSearch: (value: boolean) => void;
  typeaheadRef: React.MutableRefObject<any>;
  setBlockScroll?: (value: boolean) => void;
  selectedTypeaheadFilters: FiltersObject;
  setSelectedTypeaheadFilters: (filters: FiltersObject) => void;
  selectedTypeaheadFilterCategories: Set<IAssetAttribute>;
  setSelectedTypeaheadFilterCategories: (filterCategories: Set<IAssetAttribute>) => void;
  onExternalSearch?: (keyword: string, filters: FiltersObject) => void;
  showQuickFilters?: boolean;
  payload?: any;
  setPayload?: any;
  selectedTypeName:string;
  setSelectedTypeName:any;

  callAPI?: boolean;
  setCallAPI?: (value: boolean) => void;
}

export const SearchBoxContext = createContext<ISearchBoxState>(null!);
