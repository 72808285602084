import React from 'react';
import { Button } from 'react-bootstrap';

import './knowledge-panel-icon.scss';

export interface KnowledgePanelIconProps {
  onToggleGraph: () => void;
}
// explore-container
export const KnowledgePanelIcon: React.FC<KnowledgePanelIconProps> = ({ onToggleGraph }) => {
  return (
    <div className="col-6 d-flex justify-content-end p-0">
      <Button
        title="Wikipedia-like panel capturing Amgen products information"
        onClick={onToggleGraph}
        variant="primary"
        size="sm"
        className="explore-button"
      >
        Knowledge Panel
      </Button>
    </div>
  );
};

export default KnowledgePanelIcon;
