import { IAssetAttributeTypeProps } from '@amgen/shared';
import { format } from 'date-fns';
import React from 'react';

import './date-attribute.scss';

import TextAttribute from '../text-attribute/text-attribute';

export const DateAttribute: React.FC<IAssetAttributeTypeProps> = props => {
  const value = props.value?.toString();
  return value ? <TextAttribute value={format(new Date(value), 'MMM dd, yyyy')} assetId={props.assetId} /> : null;
};

export default DateAttribute;
