import { ISolrDataCrawlField } from './filter-type';

/**
 * A Filters object that return a new copy of itself after every operation
 */
export class IGDFiltersObject {
  private static readonly exceptions: ISolrDataCrawlField[] = [];
  public static readonly rangeKeys: ISolrDataCrawlField[] = ['created_on'];

  static fromJSONString(json: string) {
    try {
      const obj: { [category in ISolrDataCrawlField]: string[] } = JSON.parse(json);
      const filters = Object.fromEntries(Object.entries(obj).map(([category, values]) => [category, new Set(values)]));
      return new IGDFiltersObject(filters);
    } catch (e) {
      console.error('Invalid JSON', e);
      throw new Error('JSONError: Invalid JSON');
    }
  }

  constructor(public filters?: { [category in ISolrDataCrawlField]?: Set<string> }) {
    // Create a deep copy if something is passed as parameter
    this.filters = filters
      ? Object.fromEntries(Object.entries(filters).map(([key, values]) => [key, new Set(values)]))
      : {};
  }

  get count() {
    return Object.entries(this.filters!)
      .filter(([category, _]) => !IGDFiltersObject.exceptions.includes(category as ISolrDataCrawlField))
      .reduce((count, [_, values]) => count + (values ? values.size : 0), 0);
  }

  get categories() {
    return Object.keys(this.filters!) as ISolrDataCrawlField[];
  }

  rangeKeys() {
    return IGDFiltersObject.rangeKeys;
  }

  clone() {
    return new IGDFiltersObject(this.filters);
  }

  add(value: string | string[], category: ISolrDataCrawlField) {
    const values = this.filters![category] || new Set();

    if (!IGDFiltersObject.rangeKeys.includes(category)) {
      if (value instanceof Array) {
        value.forEach(v => values.add(v));
      } else {
        values.add(value);
      }
    } else {
      if (value instanceof Array && value.length === 2) {
        values.add(`${value[0]} ~ ${value[1]}`);
      } else {
        console.error('range filter not in format [string,string]');
      }
    }

    this.filters![category] = values;
    return new IGDFiltersObject(this.filters);
  }

  remove(value: string, category: ISolrDataCrawlField) {
    const values = this.filters![category] || new Set();
    values.delete(value);
    if (values.size) {
      this.filters![category] = values;
    } else {
      delete this.filters![category];
    }
    return new IGDFiltersObject(this.filters);
  }

  toggle(value: string, category: ISolrDataCrawlField) {
    const values = this.filters![category] || new Set();
    return values.has(value) ? this.remove(value, category) : this.add(value, category);
  }

  removeAll(category: ISolrDataCrawlField) {
    if (this.filters && this.filters[category]) {
      delete this.filters[category];
    }
    return new IGDFiltersObject(this.filters);
  }

  getValues(category: ISolrDataCrawlField) {
    return this.filters![category] ?? [];
  }

  clear() {
    return new IGDFiltersObject();
  }

  toJSONString() {
    return JSON.stringify(
      Object.fromEntries(
        Object.entries(this.filters!).map(([category, values]) => [category, Array.from(values || [])])
      )
    );
  }

  toQueryParam() {
    return Object.entries(this.filters!).map(([category, values]) => {
      let categoryQueryParam = '';
      if (!IGDFiltersObject.rangeKeys.includes(category as ISolrDataCrawlField)) {
        categoryQueryParam =
          category !== 'filename'
            ? [...values!].map(value => `${category} : "${value}"`).join(' OR ')
            : [...values!].map(value => `${category} : ${value}`).join(' OR ');
      } else if (values && values.size === 1) {
        const iterator = values.values().next().value.split(' ~ ');
        categoryQueryParam = `${category}:[${iterator[0]} TO ${iterator[1]}]`;
      }
      return categoryQueryParam;
    });
  }
}
