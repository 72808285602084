import { useWindowSizeListener, useWindowZoomListener } from '@amgen/shared';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import React, { Suspense, useState } from 'react';
import { Card } from 'react-bootstrap';

import './generic-card.scss';

const LazyImage = React.lazy(() => import('./lazy-image'));
export interface GenericCardProps {
  poster: string;
  posterHeight?: number | string;
  overlay?: boolean;
  horizontal?: boolean;
  title?: string;
  subtitle: string;
  link?: string;
  linkText?: string;
  overlayColor?: string;
  isThisCardLink?: boolean;
  className?: string;
}

export const GenericCard: React.FC<GenericCardProps> = props => {
  const CardContainer = Card.Body;
  const isMobile = useWindowSizeListener();
  const [fallbackImg, setFallbackImg] = useState(props.poster);
  const isZoomed = useWindowZoomListener();
  return (
    <Card
      className={classnames('h-100 generic-card cursor-pointer', props.className, {
        'flex-row': props.horizontal,
      })}
      onClick={() => {
        if (props.isThisCardLink && props.link) {
          window.open(props.link, '_blank');
        }
      }}
    >
      {/* todo tidy up the image height  */}
      {!(isMobile && isZoomed) && (
        <div className="img-hover-zoom bg-white" style={{ height: !isMobile ? props.posterHeight ?? '30vh' : '12rem' }}>
          {/* <LazyLoad height={300} once placeholder={<div className="bg-grey skeleton w-100 h-100"></div>} > */}
          <Suspense fallback={<div className="bg-grey skeleton w-100 h-100"></div>}>
            <LazyImage
              poster={fallbackImg}
              className={classnames('w-100 h-100 img-hover-zoom--quick-zoom ', {
                'h-100': props.overlay,
              })}
            />
            {/* // <Card.Img
          //   as={LazyImage}
          //   className={classnames('w-100 h-100 img-hover-zoom--quick-zoom ', {
          //     'h-100': props.overlay,
          //   })}
          // /> */}
            {/* </LazyLoad> */}
          </Suspense>
        </div>
      )}
      <CardContainer
        className={classnames('d-flex flex-column p-3 bg-white', {
          'pt-0': !props.horizontal && !props.overlay,
        })}
      >
        {props.title ? (
          <Card.Title as="h2" className={classnames('mb-1 font-weight-bold', { 'text-primary': !props.overlay })}>
            {props.title}
          </Card.Title>
        ) : null}

        <Card.Subtitle
          as="h5"
          className={classnames(' mt-1 font-weight-normal text-uppercase', { 'text-muted': !props.overlay })}
        >
          {props.subtitle}
        </Card.Subtitle>

        <div className="h-100">{props.children}</div>

        <Card.Link
          href={props.link}
          className={classnames('d-block text-right mt-auto', { 'text-white': props.overlay })}
        >
          {props.linkText ? (
            <>
              <span className="mr-2">{props.linkText}</span>
              <FontAwesomeIcon icon={faArrowRight} />
            </>
          ) : null}
        </Card.Link>
      </CardContainer>
    </Card>
  );
};

export default GenericCard;
