import { ISavedSearchInput } from '@amgen/core';
import { isBefore, isEqual } from 'date-fns';

export enum SUBSCRIPTION_TYPE {
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
}

export const NEVER_END_DATE = '';

export const TOP_RESULT_COUNT = [4, 6, 10];

export interface SaveSubscribeFormValue
  extends Pick<
    ISavedSearchInput,
    | 'id'
    | 'name'
    | 'query'
    | 'filters'
    | 'subscrptnIsactive'
    | 'subscrptnType'
    | 'subscrptnMailIds'
    | 'subscrptnRecordCount'
    | 'subscrptnStartDate'
    | 'subscrptnEndDate'
    | 'sourceType'
  > {
  subscrptnType?: SUBSCRIPTION_TYPE;
}

export function validate(values: SaveSubscribeFormValue) {
  const errors: { [key in keyof SaveSubscribeFormValue]?: string } = {};

  if (!values.name?.trim()) {
    errors.name = 'Save Search Name is required';
  }
  if (values.subscrptnIsactive) {
    if (!values.subscrptnStartDate) {
      errors.subscrptnStartDate = 'Start Date is required';
    }

    if (
      values.subscrptnStartDate &&
      values.subscrptnEndDate &&
      values.subscrptnEndDate !== NEVER_END_DATE &&
      !isEqual(new Date(values.subscrptnStartDate), new Date(values.subscrptnEndDate)) &&
      !isBefore(new Date(values.subscrptnStartDate), new Date(values.subscrptnEndDate))
    ) {
      errors.subscrptnEndDate = 'End date should be greater than Start date';
    }

    if (!values.subscrptnMailIds || !values.subscrptnMailIds.length) {
      errors.subscrptnMailIds = 'Required';
    } else if (!values.subscrptnMailIds.every(isValidEmail)) {
      errors.subscrptnMailIds = 'Invalid email address';
    }
  }

  return errors;
}

export const isValidEmail = (x: string): boolean => {
  return /(^[a-zA-Z])+(?:[-\w])*@amgen.com$/.test(x);
};
