// Matomo Events in search application are being used to track feature usage

// [todo] break this document into parts
export enum DOCUMENT_INTERACTIONS_ACTIONS {
  BOOKMARK = 'Bookmark',
  COLLABORATION = 'Collaboration',
  MORE_ACTION_OPTIONS = 'More Actions option',
  DOCUMENT_RATING = 'Document Rating',
  DOCUMENT_FEEDBACK_COMMENT = 'Document Feedback Comment',
}

export enum SAVING_CONFIGURATIONS_ACTIONS {
  SAVE_DEFAULT_FILTERS = 'Save Default Filters',
  SAVE_DEFAULT_VIEW = 'Save Default View ',
  SAVE_DEFAULT_COLUMNS = 'Save Default Columns',
  SAVE_SEARCH = 'Save Search',
  SUBSCRIBE_SEARCH = 'Subscribe Search',
  VIEW_SAVED_SEARCHES = 'View Saved Searches',
}

export enum RESULT_VIEW_CONFIGURATIONS_ACTIONS {
  RESULT_VIEW_PAGINATION = 'Pagination',
  RESULT_VIEW_SORTING = 'Sorting',
  RESULT_VIEW_RESULTS_PER_PAGE = 'Results per page',
  RESULT_VIEW_STYLE = 'View Style',
}

export enum FACET_FEATURE_ACTIONS {
  ADD_FILTER = 'Add Filter',
  CLEAR_ALL = 'Clear All',
  SELECT_FILTERS = 'Select Filters',
  ADVANCED_FILTERS_CLICKED = 'Advanced Filters Clicked',
}

export enum EXPORT_CSV_FEATURE_ACTIONS {
  SELECT_FIELDS = 'Updated Fields in Export CSV',
  RECORD_COUNT = 'Record Count',
}

export enum PAGE_LOAD_FEATURE_ACTIONS {
  USER_ACCESS = 'Fetching user access',
  USER_PREFERENCE = 'Fetching user preferences',
  SEARCH_RESULTS = 'Fetching search results',
}

export enum DASHBOARD_FEATURE_ACTIONS {
  INTERACTION_CHART = 'Interaction Chart',
  PRODUCT_CHART = 'Product Chart',
  THERAPEUTIC_CHART = 'Therapeutic Chart',
  QUICK_RANGE = 'Quick Range Options',
  CONTENT_SOURCE_CHART = 'Content Source Chart',
  CREATED_ON_CHART = 'Created On Chart',
  EXTRACTED_MOLECULES_CHART = 'Most Occurring Chart',
  FREQUENT_MODALITIES_CHART = 'Most Frequent Modalities',
}

export enum TOPLINKS_FEATURE_ACTIONS {
  POSITIVE_FEEDBACK = 'Q&A Positive Feedback',
  NEGATIVE_FEEDBACK = 'Q&A Negative Feedback',
  RECOMMENDATION_COUNT = 'Q&A recommendation Count',
  CLICK_EVENT = 'Clicks on Toplinks',
}

export enum KNOWLEDGE_PANEL_FEATURE_ACTIONS {
  SELECT_RELATED_PRODUCT = 'Select Related Product',
  SELECT_STUDY = 'Select Study',
  VIEW_GRAPH = 'Knowledge Graph',
  VIEW_PANEL = 'Knowledge Panel',
  VIEW_CLINICAL_STUDIES_TAB = 'Clinical Studies Tab',
  KNOWLEDGE_PANEL_VISITED = 'Knowledge Panel Visited',
}

export enum SEARCH_BOX_TYPEAHEAD_ACTIONS {
  SELECT_DOCUMENT_SUGGESTION = 'Document Suggestion',
  SELECT_PREVIOUS_SEARCH_SUGGESTION = 'Previous Search Suggestion',
  SELECT_TYPEAHEAD_FILTER = 'Filter Suggestion',
}

export enum IGD_ACTIONS {
  ADD_REQ_FROM_MENU = 'Add Request (From Menu)',
  ADD_REQ_FROM_LANDING = 'Add Request (From Landing)',
  ADD_REQ_FROM_TAB = 'Add Request (From Tab)',
  APPROVE_REQUEST = 'Approve Request',
  DECLINE_REQUEST = 'Decline Request',
  IGD_SORT = 'Sort',
  IGD_SEARCH = 'Search',
  IGD_PAGINATE = 'Pagination',
  IGD_EXPORT_ADMIN = 'Export CSV (Admin Page)',
  IGD_EXPORT_REQUEST = 'Export CSV (Request Page)',
}

export enum SPELLCHECK_ACTIONS {
  SPELLCHECK = 'Spellcheck Display',
  SPELLCHECK_CLICK = 'Clicks on SpellCheck',
}

export enum REPORT_BTN_ACTIONS {
  REPORT = 'Report Document',
}

export enum CLUSTER_SEARCH_FEATURE_ACTIONS {
  VIEW_TOPICS_TAB = 'View Topics Tab',
  SUGGESTED_TOPICS_BUTTON = 'Suggested Topics Button',
  TOPIC_SELECTED = 'Suggested Topic Selected',
  CLUSTER_RESULT_SELECTED = 'Cluster Result Selected',
}

export enum CHATBOT_ACTIONS {
  OPEN_CHATBOT = 'Open Chatbot',
  MESSAGE_SENT = 'Message Sent',
  RICH_MESSAGE_CLICKED = 'Rich Message Clicked',
}

export const enum ROADMAP_ACTIONS {
  LIKE_FROM_CHART = 'Like a Feature (from chart)',
  LIKE_FROM_POPUP = 'Like a Feature (from popup)',
  COMMENT = 'Comment on Feature',
  EDIT_COMMENT = 'Edit Comment on Feature',
  NAVIGATE_NEXT_QUARTER = 'Navigate NEXT Quarter',
  NAVIGATE_PREV_QUARTER = 'Navigate PREV Quarter',
  VIEW_POPUP_FEATURE_BAR = 'View Feature Details(from bar)',
  VIEW_POPUP_MORE_ICON = 'View Feature Details(from more icon)',
  VIEW_POPUP_COMMENT = 'View Feature Details(from comment)',
}

export const enum APPLICATION_FEEDBACK_ACTIONS {
  APPLICATION_RATING = 'Application Rating',
  APPLICATION_FEEDBACK_COMMENT = 'Application Feedback Comment',
}

export const enum GENAI_QnA_ACTIONS {
  GENAI_QnA_INVOKED = 'GenAI QnA is Invoked',
  GENAI_QnA_ACCORDIAN_CLICKS = 'GenAI QnA Accordian Clicks',
}

export const enum CONVERSATIONAL_SEARCH_QnA_ACTIONS {
  CONVERSATIONAL_SEARCH_QnA_INVOKED = 'Conversational Search Component is displayed with valid Summary',
  CONVERSATIONAL_SEARCH_QnA_ACCORDIAN_CLICKS = 'Conversational Search Component is engaged',
  CONVERSATIONAL_SEARCH_QnA_FOLLOWUPS = 'Conversational Search Follow Ups',
  CONVERSATIONAL_SEARCH_CITATION_CLICKS = 'Conversational Search Citation Clicks',
}

export const enum SEARCH_RESULT_FEEDBACK_ACTIONS {
  USER_SEARCH_TERM = 'Search Term',
  USER_SEARCH_REULT_RATING = 'Search Result Feedback Rating',
  USER_SEARCH_RESULT_FEEDBACK_COMMENT = 'Search Result Feedback Comment',
}
export const enum SPOTLIGHT_ACTIONS {
  SPOTLIGHT_TITLE = 'Spotlight Title',
  SPOTLIGHT_CONTENT = 'Spotlight Content',
  SPOTLIGHT_SITE_ICON = 'Site Icon',
  SPOTLIGHT_SITE_NAME = 'Site Name',
  SPOTLIGHT_PREV_NAV = 'Previous Slide',
  SPOTLIGHT_NEXT_NAV = 'Next Slide',
  SPOTLIGHT_VIDEO_CONTENT = 'Video Content',
}

export const enum CUSTOM_GROUP_SOURCES_CATEGORIES {
  CONTENT_SOURCES = 'Content Sources',
  CONTENT_SOURCE_CATEGORIES = 'Content Source Categories (Homepage)',
  CREATE_CONTENT_GROUP = 'Create Content Group',
  CREATE_CUSTOM_CONTENT_GROUP = 'Create Custom Content Group',
  PREDEFINED_CONTENT_SOURCE = 'Predefined Content Source',
  CUSTOM_CONTENT_SOURCE_GROUP = 'Custom Content Group',
}
export enum HELP_CENTER_ACTIONS {
  HELP_CENTER_CLICK = 'Clicks on Help Center',
}

export enum ACRONYMS_ACTIONS {
  ACRONYM_WIKI_LINK = 'Clicks on Acronym Wiki Link',
}

export enum RECOMMENDED_EVENTS_ACTIONS {
  EVENT_CLICKS = 'Clicks on Event',
}

export enum AMGEN_NEWS_ACTIONS {
  NEWS_CLICKS = 'Clicks on News',
}

export enum SUGGESTED_DESTINATIONS_ACTIONS {
  DESTINATION_CLICKS = 'Clicks on Suggested Destinations',
}

export enum AISERA_CHATBOT_ACTIONS {
  CHATBOT_INVOKED = 'Chatbot is Invoked',
  CHATBOT_NOT_INVOKED = 'Chatbot not Available',
  SEE_MORE_CLICKED = 'See More Clicked',
  CHAT_TAB_CLICKED = 'Chat Tab Clicked',
  MINIMIZED_AVA_CLICKED = 'Minimized AVA Clicked',
}

export enum CUSTOM_SOURCE_CREATION_ACTIONS {
  CREATED_FROM_HOME_PAGE = 'Created from Home Page',
  CREATED_FROM_SEARCH_PAGE = 'Created from Search Page',
}

export enum HEADER_TAB_ACTIONS {
  RECOMMENDED_TAB = 'Recommended Tab Clicked',
  ALL_RESULTS_TAB = 'All Results Tab Clicked',
}

export enum SEARCH_APP_FEATURE_LIST {
  HEADER_TAB_CLICKS = 'Header Tab Navigation',
  DOCUMENT_INTERACTIONS = 'Document Interactions',
  SAVING_CONFIGURATIONS = 'Saving Configurations',
  RESULT_VIEW_CONFIGURATIONS = 'Result View Configurations',
  FACET_FEATURE = 'Filter/facets Feature',
  EXPORT_CSV = 'Export CSV',
  DASHBOARD = 'Dashboard Visuals',
  TOPLINKS = 'Toplinks',
  KNOWLEDGE_PANEL = 'Knowledge Panel',
  SEARCH_BOX_TYPEAHEAD = 'Search Box Typeahead',
  SPELL_CHECK = 'Spellcheck',
  IGD = 'Information Governance Dashboard',
  REPORT_BTN = 'Report Button',
  LANDING_PAGE_NAVIGATION = 'Landing Page Navigation',
  APPLICATION_FEEDBACK = 'Application Feedback',
  GEN_AI_QnA = 'GenAI QnA',
  GEN_AI_CONVERSATIONAL_SEARCH = 'GenAI Conversational Search',
  CLUSTER_SEARCH = 'Cluster Search',
  CHATBOT = 'Chatbot',
  ROADMAP = 'Roadmap',
  SPOTLIGHT = 'Spotlight',
  CONTENT_GROUP_SOURCE = 'Content Group Source',
  CONTENT_SOURCES = 'Content Sources',
  SEARCH_RESULT_FEEDBACK_RATING = 'Search Result Feedback Rating',
  SEARCH_RESULT_FEEDBACK_COMMENT = 'Search Result Feedback Comment',
  HELP_CENTER = 'Help Center',
  PAGE_LOAD_TRACKING = 'Page Load Tracking',
  ACRONYMS = 'Acronyms',
  RECOMMENDED_EVENTS = 'Recommended Events',
  AMGEN_NEWS = 'Amgen News',
  SUGGESTED_DESTINATIONS = 'Suggested Destinations',
  AISERA_CHATBOT = 'Enterprise VA',
  AISERA_CHATBOT_INTERACTION_WITH_INTENT = 'Enterprise VA Interaction with Intent',
  AISERA_CHATBOT_INTERACTION_WITHOUT_INTENT = 'Enterprise VA Interaction without Intent',
  ENTERPRISE_VA_CHANNEL = 'Enterprise VA Channel',
  CUSTOM_SOURCE_CREATION = 'Custom Source Creation',
}
