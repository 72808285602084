import { IAsset } from '@amgen/core';
import React, { useMemo, useState } from 'react';

import './asset-context-provider.scss';

import { ResultViewType } from '../../models';
import { AssetContext, IAssetState } from './asset-context';

export interface AssetContextProviderProps {
  docSolrAsset: IAsset;
  isBookmarksDisabled: boolean;
  viewType?: ResultViewType;
  isToggleEnabled?: boolean;
}

export const AssetContextProvider: React.FC<AssetContextProviderProps> = ({
  children,
  docSolrAsset,
  isBookmarksDisabled,
  viewType,
}) => {
  const [isToggleEnabled, setToggleEnabled] = useState(false);
  const [showReadMoreFlag, setShowReadMoreFlag] = useState(true);
  const providedValue = useMemo<IAssetState>(
    () => ({
      docSolrAsset,
      isBookmarksDisabled,
      viewType,
      isToggleEnabled,
      showReadMoreFlag,
      setToggleEnabled,
      setShowReadMoreFlag,
    }),
    [docSolrAsset, isBookmarksDisabled, viewType, isToggleEnabled, showReadMoreFlag]
  );

  return <AssetContext.Provider value={providedValue}>{children}</AssetContext.Provider>;
};

export default AssetContextProvider;
