import {
  DateRangeChart,
  FacetBarChart,
  FacetPieChart,
  QuickRangeBar,
  useDashBoard,
  useDashboardFilterFunctions,
  ViewResultLink,
} from '@amgen/feature-dashboard';
import { AppliedFiltersBar } from '@amgen/feature-facets';
import {
  DASHBOARD_FEATURE_ACTIONS,
  PAGES,
  QUICK_RANGE_OPTIONS,
  SEARCH_APP_FEATURE_LIST,
  SearchContextProvider,
  useAltHotKey,
  usePageTrackingWithTitle,
  useSearchQueryValue,
} from '@amgen/shared';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import './dashboard-page.scss';

/* eslint-disable-next-line */
export interface DashboardPageProps {}

export const DashboardPage = (props: DashboardPageProps) => {
  const query = useSearchQueryValue();

  const {
    dashboardFilters,
    setDashboardFilters,
    selectedQuickRange,
    filters,
    chartType,
    setChartType,
    setDashboardFilterAndCategory,
    trackApplicationFeature,
  } = useDashBoard();
  const {
    setDateFiltersFromRange,
    setDateFiltersFromCustomRange,
    resetDashboardFilters,
    onRemoveDashboardFilter,
  } = useDashboardFilterFunctions();

  //Alt + c to clear all dashboard filters
  useAltHotKey(() => setDashboardFilters(dashboardFilters.clear()), 'c');

  usePageTrackingWithTitle(PAGES.DASHBOARD);

  return (
    <SearchContextProvider query={query} filters={filters}>
      <Row noGutters style={{ minHeight: '70vh' }} className="justify-content-center px-2">
        {/* revisit with more optimizie solution */}
        <Col xs="12" className="px-3">
          <QuickRangeBar
            quickRangeOptions={QUICK_RANGE_OPTIONS}
            selectedLabel={selectedQuickRange.label}
            onSelect={option => {
              setDateFiltersFromRange(option);
              trackApplicationFeature(SEARCH_APP_FEATURE_LIST.DASHBOARD, DASHBOARD_FEATURE_ACTIONS.QUICK_RANGE);
            }}
            applyCustomDateFilter={setDateFiltersFromCustomRange}
          />
        </Col>
        <Col xs="12" className="p-3 text-center">
          <AppliedFiltersBar
            filters={dashboardFilters}
            isClearable
            onClearAll={() => resetDashboardFilters()}
            onRemove={(value, category) => onRemoveDashboardFilter(value, category)}
            customClassOnFilter={category => (category === 'doc_last_modified' ? 'bg-primary' : '')}
          />
        </Col>
        <Col xs="12" lg={6} className="chart-holder pt-2">
          <FacetBarChart
            filter="content_source"
            filterString={filters}
            chartTitle="CONTENT SOURCES"
            onFilterSelect={key => {
              setDashboardFilterAndCategory(`0/${key}`, 'doc_nav_prefix');
              trackApplicationFeature(
                SEARCH_APP_FEATURE_LIST.DASHBOARD,
                DASHBOARD_FEATURE_ACTIONS.CONTENT_SOURCE_CHART
              );
            }}
            isHorizontal
          />
        </Col>
        <Col xs="12" lg={6} className="chart-holder pt-2">
          <DateRangeChart
            filter="created_on"
            chartTitle="CREATION DATE"
            chartType={chartType}
            setChartType={chart => {
              setChartType(chart);
            }}
            onFilterSelect={(start, end) => {
              setDashboardFilterAndCategory([start, end], 'created_on', true);
              trackApplicationFeature(SEARCH_APP_FEATURE_LIST.DASHBOARD, DASHBOARD_FEATURE_ACTIONS.CREATED_ON_CHART);
            }}
            hasAreaGradient
          />
        </Col>
        <Col xs="12" lg="12" className="chart-holder py-2">
          <FacetBarChart
            filter="product"
            filterString={filters}
            chartTitle="PRODUCT"
            onFilterSelect={key => {
              setDashboardFilterAndCategory(key, 'product');
              trackApplicationFeature(SEARCH_APP_FEATURE_LIST.DASHBOARD, DASHBOARD_FEATURE_ACTIONS.PRODUCT_CHART);
            }}
          />
        </Col>
        <Col xs="12" lg="12" className="chart-holder py-2">
          <FacetBarChart
            filter="extracted_molecules"
            filterString={filters}
            chartTitle="MOST OCCURRING MOLECULES"
            onFilterSelect={key => {
              setDashboardFilterAndCategory(key, 'extracted_molecules');
              trackApplicationFeature(
                SEARCH_APP_FEATURE_LIST.DASHBOARD,
                DASHBOARD_FEATURE_ACTIONS.EXTRACTED_MOLECULES_CHART
              );
            }}
          />
        </Col>
        <Col xs="12" lg={6} className="chart-holder pt-2">
          <FacetPieChart
            filter="extracted_therapeutic_areas"
            filterString={filters}
            chartTitle="PROMINENT THERAPEUTIC AREAS"
            onFilterSelect={key => {
              setDashboardFilterAndCategory(key, 'extracted_therapeutic_areas');
              trackApplicationFeature(SEARCH_APP_FEATURE_LIST.DASHBOARD, DASHBOARD_FEATURE_ACTIONS.THERAPEUTIC_CHART);
            }}
          />
        </Col>
        <Col xs="12" lg={6} className="chart-holder pt-2">
          <FacetPieChart
            filter="extracted_modalities"
            filterString={filters}
            chartTitle="MOST FREQUENT MODALITIES"
            onFilterSelect={key => {
              setDashboardFilterAndCategory(key, 'extracted_modalities');
              trackApplicationFeature(
                SEARCH_APP_FEATURE_LIST.DASHBOARD,
                DASHBOARD_FEATURE_ACTIONS.FREQUENT_MODALITIES_CHART
              );
            }}
          />
        </Col>
        <ViewResultLink />
      </Row>
    </SearchContextProvider>
  );
};

export default DashboardPage;
