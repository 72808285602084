import { ClinicalContext } from '@amgen/shared';
import React, { useContext } from 'react';
import { Col } from 'react-bootstrap';

import './knowledge-panel-study-attributes.scss';

/* eslint-disable-next-line */
export interface KnowledgePanelStudyAttributesProps {}

export function KnowledgePanelStudyAttributes(props: KnowledgePanelStudyAttributesProps) {
  const { product, clinicalId } = useContext(ClinicalContext);

  return (
    <>
      <Col xs="12" className="d-flex flex-wrap my-1 pl-0 text-capitalize">
        <span className="mr-2 font-weight-bold">Study Number:</span>
        <span>{clinicalId}</span>
      </Col>
      <Col xs="12" className="d-flex flex-wrap my-1 pl-0 text-capitalize">
        <span className="mr-2 font-weight-bold">Product:</span>
        <span>{product?.product ?? null}</span>
      </Col>
      {/* <Col xs="12" className="d-flex flex-wrap my-1 pl-0 text-capitalize">
        <span className="mr-2 font-weight-bold">Deliverables:</span>
        <span>
          {product?.studyInformation
            ?.find(study => study.number === clinicalId)
            ?.deliverable.map(d => (
              <Row noGutters>{d}</Row>
            ))}
        </span>
      </Col> */}
    </>
  );
}

export default KnowledgePanelStudyAttributes;
