import React from 'react';
import { Row } from 'react-bootstrap';

import './knowledge-panel-body.scss';

/* eslint-disable-next-line */
export interface KnowledgePanelBodyProps {}

export const KnowledgePanelBody: React.FC<KnowledgePanelBodyProps> = ({ children }) => {
  return <Row className="w-100 no-gutters">{children}</Row>;
};

export default KnowledgePanelBody;
