import React from 'react';

import './circular-bar.scss';

/* eslint-disable-next-line */
export interface CircularBarProps {
  sqSize: any;
  strokeWidth: any;
  text?: string;
  strokeColor?: string;
  percentage: number;
}

export const CircularBar: React.FC<CircularBarProps> = props => {
  // Size of the enclosing square
  const sqSize = props.sqSize;
  // SVG centers the stroke width on the radius, subtract out so circle fits in square
  const radius = (props.sqSize - props.strokeWidth) / 2;
  // Enclose cicle in a circumscribing square
  const viewBox = `0 0 ${sqSize} ${sqSize}`;
  // Arc length at 100% coverage is the circle circumference
  const dashArray = radius * Math.PI * 2;
  // Scale 100% coverage overlay with the actual percent
  const dashOffset = dashArray - (dashArray * props.percentage) / 100;

  return (
    <svg width="100%" height="100%" viewBox={viewBox} style={{ maxWidth: props.sqSize, maxHeight: props.sqSize }}>
      <circle
        className="circle-background"
        cx={props.sqSize / 2}
        cy={props.sqSize / 2}
        r={radius}
        strokeWidth={`${props.strokeWidth}px`}
      />
      <circle
        className="circle-progress"
        cx={props.sqSize / 2}
        cy={props.sqSize / 2}
        r={radius}
        strokeWidth={`${props.strokeWidth}px`}
        // Start progress marker at 12 O'Clock
        transform={`rotate(-90 ${props.sqSize / 2} ${props.sqSize / 2})`}
        style={{
          strokeDasharray: dashArray,
          strokeDashoffset: dashOffset,
          stroke: props.strokeColor ?? 'blue',
        }}
      />

      <foreignObject className="node" x="0" y="10" width={props.sqSize} height={props.sqSize}>
        {props.text ?? props.children}
      </foreignObject>
    </svg>
  );
};

export default CircularBar;
