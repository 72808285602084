/* eslint-disable @typescript-eslint/no-empty-interface */
import { useSearchQuery } from '@amgen/api';
import { DEFAULT_SEARCH_QUERY, IAssetAttribute, useEnvironment } from '@amgen/core';
import { useAssetAttachmentField } from '@amgen/feature-search';
import { AppContext, AssetContext, FiltersObject, MatomoContext, useAppliedFilters } from '@amgen/shared';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { Dropdown } from 'react-bootstrap';
import ReactDOM from 'react-dom';

import './asset-actions-menu.scss';

import { Item, ItemComposition, ItemProps } from './item/item';

export interface AssetActionsMenuComposition {
  Item: React.FC<ItemProps> & ItemComposition;
}

/* eslint-disable-next-line */
export interface AssetActionsMenuProps {}

export const AssetActionsMenu: React.FC<AssetActionsMenuProps> & AssetActionsMenuComposition = ({ children }) => {
  const { docSolrAsset } = useContext(AssetContext);
  const { trackApplicationFeature } = useContext(MatomoContext);
  const { internalSources } = useContext(AppContext);
  const { attachmentField } = useAssetAttachmentField(docSolrAsset.dataAsset!);
  const attachmentId = (docSolrAsset?.dataAsset?.[attachmentField] as string) ?? '';
  const filters = useAppliedFilters(
    attachmentId
      ? new FiltersObject()
          .add('attachment', 'content_type')
          .add(attachmentId, attachmentField || 'parent_object_id')
          .add('*', 'filename')
      : new FiltersObject()
  );
  const { appName } = useEnvironment();
  const { data } = useSearchQuery({
    variables: {
      solrHandler: appName === 'DVIEW' ? '/dview-search-select' : '/search-select',
      query: DEFAULT_SEARCH_QUERY,
      filters,
      start: 0,
      page: 0,
      count: 10,
      includeDocs: false,
      noShortContent: false,
    },
    skip: !attachmentId || !docSolrAsset?.dataAsset?.has_attachment,
    fetchPolicy: 'cache-first',
  });

  const container = document.getElementsByTagName('body')[0];

  const actionableAttributes: IAssetAttribute[] = [
    'short_content',
    'attachment_content',
    'attendees',
    'has_attendee',
    'has_attachment',
    'filename',
    'attachments',
    'question',
    'survey_id_account_id',
    'linktoemailresponse',
    'inqlink',
    'linktopostalresponse',
  ];

  const isInternalSource =
    docSolrAsset.dataAsset?.content_source && internalSources?.includes(docSolrAsset.dataAsset?.content_source);

  const isActionAvailable = actionableAttributes.some(attr => {
    const value = docSolrAsset.dataAsset?.[attr];
    if (!value) return false;
    if ((data?.search?.resultCount ?? 0) < 0 && attr === 'has_attachment') return false;
    if (isInternalSource && attr === 'filename') return false;
    if (!isInternalSource && !docSolrAsset.dataAsset?.filename) return false;
    if (Array.isArray(value)) return value.length !== 0 && value.some((v: any) => !!v);
    return true;
  });

  return (
    <Dropdown className="asset-actions-menu ml-2 mr-3" alignRight>
      <Dropdown.Toggle id={`asset-actions-menu-${docSolrAsset.dataAsset?.id}`} variant="link" disabled={false}>
        <FontAwesomeIcon icon={faEllipsisV} className="menu-icon" />
      </Dropdown.Toggle>

      {ReactDOM.createPortal(<Dropdown.Menu className="search-card-menu">{children}</Dropdown.Menu>, container)}
    </Dropdown>
  );
};

AssetActionsMenu.Item = Item;

export default AssetActionsMenu;
