import { useCallback, useState } from 'react';

const textDelay = 4;

export const useTypeWriter = () => {
  const [handle, setHandle] = useState<NodeJS.Timer>();
  const [typeWriter, setTypeWriter] = useState<NodeJS.Timeout>();

  const startTypewriter = useCallback((callback: (pl: string) => void, suggestingPlaceholders: string[]) => {
    const text = suggestingPlaceholders.map(t => t.padEnd(t.length + textDelay, ' '));

    setHandle(
      setTimeout(() => {
        text.reduce(
          (p, t, i) => p.then(() => typeWriteAString(t, callback, i === text.length - 1, suggestingPlaceholders)),
          Promise.resolve() as Promise<unknown>
        );
      }, 0)
    );
  }, []);

  const typeWriteAString = async (
    text: string,
    callback: (pl: string) => void,
    isTheEnd: boolean,
    suggestingPlaceholders: string[]
  ) => {
    return new Promise<void>(resolve => {
      let i = 0;
      const tLength = text.length;
      const interval = setInterval(
        () => {
          const cut = i > tLength ? text.slice(0, tLength - i) : text.slice(0, i);
          callback(cut);
          if (i === 2 * tLength) {
            clearInterval(interval);
            if (isTheEnd) {
              startTypewriter(callback, suggestingPlaceholders);
            }
            resolve();
          }
          i++;
        },
        i > tLength ? 20 : 100
      );
      setTypeWriter(interval);
    });
  };

  const pauseTypewriter = () => {
    if (handle) {
      clearTimeout(handle);
    }
    if (typeWriter) {
      clearInterval(typeWriter);
    }
  };

  return { pauseTypewriter, startTypewriter };
};
