import { useDateFilterOperations } from '@amgen/feature-facets';
import { IQuickRangeOption, QuickDateRange } from '@amgen/shared';
import { add } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Button, ButtonToolbar, Modal } from 'react-bootstrap';
import { DateRangePicker } from 'react-date-range';

import './quick-range-bar.scss';

import { useDashBoard } from '../../hooks';

export interface QuickRangeBarProps {
  quickRangeOptions: IQuickRangeOption[];
  selectedLabel: string;
  onSelect: (option: IQuickRangeOption) => void;
  applyCustomDateFilter?: (start: string, end: string) => void;
}

export const QuickRangeBar = (props: QuickRangeBarProps) => {
  return (
    <ButtonToolbar className="align-items-center justify-content-center">
      {props.quickRangeOptions
        .filter(x => x.label !== QuickDateRange.CUSTOM)
        .map(option => (
          <Button
            className="m-1"
            key={option.label}
            variant="outline-primary"
            active={option.label === props.selectedLabel}
            onClick={() => props.onSelect(option)}
          >
            {option.label}
          </Button>
        ))}
      <CustomButton selectedLabel={props.selectedLabel} applyCustomDateFilter={props.applyCustomDateFilter} />
    </ButtonToolbar>
  );
};

export default QuickRangeBar;

export const CustomButton = (props: Pick<QuickRangeBarProps, 'selectedLabel' | 'applyCustomDateFilter'>) => {
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <Button
        className="m-1"
        key="custom"
        variant="outline-primary"
        active={QuickDateRange.CUSTOM === props.selectedLabel}
        onClick={() => setModalShow(true)}
      >
        Custom
      </Button>
      <CustomRangeModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        applyCustomDateFilter={props.applyCustomDateFilter}
      />
    </>
  );
};

interface CustomRangeModalProps {
  show: boolean;
  onHide: () => void;
  applyCustomDateFilter?: (start: string, end: string) => void;
}

const CustomRangeModal = (props: CustomRangeModalProps) => {
  const { toSolrStartDate, toSolrEndDate } = useDateFilterOperations();
  const { selectedQuickRange } = useDashBoard();

  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  useEffect(() => {
    const startDate = add(new Date(), {
      years: -selectedQuickRange.yearAgo,
      months: -selectedQuickRange.monthAgo,
      days: -selectedQuickRange.daysAgo,
    });
    if (startDate && QuickDateRange.CUSTOM !== selectedQuickRange.label) {
      setRange([
        {
          startDate: startDate,
          endDate: new Date(),
          key: 'selection',
        },
      ]);
    }
  }, [selectedQuickRange]);

  const applyFilters = () => {
    props.applyCustomDateFilter &&
      props.applyCustomDateFilter(toSolrStartDate(range[0].startDate), toSolrEndDate(range[0].endDate));
    props.onHide();
  };

  return (
    <Modal show={props.show} onHide={props.onHide} size="lg" aria-labelledby="contained-modal-title-center" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-center">Custom Date</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DateRangePicker
          className="custom-dashboard-date-range"
          onChange={(item: any) => setRange([item.selection])}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          maxDate={new Date()}
          inputRanges={[]} // to remove from today section and kept only till today
          months={1}
          direction="vertical"
          scroll={{ enabled: true }}
          ranges={range}
        />
        ;
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" className="px-4" onClick={props.onHide}>
          Close
        </Button>
        <Button className="px-4" onClick={() => applyFilters()}>
          Apply
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
