import React, { useState } from 'react';

import './show-consent-button.module.scss';

import ConsentAcceptor from '../consent-acceptor/consent-acceptor';

/* eslint-disable-next-line */
export interface ShowConsentButtonProps {}

export function ShowConsentButton(props: ShowConsentButtonProps) {
  const [show, setShow] = useState(false);

  return (
    <>
      <span
        onClick={() => {
          setShow(true);
        }}
      >
        Terms & Conditions
      </span>
      <ConsentAcceptor show={show} setShow={setShow} isClosable />
    </>
  );
}

export default ShowConsentButton;
