import { DataSource, Direction, IAssetAttribute, ISavedSearch, SortField } from '@amgen/core';
import { createContext } from 'react';

import { IAttributeComponentConfig, ResultViewType } from '../../models';

export interface IAppState {
  resultCount: number;
  setResultCount: (count: number) => void;
  savedSearch: ISavedSearch | null; // used to store savedSearch data when navigating from SavedSearchesPanel.
  setSavedSearch: (savedSearch: ISavedSearch | null) => void;
  searchFacetCategories: IAssetAttribute[];
  setSearchFacetCategories: (facetCategories: IAssetAttribute[]) => void;
  attributeDisplayNames: NonNullable<{ [key in IAssetAttribute]: string }>;
  displayAttributes: IAssetAttribute[];
  pageNo: number;
  setPageNo: (page: number) => void;
  dataSources: DataSource[] | undefined; // remove undefined here when added to CI and KMI applications
  setDataSources: (dataSources: DataSource[]) => void;
  internalSources?: DataSource[];
  attributeConfig: IAttributeComponentConfig;
  excludeColumnsInTable?: IAssetAttribute[];
  suggestingPlaceholders?: string[];
  sortOrder: Direction;
  setSortOrder: (sortOrder: Direction) => void;
  sortOption: SortField;
  setSortOption: (option: SortField) => void;
  perPageOptions: number[];
  itemsPerPage: number;
  setItemsPerPage: (n: number) => void;
  viewType: ResultViewType;
  setViewType: (viewType: ResultViewType) => void;
  suggestionDoc: boolean;
  setSuggestionDoc: (suggestionDoc: boolean) => void;
}

export const AppContext = createContext<IAppState>(null!);
