import * as Types from '@amgen/core';

import { IGenericResponseFragment } from './generic-response';
import { gql } from '@apollo/client';
import { GenericResponseFragmentDoc } from './generic-response';
import * as Apollo from '@apollo/client';
export type IRoadmapFeatureLikeFragment = Pick<Types.IRoadmapFeatureLike, 'id' | 'timestamp'> & {
  user: Pick<Types.IUser, 'username' | 'firstName' | 'lastName' | 'costCenter'>;
};

export type IGetRoadmapLikesQueryVariables = Types.Exact<{
  start?: Types.Maybe<Types.Scalars['Int']>;
  parentId: Types.Scalars['String'];
  page?: Types.Maybe<Types.Scalars['Int']>;
  count?: Types.Maybe<Types.Scalars['Int']>;
}>;

export type IGetRoadmapLikesQuery = {
  getRoadmapLikes?: Types.Maybe<
    Pick<Types.IRoadmapLikeResponse, 'resultCount'> & {
      likes?: Types.Maybe<Array<Types.Maybe<IRoadmapFeatureLikeFragment>>>;
    }
  >;
};

export type ILikeRoadmapFeatureMutationVariables = Types.Exact<{
  id?: Types.Maybe<Types.Scalars['String']>;
  parentId: Types.Scalars['String'];
}>;

export type ILikeRoadmapFeatureMutation = { likeRoadmapFeature?: Types.Maybe<IGenericResponseFragment> };

export const RoadmapFeatureLikeFragmentDoc = gql`
  fragment RoadmapFeatureLike on RoadmapFeatureLike {
    id
    timestamp
    user {
      username
      firstName
      lastName
      costCenter
    }
  }
`;
export const GetRoadmapLikesDocument = gql`
  query getRoadmapLikes($start: Int = 0, $parentId: String!, $page: Int = 0, $count: Int = 10) {
    getRoadmapLikes(parentId: $parentId, page: $page, start: $start, count: $count) {
      resultCount
      likes {
        ...RoadmapFeatureLike
      }
    }
  }
  ${RoadmapFeatureLikeFragmentDoc}
`;
export function useGetRoadmapLikesQuery(
  baseOptions: Apollo.QueryHookOptions<IGetRoadmapLikesQuery, IGetRoadmapLikesQueryVariables>
) {
  return Apollo.useQuery<IGetRoadmapLikesQuery, IGetRoadmapLikesQueryVariables>(GetRoadmapLikesDocument, baseOptions);
}
export function useGetRoadmapLikesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetRoadmapLikesQuery, IGetRoadmapLikesQueryVariables>
) {
  return Apollo.useLazyQuery<IGetRoadmapLikesQuery, IGetRoadmapLikesQueryVariables>(
    GetRoadmapLikesDocument,
    baseOptions
  );
}
export type GetRoadmapLikesQueryHookResult = ReturnType<typeof useGetRoadmapLikesQuery>;
export type GetRoadmapLikesLazyQueryHookResult = ReturnType<typeof useGetRoadmapLikesLazyQuery>;
export type GetRoadmapLikesQueryResult = Apollo.QueryResult<IGetRoadmapLikesQuery, IGetRoadmapLikesQueryVariables>;
export const LikeRoadmapFeatureDocument = gql`
  mutation likeRoadmapFeature($id: String, $parentId: String!) {
    likeRoadmapFeature(id: $id, parentId: $parentId) {
      ...GenericResponse
    }
  }
  ${GenericResponseFragmentDoc}
`;
export type ILikeRoadmapFeatureMutationFn = Apollo.MutationFunction<
  ILikeRoadmapFeatureMutation,
  ILikeRoadmapFeatureMutationVariables
>;
export function useLikeRoadmapFeatureMutation(
  baseOptions?: Apollo.MutationHookOptions<ILikeRoadmapFeatureMutation, ILikeRoadmapFeatureMutationVariables>
) {
  return Apollo.useMutation<ILikeRoadmapFeatureMutation, ILikeRoadmapFeatureMutationVariables>(
    LikeRoadmapFeatureDocument,
    baseOptions
  );
}
export type LikeRoadmapFeatureMutationHookResult = ReturnType<typeof useLikeRoadmapFeatureMutation>;
export type LikeRoadmapFeatureMutationResult = Apollo.MutationResult<ILikeRoadmapFeatureMutation>;
export type LikeRoadmapFeatureMutationOptions = Apollo.BaseMutationOptions<
  ILikeRoadmapFeatureMutation,
  ILikeRoadmapFeatureMutationVariables
>;
