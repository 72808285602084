import React from 'react';
import { Spinner } from 'react-bootstrap';

import './authorizer.scss';

import { useAuth } from '../hooks';
import UnauthorizedError from '../unauthorized-error/unauthorized-error';

/* eslint-disable-next-line */
export interface AuthorizerProps {}

export const Authorizer: React.FC<AuthorizerProps> = ({ children }) => {
  const {
    getAccessDetailsLoader,
    isAuthenticated,
    isAuthorized,
    isPending,
    error,
    tabs: authTabs,
    user,
    appDetails,
    appName,
  } = useAuth(true);

  if (isAuthenticated && isAuthorized) {
    sessionStorage.setItem('isAuthenticated', JSON.stringify(isAuthenticated));
    sessionStorage.setItem('isAuthorized', JSON.stringify(isAuthorized));
    sessionStorage.setItem('loading', JSON.stringify(getAccessDetailsLoader));
    sessionStorage.setItem('appDetails', JSON.stringify(appDetails));
    sessionStorage.setItem('isPending', JSON.stringify(isPending));
  }

  if (appName) {
    sessionStorage.setItem('externalAppName', appName);
  }

  if (authTabs) {
    sessionStorage.setItem('authTabs', JSON.stringify(authTabs));
  }

  if (user) {
    sessionStorage.setItem('user', JSON.stringify(user));
  }

  if (getAccessDetailsLoader || isPending) {
    return (
      <div className="d-flex justify-content-center align-items-center row position-absolute loader-position">
        <div className="col-12 text-center">
          <Spinner animation="border" className="p-4 text-primary" /> <br></br>
        </div>
        <div className="col-10 text-center text-primary mt-1">Fetching user access...</div>
      </div>
    );
  }

  if ((!isPending && !isAuthenticated) || error) {
    return null;
  }

  if (!isAuthorized) {
    return <UnauthorizedError />;
  }

  return <>{children}</>;
};

export default Authorizer;
