import { useAddApplicationFeedbackMutation } from '@amgen/api';
import { useEnvironment } from '@amgen/core';
import { APPLICATION_FEEDBACK_ACTIONS, MatomoContext, SEARCH_APP_FEATURE_LIST, SearchContext } from '@amgen/shared';
import { faFrown, faMeh, faSmile } from '@fortawesome/free-regular-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Alert, Button, Form } from 'react-bootstrap';

import './feedback-modal.scss';

export interface FeedbackAppQueryProps {
  showFeedbackBtn?: boolean;
  setShowFeedbackBtn: (showFeedbackBtn: boolean) => void;
  setIsFeedbackSent: (isFeedbackSent: boolean) => void;
}

const FeedbackAppQuery: React.FC<FeedbackAppQueryProps> = ({
  showFeedbackBtn,
  setShowFeedbackBtn,
  setIsFeedbackSent,
}) => {
  // const [starRating, setStarRating] = useState(0);
  const [title, setTitle] = useState('');
  const [comment, setComment] = useState('');
  const { selectAppMood, selectedAppMood, typeOfFeedback } = useContext(SearchContext);
  const [validated, setValidated] = useState(false);
  const { appName } = useEnvironment();
  const user = JSON.parse(sessionStorage.user);

  const { trackApplicationFeature } = useContext(MatomoContext);

  const [addFeedback, { error }] = useAddApplicationFeedbackMutation({
    onCompleted: () => {
      setIsFeedbackSent(true);
    },
  });

  const submitFeedback = (event: React.FormEvent<HTMLFormElement>) => {
    if (event.currentTarget.checkValidity()) {
      if (selectedAppMood) {
        trackApplicationFeature(SEARCH_APP_FEATURE_LIST.APPLICATION_FEEDBACK, selectedAppMood);
      }

      if (comment) {
        trackApplicationFeature(
          SEARCH_APP_FEATURE_LIST.APPLICATION_FEEDBACK,
          APPLICATION_FEEDBACK_ACTIONS.APPLICATION_FEEDBACK_COMMENT
        );
      }

      addFeedback({
        variables: {
          feedback: {
            feedback: selectedAppMood!,
            title: title,
            comment: comment,
            applicationName: appName,
            userId: user.username,
            userFirstName: user.firstName,
            userLastName: user.fullName.split(',')[0].toString(),
            costCenter: user.subFunctionalArea,
          },
        },
      });
    }
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
  };

  return (
    <div className="scroll-app-feedback thin-scrollbar-gray">
      {/* <h2 className="text-center pt-2">How would you rate your experience?</h2>

      <Rating
        className="py-2"
        initialRating={starRating}
        fullSymbol={<FontAwesomeIcon icon={faStarSolid} className="text-gold mx-2" size="2x" />}
        emptySymbol={<FontAwesomeIcon icon={faStar} className="text-gold mx-2" size="2x" />}
        onClick={(value: number) => setStarRating(value)}
      /> */}

      <div className="mb-3">
        {selectedAppMood === 'Satisfied' ? (
          <FontAwesomeIcon
            title="Satisifed"
            icon={faSmile}
            className="fa-5x px-3 cursor-pointer border-circle-smileys-satisfied Satisfied"
            onClick={() => selectAppMood('Satisfied')}
          />
        ) : (
          <FontAwesomeIcon
            title="Satisifed"
            icon={faSmile}
            className="fa-5x px-3 cursor-pointer border-circle-smileys-outlined-satisfied Satisfied"
            onClick={() => selectAppMood('Satisfied')}
          />
        )}
        {selectedAppMood === 'Neutral' ? (
          <FontAwesomeIcon
            title="Neutral"
            icon={faMeh}
            className="fa-5x px-3 cursor-pointer border-circle-smileys-neutral Neutral"
            onClick={() => selectAppMood('Neutral')}
          />
        ) : (
          <FontAwesomeIcon
            title="Neutral"
            icon={faMeh}
            className="fa-5x px-3 cursor-pointer border-circle-smileys-outlined-neutral Neutral"
            onClick={() => selectAppMood('Neutral')}
          />
        )}
        {selectedAppMood === 'Disappointed' ? (
          <FontAwesomeIcon
            title="Disappointed"
            icon={faFrown}
            className="fa-5x px-3 cursor-pointer border-circle-smileys-disappointed Disappointed"
            onClick={() => selectAppMood('Disappointed')}
          />
        ) : (
          <FontAwesomeIcon
            title="Disappointed"
            icon={faFrown}
            className="fa-5x px-3 cursor-pointer  border-circle-smileys-outlined-disappointed Disappointed"
            onClick={() => selectAppMood('Disappointed')}
          />
        )}
      </div>

      <Form className="pt-2 px-4 pb-3" noValidate validated={validated} onSubmit={submitFeedback}>
        <Form.Group controlId="ratingForm.feedbackTitle">
          <Form.Label htmlFor="feedbacksubject" className="d-flex form-label fs-14 justify-content-between m-0">
            <div>Feedback Subject</div>
            <div className="count-textarea">{`${title.length} of 50 characters`}</div>
          </Form.Label>
          <Form.Control
            as="textarea"
            className="modal-textarea-content thin-scrollbar font-italic"
            placeholder="Add a title to your feedback"
            value={title}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)}
            required
            maxLength={50}
            isValid={title.length > 0 && title.length <= 50 && title.trim() !== ''}
          />
          {/* <Form.Control.Feedback type="invalid" className="text-left">
            Title is required
          </Form.Control.Feedback> */}
          {/* <Form.Control.Feedback type="valid" className="text-left text-black-50">
                    {`Characters left: ${50 - title.length}`}
                  </Form.Control.Feedback> */}
        </Form.Group>

        <Form.Group controlId="ratingForm.feedbackComment">
          <Form.Label htmlFor="feedbackdescription" className="d-flex form-label fs-14 justify-content-between m-0">
            <div>Feedback Description</div>
            <div className="count-textarea">{`${comment.length} of 500 characters`}</div>
          </Form.Label>
          <Form.Control
            as="textarea"
            className="modal-textarea-content thin-scrollbar font-italic"
            rows={3}
            placeholder="Add your description here (optional)"
            value={comment}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setComment(e.target.value)}
            maxLength={500}
            isValid={comment.length > 0 && comment.length <= 500 && comment.trim() !== ''}
          />
          {/* <Form.Control.Feedback type="valid" className="text-left text-black-50">{`Characters left: ${
                    500 - comment.length
                  }`}</Form.Control.Feedback> */}
        </Form.Group>
        {error ? (
          <Alert variant="danger">
            <span>Something went wrong. Please try again later.</span>
          </Alert>
        ) : null}
        <div className="feedback-modal-footer pt-2 d-flex justify-content-end">
          <Button
            variant="primary"
            type="submit"
            className={classNames('px-2 py-0', {
              'cursor-not-allowed': title.length <= 0 || selectedAppMood === 'none',
            })}
            disabled={title.length <= 0 || selectedAppMood === 'none'}
            title={
              title.length <= 0
                ? 'Feedback title or description is missing'
                : selectedAppMood === 'none'
                ? 'Smiley is not selected'
                : ''
            }
          >
            Send Feedback <FontAwesomeIcon icon={faChevronRight} className="text-white ml-1" />
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default FeedbackAppQuery;
