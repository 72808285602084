export const CHART_COLORS = {
  product: [
    'rgb(65,187,197)',
    'rgb(7,92,98)',
    'rgb(254,162,122)',
    'rgb(125,39,72)',
    'rgb(111,239,112)',
    'rgb(44,69,125)',
    'rgb(113,161,220)',
    'rgb(116,22,142)',
    'rgb(223,122,203)',
    'rgb(67,40,231)',
    'rgb(120,157,35)',
    'rgb(9,96,19)',
    'rgb(254,201,175)',
    'rgb(94,64,40)',
    'rgb(182,134,110)',
    'rgb(169,55,19)',
    'rgb(252,209,7)',
    'rgb(250,33,127)',
    'rgb(253,143,47)',
    'rgb(237,15,38)',
    'rgb(33,167,8)',
    'rgb(105,117,254)',
    'rgb(254,41,226)',
    'rgb(255,136,137)',
  ],
  therapeutic_area: [
    'rgb(94,144,233)',
    'rgb(62,60,141)',
    'rgb(52,241,153)',
    'rgb(29,109,31)',
    'rgb(185,224,155)',
    'rgb(163,62,18)',
    'rgb(250,141,128)',
    'rgb(112,70,83)',
    'rgb(253,44,59)',
    'rgb(221,192,189)',
    'rgb(142,0,73)',
    'rgb(242,51,135)',
    'rgb(173,213,31)',
    'rgb(87,43,158)',
    'rgb(101,139,251)',
    'rgb(30,67,141)',
    'rgb(200,126,248)',
    'rgb(208,3,214)',
    'rgb(63,22,249)',
    'rgb(250,206,117)',
    'rgb(71,74,9)',
    'rgb(81,243,16)',
    'rgb(178,139,40)',
    'rgb(254,143,6)',
  ],
};

export const DASHBOARD_FILTER_COUNT = 15;
