import { useEnvironment } from '@amgen/core';
import { HELP_CENTER_ACTIONS, MatomoContext, SEARCH_APP_FEATURE_LIST, ShowConsentButton } from '@amgen/shared';
import { faCommentDots } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

import './profile-menu.scss';

import { ReactComponent as HelpCenterSVG } from '../../../../../../apps/eea-search/src/assets/images/help-center.svg';
import { ReactComponent as LogoutSVG } from '../../../../../../apps/eea-search/src/assets/images/logout.svg';
import { ReactComponent as SettingsSvg } from '../../../../../../apps/eea-search/src/assets/images/settings.svg';
import { ReactComponent as TermsConditionsSVG } from '../../../../../../apps/eea-search/src/assets/images/terms-conditions.svg';
import { ReactComponent as UserGuideSVG } from '../../../../../../apps/eea-search/src/assets/images/user-guide.svg';
import { ReactComponent as UserProfileSVG } from '../../../../../../apps/eea-search/src/assets/images/user-profile.svg';

export interface ProfileMenuProps {
  user: string;
  onLogOut: () => Promise<any>;
  className?: string;
  onlanding?: boolean;
}

export const ProfileMenu: React.FC<ProfileMenuProps> = props => {
  const { trackApplicationFeature } = useContext(MatomoContext);
  let authTabs = [''];
  if (sessionStorage.authTabs) {
    authTabs = JSON.parse(sessionStorage.authTabs);
  }
  const authorizedTabs = authTabs?.includes('IGD Admin');
  const user = JSON.parse(sessionStorage?.user);
  const { support } = useEnvironment();
  const history = useHistory();
  const [IsdropDownOpen, toggleDropdown] = useState(false);
  const CheckOutsideClick = ref => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          toggleDropdown(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  CheckOutsideClick(wrapperRef);
  const CurrentLocation = window.location.href;
  const onSettingClick = () => {
    localStorage.setItem('current_new_url', CurrentLocation);
    history.push('/settings');
  };

  const onUserGuideClick = () => {
    window.open(support.guideUrl);
  };

  return (
    <DropdownButton
      id="profile"
      variant={'null'}
      className={classnames('profile-item w-100 ', props.className)}
      size={props.onlanding ? undefined : 'lg'}
      alignRight
      ref={wrapperRef}
      show={IsdropDownOpen}
      onClick={() => toggleDropdown(!IsdropDownOpen)}
      title={<img src="assets/images/profile.svg" />}
    >
      <Dropdown.Item as="li" className="profile-dropdown-item pl-3">
        <>
          <UserProfileSVG className="svg-icon" />
          <span>{user?.fullName}</span>
        </>
      </Dropdown.Item>

      {/* <ContentOnboardingSVG /> */}
      {props.children}

      <Dropdown.Item
        as="li"
        className="cursor-pointer profile-dropdown-item pl-3"
        onClick={() => {
          window.open('https://amgen.sharepoint.com/sites/IntelligentSearchCompanionPortal');
          trackApplicationFeature(SEARCH_APP_FEATURE_LIST.HELP_CENTER, HELP_CENTER_ACTIONS.HELP_CENTER_CLICK);
        }}
      >
        <div>
          <HelpCenterSVG className="svg-icon" />
          <span>Help Center</span>
        </div>
        {/* <FontAwesomeIcon
            icon={faQuestion}
            className="animating-icon "
            style={{ marginTop: '2px' }}
            onClick={() => {
              window.open('https://amgen.sharepoint.com/sites/IntelligentSearchCompanionPortal');
              trackApplicationFeature(SEARCH_APP_FEATURE_LIST.HELP_CENTER, HELP_CENTER_ACTIONS.HELP_CENTER_CLICK);
            }}
          /> */}
      </Dropdown.Item>
      <Dropdown.Item as="li" className="profile-dropdown-item cursor-pointer pl-3" onClick={onUserGuideClick}>
        <div>
          <UserGuideSVG className="svg-icon" />
          <span>User Guide</span>
        </div>
      </Dropdown.Item>

      {authorizedTabs ? (
        <Dropdown.Item className="profile-dropdown-item cursor-pointer pl-3" as={Link} to="/feedbacks">
          <div>
            <FontAwesomeIcon color="#225BAA" icon={faCommentDots} className="svg-icon" />
            <span>Feedback</span>
          </div>
        </Dropdown.Item>
      ) : null}

      <Dropdown.Item as="li" className="profile-dropdown-item cursor-pointer pl-3">
        <div>
          <TermsConditionsSVG className="svg-icon" />
          <ShowConsentButton />
        </div>
      </Dropdown.Item>

      <Dropdown.Item as="li" className="profile-dropdown-item cursor-pointer pl-3" onClick={onSettingClick}>
        <div>
          <SettingsSvg className="svg-icon" />
          <span>Settings</span>
        </div>
      </Dropdown.Item>

      <Dropdown.Item as="li" className="profile-dropdown-item cursor-pointer pl-3" onClick={props.onLogOut}>
        <div>
          <LogoutSVG className="svg-icon" />
          <span>Logout</span>
        </div>
      </Dropdown.Item>
    </DropdownButton>
  );
};

export default ProfileMenu;
