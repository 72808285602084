import { useContext } from 'react';

import { FacetContext } from '../contexts/facet-context-provider/facet-context';
import { FiltersObject } from '../models';

export function useAppliedFilters(filtersObj?: FiltersObject): string[] {
  const { appliedFilters } = useContext(FacetContext);

  return (filtersObj ?? appliedFilters).toQueryParam();
}
