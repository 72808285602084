import { AppContext } from '@amgen/shared';
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';

import './save-search-button-group.scss';

interface SaveSearchBtnGroupProps {
  updateLoading: boolean;
  saveLoading: boolean;
  isSubscribeActive?: boolean;
  handleCancel: () => void;
  handleSubmit: () => void;
  handleUpdate: () => void;
  hideUpdateBtn?: boolean;
}
export const SaveSearchBtnGroup: React.FC<SaveSearchBtnGroupProps> = props => {
  const { savedSearch } = useContext(AppContext);

  return (
    <>
      {savedSearch && (
        <Button
          variant={props.isSubscribeActive ? 'success' : 'primary'}
          className={props.isSubscribeActive ? 'px-md-2' : 'px-md-5'}
          onClick={props.handleUpdate}
          disabled={props.saveLoading || props.updateLoading}
        >
          {props.updateLoading ? (
            <Spinner animation="border" size="sm" />
          ) : !props.isSubscribeActive ? (
            'Update'
          ) : (
            'Update & Subscribe'
          )}
          {props.isSubscribeActive && <FontAwesomeIcon icon={faCalendarCheck} className="ml-2" />}
        </Button>
      )}

      {!props.hideUpdateBtn && (
        <Button
          variant={props.isSubscribeActive ? 'success' : 'primary'}
          className={props.isSubscribeActive ? 'px-md-2' : 'px-md-5'}
          onClick={props.handleSubmit}
          disabled={props.saveLoading || props.updateLoading}
        >
          {props.saveLoading ? (
            <Spinner animation="border" size="sm" />
          ) : !props.isSubscribeActive ? (
            'Save'
          ) : (
            'Save & Subscribe'
          )}
          {props.isSubscribeActive && <FontAwesomeIcon icon={faCalendarCheck} className="ml-2" />}
        </Button>
      )}

      <Button
        variant="outline-primary"
        className="px-md-5"
        onClick={props.handleCancel}
        disabled={props.saveLoading || props.updateLoading}
      >
        Cancel
      </Button>
    </>
  );
};

export default SaveSearchBtnGroup;
