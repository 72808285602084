import { ApplicationFeedbackList, BaseFeedbackPage } from '@amgen/feature-feedbacks';
import { PAGES, usePageTrackingWithTitle } from '@amgen/shared';
import React from 'react';

import './application-feedback-page.scss';

/* eslint-disable-next-line */
export interface ApplicationFeedbackPageProps {}

export const ApplicationFeedbackPage = (props: ApplicationFeedbackPageProps) => {
  usePageTrackingWithTitle(PAGES.FEEDBACKS);

  return (
    <BaseFeedbackPage>
      <ApplicationFeedbackList />
    </BaseFeedbackPage>
  );
};

export default ApplicationFeedbackPage;
