import { FacetsPanelProvider, FiltersObject, useWindowSizeListener } from '@amgen/shared';
import classNames from 'classnames';
import React, { useContext } from 'react';

import './search-bar.scss';

import SearchBox from '../search-box/search-box';

export interface SearchBarProps {
  isSearchExpanded?: boolean;
  setIsSearchExpanded?: (value: boolean) => void;
  className?: string;
  showAdvanceSearchBtn?: boolean;
  showQuickFilters?: boolean;
  searchSize?: 'sm' | 'lg';
  enableSuggestingPlaceholder?: boolean;
  setBlockScroll?: (value: boolean) => void;
  onExternalSearch?: (keyword: string, filters: FiltersObject) => void;
  isMobileLandingHeader?: boolean;
  defaultVal?: string;
  isHeaderLandingPage?: boolean;
}

export const SearchBar: React.FC<SearchBarProps> = props => {
  const isMobile = useWindowSizeListener();
  return (
    <div
      className={classNames(
        isMobile ? 'mobile-search-box ' : 'search-box',
        'position-relative  searchbox-width',
        props.className
      )}
    >
      <FacetsPanelProvider>
        <SearchBox
          isSearchExpanded={props.isSearchExpanded}
          setIsSearchExpanded={props.setIsSearchExpanded}
          searchSize={props.searchSize}
          showAdvanceSearch={props.showAdvanceSearchBtn}
          showQuickFilters={props.showQuickFilters}
          enableSuggestingPlaceholder={props.enableSuggestingPlaceholder}
          setBlockScroll={props.setBlockScroll}
          onExternalSearch={props.onExternalSearch}
        >
          <SearchBox.MobileCloseButton isMobileLandingHeader={props.isMobileLandingHeader} />
          <SearchBox.InputGroup isHeaderLandingPage={props.isHeaderLandingPage}>
            <SearchBox.InputGroup.Prepend></SearchBox.InputGroup.Prepend>
            <SearchBox.QueryTerm />
            <SearchBox.FilterPill />
            <SearchBox.Typeahead />
            <SearchBox.ClearButton />
            <SearchBox.SearchButton />
            <SearchBox.InputGroup.Append>
              {/* <SearchBox.AdvanceSearchDropdown setBlockScroll={props.setBlockScroll} /> */}
            </SearchBox.InputGroup.Append>
          </SearchBox.InputGroup>
          <SearchBox.MobileSearchButton />
        </SearchBox>
      </FacetsPanelProvider>
    </div>
  );
};

export default SearchBar;
