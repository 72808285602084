import { RequestStatusType } from '@amgen/core';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import './action-cell.module.scss';

export interface ActionCellProps {
  onAction: (type: RequestStatusType) => void;
}

export function ActionCell(props: ActionCellProps) {
  return (
    <div className="d-flex justify-content-around">
      <FontAwesomeIcon
        className="text-rich-green cursor-pointer"
        icon={faCheck}
        title="Approve"
        onClick={() => props.onAction(RequestStatusType.APPROVED)}
      />
      <FontAwesomeIcon
        className="text-danger cursor-pointer"
        icon={faTimes}
        title="Decline"
        onClick={() => props.onAction(RequestStatusType.DECLINED)}
      />
    </div>
  );
}

export default ActionCell;
