import { ApplicationName, DataSource, IAssetAttribute, IDataAsset, useEnvironment } from '@amgen/core';
import { AppContext } from '@amgen/shared';
import React, { useContext, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

import './asset-attribute-row.scss';

import { AssetAttributeConfig } from '../../config';
import AssetAttribute from '../asset-attribute/asset-attribute';

export interface AssetAttributeRowProps {
  displayRatio: [number, number];
  attribute: IAssetAttribute;
  asset: IDataAsset;
  clickableAttributes: IAssetAttribute[];
  onApplyFilter: (value: string, attribute: IAssetAttribute) => void;
  isALongAttribute?: boolean;
  mobileViewCol?: number;
  isMobile?: boolean;
}

export const AssetAttributeRow: React.FC<AssetAttributeRowProps> = props => {
  const { displayNames } = useContext(AssetAttributeConfig);
  const { attributeDisplayNames } = useContext(AppContext);
  const { appName } = useEnvironment();
  const lowerCaseAttributes = ['extracted_amg_drugs', 'extracted_therapeutic_areas', 'extracted_modalities'];

  //To convert some field values to lower case due to solr issue
  const getFieldValues = (fieldValue, attribute) => {
    if (lowerCaseAttributes.includes(attribute)) {
      if (Array.isArray(fieldValue)) {
        const newField = fieldValue.map(item => {
          return item.toLowerCase();
        });
        return newField;
      } else {
        return fieldValue;
      }
    } else {
      return fieldValue;
    }
  };

  return (
    <Row className={props.isMobile ? 'mb-1 px-1' : props.isALongAttribute ? 'mb-1 px-3' : ''}>
      {/* if it a long attribute we dont show the label */}
      {!props.isALongAttribute ? (
        <Col
          xs={props.mobileViewCol ?? '6'}
          md={
            props.attribute === 'doc_nav_prefix' ||
            props.attribute === 'content_source' ||
            props.attribute === 'doc_last_modified'
              ? props.displayRatio[0]
              : 3
          }
          className="attribute-name"
        >
          {attributeDisplayNames?.[props.attribute] ?? displayNames?.[props.attribute]}
        </Col>
      ) : null}

      <Col
        xs={props.isALongAttribute ? 12 : 6}
        md={props.isALongAttribute ? 12 : props.displayRatio[1]}
        className="d-flex flex-column align-items-start"
      >
        <AssetAttribute
          assetId={props.asset.id ?? ''}
          attribute={props.attribute}
          value={getFieldValues(props.asset[props.attribute], props.attribute)}
          isFilter={props.clickableAttributes.includes(props.attribute)}
          onApplyFilter={value => {
            if (appName === ApplicationName.EEA && props.attribute === 'content_source') {
              props.onApplyFilter(`0/${value}`, 'doc_nav_prefix');
            } else {
              props.onApplyFilter(value, props.attribute);
            }
          }}
          contentSource={props.asset.content_source as DataSource}
        />
      </Col>
    </Row>
  );
};

export default AssetAttributeRow;
