import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faLightbulb } from '@fortawesome/free-regular-svg-icons';
import {
  faChartLine,
  faFolderPlus,
  faHandshake,
  faRocket,
  faSearch,
  faTools,
  faUserTie,
} from '@fortawesome/free-solid-svg-icons';

export const THEME_ICONS: Map<string, IconProp> = new Map([
  ['ROADMAP_THEME_RunOps', faSearch],

  ['ROADMAP_THEME_Innovation & Exploration', faLightbulb],

  ['ROADMAP_THEME_Adoption & Education', faRocket],

  ['ROADMAP_THEME_Feature Advancement', faChartLine],

  ['ROADMAP_THEME_Tech Debt and Lifecycle', faTools],

  ['ROADMAP_THEME_Reliability', faHandshake],

  ['ROADMAP_THEME_Client Project', faUserTie],

  ['ROADMAP_THEME_Content Management and Onboarding', faFolderPlus],
]);
