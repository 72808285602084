import React from 'react';
import { Pagination } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import './pages.scss';

import { usePages } from '../hooks';

/* eslint-disable-next-line */
export interface PagesProps {}

export const Pages = (props: PagesProps) => {
  const location = useLocation();
  const { pages, isActive, handlePage, activePageNo } = usePages();
  const abTestingGroup = JSON.parse(sessionStorage.getItem('abTestingGroup') as string);
  const currentCustomsource = sessionStorage.getItem('current-source-selected');
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {pages?.length > 0 && (!abTestingGroup || (abTestingGroup && location.pathname !== '/')) ? (
        pages.map(pageNo => (
          <Pagination.Item key={pageNo} active={isActive(pageNo)} onClick={() => handlePage(pageNo)}>
            {pageNo}
          </Pagination.Item>
        ))
      ) : (
        <Pagination.Item key={activePageNo} active={isActive(activePageNo)} onClick={() => handlePage(activePageNo)}>
          {activePageNo}
        </Pagination.Item>
      )}
    </>
  );
};

export default Pages;
