import { IAssetAttribute } from '@amgen/core';
import { AppContext, useOutsideClick } from '@amgen/shared';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useRef, useState } from 'react';
import { Dropdown, Form } from 'react-bootstrap';

import './add-column-dropdown.scss';

export interface AddColumnDropdownProps {
  selectedColumns: Set<IAssetAttribute>;
  onColumnToggle: (column: IAssetAttribute) => void;
}

export const AddColumnDropdown: React.FC<AddColumnDropdownProps> = props => {
  const { attributeDisplayNames, displayAttributes, excludeColumnsInTable } = useContext(AppContext);
  const menuRef = useRef<any>(null);
  const [show, setShow] = useState(false);

  useOutsideClick(menuRef, () => setShow(false));

  return (
    <Dropdown
      className="add-column-dropdown d-flex justify-content-end mb-2"
      alignRight
      show={show}
      onSelect={(attribute, _) => props.onColumnToggle(attribute as IAssetAttribute)}
    >
      <Dropdown.Toggle
        id="add-column"
        variant="outline-primary"
        className="border-0 btn-sm"
        onClick={() => setShow(!show)}
      >
        <FontAwesomeIcon icon={faPlusCircle} />
        <span className="ml-2">Add Column</span>
      </Dropdown.Toggle>

      <Dropdown.Menu ref={menuRef} className="thin-scrollbar">
        {displayAttributes
          .filter(attribute => !excludeColumnsInTable?.includes(attribute))
          .sort((a, b) => attributeDisplayNames[a].localeCompare(attributeDisplayNames[b]))
          .map(attribute => (
            <Dropdown.Item eventKey={attribute} key={attribute}>
              <Form.Check
                type="checkbox"
                custom
                label={attributeDisplayNames[attribute]}
                checked={props.selectedColumns.has(attribute)}
                onChange={() => {}}
              />
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default AddColumnDropdown;
