import { IDataAsset } from '@amgen/core';
import { AttachmentDownloadButton } from '@amgen/feature-asset-actions';
import { faArrowCircleLeft, faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import LazyLoad from 'react-lazyload';

import './preview-image-detail.scss';

export interface PreviewImageDetailProps {
  previewImages: string[];
  asset: IDataAsset;
  loading: boolean;
  hasPreviewImages: boolean;
  assetTitle?: string;
}
export const PreviewImageDetail: React.FC<PreviewImageDetailProps> = props => {
  const [previewPageNumber, setPreviewPageNumber] = useState(1);

  const handleNextClicked = () => {
    setPreviewPageNumber(
      previewPageNumber + 1 > (props.previewImages.length ?? 1) ? previewPageNumber : previewPageNumber + 1
    );
  };

  const handlePrevClicked = () => {
    setPreviewPageNumber(previewPageNumber - 1 < 1 ? 1 : previewPageNumber - 1);
  };

  return (
    <Row>
      <Col xs="12">
        {/* <PreviewImageHeader asset={props.asset} /> */}
        {props.previewImages.length > 0 && props.previewImages[0] ? (
          <Card className=" border-0 py-5">
            {/*<Col className="d-flex">
              <Card.Title className=" justify-content-center d-flex  mr-n5 col-12 text-center" as="h1">
                <div className="mt-2 ml-5 image-heading">
                  {`Preview (${previewPageNumber} of ${props.previewImages.length})`}
                  {props.loading && <Spinner animation="border" variant="primary" />}
                  <AttachmentDownloadButton
                    className="btn-lg"
                    url={props.asset.filename!}
                    fileName={props.asset.title}
                    isHideFileName={true}
                    isOnImageView={true}
                  />
                </div>
              </Card.Title>
        </Col>*/}
            <Card.Body as={Row}>
              <Col xs="1" md="1" className="left-arrow-button">
                <span
                  className={`preview-page-button ${previewPageNumber === 1 ? 'grey-out' : 'cursor-pointer'}`}
                  onClick={() => handlePrevClicked()}
                >
                  <FontAwesomeIcon icon={faArrowCircleLeft} />
                </span>
              </Col>

              <Col xs="10" md="10" className="shadow-lg">
                <LazyLoad>
                  <img
                    className="img-fluid d-block preview-image"
                    src={props.previewImages[previewPageNumber - 1]}
                    alt=""
                  />
                </LazyLoad>
              </Col>

              <Col xs="1" md="1" className="right-arrow-button">
                <span
                  className={`preview-page-button ${
                    props.previewImages.length === previewPageNumber ? 'grey-out' : 'cursor-pointer'
                  }`}
                  onClick={() => handleNextClicked()}
                >
                  <FontAwesomeIcon icon={faArrowCircleRight} />
                </span>
              </Col>
            </Card.Body>
            <AttachmentDownloadButton
              className="btn-lg image-download"
              url={props.asset.filename!}
              fileName={props.asset.title}
              isHideFileName={false}
              isOnImageView={true}
            />
            {/*props.asset?.short_content && <SummaryDetail asset={props.asset} />*/}
          </Card>
        ) : (
          !props.loading && (
            <>
              <div className="d-flex justify-content-center pt-4">
                <img className="server-error ml-5" src="assets/images/no-image-available.png" alt="not found" />
              </div>

              <div className="d-flex justify-content-center pt-2">
                <p className="text-danger">No images to preview</p>
              </div>
            </>
          )
        )}
      </Col>
    </Row>
  );
};
export default PreviewImageDetail;
