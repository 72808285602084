import React, { useContext } from 'react';
import { Breadcrumb } from 'react-bootstrap';

import './SearchResultRedirectUrl.scss';

export interface SearchResultRedirectUrlProps {
  url?: string;
  showDocumentIcon?: any;
  pageNo?: string;
}
export const SearchResultRedirectUrl: React.FC<SearchResultRedirectUrlProps> = ({ url, showDocumentIcon, pageNo }) => {
  const limitUrllength = url || '';
  const abTestingGroup = JSON.parse(sessionStorage.getItem('abTestingGroup') as string);
  const toggleUrlLength = limitUrllength?.length > 51 ? url?.slice(0, 51) + '...' : url;
  const currentCustomsource = sessionStorage.getItem('current-source-selected');

  return (
    // <Row>
    //   <Col className="search-redirect-urls">
    //     <p title={url}>{toggleUrlLength}</p>
    //   </Col>
    // </Row>

    <div className="link-doc-icon">
      {showDocumentIcon ? <span className="doc-tag-url">Document</span> : <span className="link-tag-url">Link</span>}

      <div className="search-redirect-urls">
        <p title={url}>{toggleUrlLength}</p>
        {pageNo !== '' && pageNo !== null && abTestingGroup && currentCustomsource !== 'Contract Search' ? (
          <Breadcrumb.Item href="#">{`(Page: ${pageNo})`}</Breadcrumb.Item>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
