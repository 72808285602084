import { useAddDestionationsRequestMutation } from '@amgen/api';
import { NewDestinationData } from '@amgen/core';
import { useToast } from '@amgen/shared';
import { Form, Formik } from 'formik';
import React from 'react';
import { Button, Col, Row, Spinner } from 'react-bootstrap';

import './suggested-app-form.module.scss';

import { validate } from '../models';
import SuggestedAppInput from '../suggested-app-input/suggested-app-input/suggested-app-input';

/* eslint-disable-next-line */
export interface SuggestedAppFormProps {
  onCancel?: () => void;
  onSubmit?: (values: NewDestinationData) => void;
  data?: any;
  mode?: any;
}

export function SuggestedAppForm(props: SuggestedAppFormProps) {
  const [addDestinations] = useAddDestionationsRequestMutation(props.mode);
  const toast = useToast();
  const initialValue: NewDestinationData =
    props.mode === 'edit'
      ? {
          id: props.data.id ?? '',
          content: props.data.content ?? '',
          title: props.data.title ?? '',
          url: props.data.url ?? '',
          requesterId: props.data.requesterId ?? '',
          tags: props.data?.tags ?? Array[''],
        }
      : {
          content: '',
          title: '',
          url: '',
          requesterId: '',
          tags: [],
        };
  return (
    <Formik<NewDestinationData>
      initialValues={initialValue}
      onSubmit={async values => {
        try {
          const resp = await addDestinations(values);
          const message =
            props.mode === 'edit'
              ? 'Your request for updating Suggested App/Destination has been submitted successfully.'
              : 'Your request for adding Suggested App/Destination has been submitted successfully.';
          toast.success({
            message: message,
            id: 'new-request-toast',
            timeout: 5000,
          });

          props.onSubmit?.(values);
        } catch (error: any) {
          const errorMessage =
            error.toString().split('.SearchException:')?.[1] || 'Something went wrong. Please try again later.';
          toast.danger({
            message: errorMessage,
            id: 'new-request-toast',
            timeout: 5000,
          });
        }
      }}
      validate={v => validate(v)}
    >
      {({ isSubmitting, validateForm }) => (
        <Form className="mx-auto" noValidate>
          <SuggestedAppInput image={props.data?.thumbnailLarge} mode={props.mode} />

          <Row>
            <Col xs="12" className="mt-3 d-flex justify-content-end">
              <Button type="submit" variant="primary" className="px-5 mr-3" disabled={isSubmitting}>
                {isSubmitting ? <Spinner animation="border" size="sm" className="text-white" /> : 'Submit'}
              </Button>
              <Button
                type="button"
                variant="outline-secondary"
                className="px-5"
                disabled={isSubmitting}
                onClick={() => {
                  props.onCancel?.();
                }}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}

export default SuggestedAppForm;
