import { faInfoCircle } from '@amgen/assets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormLabel, OverlayTrigger, Tooltip } from 'react-bootstrap';

import './form-label-with-tooltip.scss';

export interface FormLabelWithTooltipProps {
  label?: string;
  helpText?: string;
  placement_for_export?:boolean;
}

export const FormLabelWithTooltip = (props: FormLabelWithTooltipProps) => {

  return (
    <FormLabel className="font-weight-bold">
      <span className="pr-1">{props?.label}</span>
      {props.helpText ? (
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip id={`tooltip-${props.label}`} className="tooltip-width">
              {props.helpText}
            </Tooltip>
          }
        >
          <FontAwesomeIcon icon={faInfoCircle} />
        </OverlayTrigger>
      ) : null}
    </FormLabel>
  );
};

export default FormLabelWithTooltip;
