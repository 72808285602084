import React from 'react';
import { Button, Col } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';

import './view-result-link.scss';

import { useDashBoard, useSearchDataFromDashboardFilters } from '../../hooks';

/* eslint-disable-next-line */
export interface ViewResultLinkProps {}

export const ViewResultLink = (props: ViewResultLinkProps) => {
  const history = useHistory();
  const { search } = useLocation();
  const { setAppliedFilters, dashboardFilters } = useDashBoard();
  const searchData = useSearchDataFromDashboardFilters();

  return (
    <>
      {searchData?.search?.resultCount && searchData?.search?.resultCount > 0 ? (
        <Col xs="12" className="text-center">
          <Button
            variant="link"
            className="border-primary px-lg-5"
            onClick={() => {
              setAppliedFilters(dashboardFilters.clone());
              history.push({
                pathname: '/',
                search,
              });
            }}
          >
            {`${new Intl.NumberFormat('en-US').format(searchData?.search?.resultCount)} documents match the above
          criteria. View Now.`}
          </Button>
        </Col>
      ) : null}
    </>
  );
};

export default ViewResultLink;
