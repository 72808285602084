import { APIProvider, useDowntimeConfigQuery } from '@amgen/api';
import { Authenticator, Authorizer, AutoLogout, DownTimeErrorPage } from '@amgen/auth';
import { useEnvironment } from '@amgen/core';
import React, { useContext, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

import './search-app-container.scss';

import ConsentAcceptor from '../consent-acceptor/consent-acceptor';
import { MatomoProvider } from '../matomo';
import { ToastProvider } from '../toast/toast-context';


/* eslint-disable-next-line */
export interface SearchAppContainerProps {
  showConsent?: boolean;
}

export const SearchAppContainer: React.FC<SearchAppContainerProps> = ({ children, showConsent, ...props }) => {
  const configData = useDowntimeConfigQuery(props, false);
  const { oAuth, api, production } = useEnvironment();
  const [isActive, setIsActive] = useState(true)
  const [isLogout, setLogout] = useState(false)
  const [sessionTimeout, setSessionTimeout] = useState(false)
  const [tokenRenewed, setTokenRenewed] = useState(false)
  let ConsentWrapper: React.FC = React.Fragment; //fallback in case you dont want to wrap your components

  if (showConsent) {
    ConsentWrapper = ConsentAcceptor;
  }

  const handleSessionTimeout = () => {
    setSessionTimeout(true)
    localStorage.setItem('autoLogout', JSON.stringify(true));
  }

  const handleTokenRenewed = () => {
    setTokenRenewed(true)
  }

  return configData?.response?.show_banner === 'true' && configData?.response?.site_down === 'true' ? (
    <DownTimeErrorPage configData={configData} />
  ) : (
      <BrowserRouter basename={production ? '/search' : undefined}>
        <Authenticator {...oAuth}>
          <APIProvider {...api}>
            <Authorizer {...props}>
              <ToastProvider>
                <ConsentWrapper>
                  <AutoLogout
                    onActive={() => { setIsActive(true) }}
                    onIdle={() => { setIsActive(false) }}
                    onLogout={() => { setLogout(true) }}
                    sessionTimeout={sessionTimeout}
                    sessionTimeoutHanldler={() => handleSessionTimeout()}>
                  </AutoLogout>
                  <MatomoProvider>{children}</MatomoProvider>
                </ConsentWrapper>
              </ToastProvider>
            </Authorizer>
          </APIProvider>
        </Authenticator>
      </BrowserRouter>
  );

};

export default SearchAppContainer;
