import { ClinicalContext, KNOWLEDGE_PANEL_FEATURE_ACTIONS, MatomoContext, SEARCH_APP_FEATURE_LIST } from '@amgen/shared';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { ChangeEvent, useCallback, useContext, useMemo, useState } from 'react';
import { Badge, Col, FormControl } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { debounce } from 'ts-debounce';

import './clinical-details-table.scss';

import { objHasTextMatch } from '../../utils';

/* eslint-disable-next-line */
export interface ClinicalDetailsTableProps {}

export const ClinicalDetailsTable: React.FC<ClinicalDetailsTableProps> = () => {
  const history = useHistory();
  const { clinicalType, clinicalId, product, loading, addGraph } = useContext(ClinicalContext);
  const { trackApplicationFeature } = useContext(MatomoContext);

  const [searchText, setSearchText] = useState('');

  const filteredValues = useMemo(() => {
    return product?.studyInformation?.filter(study => !searchText || objHasTextMatch(study, searchText)) ?? [];
  }, [product, searchText]);

  return (
    <>
      {product?.studyInformation?.length && (
        <div className="my-2 ml-3 search-container">
          <FormControl
            className="bg-transparent border-0 pl-3 search-btn h-100"
            placeholder="Search"
            onChange={(e: ChangeEvent<HTMLInputElement>) => debounce(setSearchText, 300)(e.target.value.trim())}
          />
        </div>
      )}
      {clinicalType === 'study' ? (
        <Badge
          className={classNames('applied-filter d-inline-flex align-items-center border rounded-pill mx-3 mb-2 px-3 ')}
          title={`${clinicalType}: ${clinicalId}`}
        >
          <span className="text-overflow-ellipsis">{clinicalId}</span>
          <span
            className={classNames('btn-clear cursor-pointer rounded-circle ml-2 mr-n2')}
            onClick={() => history.push(`/clinical/product/${product?.product}`)}
          >
            <FontAwesomeIcon className="m-1" icon={faTimes} />
          </span>
        </Badge>
      ) : null}
      {filteredValues.length ? (
        <Col xs="12" className="scroll-bar thin-scrollbar-gray">
          <table className="table table-bordered fs-13 font-family-arial-sans-serif">
            <colgroup>
              <col style={{ width: '30%' }} />
              <col style={{ width: '70%' }} />
              {/* <col style={{ width: '33%' }} /> */}
            </colgroup>
            <thead>
              <tr>
                <th>Study Number</th>
                <th>Study Details</th>
                {/* <th scope="col">Study Phase</th>
                <th scope="col">Study Trial Link</th>
                <th scope="col">Study Conditions</th> */}
              </tr>
            </thead>
            <tbody>
              {filteredValues
                .filter(study => (clinicalType === 'study' ? clinicalId === study.studynumber : true))
                .map(study => (
                  <React.Fragment key={`${study.studynumber}`}>
                    <tr
                      key={`${study.studynumber}`}
                      className="cursor-pointer study-row"
                      onClick={() => {
                        trackApplicationFeature(
                          SEARCH_APP_FEATURE_LIST.KNOWLEDGE_PANEL,
                          KNOWLEDGE_PANEL_FEATURE_ACTIONS.SELECT_STUDY
                        );
                        // addGraph('study', study?.studynumber ?? '');
                      }}
                    >
                      <td rowSpan={4}>
                        <a
                          href={`${study.studytriallink}`}
                          target="_blank"
                          rel="noreferrer"
                          onClick={e => e.stopPropagation()}
                        >
                          {study.studynumber}
                        </a>
                      </td>
                      {/* <td>Title: {study.studydescription}</td> */}
                    </tr>
                    <tr
                      className="cursor-pointer study-row"
                      onClick={() => {
                        trackApplicationFeature(
                          SEARCH_APP_FEATURE_LIST.KNOWLEDGE_PANEL,
                          KNOWLEDGE_PANEL_FEATURE_ACTIONS.SELECT_STUDY
                        );
                        // addGraph('study', study?.studynumber ?? '');
                      }}
                    >
                      <td>
                        <span className="font-weight-bold">Title: </span>
                        {study.studydescription}
                      </td>
                    </tr>
                    <tr
                      className="cursor-pointer study-row"
                      onClick={() => {
                        trackApplicationFeature(
                          SEARCH_APP_FEATURE_LIST.KNOWLEDGE_PANEL,
                          KNOWLEDGE_PANEL_FEATURE_ACTIONS.SELECT_STUDY
                        );
                        // addGraph('study', study.studynumber ?? '');
                      }}
                    >
                      <td>
                        {' '}
                        <span className="font-weight-bold">Phase:</span> {study.studyphase}
                      </td>
                    </tr>

                    <tr
                      className="cursor-pointer study-row"
                      onClick={() => {
                        trackApplicationFeature(
                          SEARCH_APP_FEATURE_LIST.KNOWLEDGE_PANEL,
                          KNOWLEDGE_PANEL_FEATURE_ACTIONS.SELECT_STUDY
                        );
                        // addGraph('study', study.studynumber ?? '');
                      }}
                    >
                      <td>
                        {' '}
                        <span className="font-weight-bold">Condition: </span>
                        {study.studyconditions}
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
            </tbody>
          </table>
        </Col>
      ) : !loading ? (
        <Col xs="12">
          {product?.studyInformation?.length
            ? 'Sorry, there are no studies that match your search!'
            : 'Sorry, there are no available studies!'}
        </Col>
      ) : null}
    </>
  );
};

export default ClinicalDetailsTable;
