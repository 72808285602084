import { IDataCrawlRequest, SortField } from '@amgen/core';

export type IDataCrawlSortableField = keyof Pick<
  IDataCrawlRequest,
  | 'contentSource'
  | 'createdOn'
  | 'reviewedOn'
  | 'reviewerName'
  | 'requestDescription'
  | 'siteDescription'
  | 'requesterName'
  | 'reviewerRemarks'
>;

export const SORT_FIELD_MAP: Record<IDataCrawlSortableField, SortField> = {
  contentSource: SortField.DATA_SOURCE,
  createdOn: SortField.CREATEDON,
  reviewedOn: SortField.REVIEWEDON,
  reviewerName: SortField.REVIEWERNAME,
  requestDescription: SortField.REQUESTDESCRIPTION,
  siteDescription: SortField.SITEDESCRIPTION,
  requesterName: SortField.REQUESTERNAME,
  reviewerRemarks: SortField.REVIEWERREMARKS,
};
