import { useAddQueryFeedbackMutation } from '@amgen/api';
import {
  MatomoContext,
  SEARCH_APP_FEATURE_LIST,
  SearchContext,
  useSearchQueryValue,
  useWindowSizeListener,
} from '@amgen/shared';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

const SubmitFeedback = props => {
  const [addFeedback, { data }] = useAddQueryFeedbackMutation({
    onCompleted: () => {
      props.setSuccessFlag(true);
    },
  });
  const { setIsUserFeedbackCompleted, setOpenFeedbackFromSearchQuery } = useContext(SearchContext);
  const query = useSearchQueryValue();
  const isMobile = useWindowSizeListener();
  const abTestingGroup = JSON.parse(sessionStorage.getItem('abTestingGroup') as string);
  const { trackApplicationFeature } = useContext(MatomoContext);
  return (
    <div className="feedback-modal-footer pt-2 d-flex justify-content-end">
      <Button
        variant="primary"
        className={classNames('py-0 mb-2 btn-primary mt-2', {
          'cursor-not-allowed':
            props.options.length <= 0 ||
            props.options.includes('') ||
            props.comment.length <= 0 ||
            props.selectedMood === 'none',
        })}
        disabled={
          props.options.length <= 0 ||
          props.options.includes('') ||
          props.comment.length <= 0 ||
          props.comment.trim() === '' ||
          props.selectedMood === 'none'
        }
        title={
          props.options.length <= 0 ||
          props.options.includes('') ||
          props.comment.length <= 0 ||
          props.comment.trim() === ''
            ? 'Feedback options or comment is missing'
            : props.selectedMood === 'none'
            ? 'Smiley is not selected'
            : ''
        }
        onClick={() => {
          setIsUserFeedbackCompleted(true);
          sessionStorage.setItem(
            'feedbackData',
            JSON.stringify({ already_reported: true, days_from_last_feedback: 0, hideSearchQuery: true })
          );
          setOpenFeedbackFromSearchQuery(true);
          addFeedback({
            variables: {
              queryFeedback: {
                feedback: props.selectedMood,
                feedbackCategories: props.options,
                feedbackComment: props.comment,
                applicationName: 'EEA',
                searchTerm: query,
                userConsent: props.userConsent,
                userFunctionalGroup: props.functionalGroups,
                abGroup: abTestingGroup,
              },
            },
          }).catch(e => console.log(e));
          trackApplicationFeature(SEARCH_APP_FEATURE_LIST.SEARCH_RESULT_FEEDBACK_COMMENT, props.options);
          trackApplicationFeature(SEARCH_APP_FEATURE_LIST.SEARCH_RESULT_FEEDBACK_RATING, props.selectedMood);
        }}
      >
        Send Feedback <FontAwesomeIcon icon={faChevronRight} className="text-white ml-1" />
      </Button>
    </div>
  );
};

export default SubmitFeedback;
