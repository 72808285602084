import * as Types from '@amgen/core';

import { IGenericResponseFragment } from './generic-response';
import { gql } from '@apollo/client';
import { GenericResponseFragmentDoc } from './generic-response';
import * as Apollo from '@apollo/client';
export type IAddDocumentInteractionMutationVariables = Types.Exact<{
  applicationName: Types.ApplicationName;
  searchTerm: Types.Scalars['String'];
  assetId: Types.Scalars['String'];
}>;

export type IAddDocumentInteractionMutation = { addDocumentInteraction?: Types.Maybe<IGenericResponseFragment> };

export const AddDocumentInteractionDocument = gql`
  mutation AddDocumentInteraction($applicationName: ApplicationName!, $searchTerm: String!, $assetId: String!) {
    addDocumentInteraction(applicationName: $applicationName, searchTerm: $searchTerm, assetId: $assetId) {
      ...GenericResponse
    }
  }
  ${GenericResponseFragmentDoc}
`;
export type IAddDocumentInteractionMutationFn = Apollo.MutationFunction<
  IAddDocumentInteractionMutation,
  IAddDocumentInteractionMutationVariables
>;
export function useAddDocumentInteractionMutation(
  baseOptions?: Apollo.MutationHookOptions<IAddDocumentInteractionMutation, IAddDocumentInteractionMutationVariables>
) {
  return Apollo.useMutation<IAddDocumentInteractionMutation, IAddDocumentInteractionMutationVariables>(
    AddDocumentInteractionDocument,
    baseOptions
  );
}
export type AddDocumentInteractionMutationHookResult = ReturnType<typeof useAddDocumentInteractionMutation>;
export type AddDocumentInteractionMutationResult = Apollo.MutationResult<IAddDocumentInteractionMutation>;
export type AddDocumentInteractionMutationOptions = Apollo.BaseMutationOptions<
  IAddDocumentInteractionMutation,
  IAddDocumentInteractionMutationVariables
>;
