import { IDataAsset } from '@amgen/core';
import { SearchContext, useWindowSizeListener } from '@amgen/shared';
import { faFileAlt } from '@fortawesome/free-regular-svg-icons';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import DOMPurify from 'dompurify';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import './asset-title.scss';

import { useAssetTitleUrl } from '../../hooks';

export interface TableAssetTitleProps {
  asset: IDataAsset;
  className?: string;
  onTitleClick?: (assetId: string, url?: string) => void;
  customTitle?: string;
  disableRouterLink?: boolean;
  hideExternalLinkIcon?: boolean;
}

export const TableAssetTitle: React.FC<TableAssetTitleProps> = ({
  asset,
  className,
  customTitle,
  onTitleClick,
  disableRouterLink,
  hideExternalLinkIcon,
}) => {
  const { title, url, target, detailUrl, showDocumentIcon, isMsgType } = useAssetTitleUrl(asset);
  const isMobile = useWindowSizeListener();
  const { selectedViewType } = useContext(SearchContext);
  const splitUrl = url?.split('/');
  splitUrl?.pop();
  const homeUrl = splitUrl?.join('/');
  const homeTarget = homeUrl ? '_blank' : '_self';
  if (url && !isMsgType) {
    return (
      <div className={classNames(className, 'd-flex row')}>
        <div className="pl-4 pb-2">
          <a
            href={DOMPurify.sanitize(url)}
            target={target || '_blank'}
            title={title}
            onClick={() => onTitleClick && onTitleClick(asset.id!, url)}
          >
            <span id="asset-title-id" className={'asset-title-search-card-table'}>
              {title}
            </span>
          </a>
          {asset.site_name === ('LCR' || 'lcr') && (
            <span className="home-icon">
              <a
                href={DOMPurify.sanitize(homeUrl)}
                target={homeTarget || '_blank'}
                onClick={() => onTitleClick && onTitleClick(asset.id!, homeUrl)}
                title="View Contract Family"
              >
                <FontAwesomeIcon icon={faHome} className="mx-1 text-info float-right" />
              </a>
            </span>
          )}
        </div>
      </div>
    );
  } else if (isMsgType) {
    return (
      <div className={classNames(className, 'd-flex')}>
        <div id="asset-title-id" className={'asset-title-search-card'}>
          {title}
        </div>
        {asset.site_name === ('LCR' || 'lcr') && (
          <a
            href={DOMPurify.sanitize(homeUrl)}
            target={homeTarget || '_blank'}
            onClick={() => onTitleClick && onTitleClick(asset.id!, homeUrl)}
            title="View Contract Family"
          >
            <FontAwesomeIcon icon={faHome} className="mx-1 text-info float-right" />
          </a>
        )}
      </div>
    );
  }

  if (detailUrl && !disableRouterLink) {
    return (
      <Link
        to={detailUrl}
        className={className}
        target={target || '_blank'}
        title={title}
        onClick={() => onTitleClick && onTitleClick(asset.id!, detailUrl)}
      >
        <div id="asset-title-id" className="asset-title-search-card">
          {title}
        </div>
        {showDocumentIcon ? <FontAwesomeIcon icon={faFileAlt} className="mx-2 text-info float-right" /> : ''}
      </Link>
    );
  }

  return (
    <span
      className={className}
      title={title}
      style={{ wordWrap: 'break-word' }}
      onClick={() => onTitleClick && onTitleClick(asset.id!)}
    >
      {title}
    </span>
  );
};

export default TableAssetTitle;
