import { useGetSavedSearchesQuery } from '@amgen/api';
import { ApplicationName, ISavedSearch } from '@amgen/core';
import { AppContext, FacetContext, FacetsPanelContext, FiltersObject, useDelayedReset } from '@amgen/shared';
import { SOLR_REFRESH_TIME } from '@amgen/utils';
import { faFolderOpen } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState } from 'react';
import { Alert, Button, Modal, Spinner } from 'react-bootstrap';

import './saved-search-panel.scss';

import SavedSearchItem from '../saved-search-item/saved-search-item';

export interface SavedSearchPanelProps {
  isOpen: boolean;
  onClose: (isSaveModal?: boolean) => void;
}

export const SavedSearchPanel: React.FC<SavedSearchPanelProps> = props => {
  const { savedSearch: appliedSavedSearch, setSavedSearch, setCurrentSource } = useContext(AppContext);
  const { setAppliedFilters, facetCategories } = useContext(FacetContext);
  const { selectedFilterCategories, setSelectedFilterCategories } = useContext(FacetsPanelContext);
  const [savedSearches, setSavedSearches] = useState<ISavedSearch[]>([]);
  const [page, setPage] = useState(0);
  const [hasNext, setHasNextPage] = useState(true);
  const [shouldResetCurrentFilter, setShouldResetCurrentFilter] = useState(false);
  const user = JSON.parse(sessionStorage.user)
  const applicationName: ApplicationName = sessionStorage.getItem('appName')
    ? ApplicationName[sessionStorage.getItem('appName')!]
    : ApplicationName.EEA;

  const resetSavedSearches = () => {
    setPage(0);
    refetch();
    setHasNextPage(true);
  };

  const { loading, error, refetch } = useGetSavedSearchesQuery({
    onCompleted: data => {
      if (data?.getSavedSearches) {
        // on first page load should refresh the searches
        page
          ? setSavedSearches([...savedSearches, ...data?.getSavedSearches])
          : setSavedSearches(data?.getSavedSearches);
        if (data?.getSavedSearches.length === 0) {
          setHasNextPage(false);
        }

        if (shouldResetCurrentFilter) {
          setSavedSearch(data?.getSavedSearches[0]);
          setShouldResetCurrentFilter(false);
        }
      }
    },
    variables: { appName: applicationName, page, count: 10, start: 0 },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const handleScroll = ({ currentTarget }: any) => {
    if (
      currentTarget &&
      currentTarget.scrollTop + currentTarget.clientHeight >= currentTarget.scrollHeight &&
      !loading &&
      hasNext
    ) {
      setPage(page + 1);
    }
  };

  const delayedReset = useDelayedReset();

  return (
    <Modal show={props.isOpen} onHide={props.onClose} className="right ">
      <Modal.Header closeButton>
        <Modal.Title as="h2">Saved Searches</Modal.Title>
      </Modal.Header>

      <Modal.Body className="p-0 overflow-hidden">
        {savedSearches?.length ? (
          <ul className="list-unstyled my-3 thin-scrollbar overflow-auto h-100" onScroll={handleScroll}>
            {savedSearches.map(savedSearch => (
              <li className="mb-3 pb-3 px-3 border-bottom" key={savedSearch.id!}>
                <SavedSearchItem
                  onEditSuccess={prevName => {
                    const handler = setTimeout(async () => {
                      if (prevName === appliedSavedSearch?.name ?? '') {
                        setShouldResetCurrentFilter(true);
                      }
                      await refetch();
                    }, SOLR_REFRESH_TIME);
                    delayedReset(handler);
                  }}
                  onDeleteSuccess={() => {
                    const handler = setTimeout(() => resetSavedSearches(), SOLR_REFRESH_TIME);
                    delayedReset(handler);
                  }}
                  savedSearch={savedSearch}
                  onNavigate={() => {
                    setCurrentSource(savedSearch.sourceType ? savedSearch.sourceType : 'all');
                    const filters = FiltersObject.fromJSONString(savedSearch?.filters?.[0] ?? '{}');
                    setSelectedFilterCategories(
                      new Set([
                        ...selectedFilterCategories,
                        ...filters.categories.filter(x => facetCategories.includes(x)),
                      ])
                    );
                    setAppliedFilters(filters);
                    props.onClose();
                  }}
                  openSavedSearchModal={() => {
                    setSavedSearch(savedSearch);

                    props.onClose(true);
                  }}
                  user={user}
                />
              </li>
            ))}
          </ul>
        ) : !loading && !error ? (
          <Alert variant="warning" className="d-flex align-items-center py-0">
            <span className="mr-3" style={{ fontSize: 'x-large' }}>
              <FontAwesomeIcon icon={faFolderOpen} />
            </span>
            You don't have any saved searches !
          </Alert>
        ) : null}

        {error && (
          <div className="py-2 px-4 mx-auto">
            <Alert variant="danger">
              <span>Something went wrong. Please try again later.</span>
              <Button variant="link" className="ml-2" onClick={() => refetch()}>
                Try Now
              </Button>
            </Alert>
          </div>
        )}

        {loading && (
          <div className="py-2 px-4 mx-auto">
            <Spinner animation="border" />
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default SavedSearchPanel;
