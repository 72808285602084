import { IRoadmapTheme, useEnvironment } from '@amgen/core';
import { LazyQueryHookOptions, QueryHookOptions, useApolloClient } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';

import { useAPIConfig } from '../context';
import {
  GetCrawlRequestsDocument,
  GetDestinationsDocument,
  GetSurveysDocument,
  IDataAssetFragment,
  IGetCrawlRequestsQuery,
  IGetCrawlRequestsQueryVariables,
  IGetDestinationsQueryVariables,
  IGetPreviousSearchesQuery,
  IGetPreviousSearchesQueryVariables,
  IGetSurveysQuery,
  IGetSurveysQueryVariables,
  IRoadmapFeatureFragment,
  ISearchQuery,
  ISearchQueryVariables,
  SearchDocument,
} from './__generated__';

export type IGetCrawlRequestsQueryVariablesCopy = IGetCrawlRequestsQueryVariables;
export type IGetDestinationsQueryVariablesCopy = IGetDestinationsQueryVariables;
export type IDataAssetFragmentCopy = IDataAssetFragment;
export type IRoadmapFeatureFragmentCopy = IRoadmapFeatureFragment;

export type IGetRoadmaps = Pick<IRoadmapTheme, 'name' | 'id' | 'color'> & {
  features?: IRoadmapFeatureFragment[];
};

export function useCrawlExportQuery() {
  const client = useApolloClient();
  const { appName } = useAPIConfig();

  return (variables?: Omit<IGetCrawlRequestsQueryVariables, 'appName' | 'requestHandler'>) =>
    client
      .query<IGetCrawlRequestsQuery, IGetCrawlRequestsQueryVariables>({
        query: GetCrawlRequestsDocument,
        variables: { ...variables!, appName },
        fetchPolicy: 'no-cache',
      })
      .then(resp => resp.data.getContentOnboarding);
}

export function useSearchExportQuery() {
  const client = useApolloClient();
  const { appName, reqHandler: requestHandler } = useAPIConfig();

  return (variables?: Omit<ISearchQueryVariables, 'appName' | 'requestHandler'>) =>
    client
      .query<ISearchQuery, ISearchQueryVariables>({
        query: SearchDocument,
        variables: { ...variables!, appName },
        fetchPolicy: 'no-cache',
      })
      .then(resp => resp.data?.search?.docs ?? []);
}

export function useLazySearchPromiseQuery(
  options?: LazyQueryHookOptions<ISearchQuery, Omit<ISearchQueryVariables, 'appName' | 'requestHandler'>>
) {
  const client = useApolloClient();
  const { appName, reqHandler: requestHandler } = useAPIConfig();

  return () =>
    client.query<ISearchQuery, ISearchQueryVariables>({
      query: SearchDocument,
      variables: { ...options!.variables!, appName },
      fetchPolicy: 'no-cache',
    });
}

const RECENT_SEARCHES_COUNT = 2;

// Note: All of this can probably be replaced by using Apollo's inbuild caching mechanism.
export function usePreviousSearches(
  options?: QueryHookOptions<
    IGetPreviousSearchesQuery,
    Omit<IGetPreviousSearchesQueryVariables, 'appName' | 'requestHandler'>
  >
) {
  const { appName } = useEnvironment();
  const loading = false;

  const setStorage = useCallback(
    (values: string[]) => localStorage.setItem(`${appName}-recent-searches`, JSON.stringify(values)),
    [appName]
  );

  const getStorage = useCallback(
    () => JSON.parse(localStorage.getItem(`${appName}-recent-searches`) ?? '[]') as string[],
    [appName]
  );

  const [keywords, setKeywords] = useState<string[]>([]);
  // useEffect(() => {
  //   const currentKeywords = getStorage();
  //   const newKeywords = [...new Set(currentKeywords.concat(data?.getPreviousSearches ?? []))];
  //   setStorage(newKeywords);
  //   setKeywords(newKeywords);
  // }, [data, getStorage, setStorage]);

  const addKeywords = useCallback(
    (keyword: string) => {
      if (keyword) {
        const currentKeywords = getStorage();
        currentKeywords.unshift(keyword);
        const newKeywords = currentKeywords.slice(0, options?.variables?.count ?? RECENT_SEARCHES_COUNT);
        setStorage(newKeywords);
        setKeywords(newKeywords);
      }
    },
    [options, getStorage, setStorage]
  );

  return { loading, keywords, addKeywords };
}

export function useSurveyExportQuery(variables?: Omit<IGetSurveysQueryVariables, 'appName' | 'requestHandler'>) {
  const client = useApolloClient();
  const { appName, reqHandler: requestHandler } = useAPIConfig();

  return () =>
    client
      .query<IGetSurveysQuery, IGetSurveysQueryVariables>({
        query: GetSurveysDocument,
        variables: { ...variables!, appName },
      })
      .then(resp => resp.data?.groupByField?.docs ?? []);
}

export function useSuggestedDestinationsExportQuery(variables) {
  const client = useApolloClient();
  return () =>
    client
      .query({
        query: GetDestinationsDocument,
        variables: { ...variables },
        fetchPolicy: 'no-cache',
      })
      .then(resp => resp.data);
}
