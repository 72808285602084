import { IStudy } from '@amgen/core';
import { ClinicalContext, MatomoContext } from '@amgen/shared';
import DOMPurify from 'dompurify';
import React, { useContext, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

import './knowledge-panel-clinical-items.scss';

import { KNOWLEDGE_PANEL_STUDY_ATTRIBUTES, STUDY_ATTRIBUTE_NAMES } from '../../config';

/* eslint-disable-next-line */
export interface KnowledgePanelClinicalItemsProps {}

export const KnowledgePanelClinicalItems: React.FC<KnowledgePanelClinicalItemsProps> = () => {
  const { clinicalType, clinicalId, product, overviewContainerHeight } = useContext(ClinicalContext);
  const { trackApplicationFeature } = useContext(MatomoContext);
  const itemLimit = 3;

  // this logic moves the searched for study number to the front of the list (if it exists)
  let studies: IStudy[] | undefined;
  const currRole =
    clinicalType === 'study' ? product?.studyInformation?.find(study => study.studynumber === clinicalId) : null;
  if (currRole) {
    studies = product?.studyInformation?.filter(study => study.studynumber !== currRole.studynumber);
    studies?.unshift(currRole);
  } else {
    studies = product?.studyInformation;
  }

  const checkForEmpty = value => {
    return value == null || value.length === 0;
  };

  // const studyAttributeWithoutDeliverable = KNOWLEDGE_PANEL_STUDY_ATTRIBUTES.filter(item => item !== 'deliverable');
  const studyAttributeWithoutDeliverable = KNOWLEDGE_PANEL_STUDY_ATTRIBUTES;
  const productValue = product?.product;
  const isEmptyExpanded = checkForEmpty(productValue);
  const studiesLength = studies && studies?.length - 1;
  return studies && !isEmptyExpanded ? (
    <Col xs="12" className="d-flex flex-column my-2 align-items-start knowledge-panel-attribute">
      {/* <div className="d-flex justify-content-between w-100 mt-2">
        <div className="fs-14 text-capitalize font-weight-bold attribute-key-text">Clinical trials</div>
        <div className="kp-view-more-btn mr-3">
          <NavLink to={`/clinical/product/${product?.product}`}>{`View All`}</NavLink>
        </div>
      </div> */}
      <div
        style={{ height: overviewContainerHeight - 45 }}
        className="thin-scrollbar-gray clinical-trial-container w-100"
      >
        {studies?.map((study, index) => (
          <Row className={index === studiesLength ? 'py-2' : 'py-2 study-info-border'}>
            <Col xs="12">
              {studyAttributeWithoutDeliverable.map(attr =>
                Object.keys(study).includes(attr) ? (
                  <div className="my-1">
                    <span className="mr-2 font-weight-bold attribute-key-text">{`${STUDY_ATTRIBUTE_NAMES[attr]}:`}</span>
                    {attr === 'studynumber' ? (
                      // <NavLink
                      //   to={`/clinical/study/${study[attr]}`}
                      //   onClick={() =>
                      //     trackApplicationFeature(
                      //       SEARCH_APP_FEATURE_LIST.KNOWLEDGE_PANEL,
                      //       KNOWLEDGE_PANEL_FEATURE_ACTIONS.SELECT_STUDY
                      //     )
                      //   }
                      // >

                      //   {study[attr]}
                      // </NavLink>

                      'studytriallink' in study ? (
                        <a href={DOMPurify.sanitize(`${study?.studytriallink}`)} target="_blank" rel="noreferrer">
                          {study[attr]}
                        </a>
                      ) : (
                        <span>{study[attr]}</span>
                      )
                    ) : (
                      <span>
                        {typeof study[attr] === 'string'
                          ? study[attr]
                          : ((study[attr] as unknown) as string[]).join(', ')}
                      </span>
                    )}
                  </div>
                ) : null
              )}
            </Col>
          </Row>
        ))}
      </div>
    </Col>
  ) : (
    <Col xs="12" className="d-flex flex-column my-2 align-items-start knowledge-panel-description">
      <Row className="mb-3">
        <Col xs="12">No data available.</Col>
      </Row>
    </Col>
  );
};

export default KnowledgePanelClinicalItems;
