import * as Types from '@amgen/core';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type IApplicationPagesAccessDetailsFragment = {
  applications?: Types.Maybe<Array<Types.Maybe<Pick<Types.IApplicationInfo, 'parent' | 'pages'>>>>;
};

export type IGetAuthorizationDetailsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type IGetAuthorizationDetailsQuery = {
  getAuthorizationDetails?: Types.Maybe<IApplicationPagesAccessDetailsFragment>;
};

export const ApplicationPagesAccessDetailsFragmentDoc = gql`
  fragment ApplicationPagesAccessDetails on ApplicationPagesAccessDetails {
    applications {
      parent
      pages
    }
  }
`;
export const GetAuthorizationDetailsDocument = gql`
  query GetAuthorizationDetails {
    getAuthorizationDetails {
      ...ApplicationPagesAccessDetails
    }
  }
  ${ApplicationPagesAccessDetailsFragmentDoc}
`;
export function useGetAuthorizationDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<IGetAuthorizationDetailsQuery, IGetAuthorizationDetailsQueryVariables>
) {
  return Apollo.useQuery<IGetAuthorizationDetailsQuery, IGetAuthorizationDetailsQueryVariables>(
    GetAuthorizationDetailsDocument,
    baseOptions
  );
}
export function useGetAuthorizationDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetAuthorizationDetailsQuery, IGetAuthorizationDetailsQueryVariables>
) {
  return Apollo.useLazyQuery<IGetAuthorizationDetailsQuery, IGetAuthorizationDetailsQueryVariables>(
    GetAuthorizationDetailsDocument,
    baseOptions
  );
}
export type GetAuthorizationDetailsQueryHookResult = ReturnType<typeof useGetAuthorizationDetailsQuery>;
export type GetAuthorizationDetailsLazyQueryHookResult = ReturnType<typeof useGetAuthorizationDetailsLazyQuery>;
export type GetAuthorizationDetailsQueryResult = Apollo.QueryResult<
  IGetAuthorizationDetailsQuery,
  IGetAuthorizationDetailsQueryVariables
>;
