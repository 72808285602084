import { LANDING_PAGE_SECTIONS } from '@amgen/shared';
import { faRoute, faSearch, faServer, faShapes } from '@fortawesome/free-solid-svg-icons';

export const SECTIONS = [
  { icon: faSearch, label: LANDING_PAGE_SECTIONS.FIND_EXPLORE_AND_DISCOVER },
  // { icon: faBullhorn, label: LANDING_PAGE_SECTIONS.RECOMMENDATIONS_FOR_YOU },
  { icon: faShapes, label: LANDING_PAGE_SECTIONS.MY_APPS },
  { icon: faRoute, label: LANDING_PAGE_SECTIONS.ROADMAP },
];

export const MOBILE_SECTIONS = [
  { icon: faSearch, label: LANDING_PAGE_SECTIONS.FIND_EXPLORE_AND_DISCOVER },
  // { icon: faBullhorn, label: LANDING_PAGE_SECTIONS.RECOMMENDATIONS_FOR_YOU },
  { icon: faShapes, label: LANDING_PAGE_SECTIONS.MY_APPS },
  // { icon: faRoute, label: LANDING_PAGE_SECTIONS.ROADMAP },
];

export const SMALL_WINDOW_SECTIONS = [
  { icon: faSearch, label: LANDING_PAGE_SECTIONS.FIND_EXPLORE_AND_DISCOVER },
  { icon: faServer, label: LANDING_PAGE_SECTIONS.DATA_SOURCE },
  //   { icon: faBullhorn, label: LANDING_PAGE_SECTIONS.RECOMMENDATIONS_FOR_YOU },
  { icon: faShapes, label: LANDING_PAGE_SECTIONS.MY_APPS },
  { icon: faRoute, label: LANDING_PAGE_SECTIONS.ROADMAP },
];
