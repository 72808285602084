import { IAssetAttribute, IDataAsset } from '@amgen/core';
import React from 'react';
import { Button } from 'react-bootstrap';

import './filter-attribute-container.scss';

export interface FilterAttributeContainerProps {
  isFilter: boolean;
  value: IDataAsset[IAssetAttribute];
  onApplyFilter: (value: string) => void;
}

export const FilterAttributeContainer: React.FC<FilterAttributeContainerProps> = props => {
  return props.isFilter && props.value !== 'technologies' ? (
    <Button
      variant="link"
      className="p-0 text-left border-0"
      onClick={() => props.onApplyFilter(props.value?.toString() ?? '')}
      title={props.value?.toString()}
      style={{ fontSize: '16px', wordBreak: 'break-all' }}
    >
      {props.children}
    </Button>
  ) : (
    <>{props.children}</>
  );
};

export default FilterAttributeContainer;
