import { useAddBinaryFeedbackMutation } from '@amgen/api';
import { IDataAsset, useEnvironment } from '@amgen/core';
import { useCleanSearchQuery, useSearchQueryValue } from '@amgen/shared';
import { SOLR_REFRESH_TIME } from '@amgen/utils';
import { faThumbsDown, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import { faThumbsDown as solidFaThumbsDown, faThumbsUp as solidFaThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { Spinner } from 'react-bootstrap';

import './feature-binary-rating.module.scss';

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
export interface FeatureBinaryRatingProps {
  asset?: IDataAsset;
  detailsPage?: boolean;
  ratingData?: any;
  refetch?: any;
  applicationName?: any;
}

export function FeatureBinaryRating(props: FeatureBinaryRatingProps) {
  const { appName } = useEnvironment();
  const query = useSearchQueryValue();
  const cleanQuery = useCleanSearchQuery();
  const [isLoading, setisLoading] = useState(false);
  const [addBinaryFeedback] = useAddBinaryFeedbackMutation();

  const refetchBinaryFeedback = useCallback(() => {
    setTimeout(() => {
      props?.refetch();
      setisLoading(false);
    }, SOLR_REFRESH_TIME);
  }, [props?.refetch]);

  return (
    <span className={props.detailsPage ? 'mr-3 d-flex' : 'pt-1 d-flex'}>
      {isLoading ? (
        <Spinner animation="border" size="sm" />
      ) : (
        <>
          {props?.ratingData?.id === props?.asset?.id && props?.ratingData?.binaryRating === 1 ? (
            <FontAwesomeIcon
              title="Liked!"
              // className={'text-primary h2 mx-1 my-auto'}
              className={classNames('text-primary h2 mx-1 my-auto', {
                'font-24': props.detailsPage,
              })}
              icon={solidFaThumbsUp}
            />
          ) : (
            <FontAwesomeIcon
              title="Like"
              //className={'text-primary cursor-pointer h2 mx-1 my-auto'}
              className={classNames('text-primary cursor-pointer h2 mx-1 my-auto', {
                'font-24': props.detailsPage,
              })}
              icon={faThumbsUp}
              onClick={() => {
                setisLoading(true);
                addBinaryFeedback({
                  variables: {
                    feedback: {
                      applicationName: props.applicationName,
                      assetId: props?.asset?.id,
                      binaryRating: 1,
                      searchTerm: cleanQuery,
                      type: 'DOCUMENT_FEEDBACK',
                    },
                  },
                })
                  .then(refetchBinaryFeedback)
                  .catch(e => console.log(e));
              }}
            />
          )}

          {props?.ratingData?.id === props?.asset?.id && props?.ratingData?.binaryRating === -1 ? (
            <FontAwesomeIcon
              title="Disliked!"
              //className={'text-primary h2 mx-1 my-auto'}
              className={classNames('text-primary h2 mx-1 my-auto', {
                'font-24': props.detailsPage,
              })}
              icon={solidFaThumbsDown}
            />
          ) : (
            <FontAwesomeIcon
              title="Dislike"
              //className={'text-primary cursor-pointer h2 mx-1 my-auto'}
              className={classNames('text-primary cursor-pointer h2 mx-1 my-auto', {
                'font-24': props.detailsPage,
              })}
              icon={faThumbsDown}
              onClick={() => {
                setisLoading(true);
                addBinaryFeedback({
                  variables: {
                    feedback: {
                      applicationName: props?.applicationName,
                      assetId: props?.asset?.id,
                      binaryRating: -1,
                      searchTerm: query,
                      type: 'DOCUMENT_FEEDBACK',
                    },
                  },
                })
                  .then(refetchBinaryFeedback)
                  .catch(e => console.log(e));
              }}
            />
          )}
        </>
      )}
    </span>
  );
}

export default FeatureBinaryRating;
