import { IGetRoadmaps } from '@amgen/api';
import React from 'react';

import './roadmap-feature-row.module.scss';

import { IRoadmapFeatureExtended } from '../../hooks/feature-utils';
import RoadmapFeature from '../roadmap-feature/roadmap-feature';

/* eslint-disable-next-line */
export interface RoadmapFeatureRowProps extends IGetRoadmaps {
  quarterNumberFeatures: { [k: string]: IRoadmapFeatureExtended[] };
  quarterIndex: number;
}

export function RoadmapFeatureRow(props: RoadmapFeatureRowProps) {
  const maxCountOfLargestQuarter = Math.max(...Object.values(props.quarterNumberFeatures).map(el => el.length));

  return (
    <>
      {Object.entries(props.quarterNumberFeatures).map(([w, features], i) =>
        features?.map((x, j) => (
          <RoadmapFeature
            key={x.id}
            {...x}
            index={parseInt(w) < props.quarterIndex ? i + maxCountOfLargestQuarter + j : j}
            quarterIndex={props.quarterIndex}
          />
        ))
      )}
    </>
  );
}

export default RoadmapFeatureRow;
