import classnames from 'classnames';
import React from 'react';
import { Nav } from 'react-bootstrap';

import './header-left.scss';

export interface HeaderLeftProps {
  className?: string;
}

export const HeaderLeft: React.FC<HeaderLeftProps> = props => {
  return (
    <Nav className={classnames('mr-auto align-items-center', props.className)}>
      {React.Children.map(props.children, child => (
        <Nav.Item>{child}</Nav.Item>
      ))}
    </Nav>
  );
};

export default HeaderLeft;
