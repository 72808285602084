import { useSpellcheckQuery } from '@amgen/api';
import { useEnvironment } from '@amgen/core';
import { AppContext, MatomoContext, SEARCH_APP_FEATURE_LIST, SPELLCHECK_ACTIONS, useSearchQueryValue } from '@amgen/shared';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import './spellcheck-link.module.scss';

/* eslint-disable-next-line */
export interface SpellcheckLinkProps {}

export function SpellcheckLink(props: SpellcheckLinkProps) {
  const { trackApplicationFeature } = useContext(MatomoContext);
  const query = useSearchQueryValue();
  const { appName } = useEnvironment();
  const { resultCount, currentSource, selectedResultTab } = useContext(AppContext);
  const abTestingGroup = JSON.parse(sessionStorage.getItem('abTestingGroup') as string);
  const sourceType = currentSource;
  const { data } = useSpellcheckQuery({
    variables: {
      query,
      appName,
      sourceType,
      userType: sessionStorage.getItem('employeeType') || '',
    },
    skip: abTestingGroup,
    onCompleted: () => {
      if (data?.spellcheck?.length) {
        trackApplicationFeature(SEARCH_APP_FEATURE_LIST.SPELL_CHECK, SPELLCHECK_ACTIONS.SPELLCHECK);
      }
    },
  });

  const params = new URLSearchParams();
  params.append('q', data?.spellcheck?.replaceAll('"', '') ?? '');

  return data?.spellcheck?.length &&
    query.length >= 4 &&
    resultCount === 0 &&
    selectedResultTab !== 'Chat' &&
    !abTestingGroup ? (
    <p className="text-overflow-ellipsis w-100 ml-4 pl-2">
      <span className="mr-2 ">Other search suggestion:</span>
      <Link
        to={{
          pathname: '/',
          search: params.toString(),
        }}
        onClick={() =>
          trackApplicationFeature(SEARCH_APP_FEATURE_LIST.SPELL_CHECK, SPELLCHECK_ACTIONS.SPELLCHECK_CLICK)
        }
        className="font-italic font-weight-bold"
      >
        {/*[todo] Not always suggestions with quotes are to be removed */}
        {data.spellcheck.replaceAll('"', '')}
      </Link>
    </p>
  ) : null;
}

export default SpellcheckLink;
