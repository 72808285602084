import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import './dot-navigator.scss';

export interface DotNavigatorProps {
  isMobile: boolean;
  sections: { label: string; icon: IconProp }[];
  position?: 'left' | 'right';
  currentPage: number;
  handleNavigatorClick: (page: number) => void;
}

export const DotNavigator = (props: DotNavigatorProps) => {
  return (
    <div
      className="position-fixed dot-navigator-container p-2 shadow-lg bg-white rounded-pill"
      style={{ top: props.isMobile ? '60%' : '50%', [props.position ?? 'left']: props.isMobile ? '1rem' : '3rem' }}
    >
      <ul className="list-unstyled text-center">
        {props.sections.map((x, i) => (
          <li
            className="my-3 ml-1 dot-navigator-item cursor-pointer"
            key={x.label}
            onClick={() => props.handleNavigatorClick(i)}
          >
            <OverlayTrigger
              placement={props.position ?? 'right'}
              overlay={
                <Tooltip id={`tooltip-${x.label}`} className="text-capitalize">
                  {x.label}
                </Tooltip>
              }
            >
              <FontAwesomeIcon
                icon={x.icon}
                size="lg"
                className={classnames('text-grey d-block p-1 dot-navigator-icon', {
                  'active text-custom-blue': props.currentPage === i,
                })}
                flip={props.currentPage === i ? undefined : 'horizontal'}
              />
            </OverlayTrigger>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DotNavigator;
