import { useAssetTitleUrl } from '@amgen/feature-search';
import { AssetContext } from '@amgen/shared';
import React, { useContext } from 'react';

import './view-downloads-button.scss';

import AssetActionsMenu from '../asset-actions-menu/asset-actions-menu';
import AttachmentDownloadButton from '../attachment-download-button/attachment-download-button';

/* eslint-disable-next-line */
export interface ViewDownloadsButtonProps {}

export const ViewDownloadsButton: React.FC<ViewDownloadsButtonProps> = props => {
  const { docSolrAsset } = useContext(AssetContext);
  const { title: assetTitle } = useAssetTitleUrl(docSolrAsset.dataAsset!);

  return (
    <AssetActionsMenu.Item actionType={'downloads-btn'}>
      <AssetActionsMenu.Item.Name>{`Download File(s)`}</AssetActionsMenu.Item.Name>
      <AssetActionsMenu.Item.Popup>
        <AssetActionsMenu.Item.Popup.Heading />
        <AssetActionsMenu.Item.Popup.Body>
          <h3 className="bg-grey px-1">Files for Download</h3>
          {[docSolrAsset.dataAsset?.filename]?.map(url =>
            url ? <AttachmentDownloadButton key={url} url={url} fileName={assetTitle} /> : null
          )}
        </AssetActionsMenu.Item.Popup.Body>
      </AssetActionsMenu.Item.Popup>
    </AssetActionsMenu.Item>
  );
};

export default ViewDownloadsButton;
