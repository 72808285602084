import { IDataCrawlRequest } from '@amgen/core';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import ClampLines from 'react-clamp-lines';
import { CellProps, Column } from 'react-table';

import AttachmentDownloadLink from '../components/attachment-download-link/attachment-download-link';
import { safeFormatDate } from './utils';

export const ADMIN_PENDING_COL: Column<IDataCrawlRequest>[] = [
  { Header: 'S.No', Cell: ({ row }: CellProps<IDataCrawlRequest>) => row.index + 1 },
  { Header: 'Data Source', accessor: 'contentSource' },
  {
    Header: 'Site URL / Space Name',
    accessor: 'sourcePath',
    Cell: ({ row }: CellProps<IDataCrawlRequest>) =>
      row.original.sourcePath ? (
        <ClampLines
          className="clampped-content p-0 w-100"
          text={row.original.sourcePath}
          id={row.id + Math.random().toString()}
          lines={3}
          ellipsis="..."
          moreText="Show more"
          lessText="Show Less"
        />
      ) : null,
  },
  { Header: 'Display Name', accessor: 'displayName' },
  { Header: 'Requestor', accessor: 'requesterName' },
  { Header: 'Site Owner', Cell: ({ row }: CellProps<IDataCrawlRequest>) => (row.original.owner ? 'Yes' : 'No') },
  {
    Header: 'File',
    Cell: ({ row }: CellProps<IDataCrawlRequest>) =>
      row.original.s3Location ? (
        <AttachmentDownloadLink s3Location={row.original.s3Location}>
          <FontAwesomeIcon className="text-primary" icon={faPaperclip} />
        </AttachmentDownloadLink>
      ) : null,
  },
  {
    Header: 'Requested Date',
    accessor: 'createdOn',
    Cell: ({ row }: CellProps<IDataCrawlRequest>) => safeFormatDate(row.values['createdOn']),
  },
];

export const ADMIN_APPROVED_DECLINED_COL: Column<IDataCrawlRequest>[] = [
  { Header: 'S.No', Cell: ({ row }: CellProps<IDataCrawlRequest>) => row.index + 1 },
  { Header: 'Data Source', accessor: 'contentSource' },
  {
    Header: 'Site URL / Space Name',
    accessor: 'sourcePath',
    Cell: ({ row }: CellProps<IDataCrawlRequest>) =>
      row.original.sourcePath ? (
        <ClampLines
          className="clampped-content p-0 w-100"
          text={row.original.sourcePath}
          id={row.id + Math.random().toString()}
          lines={3}
          ellipsis="..."
          moreText="Show more"
          lessText="Show Less"
        />
      ) : null,
  },
  { Header: 'Display Name', accessor: 'displayName' },
  {
    Header: 'Requested Date',
    accessor: 'createdOn',
    Cell: ({ row }: CellProps<IDataCrawlRequest>) => safeFormatDate(row.values['createdOn']),
  },
  {
    Header: 'File ',
    Cell: ({ row }: CellProps<IDataCrawlRequest>) =>
      row.original.s3Location ? (
        <AttachmentDownloadLink s3Location={row.original.s3Location}>
          <FontAwesomeIcon className="text-primary" icon={faPaperclip} />
        </AttachmentDownloadLink>
      ) : null,
  },
  { Header: 'Reviewer Name', accessor: 'reviewerName' },
  {
    Header: 'Reviewed On',
    accessor: 'reviewedOn',
    Cell: ({ row }: CellProps<IDataCrawlRequest>) => safeFormatDate(row.values['reviewedOn']),
  },
  { Header: "Reviewer's Remarks", accessor: 'reviewerRemarks' },
];
