import { ApolloError } from '@apollo/client';
import classNames from 'classnames';
import React from 'react';
import { Button, Spinner } from 'react-bootstrap';

import './loader-error-container.scss';

// split the HOC component into two individual HOC to report loader and  error seperately
export interface LoaderErrorContainerProps {
  loading: boolean;
  hideSpinner?: boolean;
  spinnerSize?: 'sm';
  // settingSpinnerSize?:
  reload?: () => void;
  reloadMsg?: string;
  showEmpty?: boolean;
  errors?: (ApolloError | undefined)[];
  emptyImgSrc?: string;
  className?: string;
  messageWithoutSpinner?: string;
  messageWithSpinner?: string;
  setting?:boolean
}

export const LoaderErrorContainer: React.FC<LoaderErrorContainerProps> = props => {
  // console.log(setting)

  if(props.setting && props.loading){
   return( <div className="d-flex justify-content-center">
    <div className="spinner-border text-primary spinner-size"  role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
   )
  }else{
  if (props.loading) {
    return !props.hideSpinner ? (
      <div
        className={classNames(props.className, 'd-flex justify-content-center align-items-center h-100')}
        style={{ display: 'flex', flexDirection: 'column' }}
      >

        <Spinner animation="border" role="status" variant="primary" size={props.spinnerSize}>
          <span className="sr-only">Loading...</span>
        </Spinner>
        <span className="my-2 loader-msg text-primary">{props.messageWithSpinner}</span>
      </div>

    ) : (
      <span className="my-2">{props.messageWithoutSpinner}</span>
    );
  }
}
  if (props.errors && props.errors.length > 0) {
    return props.reload ? (
      <>
        <div className="d-flex justify-content-center">
          <img
            className="server-error ml-5"
            src={props.emptyImgSrc ?? 'assets/images/server-error.png'}
            alt="no results found"
          />
        </div>

        <div className="d-flex justify-content-center">
          <p className="text-danger">Internal server error</p>
        </div>

        <div className="mt-4 d-flex justify-content-center">
          <Button className="px-4" variant="outline-warning" onClick={props.reload}>
            Please Retry
          </Button>
        </div>
      </>
    ) : null;
  }

  if (props.showEmpty) {
    return (
      <div className="d-flex justify-content-center">
        <img
          className="d-block m-auto w-50"
          src={props.emptyImgSrc ?? 'assets/images/server-error.png'}
          alt="No results found"
          title="No results found"
        />
      </div>
    );
  }

  return <>{props.children}</>;
};

export default LoaderErrorContainer;
