import React from 'react';
import { Col, Row } from 'react-bootstrap';

import './grid-search-results.scss';

import { IResultViewProps } from '../../models';
import GridSearchResult from '../grid-search-result/grid-search-result';

export const GridSearchResults: React.FC<IResultViewProps> = ({ results, ...others }) => {
  return (
    <Row as="ol" noGutters className="list-unstyled mx-n2">
      {results.map((result, i) => (
        <Col as="li" xs="12" sm="6" key={result.dataAsset?.id} className="grid-result-item mb-3">
          <GridSearchResult docPosition={i} docSolrAsset={result} {...others} />
        </Col>
      ))}
    </Row>
  );
};

export default GridSearchResults;
