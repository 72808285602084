import React from 'react';
import { InputGroup } from 'react-bootstrap';

import './search-box-input-group-prepend.scss';

/* eslint-disable-next-line */
export interface SearchBoxInputGroupPrependProps {}

export const SearchBoxInputGroupPrepend: React.FC<SearchBoxInputGroupPrependProps> = ({ children }) => {
  return <InputGroup.Prepend className="search-icon">{children}</InputGroup.Prepend>;
};

export default SearchBoxInputGroupPrepend;
