import { DataSource, IAssetAttribute } from '@amgen/core';
import { AssetAttributeRow, useDisplayAttributes } from '@amgen/feature-asset-attributes';
import { AppContext, AssetContext, FacetContext, ResultViewType } from '@amgen/shared';
import React, { useContext, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';

import './attributes.scss';

export interface AttributesProps {
  displayAllAttributes?: boolean;
  displayRatio: [number, number];
  showThumbnail?: boolean;
  isMobile?: boolean;
}

export const Attributes: React.FC<AttributesProps> = ({
  displayAllAttributes,
  displayRatio,
  showThumbnail,
  isMobile,
}) => {
  const { displayAttributes, setPageNo } = useContext(AppContext);
  const { docSolrAsset, viewType, isToggleEnabled, setShowReadMoreFlag, showReadMoreFlag } = useContext(AssetContext);

  // TODO: Remove this default value after all data has content source value
  const displayableAttributes = useDisplayAttributes(
    docSolrAsset?.dataAsset?.content_source && docSolrAsset?.dataAsset?.content_source !== 'AmgenHub'
      ? docSolrAsset.dataAsset?.content_source
      : DataSource.TWITTER
  );
  const attributeList = displayAllAttributes ? displayAttributes : displayableAttributes;
  const { addFilterCategory, appliedFilters, setAppliedFilters, clickableAttributes } = useContext(FacetContext);
  const abTestingGroup = JSON.parse(sessionStorage.getItem('abTestingGroup') as string);
  const attributeListABUSER = abTestingGroup ? attributeList.filter(i => i !== 'content') : attributeList;
  // TODO: Move this into asset context
  const handleApplyFilter = (value: string, attribute: IAssetAttribute) => {
    addFilterCategory(attribute);
    setAppliedFilters(appliedFilters.add(value, attribute));
    setPageNo(1);
  };
  // replace attributeListABUSER with attributeList if content needs to be displayed
  // TODO: This useMemo has not effect. Fix the values in the parent components to make this work.
  const filteredAttributeList: IAssetAttribute[] = useMemo(
    () =>
      attributeListABUSER.filter(attribute => {
        const value = abTestingGroup
          ? docSolrAsset.dataAsset?.[attribute] === 'content'
            ? null
            : docSolrAsset.dataAsset?.[attribute]
          : docSolrAsset.dataAsset?.[attribute];
        return Array.isArray(value) ? value.length !== 0 && value.some((v: unknown) => !!v) : !!value;
      }),
    [attributeList, docSolrAsset.dataAsset]
  );
  // TODO : change this back to filteredAttributeList.length < 4 once the data injestion is completed from BE
  if (filteredAttributeList && filteredAttributeList.length < 1) {
    setShowReadMoreFlag(false);
  }

  const isTwoColumnLayout = viewType === ResultViewType.List;
  // Moving long attributes (e.g tags, technologies, author_s) from top of list to bottom and take 12 column length
  const LONG_ATTRIBUTES: IAssetAttribute[] = isTwoColumnLayout
    ? (['tags', 'technologies', 'author_s'] as IAssetAttribute[]).filter(x => filteredAttributeList.includes(x))
    : [];

  return (
    <Col xs="12">
      <Row className="no-gutters">
        {docSolrAsset?.dataAsset?.short_content && (
          <Col xs="12" className="mt-2">
            <AssetAttributeRow
              asset={docSolrAsset.dataAsset}
              displayRatio={isTwoColumnLayout ? [3, 10] : displayRatio}
              attribute="short_content"
              clickableAttributes={clickableAttributes}
              onApplyFilter={handleApplyFilter}
              isALongAttribute
              isMobile={isMobile}
            />
          </Col>
        )}

        {filteredAttributeList.includes('content_source') && docSolrAsset?.dataAsset?.content_source && (
          <Col xs="12" md={isToggleEnabled ? 12 : isTwoColumnLayout ? 6 : 12} className={isMobile ? 'px-1' : 'px-3'}>
            <AssetAttributeRow
              asset={docSolrAsset.dataAsset}
              displayRatio={displayRatio}
              attribute="content_source"
              clickableAttributes={clickableAttributes}
              onApplyFilter={handleApplyFilter}
              isALongAttribute={false}
            />
          </Col>
        )}

        {filteredAttributeList.includes('doc_nav_prefix') &&
          docSolrAsset?.dataAsset?.doc_nav_prefix &&
          !abTestingGroup && (
            <Col xs="12" md={isToggleEnabled ? 12 : isTwoColumnLayout ? 6 : 12} className={isMobile ? 'px-1' : 'px-3'}>
              <AssetAttributeRow
                asset={docSolrAsset.dataAsset}
                displayRatio={displayRatio}
                attribute="doc_nav_prefix"
                clickableAttributes={clickableAttributes}
                onApplyFilter={handleApplyFilter}
                isALongAttribute={false}
              />
            </Col>
          )}
        {docSolrAsset?.dataAsset?.doc_last_modified && (!abTestingGroup || (abTestingGroup && isToggleEnabled)) && (
          <Col xs="12" md={isToggleEnabled ? 12 : isTwoColumnLayout ? 6 : 12} className={isMobile ? 'px-1' : 'px-3'}>
            <AssetAttributeRow
              asset={docSolrAsset.dataAsset}
              displayRatio={displayRatio}
              attribute="doc_last_modified"
              clickableAttributes={clickableAttributes}
              onApplyFilter={handleApplyFilter}
              isALongAttribute={false}
            />
          </Col>
        )}
        {isToggleEnabled &&
          filteredAttributeList
            .filter(
              attribute =>
                !['short_content', 'content_source', 'doc_last_modified', 'doc_nav_prefix'].includes(attribute)
            )
            .filter(attribute => attribute !== 'thumbnail_large' && !LONG_ATTRIBUTES.includes(attribute))
            .map(attribute => (
              <Col xs="12" md={isTwoColumnLayout ? 12 : 12} key={attribute} className={isMobile ? 'px-1' : 'px-3 pt-1'}>
                <AssetAttributeRow
                  key={attribute}
                  asset={docSolrAsset.dataAsset!}
                  displayRatio={displayRatio}
                  attribute={attribute}
                  clickableAttributes={clickableAttributes}
                  onApplyFilter={handleApplyFilter}
                />
              </Col>
            ))}

        {isToggleEnabled &&
          LONG_ATTRIBUTES?.map(attribute => (
            <Col xs="12" className={isMobile ? 'my2 px-1' : 'pt-1 my-0 px-3'} key={attribute}>
              <AssetAttributeRow
                key={attribute}
                asset={docSolrAsset.dataAsset!}
                displayRatio={displayRatio}
                attribute={attribute}
                clickableAttributes={clickableAttributes}
                onApplyFilter={handleApplyFilter}
                isALongAttribute={filteredAttributeList.includes('thumbnail_large') && attribute === 'content'}
              />
            </Col>
          ))}
      </Row>
    </Col>
  );
};

export default Attributes;
