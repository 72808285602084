import { IClusterData } from '@amgen/core';
import React, { useEffect, useMemo, useState } from 'react';

import './cluster-search-context-provider.module.scss';

import { ClusterSearchContext, IClusterSearchState, IResult } from './cluster-search-context';
import { useClusteringSearchQuery, useSelectedClusterResults } from './hooks';

/* eslint-disable-next-line */
export interface ClusterSearchContextProviderProps {}

export const ClusterSearchContextProvider: React.FC<ClusterSearchContextProviderProps> = ({ children }) => {
  const { clusters, resultCount } = useClusteringSearchQuery();
  const [selectedCluster, setSelectedCluster] = useState<IClusterData | null>(null);
  const { selectedClusterResults, selectedClusterResultsLoading } = useSelectedClusterResults(selectedCluster);
  const [selectedResult, setSelectedResult] = useState<IResult | null>(
    selectedClusterResults?.length ? selectedClusterResults[0] : null
  );

  useEffect(() => {
    if (!selectedCluster) {
      setSelectedCluster(clusters?.length ? clusters[0] : null);
    }
  }, [clusters, selectedCluster, setSelectedCluster]);

  useEffect(() => {
    if (selectedClusterResults.length) {
      setSelectedResult(selectedClusterResults[0]);
    }
  }, [selectedClusterResults, setSelectedResult]);

  const providedValue = useMemo<IClusterSearchState>(
    () => ({
      clusters,
      resultCount,
      selectedCluster,
      setSelectedCluster,
      selectedClusterResults,
      selectedClusterResultsLoading,
      selectedResult,
      setSelectedResult,
    }),
    [clusters, resultCount, selectedCluster, selectedClusterResults, selectedClusterResultsLoading, selectedResult]
  );

  return <ClusterSearchContext.Provider value={providedValue}>{children}</ClusterSearchContext.Provider>;
};

export default ClusterSearchContextProvider;
