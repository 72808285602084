import { IDestinationsData } from '@amgen/core';
import React from 'react';
import { HeaderGroup } from 'react-table';

import './suggested-app-table-head.module.scss';

/* eslint-disable-next-line */
export interface SuggestedAppTableHeadProps {
  headerGroups: HeaderGroup<IDestinationsData>[];
}

export function SuggestedAppTableHead(props: SuggestedAppTableHeadProps) {
  return (
    <>
      {props.headerGroups.map(headerGroup => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map(header => (
            <th {...header.getHeaderProps()}>{header.render('Header')}</th>
          ))}
        </tr>
      ))}
    </>
  );
}

export default SuggestedAppTableHead;
