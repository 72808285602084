import { useFacetQuery } from '@amgen/api';
import { LoaderErrorContainer, PieChart } from '@amgen/components';
import { ApplicationName, IAssetAttribute } from '@amgen/core';
import { AppContext, useCleanSearchQuery } from '@amgen/shared';
import React, { useContext } from 'react';

import './facet-pie-chart.scss';

import { CHART_COLORS, DASHBOARD_FILTER_COUNT } from '../../config';
import { convertToPieChartData } from '../../utils';

export interface FacetPieChartProps {
  filterString: string[];
  filter: IAssetAttribute;
  chartTitle: string;
  colors?: string[];
  isHorizontal?: boolean;
  onFilterSelect?: (key: string) => void;
}

export const FacetPieChart = (props: FacetPieChartProps) => {
  const appName: ApplicationName = sessionStorage.getItem('appName')
    ? ApplicationName[sessionStorage.getItem('appName')!]
    : ApplicationName.EEA;
  const cleanQuery = useCleanSearchQuery();
  const { currentSource } = useContext(AppContext);
  const { data, loading, error } = useFacetQuery({
    variables: {
      solrHandler: appName === 'DVIEW' ? '/dview-search-select' : '/search-select',
      query: cleanQuery,
      filters: props.filterString,
      fields: [{ name: props.filter, facetLimit: DASHBOARD_FILTER_COUNT }],
      excludeCount: false,
      appName,
      userType: sessionStorage.getItem('employeeType') || '',
      sourceType: currentSource,
    },
  });

  return (
    <LoaderErrorContainer loading={loading} errors={error ? [error] : []}>
      {data?.facet?.[0]?.values && (
        <PieChart
          chartName={props.chartTitle}
          colors={props.colors ?? CHART_COLORS.product}
          onChartClick={key => props.onFilterSelect && props.onFilterSelect(key.name)}
          coordinates={convertToPieChartData(data?.facet!, props.filter)}
        />
      )}
    </LoaderErrorContainer>
  );
};

export default FacetPieChart;
