import { useOutsideClick } from '@amgen/shared';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useRef, useState } from 'react';
import { ButtonGroup, Dropdown, FormControl, InputGroup } from 'react-bootstrap';
import { DateRange } from 'react-date-range';

import './date-range-datepicker.scss';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { IDateRangeValue } from '../date-range-dropdown/date-range-dropdown';

export interface DateRangeDatepickerProps {
  value?: IDateRangeValue | null;
  onValueChange: (value: IDateRangeValue) => void;
  disabled?: boolean;
  active: boolean;
  customValueText: string;
}

export const DateRangeDatepicker = (props: DateRangeDatepickerProps) => {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const datePickerRef = useRef<any>(null);
  useOutsideClick(datePickerRef, () => setShowDatePicker(false));

  const dateDisplayValue = useCallback((range?: IDateRangeValue | null, active?: boolean) => {
    if (range && active) {
      const formatter = new Intl.DateTimeFormat('en');
      const { startDate, endDate } = range.value[0];
      return `${formatter.format(startDate)} - ${formatter.format(endDate)}`;
    }

    return '';
  }, []);

  const handleChange = (range: any) => {
    props.onValueChange({ label: props.customValueText, value: [range.selection] });
  };

  return (
    <InputGroup className="daterange-dropdown shadow-none">
      <FormControl
        type="text"
        readOnly
        onFocus={() => setShowDatePicker(true)}
        value={dateDisplayValue(props.value, props.active)}
        className="h-auto"
      />

      <InputGroup.Append>
        <Dropdown
          id="data-picker"
          as={ButtonGroup}
          variant="outline-primary"
          show={showDatePicker}
          align="right"
          drop="right"
        >
          <Dropdown.Toggle
            split
            variant="outline-secondary"
            className="date-range-dropdown-btn caret-off"
            id="range-datepicker"
            onClick={() => setShowDatePicker(!showDatePicker)}
          >
            <FontAwesomeIcon icon={faCalendar} className="text-primary" />
          </Dropdown.Toggle>

          <Dropdown.Menu ref={datePickerRef}>
            <div className="advance-search-date-range">
              <DateRange
                color={props.active ? '#0063c3' : '#87a3bf'}
                maxDate={new Date()}
                showDateDisplay={false}
                onChange={(item: any) => handleChange(item)}
                ranges={props.value?.value ?? [{ startDate: null, endDate: null, key: 'selection' }]}
                disabled={props.disabled}
                className="date-range-picker-custom"
              />
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </InputGroup.Append>
    </InputGroup>
  );
};

export default DateRangeDatepicker;
