import { useAPIConfig, useDowntimeConfigQuery } from '@amgen/api';
import { useWindowSizeListener } from '@amgen/shared';
import classNames from 'classnames';
import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

import './eea-notification-banner.scss';

import ExpandButtonModal from './expand-button-modal/expand-button-modal';

/* eslint-disable-next-line */
export interface EEANotificationBannerProps {
  setShowNotification: (showNotification: boolean) => void;
}
export const EEANotificationBanner = (props: EEANotificationBannerProps) => {
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const [onExpandCurrentIndex, setOnExpandCurrentIndex] = useState(0);
  const [isExpandOpen, setIsExpandOpen] = useState(false);
  const [genAIModal, setGenAIModal] = useState(false);
  const isMobile = useWindowSizeListener();
  const { adminUrl } = useAPIConfig();
  const configData = useDowntimeConfigQuery(adminUrl, true);
  const abTestingGroup = JSON.parse(sessionStorage.getItem('abTestingGroup') as string);
  const currentCustomsource = sessionStorage.getItem('current-source-selected');
  const [announcementBannerData, setAnnouncementBannerData] = useState<any>([]);

  useEffect(() => {
    if (configData && configData.response && configData.response.banner_data) {
      setAnnouncementBannerData(configData.response.banner_data);
    }
  }, [configData]);

  const bannerData: any =
    abTestingGroup && currentCustomsource !== 'Contract Search'
      ? announcementBannerData.filter(i => i.ab_testing_user === 'true')
      : announcementBannerData.filter(i => i.ab_testing_user === 'false');

  const bannerDataMinimized: any =
    abTestingGroup && currentCustomsource !== 'Contract Search'
      ? announcementBannerData.filter(
          i => i.ab_testing_user === 'true' && i.title !== 'Welcome to the Enhanced Search Portal with GenAI!'
        )
      : announcementBannerData.filter(i => i.ab_testing_user === 'false');

  const mainteinanceBannerData = bannerData?.filter(data => {
    return data.message_type === 'maintenance';
  });
  const nonmainteinanceBannerData = bannerData?.filter(data => {
    return data.message_type !== 'maintenance';
  });
  const bannerDisplayData = mainteinanceBannerData?.length !== 0 ? mainteinanceBannerData : nonmainteinanceBannerData;

  const mainteinanceBannerDataMinimized = bannerDataMinimized?.filter(data => {
    return data.message_type === 'maintenance';
  });
  const nonmainteinanceBannerDataMinimized = bannerDataMinimized?.filter(data => {
    return data.message_type !== 'maintenance';
  });
  const bannerDisplayDataMinimized =
    mainteinanceBannerData?.length !== 0 ? mainteinanceBannerDataMinimized : nonmainteinanceBannerDataMinimized;

  const onExpandBannerCurrentData = nonmainteinanceBannerData &&
    mainteinanceBannerData &&
    bannerDisplayDataMinimized && [
      bannerDisplayDataMinimized?.[onExpandCurrentIndex],
      ...mainteinanceBannerData,
      ...nonmainteinanceBannerData,
    ];

  const bannerDataExpanded: any = announcementBannerData?.filter(
    i => i.ab_testing_user === 'true' && i.title === 'Welcome to the Enhanced Search Portal with GenAI!'
  );

  const onExpandBannerCurrentDatabUser = nonmainteinanceBannerDataMinimized &&
    mainteinanceBannerDataMinimized &&
    bannerDataExpanded && [
      bannerDataExpanded?.[0],
      ...mainteinanceBannerDataMinimized,
      ...nonmainteinanceBannerDataMinimized,
    ];

  const onExpandBannerData =
    onExpandBannerCurrentData &&
    onExpandBannerCurrentData.length > 0 &&
    onExpandBannerCurrentData?.reduce((accumulator, current) => {
      if (current !== undefined && !accumulator?.find(item => item['description'] === current?.description)) {
        accumulator.push(current as never);
      }
      return accumulator;
    }, []);

  const onExpandBannerDataabUser =
    onExpandBannerCurrentDatabUser &&
    onExpandBannerCurrentDatabUser?.reduce((accumulator, current) => {
      if (!accumulator?.find(item => item['description'] === current?.description)) {
        accumulator.push(current as never);
      }
      return accumulator;
    }, []);

  const handleCloseGenAI = () => {
    setGenAIModal(false);
    localStorage.setItem('genAIModalShown', JSON.stringify(true));
  };

  const handleCloseBanner = () => {
    setIsExpandOpen(false);
  };

  useEffect(() => {
    const genAIModalShownOnTuesday = JSON.parse(localStorage.getItem('genAIModalShown') as string);
    const today = new Date();
    const isBannerPopupDay = today.getDay() === 1 || today.getDay() === 3 || today.getDay() === 5; // Sunday is 0, Monday is 1, Tuesday is 2, etc.
    if (
      !genAIModalShownOnTuesday &&
      isBannerPopupDay &&
      abTestingGroup &&
      currentCustomsource !== 'Contract Search' &&
      configData?.response?.show_banner_popup === 'true'
    ) {
      setGenAIModal(true);
    }
  }, [bannerData, currentCustomsource]);

  useEffect(() => {
    let notificationTimeout;
    if (!isExpandOpen && bannerDisplayDataMinimized && bannerDisplayDataMinimized.length > 0) {
      notificationTimeout = setTimeout(
        () => setCurrentItemIndex((currentItemIndex + 1) % bannerDisplayDataMinimized.length),
        10000
      );
    }
    return () => clearTimeout(notificationTimeout);
  }, [currentItemIndex, isExpandOpen, bannerDisplayDataMinimized]);

  const closeNotification = () => {
    props.setShowNotification(false);
  };

  const onExpandNotification = () => {
    setIsExpandOpen(true);
    setOnExpandCurrentIndex(currentItemIndex);
  };

  return (
    <div>
      {bannerDataMinimized &&
      bannerDataMinimized.length > 0 &&
      bannerDisplayDataMinimized?.[currentItemIndex] !== undefined ? (
        <div
          className={
            !isMobile
              ? classNames('notification-container pr-1', bannerDisplayDataMinimized?.[currentItemIndex]?.message_type)
              : classNames('notification-container pr-1', bannerDisplayDataMinimized?.[currentItemIndex]?.message_type)
          }
        >
          <p className="mb-3 mt-3">
            <span className="mr-2 pr-2 notification-title">
              {bannerDisplayDataMinimized?.[currentItemIndex]?.title}
            </span>
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(bannerDisplayDataMinimized?.[currentItemIndex]?.description),
              }}
            ></span>
          </p>

          <div className="d-flex pr-4 mr-2">
            <Button className="expand-button mr-3" variant="light" onClick={onExpandNotification}>
              Expand
            </Button>

            <img
              src="assets/images/close-notification.svg"
              alt="close"
              className="close-notification"
              onClick={closeNotification}
            />
          </div>
        </div>
      ) : bannerDataMinimized === undefined ||
        (bannerDisplayDataMinimized.length !== 0 && bannerDisplayDataMinimized?.[currentItemIndex] === undefined) ? (
        <div className={!isMobile ? classNames('notification-container pr-1', 'no-data') : ''}>
          <p className="loading-text mb-3 mt-3">
            <span className="mr-2 pr-2">Loading...</span>
          </p>
        </div>
      ) : (
        <></>
      )}
      {isExpandOpen && onExpandBannerData.length > 0 ? (
        <ExpandButtonModal
          isExpandOpen={isExpandOpen}
          handleCloseBanner={handleCloseBanner}
          onExpandBannerData={onExpandBannerData}
        />
      ) : genAIModal && onExpandBannerDataabUser.length > 0 ? (
        <ExpandButtonModal
          isExpandOpen={genAIModal}
          handleCloseBanner={handleCloseGenAI}
          onExpandBannerData={onExpandBannerDataabUser}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default EEANotificationBanner;
