import { IDataCrawlRequest } from '@amgen/core';
import React from 'react';
import ClampLines from 'react-clamp-lines';
import { CellProps, Column } from 'react-table';

import { safeFormatDate } from './utils';

export const PENDING_COLUMNS: Column<IDataCrawlRequest>[] = [

  { Header: 'S.No', Cell: ({ row }: CellProps<IDataCrawlRequest>) =>row.index+1},
  { Header: 'Data Source', accessor: 'contentSource' },
  {
    Header: 'Site URL / Space Name',
    accessor: 'sourcePath',
    Cell: ({ row }: CellProps<IDataCrawlRequest>) =>
      row.original.sourcePath ? (
        <ClampLines
          className="clampped-content p-0 w-100"
          text={row.original.sourcePath}
          id={row.id + Math.random().toString()}
          lines={3}
          ellipsis="..."
          moreText="Show more"
          lessText="Show Less"
        />
      ) : null,
  },
  { Header: 'Display Name', accessor: 'displayName' },
  {
    Header: 'Requested Date',
    accessor: 'createdOn',
    Cell: ({ row }: CellProps<IDataCrawlRequest>) => safeFormatDate(row.values['createdOn']),
  },
  {
    Header: 'Site Description',
    accessor: 'siteDescription',
    Cell: ({ row }: CellProps<IDataCrawlRequest>) =>
      row.original.siteDescription ? (
        <ClampLines
          className="clampped-content p-0 w-100"
          text={row.original.siteDescription}
          id={row.id + Math.random().toString()}
          lines={3}
          ellipsis="..."
          moreText="Show more"
          lessText="Show Less"
        />
      ) : null,
  },
  {
    Header: 'Request Description',
    accessor: 'requestDescription',
    Cell: ({ row }: CellProps<IDataCrawlRequest>) =>
      row.original.requestDescription ? (
        <ClampLines
          className="clampped-content p-0 w-100"
          text={row.original.requestDescription}
          id={row.id + Math.random().toString()}
          lines={3}
          ellipsis="..."
          moreText="Show more"
          lessText="Show Less"
        />
      ) : null,
  },
];

export const APPROVED_DECLINED_COLUMNS: Column<IDataCrawlRequest>[] = [
  { Header: 'S.No', Cell: ({ row }: CellProps<IDataCrawlRequest>) => row.index + 1 },
  { Header: 'Data Source', accessor: 'contentSource' },
  {
    Header: 'Site URL / Space Name',
    accessor: 'sourcePath',
    Cell: ({ row }: CellProps<IDataCrawlRequest>) =>
      row.original.sourcePath ? (
        <ClampLines
          className="clampped-content p-0 w-100"
          text={row.original.sourcePath}
          id={row.id + Math.random().toString()}
          lines={3}
          ellipsis="..."
          moreText="Show more"
          lessText="Show Less"
        />
      ) : null,
  },
  { Header: 'Display Name', accessor: 'displayName' },
  {
    Header: 'Requested Date',
    accessor: 'createdOn',
    Cell: ({ row }: CellProps<IDataCrawlRequest>) => safeFormatDate(row.values['createdOn']),
  },
  { Header: 'Reviewer Name', accessor: 'reviewerName' },
  {
    Header: 'Reviewed On',
    accessor: 'reviewedOn',
    Cell: ({ row }: CellProps<IDataCrawlRequest>) => safeFormatDate(row.values['reviewedOn']),
  },
  { Header: "Reviewer's Remarks", accessor: 'reviewerRemarks' },
];

export const APPROVED_COLUMNS: Column<IDataCrawlRequest>[] = [
  { Header: 'S.No', Cell: ({ row }: CellProps<IDataCrawlRequest>) =>  localStorage.getItem("Existing_sites_count")?(JSON.parse(localStorage.getItem("Existing_sites_count")||"")+row.index+1):(row.index+1)},
  { Header: 'Data Source', accessor: 'contentSource' },
  {
    Header: 'Site URL / Space Name',
    accessor: 'sourcePath',
    Cell: ({ row }: CellProps<IDataCrawlRequest>) =>
      row.original.sourcePath ? (
        <ClampLines
          className="clampped-content p-0 w-100"
          text={row.original.sourcePath}
          id={row.id + Math.random().toString()}
          lines={3}
          ellipsis="..."
          moreText="Show more"
          lessText="Show Less"
        />
      ) : null,
  },
  { Header: 'Display Name', accessor: 'displayName' },
  {
    Header: 'Requested Date',
    accessor: 'createdOn',
    Cell: ({ row }: CellProps<IDataCrawlRequest>) => safeFormatDate(row.values['createdOn']),
  },
  {
    Header: 'Site Description',
    accessor: 'siteDescription',
    Cell: ({ row }: CellProps<IDataCrawlRequest>) =>
      row.original.siteDescription ? (
        <ClampLines
          className="clampped-content p-0 w-100"
          text={row.original.siteDescription}
          id={row.id + Math.random().toString()}
          lines={3}
          ellipsis="..."
          moreText="Show more"
          lessText="Show Less"
        />
      ) : null,
  },
  {
    Header: 'Request Description',
    accessor: 'requestDescription',
    Cell: ({ row }: CellProps<IDataCrawlRequest>) =>
      row.original.requestDescription ? (
        <ClampLines
          className="clampped-content p-0 w-100"
          text={row.original.requestDescription}
          id={row.id + Math.random().toString()}
          lines={3}
          ellipsis="..."
          moreText="Show more"
          lessText="Show Less"
        />
      ) : null,
  },
];
