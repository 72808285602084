import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import './auto-logout-modal.scss';


export const AutoLogoutModal = ({ showModal, handleContinue, countDownTimer }) => {

  return (
    <Modal id="countdown-timer" show={showModal} onHide={handleContinue} backdrop="static">
      <Modal.Header>
        <div className="custom-modal-title">Your session is about to expire.</div>
      </Modal.Header>
      <div className="custom-modal-body">
        For security, your current session will be logged out in <br></br> <b>{countDownTimer} second(s) </b> due to inactivity.
      </div>

      <div className="custom-modal-footer">
        <Button className="btn-timer" variant="primary" onClick={handleContinue}>
          Continue Session
        </Button>
      </div>
    </Modal>
  )
}
