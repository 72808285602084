import { AutoLogoutModal, useLogoutApp } from '@amgen/auth';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { SESSION_TIMEOUT_COUNTER } from '../constants';

export const AutoLogout = props => {
  const [showModal, setShowModal] = useState(false);
  const [seconds, setSeconds] = useState(120);
  const [timeoutError, setTimeoutError] = useState(false);
  const [startTimer, setStartTimer] = useState(false);
  const [timerOn, setTimerOn] = useState(false);
  const { logout } = useLogoutApp();
  const history = useHistory();

  useEffect(() => {
    setStartTimer(true);
  }, []);

  useEffect(() => {
    if (showModal && !props.sessionTimeout) {
      const myInterval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }

        if (seconds === 0) {
          setTimeoutError(true);
          props.sessionTimeoutHanldler();
          setStartTimer(false);
          setShowModal(false);
        }
      }, 1000);
      return () => {
        clearInterval(myInterval);
      };
    }
  }, [seconds, showModal, props.sessionTimeout]);

  useEffect(() => {
    if (startTimer) {
      addEvents();
      if (!props.sessionTimeout && !showModal && !timerOn && !timeoutError) {
        setInterval(() => {
          const elem = document.getElementById('countdown-timer');
          if (elem === null) {
            let lastInteractionTime = '';
            lastInteractionTime = JSON.parse(localStorage.lastInteractionTime);
            const endDateTime = moment(new Date());
            const duration = moment.duration(endDateTime.diff(lastInteractionTime));
            const milliSeconds = duration.asMilliseconds();

            if (milliSeconds < SESSION_TIMEOUT_COUNTER.timer || milliSeconds > SESSION_TIMEOUT_COUNTER.resetTimer) {
              props.onActive();
            } else {
              props.onIdle();
              setTimerOn(true);
              setShowModal(true);
            }
          }
        }, 30000);
      }

      return () => {
        removeEvents();
      };
    }
  }, [startTimer, showModal, timerOn, props.sessionTimeout]);

  useEffect(() => {
    if (timeoutError) {
      logout();
      setShowModal(false);
    }
  }, [timeoutError]);

  const events = [
    'click',
    'load',
    'keydown',
    'scroll',
    'mousedown',
    'mouseup',
    'unload',
    'resize',
    'mousemove',
    'mouseout',
    'mouseover',
  ];

  const eventHandler = eventType => {
    localStorage.setItem('lastInteractionTime', JSON.stringify(moment()));
    props.onActive();
    setStartTimer(true);
  };

  const addEvents = () => {
    events.forEach(eventName => {
      window.addEventListener(eventName, eventHandler);
    });
  };

  const removeEvents = () => {
    events.forEach(eventName => {
      window.removeEventListener(eventName, eventHandler);
    });
    localStorage.removeItem('lastInteractionTime');
  };

  const resetTimer = () => {
    setTimeout(() => {
      setSeconds(120);
    }, 1000);
  };

  const handleContinueSession = () => {
    setShowModal(false);
    resetTimer();
    setStartTimer(true);
  };

  return (
    <div>
      {!timeoutError && (
        <AutoLogoutModal showModal={showModal} handleContinue={handleContinueSession} countDownTimer={seconds} />
      )}
    </div>
  );
};
