import { useSearchQueryValue } from '@amgen/shared';
import React, { useContext } from 'react';

import './search-box-query-term.scss';

import { SearchBoxContext } from '../../search-box-context-provider/search-box-context';

/* eslint-disable-next-line */
export interface SearchBoxQueryTermProps {}

export const SearchBoxQueryTerm: React.FC<SearchBoxQueryTermProps> = () => {
  // const { attributeDisplayNames } = useContext(AppContext);
  const { selectedFilter } = useContext(SearchBoxContext);
  const query = useSearchQueryValue();
  return selectedFilter && query && query !== '*:*' ? (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <span className="search-term-input"> {query} &nbsp; &nbsp; </span>
  ) : null;
};
export default SearchBoxQueryTerm;
