import { useSavePreferenceMutation } from '@amgen/api';
import { ApplicationName, SettingsPreferenceType, useEnvironment } from '@amgen/core';
import { AppContext, PreferenceContext, useToast } from '@amgen/shared';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import './setting-body.scss';

export interface SettingBodyProps {
  options: { [key: string]: string };
  heading: string;
  onOptionToggle(key: string): void;
  selectedValues?: string[];
  disabledOptions?: string[];
  handleDragging?: (dragging: boolean) => void;
}

export const SettingBody = (props: SettingBodyProps) => {
  const { appName } = useEnvironment();
  const { defaultFacetCategories } = useContext(AppContext);
  // This array maintains the filters which are by default disabled.
  const ByDefaultDisabledFilters = [...(defaultFacetCategories as string[])];
  const { selectedExportFacets, selectedFacetConfig, refetchPreferences } = useContext(PreferenceContext);
  const defaultFilters = [''];
  const selectedPreferredFilters = [...defaultFilters, ...selectedExportFacets];

  const [newlistData, setnewlistData] = useState({});
  const toast = useToast();
  const [defaultValues, setDefaultValues] = useState(selectedPreferredFilters);
  const [isValueChanged, setValueChanged] = useState(false);
  const [isClearValueChanged, setClearValueChanged] = useState(false);
  const [selectAllValueChanged, setSelectAllValueChanged] = useState(false);
  const [defaulSelectValue, setSelectDefault] = useState(false);
  const [isScrollBar, setisScrollBar] = useState(false);
  const [updateDefaultFilterConfig, { loading }] = useSavePreferenceMutation();
  const { goBack } = useHistory();
  // check box design
  const dragItem = useRef(0);
  const dragOverItem = useRef(0);
  const [countDefault, setCountDefault] = useState(true);
  const [getUrl, setgetUrl] = useState(localStorage.getItem('current_new_url'));

  useEffect(() => {
    if (isClearValueChanged) {
      setSelectDefault(false);
      setCountDefault(false);
      defaultValues.length = 0;
    } else if (selectAllValueChanged) {
      setSelectDefault(true);
      setCountDefault(false);
      setDefaultValues(defaultValues => [...Object.keys(props.options)]);
      setnewlistData(props.options);
    } else if (isValueChanged) {
      setCountDefault(false);
      setSelectDefault(false);
      const obj2 = {};
      defaultValues
        .filter(v => v !== '')
        .forEach(item => {
          obj2[item] = props.options[item];
        });
      setnewlistData(obj2);
      if (Object.keys(defaultValues).length === Object.keys(props.options).length && !isValueChanged) {
        setSelectDefault(true);
      } else {
        setSelectDefault(false);
      }
    } else {
      const obj1 = {};
      selectedExportFacets.forEach(item => {
        obj1[item] = props.options[item];
      });
      setnewlistData(obj1);

      if (Object.keys(selectedExportFacets).length === Object.keys(props.options).length && !isValueChanged) {
        setSelectDefault(true);
      } else {
        setSelectDefault(false);
      }
    }
  }, [props.options]);

  const dragEnter = (e, position) => {
    e.dataTransfer.dropEffect = 'copy';
    e.dataTransfer.effectAllowed = 'copy';
    dragOverItem.current = draggedObjectIndex(position);
  };

  const dragStart = (e, position) => {
    e.dataTransfer.dropEffect = 'copy';
    e.dataTransfer.effectAllowed = 'copy';
    dragItem.current = draggedObjectIndex(position);
  };

  const drop = e => {
    e.dataTransfer.dropEffect = 'copy';
    e.dataTransfer.effectAllowed = 'copy';
    const copyListItems = [...Object.entries(newlistData)];
    const dragItemContent = copyListItems[dragItem?.current];
    copyListItems.splice(dragItem?.current, 1);
    copyListItems.splice(dragOverItem?.current, 0, dragItemContent);
    dragItem.current = 0;
    dragOverItem.current = 0;
    const newObj = {};
    for (let i = 0; i < copyListItems.length; i++) {
      newObj[copyListItems[i][0]] = copyListItems[i][1];
    }
    setnewlistData(newObj);
  };

  function draggedObjectIndex(toBeMatch) {
    const data = Object.entries(newlistData);

    let positionChange = 0;
    for (let i = 0; i < data.length; i++) {
      if (Object.entries(newlistData)[i][0].includes(toBeMatch)) {
        positionChange = i;
      }
    }
    return positionChange;
  }

  const onSave = () => {
    updateDefaultFilterConfig({
      variables: { appName, values: Object.keys(newlistData), type: SettingsPreferenceType.EXPORT_CATEGORY },
    })
      .then(() => {
        toast.success({ message: 'Saved successfully!', id: 'default-filter-category-toast', timeout: 5000 });
        refetchPreferences();
        setValueChanged(false);
        setSelectAllValueChanged(false);
      })
      .catch(err =>
        toast.danger({
          message: err?.graphQLErrors?.[0]?.exception?.message ?? 'Unable to save preferences. Please try again later.',
          id: 'default-filter-category-toast',
          timeout: 5000,
        })
      );
  };

  const handleChange = event => {
    setCountDefault(false);
    setClearValueChanged(false);
    setSelectAllValueChanged(false);

    setValueChanged(true);

    if (defaultValues.includes(event)) {
      for (let i = 0; i < defaultValues.length; i++) {
        if (defaultValues[i] === event) {
          defaultValues.splice(i, 1);
          i--;
        }

        setDefaultValues(defaultValues);
      }
    } else {
      setDefaultValues(defaultValues => [...Object.keys(newlistData), event]);
    }
  };

  const clearAll = () => {
    setSelectDefault(false);
    setnewlistData({});
    defaultValues.length = 0;
    setSelectAllValueChanged(false);
    setClearValueChanged(true);
  };

  const onSelectAll = event => {
    if (defaulSelectValue) {
      setCountDefault(false);
      setSelectAllValueChanged(false);
      defaultValues.length = 0;
      setClearValueChanged(true);
      setnewlistData({});
      setSelectDefault(false);
    } else {
      setSelectDefault(true);
      setDefaultValues(defaultValues => [...Object.keys(props.options)]);
      setnewlistData(props.options);

      setClearValueChanged(false);

      setSelectAllValueChanged(true);
    }
  };

  if (props.heading === 'Export Filter(s)') {
    return (
      <>
        {/* */}

        {/* <p>No selected Filter for export</p> */}
        {/* <h2 className="mt-2 heading-style">Export Filter List</h2> */}

        <div className="col-md-7 mt-4 mx-4 page-style-remaining">
          <div className="card-header mt-3 mx-5">
            <div className="row select-margin mt-2 zoom-screen">
              {/* <FormCheck className="mx-3 mt-3 mb-3"> */}
              <Form.Check type="checkbox" onChange={onSelectAll} checked={defaulSelectValue} custom id="select-all" />
              <h3 onClick={onSelectAll} className="cursor-pointer mt-1">
                Select All
              </h3>
              {/* </FormCheck> */}
            </div>
            <h6 className="card-subtitle mt-2 mb-2 text-muted  select-margin ">
              Please select field(s) for export settings
            </h6>
          </div>
          <div className="row page-style-export thin-scrollbar mx-5 mt-2">
            {Object.entries(props.options).map(([key, label]) => (
              <Col md="6" key={key} className="mb-4 mt-2">
                <Form.Check
                  custom
                  type="checkbox"
                  id={`${props.heading}-${key}`}
                  label={label}
                  onChange={() => {
                    props.onOptionToggle(key);
                    handleChange(key);
                  }}
                  checked={
                    !isClearValueChanged
                      ? defaultValues.includes(key)
                      : !selectAllValueChanged
                      ? defaultValues.includes(key)
                      : props.selectedValues && props.selectedValues.includes('')
                  }
                />
              </Col>
            ))}
          </div>
        </div>

        <div className="col-md-4 mt-4 mx-2">
          <div className="card-header mt-3">
            <div className="row  d-flex mt-1">
              <h3 className="mx-3 mt-1">
                {!defaulSelectValue
                  ? isClearValueChanged && !countDefault && !defaulSelectValue
                    ? defaultValues.length
                    : (countDefault || defaulSelectValue) && !isClearValueChanged
                    ? Object.keys(selectedExportFacets).length
                    : !countDefault && !isClearValueChanged && !defaulSelectValue && isValueChanged
                    ? Object.keys(newlistData).length
                    : defaultValues.length
                  : isClearValueChanged && !countDefault && !defaulSelectValue
                  ? defaultValues.length
                  : (countDefault || defaulSelectValue) && !isClearValueChanged
                  ? Object.keys(props.options).length
                  : !countDefault && !isClearValueChanged && !defaulSelectValue && !isValueChanged
                  ? ' '
                  : ''}{' '}
                Export Field(s) Selected
                {/* <OverlayTrigger
                 placement="top"


                 overlay={
                 <Tooltip id="">
                   Order according to your preference by moving the fields up or down (Drag and drop)
               </Tooltip>}
                >
                 <FontAwesomeIcon  className="mx-2" icon={faInfoCircle} />
                </OverlayTrigger> */}
              </h3>

              <h3
                className={
                  Object.keys(newlistData).length === 0 ? 'heading-style-disable mt-1 ' : 'heading-style-clear mt-1'
                }
                onClick={clearAll}
              >
                {/* <FontAwesomeIcon icon={faCross} className="mx-2"/> */}
                Clear All
              </h3>
            </div>
            <h6 className="card-subtitle mt-2 mb-2 text-muted">
              Order according to your preference by moving the fields up or down (Drag and Drop)
            </h6>
          </div>
          <div className=" page-style-export thin-scrollbar">
            <div className="row mt-2 ">
              {Object.entries(newlistData).map(
                ([key, label]) =>
                  props.options[key] && (
                    <Col
                      md="12"
                      key={key}
                      className="mb-2  mt-2 draggable "
                      onDragStart={e => dragStart(e, key)}
                      onDragEnter={e => dragEnter(e, key)}
                      onDragEnd={drop}
                      onDragOver={e => e.preventDefault()}
                      draggable
                    >
                      <FontAwesomeIcon icon={faBars} className="mx-2" />
                      <Form.Label className="mx-3 draggable">{props.options[key]}</Form.Label>
                    </Col>
                  )
              )}
            </div>
          </div>
        </div>

        <Col xs="12" className="mt-4 d-flex  mb-4 justify-content-end button-margin ">
          <Button className="px-5" onClick={onSave}>
            {'Save'}
          </Button>
          {/* <a href={getUrl ? getUrl : '/home'}> */}
          <Button variant="outline-primary" className="px-md-5  ml-3" onClick={goBack}>
            {'Cancel'}
          </Button>
          {/* </a> */}
          <div></div>
        </Col>
      </>
    );
  } else {
    return (
      <div className="col-md-12 mt-4   page-style-remaining thin-scrollbar">
        <div className="row mx-3 pl-5 d-flex">
          {Object.entries(props.options).map(([key, label]) => (
            <Col xs="10" sm="4" key={key} className="mb-4 mt-2 justify-content-md-center ">
              <Form.Check
                custom
                type="checkbox"
                id={`${props.heading}-${key}`}
                label={label}
                onChange={() => props.onOptionToggle(key)}
                checked={
                  (props.selectedValues && props.selectedValues.includes(key)) || ByDefaultDisabledFilters.includes(key)
                }
                disabled={
                  (appName === ApplicationName.EEA && props.disabledOptions?.includes(label)) ||
                  key === ByDefaultDisabledFilters.filter(item => item === key)[0]
                }
              />
            </Col>
          ))}
        </div>
      </div>
    );
  }
};

export default SettingBody;
