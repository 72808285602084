import * as Types from '@amgen/core';

import { gql } from '@apollo/client';
export type IGenericResponseFragment = Pick<Types.IGenericResponse, 'error' | 'message' | 'status'>;

export const GenericResponseFragmentDoc = gql`
  fragment GenericResponse on GenericResponse {
    error
    message
    status
  }
`;
