import { useGetMyApplicationDetailsQuery } from '@amgen/api';
import { LoaderErrorContainer, VirtualContainer } from '@amgen/components';
import React, { useMemo } from 'react';

import './app-section.scss';

import GenericCard from '../generic-card/generic-card';

/* eslint-disable-next-line */
export interface AppSectionProps {
  currentPage: number;
}

export const AppSection = (props: AppSectionProps) => {
  const { data, loading, error } = useGetMyApplicationDetailsQuery({
    variables: { count: 10, page: 0 },
  });

  const items = useMemo(() => data?.getMyApplicationDetails?.items?.filter(x => x.active && x.active === 'true'), [
    data,
  ]);

  return (
    <LoaderErrorContainer errors={error ? [error] : []} loading={loading}>
      <div className="mt-5 px-sm-4 section mb-5">
        <h1
          className="section-heading p-3 mt-1 text-center font-weight-bold text-landing-page-blue"
          style={{ fontSize: 'xx-large' }}
        >
          My Apps
        </h1>
        <VirtualContainer loading={loading}>
          <VirtualContainer.PreviousIcon />

          <VirtualContainer.Body xs="12" md="6" lg="4">
            {items?.map(card => (
              <GenericCard
                key={card.id}
                poster={card.image?.[0] ?? ''}
                link={card.url}
                subtitle={card.vendor ?? ''}
                overlayColor={''}
                title={card.name}
                isThisCardLink
              />
            ))}
          </VirtualContainer.Body>
          <VirtualContainer.NextIcon />
        </VirtualContainer>
        {/* {items?.length ? <FourCardSection cardList={items} /> : null} */}
      </div>
    </LoaderErrorContainer>
  );
};

export default AppSection;
