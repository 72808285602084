import { Avatar, TimeAgoItem } from '@amgen/components';
import { IApplicationFeedback } from '@amgen/core';
import { faFrown, faMeh, faSmile } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ClampLines from 'react-clamp-lines';

import './application-feedback-item.scss';

/* eslint-disable-next-line */
export interface ApplicationFeedbackItemProps {}

export const ApplicationFeedbackItem = (props: IApplicationFeedback) => {
  return (
    <Row className="p-4 shadow-sm bg-grey mx-5 my-4">
      <Col
        xs={{ order: 12, span: 12 }}
        md={{ order: 1, span: 2 }}
        lg={{ order: 1, span: 1 }}
        className="text-center p-0"
      >
        <Avatar userName={props.user.username} className="text-white mx-auto" />
        <div className="pt-2 text-center">
          <h4 className="mb-0 ">
            {props.user.lastName}, {props.user.firstName}
          </h4>
          <small className="text-dark font-x-small d-block">({props.user.username})</small>
          <small className="text-grey font-x-small">{props.user.costCenter}</small>
        </div>
      </Col>
      <Col xs={{ order: 1, span: 12 }} md={{ order: 12, span: 10 }} lg={{ order: 12, span: 11 }}>
        <div className="d-flex mb-1 align-items-center">
          {props.feedback === 'Satisfied' ? (
            <FontAwesomeIcon title="Satisifed" icon={faSmile} className="fa-5x px-3  Satisfied" />
          ) : props.feedback === 'Neutral' ? (
            <FontAwesomeIcon title="Neutral" icon={faMeh} className="fa-5x px-3 Neutral" />
          ) : (
            <FontAwesomeIcon title="Disappointed" icon={faFrown} className="fa-5x px-3  Disappointed" />
          )}
          {/* <Rating
              initialRating={1}
              stop={1}
              fullSymbol={<FontAwesomeIcon icon={faStar} className="text-gold mx-1" size="lg" />}
              emptySymbol={<FontAwesomeIcon icon={faStar} className="text-gold mx-1" size="lg" />}
              readonly
            /> */}
          {/* </Badge> */}
          <h1 className="mb-0  text-overflow-ellipsis">{props.title}</h1>
        </div>

        <small style={{ fontSize: 'small' }} className="text-grey my-3" title={props.timestamp}>
          {props.timestamp ? <TimeAgoItem dateString={props.timestamp} /> : null}
        </small>
        <ClampLines
          className="clampped-content p-0 mt-2 w-100"
          text={props.comment?.toString().replace(/(\r\n|\n|\r)/gm, ' ') || ''}
          id={Math.random().toString()}
          lines={2}
        />
      </Col>
    </Row>
  );
};

export default ApplicationFeedbackItem;
