import { DEFAULT_SEARCH_QUERY } from '@amgen/core';
import { removeSpecialChars } from '@amgen/utils';
import { useLocation } from 'react-router-dom';

export function useSearchQueryValue(defaultQuery = DEFAULT_SEARCH_QUERY): string {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const q = query.get('q');
  return q || defaultQuery;
}

export function useEscapedCharSearchQueryValue(defaultQuery = DEFAULT_SEARCH_QUERY): string {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const q = query.get('q');
  return removeSpecialChars(q || defaultQuery);
}

export function useCleanSearchQuery(defaultQuery = DEFAULT_SEARCH_QUERY): string {
  const location = useLocation();
  const keyword = new URLSearchParams(location.search);
  const query = keyword.get('q');
  let modifiedQuery = query;
  const isKeyword = (query?.startsWith('"') && query.endsWith('"')) || (query?.startsWith("'") && query.endsWith("'"));
  modifiedQuery = modifiedQuery ? modifiedQuery.replaceAll('\\', '') : '';
  modifiedQuery = modifiedQuery?.replaceAll('"', '');
  modifiedQuery = modifiedQuery?.replaceAll("'", '');
  if (isKeyword) {
    return '"' + modifiedQuery + '"' || defaultQuery;
  } else {
    return modifiedQuery || defaultQuery;
  }
}
