import { TimeGap } from '@amgen/shared-kmi';
import React from 'react';
import { GetHandleProps, GetTrackProps, SliderItem } from 'react-compound-slider';

import './date-slider-components.scss';

import { reverseMonthsMap } from '../months-maps';

export interface IHandleProps {
  domain: number[];
  handle: SliderItem;
  getHandleProps: GetHandleProps;
  currentView: string;
}

export const Handle: React.FC<IHandleProps> = ({
  domain: [min, max],
  handle: { id, value, percent },
  getHandleProps,
  currentView,
}) => {
  return (
    <div
      style={{
        left: `${percent}%`,
        position: 'absolute',
        marginLeft: -10,
        marginTop: -5,
        zIndex: 2,
        width: 15,
        height: 15,
        border: 0,
        textAlign: 'center',
        cursor: 'pointer',
        borderRadius: '50%',
        backgroundColor: '#333333',
        color: '#333',
      }}
      {...getHandleProps(id)}
    >
      <div style={{ fontFamily: 'Roboto', fontSize: 11, marginTop: -20 }}>{`${
        currentView === TimeGap.YEAR ? value : reverseMonthsMap[value]
      }`}</div>
    </div>
  );
};

export interface ITrackProps {
  source: SliderItem;
  target: SliderItem;
  getTrackProps: GetTrackProps;
}

export const Track: React.FC<ITrackProps> = ({ source, target, getTrackProps }) => {
  return (
    <div
      style={{
        position: 'absolute',
        height: 6,
        zIndex: 1,
        backgroundColor: '#0f9d58',
        borderRadius: 5,
        cursor: 'pointer',
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`,
      }}
      {
        ...getTrackProps() /* this will set up events if you want it to be clickeable (optional) */
      }
    />
  );
};
