import { useGetDataSourceDetailsQuery, useSavePreferenceMutation } from '@amgen/api';
import {
  ABUSERDataSourcesCW,
  ABUSERDataSourcesFTE,
  DataSource,
  IApplicationInfo,
  SettingsPreferenceType,
  useEnvironment,
} from '@amgen/core';
import { AddContentButton } from '@amgen/feature-governance-dashboard';
import { PreferenceContext, useWindowSizeListener } from '@amgen/shared';
import { toggle } from '@amgen/utils';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import './data-source-section-body.scss';

import CustomGroupButton from '../../custom-group-button/custom-group-button';
import CustomizedDataSourceCard from '../../customized-data-source-card/customized-data-source-card';
import DataSourceCard from '../../data-source-card/data-source-card';
import { convertCustomGroups, convertDataSourceToGroups, useFilteredDataSources } from '../hooks';
import { LANDING_PAGE_DATA_SOURCE_CATEGORY } from '../models';
import { CUSTOM_SOURCES } from './custom-sources-config';

export interface DataSourceSectionBodyProps {
  selectedCategory: LANDING_PAGE_DATA_SOURCE_CATEGORY;
  setBlockScroll: (val: boolean) => void;
}

export const DataSourceSectionBody: React.FC<DataSourceSectionBodyProps> = ({ selectedCategory, setBlockScroll }) => {
  const { selectedDefaultDataSources, refetchDataSources } = useContext(PreferenceContext);
  const favoriteDataSourcesValue = selectedDefaultDataSources.filter(item => item?.group === null)?.[0]?.values || [];
  const [favoriteDataSources, setFavoriteDataSources] = useState(favoriteDataSourcesValue);
  const [selectedDataSources, setSelectedDataSources] = useState(selectedDefaultDataSources);
  const { appName } = useEnvironment();
  const { data } = useGetDataSourceDetailsQuery({
    variables: { appName, page: 0, count: 50, userType: sessionStorage.getItem('employeeType') || '' },
  });
  const [isCustomCardHover, setIsCustomCardHover] = useState(false);
  const [updateCustomDataSources] = useSavePreferenceMutation();
  const items = data?.getDataSourceDetails?.items?.slice().sort((a, b) => a.id!.localeCompare(b.id!));
  const abTestingGroup = JSON.parse(sessionStorage.getItem('abTestingGroup') as string);
  const userEmployeeType = sessionStorage.getItem('employeeType');
  const abUserSources =
    abTestingGroup && appName !== 'OpsKM' && appName !== 'Catalyst'
      ? userEmployeeType === 'FTE'
        ? items?.filter(item => ABUSERDataSourcesFTE.includes(item?.id as string))
        : items?.filter(item => ABUSERDataSourcesCW.includes(item?.id as string))
      : items;
  const filteredDataSources = useFilteredDataSources(abUserSources);
  const groupDataSourceMap = convertDataSourceToGroups(abUserSources);
  const customGroupDataSourceMap = convertCustomGroups(selectedDataSources, abUserSources);
  const isMobile = useWindowSizeListener();

  useEffect(() => {
    setSelectedDataSources(selectedDefaultDataSources);
  }, [selectedDefaultDataSources]);

  return selectedCategory === LANDING_PAGE_DATA_SOURCE_CATEGORY.APPS ? (
    <Row
      className="data-source-section-body  w-100 thin-scrollbar-gray"
      noGutters
      onMouseEnter={() => setBlockScroll(true)}
      onMouseLeave={() => setBlockScroll(false)}
    >
      <Col xs={3} lg={2} className={isMobile ? 'pr-3' : 'p-0'}>
        <CustomGroupButton toggleBlockScroll={v => setBlockScroll} />
      </Col>
      <Col xs={9} lg={10} className="pl-lg-3 icon-width">
        <Row>
          {Array.from(customGroupDataSourceMap, ([group, groupedDataSources]) => {
            const dataSources = groupedDataSources.dataSources ?? [];
            const imageUrls = groupedDataSources.imageUrls ?? [];
            const groupName = groupedDataSources.groupName;

            return (
              <Col key={group} xs={4} lg={3} className="p-0">
                <CustomizedDataSourceCard
                  customDataSources={dataSources}
                  customDataSourceImages={imageUrls}
                  onMouseEnter={() => setIsCustomCardHover(true)}
                  onMouseLeave={() => setIsCustomCardHover(false)}
                  cardImageClass="app-card-img-apps  app-card-grey-border"
                  dataSource={group}
                  groupName={groupName}
                  refetch={refetchDataSources}
                  selectedCategory={selectedCategory}
                />
              </Col>
            );
          })}
          {Array.from(groupDataSourceMap, ([group, groupedDataSources]) => {
            // TODO: revisit logic here. Potentially, if ds.id is present and ds.image is not
            // (or vice versa), then the arrays can become misaligned
            const dataSources = Array.from(groupedDataSources).map(ds => ds.id ?? '');

            const imageUrls = Array.from(groupedDataSources).map(ds => ds.image?.[0] ?? '');
            return (
              <Col key={group} xs={4} lg={3} className="p-0">
                <CustomizedDataSourceCard
                  customDataSources={Array.from(dataSources)}
                  customDataSourceImages={imageUrls}
                  onMouseEnter={() => setIsCustomCardHover(true)}
                  onMouseLeave={() => setIsCustomCardHover(false)}
                  cardImageClass="app-card-img-apps  app-card-green-border"
                  dataSource={group}
                  isPredefinedGroup={true}
                  selectedCategory={selectedCategory}
                />
              </Col>
            );
          })}

          {CUSTOM_SOURCES.map((customSource, index) => {
            return customSource.customGroupName && sessionStorage.getItem('employeeType') === 'FTE' ? (
              <Col key={index} xs={4} lg={3} className="p-0">
                <CustomizedDataSourceCard
                  customDataSources={customSource.customDataSources}
                  customDataSourceImages={customSource.customDataSourceImages}
                  onMouseEnter={() => setIsCustomCardHover(true)}
                  onMouseLeave={() => setIsCustomCardHover(false)}
                  cardImageClass="app-card-img-apps  app-card-grey-border"
                  dataSource={customSource.dataSource}
                  groupName={customSource.customGroupName}
                  refetch={refetchDataSources}
                  selectedCategory={selectedCategory}
                />
              </Col>
            ) : (
              <></>
            );
          })}
        </Row>
      </Col>
    </Row>
  ) : (
    <Row
      className={
        isMobile
          ? 'data-source-section-body w-100 thin-scrollbar pr-3'
          : 'data-source-section-body w-100 thin-scrollbar'
      }
      noGutters
      onMouseEnter={() => setBlockScroll(true)}
      onMouseLeave={() => setBlockScroll(false)}
    >
      {abUserSources?.length ? (
        <>
          {selectedCategory !== LANDING_PAGE_DATA_SOURCE_CATEGORY.EXTERNAL_SOURCES && (
            <Col xs={3} lg={2} className={isMobile ? 'pr-3' : 'p-0'}>
              <AddContentButton toggleBlockScroll={v => setBlockScroll} />
            </Col>
          )}
          {selectedCategory === LANDING_PAGE_DATA_SOURCE_CATEGORY.EXTERNAL_SOURCES && (
            <Col xs={3} lg={2} className={isMobile ? 'pr-3' : 'p-0'}></Col>
          )}

          <Col xs={9} lg={10} className="pl-lg-3">
            <Row>
              {abUserSources
                ?.filter(item => filteredDataSources.get(selectedCategory)?.includes(item.id as DataSource))
                .map((dataSource: IApplicationInfo) => (
                  <Col key={dataSource.id} xs={4} lg={3} className="p-0">
                    <DataSourceCard
                      dataSource={dataSource}
                      selectedCategory={selectedCategory}
                      isCustomDataSource={favoriteDataSources?.includes(dataSource.id as DataSource)}
                      onCustomDataSourceToggle={value => {
                        const values = toggle(favoriteDataSources, value, o => o === value);

                        setFavoriteDataSources(values);
                        updateCustomDataSources({
                          variables: { appName, values, type: SettingsPreferenceType.DATA_SOURCE },
                        }).then(refetchDataSources);
                      }}
                      isCustomCardHover={isCustomCardHover}
                    />
                  </Col>
                ))}
            </Row>
          </Col>
        </>
      ) : null}
    </Row>
  );
};

export default DataSourceSectionBody;
