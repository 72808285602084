import React from 'react';
import { ListGroup } from 'react-bootstrap';

import './inquiry-list.scss';

export interface IInquiry {
  question: string;
  answer: string;
}

export interface InquiryListProps {
  inquiries: IInquiry[] | undefined;
}

export const InquiryList: React.FC<InquiryListProps> = props => {
  return (
    <ListGroup as="ol" variant="flush">
      {props.inquiries?.map(inquiry => (
        <ListGroup.Item as="li">
          <h2>{inquiry.question}</h2>
          <p>{inquiry.answer}</p>
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};

export default InquiryList;
