import React, { useContext } from 'react';
import { Modal } from 'react-bootstrap';

import './popup.scss';

import { ItemContext } from '../item-context';
import { Body, BodyProps } from './body/body';
import { Footer, FooterProps } from './footer/footer';
import { Heading, HeadingProps } from './heading/heading';

/* eslint-disable-next-line */
export interface PopupProps {}

export interface PopupComposition {
  Heading: React.FC<HeadingProps>;
  Body: React.FC<BodyProps>;
  Footer: React.FC<FooterProps>;
}

export const Popup: React.FC<PopupProps> & PopupComposition = ({ children }) => {
  const { isModalOpen, setIsModalOpen } = useContext(ItemContext);
  return (
    <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)} centered scrollable size="lg">
      {children}
    </Modal>
  );
};

Popup.Heading = Heading;
Popup.Body = Body;
Popup.Footer = Footer;

export default Popup;
