import { IAssetAttribute } from '@amgen/core';
import { FiltersObject } from '@amgen/shared-kmi';
import { createContext } from 'react';

export interface IFacetsPanelState {
  selectedFilterCategories: Set<IAssetAttribute>;
  setSelectedFilterCategories: (fields: Set<IAssetAttribute>) => void;
  selectedFilters: FiltersObject;
  setSelectedFilters: (filters: FiltersObject) => void;
  isNotConfigurable?: boolean;
  currentSelectedKeys: Set<IAssetAttribute>;
  setCurrentSelectedKeys: (fields: Set<IAssetAttribute>) => void;
}

export const FacetsPanelContext = createContext<IFacetsPanelState>(null!);
