import { DataSource, IApplicationInfo } from '@amgen/core';
import { AppContext, FacetContext, MatomoContext, useWindowSizeListener } from '@amgen/shared';
import classnames from 'classnames';
import DOMPurify from 'dompurify';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import './data-source-card.scss';

import CustomDataSourceToggleButton from '../custom-data-source-toggle-button/custom-data-source-toggle-button';
import { LANDING_PAGE_DATA_SOURCE_CATEGORY } from '../data-source-section/models';

export interface DataSourceCardProps {
  dataSource: IApplicationInfo;
  isCustomDataSource: boolean;
  onCustomDataSourceToggle: (dataSource: DataSource) => void;
  isCustomCardHover: boolean;
  docCount?: number;
  selectedCategory: LANDING_PAGE_DATA_SOURCE_CATEGORY;
}

// when true, move to front, and add to username's custom daa sources
export const DataSourceCard: React.FC<DataSourceCardProps> = ({
  dataSource,
  isCustomDataSource,
  onCustomDataSourceToggle,
  isCustomCardHover,
  docCount,
  selectedCategory,
}) => {
  const [hover, setHover] = useState(false);
  const history = useHistory();
  const { trackApplicationFeature } = useContext(MatomoContext);
  const { setCustomFilterSearch } = useContext(FacetContext);
  const { setCustomSourceActive, setLandingParent, setCurrentSource } = useContext(AppContext);
  const count = docCount
    ? new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 1,
        notation: 'compact',
        compactDisplay: 'short',
      }).format(docCount)
    : 0;

  const getSearchValue = () => {
    const searchGroupHeaderElement = document.getElementsByClassName('header-search')[0];
    const searchGroupMainElement = document.getElementsByClassName('input-group')[0];
    let searchInputValue;
    if (searchGroupHeaderElement) {
      searchInputValue = searchGroupHeaderElement.getElementsByTagName('input')[0];
      return searchInputValue.value;
    }
    if (searchGroupMainElement) {
      searchInputValue = searchGroupMainElement.getElementsByTagName('input')[0];
      return searchInputValue.value;
    }
  };
  const isMobile = useWindowSizeListener();
  return (
    <div
      className={classnames('app-card text-primary py-0 position-relative')}
      title={dataSource.description ? dataSource.description : dataSource.name}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {hover || isCustomCardHover ? (
        <CustomDataSourceToggleButton
          isCustomDataSource={isCustomDataSource}
          onCustomDataSourceToggle={() => onCustomDataSourceToggle(dataSource.id as DataSource)}
        />
      ) : (
        <div className="mt-4" />
      )}

      <div className="d-flex justify-content-center">
        <img
          className={classnames(
            { 'custom-data-source': isCustomDataSource },
            'app-card-img d-flex justify-content-center p-3 shadow-sm cursor-pointer'
          )}
          src={DOMPurify.sanitize(dataSource.image?.[0])}
          alt={dataSource.name}
          onClick={() => {
            trackApplicationFeature(selectedCategory, dataSource.name || '');

            const queryParam = getSearchValue();
            history.push({
              pathname: '/',
              search: '?q=' + queryParam,
              state: { value: dataSource.id },
            });
            setCustomFilterSearch?.(true);
            setCustomSourceActive?.(false);
            setLandingParent(dataSource.category ? dataSource.category[0] : 'All Sources');
            setCurrentSource(
              dataSource.category
                ? dataSource.category[0] === 'Internal Sources'
                  ? 'internal'
                  : dataSource.category[0] === 'External Sources'
                  ? 'external'
                  : 'all'
                : 'all'
            );
          }}
        />
      </div>

      <h2 className={isMobile ? 'pt-2 title w-100  mb-0' : 'pt-2 title w-100 f-90 mb-0'}>{dataSource.name}</h2>
      {/* commenting out count under data source */}
      {/* <Badge className="small text-white" title={count + ' documents'} variant="custom-info">
        {count}
      </Badge> */}
    </div>
  );
};

export default DataSourceCard;
