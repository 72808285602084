import { AppContext, FacetContext, SearchContext, useWindowSizeListener } from '@amgen/shared';
import classNames from 'classnames';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Nav, Row } from 'react-bootstrap';

import './top-search-filters.scss';

import CustomSourcesFilters from './custom-sources-filters';
import TopChildFilters from './top-child-filters';

/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable-next-line */
export interface TopSearchFiltersProps {
  classnames?: string;
}
enum ApplicationName {
  KMI = 'KMI',
  EEA = 'EEA',
  OpsKM = 'OpsKM',
  Catalyst = 'Catalyst',
  DVIEW = 'DVIEW',
}

export function TopSearchFilters({ classnames }: TopSearchFiltersProps) {
  const appName: ApplicationName = sessionStorage.getItem('appName')
    ? ApplicationName[sessionStorage.getItem('appName')!]
    : ApplicationName.EEA;

  const [selectedParentFilter, setSelectedParentFilter] = useState('Internal');
  const { expandPanel, setSelectedTopFilter, setAllSelected } = useContext(SearchContext);
  const { appliedFilters, isCustomFilterSearch, setCustomFilterSearch } = useContext(FacetContext);
  const {
    isCustomSourceActive,
    setCustomSourceActive,
    setSelectedCustomSourceName,
    landingParent,
    setCurrentSource,
    currentSource,
  } = useContext(AppContext);
  const parentSources = appName === 'EEA' ? ['Internal', 'External', 'All'] : ['Internal'];

  function intlFormat(num) {
    return new Intl.NumberFormat().format(Math.floor(num * 10) / 10);
  }
  function makeFriendly(num) {
    if (num && num >= 1000000) return intlFormat(num / 1000000) + 'M';
    if (num && num >= 1000) return intlFormat(num / 1000) + 'K';
    if (num) {
      return intlFormat(num);
    }
  }
  // const internalCount = makeFriendly(internalCountData?.getQueryResults?.resultCount);
  // const externalCount = makeFriendly(externalCountData?.getQueryResults?.resultCount);
  // const allCount = makeFriendly(allCountData?.getQueryResults?.resultCount);

  const setParent = (parent, flag) => {
    setSelectedParentFilter(parent);
    setCurrentSource(parent.toLowerCase());
    setCustomSourceActive(flag);
    appliedFilters.removeAll('doc_nav_prefix');
    if (parent === 'Internal') {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
    setCustomFilterSearch?.(false);
  };

  useEffect(() => {
    if (isCustomFilterSearch) {
      if (landingParent === 'Internal Sources') {
        setSelectedParentFilter('internal');
        setCurrentSource('internal');
      } else if (landingParent === 'External Sources') {
        setSelectedParentFilter('external');
        setCurrentSource('external');
      } else {
        setSelectedParentFilter('All');
        setCurrentSource('all');
      }
    }
  }, [isCustomFilterSearch]);

  useEffect(() => {
    if (currentSource === 'internal') {
      setSelectedParentFilter('Internal');
    } else if (currentSource === 'external') {
      setSelectedParentFilter('External');
    } else if (currentSource === 'all') {
      setSelectedParentFilter('All');
    }
  }, [currentSource]);

  const isMobile = useWindowSizeListener();
  return (
    <div
      className={classNames(
        classnames,
        !expandPanel && !isMobile ? 'expanded-filter spotlight-container expanded-filter' : 'spotlight-container'
      )}
    >
      {appName === 'EEA' && (
        <Row id="parent-filter" className="custom-tab no-gutters row w-100">
          <Nav className="align-items-center justify-content-end">
            {parentSources.map((parent, index) => {
              return (
                <Nav.Item
                  className={selectedParentFilter === parent && !isCustomSourceActive ? '' : 'custom-border'}
                  key={index}
                >
                  <div
                    onClick={() => {
                      setParent(parent, false);
                    }}
                  >
                    <Nav.Link
                      className={classNames(
                        'mb-0 align-items-center d-flex justify-content-center',
                        selectedParentFilter === parent && !isCustomSourceActive ? 'active active-border' : '',
                        index === 0 && selectedParentFilter !== parent ? 'pl-0' : ''
                      )}
                      style={{ fontSize: '10px' }}
                    >
                      <div
                        className={
                          selectedParentFilter === parent && !isCustomSourceActive ? 'active-parent' : 'parent'
                        }
                      >
                        {' '}
                        {parent}
                        {/* {parent === 'Internal' && internalCount
                          ? `(${internalCount})`
                          : parent === 'External' && externalCount
                          ? `(${externalCount})`
                          : parent === 'All' && allCount
                          ? `(${allCount})`
                          : ''} */}
                      </div>
                    </Nav.Link>
                  </div>
                </Nav.Item>
              );
            })}
            {appName === 'EEA' && (
              <Nav.Item
                className={selectedParentFilter === 'Custom Sources' || isCustomSourceActive ? '' : 'custom-border'}
              >
                <div
                  onClick={() => {
                    setSelectedTopFilter('');
                    setParent('Custom Source', true);
                    setSelectedCustomSourceName('All Sources');
                  }}
                >
                  <Nav.Link
                    className={classNames(
                      'mb-0 align-items-center d-flex justify-content-center',
                      selectedParentFilter === 'Custom Sources' || isCustomSourceActive ? 'active active-border' : ''
                    )}
                  >
                    <div
                      className={
                        selectedParentFilter === 'Custom Sources' || isCustomSourceActive ? 'active-parent' : 'parent'
                      }
                      style={{ fontSize: '10px' }}
                    >
                      {' '}
                      Custom Sources
                    </div>
                  </Nav.Link>
                </div>
              </Nav.Item>
            )}
          </Nav>
        </Row>
      )}

      {selectedParentFilter === 'Custom Sources' || isCustomSourceActive ? (
        <CustomSourcesFilters parent={'mobile'} selectedParentFilter={selectedParentFilter}></CustomSourcesFilters>
      ) : (
        <TopChildFilters parent={'mobile'} selectedParentFilter={selectedParentFilter}></TopChildFilters>
      )}
    </div>
  );
}

export default TopSearchFilters;
