import { useEnvironment } from '@amgen/core';
import { AssetDetail } from '@amgen/feature-asset-details';
import { BookmarkListContainer } from '@amgen/feature-bookmarks';
import { ClinicalDetails } from '@amgen/feature-clinical-details';
import { useAppliedFiltersWithPersistentValues } from '@amgen/feature-facets';
import { GovernanceDashboardPage } from '@amgen/feature-governance-dashboard';
import { AppContext, SearchContextProvider, useToast, useWindowSizeListener } from '@amgen/shared';
import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import './pages.scss';

import EEAHeader from '../components/eea-header/eea-header';
import EEALandingHeader from '../components/eea-landing-header/eea-landing-header';
import EEANavbar from '../components/eea-navbar/eea-navbar';
import EEANotificationBanner from '../components/eea-notification-banner/eea-notification-banner';
import ApplicationFeedbackPage from './application-feedback-page/application-feedback-page';
import DashboardPage from './dashboard-page/dashboard-page';
import LandingPage from './landing-page/landing-page';
import SearchPage from './search-page/search-page';
import SettingsPage from './settings-page/settings-page';
import TopicsPage from './topics-page/topics-page';

/* eslint-disable-next-line */
export interface PagesProps {}

const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const updatePosition = () => {
      setScrollPosition(window.pageYOffset);
    };
    window.addEventListener('scroll', updatePosition);
    updatePosition();
    return () => window.removeEventListener('scroll', updatePosition);
  }, []);

  return scrollPosition;
};

export const Pages = (props: PagesProps) => {
  const { path, url } = useRouteMatch();
  const { clusterSearch } = useEnvironment();
  const islandingPage = !!useRouteMatch({ path: '/home', exact: true, strict: false });
  const isSettingPage = !!useRouteMatch({ path: '/settings', exact: true, strict: false });
  const filters = useAppliedFiltersWithPersistentValues();
  let authTabs = [''];
  if (sessionStorage.authTabs) {
    authTabs = JSON.parse(sessionStorage.authTabs || ['']);
  }
  const query = sessionStorage.getItem('currentSearchTerm');
  const cleanSearchQuery = () => {
    let modifiedQuery = query ?? '*:*';
    const isKeyword =
      (query?.startsWith('"') && query.endsWith('"')) || (query?.startsWith("'") && query.endsWith("'"));
    if (modifiedQuery.includes('AND') || modifiedQuery.includes('OR')) {
      return modifiedQuery;
    } else {
      modifiedQuery = modifiedQuery ? modifiedQuery.replaceAll('\\', '') : '';
      modifiedQuery = modifiedQuery?.replaceAll('"', '');
      modifiedQuery = modifiedQuery?.replaceAll("'", '');
      if (isKeyword) {
        return '"' + modifiedQuery + '"';
      } else {
        return modifiedQuery;
      }
    }
  };

  const cleanQuery = cleanSearchQuery();
  const authorizedTabs = authTabs?.includes('IGD Admin');
  const { selectedResultTab } = useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(0);
  const location = useLocation();
  const newState = location.state as any;
  const pathname = location.pathname;
  const toast = useToast();
  const scrollPosition = useScrollPosition();
  //const { loading } = useContext(SearchContext);
  const [myAmgenMessageClass, setClass] = useState<string>('message-text-display');
  const [showNotification, setShowNotification] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setClass('message-text-hide');
    }, 15000);
  }, []);
  const isMobile = useWindowSizeListener();
  return (
    <>
      {islandingPage ? <EEALandingHeader setCurrentPage={setCurrentPage} currentPage={currentPage} /> : <EEAHeader />}
      {pathname === '/' && showNotification && <EEANotificationBanner setShowNotification={setShowNotification} />}
      {pathname === '/' && (
        <div
          className={
            !isMobile ? (scrollPosition > 0 ? 'stickyNavbarWithShadowEffect' : 'stickyNavbarWithoutShadowEffect') : ''
          }
        >
          <EEANavbar />
        </div>
      )}
      <div className={classNames('eea', { 'p-0': islandingPage || isSettingPage })}>
        <Row
          noGutters
          style={{ minHeight: `calc(100vh - ${islandingPage ? '35px' : '1px'})` }} // 127 is header + footer height calculated for
          className={classNames({ 'landing-page bg-white justify-content-md-center': islandingPage })}
        >
          <SearchContextProvider query={cleanQuery} filters={filters} trackMatomoPage="eea-home" isExpandable>
            <Switch>
              <Route path={`${path}home`}>
                <LandingPage currentPage={currentPage} setCurrentPage={setCurrentPage} />
              </Route>
              <Route path={`${path}settings`} component={SettingsPage} />
              {authorizedTabs ? <Route exact path={`${path}feedbacks`} component={ApplicationFeedbackPage} /> : null}

              <Route path={`${path}document/:assetId`}>
                <Col xs="12" className=" my-3">
                  <AssetDetail />
                </Col>
              </Route>
              <Route path={`${path}clinical/:clinicalType/:clinicalId`}>
                <Col xs="12" className="bg-white shadow-sm">
                  <ClinicalDetails />
                </Col>
              </Route>
              <Route path={`${path}data-requests`} component={GovernanceDashboardPage} />
              <Route path={path}>
                <Col xs="12" className={classNames('', { 'container details-container': newState?.hideNavbar })}>
                  <Col
                    xs="12"
                    style={
                      isMobile
                        ? { top: '10px' }
                        : selectedResultTab === 'Chat'
                        ? { height: 'auto' }
                        : { height: 'auto', minHeight: '50rem' }
                    }
                    className="bg-white shadow-sm border border-top-0 mb-3 pt-2 pb-3 px-0"
                  >
                    <Switch>
                      {/* <Route exact path={`${path}reports`} component={ReportsPage} /> */}
                      <Route exact path={`${path}dashboard`} component={DashboardPage} />
                      {/* TODO: It was requested that cluser search page to be toggled between environments
                    This condition should be removed when cluster search page is finalized */}
                      {clusterSearch && <Route exact path={`${path}topics`} component={TopicsPage} />}
                      <Route exact path={`${path}bookmarks`} component={BookmarkListContainer} />
                      <Route path={`${path}`} component={SearchPage} />
                      <Redirect to={`${url}home`} />
                    </Switch>
                  </Col>
                </Col>
              </Route>
            </Switch>
          </SearchContextProvider>
        </Row>
        {/* <SearchContextProvider query={query}>
          {pathname === '/' ? <ApplicationFeedback /> : <></>}
        </SearchContextProvider> */}
      </div>
    </>
  );
};

export default Pages;
