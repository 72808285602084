import { useBookmarkAssetMutation } from '@amgen/api';
import { ApplicationName, IBookmark } from '@amgen/core';
import { useSearchQueryValue } from '@amgen/shared';
import { faBookmark } from '@fortawesome/free-regular-svg-icons';
import React from 'react';

import './bookmark-add-button.scss';

import { BookmarkButton } from '../../components';

export interface BookmarkAddButtonProps {
  onSuccess: () => void;
  bookmark: IBookmark;
  isDisabled?: boolean;
  isCustomBookmark?: boolean;
  isAssetDetail?: boolean;
  buttonText?: string;
}

export const BookmarkAddButton = (props: BookmarkAddButtonProps) => {
  const query = useSearchQueryValue();
  //const { appName } = useEnvironment();
  const applicationName: ApplicationName = sessionStorage.getItem('appName')
    ? ApplicationName[sessionStorage.getItem('appName')!]
    : ApplicationName.EEA;
  const [addBookmark, { loading }] = useBookmarkAssetMutation({
    onCompleted: props.onSuccess,
  });

  return (
    <BookmarkButton
      icon={faBookmark}
      isLoading={loading}
      onClick={() =>
        addBookmark({
          variables: {
            assetId: props.bookmark.assetId,
            name: props.bookmark.name || props.bookmark.assetId,
            searchTerm: query,
            appName: applicationName,
          },
        })
      }
      tooltipText="Add to Bookmarks"
      isDisabled={props.isDisabled}
      isCustomBookmark={props.isCustomBookmark ?? false}
      isBookMarked={false}
      isAssetDetail={props.isAssetDetail}
      buttonText={props.buttonText}
    />
  );
};

export default BookmarkAddButton;
