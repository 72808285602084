import { AppContext, ResultViewType, SearchContext } from '@amgen/shared';
import React, { useContext } from 'react';
import { Col } from 'react-bootstrap';

import './search-page-right-flank.scss';

/* eslint-disable-next-line */
export interface SearchPageRightFlankProps {
  hideInTableView?: boolean;
}

export const SearchPageRightFlank: React.FC<SearchPageRightFlankProps> = props => {
  const { selectedResultTab } = useContext(AppContext);
  const { selectedViewType } = useContext(SearchContext);
  return (
    <Col xs="12" lg={selectedResultTab === 'Chat' ? 9 : 3} className="right-panel">
      {props.children}
    </Col>
  );
};

export default SearchPageRightFlank;
