import { ApplicationName, IAssetAttribute, useEnvironment } from '@amgen/core';
import {
  AppContext,
  FACET_FEATURE_ACTIONS,
  FacetContext,
  FacetsPanelContext,
  MatomoContext,
  SEARCH_APP_FEATURE_LIST,
  SEARCH_HIERARCHICAL_FACETS,
} from '@amgen/shared-kmi';
import { splitRetain } from '@amgen/utils';
import { format } from 'date-fns';
import { useCallback, useContext } from 'react';

export const useFilterOperations = () => {
  const { attributeDisplayNames, setPageNo } = useContext(AppContext);
  const { setAppliedFilters } = useContext(FacetContext);
  const { selectedFilters, setSelectedFilters } = useContext(FacetsPanelContext);
  const { dimensions, trackCustomDimensions } = useContext(MatomoContext);
  const { appName } = useEnvironment();

  const trackForMatamo = useCallback(
    (category: IAssetAttribute, value: string) => {
      if (category === 'content_source') {
        trackCustomDimensions(value, dimensions.DATA_SOURCE);
      }
      if (category === 'doc_nav_prefix' && appName === ApplicationName.EEA) {
        const [, dataSource, ..._] = value.split('/');
        trackCustomDimensions(dataSource, dimensions.DATA_SOURCE);
      }
      trackCustomDimensions(attributeDisplayNames[category], dimensions.FILTER_NAME);
    },
    [appName, attributeDisplayNames, dimensions.DATA_SOURCE, dimensions.FILTER_NAME, trackCustomDimensions]
  );

  const handleFilterToggle = useCallback(
    (category: IAssetAttribute, value: string) => {
      // TODO: These are special cases. These must be removed and incorporated into the each type of facet.
      if (category === 'prefix_location') {
        const [type, country, state, city] = splitRetain(value, '/', 4);

        switch (type) {
          case '0':
            setSelectedFilters(selectedFilters.toggle(country, 'country'));
            break;
          case '1':
            setSelectedFilters(selectedFilters.toggle(state, 'state'));
            break;
          case '2':
            setSelectedFilters(selectedFilters.toggle(city, 'city'));
            break;
        }
      }

      if (category === 'prefix_class') {
        const [type, classification, subclassification] = splitRetain(value, '/', 3);

        switch (type) {
          case '0':
            setSelectedFilters(selectedFilters.toggle(classification, 'classification'));
            break;
          case '1':
            setSelectedFilters(selectedFilters.toggle(subclassification, 'subclassification'));
            break;
        }
      }

      setSelectedFilters(selectedFilters.toggle(value, category));
      setPageNo(1);
      setAppliedFilters(selectedFilters);
      trackForMatamo(category, value);
    },
    [selectedFilters, setAppliedFilters, setPageNo, setSelectedFilters, trackForMatamo]
  );

  // this func is for handling hierarchal filters (when filter is toggled, we add parents rather than toggling)
  const handleFilterAddition = useCallback(
    (category: IAssetAttribute, value: string) => {
      setSelectedFilters(selectedFilters.add(value, category));
      setPageNo(1);
      setAppliedFilters(selectedFilters);
      trackForMatamo(category, value);
    },
    [selectedFilters, setAppliedFilters, setPageNo, setSelectedFilters, trackForMatamo]
  );

  return { handleFilterToggle, handleFilterAddition };
};

export const useFilterCategoryOperations = () => {
  const { appliedFilters, setAppliedFilterCategories, setAppliedFilters } = useContext(FacetContext);
  const { trackApplicationFeature } = useContext(MatomoContext);
  const {
    selectedFilterCategories,
    selectedFilters,
    setSelectedFilters,
    currentSelectedKeys,
    setCurrentSelectedKeys,
  } = useContext(FacetsPanelContext);

  const toggleCurrentSelectedKeys = (category: IAssetAttribute) => {
    const copy = new Set(currentSelectedKeys);
    if (!copy.has(category)) {
      copy.add(category);
    }
    setCurrentSelectedKeys(copy);
  };

  const toggleFilterCategory = useCallback(
    (category: IAssetAttribute) => {
      const copy = new Set(selectedFilterCategories);
      if (copy.has(category)) {
        copy.delete(category);
        // removing all sub-filter category when parent-heriachal filter gets deleted
        // eslint-disable-next-line no-unused-expressions
        SEARCH_HIERARCHICAL_FACETS[category]?.forEach(element => {
          setAppliedFilters(appliedFilters.removeAll(element));
        });
        setAppliedFilters(appliedFilters.removeAll(category));
      } else {
        copy.add(category);
        trackApplicationFeature(SEARCH_APP_FEATURE_LIST.FACET_FEATURE, FACET_FEATURE_ACTIONS.ADD_FILTER);
      }
      setAppliedFilterCategories(copy);
    },
    [appliedFilters, selectedFilterCategories, setAppliedFilterCategories, setAppliedFilters, trackApplicationFeature]
  );

  const onCategoryClear = useCallback(
    (category: IAssetAttribute) => {
      // eslint-disable-next-line no-unused-expressions
      SEARCH_HIERARCHICAL_FACETS[category]?.forEach(element => {
        setSelectedFilters(selectedFilters.removeAll(element));
      });
      setSelectedFilters(selectedFilters.removeAll(category));
    },
    [selectedFilters, setSelectedFilters]
  );

  return { onCategoryClear, toggleFilterCategory, toggleCurrentSelectedKeys };
};

export const useDateFilterOperations = () => {
  const toSolrStartDate = useCallback((date: Date) => {
    return format(date, 'yyyy-MM-dd') + 'T00:00:00Z';
  }, []);

  const toSolrEndDate = useCallback((date: Date) => {
    return format(date, 'yyyy-MM-dd') + 'T23:59:00Z';
  }, []);

  const toDateRangeLabel = useCallback((startDate: string, endDate: string) => {
    return `${startDate}~${endDate}`;
  }, []);
  return { toSolrStartDate, toSolrEndDate, toDateRangeLabel };
};
