import { useAuth } from '@amgen/auth';
import { DataSource, IAssetAttribute, ISavedSearch } from '@amgen/core';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import './app-context-provider.scss';

import { useURLParams } from '../../hooks';
import { IAttributeComponentConfig, ResultViewType } from '../../models';
import { AppContext, IAppState } from './app-context';

export interface AppContextProviderProps {
  dataSources?: DataSource[];
  internalSources?: DataSource[];
  facetCategories: IAssetAttribute[];
  attributeDisplayNames: NonNullable<{ [key in IAssetAttribute]: string }>;
  displayAttributes: IAssetAttribute[];
  excludeColumnsInTable?: IAssetAttribute[];
  suggestingPlaceholders?: string[];
  attributeConfig: IAttributeComponentConfig;
  readMoreSources?: DataSource[];
  defaultFacetCategories?: any;
}

export const AppContextProvider: React.FC<AppContextProviderProps> = ({ children, ...props }) => {
  const { user } = useAuth();
  const { sort, sortDirection, page, view } = useURLParams();
  const [resultCount, setResultCount] = useState(-1);
  const [suggestionDoc, setSuggestionDoc] = useState(false);
  const [savedSearch, setSavedSearch] = useState<ISavedSearch | null>(null);
  const [facetCategories, setFacetCategories] = useState(props.facetCategories);
  const [pageNo, setPageNo] = useState(page);
  const [activeId, setActiveId] = useState(1);
  const [dataSources, setDataSources] = useState(props.dataSources);
  const [viewType, setViewType] = useState(view);
  const [sortOption, setSortOption] = useState(sort);
  const [sortOrder, setSortOrder] = useState(sortDirection);
  const { current: perPageOptions } = useRef([15, 30, 50]);
  const [itemsPerPage, setItemsPerPage] = useState(perPageOptions[0]);
  const [isCustomSourceActive, setCustomSourceActive] = useState(false);
  const [selectedCustomSourceName, setSelectedCustomSourceName] = useState('All Sources');
  const [currentCustomsources, setCurrentCustomsources] = useState(['All Sources']);
  const [landingParent, setLandingParent] = useState('Internal Sources');
  const [currentSource, setCurrentSource] = useState('internal');
  const [backButtonClicked, setBackButtonClicked] = useState(false);
  const [selectedResultTab, setSelectedResultTab] = useState('Recommended');
  const [horizonCompanyCode, setHorizonCompanyCode] = useState(false);
  const [ischatbotIntentAvailable, setChatbotIntent] = useState(false);
  const [isBottomButtonClicked, setBottomButtonClicked] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [showIntegrationModal, setShowIntegrationModal] = useState(false);
  const [integrationModalConfirm, setIntegrationModalConfirm] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // TODO: Remove this when using apollo local state. This is a terrible solution to persist / initialize
  // view type across page navigation.
  // const { data: preferences } = useGetSavedPreferenceQuery({ variables: { appName } });
  // useEffect(() => setViewType(view ?? (preferences?.view?.values?.[0] as ResultViewType) ?? ResultViewType.List), [
  //   preferences?.view?.values,
  //   view,
  // ]);

  useEffect(() => {
    isCustomSourceActive && selectedCustomSourceName === 'Contract Search'
      ? setViewType(ResultViewType.Table)
      : setViewType(ResultViewType.List);
  }, [isCustomSourceActive, selectedCustomSourceName]);

  const providedValue = useMemo<IAppState>(
    () => ({
      resultCount,
      setResultCount,
      savedSearch,
      setSavedSearch,
      suggestionDoc,
      setSuggestionDoc,
      searchFacetCategories: props.facetCategories,
      setSearchFacetCategories: setFacetCategories,
      attributeDisplayNames: props.attributeDisplayNames,
      displayAttributes: props.displayAttributes,
      pageNo,
      setPageNo,
      dataSources,
      setDataSources,
      integrationModalConfirm,
      setIntegrationModalConfirm,
      attributeConfig: props.attributeConfig,
      excludeColumnsInTable: props.excludeColumnsInTable,
      suggestingPlaceholders: props.suggestingPlaceholders,
      viewType,
      setViewType,
      sortOption,
      setSortOption,
      sortOrder,
      setSortOrder,
      perPageOptions,
      itemsPerPage,
      setItemsPerPage,
      internalSources: props.internalSources,
      isCustomSourceActive,
      setCustomSourceActive,
      selectedCustomSourceName,
      setSelectedCustomSourceName,
      currentCustomsources,
      setCurrentCustomsources,
      user,
      setLandingParent,
      landingParent,
      readMoreSources: props.readMoreSources,
      defaultFacetCategories: props.defaultFacetCategories,
      currentSource,
      setCurrentSource,
      backButtonClicked,
      setBackButtonClicked,
      selectedResultTab,
      setSelectedResultTab,
      isBottomButtonClicked,
      setBottomButtonClicked,
      searchQuery,
      setSearchQuery,
      activeId,
      setActiveId,
      horizonCompanyCode,
      setHorizonCompanyCode,
      ischatbotIntentAvailable,
      setChatbotIntent,
      showIntegrationModal,
      setShowIntegrationModal,
      isModalOpen,
      setIsModalOpen,
    }),
    [
      isCustomSourceActive,
      setCustomSourceActive,
      selectedCustomSourceName,
      setSelectedCustomSourceName,
      props.defaultFacetCategories,
      resultCount,
      savedSearch,
      suggestionDoc,
      setSuggestionDoc,
      integrationModalConfirm,
      setIntegrationModalConfirm,
      props.attributeDisplayNames,
      props.displayAttributes,
      props.attributeConfig,
      props.excludeColumnsInTable,
      props.suggestingPlaceholders,
      props.internalSources,
      showIntegrationModal,
      setShowIntegrationModal,
      props.readMoreSources,
      props.facetCategories,
      pageNo,
      dataSources,
      viewType,
      sortOption,
      sortOrder,
      perPageOptions,
      itemsPerPage,
      currentCustomsources,
      setCurrentCustomsources,
      user,
      setLandingParent,
      landingParent,
      currentSource,
      setCurrentSource,
      backButtonClicked,
      setBackButtonClicked,
      selectedResultTab,
      setSelectedResultTab,
      isBottomButtonClicked,
      setBottomButtonClicked,
      searchQuery,
      setSearchQuery,
      activeId,
      setActiveId,
      horizonCompanyCode,
      setHorizonCompanyCode,
      ischatbotIntentAvailable,
      setChatbotIntent,
      isModalOpen,
      setIsModalOpen,
    ]
  );

  return <AppContext.Provider value={providedValue}>{children}</AppContext.Provider>;
};

export default AppContextProvider;
