import { MatomoContext, SEARCH_APP_FEATURE_LIST, TOPLINKS_FEATURE_ACTIONS } from '@amgen/shared';
import React, { useContext, useEffect } from 'react';

import './advancedDeeplinks.scss';

export interface AdvancedDeeplinksProps {
  toplinks: any;
  signedUrl: any;
  docPosition: any;
  handleTracking: (documentPosition?: number) => void;
}

export function AdvancedDeeplinks({ toplinks, signedUrl, docPosition, handleTracking }: AdvancedDeeplinksProps) {
  const { trackApplicationFeature, trackLinkClickEvent } = useContext(MatomoContext);
  useEffect(() => {
    toplinkData();
  }, [toplinks]);

  const handleClick = (eventType: string, event: string, url?: string, docPosition?: number) => {
    handleTracking(docPosition)
    trackApplicationFeature(eventType, event);
    if (url) {
      trackLinkClickEvent(url);
    }
  };

  const toplinkData = () => {
    const toplinksElement =
      toplinks &&
      toplinks.map(item => {
        return (
          // <div className="col-6 mb-2">
          <div className="col-12 mt-2 mb-2">
            <div>
              <div className="title">
                <a
                  href={item.url}
                  target={item.url || '_blank'}
                  title={item.title}
                  onClick={() => {
                    handleClick(SEARCH_APP_FEATURE_LIST.TOPLINKS, TOPLINKS_FEATURE_ACTIONS.CLICK_EVENT, item.url, docPosition);
                  }}
                >
                  <span>{item.title}</span>
                </a>
              </div>
            </div>
            <div>
              <div className="fs-13 description">
                <p className="mb-0" dangerouslySetInnerHTML={{ __html: item.content[0] }} title={item.content[0]}></p>
              </div>
            </div>
          </div>
        );
      });
    return toplinksElement;
  };
  // return <div className="row w-100 mx-5 deeplinks">{toplinkData()}</div>;
  return <div className={signedUrl ? 'row w-100 deeplinks' : 'row w-100 no-image-deeplinks'}>{toplinkData()}</div>;
}

export default AdvancedDeeplinks;
