import { createContext } from 'react';

export interface IBookmarkContextValue {
  deleted: Set<string>;
  addToDeleted: (assetId: string) => void;
  removeFromDeleted: (assetId: string) => void;
  current: Set<string>;
  addToCurrent: (assetId: string) => void;
  removeFromCurrent: (assetId: string) => void;
}

export const BookmarkContext = createContext<IBookmarkContextValue>(null!);
