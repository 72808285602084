import React, { useEffect, useState } from 'react';

import { IPaginationConfig, PaginationContext } from './context';

/* eslint-disable-next-line */
export interface PaginationProviderProps extends IPaginationConfig {}

export const PaginationProvider: React.FC<PaginationProviderProps> = ({ children, ...props }) => {
  const [startPageNo, setStartPageNo] = useState<number>(props.pageNo);
  const [activePageNo, setActivePageNo] = useState<number>(props.pageNo);

  useEffect(() => {
    if (props.pageNo !== activePageNo) {
      setActivePageNo(props.pageNo);
      if (props.pageNo !== startPageNo) {
        setStartPageNo(props.pageNo);
      }
    }
  }, [props.pageNo, startPageNo, activePageNo]);

  const nPages = Math.ceil(props.totalItems / props.itemsPerPage);
  const pages = [...Array(Math.min(nPages, props.pageOptionsCount)).keys()]
    .map(n => (n + startPageNo <= nPages ? n + startPageNo : undefined))
    .filter(item => !!item) as number[];

  const endPageNo = pages[pages.length - 1];

  return (
    <PaginationContext.Provider
      value={{ ...props, setStartPageNo, setActivePageNo, endPageNo, pages, nPages, activePageNo, startPageNo }}
    >
      {children}
    </PaginationContext.Provider>
  );
};

export default PaginationProvider;
