import React, { createContext, useContext, useEffect, useState } from 'react';

import { ApplicationName, ICustomDimension, SolrRequestHandler } from './types';

export interface Environment {
  production: boolean;
  api: {
    graphqlUrl: string;
    nlpUrl: string;
    newsUrl?: string;
    adminUrl?: string;
    chatbotUrl?: string;
    postLogoutRedirectUri?: string;
  };
  newsToken?: {
    token?: string;
  };
  oAuth: {
    clientId: string;
    redirectUri: string;
    issuer: string;
  };
  support: {
    email: string;
    guideUrl: string;
    helpcenterUrl?: string;
  };
  appHosts: Record<ApplicationName, string>;
  matomo?: {
    endPoint: string;
    siteId: number;
    dimensions: ICustomDimension;
    appDimension: number;
  };
  chatbotAppId?: string;
  clusterSearch?: boolean;
  msal?:{
    clientId: string;
    authority: string;
    redirectUri: string;
  }
}

interface IEnvironmentContextValue extends Environment {
  appName: ApplicationName;
  envGraphqlUrl: string;
  setEnvGraphqlUrl: any;
  externalAppName?: string;
  trackingAppName?: string;
  setAppName: (appName: ApplicationName) => void;
  setExternalAppName: (appName: string) => void;
  setTrackingAppName: (appName: string) => void;
  requestHandler: SolrRequestHandler;
  suggestHandler: SolrRequestHandler;
}

export const EnvironmentContext = createContext<IEnvironmentContextValue>(null!);

interface EnvironmentProviderProps extends Environment {
  appName: ApplicationName;
}

export const EnvironmentProvider: React.FC<EnvironmentProviderProps> = ({ children, ...props }) => {
  const [appName, setAppName] = useState(props.appName);
  const [envGraphqlUrl, setEnvGraphqlUrl] = useState(props?.api?.graphqlUrl);
  const [externalAppName, setExternalAppName] = useState(sessionStorage.getItem('externalAppName') || '');
  const [trackingAppName, setTrackingAppName] = useState(sessionStorage.getItem('trackingAppName') || '');
  const requestHandler = `${appName}_SEARCH` as SolrRequestHandler;
  const suggestHandler = `${appName}_SUGGEST` as SolrRequestHandler;

  return (
    <EnvironmentContext.Provider
      value={{
        ...props,
        envGraphqlUrl,
        setEnvGraphqlUrl,
        appName,
        setAppName,
        externalAppName,
        setExternalAppName,
        requestHandler,
        suggestHandler,
        trackingAppName,
        setTrackingAppName,
      }}
    >
      {children}
    </EnvironmentContext.Provider>
  );
};

export const useEnvironment = () => useContext(EnvironmentContext);
