import { IApplicationInfo } from '@amgen/core';

type IGetDataSourceItems = Pick<
  IApplicationInfo,
  'image' | 'id' | 'name' | 'type' | 'url' | 'vendor' | 'description' | 'parent' | 'active' | 'category' | 'group'
>;

export const convertDataSourceToGroups = (data?: IGetDataSourceItems[]) => {
  const appsArray = data?.filter(item => item.category?.includes('Apps')) || [];
  const groupDataSourceMap: Map<string, Set<IGetDataSourceItems>> = new Map();
  appsArray.forEach(dataSourceItem => {
    dataSourceItem.group?.forEach(groups => {
      const groupName: string = groups.split(':').shift() || '';
      if (groupDataSourceMap.has(groupName)) {
        const sourceSet = groupDataSourceMap.get(groupName)?.add(dataSourceItem);
        if (sourceSet) groupDataSourceMap.set(groupName, sourceSet);
      } else {
        const sourceSet: Set<IGetDataSourceItems> = new Set();
        sourceSet.add(dataSourceItem);
        groupDataSourceMap.set(groupName, sourceSet);
      }
    });
  });
  return groupDataSourceMap;
};

export const convertCustomGroups = (customGroups, data?: IGetDataSourceItems[] | []) => {
  // TODO: type this
  const groupDataSourceMap: Map<string, any> = new Map();
  customGroups.forEach(group => {
    // TODO: type this properly
    const dataSources = group.values;
    const groupName = group.group;
    // TODO: figure out how we want to handle icons for nested data sources
    const imageUrls: string[] = [];
    // TODO: figure out how we want to handle icons for nested data sources
    //TODO: solve this typescript issue for data as Object is undefined
    dataSources?.forEach(source => {
      data?.forEach(data => {
        if (source?.includes(data?.id)) {
          const isImageExist = data && data.image && data.image.length > 0;
          if (isImageExist) {
            const firstImage = data?.image?.[0];
            if (isImageExist && firstImage && !imageUrls?.includes(firstImage)) {
              imageUrls.push(firstImage);
            }
          }
        }
      });
    });

    groupDataSourceMap.set(group.id, { dataSources, imageUrls, groupName });
  });
  return groupDataSourceMap;
};
