import { AssetContext, useWindowSizeListener } from '@amgen/shared';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import DOMPurify from 'dompurify';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import './asset-title.scss';

import { useAssetTitleUrl } from '../../hooks';

export interface AssetTitleProps {
  asset?: any;
  className?: string;
  onTitleClick?: (assetId: string, url?: string) => void;
  customTitle?: string;
  disableRouterLink?: boolean;
  hideExternalLinkIcon?: boolean;
}

export const AssetTitle: React.FC<AssetTitleProps> = ({
  asset,
  className,
  customTitle,
  onTitleClick,
  disableRouterLink,
  hideExternalLinkIcon,
}) => {
  const { title, url, target, detailUrl, showDocumentIcon, isMsgType } = useAssetTitleUrl(asset);
  const [isAllResultsURLEmpty, setIsAllResultsURLEmpty] = useState(false);
  const isMobile = useWindowSizeListener();
  const splitUrl = url?.split('/');
  splitUrl?.pop();
  const homeUrl = splitUrl?.join('/');
  const homeTarget = homeUrl ? '_blank' : '_self';
  const { docSolrAsset } = useContext(AssetContext);
  const [docSolrAssetData, setDocSolrAsset] = useState(docSolrAsset?.dataAsset);

  const limitUrllength = docSolrAssetData?.url || '';
  const toggleUrlLength =
    limitUrllength?.length > 51 ? docSolrAssetData?.url?.slice(0, 51) + '...' : docSolrAssetData?.url;
  useEffect(() => {
    if (toggleUrlLength === null) {
      setIsAllResultsURLEmpty(true);
    } else {
      setIsAllResultsURLEmpty(false);
    }
  }, []);

  if (url && !isMsgType) {
    return (
      <div className={classNames(className, 'd-flex')}>
        <a
          href={DOMPurify.sanitize(url)}
          target={target || '_blank'}
          title={title}
          onClick={() => onTitleClick && onTitleClick(asset.id!, url)}
        >
          <div id="asset-title-id" className={'asset-title-search-card'}>
            {title}
          </div>

          {/* {showDocumentIcon ? (
            <FontAwesomeIcon icon={faFileAlt} className="mx-2 text-info float-right" />
          ) : (
            !hideExternalLinkIcon &&
            !isMobile &&
            !isAllResultsURLEmpty && <FontAwesomeIcon icon={faExternalLinkAlt} className="mx-2 text-info float-right" />
          )} */}
          {asset.site_name === ('LCR' || 'lcr') && (
            <a
              href={DOMPurify.sanitize(homeUrl)}
              target={homeTarget || '_blank'}
              onClick={() => onTitleClick && onTitleClick(asset.id!, homeUrl)}
              title="View Contract Family"
            >
              <FontAwesomeIcon icon={faHome} className="mx-1 text-info float-right" />
            </a>
          )}
        </a>
      </div>
    );
  } else if (isMsgType) {
    return (
      <div className={classNames(className, 'd-flex')}>
        <div id="asset-title-id" className={'asset-title-search-card'}>
          {title}
        </div>
        {asset.site_name === ('LCR' || 'lcr') && (
          <a
            href={DOMPurify.sanitize(homeUrl)}
            target={homeTarget || '_blank'}
            onClick={() => onTitleClick && onTitleClick(asset.id!, homeUrl)}
            title="View Contract Family"
          >
            <FontAwesomeIcon icon={faHome} className="mx-1 text-info float-right" />
          </a>
        )}
      </div>
    );
  }

  if (detailUrl && !disableRouterLink && !isMsgType) {
    return (
      <Link
        to={detailUrl}
        className={className}
        target={target || '_blank'}
        title={title}
        onClick={() => onTitleClick && onTitleClick(asset.id!, detailUrl)}
      >
        <div id="asset-title-id" className="asset-title-search-card">
          {title}
        </div>
        {/* {showDocumentIcon ? <FontAwesomeIcon icon={faFileAlt} className="mx-2 text-info float-right" /> : ''} */}
      </Link>
    );
  }

  return (
    <span
      className={className}
      title={title}
      style={{ wordWrap: 'break-word' }}
      onClick={() => onTitleClick && onTitleClick(asset.id!)}
    >
      {title}
    </span>
  );
};

export default AssetTitle;
