import React, { useContext } from 'react';
import { Col } from 'react-bootstrap';

import './search-page-empty-result.scss';

import { SearchPageContext } from '../search-page-container/context';
import { AppContext } from '@amgen/shared';
import { SearchResultPaginator } from '@amgen/feature-search';

/* eslint-disable-next-line */
export interface SearchPageEmptyResultProps {}

export const SearchPageEmptyResult: React.FC<SearchPageEmptyResultProps> = props => {
  const { loading, emptySearchResult } = useContext(SearchPageContext);
  const { selectedResultTab } = useContext(AppContext);
  return !loading && emptySearchResult ? (
    <>
      <Col>
        {props.children}
        <Col xs="8" className="mx-auto">
          <img className="d-block m-auto w-100" src="assets/images/no-results-found.png" alt="no results found" />
        </Col>
      </Col>
      {selectedResultTab === 'All Results' && <SearchResultPaginator />}
    </>
  ) : null;
};

export default SearchPageEmptyResult;
