import { AppContext, ResultViewType } from '@amgen/shared';
import React, { useContext } from 'react';
import { Col } from 'react-bootstrap';

import './header.scss';

/* eslint-disable-next-line */
export interface HeaderProps {
  isAssetDetail?: boolean;
  isMobile?: boolean;
}

export const Header: React.FC<HeaderProps> = ({ children, ...props }) => {
  const { viewType } = useContext(AppContext);
  return (
    <Col
      xs={12}
      as="h2"
      className={
        props.isMobile
          ? 'd-flex justify-content-between px-1 align-items-baseline mb-0'
          : props.isAssetDetail
          ? 'd-flex justify-content-between px-0 align-items-baseline mb-0'
          : viewType === ResultViewType.Table
          ? 'd-flex justify-content-between p-0 align-items-baseline mb-0'
          : 'd-flex justify-content-between px-3 align-items-baseline mb-0'
      }
    >
      {children}
    </Col>
  );
};

export default Header;
