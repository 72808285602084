import { useGetApplicationFeedbacksQuery } from '@amgen/api';
import { InfiniteScrollLoader } from '@amgen/components';
import { Direction, IApplicationFeedback, useEnvironment } from '@amgen/core';
import React, { useState } from 'react';

import './application-feedback-list.scss';

import ApplicationFeedbackItem from '../application-feedback-item/application-feedback-item';

export const ApplicationFeedbackList = () => {
  const [appFeedbacks, setAppFeedbacks] = useState<IApplicationFeedback[]>([]);
  const [page, setPage] = useState(0);
  const [hasNext, setHasNextPage] = useState(true);
  const { appName } = useEnvironment();

  const { loading, error, refetch } = useGetApplicationFeedbacksQuery({
    onCompleted: data => {
      if (data?.feedbacks) {
        page ? setAppFeedbacks([...appFeedbacks, ...data?.feedbacks]) : setAppFeedbacks(data?.feedbacks);
        if (data?.feedbacks.length === 0) {
          setHasNextPage(false);
        }
      }
    },
    variables: { appName, page, count: 10, start: 0, sortField: 'timestamp', sortOrder: Direction.DESC },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  return (
    <>
      <InfiniteScrollLoader
        className="application-feedback-container"
        loading={loading}
        isEmpty={(appFeedbacks.length ?? -1) <= 0}
        hasNext={hasNext}
        page={page}
        hasScrolledToEnd={p => {
          setPage(p);
          if (p >= 1) refetch();
        }}
      >
        {appFeedbacks.map((feedback, index) => (
          <ApplicationFeedbackItem key={index} {...feedback} />
        ))}
      </InfiniteScrollLoader>
      {!loading && (appFeedbacks.length ?? -1) <= 0 ? (
        <h2 className="col-12 ml-n2 mt-2 text-blue text-center">No Feedback found</h2>
      ) : null}
    </>
  );
};

export default ApplicationFeedbackList;
