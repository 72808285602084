import { IDataCrawlRequest, RequestStatusType } from '@amgen/core';
import { useWindowSizeListener } from '@amgen/shared';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { Table } from 'react-bootstrap';
import { Column, useTable } from 'react-table';

import './my-request-table.scss';

import { APPROVED_DECLINED_COLUMNS, PENDING_COLUMNS, APPROVED_COLUMNS } from '../../config';
import { SortOrderObject } from '../../models/sort';
import IgdTableBody from '../igd-table-body/igd-table-body';
import IgdTableHead from '../igd-table-head/igd-table-head';

export interface MyRequestTableProps {
  data: IDataCrawlRequest[];
  status: RequestStatusType;
  loading: boolean;
  sort: SortOrderObject;
  setSort: (sort: SortOrderObject) => void;
}

export const MyRequestTable = ({ data, status, sort, loading, setSort }: MyRequestTableProps) => {
  const columns: Column<IDataCrawlRequest>[] = useMemo(() => {
    return status === RequestStatusType.PENDING
      ? PENDING_COLUMNS
      : RequestStatusType.APPROVED
      ? APPROVED_COLUMNS
      : APPROVED_DECLINED_COLUMNS;
  }, [status]);
  const isMobile = useWindowSizeListener();

  const table = useTable<IDataCrawlRequest>({ data, columns });

  return (
    <>
      <Table striped bordered hover responsive {...table.getTableProps()} className={isMobile ? 'fs-12' : ''}>
        <thead>
          <IgdTableHead headerGroups={table.headerGroups} setSort={setSort} sort={sort} />
        </thead>

        <IgdTableBody table={table} loading={loading} />
      </Table>
      {!loading && table.rows.length < 1 ? (
        <div className={classNames('py-5 text-center bg-grey mt-n3 mb-3', { 'fs-14': isMobile })}>
          No Results found. Try removing filters or searching for other terms
        </div>
      ) : null}
    </>
  );
};

export default MyRequestTable;
