import { ICustomDimension } from '@amgen/core';
import { createContext } from 'react';

export interface IMatomoContextValue {
  // [todo] remove this
  trackEvent: (category: string, action: string, name: string, value?: string | number) => void;
  trackPageView: (userId: string) => void;
  trackSiteSearch: (searchKey: string, category: string | boolean, numFound: number) => void;
  trackCustomDimensions: (value: string | number, dimensionId?: string) => void;
  trackApplicationFeature: (category: string, action: string, value?: string | number) => void;
  trackMultipleCustomDimensions: (dimensionsMap: Map<string, string>) => void;
  trackLinkClickEvent: (value: string) => void;
  dimensions: ICustomDimension;
}

export const MatomoContext = createContext<IMatomoContextValue>(null!);
