import { useEnvironment } from '@amgen/core';
import React, { useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { IMatomoContextValue, MatomoContext } from './context';
import { SEARCH_APP_FEATURE_LIST } from './events';
import { AmgenMatomoTracker } from './matomo';

/* eslint-disable-next-line */
export interface MatomoProviderProps {}

export const MatomoProvider: React.FC<MatomoProviderProps> = ({ children }) => {
  const { matomo, appHosts, appName } = useEnvironment();
  const history = useHistory();

  // dummy handler for non-matomo sites in this code-base when wrapped with matomo context
  const noMatomoHandler = useCallback((...args: any[]) => {}, []);

  const { current: providedNoMatomoValue } = useRef<IMatomoContextValue>({
    trackEvent: noMatomoHandler,
    trackPageView: noMatomoHandler,
    trackSiteSearch: noMatomoHandler,
    trackCustomDimensions: noMatomoHandler,
    trackApplicationFeature: noMatomoHandler,
    trackMultipleCustomDimensions: noMatomoHandler,
    trackLinkClickEvent: noMatomoHandler,
    dimensions: matomo!.dimensions,
  });

  const { current: AmgenMatomo } = useRef(
    new AmgenMatomoTracker({
      url: matomo?.endPoint ?? '',
      siteId: matomo?.siteId ?? 0,
      pathBasename: 'search',
    })
  );

  useEffect(() => {
    AmgenMatomo.connectToHistory(history);

    return () => {
      AmgenMatomo.disconnectFromHistory();
    };
  }, [AmgenMatomo, history]);

  const trackEvent = useCallback(
    (category: string, action: string, name: string, value?: string | number) => {
      try {
        AmgenMatomo.push(['trackEvent', category, action, name, value]);
      } catch (error) {
        console.error(error);
      }
    },
    [AmgenMatomo]
  );

  const trackLinkClickEvent = useCallback(
    (url?: string) => {
      try {
        AmgenMatomo.push(['trackLink', url, 'link']);
      } catch (error) {
        console.error(error);
      }
    },
    [AmgenMatomo]
  );

  const trackEachPageView = useCallback(
    (userId: string) => {
      try {
        AmgenMatomo.push(['enableHeartBeatTimer', 15]);
        AmgenMatomo.push(['setUserId', userId]);
        if (appHosts[appName]) AmgenMatomo.push(['setCustomUrl', appHosts[appName]]);

        AmgenMatomo.push(['trackPageView']);

        AmgenMatomo.push(['trackVisibleContentImpressions']);
      } catch (error) {
        console.error(error);
      }
    },
    [AmgenMatomo, appHosts, appName]
  );

  const trackSiteSearch = useCallback(
    (searchKey: string, category: string | boolean, numFound: number) => {
      try {
        if ('*:*' !== searchKey) {
          AmgenMatomo.push(['trackSiteSearch', searchKey, category, numFound]);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [AmgenMatomo]
  );

  const trackCustomDimensions = useCallback(
    (value: string | number, dimensionId?: string) => {
      if (dimensionId) {
        AmgenMatomo.push(['trackPageView', document.title, { [dimensionId]: value }]);
      }
    },
    [AmgenMatomo]
  );

  const trackMultipleCustomDimensions = useCallback(
    (list: Map<string, string>) => {
      if (list.size) {
        AmgenMatomo.push(['trackPageView', document.title, Object.fromEntries(list)]);
      }
    },
    [AmgenMatomo]
  );

  const trackApplicationFeature = useCallback(
    (category: string, action: string, value: string | number = 1) => {
      if (category === SEARCH_APP_FEATURE_LIST.EXPORT_CSV) {
        AmgenMatomo.push(['trackLink', window.location.href, 'download']);
      }

      trackEvent(category, action, appName, value);
    },
    [AmgenMatomo, appName, trackEvent]
  );

  const { current: providedValue } = useRef<IMatomoContextValue>({
    trackEvent,
    trackPageView: trackEachPageView,
    trackSiteSearch,
    trackCustomDimensions,
    trackApplicationFeature,
    trackMultipleCustomDimensions,
    trackLinkClickEvent,
    dimensions: matomo!.dimensions,
  });

  if (!matomo?.endPoint || !matomo?.siteId) {
    return <MatomoContext.Provider value={providedNoMatomoValue}>{children}</MatomoContext.Provider>;
  }

  return <MatomoContext.Provider value={providedValue}>{children}</MatomoContext.Provider>;
};

export default MatomoProvider;
