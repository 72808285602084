import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useField } from 'formik';
import React, { useCallback, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';

import './suggested-app-input.module.scss';

import AttachmentDownloadLink from '../../../attachment-download-link/attachment-download-link';
import NewRequestFormLabel from '../../../new-request/new-request-form/new-request-form-label/new-request-form-label';
import { returnFileSize } from '../../models';

/* eslint-disable-next-line */
export interface SuggestedAppInputProps {
  image?: string;
  mode?: string;
}

export function SuggestedAppInput(props: SuggestedAppInputProps) {
  const [fileField, fileMeta, fileFieldHelper] = useField<File | undefined>('fileDetails');
  const handleFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      fileFieldHelper.setTouched(true);
      const inputFile = e.target.files?.[0];
      fileFieldHelper.setValue(inputFile);
    },
    [fileFieldHelper]
  );

  const removeAddedfile = () => {
    fileFieldHelper.setValue(undefined);
  };

  const [urlField, urlMeta, urlHelpers] = useField('url');
  const [contentField, contentMeta, contentHelper] = useField<string>('content');
  const [titleField, titleMeta, titleHelper] = useField('title');
  const [requesterIdField, requesterIdMeta, requesterIdHelper] = useField('requesterId');
  const [tagsField, tagsMeta, tagsHelper] = useField('tags');

  const [title, setTitle] = useState('');

  const [requesterId, setRequesterId] = useState('');

  const [removeImage, setRemoveImage] = useState(false);
  return (
    <>
      <Row className="pb-3">
        <Form.Group as={Col} xs="12" md="6" controlId={titleField.name}>
          <NewRequestFormLabel name="title" label={'Title'} helpText="Enter title for suggested app/destination" />
          <Form.Control
            type="string"
            className="thin-scrollbar"
            placeholder="Enter title for suggested app/destination"
            {...titleField}
            onChange={e => {
              titleHelper.setTouched(true);
              titleField.onChange(e);
            }}
            maxLength={50}
            isInvalid={titleMeta.touched && !!titleMeta.error}
            isValid={titleField.value.trim().length > 0 && titleField.value.trim().length <= 50}
          />
          <Form.Control.Feedback type="invalid">{titleMeta.error}</Form.Control.Feedback>
          <Form.Control.Feedback type="valid" className="text-left text-black-50">{`Characters left: ${
            50 - titleField.value.trim().length
          }`}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} xs="12" md="6" controlId="fileDetails">
          <NewRequestFormLabel name="fileDetails" label="Upload image" helpText="Please upload an image ( jpg, png)" />
          <Form.File>
            {props.mode === 'edit' && props.image && !removeImage ? (
              <Form.File.Label data-browse="Browse">
                <AttachmentDownloadLink s3Location={props.image}>{'Image  '}</AttachmentDownloadLink>
                <FontAwesomeIcon
                  title="Remove added file"
                  onClick={() => setRemoveImage(true)}
                  className="cursor-pointer text-primary fa-sm ml-2"
                  icon={faTimes}
                />
              </Form.File.Label>
            ) : fileField?.value?.name ? (
              <Form.File.Label data-browse="Browse">
                {fileField?.value?.name + '  '}
                <FontAwesomeIcon
                  title="Remove added file"
                  onClick={removeAddedfile}
                  className="cursor-pointer text-primary fa-sm ml-2"
                  icon={faTimes}
                />
              </Form.File.Label>
            ) : (
              <Form.File.Input
                name={fileField.name}
                onChange={handleFileChange}
                isInvalid={fileMeta.touched && !!fileMeta.error}
                accept=".png,.jpg"
              />
            )}

            <Form.Control.Feedback type="invalid">{fileMeta.error}</Form.Control.Feedback>
            {fileField?.value?.size && !fileMeta.error && returnFileSize(fileField?.value.size) && (
              <Form.Control.Feedback type="valid" className="text-muted float-right">
                Upload Size: {returnFileSize(fileField?.value.size)}
              </Form.Control.Feedback>
            )}
          </Form.File>
        </Form.Group>
      </Row>
      <Row className="pb-3">
        <Form.Group as={Col} md="6" controlId={contentField.name}>
          <NewRequestFormLabel
            name={contentField.name}
            helpText={'Provide more data for the suggested app/destination'}
            label={'Description'}
          />
          <Form.Control
            type="text"
            as="textarea"
            rows={3}
            placeholder="Provide more data for the suggested app/destination"
            {...contentField}
            onChange={e => {
              contentField.onChange(e);
              contentHelper.setTouched(true);
            }}
            isInvalid={contentMeta.touched && !!contentMeta.error}
          />
          <Form.Control.Feedback type="invalid">{contentMeta.error}</Form.Control.Feedback>
          {!contentMeta.error && (
            <Form.Text className="text-right">
              {/* Words: {`${contentField.value.split(/\s+/g).filter(t => !!t).length} / ${MAX_WORD_COUNT}`} */}
            </Form.Text>
          )}
        </Form.Group>
        <Form.Group as={Col} xs="12" md="6" controlId={urlField.name}>
          <NewRequestFormLabel
            name={urlField.name}
            helpText={'Please enter the Destination URL'}
            label={'Destination URL'}
          />
          <Form.Control
            type="string"
            placeholder={'eg: https://amgen.sharepoint.com/sites/data-science'}
            {...urlField}
            onChange={e => {
              urlHelpers.setTouched(true);
              urlField.onChange(e);
            }}
            isInvalid={urlMeta.touched && !!urlMeta.error}
            disabled={props.mode === 'edit'}
          />
          <Form.Control.Feedback type="invalid">{urlMeta.error}</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="pb-3">
        <Form.Group as={Col} xs="12" md="6" controlId="requesterId">
          <NewRequestFormLabel name="requesterId" label="Requester Amgen ID" helpText="Enter Requester Amgen ID" />
          <Form.Control
            type="string"
            placeholder="Enter Requester Amgen ID"
            {...requesterIdField}
            onChange={e => {
              requesterIdHelper.setTouched(true);
              requesterIdField.onChange(e);
              setRequesterId(e.target.value);
            }}
            maxLength={20}
            isInvalid={requesterIdMeta.touched && !!requesterIdMeta.error}
            isValid={requesterId.trim().length > 0 && requesterId.trim().length <= 20}
          />
          <Form.Control.Feedback type="invalid">{requesterIdMeta.error}</Form.Control.Feedback>
          <Form.Control.Feedback type="valid" className="text-left text-black-50">{`Characters left: ${
            20 - requesterId.trim().length
          }`}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} xs="12" md="6" controlId="tags">
          <NewRequestFormLabel name="tags" label="Tags (Optional)" helpText="Tags" />
          <Typeahead
            allowNew
            id="subscribe-recipients"
            multiple
            defaultSelected={tagsField.value}
            options={[]}
            placeholder="Add Tags"
            selected={tagsField.value}
            emptyLabel="Type to add Tags and Click enter"
            //This callback on allowNew prop,is passing objects instead of string[]

            // eslint-disable-next-line @typescript-eslint/no-explicit-any

            onChange={(s: any) => {
              if (s.length) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any

                const c = s.map((x: any) => (x.label ? x.label.trim() : x.trim()));

                tagsHelper.setValue([...new Set(c)] as string[]);
              } else {
                tagsHelper.setValue([]);
              }
            }}
          />
        </Form.Group>
      </Row>
    </>
  );
}

export default SuggestedAppInput;
