// import * as palette from 'google-palette';

const toHexString = (rgb: number) =>
  Number(rgb)
    .toString(16)
    .padStart(2, '0');

const fullColorHex = (r: number, g: number, b: number) => {
  return `#${toHexString(r)}${toHexString(g)}${toHexString(b)}`;
};

/**
 * @param name same name returns back same color
 *  E.g 'Repatha' always returns '#874be6'
 *  the color generated would be (r < 100  )(g is based on len of string and r) (b  is based on len of string and r)
 *  meanAlphaPos is unique no. generated by index of the aplphabet in the random string(all alphabets)
 *  r is always less < 100 as meanAlphaPos is a ratio of length and length itself [extreme=> 26*26/26]
 */
const generateColor = (name: string) => {
  const alphaNumerical = 'rg8k14c9qba3fl5mph7iejt2u6v0oswxdynz'; //random generated string for alphabets and numbers.
  const nameLen = name.length;
  const meanAlphaPos = Math.round(
    name.split('').reduce((count, values, index) => count + index * alphaNumerical.indexOf(values.toLowerCase()), 0) /
      nameLen
  );
  const r = meanAlphaPos - nameLen;
  return fullColorHex(r * (160 - r), nameLen * (270 - r), r * (295 - r)).substring(0, 7);
  // return fullColorHex(r * (160 - r), nameLen * (290 - r), nameLen * (295 - r)).substring(0, 7); more green
};

/**
 * generates a color based on the name, send it a array of strings => returns back a const name-based color array.
 * @param names
 */
export const colorPalette = (names: string[]) => names.map(generateColor);

// export const colorPalette = (names: string[]) => {
//   const cache: { [name: string]: string } = {};
//   const colors = palette('tol', names.length);
//   const colorsSet = new Set(colors);

//   names.forEach((name, index) => {
//     cache[name] = colors[index];
//   });
//   return colors;
// };

// another way of generating blue based color but color pattern generated  wasnt diverse
// export const generateColour = (name: string) => {
//   const alphabets = 'rkecqbaflmphijtugvoswxdynz';
//   const letters = '7AFBCD5201346E89';
//   let rawString = name.slice(0, 6);
//   let sixStringCode = '0';
//   if (rawString.length < 5) {
//     rawString += 'EF'; //blue basing of the color
//   }
//   for (let i = 0; i < rawString.length; i++) {
//     const v = rawString[i];
//     if (letters.indexOf(v.toUpperCase()) > 0) {
//       sixStringCode += v;
//     } else {
//       sixStringCode += alphabets.indexOf(v.toLowerCase());
//     }
//   }

//   return `#${sixStringCode.slice(0, 6)}`;
// };

// export const makeAColorArray = (arrOfNames: string[]) => {
//   let colorArray = arrOfNames.map((item: any) => {
//     return generateColour(item);
//   });
//   return colorArray;
// };
