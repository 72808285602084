import { DEFAULT_SEARCH_QUERY } from '@amgen/core';
import { AppContext, FacetContext, FacetsPanelContext, FiltersObject, useSearchQueryValue } from '@amgen/shared';
import { DEFAULT_PLACEHOLDER } from '@amgen/utils';
import queryString from 'query-string';
import React, { useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import './search-box-context-provider.scss';

import { IQueryParams } from '../../models';
import { useTypeWriter } from '../search-box/typewriter';
import { useAdvanceSearchState, useSearchBoxState, useTypeaheadState } from './hooks';
import { ISearchBoxState, SearchBoxContext } from './search-box-context';

export interface SearchBoxContextProviderProps {
  isSearchExpanded?: boolean;
  setIsSearchExpanded?: (value: boolean) => void;
  searchSize?: 'sm' | 'lg';
  showAdvanceSearch?: boolean;
  showQuickFilters?: boolean;
  enableSuggestingPlaceholder?: boolean;
  setBlockScroll?: (value: boolean) => void;
  onExternalSearch?: (keyword: string, filters: FiltersObject) => void;
}

export const SearchBoxContextProvider: React.FC<SearchBoxContextProviderProps> = ({
  children,
  isSearchExpanded,
  setIsSearchExpanded,
  searchSize,
  showAdvanceSearch,
  enableSuggestingPlaceholder,
  setBlockScroll,
  onExternalSearch,
  showQuickFilters,
}) => {
  const { savedSearch, suggestingPlaceholders, setSavedSearch } = useContext(AppContext);
  const { selectedFilters, setSelectedFilters } = useContext(FacetsPanelContext);
  const { setAppliedFilters } = useContext(FacetContext);
  const [placeholder, setPlaceholder] = useState(DEFAULT_PLACEHOLDER);
  const location = useLocation();
  const queryParams: IQueryParams = queryString.parse(location.search);
  const { q } = queryParams;
  const state = location.state as any;
  const queryValue = useSearchQueryValue();
  const { pauseTypewriter, startTypewriter } = useTypeWriter();
  const [payload, setPayload] = useState({ title: '', object: '' });
  const [selectedTypeName, setSelectedTypeName] = useState('');
  const [callAPI, setCallAPI] = useState(false);

  const {
    searchQuery,
    setSearchQuery,
    keyUpdate,
    setKeyUpdate,
    reusedQuery,
    setReusedQuery,
    onSearch,
  } = useSearchBoxState();

  const {
    selectedFilter,
    setSelectedFilter,
    openSuggestionDrop,
    setOpenSuggestionDrop,
    loading,
    dropdownOptions,
    hasSearchBoxFacetingExperience,
    typeaheadRef,
    selectedTypeaheadFilters,
    setSelectedTypeaheadFilters,
    selectedTypeaheadFilterCategories,
    setSelectedTypeaheadFilterCategories,
  } = useTypeaheadState();

  const {
    contentSelected,
    setContentSelected,
    dateFilterSelected,
    setDateFilterSelected,
    applyFilter,
    showAdvSearch,
    setShowAdvSearch,
  } = useAdvanceSearchState();

  useEffect(() => {
    if (showAdvanceSearch) {
      setSavedSearch(null);
    } else {
      setSearchQuery(prevQuery => queryValue ?? savedSearch?.query ?? prevQuery);
    }
  }, [queryValue, savedSearch, setSavedSearch, setSearchQuery, showAdvanceSearch]);

  useEffect(() => {
    const nonce = new Date().getTime();
    if (queryValue || savedSearch?.query === DEFAULT_SEARCH_QUERY) {
      if (state?.bookmarkClicked) {
        setSelectedFilter(null);
        setSearchQuery('');
        typeaheadRef.current.clear();
      } else {
        setKeyUpdate(`${queryValue}${nonce}`);
      }
    } else if (queryValue === '') {
      // to handle the kmi reset value
      setKeyUpdate('none');
    }
  }, [queryValue, savedSearch, setKeyUpdate, setSearchQuery, selectedFilter]);

  useEffect(() => {
    if (!dateFilterSelected && showAdvanceSearch) {
      setSelectedFilters(selectedFilters.removeAll('doc_last_modified'));
      setAppliedFilters(selectedFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    if (enableSuggestingPlaceholder && suggestingPlaceholders && !openSuggestionDrop) {
      startTypewriter(setPlaceholder, suggestingPlaceholders);
    } else {
      pauseTypewriter();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableSuggestingPlaceholder, suggestingPlaceholders, openSuggestionDrop]); // todo clear on unmount

  const providedValue = useMemo<ISearchBoxState>(
    () => ({
      isSearchExpanded,
      setIsSearchExpanded,
      searchSize,
      showAdvanceSearch,
      contentSelected,
      setContentSelected,
      dateFilterSelected,
      setDateFilterSelected,
      onSearch,
      applyFilter,
      searchQuery,
      setSearchQuery,
      reusedQuery,
      setReusedQuery,
      keyUpdate,
      setKeyUpdate,
      loading,
      selectedFilter,
      setSelectedFilter,
      dropdownOptions,
      hasSearchBoxFacetingExperience,
      openSuggestionDrop,
      setOpenSuggestionDrop,
      showAdvSearch,
      setShowAdvSearch,
      placeholder,
      typeaheadRef,
      setBlockScroll: setBlockScroll ? setBlockScroll : () => {},
      selectedTypeaheadFilters,
      setSelectedTypeaheadFilters,
      selectedTypeaheadFilterCategories,
      setSelectedTypeaheadFilterCategories,
      onExternalSearch,
      showQuickFilters,
      payload,
      setPayload,
      selectedTypeName,
      setSelectedTypeName,
      callAPI,
      setCallAPI,
    }),
    [
      applyFilter,
      contentSelected,
      dateFilterSelected,
      dropdownOptions,
      hasSearchBoxFacetingExperience,
      isSearchExpanded,
      keyUpdate,
      loading,
      onExternalSearch,
      onSearch,
      openSuggestionDrop,
      placeholder,
      reusedQuery,
      searchQuery,
      searchSize,
      selectedFilter,
      selectedTypeaheadFilterCategories,
      selectedTypeaheadFilters,
      setBlockScroll,
      setContentSelected,
      setDateFilterSelected,
      setIsSearchExpanded,
      setKeyUpdate,
      setOpenSuggestionDrop,
      setReusedQuery,
      setSearchQuery,
      setSelectedFilter,
      setSelectedTypeaheadFilterCategories,
      setSelectedTypeaheadFilters,
      setShowAdvSearch,
      showAdvSearch,
      showAdvanceSearch,
      showQuickFilters,
      typeaheadRef,
      payload,
      setPayload,
      setSelectedTypeName,
      selectedTypeName,
      callAPI,
      setCallAPI,
    ]
  );
  return (
    <SearchBoxContext.Provider value={providedValue}>
      <div className="d-flex d-md-flex align-items-center">{children}</div>
    </SearchBoxContext.Provider>
  );
};

export default SearchBoxContextProvider;
