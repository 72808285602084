import { faInfoCircle } from '@amgen/assets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';

import './info-text.scss';

export interface InfoTextProps {
  noIcon?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

export const InfoText: React.FC<InfoTextProps> = ({ children, noIcon, className, style }) => {
  return (
    <div
      className={classNames('d-flex justify-content-start align-items-center mt-2 text-secondary', className)}
      style={style}
    >
      {!noIcon && <FontAwesomeIcon className="mr-2" icon={faInfoCircle} color="info" style={{ fontSize: 'larger' }} />}
      <span>{children}</span>
    </div>
  );
};

export default InfoText;
