import { useWindowSizeListener } from '@amgen/shared-kmi';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import React from 'react';
import { Button, Spinner } from 'react-bootstrap';

import './bookmark-button.scss';

export interface BookmarkButtonProps {
  onClick: () => void;
  icon: IconDefinition;
  isDisabled?: boolean;
  isLoading: boolean;
  tooltipText: string;
  isCustomBookmark?: boolean;
  isBookMarked?: boolean;
  isAssetDetail?: boolean;
  buttonText?: string;
}

export const BookmarkButton = (props: BookmarkButtonProps) => {
  const isMobile = useWindowSizeListener();
  return props.isLoading ? (
    <Spinner animation="border" size="sm" variant="primary" />
  ) : !props.isCustomBookmark ? (
    <span
      className={classnames('cursor-pointer mt-1 ml-1', {
        'text-primary': !props.isDisabled,
        'text-muted': props.isDisabled,
      })}
      onClick={() => !props.isDisabled && props.onClick()}
      title={props.isDisabled ? 'Bookmarking is not available' : props.tooltipText}
    >
      <FontAwesomeIcon icon={props.icon} className={props.buttonText ? 'ml-1' : ''} />
      {isMobile && <span className="ml-2 mr-1 button-text">{props.buttonText}</span>}
    </span>
  ) : (
    <Button
      variant="primary"
      className={classnames({ 'text-muted': props.isDisabled, 'd-flex': props.isAssetDetail })}
      onClick={() => !props.isDisabled && props.onClick()}
      title={props.isDisabled ? 'Bookmarking is not available' : props.tooltipText}
      size={isMobile ? 'sm' : 'lg'}
    >
      <span className="mr-3">{props.isBookMarked ? 'Bookmarked' : 'Bookmark'}</span>
      <FontAwesomeIcon icon={props.icon} className={props.isAssetDetail ? 'mt-1' : ''} />
    </Button>
  );
};

export default BookmarkButton;
