import { PAGES, usePageTrackingWithTitle } from '@amgen/shared';
import React, { useState } from 'react';
import { CloseButton, Modal } from 'react-bootstrap';

import './new-request-modal.scss';

import ConfirmModal from '../../confirm-modal/confirm-modal';
import NewRequestForm from '../new-request-form/new-request-form';
import { NavLink, Redirect, Link, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

/* eslint-disable-next-line */
export interface NewRequestModalProps {
  show: boolean;
  onHide: () => void;
  onSuccess?: () => void;
}

export const NewRequestModal = (props: NewRequestModalProps) => {
  const [show, setShow] = useState(false);
  const [enabledNavigation, setEnabledNavigation] = useState(false);

  const { path } = useRouteMatch();
  const url = path.split('/');
  const link = `${url[0]}/data-requests/existing-sites`;

  usePageTrackingWithTitle(PAGES.NEW_REQUEST);

  const handleClick = () => {
    setEnabledNavigation(true);
    setShow(true);
  };

  return (
    <>
      <Modal show={props.show} onHide={() => setShow(true)} scrollable size="xl" centered restoreFocus={false}>
        <Modal.Header className="flex-column border-primary px-4" style={{ backgroundColor: '#f4f8fc' }}>
          <Modal.Title as="h1" className="text-primary">
            New Content Request
          </Modal.Title>
          <CloseButton
            className="mr-2 my-auto position-absolute text-primary new-content-req-close-btn"
            onClick={() => setShow(true)}
            style={{ right: 0 }}
          />
          <p className="text-muted mb-0">
            Please fill the form to raise a request for adding content from the following sources and to view onboarded
            sites please{' '}
            <a style={{ color: 'blue' }} onClick={handleClick}>
              click here
            </a>
          </p>
        </Modal.Header>

        <Modal.Body className="p-4" style={{ backgroundColor: '#f4f8fc' }}>
          <NewRequestForm
            onCancel={() => setShow(true)}
            onSubmit={() => {
              props.onHide();
              props.onSuccess?.();
            }}
          />
        </Modal.Body>
      </Modal>
      <ConfirmModal
        message="All request details will be lost. Are you sure you want to cancel ?"
        show={show}
        setShow={setShow}
        onConfirm={props.onHide}
        enabledNavigation={enabledNavigation}
      />
    </>
  );
};

export default NewRequestModal;
