import { useGetTemporaryS3FileAccessLazyQuery } from '@amgen/api';
import { useWindowSizeListener } from '@amgen/shared';
import { downloadFile } from '@amgen/utils';
import { faDownload, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { Button } from 'react-bootstrap';

import './attachment-download-button.scss';

export interface AttachmentDownloadButtonProps {
  url: string;
  fileName?: string;
  isHideFileName?: boolean;
  buttonText?: string;
  className?: string;
  isAssetDetail?: boolean;
  isOnImageView?: boolean;
}

export const AttachmentDownloadButton: React.FC<AttachmentDownloadButtonProps> = props => {
  const [getPreSignedURL, { data }] = useGetTemporaryS3FileAccessLazyQuery({
    onCompleted: () => {
      const fileAccess = data?.getTemporaryS3FileAccess?.[0];
      if (fileAccess?.status?.toLowerCase() === 'success') {
        downloadFile(fileAccess.preSignedUrl!, props.fileName);
      }
    },
    fetchPolicy: 'network-only',
  });

  const parts = props.url?.split('/');
  const fileName = parts[parts?.length - 1];
  const isMobile = useWindowSizeListener();

  return (
    <Button
      variant={props.buttonText ? 'primary' : 'link'}
      //size={isMobile ? 'sm' : 'sm'}
      className={classNames(props.className, {
        'w-100': props.isHideFileName && !props.buttonText && !props.isOnImageView,
        'px-0 text-justify': !props.isHideFileName,
      })}
      // [todo] remove undefined
      // size={props.buttonText ? 'sm' : undefined}
      onClick={() => getPreSignedURL({ variables: { paths: [props.url] } })}
      title={props.isHideFileName && !props.isOnImageView ? '' : props.isOnImageView ? fileName : ''}
    >
      <span className="mr-3">{props.buttonText}</span>
      {!props.isOnImageView && <FontAwesomeIcon icon={faFileDownload} className={props.isAssetDetail ? 'mt-1' : ''} />}

      {props.isHideFileName ? null : props.isOnImageView ? (
        <span className="pl-2">Download</span>
      ) : (
        <span className="pl-2">{fileName}</span>
      )}
      {props.isOnImageView && <FontAwesomeIcon icon={faDownload} className={'mt-1 mx-1'} />}
    </Button>
    // ) : (
    //   <FontAwesomeIcon
    //     icon={faFileDownload}
    //     className={props.isAssetDetail ? 'mt-1' : ''}
    //     onClick={() => getPreSignedURL({ variables: { paths: [props.url] } })}
    //   />
  );
};

export default AttachmentDownloadButton;
