import * as Types from '@amgen/core';

import { IDataAssetForSearchObjectsFragment } from './search';
import { gql } from '@apollo/client';
import { DataAssetForSearchObjectsFragmentDoc } from './search';
import * as Apollo from '@apollo/client';
export type IGetSearchObjectsQueryVariables = Types.Exact<{
  applicationName?: Types.Maybe<Types.ApplicationName>;
  query: Types.Scalars['String'];
  filters?: Types.Maybe<Array<Types.Maybe<Types.Scalars['String']>> | Types.Maybe<Types.Scalars['String']>>;
  count?: Types.Maybe<Types.Scalars['Int']>;
  sort?: Types.Maybe<Types.Scalars['String']>;
  order?: Types.Maybe<Types.Direction>;
  solrHandler?: Types.Maybe<Types.Scalars['String']>;
}>;

export type IGetSearchObjectsQuery = {
  getSearchObjects?: Types.Maybe<
    Pick<Types.IObjectDataAssetCollection, 'resultCount' | 'QTime' | 'bertScore' | 'isResponseFromKeyword'> & {
      docs?: Types.Maybe<Array<Types.Maybe<IDataAssetForSearchObjectsFragment>>>;
    }
  >;
};

export type IGetSearchObjectsAppsQuery = {
  getSearchObjectsApps?: Types.Maybe<
    Pick<Types.IObjectDataAssetCollection, 'resultCount' | 'QTime' | 'bertScore' | 'isResponseFromKeyword'> & {
      docs?: Types.Maybe<Array<Types.Maybe<IDataAssetForSearchObjectsFragment>>>;
    }
  >;
};

export const GetSearchObjectsDocument = gql`
  query GetSearchObjects(
    $applicationName: ApplicationName = EEA
    $query: String!
    $filters: [String]
    $count: Int
    $sort: String
    $order: Direction
    $solrHandler: String
  ) {
    getSearchObjects(
      applicationName: $applicationName
      query: $query
      filters: $filters
      count: $count
      sort: $sort
      order: $order
      solrHandler: $solrHandler
    ) {
      resultCount
      QTime
      bertScore
      isResponseFromKeyword
      docs {
        ...DataAssetForSearchObjects
      }
    }
  }
  ${DataAssetForSearchObjectsFragmentDoc}
`;
export function useGetSearchObjectsQuery(
  baseOptions: Apollo.QueryHookOptions<IGetSearchObjectsQuery, IGetSearchObjectsQueryVariables>
) {
  return Apollo.useQuery<IGetSearchObjectsQuery, IGetSearchObjectsQueryVariables>(
    GetSearchObjectsDocument,
    baseOptions
  );
}
export function useGetSearchObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetSearchObjectsQuery, IGetSearchObjectsQueryVariables>
) {
  return Apollo.useLazyQuery<IGetSearchObjectsQuery, IGetSearchObjectsQueryVariables>(
    GetSearchObjectsDocument,
    baseOptions
  );
}
export type GetSearchObjectsQueryHookResult = ReturnType<typeof useGetSearchObjectsQuery>;
export type GetSearchObjectsLazyQueryHookResult = ReturnType<typeof useGetSearchObjectsLazyQuery>;
export type GetSearchObjectsQueryResult = Apollo.QueryResult<IGetSearchObjectsQuery, IGetSearchObjectsQueryVariables>;

export const GetSearchObjectsAppsDocument = gql`
  query GetSearchObjectsApps(
    $applicationName: ApplicationName = EEA
    $query: String!
    $filters: [String]
    $count: Int
    $sort: String
    $order: Direction
    $solrHandler: String
  ) {
    getSearchObjectsApps(
      applicationName: $applicationName
      query: $query
      filters: $filters
      count: $count
      sort: $sort
      order: $order
      solrHandler: $solrHandler
    ) {
      resultCount
      QTime
      bertScore
      isResponseFromKeyword
      docs {
        ...DataAssetForSearchObjects
        __typename
      }
      __typename
    }
  }
  ${DataAssetForSearchObjectsFragmentDoc}
`;
export function useGetSearchObjectsAppsQuery(
  baseOptions: Apollo.QueryHookOptions<IGetSearchObjectsAppsQuery, IGetSearchObjectsQueryVariables>
) {
  return Apollo.useQuery<IGetSearchObjectsAppsQuery, IGetSearchObjectsQueryVariables>(
    GetSearchObjectsAppsDocument,
    baseOptions
  );
}
export function useGetSearchObjectsAppsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetSearchObjectsAppsQuery, IGetSearchObjectsQueryVariables>
) {
  return Apollo.useLazyQuery<IGetSearchObjectsAppsQuery, IGetSearchObjectsQueryVariables>(
    GetSearchObjectsAppsDocument,
    baseOptions
  );
}
export type GetSearchObjectsAppsQueryHookResult = ReturnType<typeof useGetSearchObjectsAppsQuery>;
export type GetSearchObjectsAppsLazyQueryHookResult = ReturnType<typeof useGetSearchObjectsAppsLazyQuery>;
export type GetSearchObjectsAppsQueryResult = Apollo.QueryResult<
  IGetSearchObjectsAppsQuery,
  IGetSearchObjectsQueryVariables
>;
