import { TEXT_SHOW_LIMIT } from '@amgen/utils';
import React, { useState } from 'react';

import './text-more-less.scss';

export interface TextMoreLessProps {
  text: string;
  showMore?: boolean;
}

export const TextMoreLess: React.FC<TextMoreLessProps> = ({ text, showMore = false }) => {
  const [showMoreText, setShowMoreText] = useState(showMore);

  if (text.length < TEXT_SHOW_LIMIT) {
    return <span title={text}>{text}</span>;
  } else if (!showMoreText) {
    return (
      <span>
        {`${text.slice(0, TEXT_SHOW_LIMIT)}...`}
        <span className="btn-link cursor-pointer" onClick={() => setShowMoreText(true)}>
          Show more
        </span>
      </span>
    );
  } else {
    return (
      <span>
        {text}
        <span className="btn-link cursor-pointer" onClick={() => setShowMoreText(false)}>
          ..Show less
        </span>
      </span>
    );
  }
};

export default TextMoreLess;
