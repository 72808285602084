import { IDestinationsData } from '@amgen/core';

export type IDestionationFields = keyof IDestinationsData;

export interface ISuggestedAppsExportRequestFormValue {
  fields: IDestionationFields[];
}

export const SUGGESTED_APPS_FIELDS: IDestionationFields[] = [
  'id',
  'title',
  'content',
  'createdOn',
  'createdBy',
  'url',
  'requesterId',
  'tags',
];

export const CRAWL_FIELD_NAMES: Record<IDestionationFields, string> = {
  id: 'ID',
  title: 'Title',
  content: 'Description',
  createdOn: 'Requested Date',
  createdBy: 'Created By',
  url: 'Destination URL',
  requesterId: 'Requestor ID',
  thumbnailLarge: 'Image URL',
  applicationType: 'Application Type',
  ownwerId: 'Owner ID',
  users_and_groups_allowed: 'User and Gropus Allowed',
  contentSource: 'Content Source',
  objectName: 'Object Name',
  previewImages: 'Preview Images',
  docLastModified: 'Last Modified Date',
  lastModifiedBy: 'Last Modified By',
  tags: 'Tags',
};

export function validate(values: ISuggestedAppsExportRequestFormValue) {
  const errors: { [key in keyof ISuggestedAppsExportRequestFormValue]?: string } = {};

  if (values.fields.length === 0) {
    errors.fields = 'You must select at least one field';
  }

  return errors;
}
