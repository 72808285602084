import { useAddRoadmapFeatureCommentMutation } from '@amgen/api';
import { Avatar } from '@amgen/components';
import { IUserInfo } from '@amgen/core';
import { MatomoContext, ROADMAP_ACTIONS, SEARCH_APP_FEATURE_LIST } from '@amgen/shared';
import format from 'date-fns/format';
import React, { useContext, useState } from 'react';
import { Button, Col, Form, Spinner } from 'react-bootstrap';

import './add-comment.module.scss';

/* eslint-disable-next-line */
export interface AddCommentProps {
  featureId: string;
  onPost: () => void;
  user?: IUserInfo;
  startDate: string;
  endDate: string;
  featureName: string;
  themeName: string;
}

export function AddComment({ featureId, onPost, user, startDate, endDate, featureName, themeName }: AddCommentProps) {
  const [comment, setComment] = useState('');
  const { trackApplicationFeature } = useContext(MatomoContext);

  const [addComment, { loading }] = useAddRoadmapFeatureCommentMutation();

  return (
    <Col xs="12" className="d-flex mt-2 justify-content-between align-items-center">
      <Avatar userName={user?.username} className="text-white comment-avatar mr-2" />
      <Form.Group className="flex-fill mb-0 mx-2">
        <Form.Control
          type="text"
          as="textarea"
          rows={1}
          className="thin-scrollbar"
          placeholder="Add a Comment"
          value={comment}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setComment(e.target.value)}
          maxLength={500}
          isValid={comment.length > 0 && comment.length <= 500 && comment.trim() !== ''}
        />
        <Form.Control.Feedback type="valid" className="text-left text-black-50">{`Characters left: ${
          500 - comment.length
        }`}</Form.Control.Feedback>
      </Form.Group>
      <Button
        disabled={loading || !comment || comment.trim() === ''}
        onClick={() => {
          if (comment) {
            setComment('');

            trackApplicationFeature(SEARCH_APP_FEATURE_LIST.ROADMAP, ROADMAP_ACTIONS.COMMENT);
            addComment({
              variables: {
                comment,
                startDate: format(new Date(startDate), 'MMM dd, yyyy'),
                endDate: format(new Date(endDate), 'MMM dd, yyyy'),
                themeName: themeName,
                featureName: featureName,
                parentId: featureId,
              },
            })
              .then(onPost)
              .catch(e => console.log(e));
          }
        }}
        variant="primary"
        className="px-2 py-1"
      >
        Post {loading ? <Spinner animation="border" size="sm" className="ml-1" /> : null}
      </Button>
    </Col>
  );
}

export default AddComment;
