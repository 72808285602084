import { IProduct } from '@amgen/core';
import { ClinicalContext, FacetsPanelContext } from '@amgen/shared';
import React, { useContext, useEffect, useState } from 'react';

import './knowledge-panel-info.scss';

import { KnowledgePanelOption } from '../../config/knowledge-panel-tabs';
import { useKnowledgePanelComponents } from '../../hooks';
import KnowledgePanelAsset from '../knowledge-panel-asset/knowledge-panel-asset';
import KnowledgePanelTabSwitch from '../knowledge-panel-tab-switch/knowledge-panel-tab-switch';

export interface KnowledgePanelInfoProps {
  product: IProduct;
  onToggleGraph: () => void;
}

export const KnowledgePanelInfo: React.FC<KnowledgePanelInfoProps> = ({ product, onToggleGraph }) => {
  const { clinicalType } = useContext(ClinicalContext);
  const { setIsExpandButtonClicked } = useContext(FacetsPanelContext);
  const [activeOption, setActiveOption] = useState(KnowledgePanelOption.OVERVIEW);
  const KnowledgePanelItems = useKnowledgePanelComponents(activeOption);
  const [expandPanel, setExpandPanel] = useState(false);

  const handleExpandButtonClicked = () => {
    setExpandPanel?.(!expandPanel);
    setIsExpandButtonClicked(!expandPanel);
  };

  useEffect(() => {
    if (clinicalType === 'study') {
      setActiveOption(KnowledgePanelOption.STUDIES);
      sessionStorage.setItem('activeKGTab', KnowledgePanelOption.STUDIES);
    } else {
      setActiveOption(KnowledgePanelOption.OVERVIEW);
      sessionStorage.setItem('activeKGTab', KnowledgePanelOption.OVERVIEW);
    }
  }, [clinicalType]);

  return (
    <div>
      <KnowledgePanelAsset>
        <>
          <KnowledgePanelAsset.Header>
            {product.imageUrl ? (
              <KnowledgePanelAsset.Image image={product.imageUrl} />
            ) : (
              <KnowledgePanelAsset.Title title={product.product} />
            )}
            {/* <KnowledgePanelAsset.Icon onToggleGraph={onToggleGraph} /> */}
            {/* <KnowledgePanelAsset.Expand
              handleExpandButtonClicked={handleExpandButtonClicked}
              expandPanel={expandPanel}
            /> */}
          </KnowledgePanelAsset.Header>
          <KnowledgePanelAsset.Body>
            <KnowledgePanelTabSwitch
              activeOption={activeOption}
              setActiveOption={tab => {
                setActiveOption(tab);
                sessionStorage.setItem('activeKGTab', tab);
              }}
            />
            <KnowledgePanelItems />
          </KnowledgePanelAsset.Body>
        </>
      </KnowledgePanelAsset>
    </div>
  );
};

export default KnowledgePanelInfo;
