import { useWindowSizeListener } from '@amgen/shared';
import React from 'react';

import './sources-metric.module.scss';

import { useContentSourceMetrics } from '../../hooks/content-source';

/* eslint-disable-next-line */
export interface SourcesMetricProps {}

export function SourcesMetric(props: SourcesMetricProps) {
  const { loading, countMap, totalDocs } = useContentSourceMetrics();
  const abTestingGroup = JSON.parse(sessionStorage.getItem('abTestingGroup') as string);
  const t = totalDocs
    ? new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 1,
        notation: 'compact',
        compactDisplay: 'short',
      }).format(totalDocs)
    : 0;
  const isMobile = useWindowSizeListener();
  return !loading && t ? (
    <span
      className={isMobile ? 'text-banner-green text-center pb-2' : 'text-banner-green text-center '}
      title="Total document count"
    >
      {abTestingGroup ? (
        <>
          Over <b>2.5M</b> documents from <b>4</b> sources.
        </>
      ) : (
        <>
          Over <b>{t}</b> documents from <b>{countMap.size}</b> sources.
        </>
      )}
    </span>
  ) : null;
}

export default SourcesMetric;
