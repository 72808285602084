import React from 'react';
import { Button, Spinner } from 'react-bootstrap';

import './delete-confirm-panel.scss';

export interface DeleteConfirmPanelProps {
  onCancel: () => void;
  onDelete: () => void;
  message: string;
  isLoading: boolean;
  actionMessage?: string;
  variant?: 'danger' | 'primary';
}

export const DeleteConfirmPanel: React.FC<DeleteConfirmPanelProps> = props => {
  // TODO: Add transition

  return (
    <div className="bg-grey p-2 rounded">
      <h3>{props.message}</h3>

      <Button
        variant={props.variant || 'danger'}
        className="px-5 mr-2"
        onClick={props.onDelete}
        disabled={props.isLoading}
      >
        {props.isLoading ? (
          <Spinner animation="border" size="sm" />
        ) : props.actionMessage ? (
          props.actionMessage
        ) : (
          'Delete'
        )}
      </Button>

      <Button variant="outline-primary" className="px-5" onClick={props.onCancel} disabled={props.isLoading}>
        Cancel
      </Button>
    </div>
  );
};

export default DeleteConfirmPanel;
