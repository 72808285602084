import { useAddRoadmapFeatureCommentMutation } from '@amgen/api';
import { Avatar, InlineEditable, TimeAgoItem } from '@amgen/components';
import { IRoadmapFeatureComment, IUserInfo } from '@amgen/core';
import { MatomoContext, ROADMAP_ACTIONS, SEARCH_APP_FEATURE_LIST } from '@amgen/shared';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState } from 'react';
import { Col, Spinner } from 'react-bootstrap';

import './comment.scss';

/* eslint-disable-next-line */
export interface CommentProps extends IRoadmapFeatureComment {
  featureId: string;
  onEdit: () => void;
  myUser: IUserInfo;
}

export function Comment(props: CommentProps) {
  const [addComment, { loading }] = useAddRoadmapFeatureCommentMutation();
  const { trackApplicationFeature } = useContext(MatomoContext);

  const editComment = (comment: string) => {
    if (comment) {
      trackApplicationFeature(SEARCH_APP_FEATURE_LIST.ROADMAP, ROADMAP_ACTIONS.EDIT_COMMENT);
      addComment({
        variables: {
          id: props.id,
          comment,
          parentId: props.featureId,
        },
      })
        .then(props.onEdit)
        .catch(error => console.log(error));
    }

    setIsEditOn(false);
  };

  const [isEditOn, setIsEditOn] = useState(false);

  return (
    <Col xs="12" className="d-flex mb-2 ">
      <Avatar userName={props.user?.username} className="text-white flex-shrink-0 comment-avatar mr-2" />
      <div className="comment-message-container p-2 rounded shadow-sm d-flex flex-column align-self-end">
        <div className="d-flex justify-content-between flex-fill">
          <h3 className="text-squid font-weight-bold small mb-0">
            {props.user?.firstName}, {props.user?.lastName}
          </h3>
          {props.user?.username === props.myUser.username && !loading && (
            <FontAwesomeIcon
              icon={faPencilAlt}
              onClick={() => {
                setIsEditOn(true);
              }}
              size="sm"
            />
          )}
          {loading && <Spinner animation="border" size="sm" />}
        </div>
        <small style={{ fontSize: 'small' }} className="text-grey mb-1" title={props.timestamp}>
          {props.timestamp && <TimeAgoItem dateString={props.timestamp} />}
        </small>

        {isEditOn ? (
          <InlineEditable initialValue={props.comment} onCancel={() => setIsEditOn(false)} onSave={editComment} />
        ) : (
          <div className="comment-message small text-squid-light">{props.comment}</div>
        )}
      </div>
    </Col>
  );
}

export default Comment;
