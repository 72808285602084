import { ApplicationName } from '@amgen/core';
import {
  AISERA_CHATBOT_ACTIONS,
  AppContext,
  FacetContext,
  HEADER_TAB_ACTIONS,
  MatomoContext,
  SEARCH_APP_FEATURE_LIST,
} from '@amgen/shared';
import React, { useContext, useEffect, useState } from 'react';
import { Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';

import './sub-header-navigation.scss';

import { ReactComponent as AllResultSVG } from '../../../../../../apps/eea-search/src/assets/images/all-result.svg';
import { ReactComponent as ChatSVG } from '../../../../../../apps/eea-search/src/assets/images/chat.svg';
import { ReactComponent as RecommendedSVG } from '../../../../../../apps/eea-search/src/assets/images/recommended.svg';
import { TABS } from './config/tabs';

/* eslint-disable-next-line */
export interface SubHeaderNavigationProps {}

export const SubHeaderNavigation = (props: SubHeaderNavigationProps) => {
  const {
    setSelectedResultTab,
    selectedResultTab,
    isBottomButtonClicked,
    setBottomButtonClicked,
    isCustomSourceActive,
    selectedCustomSourceName,
    searchQuery,
    activeId,
    setActiveId,
    ischatbotIntentAvailable,
  } = useContext(AppContext);
  const { isCustomFilterSearch } = useContext(FacetContext);
  const { trackApplicationFeature } = useContext(MatomoContext);
  const [imageSrc, setImageSrc] = useState();
  const [navTabs, setNavTabs] = useState(TABS);
  const appName: ApplicationName = sessionStorage.getItem('appName')
    ? ApplicationName[sessionStorage.getItem('appName')!]
    : ApplicationName.EEA;
  const sessionChatbot = localStorage.getItem('PiwikAbTesting') || 'PiwikAbTesting';

  // Default Selection From landing page.
  useEffect(() => {
    if (isCustomFilterSearch) {
      setSelectedResultTab('All Results');
      setBottomButtonClicked(false);
      setActiveId(1);
    } else {
      setSelectedResultTab('Recommended');
      setBottomButtonClicked(false);
      setActiveId(0);
    }
  }, []);

  useEffect(() => {
    if (isCustomSourceActive && selectedCustomSourceName === 'Contract Search') {
      setSelectedResultTab('All Results');
      setBottomButtonClicked(false);
      setActiveId(1);
    } else {
      setSelectedResultTab('Recommended');
      setBottomButtonClicked(false);
      setActiveId(0);
    }
  }, [searchQuery]);

  //Contract search selection
  useEffect(() => {
    const chatTabActive =
      sessionChatbot === 'PiwikAbTesting' ? 'PiwikAbTesting' : JSON.parse(sessionChatbot)['variation:chatbot'];
    if (isCustomSourceActive && selectedCustomSourceName === 'Contract Search') {
      const tabs = navTabs.filter(item => {
        return item.id !== 0;
      });
      setNavTabs(tabs);
    } else {
      if (appName === 'OpsKM' || appName === 'Catalyst' || appName === 'DVIEW' || chatTabActive === 'original') {
        const tabs = navTabs.filter(item => {
          return item.id !== 2;
        });
        setNavTabs(tabs);
      } else {
        setNavTabs(TABS);
      }
    }
  }, [isCustomSourceActive, selectedCustomSourceName]);

  // Bottom button selection.
  useEffect(() => {
    if (isBottomButtonClicked) {
      setSelectedResultTab('All Results');
      setActiveId(1);
      window.scrollTo(0, 0);
      trackApplicationFeature(SEARCH_APP_FEATURE_LIST.HEADER_TAB_CLICKS, HEADER_TAB_ACTIONS.ALL_RESULTS_TAB);
    }
  }, [isBottomButtonClicked]);

  // Tab Switching mechanism.
  const selectedTabClicked = (id, title) => {
    setActiveId(id);
    setSelectedResultTab(title);
    window.scrollTo(0, 0);
    setBottomButtonClicked(false);
    if (id === 0) {
      trackApplicationFeature(SEARCH_APP_FEATURE_LIST.HEADER_TAB_CLICKS, HEADER_TAB_ACTIONS.RECOMMENDED_TAB);
    }
    if (id === 1) {
      trackApplicationFeature(SEARCH_APP_FEATURE_LIST.HEADER_TAB_CLICKS, HEADER_TAB_ACTIONS.ALL_RESULTS_TAB);
    }
    if (id === 2) {
      if (ischatbotIntentAvailable === true) {
        trackApplicationFeature(
          SEARCH_APP_FEATURE_LIST.AISERA_CHATBOT_INTERACTION_WITH_INTENT,
          AISERA_CHATBOT_ACTIONS.CHAT_TAB_CLICKED
        );
      } else if (ischatbotIntentAvailable === false) {
        trackApplicationFeature(
          SEARCH_APP_FEATURE_LIST.AISERA_CHATBOT_INTERACTION_WITHOUT_INTENT,
          AISERA_CHATBOT_ACTIONS.CHAT_TAB_CLICKED
        );
      }
    }
  };

  const getSvgIcons = id => {
    switch (id) {
      case 0:
        return <RecommendedSVG className={activeId === 0 ? 'active' : ''} />;
      case 1:
        return <AllResultSVG className={activeId === 1 ? 'active' : ''} />;
      case 2:
        return <ChatSVG className={activeId === 2 ? 'active' : ''} />;

      default:
        break;
    }
  };

  return (
    <div className="navigation-tab mx-auto">
      <div className="d-flex justify-content-between">
        <Nav className="sub-header-navbar align-items-center justify-content-start">
          {navTabs.map(tab => (
            <Nav.Item
              className={activeId === tab.id ? 'active text-center cursor-pointer' : 'text-center cursor-pointer'}
              key={tab.title}
              onClick={() => selectedTabClicked(tab.id, tab.title)}
            >
              {tab.id === 2 ? (
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip id="chat-tooltip">
                      I'm the Amgen Virtual Assistant. Use the Search bar to ask me questions on MAP, Travel/Expense,
                      Meetings, Learning, Workday/Time/Absence, Benefits, Sourcing/Purchasing, and Talent Acquisition
                    </Tooltip>
                  }
                >
                  <span className="d-inline-block">
                    <span
                      className={'nav-link align-items-center d-flex justify-content-center'}
                      style={{ pointerEvents: 'none' }}
                    >
                      {getSvgIcons(tab.id)}
                      <span className={tab.id === 2 ? 'pl-2 chat-text-rainbow-animation' : 'pl-2'}>{tab.title}</span>
                      {/* <div>
                        {tab.id === 2 && (
                          <img
                            style={{ pointerEvents: 'none' }}
                            className="new-icon"
                            alt=""
                            src="assets/images/new-animated.gif"
                          ></img>
                        )}
                      </div> */}
                    </span>
                  </span>
                </OverlayTrigger>
              ) : (
                <span
                  className={'nav-link align-items-center d-flex justify-content-center'}
                  style={{ pointerEvents: 'none' }}
                >
                  {getSvgIcons(tab.id)}
                  <span className="pl-2">{tab.title}</span>
                  <div>
                    {tab.id === 2 && (
                      <img
                        style={{ pointerEvents: 'none' }}
                        className="pl-2 chat-new-icon"
                        src={`assets/images/new.svg`}
                        alt=""
                      />
                    )}
                  </div>
                </span>
              )}
            </Nav.Item>
          ))}
        </Nav>
      </div>
    </div>
  );
};

export default SubHeaderNavigation;
