import { ResultViewType } from '@amgen/shared';
import { useRef } from 'react';

import CardSearchResults from './components/card-search-results/card-search-results';
import TableSearchResults from './components/table-search-results/table-search-results';
import { IResultViewProps } from './models';

export function useResultView(viewType: ResultViewType) {
  const { current: resultViewMap } = useRef(
    new Map<ResultViewType, React.FC<IResultViewProps>>([
      [ResultViewType.List, CardSearchResults],
      [ResultViewType.Table, TableSearchResults],
    ])
  );

  return resultViewMap.get(viewType) ?? CardSearchResults;
}
