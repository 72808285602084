import React, { useEffect } from 'react';
import { Row } from 'react-bootstrap';

import './setting.scss';

import { SettingActionFooter, SettingActionFooterProps } from './setting-action-footer/setting-action-footer';
import { SettingBody, SettingBodyProps } from './setting-body/setting-body';
import { SettingHeader, SettingHeaderProps } from './setting-header/setting-header';

/* eslint-disable-next-line */
export interface ISettingsContext {}

export const SettingsContext = React.createContext<ISettingsContext | undefined>(undefined);

interface ISettingsComposition {
  Header: React.FC<SettingHeaderProps>;
  Body: React.FC<SettingBodyProps>;
  Footer: React.FC<SettingActionFooterProps>;
}

export const Setting: React.FC & ISettingsComposition = props => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <SettingsContext.Provider value={{}}>
      <Row className=" setting-container">{props.children}</Row>
    </SettingsContext.Provider>
  );
};

Setting.Header = SettingHeader;
Setting.Body = SettingBody;
Setting.Footer = SettingActionFooter;

export default { Setting };
