import { IAsset } from '@amgen/core';
import React, { useMemo } from 'react';

import './asset-container.scss';

import { AssetContext, IAssetState } from '../asset/asset-context/asset-context';

export interface AssetContainerProps {
  className?: string;
  docSolrAsset: IAsset;
  isBookmarksDisabled: boolean;
}

export const AssetContainer: React.FC<AssetContainerProps> = ({ children, docSolrAsset, isBookmarksDisabled }) => {
  const providedValue = useMemo<IAssetState>(
    () => ({
      docSolrAsset,
      isBookmarksDisabled,
    }),
    [docSolrAsset, isBookmarksDisabled]
  );

  return <AssetContext.Provider value={providedValue}>{children}</AssetContext.Provider>;
};

export default AssetContainer;
