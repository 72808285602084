import { Slider } from '@amgen/components';
import { FacetsPanel } from '@amgen/feature-facets';
import { AppContext, SearchContext, useWindowSizeListener } from '@amgen/shared';
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useContext } from 'react';
import { Col } from 'react-bootstrap';

import './search-facet-container.scss';

/* eslint-disable-next-line */
export interface SearchFacetContainerProps {
  togglePanel?: () => void;
}

export const ViewFilterTab = () => {
  const { expandPanel, setExpandPanel } = useContext(SearchContext);

  const isMobile = useWindowSizeListener();

  return !expandPanel && !isMobile ? (
    <Col className="flex-grow-0 vertical-filter-expander text-center">
      <span
        className="bg-grey cursor-pointer p-2 py-4 btn-link font-weight-bold"
        onClick={() => setExpandPanel?.(!expandPanel)}
        title="Expand (Alt + q)"
      >
        View Filter Panel
        <FontAwesomeIcon icon={faAngleDoubleLeft} className="mt-2 icon-animation" />
      </span>
    </Col>
  ) : null;
};

export const SearchFacetContainer: React.FC<SearchFacetContainerProps> = props => {
  const { isSliderShown, setIsSliderShown, isMobile } = useContext(SearchContext);
  const { expandPanel, setExpandPanel } = useContext(SearchContext);
  const { selectedResultTab } = useContext(AppContext);

  // NOTE: FacetPanel has been duplicated intentionally. Extracting it in a function or variable would create a new
  // instance of this component on every render.

  return (
    <>
      {selectedResultTab !== 'Chat' && (
        <>
          <Col
            xs="12"
            // md="3"
            md="2"
            // className={classNames('d-none search-facet-container pl-4 pt-3', {
            className={classNames(
              'search-facet-container pl-3 pt-2',
              expandPanel && selectedResultTab == 'All Results' ? 'visible' : 'invisible',
              {
                fade: !expandPanel,
                'd-md-block': expandPanel,
              }
            )}
          >
            {isMobile ? (
              <Slider
                header={<span className="mx-3 mt-4 d-block">Filter By: </span>}
                show={isSliderShown}
                position="right"
                variant="light"
                onToggle={isShown => setIsSliderShown(isShown)}
              >
                <div className="mx-3">{selectedResultTab == 'All Results' && <FacetsPanel isMobile={isMobile} />}</div>
              </Slider>
            ) : (
              // <FacetsPanel {...props} />
              <>{selectedResultTab == 'All Results' && <FacetsPanel {...props} />}</>
            )}
          </Col>
          {/* <ViewFilterTab /> */}
        </>
      )}
      {''}
    </>
  );
};

export default SearchFacetContainer;
