import { EnvironmentContext } from '@amgen/core';
import { AppContext, FacetContext, PreferenceContext } from '@amgen/shared';
import React, { useContext, useEffect, useState } from 'react';
import { Button, ButtonGroup, Col, Dropdown, DropdownButton } from 'react-bootstrap';

import './quick-source-filter.scss';

import { SearchBoxContext } from '../search-box-context-provider/search-box-context';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface QuickSourceFilterProps {}

export function QuickSourceFilter(props: QuickSourceFilterProps) {
  const { selectedDefaultDataSources } = useContext(PreferenceContext);
  const { setCustomFilterSearch } = useContext(FacetContext);
  const {
    setCustomSourceActive,
    isCustomSourceActive,
    setSelectedCustomSourceName,
    selectedCustomSourceName,
    setCurrentCustomsources,
    currentCustomsources,
    user,
  } = useContext(AppContext);
  const envContext = useContext(EnvironmentContext);
  const usersFavorites = user.firstName ? `${user.firstName}'s Favorites` : 'Favorites';
  const [isFavSourcesSearch, setIsFavSourcesSearch] = useState(false);
  const [selectedDataSources, setSelectedDataSources] = useState(selectedDefaultDataSources);
  const abTestingGroup = JSON.parse(sessionStorage.getItem('abTestingGroup') as string);
  const [customFilterName, setCustomFilterName] = useState('');
  const OpsKMCustomGroup = { 'OpsKM Search': ['0/Ops KM Assets', '0/CDOCS'] };
  const LegalCustomGroup = {
    'Contract Search': ['1/SharePoint/LCR', '1/SharePoint/ILGDaW', '1/SharePoint/ILGHQContracts'],
  };

  const customGroups = selectedDataSources
    .filter(item => item.values !== null)
    .map(item => {
      if (item.group) {
        const obj = {};
        obj[item.group] = item.values;
        return obj;
      } else {
        const obj = {};
        obj[usersFavorites] = item.values;
        return obj;
      }
    });
  if (sessionStorage.getItem('employeeType') === 'FTE') {
    customGroups.push(OpsKMCustomGroup);
    customGroups.push(LegalCustomGroup);
  }
  const {
    selectedTypeaheadFilters,
    setSelectedTypeaheadFilters,
    setSelectedTypeaheadFilterCategories,
    selectedTypeaheadFilterCategories,
  } = useContext(SearchBoxContext);

  useEffect(() => {
    setFavoriteFilters(isFavSourcesSearch);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setFavoriteFilters = (isFav: boolean, values?: [], isFavorite?: boolean) => {
    const favoriteDataSourcesValue = values || [];
    const s = isFavorite ? favoriteDataSourcesValue.map(v => `0/${v}`) : favoriteDataSourcesValue;
    if (isFav) {
      setSelectedTypeaheadFilters(selectedTypeaheadFilters.removeAll('doc_nav_prefix').add(s, 'doc_nav_prefix'));
      if (!selectedTypeaheadFilterCategories.has('doc_nav_prefix')) {
        setSelectedTypeaheadFilterCategories(new Set([...selectedTypeaheadFilterCategories, 'doc_nav_prefix']));
      }
      setCustomFilterSearch?.(true);
    }
  };

  const handleSelect = (flag: boolean, values?: [], isFavorite?: boolean, groupName?: string) => {
    setSelectedTypeaheadFilters(selectedTypeaheadFilters.removeAll('doc_nav_prefix'));
    setSelectedCustomSourceName(groupName || 'Custom sources');
    setCustomSourceActive(true);
    if (values) {
      setCurrentCustomsources?.(values);
    }
    if (!flag) {
      setCustomFilterName('');
      setCustomSourceActive(false);
      setSelectedCustomSourceName('Custom Sources');
      setCurrentCustomsources?.([]);
    }
    setIsFavSourcesSearch(flag);
    setFavoriteFilters(flag, values, isFavorite);
    sessionStorage.setItem('current-source-selected', groupName || '');
    if (groupName === 'Contract Search') {
      const initialGraphAPIUrl = envContext?.envGraphqlUrl?.match(/(.*\/graphql)/)?.[1];
      envContext?.setEnvGraphqlUrl(initialGraphAPIUrl);
    }
  };

  return (
    <Col xs="12" className="align-items-center d-flex justify-content-center p-lg-2">
      <ButtonGroup size="sm" className="button-group-width">
        <Button
          className="quick-source-filter-btn"
          variant="outline-primary"
          active={!isFavSourcesSearch}
          onClick={() => handleSelect(false)}
        >
          All Sources
        </Button>

        <DropdownButton
          id="customSourcesButton"
          size="sm"
          variant={
            customFilterName !== '' && isFavSourcesSearch
              ? 'primary'
              : customGroups.length > 0
              ? 'outline-primary'
              : 'disabled-dropdown'
          }
          className={
            customFilterName !== '' && isFavSourcesSearch
              ? 'primary'
              : customGroups.length > 0
              ? 'outline-primary'
              : 'disabled-dropdown'
          }
          onSelect={eventKey => {
            setCustomFilterName(eventKey || '');
          }}
          title={
            <span className="mx-2" title={customGroups.length > 0 ? '' : 'No custom sources added'}>
              {customFilterName === '' && !isFavSourcesSearch ? 'Custom Sources' : customFilterName}
            </span>
          }
        >
          {customGroups
            ? customGroups.map(group => {
                const groupName = Object.keys(group)?.[0];
                if (Object.values(group)[0]) {
                  return (
                    <Dropdown.Item
                      as="li"
                      className="cursor-pointer"
                      onClick={() => handleSelect(true, group[groupName], groupName === usersFavorites, groupName)}
                      eventKey={groupName}
                    >
                      <span title={groupName}>{groupName}</span>
                    </Dropdown.Item>
                  );
                }
              })
            : ''}
        </DropdownButton>
      </ButtonGroup>
    </Col>
  );
}

export default QuickSourceFilter;
