import { CUSTOM_GROUP_SOURCES_CATEGORIES, MatomoContext } from '@amgen/shared';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import React, { useContext, useState } from 'react';
import { Row } from 'react-bootstrap';

import './data-source-section-header.scss';

import { ABcategories, categories, LANDING_PAGE_DATA_SOURCE_CATEGORY } from '../models';

export interface DataSourceSectionHeaderProps {
  selectedCategory: LANDING_PAGE_DATA_SOURCE_CATEGORY;
  setSelectedCategory: (value: LANDING_PAGE_DATA_SOURCE_CATEGORY) => void;
}

export const DataSourceSectionHeader: React.FC<DataSourceSectionHeaderProps> = ({
  selectedCategory,
  setSelectedCategory,
}) => {
  const { trackApplicationFeature } = useContext(MatomoContext);
  const abTestingGroup = JSON.parse(sessionStorage.getItem('abTestingGroup') as string);
  const category = abTestingGroup ? ABcategories : categories;

  return (
    <Row
      className={
        abTestingGroup
          ? 'data-source-section-header d-flex flex-row flex-md-row w-100'
          : 'data-source-section-header d-flex flex-row flex-md-row justify-content-between w-100'
      }
      noGutters
    >
      {category.map((category, index) => (
        <div
          key={index}
          className={classnames('section-category font-weight-bold cursor-pointer text-center px-2', {
            active: category.name === selectedCategory,
            alignCategories: abTestingGroup,
          })}
          onClick={() => {
            setSelectedCategory(category.name);
            trackApplicationFeature(CUSTOM_GROUP_SOURCES_CATEGORIES.CONTENT_SOURCE_CATEGORIES, category.name || '');
          }}
        >
          <FontAwesomeIcon icon={category.icon} size={'lg'} title={category.name} className="d-lg-inline mr-2" />
          <span className="d-lg-inline d-none">{category.name}</span>
        </div>
      ))}
    </Row>
  );
};

export default DataSourceSectionHeader;
