import classNames from 'classnames';
import React from 'react';

import './actions.scss';

/* eslint-disable-next-line */
export interface ActionsProps {
  buttonClass?: string;
}

export const Actions: React.FC<ActionsProps> = ({ children, buttonClass }) => {
  return <div className={classNames('d-flex align-items-center', buttonClass)}>{children}</div>;
};

export default Actions;
