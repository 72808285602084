import { Direction, SortField } from '@amgen/core';
import { useLocation } from 'react-router-dom';

import { ResultViewType, URLParams } from '../models';

export function useURLParams() {
  const location = useLocation();
  const params = new URLSearchParams(decodeURIComponent(location.search));
  const sortParam = params.get(URLParams.SORT_OPTION);
  const sortDirection = sortParam && sortParam[0] === '-' ? Direction.DESC : Direction.ASC;
  const sort = sortParam
    ? ((['+', '-'].includes(sortParam[0]) ? sortParam.substring(1).trim() : sortParam.trim()) as SortField)
    : SortField.RELEVANCY;
  const page = parseInt(params.get(URLParams.PAGE) ?? '1');
  const view = params.get(URLParams.VIEW_STYLE) as ResultViewType;

  return { sort, sortDirection, page, view };
}
