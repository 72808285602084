import React, { useState } from 'react';
import { CloseButton, Modal } from 'react-bootstrap';

import './suggested-app-modal.scss';

import ConfirmModal from '../../confirm-modal/confirm-modal';
import SuggestedAppForm from '../suggested-app-form/suggested-app-form';

/* eslint-disable-next-line */
export interface SuggestedAppModalProps {
  show: boolean;
  onHide: () => void;
  onSuccess?: () => void;
  data?: any;
  mode?: string;
  refetch?: any;
}

export function SuggestedAppModal(props: SuggestedAppModalProps) {
  const [show, setShow] = useState(false);

  return (
    <>
      <Modal show={props.show} onHide={() => setShow(true)} scrollable size="xl" centered restoreFocus={false}>
        <Modal.Header className="flex-column border-primary px-4" style={{ backgroundColor: '#f4f8fc' }}>
          <Modal.Title as="h1" className="text-primary">
            {props.mode === 'edit' ? 'Update Suggested Apps/Destination' : 'Add Suggested Apps/Destination'}
          </Modal.Title>
          <CloseButton
            className="mr-2 my-auto position-absolute text-primary suggested-app-close-btn"
            onClick={() => setShow(true)}
            style={{ right: 0 }}
          />
        </Modal.Header>

        <Modal.Body className="p-4" style={{ backgroundColor: '#f4f8fc' }}>
          <SuggestedAppForm
            onCancel={() => setShow(true)}
            data={props.data}
            onSubmit={() => {
              props.refetch();
              props.onHide();
              // props.onSuccess?.();
            }}
            mode={props.mode}
          />
        </Modal.Body>
      </Modal>
      <ConfirmModal
        message="All request details will be lost. Are you sure you want to cancel ?"
        show={show}
        setShow={setShow}
        onConfirm={props.onHide}
      />
    </>
  );
}

export default SuggestedAppModal;
