import { IGD_ACTIONS, MatomoContext, SEARCH_APP_FEATURE_LIST } from '@amgen/shared';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState } from 'react';

import './add-content-button.scss';

import NewRequestModal from '../new-request-modal/new-request-modal';

/* eslint-disable-next-line */
export interface AddContentButtonProps {
  toggleBlockScroll: (show: boolean) => void;
}

export const AddContentButton = (props: AddContentButtonProps) => {
  const [show, setShow] = useState(false);
  const { trackApplicationFeature } = useContext(MatomoContext);

  return (
    <>
      <div
        className="app-card text-muted py-0"
        title="Add Content"
        onClick={() => {
          setShow(true);
          props.toggleBlockScroll(true);
          trackApplicationFeature(SEARCH_APP_FEATURE_LIST.IGD, IGD_ACTIONS.ADD_REQ_FROM_LANDING);
        }}
      >
        <div className="mt-4" />
        <div className="d-flex justify-content-center">
          <FontAwesomeIcon
            icon={faPlus}
            className="app-card-img d-flex justify-content-center p-3 shadow-sm cursor-pointer"
          />
        </div>
        <h2 className="pt-2 title text-blue w-100 f-90">Add Content</h2>
      </div>

      <NewRequestModal
        show={show}
        onHide={() => {
          setShow(false);
          props.toggleBlockScroll(false);
        }}
      />
    </>
  );
};

export default AddContentButton;
