import { FormLabelWithTooltip } from '@amgen/components';
import { useField } from 'formik';
import React from 'react';

import './new-request-form-label.scss';

import { formConfig } from '../../models';

/* eslint-disable-next-line */
export interface NewRequestFormLabelProps {
  name: string;
  label?: string;
  helpText?: string;
}

export const NewRequestFormLabel = (props: NewRequestFormLabelProps) => {
  const [{ value }] = useField('contentSource');
  const label = (formConfig.get(value) as any)?.[props.name]?.label ?? props.label ?? '';

  return <FormLabelWithTooltip label={label} helpText={props.helpText || ''} />;
};

export default NewRequestFormLabel;
