import React from 'react';
import { Col } from 'react-bootstrap';

import './knowledge-panel-header.scss';

/* eslint-disable-next-line */
export interface KnowledgePanelHeaderProps {}

export const KnowledgePanelHeader: React.FC<KnowledgePanelHeaderProps> = ({ children }) => {
  return (
    <Col xs="12" className="d-flex pb-2">
      <Col xs="12" className=" d-flex p-0 align-items-center">
        {' '}
        {children}
      </Col>
    </Col>
  );
};

export default KnowledgePanelHeader;
