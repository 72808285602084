import { getZoom, SearchContext, useWindowSizeListener } from '@amgen/shared';
import React, { useContext } from 'react';
import { Col } from 'react-bootstrap';

import './search-page-results.scss';

import { SearchPageContext } from '../search-page-container/context';

/* eslint-disable-next-line */
export interface SearchPageResultsProps {}

export const SearchPageResults: React.FC<SearchPageResultsProps> = props => {
  const { loading, emptySearchResult } = useContext(SearchPageContext);
  const { expandPanel } = useContext(SearchContext);
  const zoomLevel = getZoom();
  const isMobile = useWindowSizeListener();
  const searchResultsWidth = isMobile
    ? 'col-md-9'
    : zoomLevel < 1.5
    ? expandPanel
      ? 'col-md-9 pl-3 pr-4'
      : ' px-4'
    : expandPanel
    ? 'col-md-9 px-3'
    : 'col-11 pl-4 filter-unexpanded';

  return loading ? (
    <div
      className={
        expandPanel
          ? 'd-flex justify-content-center align-items-center row position-absolute loader-position-search-result-page-with-panel'
          : 'd-flex justify-content-center align-items-center row position-absolute loader-position-search-result-page'
      }
    >
      <div className="dot-flashing"></div>
      <br />
      <br />
      <div className="col-12 text-center text-primary mt-1">Fetching search results...</div>
    </div>
  ) : !emptySearchResult ? (
    // <Col className={` ${searchResultsWidth} search-results-container`}>{props.children}</Col>
    <Col className={'col-md-12 search-results-container'}>{props.children}</Col>
  ) : loading ? (
    <div
      className={
        expandPanel
          ? 'd-flex justify-content-center align-items-center row position-absolute loader-position-search-result-page-with-panel'
          : 'd-flex justify-content-center align-items-center row position-absolute loader-position-search-result-page'
      }
    >
      <div className="dot-flashing"></div>
      <br />
      <br />
      <div className="col-12 text-center text-primary mt-1">Fetching search results...</div>
    </div>
  ) : null;
};

export default SearchPageResults;
