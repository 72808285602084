import { SearchContext } from '@amgen/shared';
import React, { useContext, useEffect, useState } from 'react';

import './generative-ai.scss';

const GenerativeAIQA = () => {
  const { generativeAIData } = useContext(SearchContext);

  const generativeQnAData = generativeAIData && generativeAIData?.generateQA?.filter(i => i?.additionalQA === false);
  return generativeQnAData?.length > 0 ? (
    <div className="mt-3">
      <div className="font-weight-bold fs-14">{generativeQnAData[0]?.question}</div>
      <div className="fs-13 mb-2 gen-ai-answers thin-scrollbar-gray">{generativeQnAData[0]?.answer}</div>
    </div>
  ) : (
    <></>
  );
};

export default GenerativeAIQA;
