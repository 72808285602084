import { useField } from 'formik';
import React, { useState } from 'react';
import { Col, Form } from 'react-bootstrap';

import { NewRequestFormLabel } from '../new-request-form-label/new-request-form-label';

/* eslint-disable-next-line */
export interface DisplayNameInputProps {}

export const DisplayNameInput = (props: DisplayNameInputProps) => {
  const [displayNameField, displayNameMeta, displayNameHelpers] = useField('displayName');
  const [displayName, setDisplayName] = useState('');
  return (
    <Form.Group as={Col} xs="12" md="6" controlId="displayName">
      <NewRequestFormLabel
        name="displayName"
        label="Display Name"
        helpText="Name displayed on the filters and everywhere else it is referenced"
      />
      <Form.Control
        type="string"
        className="thin-scrollbar"
        placeholder="Name displayed on the filters and everywhere else it is referenced"
        onChange={e => {
          displayNameHelpers.setTouched(true);
          displayNameField.onChange(e);
          setDisplayName(e.target.value);
        }}
        maxLength={20}
        isInvalid={displayNameMeta.touched && !!displayNameMeta.error}
        isValid={displayName.length > 0 && displayName.length <= 20 && displayName.trim() !== ''}
      />
      <Form.Control.Feedback type="invalid">{displayNameMeta.error}</Form.Control.Feedback>
      <Form.Control.Feedback type="valid" className="text-left text-black-50">{`Characters left: ${
        20 - displayName.length
      }`}</Form.Control.Feedback>
    </Form.Group>
  );
};

export default DisplayNameInput;
