import { IAsset, IAssetAttribute } from '@amgen/core';
import { AssetAttribute } from '@amgen/feature-asset-attributes';
import { Asset } from '@amgen/feature-search';
import { AppContext, AssetContextProvider, FacetContext } from '@amgen/shared';
import React, { useContext } from 'react';

import './table-search-result-row.scss';

export interface TableSearchResultRowProps {
  key: string;
  docPosition: number;
  docSolrAsset: IAsset;
  selectedColumns: Set<IAssetAttribute>;
  isBookmarksDisabled?: boolean;
  hideRatingBtn?: boolean;
}

export const TableSearchResultRow: React.FC<TableSearchResultRowProps> = ({
  docSolrAsset,
  selectedColumns,
  isBookmarksDisabled,
  hideRatingBtn,
  docPosition,
}) => {
  const { setPageNo } = useContext(AppContext);
  const { appliedFilters, setAppliedFilters, addFilterCategory, clickableAttributes } = useContext(FacetContext);

  const handleApplyFilter = (value: string, attribute: IAssetAttribute) => {
    addFilterCategory(attribute);
    setAppliedFilters(appliedFilters.add(value, attribute));
    setPageNo(1);
  };
  const legalColumns = ['contract_parties', 'contract_title', 'contract_effective_date', 'extracted_amg_drugs'];
  const legal = new Set(legalColumns);
  const lowerCaseAttributes = ['extracted_amg_drugs'];

  const getFieldValues = (fieldValue, attribute) => {
    if (lowerCaseAttributes.includes(attribute)) {
      if (Array.isArray(fieldValue)) {
        const newField = fieldValue.map(item => {
          return item.toLowerCase();
        });
        return newField;
      } else {
        return fieldValue;
      }
    } else {
      return fieldValue;
    }
  };

  return (
    <tr key={docSolrAsset.dataAsset?.id} className="search-result-item">
      <th className="position-sticky fixed-column p-0" scope="row">
        <div className="d-flex pt-3 w-100 h-100 border-right border-left">
          <AssetContextProvider docSolrAsset={docSolrAsset} isBookmarksDisabled={isBookmarksDisabled!}>
            <Asset.Header>
              <Asset.Title docPosition={docPosition} view="table-view" />
              <div className="d-flex justify-content">
                {/* {!hideRatingBtn && <DocRatingBtn asset={docSolrAsset.dataAsset!} />} */}
                {/* <Asset.Actions>
                <BookmarkToggleButton />
                  <ReportButton/>
                  <AssetActionsMenu>
                    <ViewAssetContentButton />
                    <ViewAttachmentsButton />
                    <ViewAttendeeButton />
                    <ViewDocContentButton />
                    <ViewDownloadsButton />
                    <ViewQaButton />
                    <ViewInquiryButton />
                    <ViewEmailResponseButton />
                    <ViewPostalResponseButton />
                  </AssetActionsMenu>
                </Asset.Actions> */}
              </div>
            </Asset.Header>
          </AssetContextProvider>
        </div>
      </th>

      {[...selectedColumns].map(attribute => (
        <td key={attribute} className="border-right">
          <AssetAttribute
            assetId={docSolrAsset.dataAsset?.id ?? ''}
            attribute={attribute}
            value={getFieldValues(docSolrAsset.dataAsset?.[attribute], attribute)}
            isFilter={clickableAttributes.includes(attribute)}
            onApplyFilter={value => handleApplyFilter(value, attribute)}
          />
        </td>
      ))}
    </tr>
  );
};

export default TableSearchResultRow;
