import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import './bookmark-popover.scss';

import BookmarkListContainer from '../bookmark-list-container/bookmark-list-container';

// import { faBookmark } from '@fortawesome/free-regular-svg-icons';
export interface BookmarkPopoverProps {
  className?: string;
}

export const BookmarkPopover: React.FC<BookmarkPopoverProps> = props => {
  return (
    <OverlayTrigger
      placement="bottom"
      trigger="click"
      rootClose
      overlay={
        <Popover
          id="bookmarks-popover"
          style={{ maxWidth: '500px', width: '500px' }}
          className="shadow border-0 mh-75 pt-4"
          placement="bottom-start"
        >
          <BookmarkListContainer />
        </Popover>
      }
    >
      <span className="pr-2 cursor-pointer">
        <img
          alt=""
          src="assets/images/bookmark.svg"
          style={{ width: '14px', paddingBottom: '2px' }}
          title="View Bookmarks"
        />
      </span>
    </OverlayTrigger>
  );
};

export default BookmarkPopover;
