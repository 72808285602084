import { FormLabelWithTooltip } from '@amgen/components';
import { IAssetAttribute } from '@amgen/core';
import { FacetsPanelContext, useWindowSizeListener } from '@amgen/shared';
import classNames from 'classnames';
import { FieldArray, useField } from 'formik';
import React, { useContext, useEffect } from 'react';
import { Col, Dropdown, Form } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

import './content-source-group-items.scss';

import LazyFacetTree from '../lazy-facet-tree/lazy-facet-tree';
import { ICustomGroupFormValue } from '../models';

export interface ContentSourceGroupItemsProps {
  category: IAssetAttribute;
  values: ICustomGroupFormValue;
}

export function ContentSourceGroupItems(props: ContentSourceGroupItemsProps) {
  const { selectedFilters } = useContext(FacetsPanelContext);
  const [selectedDataSources, selectedDataSourcesMeta, selectedDataSourcesHelpers] = useField('sources');
  const scrollHeight = document.getElementById('content-sources')?.scrollTop;
  const DataSourceDropdown = formControlProps => {
    useEffect(() => {
      if (scrollHeight) {
        const myDiv = document.getElementById('content-sources');
        myDiv!.scrollTop = scrollHeight;
      }
    }, [props.values.sources]);
    const isMobile = useWindowSizeListener();
    return (
      <FieldArray name="sources">
        {({ push, remove }) => (
          <div
            id="content-sources"
            className={classNames('data-sources data-sources-height thin-scrollbar', formControlProps.className, {
              'f-80': isMobile,
            })}
          >
            <Dropdown
              onSelect={async (value: string | null, e) => {
                if (value) {
                  const idx = props.values.sources.indexOf(value);
                  if (idx >= 0) {
                    remove(idx);
                  } else {
                    push(value);
                  }
                  await selectedFilters.toggle(value, 'doc_nav_prefix');
                  selectedDataSourcesHelpers.setTouched(true);
                }
              }}
            >
              <LazyFacetTree sources={props.values.sources} category={props.category} prefix="0/" root />
            </Dropdown>
          </div>
        )}
      </FieldArray>
    );
  };

  return (
    <Form.Group as={Col} xs="12" controlId={selectedDataSources.name}>
      <FormLabelWithTooltip label="Select Data Sources" helpText="Select Data Sources" />
      <Form.Control
        as={DataSourceDropdown}
        isInvalid={selectedDataSourcesMeta.touched && !!selectedDataSourcesMeta.error}
      />
      <Form.Control.Feedback type="invalid">{selectedDataSourcesMeta.error}</Form.Control.Feedback>
    </Form.Group>
  );
}

export default ContentSourceGroupItems;
