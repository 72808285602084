export function hexToRgb(hex: string) {
  hex = hex.replace('#', '');
  const r = parseInt(hex.length === 3 ? hex.slice(0, 1).repeat(2) : hex.slice(0, 2), 16);
  const g = parseInt(hex.length === 3 ? hex.slice(1, 2).repeat(2) : hex.slice(2, 4), 16);
  const b = parseInt(hex.length === 3 ? hex.slice(2, 3).repeat(2) : hex.slice(4, 6), 16);
  return { r, g, b };
}

export const formColorBasedOnIndex = (hex: string, i: number, a = 1) => {
  let { r, g, b } = hexToRgb(hex);
  r = Math.round((r / 255) * 5 * 51);
  g = Math.round((g / 255) * 5 * 51);
  b = Math.round((b / 255) * 5 * 51);
  const max = Math.max(r, b, g);
  const getNearestChromatic = (c: number) => {
    return c === max ? Math.round(c - 12 * i * Math.pow(-1.1, i)) : c;
  };
  return (
    'rgba(' +
    getNearestChromatic(r) +
    ', ' +
    getNearestChromatic(g) +
    ', ' +
    getNearestChromatic(b) +
    ', ' +
    (7.9 + i) / 10 +
    ')'
  );
};
