import { DownloadFileButton } from '@amgen/components';
import { IDataAsset } from '@amgen/core';
import { faArrowCircleLeft, faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Col, Modal, Row, Spinner } from 'react-bootstrap';

import './preview-popup.scss';

import { useAssetTitleUrl } from '../../hooks';

export interface PreviewPopupProps {
  isOpen: boolean;
  previewImages: string[];
  asset: IDataAsset;
  onCancel: () => void;
  loading: boolean;
  hasPreviewImages: boolean;
}

export const PreviewPopup: React.FC<PreviewPopupProps> = props => {
  const [previewPageNumber, setPreviewPageNumber] = useState(1);

  const { title: assetTitle } = useAssetTitleUrl(props.asset);


  const handleNextClicked = () => {
    setPreviewPageNumber(
      previewPageNumber + 1 > (props.previewImages.length ?? 1) ? previewPageNumber : previewPageNumber + 1
    );
  };

  const handlePrevClicked = () => {
    setPreviewPageNumber(previewPageNumber - 1 < 1 ? 1 : previewPageNumber - 1);
  };

  return !props.hasPreviewImages ? (
    <Modal show={props.isOpen} onHide={props.onCancel} centered size="lg">
      <Modal.Body className="border-0 d-flex justify-content-center align-items-center">
        Sorry, there is no preview available for this file!
      </Modal.Body>

      <Modal.Footer className="justify-content-center">
        {props.asset.filename ? <DownloadFileButton url={props.asset.filename} fileName={assetTitle?assetTitle:""} /> : null}
      </Modal.Footer>
    </Modal>
  ) : props.previewImages.length > 0 ? (
    <Modal show={props.isOpen} onHide={props.onCancel} centered size="lg" animation>
      <Modal.Header closeButton className="border-0">
        <Modal.Title className="mr-n5 col-12 text-center" as="h1">
          {`Preview (${previewPageNumber} of ${props.previewImages.length})`}
          {props.loading && <Spinner animation="border" variant="primary" />}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="pt-0 scrollable">
        <Row>
          <Col xs="2" className="left-button">
            <span
              className={`preview-page-button ${previewPageNumber === 1 ? 'grey-out' : 'cursor-pointer'}`}
              onClick={() => handlePrevClicked()}
            >
              <FontAwesomeIcon icon={faArrowCircleLeft} />
            </span>
          </Col>

          <Col xs="8">
            <img className="img-fluid d-block preview-image" src={props.previewImages[previewPageNumber - 1]} alt="" />
          </Col>

          <Col xs="2" className="right-button">
            <span
              className={`preview-page-button ${
                props.previewImages.length === previewPageNumber ? 'grey-out' : 'cursor-pointer'
              }`}
              onClick={() => handleNextClicked()}
            >
              <FontAwesomeIcon icon={faArrowCircleRight} />
            </span>
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer className="justify-content-center border-0">
        {props.asset.filename ? <DownloadFileButton url={props.asset.filename} fileName={assetTitle?assetTitle:""}  /> : null}
      </Modal.Footer>
    </Modal>
  ) : (
    <Modal show={props.isOpen} onHide={props.onCancel} centered size="lg">
      <Modal.Header closeButton className="border-0">
        <Modal.Title className="mr-n5 col-12 text-center" as="h1">
          Loading preview
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="border-0 d-flex justify-content-center align-items-center">
        <Spinner animation="border" variant="primary" />
      </Modal.Body>

      <Modal.Footer className="justify-content-center border-0">
        {props.asset.filename ? <DownloadFileButton url={props.asset.filename} fileName={assetTitle?assetTitle:""}  /> : null}
      </Modal.Footer>
    </Modal>
  );
};

export default PreviewPopup;
