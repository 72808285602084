import { IRoadmapFeatureFragmentCopy, useGetRoadmapsQuery } from '@amgen/api';
import format from 'date-fns/format';
import React, { useContext, useEffect, useMemo } from 'react';
import { Col, Container, Modal, Row, Spinner } from 'react-bootstrap';

import './feature-popup.module.scss';

import { RoadmapContext, ThemeContext } from '../../contexts';
import { getPercentage } from '../../hooks/feature-utils';
import PopupMetricSection from '../popup-metric-section/popup-metric-section';

/* eslint-disable-next-line */
export interface FeaturePopupProps extends IRoadmapFeatureFragmentCopy {
  show: boolean;
  onHide: () => void;
}

export function FeaturePopup(props: FeaturePopupProps) {
  const { name: themeName, id } = useContext(ThemeContext);
  const { setBlockScroll } = useContext(RoadmapContext);
  const percentage = useMemo(() => getPercentage(props.startDate, props.endDate) + '%', [props]);

  useEffect(() => {
    setBlockScroll(true);
    return () => {
      setBlockScroll(false);
    };
  }, [setBlockScroll]);

  const { data, loading } = useGetRoadmapsQuery({
    variables: {
      featureId: props.id,
      parentThemeId: id,
      noDetails: false,
    },
    fetchPolicy: 'no-cache',
  });

  return (
    <Modal show={props.show} onHide={props.onHide} centered scrollable size="xl">
      <Modal.Header closeButton className="bg-grey border-0 p-">
        <Modal.Title as="h1">{props.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body as={Container} className="px-4 pt-2">
        <Row className="d-flex">
          {loading ? (
            <Col xs="12" lg="6">
              <Spinner animation="border" className="mx-auto" variant="primary" />
            </Col>
          ) : null}
          {!loading && data?.getRoadmaps?.[0] ? (
            <Col xs="12" lg="6">
              <dl className="row mt-3">
                <dt className="col-12 h1 text-squid font-weight-bold">{data?.getRoadmaps[0].features?.[0].subtitle}</dt>
                <p className="col-12 mb-4">{data?.getRoadmaps[0].features?.[0].description}</p>

                <dt className="col-12 h2 mb-1">Percentage Completed</dt>
                <dd className="col-12 mb-4">{percentage}</dd>

                <dt className="col-xs-12 col-sm-6 h2 mb-1">Start Date</dt>
                <dt className="col-xs-12 col-sm-6 h2 mb-1">Completed Date</dt>
                <dd className="col-xs-12 col-sm-6 mb-4">{format(new Date(props.startDate), 'MMM dd, yyyy')}</dd>
                <dd className="col-xs-12 col-sm-6 mb-4">{format(new Date(props.endDate), 'MMM dd, yyyy')}</dd>

                <dt className="col-12 h2 mb-1">Theme</dt>
                <dd className="col-12 mb-4">{themeName}</dd>

                {data?.getRoadmaps[0].features?.[0].businessValue && (
                  <>
                    <dt className="col-12 h2 mb-1">Business Value</dt>
                    <dd className="col-12 mb-4">
                      <p>{data?.getRoadmaps[0].features?.[0].businessValue}</p>
                    </dd>
                  </>
                )}
              </dl>
            </Col>
          ) : null}
          <Col className="align-self-end">
            <PopupMetricSection featureId={props.id} startDate={props.startDate} endDate={props.endDate} themeName={themeName} featureName={props.name} />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default FeaturePopup;
