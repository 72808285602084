import {
  AppContext,
  ClinicalContext,
  KNOWLEDGE_PANEL_FEATURE_ACTIONS,
  MatomoContext,
  ResultViewType,
  SEARCH_APP_FEATURE_LIST,
  SearchContext,
} from '@amgen/shared';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import './knowledge-panel.scss';

import KnowledgePanelInfo from '../knowledge-panel-info/knowledge-panel-info';

/* eslint-disable-next-line */
export interface KnowledgePanelProps {}

export const KnowledgePanel: React.FC<KnowledgePanelProps> = () => {
  const { product, loading } = useContext(ClinicalContext);
  const { trackApplicationFeature } = useContext(MatomoContext);
  const { selectedResultTab } = useContext(AppContext);
  const { selectedViewType } = useContext(SearchContext);

  const history = useHistory();

  useEffect(() => {
    if (product) {
      trackApplicationFeature(SEARCH_APP_FEATURE_LIST.KNOWLEDGE_PANEL, KNOWLEDGE_PANEL_FEATURE_ACTIONS.VIEW_PANEL, 1);
    }
  }, [product, trackApplicationFeature]);

  return product && !loading && selectedResultTab !== 'Chat' && selectedViewType !== ResultViewType.Table ? (
    <KnowledgePanelInfo
      product={product}
      onToggleGraph={() => {
        history.push(`/clinical/product/${product.product}`);
        trackApplicationFeature(SEARCH_APP_FEATURE_LIST.KNOWLEDGE_PANEL, KNOWLEDGE_PANEL_FEATURE_ACTIONS.VIEW_GRAPH, 1);
      }}
    />
  ) : null;
};

export default KnowledgePanel;
