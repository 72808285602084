import React, { useContext } from 'react';

import './roadmap-body.module.scss';

import { RoadmapContext } from '../../contexts';
import RoadmapSkeletonLoad from '../roadmap-skeleton-load/roadmap-skeleton-load';
import RoadmapThemeRow from '../roadmap-theme-row/roadmap-theme-row';

/* eslint-disable-next-line */
export interface RoadmapBodyProps {
  quarterIndex: number;
}

export function RoadmapBody(props: RoadmapBodyProps) {
  const { roadmapThemes: themes, loading } = useContext(RoadmapContext);

  return (
    <>
      {loading ? [1, 2, 3, 4].map(x => <RoadmapSkeletonLoad key={'theme-load' + x} />) : null}
      {!loading &&
        themes.map(theme => <RoadmapThemeRow key={theme.id} theme={theme} quarterIndex={props.quarterIndex} />)}
    </>
  );
}

export default RoadmapBody;
