import { DeleteConfirmPanel, InlineEditable, TimeAgoItem } from '@amgen/components';
import { ApplicationName, DEFAULT_SEARCH_QUERY, ISavedSearch, IUserInfo, useEnvironment } from '@amgen/core';
import { AppliedFiltersBar } from '@amgen/feature-facets';
import { AppContext, FiltersObject } from '@amgen/shared';
import { getDisplayValueOfSearch } from '@amgen/utils';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import './saved-search-item.scss';

import {
  KmiAppliedFiltersBar,
} from '../../../../../../apps/kmi-search/src/app/components/kmi-applied-filters-bar/kmi-applied-filters-bar';
import { useSaveSubscribeItemMutation } from '../../hooks/save-subscribe-item';
import SaveSearchItemActionPanel from '../save-search-item-action-panel/save-search-item-action-panel';
import SubscriptionDetailsContent from '../subscription-detail-content/subscription-detail-content';

export interface SavedSearchItemProps {
  savedSearch: ISavedSearch;
  onDeleteSuccess: () => void;
  onEditSuccess: (name: string) => void;
  onNavigate: () => void;
  isDeleteDisabled?: boolean;
  openSavedSearchModal: () => void;
  user: IUserInfo;
}

export const SavedSearchItem: React.FC<SavedSearchItemProps> = props => {
  const { savedSearch: appliedSavedSearch, setSavedSearch } = useContext(AppContext);
  const [isDeletePanelOpen, setIsDeletePanelOpen] = useState(false);
  const [isSubscribePanelOpen, setIsSubscribePanelOpen] = useState(false);
  const [isSearchNameOnEditMode, setSearchNameEditMode] = useState(false);
  const [searchItemFilters] = useState(FiltersObject.fromJSONString(props.savedSearch.filters?.[0] ?? '{}'));
  const { appName } = useEnvironment();

  const {
    handleUpdate,
    updateLoading,
    handleUnsubscribe,
    handleDelete,
    deleteLoading,
  } = useSaveSubscribeItemMutation();

  const onDeleteSuccess = () => {
    if (props.savedSearch.id === appliedSavedSearch?.id) {
      setSavedSearch(null);
    }
    props.onDeleteSuccess();
    setIsDeletePanelOpen(false);
  };

  const onUpdateSuccess = () => {
    setIsSubscribePanelOpen(false);
  };

  const params = new URLSearchParams();
  params.append(
    'q',
    props?.savedSearch?.query && props?.savedSearch?.query !== DEFAULT_SEARCH_QUERY ? props.savedSearch.query : ''
  );

  return (
    <Card className="border-0">
      <Card.Header as="h2" className="bg-white border-0 d-flex justify-content-between mb-1 p-0">
        {isSearchNameOnEditMode ? (
          <InlineEditable
            initialValue={props.savedSearch.name!}
            onSave={updatedValue => {
              if (updatedValue !== props.savedSearch.name) {
                handleUpdate(updatedValue, props.savedSearch, props.onEditSuccess);
              }
              setSearchNameEditMode(false);
            }}
            onCancel={() => setSearchNameEditMode(false)}
          />
        ) : (
          <>
            <span className="save-search-item-name-container">
              <div>
                <Link
                  to={{
                    pathname: '/',
                    search: params.toString(),
                  }}
                  onClick={props.onNavigate}
                  className="save-search-item-name"
                >
                  {props.savedSearch.name}
                </Link>
                <span
                  onClick={() => setSearchNameEditMode(true)}
                  className="save-search-item-name-edit-button text-info cursor-pointer ml-3"
                  style={{ fontSize: 'medium' }}
                  title="Edit Search Title"
                >
                  <FontAwesomeIcon icon={faPen} />
                </span>
              </div>
              <small style={{ fontSize: 'small' }} className="text-grey">
                {props.savedSearch.timestamp ? <TimeAgoItem dateString={props.savedSearch.timestamp} /> : null}
              </small>
            </span>
            <SaveSearchItemActionPanel
              onDelete={() => setIsDeletePanelOpen(true)}
              subscrptnIsactive={props.savedSearch.subscrptnIsactive}
              openSavedSearchModal={props.openSavedSearchModal}
            />
          </>
        )}
      </Card.Header>

      <Card.Body className="p-1">
        <Container className="mt-2 p-0">
          <Row noGutters className="pb-2">
            <Col xs="3" className="font-weight-bold">
              Keyword:
            </Col>
            <Col xs="9">{getDisplayValueOfSearch(props.savedSearch.query)}</Col>
          </Row>

          <Row noGutters className="pb-2">
            <Col xs="3" className="font-weight-bold">
              Applied Filters:
            </Col>
            <Col xs="9">
              {appName === ApplicationName.KMI ? (
                <KmiAppliedFiltersBar
                  filters={searchItemFilters}
                  isClearable={false}
                  message="No Applied Filters"
                  small
                />
              ) : (
                <AppliedFiltersBar
                  filters={searchItemFilters}
                  isClearable={false}
                  message="No Applied Filters"
                  small
                  //page="save-search"
                  sourceType={props.savedSearch.sourceType}
                />
              )}
            </Col>
          </Row>
        </Container>

        {props.savedSearch.subscrptnIsactive && (
          <SubscriptionDetailsContent
            savedSearch={props.savedSearch}
            updateLoading={updateLoading}
            onToggleSubscribe={() => setIsSubscribePanelOpen(true)}
          />
        )}

        {isDeletePanelOpen && (
          <DeleteConfirmPanel
            message="Are you sure you want to delete this search?"
            onCancel={() => setIsDeletePanelOpen(false)}
            onDelete={() => {
              handleDelete(props.savedSearch, onDeleteSuccess);
            }}
            isLoading={deleteLoading}
          />
        )}

        {isSubscribePanelOpen && (
          <DeleteConfirmPanel
            message={`Are you sure, you want to ${
              props.savedSearch.subscrptnIsactive ? 'unsubscribe' : 'subscribe'
            } this search?`}
            onCancel={() => setIsSubscribePanelOpen(false)}
            onDelete={() => {
              handleUnsubscribe(props.savedSearch, props.user.emailId, onUpdateSuccess);
            }}
            isLoading={updateLoading}
            variant="primary"
            actionMessage={props.savedSearch.subscrptnIsactive ? 'Unsubscribe' : 'Subscribe'}
          />
        )}
      </Card.Body>
    </Card>
  );
};

export default SavedSearchItem;
