import { useWindowSizeListener } from '@amgen/shared';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { Button } from 'react-bootstrap';
import Rating from 'react-rating';

import './rating-btn.scss';

export interface RatingBtnProps {
  avgRating: number;
  className?: string;
  buttonText?: string;
}

export const RatingBtn = (props: RatingBtnProps) => {
  const isMobile = useWindowSizeListener();
  return (
    <Button
      variant="link"
      className={classNames(
        props.className,
        'doc-rating-btn d-flex align-items-center border-0 text-decoration-none p-0 '
      )}
    >
      {/* <span className="pt-1">{Math.round(props.avgRating * 100) / 100}</span> */}
      <Rating
        initialRating={1}
        stop={1}
        fullSymbol={<FontAwesomeIcon icon={faStarSolid} className="text-gold mx-1 star-style" size="lg" />}
        emptySymbol={<FontAwesomeIcon icon={faStar} className="text-gold mx-1 star-style" size="lg" />}
        readonly
      />
      {isMobile && <span className="mr-3 button-text">{props.buttonText}</span>}
    </Button>
  );
};

export default RatingBtn;
