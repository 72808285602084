import { DEFAULT_SEARCH_QUERY, IAssetAttribute, ITypeaheadSuggestion } from '@amgen/core';
import {
  AppContext,
  FacetContext,
  MatomoContext,
  SEARCH_APP_FEATURE_LIST,
  SEARCH_BOX_TYPEAHEAD_ACTIONS,
  useSearchQueryValue,
  useToast,
} from '@amgen/shared';
import DOMPurify from 'dompurify';
import { useContext, useEffect, useMemo } from 'react';
import { useState } from 'react';

import { SearchBoxContext } from '../../search-box-context-provider/search-box-context';

export function useTypeaheadHooks() {
  const {
    typeaheadRef,
    selectedFilter,
    setOpenSuggestionDrop,
    setSearchQuery,
    setSelectedFilter,
    applyFilter,
    showAdvanceSearch,
    onSearch,
    setReusedQuery,
    searchQuery,
    setBlockScroll,
    selectedTypeaheadFilters,
    setSelectedTypeaheadFilters,
    selectedTypeaheadFilterCategories,
    setSelectedTypeaheadFilterCategories,
    onExternalSearch,
    payload,
    setPayload,
    setCallAPI,
    callAPI,
  } = useContext(SearchBoxContext);
  const { setAppliedFilterCategories, setAppliedFilters, appliedFilterCategories } = useContext(FacetContext);
  const { trackApplicationFeature } = useContext(MatomoContext);
  const [hideTyping, setHideTyping] = useState(false);
  const { setSuggestionDoc } = useContext(AppContext);
  // const { matomo } = useEnvironment();
  const searchQueryTerm = useSearchQueryValue();
  const [isPayload, setIsPayload] = useState(false);
  const currentCustomsource = sessionStorage.getItem('current-source-selected');
  //const [payload, setPayload] = useState({ title: '', object: '' });

  // const { addKeywords } = usePreviousSearches({
  //   variables: { dimensionId: matomo?.appDimension!, count: 2 },
  //   skip: !matomo?.appDimension,
  // });

  const handleBlur = () => {
    setOpenSuggestionDrop(false);
    setHideTyping(false);
    if (setBlockScroll) {
      setBlockScroll(false);
    }
  };
  const user = JSON.parse(sessionStorage.user);

  const variables = useMemo(
    () => ({
      solrHandler: '/search-select',
      filters: [`title_s : "${payload.title}"`, `autosuggest_object: "${payload.object}"`],
      userId: user.username,
      userType: sessionStorage.getItem('employeeType') || '',
    }),
    [payload.object, payload.title, user.username]
  );

  //const { payload, setPayload } = useContext(SearchBoxContext);
  //console.log(Object.keys(payload).length, 'Title===');
  // console.log(isPayload, 'Payload==');
  // const { data, refetch } = useGetTypeaheadResultUrlQuery({
  //   variables,
  //   skip: payload.title === '' || !payload.title,
  //   // onCompleted: data => {
  //   //   if (data && data.getTypeaheadResultUrl) {
  //   //     window.open(data?.getTypeaheadResultUrl, '_blank');
  //   //   }
  //   //setIsPayload(false);
  //   //},
  //   // fetchPolicy: 'no-cache',
  // });

  const handleFocus = () => {
    // if (typeaheadRef.current.getInput().value.trim().length === 0) {
    //   setOpenSuggestionDrop(false);
    // } else {
    //   setOpenSuggestionDrop(true);
    // }
    setOpenSuggestionDrop(true);

    setHideTyping(true);
    if (setBlockScroll) {
      setBlockScroll(true);
    }
  };

  const handleInputChange = (query: string) => {
    if (query && !selectedFilter) {
      setOpenSuggestionDrop(true);
    }
    setSearchQuery(query);
    //refetch();
  };

  const getAction = (typeName: string) => {
    switch (typeName) {
      default:
      case 'Filter Values':
        return SEARCH_BOX_TYPEAHEAD_ACTIONS.SELECT_TYPEAHEAD_FILTER;
      case 'Documents':
        return SEARCH_BOX_TYPEAHEAD_ACTIONS.SELECT_DOCUMENT_SUGGESTION;
      case 'Previous Search':
        return SEARCH_BOX_TYPEAHEAD_ACTIONS.SELECT_PREVIOUS_SEARCH_SUGGESTION;
    }
  };

  const trackTypeaheadActions = (typeName: string) => {
    const action = getAction(typeName);
    if (action) {
      trackApplicationFeature(SEARCH_APP_FEATURE_LIST.SEARCH_BOX_TYPEAHEAD, action);
    }
  };
  let searchTypeahead = '';
  const handleChange = (query: ITypeaheadSuggestion[]) => {
    const term = query?.[0]?.term ?? '';
    const typeName = query?.[0]?.suggestionType;
    const matchObject = query?.[0]?.matchObject ?? '';
    const value = (query?.[0]?.attributeName as IAssetAttribute) ?? null;
    searchTypeahead = typeName;

    if (typeName === 'Filters') {
      typeaheadRef.current.clear();
      setSearchQuery('');
      setSelectedFilter(value);

      setSuggestionDoc(false);
    } else if (typeName === 'Filter Values') {
      typeaheadRef.current.clear();
      setSelectedTypeaheadFilters(selectedTypeaheadFilters.add(value, selectedFilter as IAssetAttribute));
      setAppliedFilters(selectedTypeaheadFilters);
      setSelectedTypeaheadFilterCategories(
        new Set([...selectedTypeaheadFilterCategories, selectedFilter as IAssetAttribute])
      );
      setAppliedFilterCategories(new Set([...appliedFilterCategories, ...selectedTypeaheadFilterCategories]));
      setSearchQuery(searchQueryTerm);
      setSelectedFilter(null);
      setSuggestionDoc(false);
    } else {
      if (term.length > 0) {
        // if (showAdvanceSearch) {
        //   console.log(showAdvanceSearch);
        //   applyFilter(term);
        // }
        setSearchQuery(term);
        handleSearch(term.trim());
        setReusedQuery(true);
        // addKeywords(term);
        setSuggestionDoc(true);
        setOpenSuggestionDrop(false);
        setAppliedFilters(selectedTypeaheadFilters);
        setAppliedFilterCategories(new Set([...appliedFilterCategories, ...selectedTypeaheadFilterCategories]));

        // setting title and object name to pass in GetSearchResults API
        if ((typeName === 'Suggested Destinations' || typeName === 'MyAmgen Content') && keyEvent !== 'ArrowRight') {
          //refetch();
          //setIsPayload(true);
          setPayload({ title: term, object: matchObject, time: new Date().getTime });
          setCallAPI?.(true);
        }
      }
    }
    trackTypeaheadActions(typeName);
  };

  const stripHomePathFromUrl = () => {
    const urlObject = new URL(window.location.href);
    const pathSegments = urlObject.pathname.split('/').filter(segment => segment.trim() !== ''); // Split path into segments and remove empty segments
    if (pathSegments.length > 1) {
      pathSegments.pop(); // Remove the last path segment if there's more than one segment
      urlObject.pathname = pathSegments.join('/') + '/'; // Reconstruct the pathname with a trailing slash
    } else {
      urlObject.pathname = '/'; // If there's only one segment (like '/home'), set pathname to root '/'
    }
    return urlObject.toString();
  };

  const handleSearch = (query: string) => {
    if (currentCustomsource === 'OpsKM Search') {
      const inputValue = typeaheadRef.current.getInput().value.trim();
      const sanitizedInput = encodeURIComponent(inputValue);
      const redirectTo = `${stripHomePathFromUrl()}?q=${sanitizedInput}&pk_source=OpsKM&pk_campaign=widget&pk_medium=search-box&pk_filter=Ops%20KM%20Assets`;
      window.open(`${DOMPurify.sanitize(redirectTo)}`, '_self');
      return;
    } else {
      onSearch(query, searchTypeahead);
      onExternalSearch?.(query, selectedTypeaheadFilters);
    }
  };
  // const getMoreThanOneChar = () => typeaheadRef.current.getInput().value.trim().length > 1;
  const toast = useToast();
  let keyEvent = '';
  const handleKeyDown = (e: any) => {
    // setSearchQuery( typeaheadRef.current.getInput().value.trim())
    keyEvent = e.code;
    if (e.key === 'Enter' && typeaheadRef.current.getInput().value.trim().length < 2) {
      toast.danger({
        message: 'Please enter minimum two characters to perform search',
        id: 'one-or-null-char-toaster',
        timeout: 5000,
      });
    }
    if (e.key === 'Enter' && typeaheadRef.current.getInput().value.trim().length > 1) {
      setSuggestionDoc(false);
      if ((typeaheadRef.current.state.activeItem?.suggestionType ?? null) === 'Filters') {
        typeaheadRef.current.clear();
        setSelectedFilter(typeaheadRef.current.state.activeItem.attributeName);
      } else if ((typeaheadRef.current.state.activeItem?.suggestionType ?? null) === 'Filter Values') {
        typeaheadRef.current.clear();
      } else {
        const term = typeaheadRef.current.getInput().value.trim();
        const notCallSearch = ['MyAmgen Content', 'Suggested Destinations'];

        if (!notCallSearch.includes(typeaheadRef.current.state.activeItem?.suggestionType)) {
          handleSearch(term);
        }
        // handleChange(term)
        setReusedQuery(true);
        // addKeywords(term);
        setOpenSuggestionDrop(false);
        setAppliedFilters(selectedTypeaheadFilters);
        setAppliedFilterCategories(new Set([...appliedFilterCategories, ...selectedTypeaheadFilterCategories]));
      }
    }

    if (e.key === 'Backspace') {
      setOpenSuggestionDrop(false);
      if (!searchQuery || searchQuery === DEFAULT_SEARCH_QUERY) {
        setSelectedFilter(null);
      }
    }
  };

  return {
    typeaheadRef,
    hideTyping,
    handleBlur,
    handleFocus,
    handleInputChange,
    handleChange,
    handleSearch,
    handleKeyDown,
    payload,
  };
}
