import { DEFAULT_SEARCH_QUERY } from '@amgen/core';
import classnames from 'classnames';
import React, { useContext } from 'react';

import './search-box-clear-button.scss';

import { SearchBoxContext } from '../../search-box-context-provider/search-box-context';

/* eslint-disable-next-line */
export interface SearchBoxClearButtonProps {}

export const SearchBoxClearButton: React.FC<SearchBoxClearButtonProps> = () => {
  const {
    typeaheadRef,
    selectedFilter,
    searchQuery,
    setSelectedFilter,
    setSearchQuery,
    showAdvanceSearch,
  } = useContext(SearchBoxContext);

  return selectedFilter || (searchQuery && searchQuery !== DEFAULT_SEARCH_QUERY) ? (
    <span
      // className={classnames('position-relative cursor-pointer clear-icon', showAdvanceSearch ? 'mr-3' : '')}
      className={classnames('position-relative cursor-pointer clear-icon d-flex')}
      onClick={() => {
        setSelectedFilter(null);
        setSearchQuery('');
        typeaheadRef.current.clear();
      }}
      style={{ alignItems: 'stretch' }}
    >
      <img src="assets/images/clear.svg" className="clear-icon-svg" />
      <img src="assets/images/line.svg" height={'100%'} />
    </span>
  ) : null;
};

export default SearchBoxClearButton;
