import { MatomoContext, ROADMAP_ACTIONS, SEARCH_APP_FEATURE_LIST } from '@amgen/shared';
import React, { useContext, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import './roadmap-timeline.scss';

import { QUARTERS } from '../../config/timeline';
import { NavigateNext, NavigatePrev } from '../navigate-icon/navigate-icon';

/* eslint-disable-next-line */
export interface RoadmapTimelineProps {
  position: number;
  setPosition: (pos: number) => void;
}

export function RoadmapTimeline({ position, setPosition }: RoadmapTimelineProps) {
  const { trackApplicationFeature } = useContext(MatomoContext);

  return (
    <Row className="position-relative">
      <NavigatePrev
        position={position}
        setPosition={() => {
          setPosition(position - 1);
          trackApplicationFeature(SEARCH_APP_FEATURE_LIST.ROADMAP, ROADMAP_ACTIONS.NAVIGATE_PREV_QUARTER);
        }}
      />
      <Col></Col>
      {QUARTERS.slice(position, 2 + position).map((x, i) => (
        <Col key={x.label} xs="5" className="p-3 bg-light border text-center">
          <h1 className="text-squid font-weight-bold">{x.label}</h1>
        </Col>
      ))}
      <NavigateNext
        position={position}
        setPosition={() => {
          setPosition(position + 1);
          trackApplicationFeature(SEARCH_APP_FEATURE_LIST.ROADMAP, ROADMAP_ACTIONS.NAVIGATE_NEXT_QUARTER);
        }}
      />
    </Row>
  );
}

export default RoadmapTimeline;
