import classnames from 'classnames';
import React from 'react';

import './notify-badge.module.scss';

/* eslint-disable-next-line */
export interface NotifyBadgeProps {
  count: number;
  className?: string;
}

export function NotifyBadge(props: NotifyBadgeProps) {
  return (
    <span
      className={classnames(
        'notification-badge d-none d-lg-flex justify-content-center align-items-center bg-light position-absolute rounded-circle text-dark shadow font-weight-bold',
        props.className
      )}
      style={{ fontSize: '0.75rem' }}
    >
      {props.count}
    </span>
  );
}

export default NotifyBadge;
