import { PageType } from '@amgen/core';
import { FieldArray, useField } from 'formik';
import React, { useEffect, useState } from 'react';
import { Badge, Dropdown, FormCheck, FormGroup } from 'react-bootstrap';

import './dropdown-array-field.scss';

import DropdownContainer from '../dropdown-container/dropdown-container';
import FormLabelWithTooltip from '../form-label-with-tooltip/form-label-with-tooltip';

export interface DropdownArrayFieldProps<T> {
  name: string;
  placeholder: string;
  options: T[];
  optionSortFn: (a: T, b: T) => number;
  optionKeyFn: (a: T) => string;
  optionLabelFn: (a: T) => string;
  label: string;
  helpText: string;
  setSelectedReqStatus?: any;
  pageType?: PageType;
  setAllowSubmit?: any;
}

export const DropdownArrayField = <T extends unknown>(props: DropdownArrayFieldProps<T>) => {
  const [field, meta, helpers] = useField<T[]>(props.name);
  const [isSelectAll, setSelectAll] = useState(true);
  const [preferredFilters, setPreferredFilters] = useState(new Set(props.options));
  useEffect(() => {
    if (field.value.length === 1 && field.value[0] === 'selectAll') {
      props.setAllowSubmit(false);
    } else {
      props.setAllowSubmit(true);
    }
  }, [field.value]);
  return (
    <FormGroup className="w-100">
      <FormLabelWithTooltip label={props.label} helpText={props.helpText} />
      <FieldArray name={props.name}>
        {({ push, remove }) => (
          <DropdownContainer
            title={
              <div className="d-flex justify-content-between align-items-center">
                <span>{props.placeholder}</span>
                <Badge
                  className="d-inline-flex align-items-center border rounded-pill mx-1 px-2"
                  style={{ backgroundColor: '#e0e0e0', color: '#797979' }}
                >
                  {props.name === 'status' ? 1 : field.value.length - 1}
                </Badge>
              </div>
            }
            onValueToggle={category => {
              const value = category as T;
              const index = field.value.indexOf(value);
              if (props.name === 'status') {
                if (field.value.length > 0) {
                  field.value.shift();
                } else {
                  push(value);
                }
              }
              const optionSet = new Set();
              if (category === 'selectAll') {
                setSelectAll(!isSelectAll);
                if (!isSelectAll) {
                  props.options.forEach(opt => {
                    optionSet.add(opt);
                  });

                  Array.from(optionSet).forEach(value => {
                    push(value);
                  });
                } else {
                  props.options.forEach((option, key) => {
                    remove(option[key]);
                  });
                }
              }
              if (index > -1) {
                remove(index);
              } else {
                push(value);
              }
            }}
            onToggle={() => helpers.setTouched(true)}
          >
            <div className="thin-scrollbar" style={{ maxHeight: 250, overflowY: 'auto' }}>
              {props.name !== 'status' ? (
                <Dropdown.Item as="li" className="px-3" eventKey="selectAll">
                  <FormCheck>
                    <FormCheck.Input type="checkbox" checked={isSelectAll} />
                    <FormCheck.Label>Select All</FormCheck.Label>
                  </FormCheck>
                </Dropdown.Item>
              ) : (
                ''
              )}
              {props.options.sort(props.optionSortFn).map(option => {
                const key = props.optionKeyFn(option);
                return (
                  <Dropdown.Item as="li" className="px-3" eventKey={key} key={key}>
                    <FormCheck>
                      {props.name === 'status' ? (
                        <FormCheck.Input type="radio" checked={field.value.includes(option)} onChange={() => {}} />
                      ) : (
                        <FormCheck.Input type="checkbox" checked={field.value.includes(option)} onChange={() => {}} />
                      )}

                      <FormCheck.Label>{props.optionLabelFn(option)}</FormCheck.Label>
                    </FormCheck>
                  </Dropdown.Item>
                );
              })}
            </div>
          </DropdownContainer>
        )}
      </FieldArray>
      {meta.touched && !!meta.error && <small className="text-danger">{meta.error}</small>}
    </FormGroup>
  );
};

export default DropdownArrayField;
