import { ClinicalContext } from '@amgen/shared';
import { EChartOption } from 'echarts';
import { useCallback, useContext, useMemo, useRef } from 'react';

import KnowledgePanelClinicalItems from './components/knowledge-panel-clinical-items/knowledge-panel-clinical-items';
import KnowledgePanelOverview from './components/knowledge-panel-overview/knowledge-panel-overview';
import { KNOWLEDGE_GRAPH_CONFIG, KNOWLEDGE_GRAPH_PRODUCT_DISPLAY_ATTRIBUTES } from './config/knowledge-panel-attributes';
import { KnowledgePanelOption, KnowledgePanelTabConfig } from './config/knowledge-panel-tabs';
import { IEchartCategory, IEchartLink, IEchartNode } from './models';

// import { IRole } from '@amgen/core';
// import { parse } from 'date-fns';
const categoriesConfig: IEchartCategory[] = [
  {
    name: 'Product',
    itemStyle: { color: '#0063c3' },
  },
  {
    name: 'Study Number',
    itemStyle: { color: '#88C765' },
  },
  {
    name: 'Roles',
    itemStyle: { color: '#F3C108' },
  },
  {
    name: 'Other',
    itemStyle: { color: '#597B7C' },
  },
];

function useKnowledgeGraphData() {
  const [nodes, links, categories] = useGraphNodesAndLinks();

  return { nodes, links, categories };
}

// TODO - Jed: consider using recursion to clean these functions up
export function useGraphNodesAndLinks() {
  const { product, clinicalId: studyNumber, clinicalType } = useContext(ClinicalContext);

  const getProductData: () => [IEchartNode[], IEchartLink[], IEchartCategory[]] = useCallback(() => {
    const productNodes: IEchartNode[] = [];
    const productLinks: IEchartLink[] = [];
    const categoriesMap = new Map();

    let i = 0;
    KNOWLEDGE_GRAPH_PRODUCT_DISPLAY_ATTRIBUTES.forEach(field => {
      if (product?.[field]) {
        const attr = KNOWLEDGE_GRAPH_CONFIG[field];
        const nodeName =
          (Array.isArray(product?.[field])
            ? (product?.[field] as string[]).join('\n')
            : product?.[field]
          )?.toString() ?? '';

        productNodes.push({
          id: i,
          name: attr?.hasMoreInfo ? attr?.displayName : nodeName,
          symbolSize: attr?.nodeSize ?? 25,
          itemStyle: attr?.nodeStyle ?? { color: '#597B7C' },

          category: attr?.category ?? 2,
          label: attr?.label ?? { position: 'top', color: '#0063c3' },
        });

        productLinks.push({
          id: i,
          source: i,
          target: 0,
          symbol: attr?.linkSymbols ?? ['none', 'none'],
          symbolSize: attr?.linkSymbolSize ?? 14,
          name: attr?.linkName ?? '',
          lineStyle: { color: attr?.lineColor ?? '#aaa' },
        });

        categoriesMap.set(attr?.category ?? 3, categoriesConfig[attr?.category ?? 3]);

        i += 1;
      }
    });

    // // creates a map to store the role with the most recent endDate
    // const productRolesMap = new Map<string, IRole>();
    // product?.['roles']
    //   ?.filter(role => role.roleName !== 'Team Member')
    //   .forEach(role => {
    //     if (productRolesMap.has(role.roleName)) {
    //       if (productRolesMap.get(role.roleName)?.endDate === 'nan') {
    //         // retain current map
    //       } else if (role.endDate === 'nan') {
    //         productRolesMap.set(role.roleName, role);
    //       } else {
    //         const latestEndDate = parse(productRolesMap.get(role.roleName)!.endDate, 'M/d/yyyy', new Date());
    //         const currentEndDate = parse(role.endDate, 'M/d/yyyy', new Date());
    //         if (currentEndDate > latestEndDate) {
    //           productRolesMap.set(role.roleName, role);
    //         }
    //       }
    //     } else {
    //       productRolesMap.set(role.roleName, role);
    //     }
    //   });

    // // this adds roles nodes and links to graph
    // productRolesMap.forEach((role, _) => {
    //   productNodes.push({
    //     id: i,
    //     name: role.person ?? '',
    //     symbolSize: 25,
    //     itemStyle: { color: '#F3C108' },
    //     category: 2,
    //     label: { position: 'top', color: '#0063c3' },
    //     value: { role },
    //   });

    //   productLinks.push({
    //     id: i,
    //     source: i,
    //     target: 0,
    //     symbol: ['none', 'none'],
    //     symbolSize: 14,
    //     name: role.roleName,
    //   });

    //   categoriesMap.set(2, categoriesConfig[2]);

    //   i += 1;
    // });

    // Exclude "Roles" category from the map
    categoriesMap.delete(2);

    return [productNodes, productLinks, [...categoriesMap.values()]];
  }, [product]);

  const getStudyData: () => [IEchartNode[], IEchartLink[], IEchartCategory[]] = useCallback(() => {
    const studyNodes: IEchartNode[] = [];
    const studyLinks: IEchartLink[] = [];
    const categoriesMap = new Map();

    // study (center) node
    studyNodes.push({
      id: 0,
      name: studyNumber ?? 'Study',
      symbolSize: 30,
      category: 1,
      label: { position: 'top', color: '#0063c3' },
      itemStyle: { color: '#88C765' },
    });

    categoriesMap.set(1, categoriesConfig[1]);

    const i = 1;

    // deliverable nodes
    // product?.studyInformation
    //   ?.find(study => study.number === studyNumber)
    //   ?.deliverable.forEach(d => {
    //     studyNodes.push({
    //       id: i,
    //       name: d,
    //       symbolSize: 25,
    //       itemStyle: { color: '#597B7C' },
    //       category: 2,
    //       label: { position: 'top', color: '#0063c3' },
    //     });

    //     studyLinks.push({
    //       id: i,
    //       source: i,
    //       target: 0,
    //       name: 'has deliverable',
    //     });

    //     categoriesMap.set(3, categoriesConfig[3]);

    //     i += 1;
    //   });

    // product node
    studyNodes.push({
      id: i,
      name: product?.product ?? 'Product',
      itemStyle: { color: '#0063c3' },
      symbolSize: 60,
      category: 0,
      label: { position: 'inside', color: 'white' },
    });

    studyLinks.push({
      id: i,
      source: i,
      target: 0,
      name: 'has product',
      symbol: ['arrow', 'none'],
      symbolSize: 20,
      lineStyle: { color: '#00BCE4' },
    });

    categoriesMap.set(0, categoriesConfig[0]);

    return [studyNodes, studyLinks, [...categoriesMap.values()]];
  }, [product, studyNumber]);

  return useMemo(() => {
    return clinicalType === 'study' ? getStudyData() : getProductData();
  }, [clinicalType, getProductData, getStudyData]);
}

export function useEchartsSeries() {
  const { nodes, links, categories } = useKnowledgeGraphData();

  const series: EChartOption.Series[] = useMemo(
    () => [
      {
        type: 'graph',
        layout: 'force',
        force: {
          edgeLength: 110,
          repulsion: 150,
          gravity: 0.1,
        },
        animation: false,
        data: nodes,
        links,
        edgeLabel: {
          show: true,
          formatter: (params: { data: { name: string } }) => params.data.name,
        },
        categories,
        roam: true,
        label: {
          show: true,
          position: 'right',
          labelLayout: {
            hideOverlap: true,
          },

          formatter: (params: any) => {
            return params.value?.role
              ? `${params.value.role.person ?? ''}\n${params.value.role.email ?? ''}`
              : params.name;
          },
        },
        focusNodeAdjacency: true,
        labelLayout: {
          hideOverlap: true,
        },
        scaleLimit: {
          min: 0.75,
          max: 1.75,
        },
        zoom: 2.5,
        lineStyle: {
          curveness: 0.0,
          width: 3,
          opacity: 0.5,
        },
        legendHoverLink: true,
      },
    ],
    [categories, links, nodes]
  );

  const legend: EChartOption.Legend = useMemo(
    () => ({
      data: categories.map(a => a.name),
      textStyle: {
        fontSize: 10,
      },
      top: 'top',
      icon: 'circle',
      selectedMode: false,
    }),
    [categories]
  );

  return { series, legend };
}

export function useKnowledgeGraphOption() {
  const { series, legend } = useEchartsSeries();

  const option = useMemo(
    () => ({
      series,
      legend,
    }),
    [series, legend]
  );

  return option;
}
// hiding the clinical trials tab for a temporary release
export function useKnowledgePanelComponents(tab: KnowledgePanelOption) {
  const { current: config } = useRef<KnowledgePanelTabConfig>({
    Overview: KnowledgePanelOverview,
    'Active Clinical Trials': KnowledgePanelClinicalItems,
  });

  const KnowledgePanelItems = useMemo(() => config[tab] ?? KnowledgePanelOverview, [tab, config]);

  return KnowledgePanelItems;
}
