import { useLogoutApp } from '@amgen/auth';
import {
  HamburgerMenu,
  HamburgerMenuItem,
  HamburgerMenuItemChild,
  Header,
  HeaderCenter,
  HeaderLeft,
  HeaderRight,
  Logo,
} from '@amgen/components';
import { useEnvironment } from '@amgen/core';
import { DataRequestSubmenu, NewRequestModal } from '@amgen/feature-governance-dashboard';
import { ProfileMenu } from '@amgen/feature-navigation';
import { SearchBar } from '@amgen/feature-search';
import {
  IGD_ACTIONS,
  LANDING_PAGE_SECTIONS,
  MatomoContext,
  SEARCH_APP_FEATURE_LIST,
  useWindowHeightListener,
  useWindowSizeListener,
} from '@amgen/shared';
import { faBookmark } from '@fortawesome/free-regular-svg-icons';
import { faCog, faExternalLinkAlt, faHdd, faSignOutAlt, faUpload, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import './eea-landing-header.scss';

import { SECTIONS, SMALL_WINDOW_SECTIONS } from '../../pages/model';

/* eslint-disable-next-line */
export interface EEALandingHeaderProps {
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

export const EEALandingHeader = (props: EEALandingHeaderProps) => {
  const { logout } = useLogoutApp();

  let authTabs = [''];
  if (sessionStorage.authTabs) {
    authTabs = JSON.parse(sessionStorage.authTabs);
  }
  const user = JSON.parse(sessionStorage?.user);
  const { support } = useEnvironment();
  const { trackApplicationFeature } = useContext(MatomoContext);

  const { push } = useHistory();
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [showBanner, setShowBanner] = useState(true);
  const [isSearchExpanded, setIsSearchExpanded] = useState(window.innerWidth >= 992);
  const isMobile = useWindowSizeListener();
  const mimicHasScrolled = props.currentPage >= 1;
  const authorizedTabs = authTabs?.includes('IGD Admin');

  useEffect(() => {
    const handler = () => setIsSearchExpanded(window.innerWidth >= 992);
    window.addEventListener('resize', handler);
    return () => window.removeEventListener('resize', handler);
  }, [isSearchExpanded]);

  const isShort = useWindowHeightListener(700, () => props.setCurrentPage(0));
  const userGuide = () => {
    window.open(support.guideUrl);
  };

  const onSettingClick = () => {
    localStorage.setItem('current_url1', window.location.href);
  };
  const roadmapIndex = isShort
    ? SMALL_WINDOW_SECTIONS.findIndex(section => section.label == LANDING_PAGE_SECTIONS.ROADMAP)
    : SECTIONS.findIndex(section => section.label == LANDING_PAGE_SECTIONS.ROADMAP);

  return (
    <>
      <Header
        bg="white"
        className={classNames('landing-header position-sticky px-md-5', {
          'shadow-lg border-bottom': mimicHasScrolled,
        })}
      >
        <HeaderLeft className={classNames({ 'bg-white': isMobile })}>
          <HamburgerMenu className={classNames('d-md-none d-lg-none', { 'text-primary': isMobile })}>
            <HamburgerMenuItem
              content={<Logo className="d-lg-flex" img="assets/images/eea-logo.svg" appName="EEA Search" />}
            />
            <HamburgerMenuItem icon={faUserCircle} content={user.fullName} />
            <HamburgerMenuItem to="/" icon={faHdd} content={'Results'} />
            <HamburgerMenuItem to="/bookmarks" icon={faBookmark} content={'Bookmarks'} />
            <HamburgerMenuItem to="/data-requests" icon={faUpload} hasDropdown content={'Content Onboarding'}>
              <HamburgerMenuItemChild
                onClick={() => {
                  setShowRequestModal(true);
                  trackApplicationFeature(SEARCH_APP_FEATURE_LIST.IGD, IGD_ACTIONS.ADD_REQ_FROM_MENU);
                }}
                content="New Request"
              />
              <HamburgerMenuItemChild to="/data-requests/" content="Request Tracker" />
            </HamburgerMenuItem>
            <HamburgerMenuItem to="/settings" icon={faCog} content={<span onClick={onSettingClick}>Settings</span>} />
            <HamburgerMenuItem
              icon={faExternalLinkAlt}
              content={<span onClick={userGuide}>User Guide</span>}
            ></HamburgerMenuItem>
            <HamburgerMenuItem icon={faSignOutAlt} content={<span onClick={logout}>Logout</span>} />
          </HamburgerMenu>
          <Logo className="d-none d-md-flex d-lg-flex" img="assets/images/eea-blue-logo.svg" appName="EEA Search" />
          {!mimicHasScrolled && showBanner && (
            <div className="m-3 position-fixed show banner-toaster-container row d-flex w-50">
              <div className="row w-100">
                <div className=" col-11 text-center">
                  <span className="banner-heading">
                    Intelligent Search - The more you use it the smarter it becomes...
                    <u className="cursor-pointer ml-1" onClick={() => props.setCurrentPage(roadmapIndex)}>
                      (Roadmap)
                    </u>
                  </span>
                </div>
                <div className="col-1">
                  <img
                    alt=""
                    src="assets/images/close.png"
                    className="banner-close"
                    onClick={() => {
                      setShowBanner(!showBanner);
                    }}
                  ></img>
                </div>
              </div>
              {/* <p className="marquee">
                <span className="banner-text">
                  This is a pilot version of Intelligent Search. Check back frequently as more sources are continuously
                  being added and search results are refined with increased usage.
                  <u className="cursor-pointer ml-1" onClick={() => props.setCurrentPage(roadmapIndex)}>
                    See Roadmap
                  </u>
                </span>
              </p> */}
            </div>
          )}
        </HeaderLeft>
        <HeaderCenter className="justify-content-center header-center">
          {mimicHasScrolled ? (
            <h1 className="mt-md-2 w-75 mx-auto">
              <SearchBar
                className="mobile-landing-header"
                showAdvanceSearchBtn
                showQuickFilters
                searchSize="lg"
                enableSuggestingPlaceholder
                isSearchExpanded={isSearchExpanded}
                setIsSearchExpanded={setIsSearchExpanded}
                isMobileLandingHeader={isMobile}
                isHeaderLandingPage={true}
              />
            </h1>
          ) : null}
        </HeaderCenter>
        <HeaderRight
          className={classNames('justify-content-end', {
            'justify-content-end flex-lg-grow-0 flex-grow-1 flex-sm-grow-1 flex-md-grow-0': isSearchExpanded,
          })}
        >
          {/* <InfoButton className="d-none d-lg-flex bg-primary rounded" guideUrl={support.guideUrl}>
            {authorizedTabs ? (
              <Dropdown.Item as={Link} to="/feedbacks">
                Feedback
              </Dropdown.Item>
            ) : null}

            <Dropdown.Item>
              <ShowConsentButton />
            </Dropdown.Item>
          </InfoButton> */}
          <ProfileMenu
            className="d-none d-md-flex d-lg-flex bg-primary rounded"
            user={user.fullName}
            onLogOut={logout}
            onlanding={true}
          >
            <DataRequestSubmenu />
          </ProfileMenu>
        </HeaderRight>
      </Header>
      <NewRequestModal
        show={showRequestModal}
        onHide={() => setShowRequestModal(false)}
        onSuccess={() => push('data-requests/my-requests')}
      />
    </>
  );
};

export default EEALandingHeader;
