import * as Types from '@amgen/core';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type IGetQueryGenerativeAIVariables = Types.Exact<{
  query: Types.Scalars['String'];
  content: Types.Scalars['String'];
  qaNum?: Types.Maybe<Types.Scalars['Int']>;
  is_application?: Types.Maybe<Types.Scalars['Boolean']>;
}>;

export type IGetQueryGenerativeAIList = {
  GenerateQA?: Types.Maybe<Pick<Types.IQueryGenerativeAI, 'question' | 'answer' | 'additionalQA'>>;
};

export const GetGenerativeAIList = gql`
  query GenerateQA($query: String!, $content: String!, $qaNum: Int, $is_application: Boolean) {
    generateQA(query: $query, content: $content, qaNum: $qaNum, is_application: $is_application) {
      question
      answer
      additionalQA
      __typename
    }
  }
`;

export function useGetQueryGenerativeAIList(
  baseOptions: Apollo.QueryHookOptions<IGetQueryGenerativeAIList, IGetQueryGenerativeAIVariables>
) {
  return Apollo.useQuery<IGetQueryGenerativeAIList, IGetQueryGenerativeAIVariables>(GetGenerativeAIList, baseOptions);
}
