import { useGetContentQuery } from '@amgen/api';
import { useEnvironment } from '@amgen/core';
import { AppContext, AssetContext, useSearchQueryValue } from '@amgen/shared';
import DOMPurify from 'dompurify';
import React, { useContext } from 'react';
import { Spinner } from 'react-bootstrap';

import './view-asset-content-button.scss';

import AssetActionsMenu from '../asset-actions-menu/asset-actions-menu';

/* eslint-disable-next-line */
export interface ViewAssetContentButtonProps {}

export const ViewAssetContentButton = (props: ViewAssetContentButtonProps) => {
  const { docSolrAsset } = useContext(AssetContext);
  const { currentSource } = useContext(AppContext);
  const { appName } = useEnvironment();
  const query = useSearchQueryValue();
  const { data, loading, error } = useGetContentQuery({
    variables: {
      query,
      appName,
      filters: [`id : "${docSolrAsset?.dataAsset?.id || ''}"`],
      count: 1,
      noShortContent: true,
      sourceType: currentSource,
      userType: sessionStorage.getItem('employeeType') || '',
    },
  });

  return (
    <AssetActionsMenu.Item actionType={'content-btn'}>
      <AssetActionsMenu.Item.Name>{`View All Content`}</AssetActionsMenu.Item.Name>
      <AssetActionsMenu.Item.Popup>
        <AssetActionsMenu.Item.Popup.Heading />
        <AssetActionsMenu.Item.Popup.Body>
          <h3 className="bg-grey px-1">Content</h3>
          {error ? (
            <p className="text-center">Sorry something went wrong. Please try again later.</p>
          ) : loading ? (
            <div className="d-flex justify-content-center w-100">
              <Spinner className="text-primary" animation="border" />
            </div>
          ) : (
            <ul className="p-0">
              {data?.search?.docs?.[0]?.dataAsset?.content?.map((content, index) => (
                <li className="list-unstyled" key={index}>
                  <pre>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(content),
                      }}
                    />
                  </pre>
                </li>
              ))}
            </ul>
          )}
        </AssetActionsMenu.Item.Popup.Body>
      </AssetActionsMenu.Item.Popup>
    </AssetActionsMenu.Item>
  );
};

export default ViewAssetContentButton;
