import React from 'react';
import { Navbar } from 'react-bootstrap';

import './footer.scss';

/* eslint-disable-next-line */
export interface FooterProps {
  className?: string;
}

export const Footer: React.FC<FooterProps> = props => {
  return (
    <Navbar
      className={`d-flex justify-content-start align-items-end py-2 border-top ${props.className}`}
      bg="white"
      expand="lg"
    >
      <img style={{ maxWidth: '110px' }} src="assets/images/amgen-40th-logo.svg" alt="Amgen" />
      <span className="mb-1 px-3" style={{ color: '#0063c3', fontWeight: 'bold' }}>
        {`Powered by: Enterprise Engineering & Analytics [EEA]`}
      </span>
    </Navbar>
  );
};

export default Footer;
