import { DEFAULT_SEARCH_QUERY } from '@amgen/core';
import { removeSpecialChars } from '@amgen/utils';
import { useLocation } from 'react-router-dom';

export function useSearchQueryValue(defaultQuery = DEFAULT_SEARCH_QUERY): string {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const q = query.get('q');
  return q || defaultQuery;
}

export function useEscapedCharSearchQueryValue(defaultQuery = DEFAULT_SEARCH_QUERY): string {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const q = query.get('q');
  return removeSpecialChars(q || defaultQuery);
}
