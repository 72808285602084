import React from 'react';
import { Col, Row } from 'react-bootstrap';

import './clinical-details-info.scss';

import ClinicalDetailsRelatedSection from '../clinical-details-related-section/clinical-details-related-section';

/* eslint-disable-next-line */
export interface ClinicalDetailsInfoProps {}

export const ClinicalDetailsInfo: React.FC<ClinicalDetailsInfoProps> = () => {
  return (
    <Col xs="12" lg="9" className="tabs-container ">
      <Row className=" mt-4">
        <ClinicalDetailsRelatedSection />
      </Row>
    </Col>
  );
};

export default ClinicalDetailsInfo;
