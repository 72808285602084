import { Environment } from '@amgen/core';
const abTestingGroup = JSON.parse(sessionStorage.getItem('abTestingGroup') as string);

export const environment: Environment = {
  production: true,
  api: {
    graphqlUrl: abTestingGroup
      ? 'https://search-dev.amgen.com/discovery/graphql/v2'
      : 'https://search-dev.amgen.com/discovery/graphql',
    nlpUrl: 'https://search-dev.amgen.com/api/v1/semantic-search',
    newsUrl: 'https://amgennews.amgen.com/v1',
    adminUrl: 'https://search-dev.amgen.com/api/v1/admin',
    chatbotUrl:
      'https://amgen-uat.chatbot.aisera.cloud/awc/js?t=46ae4a1b-b247-14ac-e5f0-a319e44ffc61&target_id=aisera-webchat&no_header&session_vars=',
    postLogoutRedirectUri: 'https://search-dev.amgen.com/search/logout',
  },
  newsToken: {
    token:
      'WVNnd3lZSW0rK1VyMlY0aVh0ZHRPQT09XzVzQmhuRzJLblZlUlA2dGluaEhKK01zN2NTUklla2QyS0V1WmNVNzlZbEhhRU1pQWo2bDhUZ2xNbzJIUlJqY1VKMnJrNXhkelBBT3ByOTRWd1B3ZUFXc3Z4MHNZdkhUMGdmRUJHRTRrUStxL3BnNGZ2a3ZpcXFtc0NHditqeDRx',
  },
  oAuth: {
    clientId: '0oanapu6mhwvzIzlw0h7',
    redirectUri: 'https://search-dev.amgen.com/search/callback',
    issuer: 'https://amgentest.oktapreview.com/oauth2/ausjfltczqqfjN16U0h7',
  },
  support: {
    email: 'CTS-Solr-Support@amgen.com',
    guideUrl: 'https://confluence.devops.amgen.com/x/CQKgAg',
    helpcenterUrl: 'https://amgen.sharepoint.com/sites/IntelligentSearchCompanionPortal',
  },
  appHosts: {
    EEA: 'https://search-dev.amgen.com/',
    KMI: 'https://kmisearch-dev.amgen.com/',
    OpsKM: 'https://search-dev.amgen.com/',
    Catalyst: 'https://search-dev.amgen.com/',
    DVIEW: 'https://search-dev.amgen.com/',
  },
  matomo: {
    endPoint: 'https://matomo-ext.devops.amgen.com',
    siteId: 27,
    appDimension: 12,
    dimensions: {
      COST_CENTER: 'dimension1',
      FULL_NAME: 'dimension2',
      FUNCTIONAL_AREA: 'dimension3',
      COST_CENTER_NAME: 'dimension4',
      SORT: 'dimension5',
      DOCUMENT_ID: 'dimension6',
      AB_DOCUMENT_ID: 'dimension25',
      DOCUMENT_POSITION: 'dimension7',
      AB_DOCUMENT_POSITION: 'dimension26',
      PAGE_NAME: 'dimension8',
      APP_NAME: 'dimension9',
      DATA_SOURCE: 'dimension10',
      BOOKMARK: 'dimension11',
      SAVED_SEARCH: 'dimension12',
      FILTER_NAME: 'dimension13',
      PAGINATION: 'dimension14',
      CHATBOT_QUERIES: 'dimension15',
      COMPANY_CODE: 'dimension22',
      GENAI_QNA_QUERIES: 'dimension23',
      USER_GROUP: 'dimension24',
      CONVERSATIONAL_SEARCH_QNA_QUERIES: 'dimension27',
    },
  },
  chatbotAppId: '1c7766d2f4aa7322120577b98f8ac9b2',
  clusterSearch: false,
  msal: {
    clientId: 'bafa0770-6c30-4cd9-8e3f-6a40692c4e4c',
    authority: 'https://login.microsoftonline.com/4b4266a6-1368-41af-ad5a-59eb634f7ad8/',
    redirectUri: 'https://search-dev.amgen.com/',
  },
};
