import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useState } from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';

import './request-search-box.scss';

export interface RequestSearchBoxProps {
  query: string;
  onSearch: (query: string) => void;
  placeholder: string;
  isMobile?: boolean;
}

export const RequestSearchBox = (props: RequestSearchBoxProps) => {
  return (
    <InputGroup className="w-100">
      <div onClick={() => props.onSearch(props.query)}>
        <InputGroup.Text className="bg-white border-right-0 content-onboarding-search-box">
          <FontAwesomeIcon icon={faSearch} className="text-primary" />
        </InputGroup.Text>
      </div>
      <FormControl
        type="search"
        className={classNames('border-left-0 pl-0', { 'fs-11': props.isMobile })}
        placeholder={props.placeholder}
        value={props.query}
        onChange={e => props.onSearch(e.target.value)}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === 'Enter') {
            props.onSearch(props.query);
          }
        }}
      />
    </InputGroup>
  );
};

export default RequestSearchBox;
