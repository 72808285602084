import * as Types from '@amgen/core';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type IApplicationInfoResponseFragment = Pick<Types.IApplicationInfoResponse, 'totalCount'> & {
  items?: Types.Maybe<
    Array<
      Types.Maybe<
        Pick<
          Types.IApplicationInfo,
          | 'id'
          | 'name'
          | 'vendor'
          | 'description'
          | 'image'
          | 'url'
          | 'type'
          | 'parent'
          | 'active'
          | 'category'
          | 'group'
        >
      >
    >
  >;
};

export type IGetDataSourceDetailsQueryVariables = Types.Exact<{
  appName?: Types.Maybe<Types.ApplicationName>;
  page?: Types.Maybe<Types.Scalars['Int']>;
  count?: Types.Maybe<Types.Scalars['Int']>;
  userType?: Types.Maybe<Types.Scalars['String']>;
}>;

export type IGetDataSourceDetailsQuery = { getDataSourceDetails: IApplicationInfoResponseFragment };

export type IGetMyApplicationDetailsQueryVariables = Types.Exact<{
  page?: Types.Maybe<Types.Scalars['Int']>;
  count?: Types.Maybe<Types.Scalars['Int']>;
}>;

export type IGetMyApplicationDetailsQuery = { getMyApplicationDetails?: Types.Maybe<IApplicationInfoResponseFragment> };

export const ApplicationInfoResponseFragmentDoc = gql`
  fragment ApplicationInfoResponse on ApplicationInfoResponse {
    totalCount
    items {
      id
      name
      vendor
      description
      image
      url
      type
      parent
      active
      category
      group
    }
  }
`;
export const GetDataSourceDetailsDocument = gql`
  query GetDataSourceDetails($appName: ApplicationName, $page: Int, $count: Int, $userType: String) {
    getDataSourceDetails(applicationName: $appName, page: $page, count: $count, userType: $userType) {
      ...ApplicationInfoResponse
    }
  }
  ${ApplicationInfoResponseFragmentDoc}
`;
export function useGetDataSourceDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<IGetDataSourceDetailsQuery, IGetDataSourceDetailsQueryVariables>
) {
  return Apollo.useQuery<IGetDataSourceDetailsQuery, IGetDataSourceDetailsQueryVariables>(
    GetDataSourceDetailsDocument,
    baseOptions
  );
}
export function useGetDataSourceDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetDataSourceDetailsQuery, IGetDataSourceDetailsQueryVariables>
) {
  return Apollo.useLazyQuery<IGetDataSourceDetailsQuery, IGetDataSourceDetailsQueryVariables>(
    GetDataSourceDetailsDocument,
    baseOptions
  );
}
export type GetDataSourceDetailsQueryHookResult = ReturnType<typeof useGetDataSourceDetailsQuery>;
export type GetDataSourceDetailsLazyQueryHookResult = ReturnType<typeof useGetDataSourceDetailsLazyQuery>;
export type GetDataSourceDetailsQueryResult = Apollo.QueryResult<
  IGetDataSourceDetailsQuery,
  IGetDataSourceDetailsQueryVariables
>;
export const GetMyApplicationDetailsDocument = gql`
  query GetMyApplicationDetails($page: Int, $count: Int) {
    getMyApplicationDetails(page: $page, count: $count) {
      ...ApplicationInfoResponse
    }
  }
  ${ApplicationInfoResponseFragmentDoc}
`;
export function useGetMyApplicationDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<IGetMyApplicationDetailsQuery, IGetMyApplicationDetailsQueryVariables>
) {
  return Apollo.useQuery<IGetMyApplicationDetailsQuery, IGetMyApplicationDetailsQueryVariables>(
    GetMyApplicationDetailsDocument,
    baseOptions
  );
}
export function useGetMyApplicationDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetMyApplicationDetailsQuery, IGetMyApplicationDetailsQueryVariables>
) {
  return Apollo.useLazyQuery<IGetMyApplicationDetailsQuery, IGetMyApplicationDetailsQueryVariables>(
    GetMyApplicationDetailsDocument,
    baseOptions
  );
}
export type GetMyApplicationDetailsQueryHookResult = ReturnType<typeof useGetMyApplicationDetailsQuery>;
export type GetMyApplicationDetailsLazyQueryHookResult = ReturnType<typeof useGetMyApplicationDetailsLazyQuery>;
export type GetMyApplicationDetailsQueryResult = Apollo.QueryResult<
  IGetMyApplicationDetailsQuery,
  IGetMyApplicationDetailsQueryVariables
>;
