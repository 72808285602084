import * as Types from '@amgen/core';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type IAddFeedbackMutationVariables = Types.Exact<{
  feedback?: Types.Maybe<Types.IFeedbackInput>;
}>;

export type IAddFeedbackMutation = {
  addFeedback?: Types.Maybe<
    { __typename: 'GenericResponse' } & Pick<Types.IGenericResponse, 'status' | 'message' | 'error'>
  >;
};

export const AddFeedbackDocument = gql`
  mutation AddFeedback($feedback: FeedbackInput) {
    addFeedback(feedback: $feedback) {
      status
      message
      error
      __typename
    }
  }
`;
export type IAddFeedbackMutationFn = Apollo.MutationFunction<IAddFeedbackMutation, IAddFeedbackMutationVariables>;
export function useAddFeedbackMutation(
  baseOptions?: Apollo.MutationHookOptions<IAddFeedbackMutation, IAddFeedbackMutationVariables>
) {
  return Apollo.useMutation<IAddFeedbackMutation, IAddFeedbackMutationVariables>(AddFeedbackDocument, baseOptions);
}
export type AddFeedbackMutationHookResult = ReturnType<typeof useAddFeedbackMutation>;
export type AddFeedbackMutationResult = Apollo.MutationResult<IAddFeedbackMutation>;
export type AddFeedbackMutationOptions = Apollo.BaseMutationOptions<
  IAddFeedbackMutation,
  IAddFeedbackMutationVariables
>;
