import { FacetsPanelContext, FacetsPanelProvider, useOutsideClick } from '@amgen/shared';
import classnames from 'classnames';
import React, { useContext, useRef } from 'react';
import { ButtonGroup, Dropdown } from 'react-bootstrap';

import './search-box-advance-search-dropdown.scss';

import AdvanceSearch from '../../advance-search/advance-search';
import { SearchBoxContext } from '../../search-box-context-provider/search-box-context';

/* eslint-disable-next-line */
export interface SearchBoxAdvanceSearchDropdownProps {
  setBlockScroll?: (value: boolean) => void;
}

export const SearchBoxAdvanceSearchDropdown: React.FC<SearchBoxAdvanceSearchDropdownProps> = props => {
  const {
    showAdvanceSearch,
    showAdvSearch,
    setShowAdvSearch,
    searchSize,
    setContentSelected,
    setSearchQuery,
    searchQuery,
    applyFilter,
    setDateFilterSelected,
  } = useContext(SearchBoxContext);
  const { selectedFilters, setSelectedFilters } = useContext(FacetsPanelContext);

  const advSearchRef = useRef<any>(null);

  useOutsideClick(advSearchRef, () => setShowAdvSearch(false));

  return showAdvanceSearch ? ( // this only renders when on landing page <InputGroup append> is rendered to give rounded boundary>
    <Dropdown as={ButtonGroup} show={showAdvSearch} alignRight>
      <Dropdown.Toggle
        split
        variant="outline-primary"
        id="advaned-search-toggle"
        className={classnames('advance-search-btn rounded-circle')}
        title="Search filtered per criteria like source, timelines, therapeutic areas, etc."
        onClick={() => setShowAdvSearch(!showAdvSearch)}
        style={{
          backgroundColor: '#87a3bf',
          color: 'white',
          borderColor: '#87a3bf',
          width: '38px',
          fontSize: '33px',
        }}
      />

      <Dropdown.Menu
        ref={advSearchRef}
        id="advance-search-ref"
        className={classnames('advance-search-panel', {
          'mr-3': searchSize,
          'ml-n3': !searchSize,
        })}
      >
        <FacetsPanelProvider>
          <AdvanceSearch
            onSearch={() => setSearchQuery(searchQuery)}
            onCancel={() => setShowAdvSearch(false)}
            setContentSelected={dataSources => setContentSelected(dataSources)}
            searchSize={searchSize}
            onFilter={() => applyFilter(searchQuery)}
            selectedFilters={selectedFilters}
            setSelectedFilters={() => setSelectedFilters(selectedFilters)}
            dateFilterSelected={(value: boolean) => setDateFilterSelected(value)}
            showAdvSearch={showAdvSearch}
            setBlockScroll={props.setBlockScroll}
          />
        </FacetsPanelProvider>
      </Dropdown.Menu>
    </Dropdown>
  ) : null;
};

export default SearchBoxAdvanceSearchDropdown;
