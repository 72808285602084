import { useDateFilterOperations, useFilterCategoryOperations } from '@amgen/feature-facets';
import { FacetsPanelContext, FiltersObject } from '@amgen/shared';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

import './date-range-input.scss';

import DateRangeDatepicker from './date-range-datepicker/date-range-datepicker';
import DateRangeDropdown, { IDateRangeValue } from './date-range-dropdown/date-range-dropdown';

export interface DateRangeInputProps {
  searchSize?: 'sm' | 'lg';
  selectedFilters: FiltersObject;
  setSelectedFilters: (selectedFilters: FiltersObject) => void;
  dateFilterSelected: (value: boolean) => void;
  dateRange: IDateRangeValue | null;
  setDateRange: (dateRange: IDateRangeValue | null) => void;
  isActive: boolean;
  setIsActive: (value: boolean) => void;
}

export const DateRangeInput = (props: DateRangeInputProps) => {
  const { selectedFilterCategories } = useContext(FacetsPanelContext);
  const { toggleFilterCategory } = useFilterCategoryOperations();
  const { dateRange, setDateRange } = props;
  const customText = 'Date Range';
  const { selectedFilters, isActive, setIsActive, setSelectedFilters, dateFilterSelected } = props;
  const { toSolrStartDate, toSolrEndDate } = useDateFilterOperations();

  useEffect(() => {
    if (!selectedFilters?.filters?.doc_last_modified?.values().next().value) {
      setDateRange({
        label: 'Date Range ',
        value: [{ startDate: new Date(), endDate: new Date(), key: 'selection' }],
      });
      setIsActive(false);
      dateFilterSelected(false);
    } else {
      setIsActive(true);
    }
    // require "component did mount" lifecycle functionality thus disabled the es-lint
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (value: IDateRangeValue) => {
    const { startDate, endDate } = value.value[0];
    const { label } = value;
    setDateRange(value);
    if (label !== 'Date Range ') {
      setSelectedFilters(selectedFilters.removeAll('doc_last_modified'));
      setSelectedFilters(
        selectedFilters.add([toSolrStartDate(startDate), toSolrEndDate(endDate)], 'doc_last_modified')
      );
      props.dateFilterSelected(true);
      setIsActive(true);
      if (!selectedFilterCategories.has('doc_last_modified')) {
        toggleFilterCategory('doc_last_modified');
      }
    } else {
      setSelectedFilters(selectedFilters.removeAll('doc_last_modified'));
      props.dateFilterSelected(false);
      setIsActive(false);
    }
  };

  return (
    <Row noGutters className="mt-sm-3">
      <Col xs="12" lg="6">
        <DateRangeDropdown value={dateRange} onValueChange={handleChange} customValueText={customText} />
      </Col>

      <Col xs="11" lg="5" className="pl-md-0 mt-3 mt-lg-0 d-flex">
        <DateRangeDatepicker
          value={dateRange}
          onValueChange={handleChange}
          customValueText={customText}
          disabled={dateRange?.label !== customText}
          active={isActive}
        />
      </Col>
      {isActive && (
        <span
          className="facet-clear-icon cursor-pointer align-self-center pl-2 mr-n2 mr-lg-0 mt-3 mt-lg-0"
          onClick={() =>
            handleChange({
              label: 'Date Range ',
              value: [{ startDate: new Date(), endDate: new Date(), key: 'selection' }],
            })
          }
          title="Clear all values for this Category"
        >
          <FontAwesomeIcon icon={faTimesCircle} />
        </span>
      )}
    </Row>
  );
};

export default DateRangeInput;
