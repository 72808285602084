import DOMPurify from 'dompurify';

export const downloadFile = (url: string, fileName?: string) => {
  const a = document.createElement('a');
  a.href = DOMPurify.sanitize(url);
  a.download = fileName || 'Download-File';
  a.target = '_blank';
  a.style.display = 'none';
  document.body.appendChild(DOMPurify.sanitize(a));
  a.click();
  document.body.removeChild(a);
};
