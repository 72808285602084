import React, { useCallback, useMemo, useState } from 'react';

import { BookmarkContext, IBookmarkContextValue } from './context';

export const BookmarkProvider: React.FC = ({ children }) => {
  const [deleted, setDeleted] = useState<Set<string>>(new Set());
  const [current, setCurrent] = useState<Set<string>>(new Set());

  const addToDeleted = useCallback((assetId: string) => {
    setDeleted(deletedBookmarks => {
      const copy = new Set(deletedBookmarks);
      copy.add(assetId);
      return copy;
    });
  }, []);

  const removeFromDeleted = useCallback((assetId: string) => {
    setDeleted(deletedBookmarks => {
      const copy = new Set(deletedBookmarks);
      copy.delete(assetId);
      return copy;
    });
  }, []);

  const addToCurrent = useCallback((assetId: string) => {
    setCurrent(currentBookmarks => {
      const copy = new Set(currentBookmarks);
      copy.add(assetId);
      return copy;
    });
  }, []);

  const removeFromCurrent = useCallback((assetId: string) => {
    setCurrent(currentBookmarks => {
      const copy = new Set(currentBookmarks);
      copy.delete(assetId);
      return copy;
    });
  }, []);

  const value = useMemo<IBookmarkContextValue>(
    () => ({
      deleted,
      current,
      addToCurrent,
      addToDeleted,
      removeFromCurrent,
      removeFromDeleted,
    }),
    [addToCurrent, addToDeleted, current, deleted, removeFromCurrent, removeFromDeleted]
  );

  return <BookmarkContext.Provider value={value}>{children}</BookmarkContext.Provider>;
};
