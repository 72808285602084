import { FeatureChatbotHeaderButton } from '@amgen/feature-chatbot';
import {
  AISERA_CHATBOT_ACTIONS,
  AppContext,
  FacetsPanelContext,
  MatomoContext,
  ResultViewType,
  SEARCH_APP_FEATURE_LIST,
  SearchContext,
} from '@amgen/shared';
import React, { useContext, useEffect, useRef, useState } from 'react';

import './feature-chatbot.scss';

/* eslint-disable-next-line */
export interface FeatureChatbotProps {
  showChatBot: boolean;
  searchTerm: string;
  chatbotURL: string;
  configData: any;
}

export function FeatureChatbot(props: FeatureChatbotProps) {
  const [searchTerm, setSearchTerm] = useState(props.searchTerm);
  const { isIframeRendered, setIsIframeRendered, selectedViewType, searchQueryVariables } = useContext(SearchContext);
  const { query } = searchQueryVariables;
  const { trackApplicationFeature } = useContext(MatomoContext);
  const { isChatbotInvoke, setIsChatbotInvoke } = useContext(FacetsPanelContext);
  const { user, selectedResultTab, setSelectedResultTab, setActiveId } = useContext(AppContext);
  const [isChatbotReady, setIsChatbotReady] = useState(false);
  const isFirstRun = useRef(true);

  useEffect(() => {
    setSearchTerm(props.searchTerm);
    if (searchTerm !== props.searchTerm || (searchTerm === '*:*' && props.searchTerm === '*:*')) {
      if (props.showChatBot) {
        setIsChatbotInvoke(true);
      } else {
        const element = document.getElementsByTagName('iframe');
        if (element.length === 0) {
          return;
        }
        // show the toster message
      }
      if (isFirstRun.current) {
      } else {
        const element = document.getElementsByTagName('iframe');
        if (element.length > 0) {
          setTimeout(() => {
            const timeDelay = 500;
            postChatbotMessage(timeDelay);
          }, 250);
        }
      }
      isFirstRun.current = false;
    }
  }, [props.searchTerm, searchTerm, isIframeRendered, isChatbotInvoke, user]);

  useEffect(() => {
    window.addEventListener('message', event => {
      let message = event.data;
      if (typeof message !== 'string' || !message.startsWith('{')) {
        return;
      }
      message = JSON.parse(message);
      if (message.type === 'aisera.webchat.ready' && !isChatbotReady) {
        setIsChatbotReady(true);
      }
    });
  }, []);

  useEffect(() => {
    if (isChatbotReady) {
      const timeDelay = 500;
      postChatbotMessage(timeDelay);
    }
  }, [isChatbotReady]);

  useEffect(() => {
    if (selectedResultTab === 'Chat') {
      window.scrollTo(0, 0);
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'scroll';
    }
    const frameCreated = document.getElementById('aisera-webchat');
    if (frameCreated) {
      if (selectedResultTab !== 'Chat') {
        frameCreated.style.display = 'none';
      } else {
        frameCreated.style.display = 'block';
      }
    }
  }, [selectedResultTab]);

  const isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
    input !== null && input.tagName === 'IFRAME';

  const postChatbotMessage = timeDelay => {
    const message = {
      type: 'aisera.webchat.open',
      text: props.searchTerm,
    };
    const frame = document.getElementById('aisera-webchat');

    setTimeout(() => {
      if (frame) {
        if (isIFrame(frame) && frame.contentWindow && props.searchTerm !== '*:*') {
          frame.contentWindow.postMessage(JSON.stringify(message), '*');
        }
      }
    }, timeDelay);
  };

  const invokeChatbot = () => {
    const element = document.getElementsByTagName('iframe');
    if (props.showChatBot && element.length === 0 && user.emailId !== undefined) {
      const userInfo = {
        userEmail: user.emailId,
        userFullName: user.fullName,
        accountId: user.emailId,
      };

      const ifrm = document.createElement('iframe');
      ifrm.id = 'aisera-webchat';
      ifrm.style.zIndex = '0';

      const ele = document.getElementById('va-chatbot');
      if (ele) {
        const s = document.createElement('script');
        s.async = true;
        s.setAttribute('src', props.chatbotURL + JSON.stringify(userInfo));

        ifrm.appendChild(s);
        ele.appendChild(ifrm);
      }

      setIsIframeRendered(false);
      if (ifrm) {
        if (selectedResultTab !== 'Chat') {
          ifrm.style.display = 'none';
        } else {
          ifrm.style.display = 'block';
        }
      }
    }
  };

  const seeMoreButtonClicked = typeOfInteraction => {
    setSelectedResultTab('Chat');
    setActiveId(2);
    if (typeOfInteraction === 'seeMore') {
      if (props.configData?.response?.invoke_chatbot === true) {
        trackApplicationFeature(
          SEARCH_APP_FEATURE_LIST.AISERA_CHATBOT_INTERACTION_WITH_INTENT,
          AISERA_CHATBOT_ACTIONS.SEE_MORE_CLICKED
        );
      } else if (props.configData?.response?.invoke_chatbot === false || query === '*:*' || query === '') {
        trackApplicationFeature(
          SEARCH_APP_FEATURE_LIST.AISERA_CHATBOT_INTERACTION_WITHOUT_INTENT,
          AISERA_CHATBOT_ACTIONS.SEE_MORE_CLICKED
        );
      }
    } else if (typeOfInteraction === 'minimizedAVA') {
      if (props.configData?.response?.invoke_chatbot === true) {
        trackApplicationFeature(
          SEARCH_APP_FEATURE_LIST.AISERA_CHATBOT_INTERACTION_WITH_INTENT,
          AISERA_CHATBOT_ACTIONS.MINIMIZED_AVA_CLICKED
        );
      } else if (props.configData?.response?.invoke_chatbot === false || query === '*:*' || query === '') {
        trackApplicationFeature(
          SEARCH_APP_FEATURE_LIST.AISERA_CHATBOT_INTERACTION_WITHOUT_INTENT,
          AISERA_CHATBOT_ACTIONS.MINIMIZED_AVA_CLICKED
        );
      }
    }
  };

  return (
    <div id="va-chatbot" className={selectedResultTab !== 'Chat' ? '' : 'chatbot-main-chat va-responsive'}>
      {selectedResultTab === 'Chat' || selectedViewType === ResultViewType.Table ? null : (
        <div>
          <FeatureChatbotHeaderButton
            configData={props.configData}
            searchTerm={searchTerm}
            seeMoreButtonClicked={seeMoreButtonClicked}
          />
        </div>
      )}
      {invokeChatbot()}
    </div>
  );
}

export default FeatureChatbot;
