import { useMemo } from 'react';

import { useAPIConfig } from '../context';
import { usePost } from './http';

interface IChatbotPayload {
  query: Object;
}

interface IChatbotLogoutPayload {
  userEmail: Object;
}

interface IConfigData {
  query_term: string;
  invoke_chatbot: boolean;
}

interface IConfigDataForLogoutQuery {
  response: string;
}

export function useChatbotConfigQuery(query: string, chatbotChannelQuery: string) {
  const payload = useMemo(() => ({ query: `${query}`, chatbot_channel: `${chatbotChannelQuery}` }), [
    query,
    chatbotChannelQuery,
  ]);
  const { adminUrl } = useAPIConfig();
  const { data, loading, error } = usePost<IConfigData, IChatbotPayload>(`${adminUrl}/get-chatbot-status`, payload);

  return {
    loading,
    error,
    response: Object.keys(data ?? {}).length === 0 ? null : data,
  };
}

export function useChatbotLogoutQuery(query: string) {
  const payload = useMemo(() => ({ userEmail: `${query}` }), [query]);
  const { adminUrl } = useAPIConfig();
  const { data, loading, error } = usePost<IConfigDataForLogoutQuery, IChatbotLogoutPayload>(
    `${adminUrl}/chatbot-session-logout`,
    payload
  );

  return {
    loading,
    error,
    response: Object.keys(data ?? {}).length === 0 ? null : data,
  };
}
