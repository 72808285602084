import { PageType } from '@amgen/core';
import { useWindowSizeListener } from '@amgen/shared';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

import './export-requests-button.scss';

import ExportRequestsPopup from '../export-requests-popup/export-requests-popup';

export interface ExportRequestsButtonProps {
  pageType: PageType;
  page?: string;
}

export const ExportRequestsButton = (props: ExportRequestsButtonProps) => {
  const [show, setShow] = useState(false);
  const isMobile = useWindowSizeListener();
  return (
    <>
      <Button
        variant="outline-primary"
        className={classNames('mx-3', { 'd-flex': isMobile })}
        size="sm"
        onClick={() => setShow(true)}
      >
        <FontAwesomeIcon icon={faExternalLinkAlt} className={classNames({ 'mt-1': isMobile })} />
        <span className="ml-2">Export</span>
      </Button>
      <ExportRequestsPopup show={show} onHide={() => setShow(false)} pageType={props.pageType} page={props.page} />
    </>
  );
};

export default ExportRequestsButton;
