import { KnowledgePanelAttributes, KnowledgePanelDescription, RelatedProducts } from '@amgen/feature-knowledge-panel';
import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';

import './clinical-details-explore-section.scss';

/* eslint-disable-next-line */
export interface ClinicalDetailsExploreSectionProps {}

export const ClinicalDetailsExploreSection: React.FC<ClinicalDetailsExploreSectionProps> = () => {
  return (
    <Col xs="12">
      <Row>
        <Col xs="12">
          <Row>
            <KnowledgePanelDescription isexploreExpanded={true} />
            <KnowledgePanelAttributes isexploreExpanded={true} />
            <RelatedProducts isexploreExpanded={true} />
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default ClinicalDetailsExploreSection;
