import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

import './attendee.scss';

export interface IAttendee {
  name?: string;
  type?: string;
  city?: string;
  address?: string;
  country?: string;
}

export interface AttendeeProps {
  attendee: IAttendee;
}

export const Attendee = (props: AttendeeProps) => {
  return (
    <Card className="h-100 attendee-item shadow-sm rounded">
      <Card.Body>
        {Object.entries(props.attendee)
          .filter(([_, value]) => !!value)
          .map(([key, value]) => (
            <Row key={key}>
              <Col xs="6" lg="4" className="attribute-name d-flex">
                <h4 className="mb-0 text-capitalize" style={{ fontWeight: 'bold' }}>
                  {key}:
                </h4>
              </Col>
              <Col xs="6" lg="6" className="d-flex flex-column">
                {value}
              </Col>
            </Row>
          ))}
      </Card.Body>
    </Card>
  );
};

export default Attendee;
