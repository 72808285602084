import { LinkToggler } from '@amgen/components';
import { safeFormatDateString } from '@amgen/utils';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import { useField } from 'formik';
import React from 'react';
import { Card, Col, Dropdown, Form, Row } from 'react-bootstrap';
import { Token, Typeahead, useToken } from 'react-bootstrap-typeahead';

import './subscribe-search-details.scss';

import { isValidEmail, NEVER_END_DATE, SUBSCRIPTION_TYPE, TOP_RESULT_COUNT } from '../../model';

export interface SubscribeSearchDetailsProps {
  isSubscribe: boolean;
}

export const SubscribeSearchDetails: React.FC<SubscribeSearchDetailsProps> = props => {
  const user = JSON.parse(sessionStorage.user);
  const [startDateField, , startDateFieldHelper] = useField<string>('subscrptnStartDate');
  const [endDateField, endDateMeta, endDateFieldHelper] = useField<string>('subscrptnEndDate');
  const [typeField, , typeFieldHelper] = useField<SUBSCRIPTION_TYPE>('subscrptnType');
  const [mailIdsField, mailIdsMeta, mailIdsFieldHelper] = useField<string[]>('subscrptnMailIds');
  const [resultCountField, , resultCountFieldHelper] = useField<number>('subscrptnRecordCount');

  //[todo] break this into sub form inputs
  return (
    <Card.Body className="p-4">
      <Form.Group as={Row} noGutters>
        <Form.Label column xs="12" md="3" className="font-weight-bold">
          Recipients:
          <Form.Text className="text-muted m-0 pr-1 small">Select users who have access to dataset</Form.Text>
        </Form.Label>

        <Col xs="12" md="6" className="label-color">
          <Typeahead
            allowNew
            id="subscribe-recipients"
            multiple
            defaultSelected={mailIdsField.value}
            options={mailIdsField.value.length ? mailIdsField.value : user.emailId ? [user.emailId] : []}
            placeholder="Recipients for email notifications"
            selected={mailIdsField.value}
            emptyLabel="Type to add recipients for email notifications and Click enter"
            //This callback on allowNew prop,is passing objects instead of string[]
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(s: any) => {
              if (s.length) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const c = s.map((x: any) => (x.label ? x.label.trim() : x.trim()));
                mailIdsFieldHelper.setValue([...new Set(c)] as string[]);
              } else {
                mailIdsFieldHelper.setValue([]);
              }
            }}
            renderToken={(x: any, props, index) => {
              return <EmailToken {...props} key={index} option={x.label ? x.label.trim() : x.trim()} />;
            }}
            isInvalid={!!mailIdsMeta.error}
          />
          <Form.Text className="text-muted">
            &#60;recipient-email&#62;<b>@amgen.com</b>.
          </Form.Text>
          <div className="text-left invalid-feedback d-block">{mailIdsMeta.error}</div>
        </Col>
      </Form.Group>
      <Form.Group as={Row} noGutters>
        <Form.Label column xs="12" md="3" className="font-weight-bold">
          Starts On:
        </Form.Label>

        <Col xs="12" md="6">
          <Form.Control
            type="date"
            placeholder="Start date"
            min={format(new Date(), 'yyyy-MM-dd')}
            value={safeFormatDateString(startDateField.value)}
            onChange={e => startDateFieldHelper.setValue(e.target.value)}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} noGutters>
        <Form.Label column xs="12" md="3" className="font-weight-bold">
          Ends On:
        </Form.Label>

        <Col xs="12" md="9">
          <Form.Check inline type="radio" name={endDateField.name} label="never">
            <Form.Check.Input
              type="radio"
              name={endDateField.name}
              onClick={() => endDateFieldHelper.setValue(NEVER_END_DATE)}
              checked={endDateField.value === NEVER_END_DATE}
              readOnly
            />
            <Form.Check.Label onClick={() => endDateFieldHelper.setValue(NEVER_END_DATE)}>Never</Form.Check.Label>
          </Form.Check>
          <Form.Check inline type="radio" name={endDateField.name} label="custom-date" className="w-50">
            <Form.Check.Input
              type="radio"
              name={endDateField.name}
              checked={endDateField.value !== NEVER_END_DATE}
              readOnly
            />
            <Form.Control
              className="hidden-input-date"
              type="date"
              placeholder="End date"
              min={safeFormatDateString(startDateField.value)}
              value={
                endDateField.value && endDateField.value !== NEVER_END_DATE
                  ? safeFormatDateString(endDateField.value)
                  : ''
              }
              onChange={e => endDateFieldHelper.setValue(e.target.value)}
              isInvalid={endDateMeta.touched && !!endDateMeta.error}
            />

            <Form.Check.Label className="col-md-12 p-0">
              <Form.Control
                type="date"
                placeholder="End date"
                min={safeFormatDateString(startDateField.value)}
                value={
                  endDateField.value && endDateField.value !== NEVER_END_DATE
                    ? safeFormatDateString(endDateField.value)
                    : ''
                }
                onChange={e => endDateFieldHelper.setValue(e.target.value)}
                isInvalid={endDateMeta.touched && !!endDateMeta.error}
              />
              <Form.Control.Feedback type="invalid" className="text-left">
                {endDateMeta.error}
              </Form.Control.Feedback>
            </Form.Check.Label>
          </Form.Check>
        </Col>
      </Form.Group>

      <Form.Group as={Row} noGutters>
        <Form.Label column xs="12" md="3" className="font-weight-bold">
          Frequency:
        </Form.Label>

        <Col xs="12" md="3">
          <div className="repeat-type-container">
            <Dropdown
              alignRight
              onSelect={(value, e) => {
                typeFieldHelper.setValue(value as SUBSCRIPTION_TYPE);
              }}
            >
              <Dropdown.Toggle
                as={LinkToggler}
                id="repeat-type-toggle"
                type="button"
                className="px-3 py-2 bg-light w-75 border d-flex justify-content-between align-items-center"
              >
                <span>{typeField.value}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {Object.values(SUBSCRIPTION_TYPE).map(x => (
                  <Dropdown.Item key={x} className="px-2" eventKey={x}>
                    {x}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <Form.Text className="text-muted" style={{ whiteSpace: 'nowrap' }}>
            {typeField.value === 'Monthly'
              ? 'Receive Notification on every first Monday of the month'
              : typeField.value === 'Weekly'
              ? 'Receive Notification on every first day of the week'
              : ''}
          </Form.Text>
        </Col>
      </Form.Group>
      <Form.Group as={Row} noGutters>
        <Form.Label column xs="12" md="3" className="font-weight-bold">
          Show Top:
        </Form.Label>

        <Col xs="12" md="3">
          <div className="repeat-type-container">
            <Dropdown
              alignRight
              onSelect={(value, e) => {
                resultCountFieldHelper.setValue(Number(value));
              }}
            >
              <Dropdown.Toggle
                as={LinkToggler}
                id="repeat-type-toggle"
                className="px-3 py-2 bg-light w-75 border d-flex justify-content-between align-items-center"
              >
                <span>{resultCountField.value} Results</span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {TOP_RESULT_COUNT.map(x => (
                  <Dropdown.Item key={x} className="px-2" eventKey={x.toString()}>
                    {x}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <Form.Text className="text-muted" style={{ whiteSpace: 'nowrap' }}>
            Number of documents to be displayed in the email notification
          </Form.Text>
        </Col>
      </Form.Group>
    </Card.Body>
  );
};
export default SubscribeSearchDetails;

const EmailToken: React.FC<any> = props => {
  const { active, onRemove, ref, ...otherProps } = useToken(props);
  const isInvalid = !isValidEmail(props.option);
  return (
    <Token {...otherProps} className={isInvalid ? 'bg-danger text-white blink-1 mw-100' : 'mw-100'}>
      <span
        className={props.option.length > 60 ? 'mr-2 second-line-text-overflow-ellipsis' : 'mr-2'}
        title={isInvalid ? 'Invalid Email' : props.option}
      >
        {props.option}
      </span>
      <FontAwesomeIcon icon={faTimes} onClick={onRemove} className="cursor-pointer" />
    </Token>
  );
};
