import * as Types from '@amgen/core';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type IRoadmapFeatureFragment = Types.MakeOptional<
  Pick<Types.IRoadmapFeature, 'id' | 'name' | 'startDate' | 'endDate' | 'subtitle' | 'description' | 'businessValue'>,
  'subtitle' | 'description' | 'businessValue'
>;

export type IGetRoadmapsQueryVariables = Types.Exact<{
  parentThemeId?: Types.Maybe<Types.Scalars['String']>;
  featureId?: Types.Maybe<Types.Scalars['String']>;
  noDetails?: Types.Maybe<Types.Scalars['Boolean']>;
}>;

export type IGetRoadmapsQuery = {
  getRoadmaps?: Types.Maybe<
    Array<
      Types.Maybe<
        Pick<Types.IRoadmapTheme, 'name' | 'id' | 'color'> & {
          features?: Types.Maybe<Array<Types.Maybe<IRoadmapFeatureFragment>>>;
        }
      >
    >
  >;
};

export const RoadmapFeatureFragmentDoc = gql`
  fragment RoadmapFeature on RoadmapFeature {
    id
    name
    startDate
    endDate
    subtitle @skip(if: $noDetails)
    description @skip(if: $noDetails)
    businessValue @skip(if: $noDetails)
  }
`;
export const GetRoadmapsDocument = gql`
  query getRoadmaps($parentThemeId: String, $featureId: String, $noDetails: Boolean = true) {
    getRoadmaps(parentThemeId: $parentThemeId, featureId: $featureId) {
      name
      id
      color
      features {
        ...RoadmapFeature
      }
    }
  }
  ${RoadmapFeatureFragmentDoc}
`;
export function useGetRoadmapsQuery(
  baseOptions?: Apollo.QueryHookOptions<IGetRoadmapsQuery, IGetRoadmapsQueryVariables>
) {
  return Apollo.useQuery<IGetRoadmapsQuery, IGetRoadmapsQueryVariables>(GetRoadmapsDocument, baseOptions);
}
export function useGetRoadmapsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetRoadmapsQuery, IGetRoadmapsQueryVariables>
) {
  return Apollo.useLazyQuery<IGetRoadmapsQuery, IGetRoadmapsQueryVariables>(GetRoadmapsDocument, baseOptions);
}
export type GetRoadmapsQueryHookResult = ReturnType<typeof useGetRoadmapsQuery>;
export type GetRoadmapsLazyQueryHookResult = ReturnType<typeof useGetRoadmapsLazyQuery>;
export type GetRoadmapsQueryResult = Apollo.QueryResult<IGetRoadmapsQuery, IGetRoadmapsQueryVariables>;
