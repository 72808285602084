import { MutableRefObject, useCallback, useEffect } from 'react';

export function useOutsideClick(ref: MutableRefObject<any>, callback: () => void, enabled = true) {
  const handleClickOutside = useCallback(
    (e: any) => {
      if (ref.current?.contains(e.target)) return;
      callback();
    },
    [callback, ref]
  );

  useEffect(() => {
    if (enabled) {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  });
}
