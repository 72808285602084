import { ClinicalContext } from '@amgen/shared';
import React, { useContext, useState } from 'react';
import { Col } from 'react-bootstrap';

import './knowledge-panel-description.scss';

/* eslint-disable-next-line */
export interface KnowledgePanelDescriptionProps {
  isexploreExpanded: boolean;
}

export const KnowledgePanelDescription: React.FC<KnowledgePanelDescriptionProps> = ({ isexploreExpanded }) => {
  const { product } = useContext(ClinicalContext);
  const [isExpanded, setIsExpanded] = useState(false);

  const wordsToShow = isExpanded ? product?.description : product?.description?.split(/\s+/).slice(0, 30).join(' ');

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  return product?.description ? (
    <Col
      xs="12"
      className={
        isexploreExpanded
          ? 'd-flex flex-column mt-3 mb-2 align-items-start fs-13'
          : 'd-flex flex-column mt-3 mb-2 align-items-start fs-13 p-0'
      }
    >
      {product?.description?.split(/\s+/).length > 30 ? (
        <span>
          {wordsToShow}

          <span onClick={toggleExpansion} className="description-toggle">
            {isExpanded ? ' Show Less' : ' ...Show More'}
          </span>
        </span>
      ) : (
        <span>{product?.description}</span>
      )}
    </Col>
  ) : null;
};

export default KnowledgePanelDescription;
