import React from 'react';

import './knowledge-panel-overview.scss';

import KnowledgePanelAttributes from '../knowledge-panel-attributes/knowledge-panel-attributes';
import KnowledgePanelDescription from '../knowledge-panel-description/knowledge-panel-description';
import RelatedProducts from '../related-products/related-products';

/* eslint-disable-next-line */
export interface KnowledgePanelOverviewProps {}

export const KnowledgePanelOverview: React.FC<KnowledgePanelOverviewProps> = () => {
  return (
    <div id="knowledge-panel-overview">
      <KnowledgePanelDescription isexploreExpanded={false} />
      <KnowledgePanelAttributes isexploreExpanded={false} />
      <RelatedProducts isexploreExpanded={false} />
    </div>
  );
};

export default KnowledgePanelOverview;
