export class SearchTermDocClick {
  constructor(public searchTermObj?: Map<string, Set<string>>) {
    this.searchTermObj = searchTermObj ? new Map([...searchTermObj]) : new Map();
  }

  clone() {
    return new SearchTermDocClick(this.searchTermObj);
  }

  values(searchKey: string) {
    return this.searchTermObj?.get(searchKey);
  }

  add(value: string, searchKey: string) {
    const values = this.searchTermObj?.has(searchKey) ? this.searchTermObj.get(searchKey) : new Set<string>();
    values?.add(value);
    if (values) this.searchTermObj?.set(searchKey, values);
    return new SearchTermDocClick(this.searchTermObj);
  }

  removeSearchKey(searchKey: string) {
    this.searchTermObj?.delete(searchKey);
    return new SearchTermDocClick(this.searchTermObj);
  }

  empty() {
    this.searchTermObj = new Map();
    return new SearchTermDocClick();
  }
}
