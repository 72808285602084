import { useAddDocumentFeedbackMutation } from '@amgen/api';
import { IDocumentFeedback, useEnvironment } from '@amgen/core';
import { DOCUMENT_INTERACTIONS_ACTIONS, MatomoContext, SEARCH_APP_FEATURE_LIST, useSearchQueryValue } from '@amgen/shared';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import Rating from 'react-rating';

import './doc-feedback-add-form.scss';

/* eslint-disable-next-line */
export interface DocFeedbackAddFormProps {
  assetId: string;
  inPopover?: boolean;
  userRating?: IDocumentFeedback;
  refetchFeedbackMetrics: () => void;
  ratingFromButton?: number;
}

export const DocFeedbackAddForm = (props: DocFeedbackAddFormProps) => {
  const [starRating, setStarRating] = useState(props.userRating?.rating ?? props.ratingFromButton ?? 0);
  const [comment, setComment] = useState(props.userRating?.comment ?? '');
  const [userReview, setUserReview] = useState(props.userRating);
  const [validated, setValidated] = useState(false);
  const { appName } = useEnvironment();
  const user = JSON.parse(sessionStorage.user);
  const searchTerm = useSearchQueryValue();
  const { trackApplicationFeature } = useContext(MatomoContext);

  useEffect(() => {
    if (!props.inPopover) {
      setStarRating(props.ratingFromButton ?? 0);
    }
  }, [props.inPopover, props.ratingFromButton]);

  const [addFeedback, { loading, error }] = useAddDocumentFeedbackMutation({
    onCompleted: () => {
      setTimeout(() => {
        props.refetchFeedbackMetrics();
      }, 200);
    },
  });

  useEffect(() => {
    if (props.userRating?.rating) {
      setStarRating(props.userRating?.rating);
    }
    if (props.userRating?.comment) {
      setComment(props.userRating?.comment);
    }
    if (props.userRating?.id) {
      setUserReview(props.userRating);
    }
  }, [props.userRating, props.userRating?.id]);

  const submitFeedback = (event: React.FormEvent<HTMLFormElement>) => {
    if (event.currentTarget.checkValidity()) {
      if (comment) {
        trackApplicationFeature(
          SEARCH_APP_FEATURE_LIST.DOCUMENT_INTERACTIONS,
          DOCUMENT_INTERACTIONS_ACTIONS.DOCUMENT_FEEDBACK_COMMENT
        );
      }

      if (starRating) {
        trackApplicationFeature(
          SEARCH_APP_FEATURE_LIST.DOCUMENT_INTERACTIONS,
          DOCUMENT_INTERACTIONS_ACTIONS.DOCUMENT_RATING
        );
      }

      addFeedback({
        variables: {
          documentFeedback: {
            rating: starRating,
            comment: comment,
            applicationName: appName,
            userId: user.username,
            userFirstName: user.firstName,
            userLastName: user.fullName.split(',')[0].toString(),
            costCenter: user.subFunctionalArea,
            assetId: props.assetId,
            searchTerm,
          },
          existingId: props.userRating?.id,
        },
      });
    }
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
  };

  const checkForBtnDisable = (): boolean => {
    if (userReview) {
      return starRating === userReview.rating && comment.trim() === userReview.comment;
    } else {
      return !starRating;
    }
  };

  return (
    <div>
      <h2 className="font-weight-bold">
        {props.userRating?.userId ? 'Your Review' : 'Rate and improve search relevancy'}
      </h2>
      <Form className="p-1 custom-form-style" onSubmit={submitFeedback}>
        <Form.Group as={Row} controlId="formBasicEmail">
          <Col sm="12">
            <Rating
              initialRating={starRating}
              className="pt-2"
              fullSymbol={<FontAwesomeIcon icon={faStarSolid} className="text-dark-gold mx-1" size="lg" />}
              emptySymbol={<FontAwesomeIcon icon={faStar} className="text-dark-gold mx-1" size="lg" />}
              onClick={(value: number) => setStarRating(value)}
            />
          </Col>
        </Form.Group>

        <Form.Group controlId="formBasicComment">
          <Form.Control
            as="textarea"
            className="thin-scrollbar "
            placeholder="Write your review here"
            rows={props.inPopover ? 2 : 3}
            value={comment}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setComment(e.target.value)}
            maxLength={500}
            isValid={comment.length > 0 && comment.length <= 500 && comment.trim() !== ''}
          />
          <Form.Control.Feedback type="valid" className="text-left text-black-50">{`Characters left: ${
            500 - comment.length
          }`}</Form.Control.Feedback>
        </Form.Group>
        <Button
          variant="primary"
          type="submit"
          className="px-4 py-0 float-right mb-2 custom-review-submit-btn"
          disabled={checkForBtnDisable()}
        >
          Submit
          {loading ? <Spinner animation="border" size="sm" className="mx-2" /> : null}
        </Button>
      </Form>
    </div>
  );
};

export default DocFeedbackAddForm;
