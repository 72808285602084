export enum KnowledgePanelOption {
  OVERVIEW = 'Overview',
  STUDIES = 'Active Clinical Trials',
}
// hiding the clinical trials tab for a temporary release
export const KNOWLEDGE_PANEL_OPTIONS: KnowledgePanelOption[] = [
  KnowledgePanelOption.OVERVIEW,
  KnowledgePanelOption.STUDIES,
];

export type KnowledgePanelTabConfig = { [key in KnowledgePanelOption]?: React.FC };
