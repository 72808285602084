import { IAssetAttribute, IFacetValue } from '@amgen/core';
import { FacetsPanelContext } from '@amgen/shared';
import React, { ChangeEvent, useContext, useMemo, useState } from 'react';
import { FormControl } from 'react-bootstrap';
import { FixedSizeList } from 'react-window';
import { debounce } from 'ts-debounce';

import './facet-dropdown-items.scss';

import FacetValue from '../facet-value/facet-value';

export interface FacetDropdownItemsProps {
  facetValues: IFacetValue[];
  category: IAssetAttribute;
  onFilterToggle?: (category: IAssetAttribute, value: string) => void;
  onClear?: (category: IAssetAttribute) => void;
  categoryName?: string | undefined;
  excludeCount?: boolean;
  showMore?: boolean;
}

export const FacetDropdownItems: React.FC<FacetDropdownItemsProps> = props => {
  const { selectedFilters } = useContext(FacetsPanelContext);
  const categoryItems = selectedFilters.filters?.[props.category] ?? new Set<string>();
  const [searchText, setSearchText] = useState('');
  const ITEM_HEIGHT = 32;

  const filteredValues = useMemo(() => {
    const regex = new RegExp(searchText, 'i');
    return props.facetValues.filter(value => !searchText || regex.test(value.value ?? '')) ?? [];
  }, [props.facetValues, searchText]);

  return (
    <>
      {(props.facetValues.length ?? 0) > 0 && (
        <div className="px-3 mb-2">
          <FormControl
            placeholder="Search"
            onChange={(e: ChangeEvent<HTMLInputElement>) => debounce(setSearchText, 300)(e.target.value.trim())}
          />
        </div>
      )}

      {filteredValues.length > 0 ? (
        <FixedSizeList
          className="thin-scrollbar"
          height={Math.min(filteredValues.length, 6) * ITEM_HEIGHT}
          itemCount={filteredValues.length}
          itemSize={ITEM_HEIGHT}
          width="100%"
        >
          {({ index, style }) => (
            <div style={style}>
              <FacetValue
                key={`${filteredValues[index].value}_${index}`}
                facet={filteredValues[index]}
                isSelected={categoryItems.has(filteredValues[index].value!)}
              />
            </div>
          )}
        </FixedSizeList>
      ) : (
        <div className="px-3">No Items</div>
      )}
    </>
  );
};
export default FacetDropdownItems;
