import { IAssetAttribute, IFacetField } from '@amgen/core';
import { IPieSlice } from '@amgen/shared';

export const convertToPieChartData = (data: IFacetField[], facetName: IAssetAttribute): [string[], IPieSlice[]] => {
  const sliceField: IPieSlice[] = [];
  const slicesList: string[] = [];
  const pieFilterIndex = data.findIndex(facet => facet.field === facetName);
  const facetValues = data?.[pieFilterIndex]?.values ?? [];

  facetValues.forEach(item => {
    slicesList.push(item.value!);
    sliceField.push({ value: item.count!, name: item.value! });
  });

  return [slicesList, sliceField];
};
