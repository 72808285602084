import { IDataAsset } from './api.generated';

export const DEFAULT_SEARCH_QUERY = '*:*';
const userEmployeeType = sessionStorage.getItem('employeeType');
export enum DataSource {
  ALIGN = 'Align Interactions',
  ALIGN_SURVEY = 'Align Survey',
  AMGEN_RIM = 'Amgen RIM',
  AMGEN_WEBSITES = 'Amgen Websites',
  BOX = 'Ci Box',
  C3 = 'C3',
  CDOCS = 'CDOCS',
  CLINICAL_TRIALS = 'ClinicalTrials.gov',
  CONFLUENCE = 'Confluence',
  CORTELLIS = 'Cortellis',
  ELN = 'ELN',
  IDBS_GXP = 'IDBSGxP',
  IDBS_NON_GXP = 'IDBSNonGxP',
  IRPC = 'IRPC',
  KM_ASSET = 'Ops KM Assets',
  MY_AMGEN = 'MyAmgen',
  PERKIN_ELMER = 'PerkinElmer',
  PUBMED = 'PubMed',
  SHAREPOINT = 'SharePoint',
  SMARTSHEET = 'SmartSheet',
  SPO = 'SPO',
  TWITTER = 'Analytics Vidhya',
  WATERS_ACO = 'waters_aco',
  WATERS_AML = 'waters_aml',
  WATERS_ARI = 'waters_ari',
  WATERS_ELN_ACO = 'waters_eln_aco',
  WATERS_ELN_AML = 'waters_eln_aml',
  WATERS_ELN_ARI = 'waters_eln_ari',
  ETMF = 'eTMF',
  ServiceNow = 'ServiceNow',
  AmgenHub = 'AmgenHub',
}

export const ABUSERDataSourcesFTE = [
  'MyAmgen',
  'CDOCS',
  'ServiceNow',
  'SharePoint',
  'Ops KM Assets',
  'Amgen RIM',
  'Amgen Websites',
];

export const ABUSERDataSourcesCW = ['MyAmgen', 'ServiceNow'];

export const ABUSERTopFacetValuesFTE = [
  {
    __typename: 'FacetValue',
    value: 'MyAmgen',
    count: 100,
  },
  {
    __typename: 'FacetValue',
    value: 'CDOCS',
    count: 100,
  },
  {
    __typename: 'FacetValue',
    value: 'ServiceNow',
    count: 100,
  },
  {
    __typename: 'FacetValue',
    value: 'SharePoint',
    count: 100,
  },
  {
    __typename: 'FacetValue',
    value: 'Ops KM Assets',
    count: 100,
  },
  {
    __typename: 'FacetValue',
    value: 'Amgen RIM',
    count: 100,
  },
  {
    __typename: 'FacetValue',
    value: 'Amgen Websites',
    count: 100,
  },
];

export const ABUSERTopFacetValuesOpsKM = [
  {
    __typename: 'FacetValue',
    value: 'Ops KM Assets',
    count: 100,
  },
  {
    __typename: 'FacetValue',
    value: 'CDOCS',
    count: 100,
  },
];

export const ABUSERTopFacetValuesCatalyst = [
  {
    __typename: 'FacetValue',
    value: 'SharePoint',
    count: 100,
  },
];

export const ABUSERTopFacetValuesCW = [
  {
    __typename: 'FacetValue',
    value: 'MyAmgen',
    count: 100,
  },
  {
    __typename: 'FacetValue',
    value: 'ServiceNow',
    count: 100,
  },
];

export type IAssetAttribute = keyof IDataAsset;
