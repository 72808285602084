import * as Types from '@amgen/core';

import { IDataAssetForSpotlightFragment } from './search';
import { gql } from '@apollo/client';
import { DataAssetForSpotlightFragmentDoc } from './search';
import * as Apollo from '@apollo/client';
export type ISpotlightSearchQueryVariables = Types.Exact<{
  applicationName?: Types.Maybe<Types.ApplicationName>;
  query: Types.Scalars['String'];
  filters?: Types.Maybe<Array<Types.Maybe<Types.Scalars['String']>> | Types.Maybe<Types.Scalars['String']>>;
  start?: Types.Maybe<Types.Scalars['Int']>;
  page?: Types.Maybe<Types.Scalars['Int']>;
  bertCount?: Types.Maybe<Types.Scalars['Int']>;
  keywordCount?: Types.Maybe<Types.Scalars['Int']>;
  sort?: Types.Maybe<Types.SortField>;
  order?: Types.Maybe<Types.Direction>;
  addPersonalizationDetails?: Types.Maybe<Types.Scalars['Boolean']>;
  requestHandler?: Types.Maybe<Types.SolrRequestHandler>;
  solrHandler?: Types.Maybe<Types.Scalars['String']>;
  noShortContent?: Types.Maybe<Types.Scalars['Boolean']>;
  noSpotlight?: Types.Maybe<Types.Scalars['Boolean']>;
  cosine?: Types.Maybe<Types.Scalars['Boolean']>;
}>;

export type ISpotlightSearchQuery = {
  spotlightSearch?: Types.Maybe<
    Pick<Types.IDataAssetCollection, 'QTime'> & {
      docs?: Types.Maybe<
        Array<
          Types.Maybe<{
            dataAsset?: Types.Maybe<IDataAssetForSpotlightFragment>;
            personalizationDetails?: Types.Maybe<{
              bookmarkDetails?: Types.Maybe<Pick<Types.IBookmarkDetails, 'isBookmarked' | 'bookmarkId'>>;
            }>;
          }>
        >
      >;
    }
  >;
};

export const SpotlightSearchDocument = gql`
  query SpotlightSearch(
    $applicationName: ApplicationName = EEA
    $query: String!
    $filters: [String]
    $start: Int = 0
    $page: Int
    $bertCount: Int
    $keywordCount: Int
    $sort: SortField
    $order: Direction
    $addPersonalizationDetails: Boolean
    $requestHandler: SolrRequestHandler
    $solrHandler: String = "/search-opennlp-select"
    $noShortContent: Boolean = false
    $noSpotlight: Boolean = true
    $cosine: Boolean = true
  ) {
    spotlightSearch(
      applicationName: $applicationName
      query: $query
      filters: $filters
      start: $start
      page: $page
      bertCount: $bertCount
      keywordCount: $keywordCount
      sort: $sort
      order: $order
      addPersonalizationDetails: $addPersonalizationDetails
      requestHandler: $requestHandler
      solrHandler: $solrHandler
      noShortContent: $noShortContent
      noSpotlight: $noSpotlight
      cosine: $cosine
    ) {
      QTime
      docs {
        dataAsset {
          ...DataAssetForSpotlight
        }
        personalizationDetails {
          bookmarkDetails {
            isBookmarked
            bookmarkId
          }
        }
      }
    }
  }
  ${DataAssetForSpotlightFragmentDoc}
`;
export function useSpotlightSearchQuery(
  baseOptions: Apollo.QueryHookOptions<ISpotlightSearchQuery, ISpotlightSearchQueryVariables>
) {
  return Apollo.useQuery<ISpotlightSearchQuery, ISpotlightSearchQueryVariables>(SpotlightSearchDocument, baseOptions);
}
export function useSpotlightSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ISpotlightSearchQuery, ISpotlightSearchQueryVariables>
) {
  return Apollo.useLazyQuery<ISpotlightSearchQuery, ISpotlightSearchQueryVariables>(
    SpotlightSearchDocument,
    baseOptions
  );
}
export type SpotlightSearchQueryHookResult = ReturnType<typeof useSpotlightSearchQuery>;
export type SpotlightSearchLazyQueryHookResult = ReturnType<typeof useSpotlightSearchLazyQuery>;
export type SpotlightSearchQueryResult = Apollo.QueryResult<ISpotlightSearchQuery, ISpotlightSearchQueryVariables>;
