import React, { useContext } from 'react';
import { Col } from 'react-bootstrap';

import './clustered-results-list.scss';

import { ClusterSearchContext } from '../cluster-search-context-provider/cluster-search-context';
import ClusteredResultItem from '../clustered-result-item/clustered-result-item';

/* eslint-disable-next-line */
export interface ClusteredResultsListProps {}

export function ClusteredResultsList(props: ClusteredResultsListProps) {
  const { selectedClusterResults } = useContext(ClusterSearchContext);

  return (
    <Col xs="3" className="clustered-results-list border thin-scrollbar px-0">
      <ol className="list-unstyled">
        {selectedClusterResults?.map((result, i) => (
          <ClusteredResultItem result={result} key={result.dataAsset?.id} docPosition={i} />
        ))}
      </ol>
    </Col>
  );
}

export default ClusteredResultsList;
