import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import './session-timeout-error.scss';

import { SESSION_TIMEOUT_MESSAGES } from '../constants';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SessionTimeoutProps {

}

export const SessionTimeoutError = (props: SessionTimeoutProps) => {
  const history = useHistory()
  const [errorDisplayMessage, setErrorDisplayMessage] = useState(SESSION_TIMEOUT_MESSAGES.AUTO_LOGOUT_MESSAGE)
  const isAutoLogout = localStorage.getItem('autoLogout');

  useEffect(() => {
    if (isAutoLogout !== null && JSON.parse(isAutoLogout)) {
      setErrorDisplayMessage(SESSION_TIMEOUT_MESSAGES.AUTO_LOGOUT_MESSAGE)
      clearLocalAndSessionStorage()
    } else {
      setErrorDisplayMessage(SESSION_TIMEOUT_MESSAGES.MANUAL_LOGOUT_MESSAGE)
      clearLocalAndSessionStorage()
    }
  }, []);

  const clearLocalAndSessionStorage = () => {
    localStorage.clear();
    sessionStorage.clear();
  }

  return (
    <div className="d-flex justify-content-center align-items-center py-3" style={{ height: '100vh' }}>
      <div className="text-center shadow-lg bg-white px-5 pb-4 w-60 rounded">
        <div className="my-4">
          <div className="logout-text py-2 pt-4" dangerouslySetInnerHTML={{ __html: errorDisplayMessage }}></div>
          <Button variant="primary" className="btn-login mt-4" onClick={() => history.push('/home')}>
            <span className="btn-login-text">Login</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SessionTimeoutError;
