import { useDeletePreferenceMutation, useLazySearchPromiseQuery } from '@amgen/api';
import { DEFAULT_SEARCH_QUERY, Direction, IBookmark, SortField, useEnvironment } from '@amgen/core';
import { removeSpecialChars, SOLR_REFRESH_TIME } from '@amgen/utils';
import { faBookmark } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';

import './bookmark-remove-button.scss';

import { BookmarkButton } from '../../components';

export interface BookmarkRemoveButtonProps {
  bookmark: IBookmark;
  onSuccess: () => void;
  isDisabled?: boolean;
  isCustomBookmark?: boolean;
  isBookmarked?: boolean;
  isAssetDetail?: boolean;
  buttonText?: string;
}

export const BookmarkRemoveButton = (props: BookmarkRemoveButtonProps) => {
  const [bookmarkId, setBookmarkId] = useState(props.bookmark.id);
  const { appName } = useEnvironment();

  const getBookmarkId = useLazySearchPromiseQuery({
    variables: {
      query: DEFAULT_SEARCH_QUERY,
      filters: [`id : "${props.bookmark.assetId || ''}"`],
      page: 0,
      count: 1,
      sort: SortField.MODIFIED_DATE,
      order: Direction.ASC,
      includeDocs: true,
      noShortContent: false,
      addPersonalizationDetails: true,
    },
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      getBookmarkId().then(({ data }) => {
        setBookmarkId(data?.search?.docs?.[0]?.personalizationDetails?.bookmarkDetails?.bookmarkId ?? '');
      });
    }, SOLR_REFRESH_TIME);
    return () => clearTimeout(timer);
  }, [getBookmarkId]);

  const [removeBookmark, { loading }] = useDeletePreferenceMutation({
    variables: {
      id: removeSpecialChars(bookmarkId ?? ''),
      isSoft: true,
      applicationName: appName,
    },
    onCompleted: props.onSuccess,
    onError: err => {
      console.error(err, 'Bookmarking error. Please try again');
    },
  });

  return (
    <BookmarkButton
      icon={faBookmark}
      isDisabled={props.isDisabled}
      isLoading={loading}
      tooltipText="Remove from Bookmarks"
      onClick={removeBookmark}
      isCustomBookmark={props.isCustomBookmark}
      isBookMarked={props.isBookmarked}
      isAssetDetail={props.isAssetDetail}
      buttonText={props.buttonText}
    />
  );
};

export default BookmarkRemoveButton;
