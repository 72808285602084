export enum UTMParams {
  CAMPAIGN = 'pk_campaign',
  SOURCE = 'pk_source',
  MEDIUM = 'pk_medium',
}

export enum URLParams {
  SORT_OPTION = 'sort',
  PAGE = 'page',
  VIEW_STYLE = 'view',
}
