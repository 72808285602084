import { useField } from 'formik';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';

import './description-input.scss';

import { IGD_HELP_TEXT } from '../../help-text';
import { MAX_WORD_COUNT } from '../../models';
import NewRequestFormLabel from '../new-request-form-label/new-request-form-label';

/* eslint-disable-next-line */
export interface DescriptionInputProps {}

export const DescriptionInput = (props: DescriptionInputProps) => {
  const [siteDescField, siteDescMeta, siteDescHelper] = useField<string>('siteDescription');
  const [reqDescField, reqDescMeta, reqDescHelper] = useField<string>('requestDescription');

  return (
    <Row className="pb-3">
      <Form.Group as={Col} md="6" controlId={siteDescField.name}>
        <NewRequestFormLabel name={siteDescField.name} helpText={IGD_HELP_TEXT['siteDescription']} />
        <Form.Control
          type="text"
          as="textarea"
          rows={3}
          placeholder="eg: Data for all Amgen products and experimental trials."
          {...siteDescField}
          onChange={e => {
            siteDescField.onChange(e);
            siteDescHelper.setTouched(true);
          }}
          isInvalid={siteDescMeta.touched && !!siteDescMeta.error}
        />
        <Form.Control.Feedback type="invalid">{siteDescMeta.error}</Form.Control.Feedback>
        {!siteDescMeta.error && (
          <Form.Text className="text-right">
            Words: {`${siteDescField.value.split(/\s+/g).filter(t => !!t).length} / ${MAX_WORD_COUNT}`}
          </Form.Text>
        )}
      </Form.Group>

      <Form.Group as={Col} md="6" controlId={reqDescField.name}>
        <NewRequestFormLabel
          name={reqDescField.name}
          label="Request Description"
          helpText={IGD_HELP_TEXT['requestDescription']}
        />
        <Form.Control
          type="text"
          as="textarea"
          rows={3}
          placeholder="eg: Researchers need to access Product and Trial data.The Access is limited to only those who need it."
          {...reqDescField}
          onChange={e => {
            reqDescField.onChange(e);
            reqDescHelper.setTouched(true);
          }}
          isInvalid={reqDescMeta.touched && !!reqDescMeta.error}
        />
        <Form.Control.Feedback type="invalid">{reqDescMeta.error}</Form.Control.Feedback>
        {!reqDescMeta.error && (
          <Form.Text className="text-right">
            Words: {`${reqDescField.value.split(/\s+/g).filter(t => !!t).length} / ${MAX_WORD_COUNT}`}
          </Form.Text>
        )}
      </Form.Group>
    </Row>
  );
};

export default DescriptionInput;
