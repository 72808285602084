import { IAssetAttributeTypeProps } from '@amgen/shared';
import React from 'react';
import { Button } from 'react-bootstrap';

import './tag-attribute.scss';

export interface ITagAttributeProps extends IAssetAttributeTypeProps {
  onApplyFilter: (value: string) => void;
}

export const TagAttribute: React.FC<IAssetAttributeTypeProps> = ({ value, onApplyFilter }) => {
  const tags = (value as string[]) ?? [];

  return (
    <span>
      {tags.map((tag, i) => (
        <>
          {console.log(tag, 'tag===')}
          <Button
            as="span"
            key={`${tag}-${i}`}
            variant="light"
            className="shadow-sm border mr-1 badge"
            onClick={() => onApplyFilter?.(tag.toString())}
          >
            {tag}
          </Button>
        </>
      ))}
    </span>
  );
};

export default TagAttribute;
