import { faWindowClose, faExpandAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import './feature-chatbot-header.scss';
import { Button } from 'react-bootstrap';
import { AppContext, SearchContext } from '@amgen/shared';
import { ReactComponent as VAChatbot } from '../../../../../../apps/eea-search/src/assets/images/va-chatbot.svg';

export interface FeatureChatbotHeaderButtonProps {
  configData: any;
  searchTerm: string;
  seeMoreButtonClicked: (typeValue: string) => void;
}

export const FeatureChatbotHeaderButton: React.FC<FeatureChatbotHeaderButtonProps> = ({
  configData,
  searchTerm,
  seeMoreButtonClicked,
}) => {
  const { selectedResultTab } = useContext(AppContext);
  const { searchQueryVariables } = useContext(SearchContext);
  const { query } = searchQueryVariables;
  const QueryValue = query?.replace(/"|'/g, '')?.toLowerCase();
  const configDataQueryValue =
    configData && configData?.response !== null && configData?.response?.query_term?.replace(/"|'/g, '')?.toLowerCase();
  const abTestingGroup = JSON.parse(sessionStorage.getItem('abTestingGroup') as string);

  function isInViewport(el) {
    if (el) {
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    }
  }
  const chatbotVAComponent = document.getElementById('right-va');
  const showRightTopVA = isInViewport(chatbotVAComponent) !== undefined ? isInViewport(chatbotVAComponent) : true;
  const matchedIntentText = (
    <>
      I've found some <b>{searchTerm}</b> related topics
    </>
  );

  return (
    <div id="right-va">
      {showRightTopVA ? (
        selectedResultTab !== 'Chat' &&
          configData &&
          configData?.response !== null &&
          (QueryValue === configDataQueryValue || QueryValue === '*:*') &&
          configData?.response?.invoke_chatbot === false && !abTestingGroup ? (
          <div className="va-positioned">
            <div className="va-green va-minimized" onClick={() => seeMoreButtonClicked('seeMore')}>
              <div className="va-eyes-left"></div>
              <div className="va-eyes-right"></div>
              <VAChatbot className="va-logo" />
              <div className="text-va">Amgen Virtual Assistant</div>
            </div>
          </div>
        ) : selectedResultTab !== 'Chat' &&
          configData &&
          configData?.response !== null &&
          QueryValue === configDataQueryValue &&
          configData?.response?.invoke_chatbot === true ? (
          <div className="va-container">
            <div className="va-green va-expanded" onClick={() => seeMoreButtonClicked('seeMore')}>
              <div className="va-eyes-left"></div>
              <div className="va-eyes-right"></div>
              <VAChatbot className="va-logo" />
              <div className="text-va">Amgen Virtual Assistant</div>
            </div>
            <div className="query-text">{matchedIntentText}</div>
            <div className="bottom-ava-container">
              <div className="va-chips-container">
                <div className="va-chips">Virtual Assistant</div>
                <div className="va-chips">Virtual Assistant</div>
              </div>
              <div className="see-more-container">
                <Button
                  className="see-more-btn dropdown-arrow"
                  size="sm"
                  onClick={() => seeMoreButtonClicked('seeMore')}
                >
                  See More &#x25bc;
                </Button>
              </div>
            </div>
          </div>
        ) : selectedResultTab !== 'Chat' && QueryValue !== configDataQueryValue && !abTestingGroup ? (
          <div className="va-positioned">
            <div className="va-green va-minimized">
              <div className="va-eyes-left"></div>
              <div className="va-eyes-right"></div>
              <VAChatbot className="va-logo" />
              <div className="text-va-searching">Analyzing</div>
              <div className="dot-flashing"></div>
            </div>
          </div>
        ) : (
          <></>
        )
      ) : selectedResultTab !== 'Chat' && !abTestingGroup ? (
        <div className="va-container-circular" onClick={() => seeMoreButtonClicked('minimizedAVA')}>
          <div className="va-green-cirular">
            <div className="va-eyes-left-circular"></div>
            <div className="va-eyes-right-circular"></div>
            <VAChatbot className="va-logo-circular" />
          </div>
          <div className="box arrow-bottom">
            {configData &&
              configData?.response !== null &&
              QueryValue === configDataQueryValue &&
              configData?.response?.invoke_chatbot === true
              ? `Let's Chat..`
              : 'Amgen VA'}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default FeatureChatbotHeaderButton;
