import { IDataCrawlRequest, NewRequestFormValue, useEnvironment } from '@amgen/core';
import { useOktaAuth } from '@okta/okta-react';
import { useCallback, useState } from 'react';

const ADD_CRAWL_REQUEST = `
  mutation AddCrawlRequest($applicationName: ApplicationName!,$request: DataCrawlRequestInput) {
    addContentOnboarding(applicationName: $applicationName,dataCrawlRequest: $request) {
      owner
      requestDescription
      requestStatus
      id
    }
  }
`;

type CrawlRequestReturnType = [
  (formValue: NewRequestFormValue, file?: File) => Promise<IDataCrawlRequest | null | undefined>,
  { data: IDataCrawlRequest | null; loading: boolean; error: string }
];

export const useAddCrawlRequestMutation = (consentGroup: string, fullName?: string): CrawlRequestReturnType => {
  // Using oktaAuth directly here. as this is a fetch api call and `useAuth` doesn't expose token by design.
  const { authState } = useOktaAuth();
  const { envGraphqlUrl, appName } = useEnvironment();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IDataCrawlRequest | null>(null);
  const [error, setError] = useState<string>('');

  const mutate = useCallback(
    async (formValue: NewRequestFormValue) => {
      const { file, ...values } = formValue;
      const isOwner = formValue.owner === 'Yes';
      const variables = {
        ...values,
        requesterName: fullName,
        owner: isOwner,
        consent: formValue.consent.reduce((c, cc) => cc && c, true),
        consents: consentGroup,
        fileDetails: file && !isOwner ? { filename: file.name } : undefined,
        sourcePath: formValue.sourcePath?.replace(/\/$/, ''),
      };

      const formData = new FormData();
      if (file && !isOwner) {
        formData.set(file.name, file);
      }
      formData.set('variables', JSON.stringify({ request: variables, applicationName: appName }, null, 2));
      formData.set('query', ADD_CRAWL_REQUEST);

      setLoading(true);

      try {
        const resp = await fetch(envGraphqlUrl, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${authState.accessToken?.accessToken}`,
          },
          body: formData,
        });
        const data = await resp.json();
        if (data.error || data.errors) {
          const err = new Error('Request Failed');
          err.message = data.errors[0].message;
          throw err;
        }
        setData(data.addContentOnboarding);
        return data;
      } catch (err: any) {
        setError('Network Error');
        throw new Error(err.message || 'Network Error');
      } finally {
        setLoading(false);
      }
    },
    [envGraphqlUrl, appName, authState.accessToken?.accessToken, consentGroup, fullName]
  );

  return [mutate, { data, loading, error }];
};
