import { IAssetAttribute, IFacetValue } from '@amgen/core';

export interface ITreeFacet extends IFacetValue {
  isOpened?: boolean;
  parentValue?: string;
  page: number;
  path: string;
  level: number;
  subField?: {
    field?: IAssetAttribute;
    count?: number;
    values?: ITreeFacet[];
  };
}

export const convertFacetToTree = (facetList: IFacetValue[], root: ITreeFacet): ITreeFacet[] => {
  const converted: ITreeFacet[] = facetList.map(facet => {
    const tF: ITreeFacet = {
      value: facet.value,
      count: facet.count,
      level: root?.level ? root.level + 1 : 0,
      parentValue: root?.value ? root.value : undefined,
      isOpened: false,
      page: 0,
      path: root.path && facet.value ? facet.value.concat('.', root.path) : facet.value ?? 'root',
    };
    tF.subField = {
      field: facet.subField?.field as IAssetAttribute,
      values: facet.subField?.values ? convertFacetToTree(facet.subField.values, tF) : [],
    };

    return tF;
  });
  return converted;
};

export const returnSubFieldValues = (values: IFacetValue[], level: number): IFacetValue[] => {
  const o = values[0].subField?.values ?? [];
  if (level && o.length) {
    return returnSubFieldValues(o, level - 1);
  }
  return o;
};

// export const returnBranchedPivotForGraphQl = (
//   index: number,
//   categoryTree: IAssetAttribute[],
//   level: number,
//   pivotDetail: IPivotDetail,
//   page: number
// ) => {
//   const j = {
//     facetLimit: 1,
//     page: index,
//     pivotDetail: {
//       name: categoryTree[level + 1],
//       facetLimit: 6,
//       page,
//     },
//   };

//   if (pivotDetail.pivotDetail) {
//     // return returnSubFieldValues(o, level - 1);
//   } else {
//     pivotDetail.pivotDetail = { ...pivotDetail.pivotDetail, j };
//   }
//   return o;
// };

// TODO remove after pivot tree is stable unsed function
export const updateTreeFacetByPath = (facetTree: ITreeFacet[], replaceTreeFacet: ITreeFacet): ITreeFacet[] => {
  const pathArray = replaceTreeFacet.path.split('.').reverse();
  pathArray.shift();

  return recursion(facetTree, pathArray, 0, replaceTreeFacet);
};

export const recursion = (arr: ITreeFacet[], path: string[], level: number, obj: ITreeFacet): ITreeFacet[] => {
  const o = [...arr];

  const facetArrayAtThisPath = o.find(x => x.value === path[level]);
  if (path.length > level + 1) {
    facetArrayAtThisPath!.subField!.values = recursion(facetArrayAtThisPath!.subField!.values!, path, level + 1, obj);
  } else {
    o[o.findIndex(x => x.value === path[level])] = obj;
  }
  return o;
};
