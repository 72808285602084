import { useGetTemporaryS3FileAccessQuery, useUpdateCrawlRequestMutation } from '@amgen/api';
import { IDataCrawlRequest, RequestStatusType, useEnvironment } from '@amgen/core';
import { IGD_ACTIONS, MatomoContext, SEARCH_APP_FEATURE_LIST, useToast } from '@amgen/shared';
import { format as formatDate } from 'date-fns';
import DOMPurify from 'dompurify';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import ClampLines from 'react-clamp-lines';

import './request-detail-popup.scss';

const RequestDetailRow: React.FC<{ label: string }> = props => (
  <Row>
    <Col md="3" className="font-weight-bold">
      {props.label}
    </Col>
    <Col md="9">{props.children}</Col>
  </Row>
);

const RequestSiteOwnerRow = (props: { request: IDataCrawlRequest }) => {
  const { data } = useGetTemporaryS3FileAccessQuery({
    variables: { paths: [props.request.s3Location!] },
    skip: !props.request.s3Location,
  });
  const signedUrl = data?.getTemporaryS3FileAccess?.[0].preSignedUrl;

  return (
    <RequestDetailRow label="Site Owner">
      {props.request.owner ? (
        'Yes'
      ) : (
        <span>
          No (
          <a href={DOMPurify.sanitize(signedUrl)} target="_blank" rel="noreferrer">
            Approval
          </a>
          )
        </span>
      )}
    </RequestDetailRow>
  );
};

export interface RequestDetailPopupProps {
  request: IDataCrawlRequest | null;
  show: boolean;
  onHide: () => void;
  onAction: () => void;
  userName?: string;
  isApproval?: boolean;
}

export const RequestDetailPopup = (props: RequestDetailPopupProps) => {
  const [approve, setApprove] = useState(false);
  const [reviewerRemarks, setReviewerRemarks] = useState(props.request?.reviewerRemarks ?? '');
  const [updateRequest, { loading }] = useUpdateCrawlRequestMutation();
  const isActionable = props.request?.requestStatus === RequestStatusType.PENDING;
  const toast = useToast();
  const { trackApplicationFeature } = useContext(MatomoContext);
  const { appName } = useEnvironment();

  useEffect(() => {
    setReviewerRemarks(props.request?.reviewerRemarks ?? '');
  }, [props.request?.reviewerRemarks]);

  const approveRequest = useCallback(
    (isApprove: boolean) => {
      if (reviewerRemarks) {
        setApprove(isApprove);
        if (isApprove) {
          trackApplicationFeature(SEARCH_APP_FEATURE_LIST.IGD, IGD_ACTIONS.APPROVE_REQUEST);
        } else {
          trackApplicationFeature(SEARCH_APP_FEATURE_LIST.IGD, IGD_ACTIONS.DECLINE_REQUEST);
        }
        updateRequest({
          variables: {
            appName,
            requestId: props.request?.id!,
            requestStatus: isApprove ? RequestStatusType.APPROVED : RequestStatusType.DECLINED,
            reviewerName: props.userName,
            reviewerRemarks,
          },
        })
          .then(resp => {
            if (resp.data?.updateContentOnboarding?.status === 'SUCCESS') {
              toast.success({
                message: `${props.request?.requesterName}'s request was successfully ${
                  isApprove ? RequestStatusType.APPROVED : RequestStatusType.DECLINED
                }`,
                timeout: 5000,
              });
              props.onAction();
              props.onHide();
            } else {
              toast.danger({
                message: 'Something went wrong. Please try again later.',
                timeout: 5000,
              });
            }
          })
          .catch(() =>
            toast.danger({
              message: 'Something went wrong. Please try again later.',
              timeout: 5000,
            })
          );
      }
    },
    [reviewerRemarks, updateRequest, props, trackApplicationFeature, toast]
  );

  return (
    <Modal centered scrollable show={props.show} onHide={props.onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title as="h2" className="text-primary">
          Data Request
        </Modal.Title>
      </Modal.Header>
      {props.request && (
        <Modal.Body style={{ minHeight: 'unset' }}>
          <RequestDetailRow label="Data Source">{props.request.contentSource}</RequestDetailRow>
          <RequestDetailRow label="Site URL / Space Name">
            <ClampLines
              className="clampped-content p-0 w-100"
              text={props.request.sourcePath ?? ''}
              id={Math.random().toString()}
              lines={2}
              ellipsis="..."
              moreText="Show more"
              lessText="Show Less"
            />
          </RequestDetailRow>
          <RequestDetailRow label="Request">{props.request.requesterId}</RequestDetailRow>
          <RequestSiteOwnerRow request={props.request} />
          <RequestDetailRow label="Request Description">
            <ClampLines
              className="clampped-content p-0 w-100"
              text={props.request.requestDescription ?? ''}
              id={Math.random().toString()}
              lines={2}
              ellipsis="..."
              moreText="Show more"
              lessText="Show Less"
            />
          </RequestDetailRow>
          <RequestDetailRow label="Site Description">
            <ClampLines
              className="clampped-content p-0 w-100"
              text={props.request.siteDescription ?? ''}
              id={Math.random().toString()}
              lines={2}
              ellipsis="..."
              moreText="Show more"
              lessText="Show Less"
            />
          </RequestDetailRow>
          <RequestDetailRow label="Request Status">
            {props.request?.requestStatus}{' '}
            {!isActionable &&
              props.request?.reviewedOn &&
              ` on ${formatDate(new Date(props.request?.reviewedOn), 'MMM dd, yyyy')} by ${
                props.request?.reviewerName
              }`}
          </RequestDetailRow>
          <Form>
            <Form.Group as={Row} controlId="formReviewerRemarks">
              <Form.Label column sm="3" className="font-weight-bold">
                Reviewer Remarks
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  className="thin-scrollbar"
                  placeholder={`Add remark for ${props.isApproval ? 'approval' : 'decline'}`}
                  value={reviewerRemarks}
                  required
                  maxLength={100}
                  onChange={e => setReviewerRemarks(e.target.value)}
                  isValid={reviewerRemarks.length > 0 && reviewerRemarks.length <= 100}
                />
                <Form.Control.Feedback type="invalid" className="text-left">
                  Review remark is required
                </Form.Control.Feedback>
                <Form.Control.Feedback type="valid" className="text-left text-black-50">
                  {`Characters left: ${100 - reviewerRemarks.length}`}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Row className="justify-content-end mt-3 px-3">
              {isActionable &&
                (props.isApproval ? (
                  <Button
                    type="submit"
                    variant="success"
                    className="mr-2 px-4"
                    disabled={loading}
                    onClick={() => approveRequest(true)}
                  >
                    {loading && approve ? <Spinner animation="border" size="sm" className="text-white" /> : 'Approve'}
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    variant="danger"
                    className="mr-2 px-4"
                    disabled={loading}
                    onClick={() => approveRequest(false)}
                  >
                    {loading && !approve ? <Spinner animation="border" size="sm" className="text-white" /> : 'Decline'}
                  </Button>
                ))}
              <Button
                variant={isActionable ? 'outline-secondary' : 'primary'}
                className="px-4"
                onClick={props.onHide}
                disabled={loading}
              >
                {isActionable ? 'Cancel' : 'Close'}
              </Button>
            </Row>
          </Form>
        </Modal.Body>
      )}
    </Modal>
  );
};

export default RequestDetailPopup;
