import { FormLabelWithTooltip } from '@amgen/components';
import { useWindowSizeListener } from '@amgen/shared';
import { useField } from 'formik';
import React from 'react';
import { Col, Form } from 'react-bootstrap';

import './custom-group-name-input.module.scss';

/* eslint-disable-next-line */
export interface CustomGroupNameInputProps {
  name: string;
}

export function CustomGroupNameInput(props: CustomGroupNameInputProps) {
  const [customGroupNameField, customGroupNameMeta, customGroupNameHelpers] = useField('name');
  const isMobile = useWindowSizeListener();
  return (
    <Form.Group as={Col} xs="12" controlId={customGroupNameField.name}>
      <FormLabelWithTooltip label="Group Name" helpText="Enter a Group Name" />
      <Form.Control
        type="text"
        value={props.name}
        onChange={e => {
          customGroupNameHelpers.setTouched(true);
          customGroupNameField.onChange(e);
        }}
        isInvalid={customGroupNameMeta.touched && !!customGroupNameMeta.error}
        className={isMobile ? 'f-80' : ''}
      />
      <Form.Control.Feedback type="invalid">{customGroupNameMeta.error}</Form.Control.Feedback>
    </Form.Group>
  );
}

export default CustomGroupNameInput;
