import { Direction, IAsset, IAssetAttribute, SortField } from '@amgen/core';
import { ResultViewType } from '@amgen/shared-kmi';
import { createContext } from 'react';

export interface IKmiSearchState {
  isSliderShown: boolean;
  setIsSliderShown: (value: boolean) => void;
  isMobile: boolean;
  pageOptionsCount: number;
  selectedViewType: ResultViewType;
  onViewTypeChange: (viewType: ResultViewType) => void;
  results: IAsset[];
  query: string;
  sortOption: SortField;
  selectedSort: SortField;
  setSelectedSort: (sortOption: SortField) => void;
  sortOrder: Direction;
  setSortOrder: (sortOrder: Direction) => void;
  data: any;
  loading: boolean;
  onPageSort: (sortOption: SortField) => void;
  onItemsPerPageChange: (size: number) => void;
  itemsPerPage: number;
  onClearAllFilters: () => void;
  removeParentFilter: (depth: number, parentCategory: IAssetAttribute, val: string) => void;
  onRemoveAppliedFilters: (val: string, category: IAssetAttribute) => void;
  onPageSelect: (pageNo: number) => void;
  searchQueryVariables: any;
  isExpandable?: boolean;
  expandPanel?: boolean;
  setExpandPanel?: (open: boolean) => void;
}

export const KmiSearchContext = createContext<IKmiSearchState>(null!);
