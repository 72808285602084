import classNames from 'classnames';
import React, { CSSProperties } from 'react';
import { ButtonGroup, Dropdown } from 'react-bootstrap';

import './results-per-page-dropdown.scss';

import { LinkToggler } from '../link-toggler/link-toggler';

export interface ResultsPerPageDropdownProps {
  option: number;
  onChange: (count: number) => void;
  className?: string;
  style?: CSSProperties;
}

export const ResultsPerPageDropdown: React.FC<ResultsPerPageDropdownProps> = props => {
  const PER_PAGE_OPTIONS = [10, 15, 30, 50];

  return (
    <div
      className={classNames(props.className, 'results-per-page-container d-flex bg-light px-1 px-lg-3')}
      style={props.style}
    >
      <Dropdown as={ButtonGroup} alignRight onSelect={(value, _) => props.onChange(+(value ?? '15'))}>
        <Dropdown.Toggle as={LinkToggler} title="Per Page" id="sort-dropdown-toggle">
          {props.option}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {PER_PAGE_OPTIONS.map(option => (
            <Dropdown.Item eventKey={`${option}`} key={option}>
              {option}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default ResultsPerPageDropdown;
