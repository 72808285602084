import { ISavedSearch } from '@amgen/core';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import React, { useContext, useState } from 'react';
import { Badge, Col, Container, Row, Spinner } from 'react-bootstrap';

import './subscription-detail-content.scss';

export interface SubscriptionDetailsContentProps {
  savedSearch: ISavedSearch;
  onToggleSubscribe(): void;
  updateLoading: boolean;
}

export const SubscriptionDetailsContent: React.FC<SubscriptionDetailsContentProps> = props => {
  return (
    <Container className="mt-3 p-0">
      <Row>
        <Col xs="6" md="4" className="text-info h3">
          Subscription details
        </Col>

        {props.updateLoading ? (
          <Spinner animation="grow" size="sm" variant="primary" />
        ) : (
          <FontAwesomeIcon
            icon={faBell}
            className="text-primary cursor-pointer"
            onClick={() => props.onToggleSubscribe()}
            title="Click to unsubscribe"
          />
        )}
      </Row>

      {props.savedSearch.subscrptnStartDate && (
        <Row noGutters className="pb-2">
          <Col xs="3" className="font-weight-bold p-0">
            Start Date
          </Col>
          <Col xs="3">
            <span>{format(new Date(props.savedSearch.subscrptnStartDate.toString()), 'MMM dd, yyyy')}</span>
          </Col>
        </Row>
      )}

      {props.savedSearch.subscrptnEndDate && (
        <Row noGutters className="pb-2">
          <Col xs="3" className="font-weight-bold p-0">
            End Date
          </Col>
          <Col xs="3">
            <span>{format(new Date(props.savedSearch.subscrptnEndDate.toString()), 'MMM dd, yyyy')}</span>
          </Col>
        </Row>
      )}

      {props.savedSearch.subscrptnMailIds && (
        <Row noGutters className="pb-2">
          <Col xs="3" className="font-weight-bold p-0">
            Mail Recipients
          </Col>
          <Col xs="9" className="overflow-auto pb-1 thin-scrollbar">
            {props.savedSearch.subscrptnMailIds.map(x => (
              <Badge key={x} className="shadow-sm border mr-1 " variant="light">
                {x}
              </Badge>
            ))}
          </Col>
        </Row>
      )}

      {props.savedSearch.subscrptnType && (
        <Row noGutters className="pb-2">
          <Col xs="3" className="font-weight-bold p-0">
            Frequency
          </Col>
          <Col xs="3">{props.savedSearch.subscrptnType}</Col>
        </Row>
      )}

      {props.savedSearch.subscrptnRecordCount ? (
        <Row noGutters className="pb-2">
          <Col xs="3" className="font-weight-bold p-0">
            Show Top
          </Col>
          <Col xs="3">{props.savedSearch.subscrptnRecordCount}</Col>
        </Row>
      ) : null}
    </Container>
  );
};

export default SubscriptionDetailsContent;
