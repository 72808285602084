import React from 'react';
import { Button, Col } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';

import './back-to-results-btn.module.scss';

/* eslint-disable-next-line */
export interface BackToResultsBtnProps {}

export function BackToResultsBtn(props: BackToResultsBtnProps) {
  const { search } = useLocation();

  return (
    <Col xs="12" className="text-center">
      <Link to={{ pathname: '/', search }}>
        <Button variant="link" className="border-primary px-lg-5">
          {`Back to search results`}
        </Button>
      </Link>
    </Col>
  );
}

export default BackToResultsBtn;
