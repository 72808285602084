import { BookmarkToggleButton } from '@amgen/feature-bookmarks';
import { Asset, AssetThumbnail } from '@amgen/feature-search';
import {
  AssetContextProvider,
  CLUSTER_SEARCH_FEATURE_ACTIONS,
  MatomoContext,
  ResultViewType,
  SEARCH_APP_FEATURE_LIST,
} from '@amgen/shared';
import classnames from 'classnames';
import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';

import './clustered-result-item.scss';

import { ClusterSearchContext, IResult } from '../cluster-search-context-provider/cluster-search-context';

export interface ClusteredResultItemProps {
  result: IResult;
  docPosition: number;
}

export const ClusteredResultItem: React.FC<ClusteredResultItemProps> = ({ result, docPosition }) => {
  const { selectedResult, setSelectedResult } = useContext(ClusterSearchContext);
  const { trackApplicationFeature } = useContext(MatomoContext);

  return (
    <Row
      className={classnames('cluster-result-item cursor-pointer shadow-sm p-3 mx-0 rounded border', {
        active: result.dataAsset?.id === selectedResult?.dataAsset?.id,
      })}
      onClick={() => {
        trackApplicationFeature(
          SEARCH_APP_FEATURE_LIST.CLUSTER_SEARCH,
          CLUSTER_SEARCH_FEATURE_ACTIONS.CLUSTER_RESULT_SELECTED
        );
        setSelectedResult(result);
      }}
    >
      <AssetContextProvider docSolrAsset={result} isBookmarksDisabled={false} viewType={ResultViewType.List}>
        <Col xs="12" className="d-flex justify-content-between align-items-start">
          <Row className="w-25 pr-2">
            {!!result.dataAsset && <AssetThumbnail asset={result.dataAsset} maxWidth="70px" heightAuto />}
          </Row>
          <Asset.Title docPosition={docPosition} hideExternalLinkIcon />
          <Row className="d-flex align-items-start">
            <Asset.Actions>
              <BookmarkToggleButton />
            </Asset.Actions>
          </Row>
        </Col>
      </AssetContextProvider>
    </Row>
  );
};

export default ClusteredResultItem;
