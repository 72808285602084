import { IAssetAttribute } from '@amgen/core';
import { AppContext, FacetContext, FacetsPanelContext, TimeGap, useSearchQueryValue } from '@amgen/shared-kmi';
import { endOfDay, getYear, isSameYear, parse } from 'date-fns';
import { endOfMonth, endOfYear, format } from 'date-fns/esm';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import './date-filter-provider.scss';

import { useChartValues, useDateBarChartData, useEchartsBarChartOption } from '../hooks';
import { DateFilterContext, IDateFilterState } from './date-filter-context';

export interface DateFilterProviderProps {
  category: IAssetAttribute;
}

export const DateFilterProvider: React.FC<DateFilterProviderProps> = ({ children, category }) => {
  const { attributeDisplayNames } = useContext(AppContext);
  const { setAppliedFilters } = useContext(FacetContext);
  const { selectedFilters, setSelectedFilters } = useContext(FacetsPanelContext);
  const [currentView, setCurrentView] = useState(TimeGap.YEAR);
  const [selectedYear, setSelectedYear] = useState('');
  const [startDate, setStartDate] = useState<Date>(new Date(1970));
  const [endDate, setEndDate] = useState<Date>(new Date());
  const categoryName = attributeDisplayNames[category];

  const query = useSearchQueryValue();
  const { data, loading } = useDateBarChartData(category, query);

  const { xData, yData } = useChartValues(currentView, startDate, endDate, selectedYear, data?.facet?.[0].values ?? []);

  const { dateFilterOption } = useEchartsBarChartOption(categoryName, xData, yData);

  useEffect(() => {
    const selectedDate = selectedFilters?.filters ? selectedFilters?.filters[category]?.values()?.next()?.value : '';

    if (selectedDate) {
      const selectedStartDate = parse(selectedDate.split('~')[0].substring(0, 10), 'yyyy-M-d', new Date());
      const selectedEndDate = endOfDay(parse(selectedDate.split('~')[1].substring(1, 11), 'yyyy-M-d', new Date()));

      if (!isSameYear(selectedEndDate, selectedStartDate)) {
        setCurrentView(TimeGap.YEAR);
      } else {
        setCurrentView(TimeGap.MONTH);
        setSelectedYear(getYear(selectedEndDate).toString());
      }
      setStartDate(selectedStartDate);
      setEndDate(selectedEndDate);
    } else {
      setSelectedYear('');
      setCurrentView(TimeGap.YEAR);
      setStartDate(new Date(1970));
      setEndDate(new Date());
    }
  }, [category, selectedFilters]);

  const getSelectedRange = useCallback(
    (startDate: Date, endDate: Date) => {
      const filters = selectedFilters
        .removeAll(category)
        .add(
          [
            format(startDate, 'yyyy-MM-dd').toString() + 'T00:00:00Z',
            format(endDate, 'yyyy-MM-dd').toString() + 'T23:59:59Z',
          ],
          category
        );
      return filters;
    },
    [category, selectedFilters]
  );

  const handleDateBarSelected = useCallback(
    (period: string) => {
      let periodStart;
      let periodEnd;
      if (currentView === TimeGap.YEAR) {
        periodStart = parse(`${period}-01-01`, 'yyyy-M-d', new Date());
        periodEnd = parse(`${period}-12-31`, 'yyyy-M-d', new Date());
      } else {
        periodStart = parse(`${period}-01`, 'yyyy-MMMM-d', new Date());
        periodEnd = endOfMonth(periodStart);
      }
      const selectedRange = getSelectedRange(periodStart, periodEnd);
      setAppliedFilters(selectedRange);
      setStartDate(periodStart);
      setEndDate(periodEnd);
    },
    [currentView, getSelectedRange, setAppliedFilters]
  );

  const handleApplyYearRange = useCallback(
    (values: readonly number[]) => {
      if (!isNaN(values[0]) && !isNaN(values[1])) {
        const startDate = parse(`${values[0]}-01-01`, 'yyyy-MM-d', new Date());
        const endDate = endOfYear(parse(`${values[1]}-01-01`, 'yyyy-MM-d', new Date()));
        const selectedRange = getSelectedRange(startDate, endDate);
        setAppliedFilters(selectedRange);
        setStartDate(startDate);
        setEndDate(endDate);
      }
    },
    [getSelectedRange, setAppliedFilters]
  );

  const handleApplyMonthRange = useCallback(
    (values: readonly number[]) => {
      if (!isNaN(values[0]) && !isNaN(values[1])) {
        const startDate = parse(`${selectedYear}-${values[0]}-01`, 'yyyy-MM-d', new Date());
        const endDate = endOfMonth(parse(`${selectedYear}-${values[1]}-01`, 'yyyy-MM-d', new Date()));
        const selectedRange = getSelectedRange(startDate, endDate);
        setAppliedFilters(selectedRange);
        setStartDate(startDate);
        setEndDate(endDate);
      }
    },
    [selectedYear, getSelectedRange, setAppliedFilters]
  );

  const handleApplyFilters = useCallback(
    (values: readonly number[]) => {
      if (currentView === TimeGap.YEAR) {
        handleApplyYearRange(values);
      } else {
        handleApplyMonthRange(values);
      }
    },
    [currentView, handleApplyYearRange, handleApplyMonthRange]
  );

  const providedValue = useMemo<IDateFilterState>(
    () => ({
      handleDateBarSelected,
      dateFilterOption,
      currentView,
      sliderValues: xData,
      handleApplyFilters,
      loading,
    }),
    [handleDateBarSelected, dateFilterOption, currentView, xData, handleApplyFilters, loading]
  );

  return <DateFilterContext.Provider value={providedValue}>{children}</DateFilterContext.Provider>;
};

export default DateFilterProvider;
