import { useGetTemporaryS3FileAccessQuery } from '@amgen/api';
import { MatomoContext, RECOMMENDED_EVENTS_ACTIONS, SEARCH_APP_FEATURE_LIST } from '@amgen/shared';
import DOMPurify from 'dompurify';
import moment from 'moment';
import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';

import './event.details.scss';

export interface EventDetailsProps {
  eventsData: any;
}

export function EventDetails({ eventsData }: EventDetailsProps) {
  const { trackApplicationFeature, trackLinkClickEvent } = useContext(MatomoContext);
  const getImage = (img: any) => {
    const { data, loading } = useGetTemporaryS3FileAccessQuery({
      variables: { paths: [img ?? ''] },
    });

    return data?.getTemporaryS3FileAccess?.[0].preSignedUrl;
  };

  const handleClick = (url?: string) => {
    trackApplicationFeature(SEARCH_APP_FEATURE_LIST.RECOMMENDED_EVENTS, RECOMMENDED_EVENTS_ACTIONS.EVENT_CLICKS);
    window.open(url, '_blank');
    if (url) {
      trackLinkClickEvent(url);
    }
  };

  return eventsData?.length > 0 ? (
    <>
      <span className="event-tag-url mb-1">Future Event(s)</span>
      <Col md="12" className="d-flex px-3 pt-1 mb-3">
        {eventsData &&
          eventsData.map((item: any, index: any) => {
            return (
              <>
                <div className="events-divider"></div>
                <Col md={eventsData.length === 1 ? '12' : '6'} xs="12">
                  <Row className="events-container pl-2" onClick={() => handleClick(item?.url)}>
                    {item.thumbnail_large && (
                      <Col
                        md={eventsData.length === 1 ? '2' : '4'}
                        xs={eventsData.length === 1 ? '2' : '4'}
                        lg={eventsData.length === 1 ? '2' : '4'}
                        xl={eventsData.length === 1 ? '2' : '4'}
                        className={index == 0 ? 'ml-xl-n3 pt-2' : 'pt-2'}
                      >
                        <img
                          className="events-image"
                          src={DOMPurify.sanitize(getImage(item.thumbnail_large))}
                          alt="events"
                        ></img>
                      </Col>
                    )}
                    <Col
                      md={item.thumbnail_large ? '8' : '12'}
                      lg="8"
                      xs="6"
                      xl="8"
                      className={item.thumbnail_large ? 'events-content mt-1 pt-2' : 'mt-1 pl-0 pt-2'}
                    >
                      <div className="date-container">
                        {item?.start_date !== null && (
                          <span className="events-date">{moment(item?.start_date).format('MMM DD YYYY')}</span>
                        )}
                        {item?.end_date !== null && (
                          <span className="events-date"> - {moment(item?.end_date).format('MMM DD YYYY')} </span>
                        )}
                      </div>

                      <div title={item.title} className="events-title mt-1 pt-2">
                        {item.title}
                      </div>
                    </Col>
                  </Row>
                </Col>
                <div
                  className={index == 0 ? (eventsData.length === 1 ? 'events-divider' : '') : 'events-divider'}
                ></div>
              </>
            );
          })}
      </Col>{' '}
    </>
  ) : (
    <></>
  );
}

export default EventDetails;
