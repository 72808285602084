import { DotNavigator } from '@amgen/components';
import { FeatureRoadmap } from '@amgen/feature-roadmap';
import { useClearFiltersOnNavFromBookmark } from '@amgen/feature-search';
import {
  MatomoContext,
  PAGES,
  SEARCH_APP_FEATURE_LIST,
  usePageTrackingWithTitle,
  useWindowHeightListener,
  useWindowSizeListener,
  useWindowZoomListener,
} from '@amgen/shared';
import { faChevronDown, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import ReactPageScroller from 'react-page-scroller';

import './landing-page.scss';

import AppSection from '../../components/app-section/app-section';
import { DataSourceSection } from '../../components/data-source-section/data-source-section';
import SearchBoxSection from '../../components/search-box-section/search-box-section';
import { MOBILE_SECTIONS, SECTIONS, SMALL_WINDOW_SECTIONS } from '../model';

export interface LandingPageProps {
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

export interface ISection {
  icon: IconDefinition;
  label: string;
}

export const LandingPage = (props: LandingPageProps) => {
  const isShort = useWindowHeightListener(700, () => props.setCurrentPage(0));
  const { trackApplicationFeature } = useContext(MatomoContext);
  useClearFiltersOnNavFromBookmark();
  usePageTrackingWithTitle(PAGES.HOME);

  const onNavigationItemClick = (index: number) => {
    const pageName = isShort ? SMALL_WINDOW_SECTIONS[index]?.label : SECTIONS[index]?.label;
    props.setCurrentPage(index);
    if (pageName) {
      trackApplicationFeature(SEARCH_APP_FEATURE_LIST.LANDING_PAGE_NAVIGATION, pageName);
    }
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    props.setCurrentPage(0);
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);
  const isMobile = useWindowSizeListener();
  if (isMobile) {
    return <LongLandingPage onNavigationItemClick={onNavigationItemClick} sections={MOBILE_SECTIONS} {...props} />;
  } else if (isShort) {
    return (
      <ShortLandingPage onNavigationItemClick={onNavigationItemClick} sections={SMALL_WINDOW_SECTIONS} {...props} />
    );
  }

  return <LongLandingPage onNavigationItemClick={onNavigationItemClick} sections={SECTIONS} {...props} />;
};

export default LandingPage;

export interface ShortLandingPageProps extends LandingPageProps {
  sections: ISection[];
  onNavigationItemClick: (page: number) => void;
}
export const ShortLandingPage = (props: ShortLandingPageProps) => {
  const [blockScroll, setBlockScroll] = useState(false);
  const isMobile = useWindowSizeListener();
  const isZoomed = useWindowZoomListener();

  useEffect(() => {
    const timer1 = setTimeout(() => props.setCurrentPage(0), 1500);

    return () => {
      clearTimeout(timer1);
    };
  }, []);

  useEffect(() => {
    setBlockScroll(false);
  }, [props.currentPage]);

  return (
    <>
      <ReactPageScroller
        pageOnChange={props.onNavigationItemClick}
        customPageNumber={props.currentPage}
        renderAllPagesOnFirstRender
        blockScrollDown={blockScroll}
        blockScrollUp={blockScroll}
      >
        <Col xs="12" className="d-flex justify-content-center h-100">
          <Row className="d-flex justify-content-center w-100">
            <Col
              as="section"
              xs="12"
              className={classNames('d-flex flex-column px-md-5 search-section', {
                'search-mobile-section': isMobile,
                'search-web-section': !isMobile,
              })}
            >
              <SearchBoxSection
                isMobile={isMobile}
                currentPageNumber={props.currentPage}
                setBlockScroll={setBlockScroll}
              />
            </Col>
          </Row>
        </Col>
        <Col xs="12" className="d-flex justify-content-center h-100">
          <Row className="d-flex justify-content-center w-100">
            <Col xs="12" className={classNames('data-source-section mt-3', { 'mt-4': isZoomed })}>
              <DataSourceSection setBlockScroll={setBlockScroll} />
            </Col>
          </Row>
        </Col>
        {/* <Col xs="12" className="d-flex justify-content-center h-100">
          <Row className="d-flex justify-content-center w-100">
            <Col
              id="recommendations"
              as="section"
              xs="12"
              md="10"
              className="suggestion-section d-lg-flex justify-content-lg-center mt-n2 mb-md-5"
            >
              <SuggestionSection />
            </Col>
          </Row>
        </Col> */}
        <Col xs="12" className="d-flex justify-content-center h-100 third-section">
          <Row className="d-flex justify-content-center w-100">
            <Col as="section" xs="12" md="10" className="d-lg-flex justify-content-lg-center mt-2 mb-md-5">
              <AppSection currentPage={props.currentPage} />
            </Col>
          </Row>
        </Col>

        <Col xs="12" className="d-flex justify-content-center h-100 fourth-section">
          <Row className="d-flex justify-content-center w-100">
            <Col as="section" xs="12" md="11" className="d-lg-flex justify-content-lg-center mt-2 mb-md-5 ml-md-5">
              <FeatureRoadmap
                setBlockScroll={setBlockScroll}
                currentPage={props.currentPage}
                setCurrentPage={props.setCurrentPage}
              />
            </Col>
          </Row>
        </Col>
      </ReactPageScroller>
      <DotNavigator
        isMobile={isMobile}
        sections={props.sections}
        currentPage={props.currentPage}
        handleNavigatorClick={props.onNavigationItemClick}
      />
      {props.currentPage === 0 && (
        <div className="page-down position-absolute" style={{ bottom: '3.5%' }}>
          <FontAwesomeIcon icon={faChevronDown} className="text-primary go-down-animate" size="lg" />
        </div>
      )}
    </>
  );
};

export const LongLandingPage = (props: ShortLandingPageProps) => {
  const [blockScroll, setBlockScroll] = useState(false);

  const isMobile = useWindowSizeListener();
  const isZoomed = useWindowZoomListener(1.2);

  useEffect(() => {
    const timer1 = setTimeout(() => props.setCurrentPage(0), 1500);
    return () => {
      clearTimeout(timer1);
    };
  }, []);

  useEffect(() => {
    setBlockScroll(false);
  }, [props.currentPage]);

  return (
    <>
      <ReactPageScroller
        pageOnChange={props.onNavigationItemClick}
        customPageNumber={props.currentPage}
        blockScrollDown={blockScroll || props.currentPage > props.sections.length - 2}
        blockScrollUp={blockScroll}
        renderAllPagesOnFirstRender
      >
        <Col xs="12" className="d-flex justify-content-center">
          <Row className="d-flex justify-content-center w-100">
            <Col
              as="section"
              xs="12"
              className={classNames('d-flex flex-column px-sm-5 search-section', {
                'search-mobile-section': isMobile,
                'search-web-section': !isMobile,
              })}
            >
              <SearchBoxSection
                isMobile={isMobile}
                currentPageNumber={props.currentPage}
                setBlockScroll={setBlockScroll}
              />
            </Col>

            <Col as="section" xs="12" md="11" lg="11" className="data-source-section px-0">
              <DataSourceSection setBlockScroll={setBlockScroll} />
            </Col>
          </Row>
        </Col>

        {/* <Col xs="12" className="d-flex justify-content-center h-100">
          <Row className="d-flex justify-content-center w-100">
            <Col
              id="recommendations"
              as="section"
              xs="12"
              md="10"
              className="suggestion-section d-lg-flex justify-content-lg-center mt-2 mb-md-5"
            >
              <SuggestionSection />
            </Col>
          </Row>
        </Col> */}
        <Col xs="12" className="d-flex justify-content-center h-100 third-section">
          <Row className="d-flex justify-content-center w-100">
            <Col as="section" xs="12" md="10" className="d-lg-flex justify-content-lg-center mt-2 mb-md-5">
              <AppSection currentPage={props.currentPage} />
            </Col>
          </Row>
        </Col>

        <Col xs="12" className="d-flex justify-content-center h-100 fourth-section">
          <Row className="d-flex justify-content-center w-100">
            <Col as="section" xs="12" md="11" className="d-lg-flex justify-content-lg-center mt-2 mb-md-5 ml-md-5">
              <FeatureRoadmap
                setBlockScroll={setBlockScroll}
                currentPage={props.currentPage}
                setCurrentPage={props.setCurrentPage}
              />
            </Col>
          </Row>
        </Col>
      </ReactPageScroller>
      <DotNavigator
        isMobile={isMobile}
        sections={props.sections}
        currentPage={props.currentPage}
        handleNavigatorClick={props.onNavigationItemClick}
      />
      {props.currentPage === 0 && (
        <div
          className={classNames('position-absolute', {
            'page-down': isZoomed,
          })}
          style={{ bottom: '1.5%' }}
        >
          <FontAwesomeIcon icon={faChevronDown} className="text-primary go-down-animate" size="lg" />
        </div>
      )}
    </>
  );
};
