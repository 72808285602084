import { AppliedFiltersBar, useAppliedFiltersWithPersistentValues } from '@amgen/feature-facets';
import { SearchPageContainer, SpellcheckLink } from '@amgen/feature-result-views';
import {
  BackToResultsBtn,
  ClusteredResultsList,
  SelectedAsset,
  TopicsDescription,
  TopicsList,
  TopicsPageBody,
  TopicsPageHeader,
} from '@amgen/feature-topics';
import {
  ClinicalContextProvider,
  PAGES,
  SearchContextProvider,
  usePageTrackingWithTitle,
  useSearchQueryValue,
} from '@amgen/shared';
import React from 'react';

import './topics-page.scss';

/* eslint-disable-next-line */
export interface TopicsPageProps {}

export function TopicsPage(props: TopicsPageProps) {
  const query = useSearchQueryValue();
  const filters = useAppliedFiltersWithPersistentValues();

  usePageTrackingWithTitle(PAGES.TOPICS_PAGE);

  return (
    <SearchContextProvider query={query} filters={filters} trackMatomoPage={'eea-home'} isExpandable>
      <ClinicalContextProvider>
        <SearchPageContainer>
          <TopicsPageHeader>
            <SpellcheckLink />
            <TopicsDescription />
            <AppliedFiltersBar />
            <TopicsList />
          </TopicsPageHeader>
          <TopicsPageBody>
            <ClusteredResultsList />
            <SelectedAsset />
          </TopicsPageBody>
          <BackToResultsBtn />
        </SearchPageContainer>
      </ClinicalContextProvider>
    </SearchContextProvider>
  );
}

export default TopicsPage;
