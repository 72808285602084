import { IDataCrawlRequest, RequestStatusType } from '@amgen/core';
import { useWindowSizeListener } from '@amgen/shared';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { Table } from 'react-bootstrap';
import { CellProps, Column, useTable } from 'react-table';

import './admin-request-table.scss';

import { ADMIN_APPROVED_DECLINED_COL, ADMIN_PENDING_COL } from '../../config';
import { SortOrderObject } from '../../models/sort';
import ActionCell from '../action-cell/action-cell';
import IgdTableBody from '../igd-table-body/igd-table-body';
import IgdTableHead from '../igd-table-head/igd-table-head';

/* eslint-disable-next-line */
export interface AdminRequestTableProps {
  data: IDataCrawlRequest[];
  status: RequestStatusType;
  loading: boolean;
  sort: SortOrderObject;
  setSort: (sort: SortOrderObject) => void;
  onSelect: (request: IDataCrawlRequest, isApprove?: boolean) => void;
}

export const AdminRequestTable = ({ data, onSelect, status, sort, loading, setSort }: AdminRequestTableProps) => {
  const PENDING_COL = [
    ...ADMIN_PENDING_COL,
    {
      Header: 'Action',
      Cell: ({ row }: CellProps<IDataCrawlRequest>) => (
        <ActionCell onAction={type => onSelect(row.original, type === RequestStatusType.APPROVED)} />
      ),
    },
  ];

  const columns: Column<IDataCrawlRequest>[] = useMemo(() => {
    return status === RequestStatusType.PENDING ? PENDING_COL : ADMIN_APPROVED_DECLINED_COL;
  }, [status]);

  const table = useTable<IDataCrawlRequest>({ data, columns });
  const isMobile = useWindowSizeListener();

  return (
    <>
      <Table striped bordered hover responsive {...table.getTableProps()} className={isMobile ? 'fs-12' : ''}>
        <thead>
          <IgdTableHead headerGroups={table.headerGroups} setSort={setSort} sort={sort} />
        </thead>

        <IgdTableBody table={table} loading={loading} />
      </Table>
      {!loading && table.rows.length < 1 ? (
        <div className={classNames('py-5 text-center bg-grey mt-n3 mb-3', { 'fs-14': isMobile })}>
          No Results found. Try removing filters or searching for other terms
        </div>
      ) : null}
    </>
  );
};

export default AdminRequestTable;
