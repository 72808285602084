/**
 * Remove an element from an array of objects and return the new array
 * @param arr Array
 * @param key key used to find the index of element to be removed
 * @param value value to be matched against in the object
 */
export function remove<T extends Record<string, unknown>, K extends keyof T>(arr: T[], key: keyof T, value: T[K]): T[] {
  const copy = arr.slice();
  const index = arr.findIndex(el => el[key] === value);
  copy.splice(index, 1);
  return copy;
}

export function toggle<T>(arr: T[], value: T, predicate: (value: T) => boolean): T[] {
  const copy = arr.slice();
  const index = arr.findIndex(predicate);
  if (index >= 0) {
    copy.splice(index, 1);
  } else {
    copy.push(value);
  }
  return copy;
}

export const checkDissimilarity = (compareArray: string[] | undefined, arrayToBeCompared: string[]) => {
  // get two arrays concat them assuming all the items are unique: that would be ideal diff [(A - B) U (B - A)]
  if (!arrayToBeCompared || arrayToBeCompared.length < 0) {
    arrayToBeCompared = [];
  }
  let difference: string[] = arrayToBeCompared.concat(compareArray ? compareArray : []);
  // reducing the comparisons, will check diff only if lengths are same
  if (compareArray && arrayToBeCompared && compareArray.length === arrayToBeCompared.length) {
    difference = compareArray
      .filter(x => !arrayToBeCompared.includes(x))
      .concat(arrayToBeCompared.filter(x => !compareArray.includes(x)));
  }
  return difference ? difference.length < 1 : false;
};

/**
 * groupBy array objects by the key supplied
 * @param items the array of objects need to grouped
 * @param key the key to be grouped on
 * @returns
 */
export const groupBy = <T>(items: T[], key: keyof Omit<T, 'coordinates'>) =>
  items.reduce(
    (
      result: {
        [key: string]: T[];
      },
      item
    ) => ({
      ...result,
      [(item[key] as unknown) as string]: [...(result[(item[key] as unknown) as string] || []), item],
    }),
    {}
  );
