import { IDataAsset } from './api.generated';

export enum RecommendationType {
  QNA = 'QnA',
  DATA_ASSET = 'DATA_ASSET',
}

interface IAbstractRecommendation {
  suggestion_id: string;
  suggestion_title: string;
  suggestion_url: string;
}

export interface IQnARecommendation extends IAbstractRecommendation {
  suggestion_type: RecommendationType.QNA;
  suggestion: string;
  query_id: string;
  result_id: string;
}

export interface IAssetRecommendation extends IAbstractRecommendation {
  suggestion_type: RecommendationType.DATA_ASSET;
  suggestion: IDataAsset;
}

export interface IQnANewRecommendation {
  content: [string];
  title: string;
  url: string;
  id: string;
  suggestion: IDataAsset;
}

export type IRecommendation = IQnARecommendation | IAssetRecommendation;
