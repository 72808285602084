import { useOutsideClick } from '@amgen/shared';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { Button, ButtonGroup, Col, Dropdown, Row, Spinner } from 'react-bootstrap';

import './dropdown-container.scss';

export interface DropdownContainerProps {
  title: ReactNode;
  onValueToggle: (val: string) => void;
  onClear?: () => void;
  isLoading?: boolean;
  isOpen?: boolean;
  closeAfterSelection?: boolean;
  isAddFilter?: boolean;
  isExpand?: boolean;
  hideClearButton?: boolean;
  onToggle?: () => void;
}

export const DropdownContainer: React.FC<DropdownContainerProps> = props => {
  const [show, setShow] = useState(props.isExpand);

  const dropdownWidth = props.onClear ? '11' : '12';

  useEffect(() => {
    // NOTE: This is a hack to close the dropdown after every selection.
    // Ideally, the isOpen state should be stored only in the parent component.
    // This intentionally violates unidirectional data flow philosophy of React
    if (props.closeAfterSelection) {
      if (!props.isOpen) {
        setShow(false);
      }
    }
  }, [props.isOpen, show, props.closeAfterSelection]);

  const containerRef = useRef<any>(null);
  useOutsideClick(containerRef, () => setShow(false));

  return (
    <Row noGutters>
      <Col xs={dropdownWidth}>
        <Dropdown
          as={ButtonGroup}
          className="facet-dropdown-container w-100"
          onSelect={(value, _) => props.onValueToggle(value ?? '')}
          alignRight
          show={show}
        >
          <Button
            block
            variant="light"
            className={classNames('d-flex justify-content-between align-items-center border', {
              'border-primary bg-light-blue': props.isAddFilter,
            })}
            onClick={() => setShow(!show)}
          >
            <span className={classNames({ 'text-primary': props.isAddFilter })}>{props.title}</span>
            {props.isLoading && <Spinner className="text-primary" animation="border" size="sm" />}
          </Button>
          <Dropdown.Toggle
            split
            variant="light"
            className={classNames('border bg-white text-primary', {
              'border-primary bg-light-blue': props.isAddFilter,
            })}
            onClick={() => {
              setShow(!show);
              props.onToggle?.();
            }}
          />
          <Dropdown.Menu ref={containerRef}>{props.children}</Dropdown.Menu>
        </Dropdown>
      </Col>

      {props.onClear && !props.hideClearButton && (
        <Col xs="1" className="text-right pl-2 d-flex align-items-center">
          <span
            className="facet-clear-icon cursor-pointer"
            onClick={props.onClear}
            title="Clear all values for this Category"
          >
            <FontAwesomeIcon icon={faTimesCircle} />
          </span>
        </Col>
      )}
    </Row>
  );
};

export default DropdownContainer;
