import React from 'react';
import { Row } from 'react-bootstrap';

import './topics-page-header.module.scss';

/* eslint-disable-next-line */
export interface TopicsPageHeaderProps {}

export const TopicsPageHeader: React.FC<TopicsPageHeaderProps> = ({ children }) => {
  return (
    <Row noGutters className="flex-column ml-4">
      {children}
    </Row>
  );
};

export default TopicsPageHeader;
