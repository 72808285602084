import { useSuggestedDestinationsExportQuery } from '@amgen/api';
import { DEFAULT_SEARCH_QUERY, useEnvironment } from '@amgen/core';
import { exportCSVFile } from '@amgen/feature-csv-export';
import { MatomoContext, useToast } from '@amgen/shared';
import { Form, Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { Button, Col, Spinner } from 'react-bootstrap';

import './suggested-destinations-export-form.scss';

import ExportRequestsFieldInput from './export-requests-field-input/export-requests-field-input';
import { CRAWL_FIELD_NAMES, ISuggestedAppsExportRequestFormValue, SUGGESTED_APPS_FIELDS, validate } from './models';

export interface SuggestedDestinationsExportFormProps {
  onCancel: () => void;
}

export const SuggestedDestinationsExportForm = (props: SuggestedDestinationsExportFormProps) => {
  const toast = useToast();
  const { appName } = useEnvironment();
  const { trackApplicationFeature } = useContext(MatomoContext);
  const variables = {
    appName,
    query: DEFAULT_SEARCH_QUERY,
    count: 500,
    page: 0,
    start: 0,
  };
  const exportFileName = () => `All Requests`;

  const exportFields = SUGGESTED_APPS_FIELDS;
  const getRequests = useSuggestedDestinationsExportQuery(variables);
  const [allowSubmit, setAllowSubmit] = useState(true);
  return (
    <Formik<ISuggestedAppsExportRequestFormValue>
      initialValues={{ fields: exportFields }}
      onSubmit={async values => {
        const data = await getRequests();
        const exportData = data?.getDestinations?.requests?.map(req =>
          values.fields.reduce(
            (obj, field) => ({
              ...obj,
              [CRAWL_FIELD_NAMES[field]]: typeof req[field] === 'boolean' ? (req[field] ? 'Yes' : 'No') : req[field],
            }),
            {}
          )
        );
        if (exportData?.length) {
          // trackApplicationFeature(
          //   SEARCH_APP_FEATURE_LIST.IGD,
          //   props.pageType === PageType.IGD_MY_REQUEST ? IGD_ACTIONS.IGD_EXPORT_REQUEST : IGD_ACTIONS.IGD_EXPORT_ADMIN,
          //   exportData?.length - 1
          // );
          await exportCSVFile(exportData ?? [], exportFileName());
        } else {
          toast.warning({
            message: 'No requests found at present to export, Please try again after sometime.',
            timeout: 5000,
          });
        }
      }}
      validate={validate}
    >
      {({ isSubmitting }) => (
        <Form>
          <ExportRequestsFieldInput exportFields={exportFields} setAllowSubmit={setAllowSubmit} />

          <Col xs="12" className="text-center">
            <Button variant="primary" type="submit" className="px-lg-5 mr-3" disabled={isSubmitting || !allowSubmit}>
              {isSubmitting ? <Spinner animation="border" size="sm" className="text-white" /> : 'Export'}
            </Button>
            <Button
              variant="outline-secondary"
              type="button"
              className="px-lg-5"
              onClick={props.onCancel}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
          </Col>
        </Form>
      )}
    </Formik>
  );
};

export default SuggestedDestinationsExportForm;
