import { useReportDocumentMutation } from '@amgen/api';
import { ApplicationName, useEnvironment } from '@amgen/core';
import { useAssetTitleUrl } from '@amgen/feature-search';
import {
  AppContext,
  AssetContext,
  MatomoContext,
  REPORT_BTN_ACTIONS,
  SEARCH_APP_FEATURE_LIST,
  SearchContext,
  useToast,
  useWindowSizeListener,
} from '@amgen/shared';
import React, { useContext, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

import './report-button.scss';

/* eslint-disable-next-line */
export interface ReportButtonProps {
  buttonText?: string;
  type?: string;
}

export function ReportButton(props: ReportButtonProps) {
  const { internalSources } = useContext(AppContext);
  const { trackApplicationFeature } = useContext(MatomoContext);
  const { docSolrAsset } = useContext(AssetContext);
  const { appData } = useContext(SearchContext);
  const abTestingGroup = JSON.parse(sessionStorage.getItem('abTestingGroup') as string);
  const [showReport, setShowReport] = useState(false);
  const [reportComment, setReportComment] = useState('');
  const [reportDoc] = useReportDocumentMutation();
  const { title: assetTitle } = useAssetTitleUrl(
    props.type === 'apps' && abTestingGroup ? appData?.getSearchObjectsApps?.docs[0]! : docSolrAsset?.dataAsset!
  );
  const { appName } = useEnvironment();
  const applicationName: ApplicationName = sessionStorage.getItem('appName')
    ? ApplicationName[sessionStorage.getItem('appName')!]
    : ApplicationName.EEA;
  const toast = useToast();

  const sendReport = () => {
    setShowReport(!showReport);
    reportDoc({
      variables: {
        reportDocumentInput: {
          assetId:
            props.type === 'apps' && abTestingGroup
              ? appData?.getSearchObjectsApps?.docs[0]?.id!
              : docSolrAsset?.dataAsset?.id!,
          comment: reportComment,
          applicationName: applicationName,
          name: assetTitle,
          contentSource: abTestingGroup
            ? props.type === 'apps' || docSolrAsset?.dataAsset?.content_source == null
              ? 'EEA'
              : docSolrAsset?.dataAsset?.content_source
            : docSolrAsset?.dataAsset?.content_source,
        },
      },
    })
      .then(() => {
        trackApplicationFeature(SEARCH_APP_FEATURE_LIST.REPORT_BTN, REPORT_BTN_ACTIONS.REPORT);
        toast.info({
          message: 'Reported successfully',
          timeout: 5000,
        });
      })
      .catch(err =>
        toast.danger({
          message: 'Something went wrong. Please try again later',
          timeout: 5000,
        })
      );
  };
  const isMobile = useWindowSizeListener();
  const dataId =
    props.type === 'apps' && abTestingGroup ? appData?.getSearchObjectsApps?.docs[0]?.id : docSolrAsset?.dataAsset?.id;
  return dataId ? (
    <>
      <span className="cursor-pointer mt-1 mr-n1" title="Raise a flag if sensitive information is exposed">
        <img
          src="assets/icons/report-icon.png"
          alt="report-btn"
          onClick={() => setShowReport(!showReport)}
          style={{ width: isMobile ? '25px' : '32px', paddingRight: isMobile ? '4px' : '' }}
        />
        {isMobile && <span className="ml-0 mr-1 button-text">{props.buttonText}</span>}
      </span>

      <Modal show={showReport} onHide={() => setShowReport(!showReport)} centered>
        <Modal.Header closeButton>
          <Modal.Title as="h2">Report any document security issue here</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formBasicComment">
            <Form.Control
              as="textarea"
              className="thin-scrollbar"
              placeholder="Please describe the issue"
              style={{ minHeight: '18vh' }}
              onChange={e => setReportComment(e.target.value)}
              maxLength={500}
              value={reportComment}
              isValid={reportComment.trim().length > 0 && reportComment.trim().length <= 500}
            />
            <Form.Control.Feedback type="valid" className="text-left text-black-50">{`${
              500 - reportComment.trim().length
            }/500`}</Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={sendReport} disabled={!reportComment.trim()}>
            Submit
          </Button>
          <Button variant="secondary" onClick={() => setShowReport(!showReport)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  ) : null;
}

export default ReportButton;
