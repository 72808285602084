import { ApplicationName, IAssetAttribute } from '@amgen/core';
import {
  AppContext,
  FacetContext,
  GENAI_QnA_ACTIONS,
  MatomoContext,
  PreferenceContext,
  ResultViewType,
  SEARCH_APP_FEATURE_LIST,
  SearchContext,
  useSearchQueryValue,
} from '@amgen/shared';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import LazyLoad from 'react-lazyload';

import './result-view.scss';

import { useResultView } from '../../constants';
import GenAIMultiTurn from '../gen-ai-multi-turn/gen-ai-multi-turn';
import GenerativeAIMultiLoader from '../gen-ai-multi-turn/gen-ai-multi-turn-loader';
import GenerativeAI from '../generative-ai/generative-ai';
import GenerativeAILoader from '../generative-ai/generative-ai-loading';
import RecommendedResults from '../recommended-results/recommended-results';

export interface ResultViewProps {
  isBookmarksDisabled?: boolean;
  hideRatingBtn?: boolean;
  disableRouterLink?: boolean;
  pageNo: number;
}

export const ResultView: React.FC<ResultViewProps> = props => {
  const {
    selectedViewType,
    results,
    pageNo,
    recommendedResultCount,
    generativeAIData,
    generativeAILoading,
    recommendedTopData,
    genAIDeeplinkData,
    conversationalSearchData,
    multiTurnConversationList,
    multiTurnAPICompleted,
    followUpQuery,
    setFinalFollowupQuery,
  } = useContext(SearchContext);
  const { trackApplicationFeature, trackCustomDimensions, trackMultipleCustomDimensions, dimensions } = useContext(
    MatomoContext
  );
  const { appliedFilters } = useContext(FacetContext);
  const { selectedDefaultColumns } = useContext(PreferenceContext);
  const { selectedResultTab, setBottomButtonClicked } = useContext(AppContext);
  const [selectedColumns, setSelectedColumns] = useState<Set<IAssetAttribute>>(new Set(selectedDefaultColumns));
  const [genAITimeOut, setGenAITimeOut] = useState<boolean>(false);
  const [experiment, setexperiment] = useState<boolean>(false);
  const applicationName: ApplicationName = sessionStorage.getItem('appName')
    ? ApplicationName[sessionStorage.getItem('appName')!]
    : ApplicationName.EEA;
  // Card Search Result
  const View = useResultView(selectedViewType);
  const searchTerm = useSearchQueryValue();
  const abTestingGroup = JSON.parse(sessionStorage.getItem('abTestingGroup') as string);
  const followUpQueryRef = useRef<HTMLDivElement>(null);
  const currentCustomsource = sessionStorage.getItem('current-source-selected');

  const goToAllResults = () => {
    setBottomButtonClicked(true);
  };

  useEffect(() => {
    if (followUpQueryRef.current) {
      const yOffset = -6 * 16; // Offset in pixels, assuming 1rem = 16px
      const element = followUpQueryRef.current;
      const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }, [followUpQuery]);

  useEffect(() => {
    let genAITimeOutID;
    if (generativeAILoading) {
      genAITimeOutID = setTimeout(() => {
        setGenAITimeOut(true);
      }, 15000);
    }
    return () => {
      clearTimeout(genAITimeOutID);
    };
  }, [generativeAILoading]);

  useEffect(() => {
    if (
      recommendedTopData.length >= 1 &&
      generativeAIData &&
      generativeAIData.generateQA &&
      generativeAIData.generateQA.length > 0 &&
      !genAITimeOut &&
      appliedFilters?.count === 0 &&
      !abTestingGroup
    ) {
      trackCustomDimensions(searchTerm, dimensions.GENAI_QNA_QUERIES);
      trackApplicationFeature(SEARCH_APP_FEATURE_LIST.GEN_AI_QnA, GENAI_QnA_ACTIONS.GENAI_QnA_INVOKED);
    }
  }, [JSON.stringify(generativeAIData)]);

  useEffect(() => {
    // if the user is coming from KG page , then browser scroll is not visible for abusers due to removing of chatbot from the page
    document.body.style.overflow = 'scroll';
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (multiTurnConversationList.length >= 1 && appliedFilters?.count === 0 && abTestingGroup) {
      let searchedQuery;
      multiTurnConversationList?.forEach(item => {
        searchedQuery = searchedQuery + ' ==> ' + item?.term;
      });
      const searchedQueryArr = searchedQuery?.split(' ==> ');
      const uniqueParts = [...new Set(searchedQueryArr)];
      let finalSearchedQuery;
      if (uniqueParts?.length >= 2) finalSearchedQuery = uniqueParts?.slice(1)?.join(' ==> ');
      if (
        finalSearchedQuery !== null &&
        finalSearchedQuery !== undefined &&
        finalSearchedQuery !== '' &&
        finalSearchedQuery?.includes(' ==> ')
      ) {
        const userDimensionMap: Map<string, string> = new Map();
        userDimensionMap.set(dimensions.CONVERSATIONAL_SEARCH_QNA_QUERIES as string, finalSearchedQuery);
        trackMultipleCustomDimensions(userDimensionMap);
        setFinalFollowupQuery(finalSearchedQuery);
        // trackApplicationFeature(SEARCH_APP_FEATURE_LIST.GEN_AI_CONVERSATIONAL_SEARCH, CONVERSATIONAL_SEARCH_QnA_ACTIONS.CONVERSATIONAL_SEARCH_QnA_FOLLOWUPS);
      }
    }
  }, [JSON.stringify(multiTurnConversationList)]);

  const getGenAIComponent = () => {
    return (
      <View
        results={results}
        pageNo={pageNo}
        selectedColumns={selectedColumns}
        onSelectedColumnsChange={setSelectedColumns}
        isBookmarksDisabled={props.isBookmarksDisabled}
        hideRatingBtn={props.hideRatingBtn}
        disableRouterLink={props.disableRouterLink}
      />
    );
  };

  return (
    <>
      {selectedResultTab === 'All Results' && !abTestingGroup && getGenAIComponent()}
      {selectedResultTab === 'Recommended' ? (
        <>
          {!abTestingGroup && (
            <div className="col d-flex pl-4 ml-2 top-go-to-allResult">
              <span>Top recommended results, </span>
              <Button
                className="cursor-pointer d-flex align-items-center pt-0 pr-1 pl-1 top-go-to-allResult"
                variant="link"
                onClick={goToAllResults}
              >
                click here
              </Button>
              <span>to view all results </span>
            </div>
          )}
          {recommendedTopData.length >= 1 &&
          generativeAIData &&
          generativeAIData.generateQA &&
          generativeAIData.generateQA.length > 0 &&
          !genAITimeOut &&
          appliedFilters?.count === 0 ? (
            <LazyLoad>
              <GenerativeAI />
            </LazyLoad>
          ) : searchTerm === '*:*' ||
            recommendedTopData.length < 1 ||
            applicationName === 'DVIEW' ||
            applicationName === 'OpsKM' ||
            applicationName === 'Catalyst' ||
            applicationName === 'KMI' ||
            (generativeAIData && generativeAIData.generateQA && generativeAIData.generateQA.length === 0) ||
            // horizonCompanyCode ||
            genAITimeOut ||
            appliedFilters?.count > 0 ? (
            <></>
          ) : (
            // horizonCompanyCode || appliedFilters?.count > 0 ? (
            //   <></>
            // ) :
            <GenerativeAILoader />
          )}
        </>
      ) : (
        <></>
      )}

      {selectedResultTab === 'All Results' &&
      abTestingGroup &&
      results.length > 0 &&
      currentCustomsource !== 'Contract Search' &&
      selectedViewType !== ResultViewType.Table ? (
        !multiTurnAPICompleted && multiTurnConversationList.length > 0 && followUpQuery !== '' ? (
          <LazyLoad>
            <GenAIMultiTurn />
            <div className="follow-up-query-container mt-5 pr-2 mb-3" ref={followUpQueryRef}>
              <div className="follow-up-query-align">{followUpQuery}</div>
            </div>
            <GenerativeAIMultiLoader />
          </LazyLoad>
        ) : multiTurnConversationList.length > 0 ? (
          <LazyLoad>
            <GenAIMultiTurn />
          </LazyLoad>
        ) : multiTurnConversationList.length === 0 && !multiTurnAPICompleted ? (
          <GenerativeAIMultiLoader />
        ) : (
          <></>
        )
      ) : (
        <></>
      )}

      {selectedResultTab === 'All Results' && abTestingGroup && getGenAIComponent()}
      {selectedResultTab === 'Recommended' && (
        <RecommendedResults results={results} pageNo={pageNo} hideUserFeedback={false} />
      )}
      {selectedResultTab === 'Recommended' && (
        <div className="container ">
          <div className="row">
            <div className="col d-flex text-center justify-content-center">
              <Button className="cursor-pointer d-flex align-items-center" variant="link" onClick={goToAllResults}>
                <span>See All Results</span>
                <FontAwesomeIcon
                  className="recommended-explore-button ml-2"
                  icon={faAngleDoubleRight}
                  title=""
                  size="lg"
                />
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ResultView;
