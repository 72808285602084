import { ClinicalContext, KNOWLEDGE_PANEL_FEATURE_ACTIONS, MatomoContext, SEARCH_APP_FEATURE_LIST } from '@amgen/shared';
import { amgNumFormatter, isAmgNum } from '@amgen/utils';
import DOMPurify from 'dompurify';
import React, { useContext } from 'react';
import { Col } from 'react-bootstrap';

import './related-products.scss';

export interface RelatedProductsProps {
  isOnKnowledgeGraph?: boolean;
  isexploreExpanded?: boolean;
}

export const RelatedProducts: React.FC<RelatedProductsProps> = ({ isOnKnowledgeGraph, isexploreExpanded }) => {
  const { addGraph, product } = useContext(ClinicalContext);
  const amgValue = product?.amgNumber;
  const amgID = amgValue?.slice(3);
  const { trackApplicationFeature } = useContext(MatomoContext);

  // const catalystLink =
  //   product?.catalystLink ??
  //   `http://apps.cfda.amgen.com:3939/CatalystConnectApp/?_values_&currentVec=%22Product%20%3A%20AMG%20${amgID}%22`;

  return product?.catalystLink ? (
    <>
      <Col className={isexploreExpanded ? 'col-12 pt-2' : 'col-12 pt-2 p-0'}>
        <span>
          <img src={'assets/images/spark-transparent.png'} className="default-product-img" alt={''} width="30px" />
        </span>
        <a
          href={DOMPurify.sanitize(product?.catalystLink)}
          target="_blank"
          rel="noreferrer"
          className="related-products"
        >
          Visit <span className="related-products-link-text">Catalyst Connect</span> to locate staff
        </a>
      </Col>

      {product?.relatedProduct && (
        <Col className={isexploreExpanded ? 'col-12 p-0 pl-3 d-flex' : 'col-12 p-0 d-flex'}>
          <div className="d-flex related-products mt-3 font-weight-bold mr-3">
            {product?.relatedProduct ? `Related Products${isOnKnowledgeGraph ? '' : ':'}` : <></>}
          </div>
          <div className="d-flex flex-wrap my-1 text-capitalize">
            {product?.relatedProduct?.map(product => (
              <div
                className="d-flex align-items-center mb-1 mr-3 mt-2 p-1 cursor-pointer related-product"
                key={product.product}
                onClick={() => {
                  trackApplicationFeature(
                    SEARCH_APP_FEATURE_LIST.KNOWLEDGE_PANEL,
                    KNOWLEDGE_PANEL_FEATURE_ACTIONS.SELECT_RELATED_PRODUCT
                  );
                  addGraph('product', product.product);
                }}
              >
                {product.imageUrl ? (
                  <img src={DOMPurify.sanitize(product.imageUrl)} alt={'NA'} className="related-products-image" />
                ) : (
                  <span className="default-product-name">
                    <img src={'assets/images/generic-drug-icon.png'} className="default-product-img" alt={''} />
                    {isAmgNum(product.product) ? amgNumFormatter(product.product) : product.product}
                  </span>
                )}
              </div>
            ))}
          </div>
        </Col>
      )}
      <Col className={isexploreExpanded ? 'col-12 pt-2' : 'col-12 pt-1 p-0'}>
        <span className="fs-13 font-weight-bold attribute-key-text"> Source: </span>
        <a href={'https://products.amgen.com/pop/'} target="_blank" rel="noreferrer" className="related-products">
          <span className="related-products-link-text">
            {' '}
            <span> POP, the Products One Pager</span>
          </span>
          {/* <span>
            {' '}
            <FontAwesomeIcon className="ml-2" icon={faExternalLinkAlt} />
          </span> */}
        </a>
      </Col>
    </>
  ) : null;
};

export default RelatedProducts;
