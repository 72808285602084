import * as Types from '@amgen/core';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type IApplicationFeedbackFragment = Pick<
  Types.IApplicationFeedback,
  'id' | 'timestamp' | 'feedback' | 'title' | 'comment' | 'applicationName'
> & { user: Pick<Types.IUser, 'username' | 'firstName' | 'lastName' | 'costCenter'> };

export type IGetApplicationFeedbacksQueryVariables = Types.Exact<{
  appName: Types.ApplicationName;
  start?: Types.Maybe<Types.Scalars['Int']>;
  page?: Types.Maybe<Types.Scalars['Int']>;
  count?: Types.Maybe<Types.Scalars['Int']>;
  sortField?: Types.Maybe<Types.Scalars['String']>;
  sortOrder?: Types.Maybe<Types.Direction>;
}>;

export type IGetApplicationFeedbacksQuery = { feedbacks: Array<IApplicationFeedbackFragment> };

export type IAddApplicationFeedbackMutationVariables = Types.Exact<{
  feedback?: Types.Maybe<Types.IApplicationFeedbackInput>;
}>;

export type IAddApplicationFeedbackMutation = { addApplicationFeedback?: Types.Maybe<IApplicationFeedbackFragment> };

export type IDocumentFeedbackFragment = Pick<
  Types.IDocumentFeedback,
  | 'applicationName'
  | 'assetId'
  | 'comment'
  | 'costCenter'
  | 'id'
  | 'rating'
  | 'searchTerm'
  | 'timestamp'
  | 'userFirstName'
  | 'userId'
  | 'userLastName'
>;

export type IGetDocumentFeedbacksQueryVariables = Types.Exact<{
  appName: Types.ApplicationName;
  reviewStart?: Types.Maybe<Types.Scalars['Int']>;
  reviewPage?: Types.Maybe<Types.Scalars['Int']>;
  reviewCount?: Types.Maybe<Types.Scalars['Int']>;
  sortField?: Types.Maybe<Types.Scalars['String']>;
  sortOrder?: Types.Maybe<Types.Direction>;
  assetId?: Types.Maybe<Types.Scalars['String']>;
}>;

export type IGetDocumentFeedbacksQuery = {
  getDocumentFeedback?: Types.Maybe<{
    assetFeedbacks?: Types.Maybe<Array<Types.Maybe<IDocumentFeedbackFragment>>>;
    feedbackMetrics?: Types.Maybe<
      Array<
        Types.Maybe<
          Pick<Types.IFacetField, 'field'> & {
            values?: Types.Maybe<Array<Types.Maybe<Pick<Types.IFacetValue, 'value' | 'count'>>>>;
          }
        >
      >
    >;
    userAssetFeedback?: Types.Maybe<IDocumentFeedbackFragment>;
  }>;
};

export type IAddDocumentFeedbackMutationVariables = Types.Exact<{
  documentFeedback?: Types.Maybe<Types.IDocumentFeedbackInput>;
  existingId?: Types.Maybe<Types.Scalars['String']>;
}>;

export type IAddDocumentFeedbackMutation = { addDocumentFeedback?: Types.Maybe<IDocumentFeedbackFragment> };

export const ApplicationFeedbackFragmentDoc = gql`
  fragment ApplicationFeedback on ApplicationFeedback {
    id
    timestamp
    feedback
    title
    comment
    applicationName
    user {
      username
      firstName
      lastName
      costCenter
    }
  }
`;
export const DocumentFeedbackFragmentDoc = gql`
  fragment DocumentFeedback on DocumentFeedback {
    applicationName
    assetId
    comment
    costCenter
    id
    feedback
    searchTerm
    timestamp
    userFirstName
    userId
    userLastName
  }
`;
export const GetApplicationFeedbacksDocument = gql`
  query GetApplicationFeedbacks(
    $appName: ApplicationName!
    $start: Int = 0
    $page: Int = 0
    $count: Int = 10
    $sortField: String
    $sortOrder: Direction
  ) {
    feedbacks(
      applicationName: $appName
      page: $page
      start: $start
      count: $count
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      ...ApplicationFeedback
    }
  }
  ${ApplicationFeedbackFragmentDoc}
`;
export function useGetApplicationFeedbacksQuery(
  baseOptions: Apollo.QueryHookOptions<IGetApplicationFeedbacksQuery, IGetApplicationFeedbacksQueryVariables>
) {
  return Apollo.useQuery<IGetApplicationFeedbacksQuery, IGetApplicationFeedbacksQueryVariables>(
    GetApplicationFeedbacksDocument,
    baseOptions
  );
}
export function useGetApplicationFeedbacksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetApplicationFeedbacksQuery, IGetApplicationFeedbacksQueryVariables>
) {
  return Apollo.useLazyQuery<IGetApplicationFeedbacksQuery, IGetApplicationFeedbacksQueryVariables>(
    GetApplicationFeedbacksDocument,
    baseOptions
  );
}
export type GetApplicationFeedbacksQueryHookResult = ReturnType<typeof useGetApplicationFeedbacksQuery>;
export type GetApplicationFeedbacksLazyQueryHookResult = ReturnType<typeof useGetApplicationFeedbacksLazyQuery>;
export type GetApplicationFeedbacksQueryResult = Apollo.QueryResult<
  IGetApplicationFeedbacksQuery,
  IGetApplicationFeedbacksQueryVariables
>;
export const AddApplicationFeedbackDocument = gql`
  mutation AddApplicationFeedback($feedback: ApplicationFeedbackInput) {
    addApplicationFeedback(feedback: $feedback) {
      ...ApplicationFeedback
    }
  }
  ${ApplicationFeedbackFragmentDoc}
`;
export type IAddApplicationFeedbackMutationFn = Apollo.MutationFunction<
  IAddApplicationFeedbackMutation,
  IAddApplicationFeedbackMutationVariables
>;
export function useAddApplicationFeedbackMutation(
  baseOptions?: Apollo.MutationHookOptions<IAddApplicationFeedbackMutation, IAddApplicationFeedbackMutationVariables>
) {
  return Apollo.useMutation<IAddApplicationFeedbackMutation, IAddApplicationFeedbackMutationVariables>(
    AddApplicationFeedbackDocument,
    baseOptions
  );
}
export type AddApplicationFeedbackMutationHookResult = ReturnType<typeof useAddApplicationFeedbackMutation>;
export type AddApplicationFeedbackMutationResult = Apollo.MutationResult<IAddApplicationFeedbackMutation>;
export type AddApplicationFeedbackMutationOptions = Apollo.BaseMutationOptions<
  IAddApplicationFeedbackMutation,
  IAddApplicationFeedbackMutationVariables
>;
export const GetDocumentFeedbacksDocument = gql`
  query GetDocumentFeedbacks(
    $appName: ApplicationName!
    $reviewStart: Int
    $reviewPage: Int
    $reviewCount: Int
    $sortField: String
    $sortOrder: Direction
    $assetId: String
  ) {
    getDocumentFeedback(
      applicationName: $appName
      reviewPage: $reviewPage
      reviewStart: $reviewStart
      reviewCount: $reviewCount
      sortField: $sortField
      sortOrder: $sortOrder
      assetId: $assetId
    ) {
      assetFeedbacks {
        ...DocumentFeedback
      }
      feedbackMetrics {
        field
        values {
          value
          count
        }
      }
      userAssetFeedback {
        ...DocumentFeedback
      }
    }
  }
  ${DocumentFeedbackFragmentDoc}
`;
export function useGetDocumentFeedbacksQuery(
  baseOptions: Apollo.QueryHookOptions<IGetDocumentFeedbacksQuery, IGetDocumentFeedbacksQueryVariables>
) {
  return Apollo.useQuery<IGetDocumentFeedbacksQuery, IGetDocumentFeedbacksQueryVariables>(
    GetDocumentFeedbacksDocument,
    baseOptions
  );
}
export function useGetDocumentFeedbacksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetDocumentFeedbacksQuery, IGetDocumentFeedbacksQueryVariables>
) {
  return Apollo.useLazyQuery<IGetDocumentFeedbacksQuery, IGetDocumentFeedbacksQueryVariables>(
    GetDocumentFeedbacksDocument,
    baseOptions
  );
}
export type GetDocumentFeedbacksQueryHookResult = ReturnType<typeof useGetDocumentFeedbacksQuery>;
export type GetDocumentFeedbacksLazyQueryHookResult = ReturnType<typeof useGetDocumentFeedbacksLazyQuery>;
export type GetDocumentFeedbacksQueryResult = Apollo.QueryResult<
  IGetDocumentFeedbacksQuery,
  IGetDocumentFeedbacksQueryVariables
>;
export const AddDocumentFeedbackDocument = gql`
  mutation AddDocumentFeedback($documentFeedback: DocumentFeedbackInput, $existingId: String) {
    addDocumentFeedback(documentFeedback: $documentFeedback, existingId: $existingId) {
      ...DocumentFeedback
    }
  }
  ${DocumentFeedbackFragmentDoc}
`;
export type IAddDocumentFeedbackMutationFn = Apollo.MutationFunction<
  IAddDocumentFeedbackMutation,
  IAddDocumentFeedbackMutationVariables
>;
export function useAddDocumentFeedbackMutation(
  baseOptions?: Apollo.MutationHookOptions<IAddDocumentFeedbackMutation, IAddDocumentFeedbackMutationVariables>
) {
  return Apollo.useMutation<IAddDocumentFeedbackMutation, IAddDocumentFeedbackMutationVariables>(
    AddDocumentFeedbackDocument,
    baseOptions
  );
}
export type AddDocumentFeedbackMutationHookResult = ReturnType<typeof useAddDocumentFeedbackMutation>;
export type AddDocumentFeedbackMutationResult = Apollo.MutationResult<IAddDocumentFeedbackMutation>;
export type AddDocumentFeedbackMutationOptions = Apollo.BaseMutationOptions<
  IAddDocumentFeedbackMutation,
  IAddDocumentFeedbackMutationVariables
>;
