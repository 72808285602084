import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import React, { ReactElement, useState } from 'react';
import { ListGroup } from 'react-bootstrap';

import './hamburger-menu.scss';

import Slider from '../slider/slider';
import { HamburgerMenuContext } from './hamburger-menu-context';

export interface HamburgerMenuProps {
  className?: string;
}

export const HamburgerMenu: React.FC<HamburgerMenuProps> = props => {
  const [show, setShow] = useState(false);

  return (
    <>
      <span
        className={classnames('text-white cursor-pointer ', props.className)}
        style={{ fontSize: 'x-large' }}
        onClick={() => setShow(true)}
      >
        <FontAwesomeIcon icon={faBars} />
      </span>

      <Slider variant="dark" show={show} position="left" onToggle={setShow}>
        <ListGroup className="container px-0 az-sidenav">
          <HamburgerMenuContext.Provider value={{ show, setShow }}>{props.children}</HamburgerMenuContext.Provider>
        </ListGroup>
      </Slider>
    </>
  );
};

export default HamburgerMenu;
