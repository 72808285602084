import { useCallback, useEffect, useState } from 'react';

export function useWidthListener(targetId: string, forcedLoad?: boolean) {
  const searchGroupWidth = useCallback(() => {
    const container = document.getElementById(targetId);
    const width = container?.clientWidth ?? 0;
    return width;
  }, [targetId]);

  const [width, setWidth] = useState(searchGroupWidth());

  useEffect(() => {
    const widthListener = () => {
      setWidth(searchGroupWidth());
    };
    widthListener();
    window.addEventListener('resize', widthListener);
    return () => {
      window.removeEventListener('resize', widthListener);
    };
    //  deliberately added flag so that whenever flag changes new width should be updated.
  }, [forcedLoad, searchGroupWidth]);

  return width;
}
