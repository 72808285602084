import { useSearchQuery } from '@amgen/api';
import { Direction, IAssetAttribute, SolrRequestHandler, SortField, useEnvironment } from '@amgen/core';
import { FacetContext, FiltersObject, useAppliedFilters, useSearchQueryValue } from '@amgen/shared';
import React, { useContext, useEffect, useMemo } from 'react';

//import { IAssetAttribute } from '@amgen/core';
//import { useAppliedFiltersWithPersistentValues } from '@amgen/feature-facets';
export const ContractPagesData = () => {
  const { appName } = useEnvironment();
  const query = useSearchQueryValue();
  const { appliedFilters } = useContext(FacetContext);
  function useAppliedFiltersWithPersistentValues(filtersObj?: FiltersObject) {
    const filters = useAppliedFilters(filtersObj);
    const [shouldUse, hierarchicalFilters] = useHierarchicalValues('doc_nav_prefix');
    const persistentFilters = usePersistentFilters();

    return (shouldUse ? hierarchicalFilters : filters)
      .filter(f => !!f && !f.includes('prefix_location'))
      .concat(persistentFilters);
  }

  function usePersistentFilters() {
    const { persistentFilters } = useContext(FacetContext);

    // these filters are included for IRPC.
    const includedFilters = new FiltersObject(persistentFilters.INCLUDE).toQueryParam();

    // these filters are to be excluded for C3.
    const excludedFilters = new FiltersObject(persistentFilters.EXCLUDE).toQueryParam().map(f => `-(${f})`);

    return [...includedFilters, ...excludedFilters];
  }

  function useHierarchicalValues(facetCategory: IAssetAttribute): [boolean, string[]] {
    const { appliedFilters } = useContext(FacetContext);
    const values = appliedFilters.filters?.[facetCategory];
    const hasValues = !!values && values.size > 0;
    const filters = hasValues
      ? appliedFilters
          .clone()
          .removeAll(facetCategory)
          .add([...removeParentValue(values!)], facetCategory)
      : appliedFilters.clone();

    return [hasValues, filters.toQueryParam()];
  }

  function removeParentValue(values: Set<string>) {
    const valuesCopy = new Set(values);
    values.forEach(value => {
      const [depth, ...splits] = value.split('/');
      splits.pop();
      const finalArr = splits.map(function (element, index) {
        return index + '/' + splits.slice(0, index + 1).join('/');
      });
      //const parentValue = [+depth - 1, ...splits].join('/');
      finalArr.forEach(parentValue => {
        valuesCopy.delete(parentValue);
      });
    });

    return valuesCopy;
  }
  const filters = useAppliedFiltersWithPersistentValues(appliedFilters);

  const rangeAppliedFilters = pageFilterVal => {
    const mergedFilters = filters.concat(pageFilterVal);
    const uniqueFilters = new Set(mergedFilters);
    const uniqueFiltlerArr = Array.from(uniqueFilters);
    return uniqueFiltlerArr;
  };

  const variables = useMemo(
    () => ({
      start: 0,
      noShortContent: false,
      // solrHandler: '/search-select',
      solrHandler: appName === 'DVIEW' ? '/dview-search-select' : '/search-select',
      query,
      page: 0,
      count: 0,
      sort: SortField.RELEVANCY,
      order: Direction.ASC,
      includeDocs: false,
      addPersonalizationDetails: true,
      appName,
      requestHandler: SolrRequestHandler.MYAMGEN_SEARCH,
    }),
    [appName, query]
  );

  const variableZeroToNinetyNine = useMemo(
    () => ({
      ...variables,
      filters: rangeAppliedFilters(['page:[ 0 TO 4]']),
    }),
    [variables, filters]
  );

  const variableHundredToThousands = useMemo(
    () => ({
      ...variables,
      filters: rangeAppliedFilters(['page:[ 5 TO 14]']),
    }),
    [variables, filters]
  );

  const variableThousandToTenThousands = useMemo(
    () => ({
      ...variables,
      filters: rangeAppliedFilters(['page:[ 15 TO 59]']),
    }),
    [variables, filters]
  );

  const variableMoreThanTenThousands = useMemo(
    () => ({
      ...variables,
      filters: rangeAppliedFilters(['page:[60 TO *]']),
    }),
    [variables, filters]
  );

  const { data: dataZeroToNinetyNine } = useSearchQuery({
    variables: variableZeroToNinetyNine,
  });

  const { data: dataHundredToThousands } = useSearchQuery({
    variables: variableHundredToThousands,
  });

  const { data: dataThousandToTenThousands } = useSearchQuery({
    variables: variableThousandToTenThousands,
  });

  const { data: dataMoreThanTenThousands } = useSearchQuery({
    variables: variableMoreThanTenThousands,
  });

  return { dataZeroToNinetyNine, dataHundredToThousands, dataThousandToTenThousands, dataMoreThanTenThousands };
};
