import { getEChartSeriesObjectForLine, LoaderErrorContainer, MagicLineChart } from '@amgen/components';
import { IAssetAttribute } from '@amgen/core';
import { useDateFilterOperations } from '@amgen/feature-facets';
import { IInteractionLineChartSeries, MagicLineChartType } from '@amgen/shared';
import React from 'react';
import { debounce } from 'ts-debounce';

import './date-range-chart.scss';

import { useEEADashboardData } from '../../hooks';

export interface DateRangeChartProps {
  chartType: MagicLineChartType;
  chartTitle: string;
  filter: IAssetAttribute;
  setChartType: (chartType: MagicLineChartType) => void;
  onFilterSelect?: (start: string, end: string) => void;
  hasAreaGradient?: boolean;
}

export const DateRangeChart: React.FC<DateRangeChartProps> = props => {
  const { data, loading } = useEEADashboardData(props.filter);
  const { toSolrStartDate, toSolrEndDate } = useDateFilterOperations();

  const reduceToMatchingLineData = () => {
    const LineChart: IInteractionLineChartSeries = {
      secondLineSeries: [],
      xAxisData: [],
    };
    if (data?.facet?.[0]?.values) {
      const facetData = data?.facet?.[0].values;
      facetData.forEach(facet => {
        LineChart.secondLineSeries.push(facet.count ?? 0);
        LineChart.xAxisData.push(new Date(facet.value ?? '').toDateString());
      });
    }
    return LineChart;
  };

  return (
    <LoaderErrorContainer loading={loading} errors={[]}>
      <MagicLineChart
        chartName={props.chartTitle}
        onChartLegendSelectChanged={key => reduceToMatchingLineData()}
        lineChartData={reduceToMatchingLineData()}
        legendData={[props.chartTitle]}
        chartType={props.chartType}
        onChangeChartType={props.setChartType}
        series={[
          getEChartSeriesObjectForLine(
            props.chartTitle,
            props.chartType,
            props.chartTitle,
            reduceToMatchingLineData().secondLineSeries,
            '#019c7c',
            props.hasAreaGradient
          ),
        ]}
        onDataZoom={(start, end) => {
          const facetValues = data?.facet?.[0].values;
          if (facetValues && props.onFilterSelect) {
            debounce(props.onFilterSelect, 200)(
              facetValues[Math.max(0, start)].value!,
              facetValues[Math.min(end, facetValues.length - 1)].value!
            );
          }
        }}
        onChartClick={key => {
          const dateFilter = new Date(data?.facet?.[0].values?.[key.dataIndex as number].value ?? '');
          props.onFilterSelect &&
            dateFilter &&
            props.onFilterSelect(toSolrStartDate(dateFilter), toSolrEndDate(dateFilter));
        }}
      />
    </LoaderErrorContainer>
  );
};

export default DateRangeChart;
