export const objHasTextMatch = <T, U extends keyof T>(obj: T, text: string): boolean => {
  const regex = new RegExp(escapeRegex(text), 'i');

  if (obj) {
    return Object.entries(obj).some(([key, value]) => {
      if (typeof obj[key as U] === 'string') {
        return regex.test(value?.toString() ?? '');
      } else {
        return objHasTextMatch(obj[key as U], text);
      }
    });
  }

  return false;
};

// https://stackoverflow.com/questions/3561493/is-there-a-regexp-escape-function-in-javascript/3561711#3561711
export const escapeRegex = (text: string) => {
  return text.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
};
