import classnames from 'classnames';
import React, { useContext } from 'react';
import { InputGroup } from 'react-bootstrap';

import './search-box-input-group.scss';

import { SearchBoxContext } from '../../search-box-context-provider/search-box-context';
import SearchBoxInputGroupAppend, {
  SearchBoxInputGroupAppendProps,
} from '../search-box-input-group-append/search-box-input-group-append';
import SearchBoxInputGroupPrepend, {
  SearchBoxInputGroupPrependProps,
} from './search-box-input-group-prepend/search-box-input-group-prepend';

/* eslint-disable-next-line */
export interface SearchBoxInputGroupProps {
  isHeaderLandingPage?: boolean;
}

export interface ISearchBoxInputGroupComposition {
  Append: React.FC<SearchBoxInputGroupAppendProps>;
  Prepend: React.FC<SearchBoxInputGroupPrependProps>;
}

export const SearchBoxInputGroup: React.FC<SearchBoxInputGroupProps> & ISearchBoxInputGroupComposition = ({
  children,
  isHeaderLandingPage,
}) => {
  const { isSearchExpanded, searchSize } = useContext(SearchBoxContext);

  return (
    <InputGroup
      size={searchSize}
      className={classnames('search-input-group search-result-page-input w-90', {
        'd-none': !isSearchExpanded,
        'header-search': isHeaderLandingPage,
      })}
      id="search-input-group"
    >
      {children}
    </InputGroup>
  );
};

SearchBoxInputGroup.Append = SearchBoxInputGroupAppend;
SearchBoxInputGroup.Prepend = SearchBoxInputGroupPrepend;

export default SearchBoxInputGroup;
