import React from 'react';

import './gen-ai-multi-turn.scss';

const GenAIBetaNote = () => {
  return (
    <div className="fs-14 mb-2 ml-4 pl-2 pr-3 beta-summary-text">
      {/* <span className="text-rainbow-animation">BETA </span> */}
      <span className="animated-note fs-16">Note: </span>
      <span className="font-italic font-weight-bold">
        Answers were created using Generative AI and may not always be 100% accurate. Verify information in the source
        before making any GxP-related decisions or performing GxP processes.
      </span>
      <span className="font-italic font-weight-bold">
        <span> Please refer to Amgen </span>
        <span>
          <a
            href="https://amgencdocs.veevavault.com/ui/#doc_info/2499463"
            target="_blank"
            rel="noreferrer"
            className="link-horizon-day-guide"
          >
            AI Tools SOP
          </a>
        </span>{' '}
        for more details.
      </span>
    </div>
  );
};

export default GenAIBetaNote;
