import { IAssetAttribute } from '@amgen/core';
import { FacetsPanelContext, useOutsideClick } from '@amgen/shared';
import { subDays } from 'date-fns';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Dropdown, Row } from 'react-bootstrap';
import { DateRange } from 'react-date-range';

import './advance-search-date-range.scss';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

export interface AdvanceSearchDateRangeProps {
  category: IAssetAttribute;
  isOpen: boolean;
  onCloseCalender: () => void;
  onFilterToggle?: (category: IAssetAttribute, value: string) => void;
  onClear?: (category: IAssetAttribute) => void;
  categoryName?: string | undefined;
  excludeCount?: boolean;
  // maxDate?: Date;
  // className?: string;
}

export const AdvanceSearchDateRange: React.FC<AdvanceSearchDateRangeProps> = props => {
  const { selectedFilters, setSelectedFilters } = useContext(FacetsPanelContext);

  // TODO: Add type
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: subDays(new Date(), 7),
      key: 'selection',
    },
  ]);

  const [isActive, setIsActive] = useState(false);
  const dateRangeCalender = useRef<any>(null);
  useOutsideClick(dateRangeCalender, () => props.onCloseCalender());

  useEffect(() => {
    selectedFilters?.filters?.doc_last_modified?.values()?.next()?.value ? setIsActive(true) : setIsActive(false);
  }, [selectedFilters]);

  // Note: The commas are intentional, to destructure the 2nd elemenet of the array
  const [, setStart] = useState('');
  const [, setEnd] = useState('');

  // TODO: Add Type
  const handleChange = (item: any) => {
    setRange([item.selection]);
    setStart(item.selection.startDate);
    setEnd(item.selection.endDate);
    handleRangeSelect(item);
  };

  // TODO: Add type
  const handleRangeSelect = (item: any) => {
    handleDateRangeSelected(item['selection'].startDate.toISOString(), item['selection'].endDate.toISOString());
  };

  const handleselectedDateToggle = (datetoggle: string) => {
    const selectedFilterString: string =
      selectedFilters?.filters?.doc_last_modified?.values()?.next()?.value?.toString() ?? '';
    if (datetoggle && datetoggle === selectedFilterString) {
      return true;
    } else {
      return false;
    }
  };
  const handleDateRangeSelected = (startDate: string, endDate: string) => {
    const dateSelected = startDate.substring(0, 11) + 'T00:00:00Z ~ ' + endDate.substring(0, 11) + 'T23:59:59Z';
    if (handleselectedDateToggle(dateSelected)) {
      setSelectedFilters(selectedFilters.removeAll('doc_last_modified'));
      setIsActive(false);
    } else {
      setSelectedFilters(selectedFilters.removeAll('doc_last_modified'));
      setIsActive(true);
      setSelectedFilters(
        selectedFilters.add(
          [startDate.substring(0, 11) + 'T00:00:00Z', endDate.substring(0, 11) + 'T23:59:59Z'],
          props.category
        )
      );
    }
  };

  return (
    <Dropdown.Menu
      id="dateRangeCalender"
      className=".dateRangeMenu"
      show={props.isOpen}
      ref={dateRangeCalender}
      style={{ border: 'none', width: '95%', paddingLeft: '5%' }}
    >
      <Row className="w-100">
        <DateRange
          color={isActive ? '#0063c3' : 'rgb(0, 0, 0, 0.3)'}
          maxDate={new Date()}
          showDateDisplay={false}
          onChange={(item: any) => handleChange(item)}
          ranges={range}
        ></DateRange>
      </Row>
    </Dropdown.Menu>
  );
};

export default AdvanceSearchDateRange;
