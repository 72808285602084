import { DataSource, IAssetAttribute, IDataAsset } from '@amgen/core';
import { AppContext } from '@amgen/shared';
import React, { useContext } from 'react';

import './asset-attribute.scss';

import FilterAttributeContainer from '../filter-attribute-container/filter-attribute-container';
import MultipleAttributeContainer from '../multiple-attribute-container/multiple-attribute-container';
import TextAttribute from '../text-attribute/text-attribute';

export interface AssetAttributeProps {
  assetId: string;
  attribute: IAssetAttribute;
  value: IDataAsset[IAssetAttribute];
  onApplyFilter: (value: string) => void;
  isFilter: boolean;
  contentSource?: DataSource;
}

export const AssetAttribute: React.FC<AssetAttributeProps> = props => {
  const { attributeConfig } = useContext(AppContext);
  const Component = attributeConfig?.[props.attribute] ?? TextAttribute;

  return (
    <MultipleAttributeContainer
      value={props.value}
      byPassArrayCheck={['tags', 'technologies', 'doc_nav_prefix'].includes(props.attribute)}
    >
      {value => (
        <FilterAttributeContainer isFilter={props.isFilter} value={value} onApplyFilter={props.onApplyFilter}>
          <Component
            value={value}
            onApplyFilter={props.onApplyFilter}
            assetId={props.assetId}
            contentSource={props.contentSource}
          />
        </FilterAttributeContainer>
      )}
    </MultipleAttributeContainer>
  );
};

export default AssetAttribute;
