import * as Types from '@amgen/core';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type IDataCrawlRequestFragment = Pick<
  Types.IDataCrawlRequest,
  | 'id'
  | 'applicationName'
  | 'contentSource'
  | 'sourcePath'
  | 'owner'
  | 'siteDescription'
  | 'requestDescription'
  | 'consent'
  | 's3Location'
  | 'crawlStatus'
  | 'requestStatus'
  | 'dynamodbInsertFlag'
  | 'type'
  | 'requesterId'
  | 'requesterName'
  | 'createdOn'
  | 'reviewedOn'
  | 'reviewerId'
  | 'reviewerRemarks'
  | 'reviewerName'
  | 'consents'
  | 'displayName'
>;

export type IGetCrawlRequestsQueryVariables = Types.Exact<{
  appName?: Types.Maybe<Types.ApplicationName>;
  query?: Types.Maybe<Types.Scalars['String']>;
  requestStatus?: Types.Maybe<Types.RequestStatusType>;
  type: Types.PageType;
  filters?: Types.Maybe<Array<Types.Maybe<Types.Scalars['String']>> | Types.Maybe<Types.Scalars['String']>>;
  start?: Types.Maybe<Types.Scalars['Int']>;
  page?: Types.Maybe<Types.Scalars['Int']>;
  count?: Types.Maybe<Types.Scalars['Int']>;
  sortOrder?: Types.Maybe<Array<Types.Maybe<Types.ISortOrder>> | Types.Maybe<Types.ISortOrder>>;
  solrHandler?: Types.Maybe<Types.Scalars['String']>;
}>;

export type IGetCrawlRequestsQuery = {
  getContentOnboarding?: Types.Maybe<
    Pick<Types.IIgdRequestCollection, 'resultCount' | 'QTime'> & {
      requests?: Types.Maybe<Array<Types.Maybe<IDataCrawlRequestFragment>>>;
    }
  >;
};

export type IUpdateCrawlRequestMutationVariables = Types.Exact<{
  appName?: Types.Maybe<Types.ApplicationName>;
  requestId: Types.Scalars['String'];
  requestStatus: Types.RequestStatusType;
  reviewerRemarks?: Types.Maybe<Types.Scalars['String']>;
  reviewerName?: Types.Maybe<Types.Scalars['String']>;
}>;

export type IUpdateCrawlRequestMutation = {
  updateContentOnboarding?: Types.Maybe<Pick<Types.IGenericResponse, 'status' | 'message' | 'error'>>;
};

export const DataCrawlRequestFragmentDoc = gql`
  fragment DataCrawlRequest on DataCrawlRequest {
    id
    applicationName
    contentSource
    sourcePath
    owner
    siteDescription
    requestDescription
    consent
    s3Location
    crawlStatus
    requestStatus
    dynamodbInsertFlag
    type
    requesterId
    requesterName
    createdOn
    reviewedOn
    reviewerId
    reviewerRemarks
    reviewerName
    consents
    displayName
  }
`;
export const GetCrawlRequestsDocument = gql`
  query GetCrawlRequests(
    $appName: ApplicationName
    $query: String
    $requestStatus: RequestStatusType
    $type: PageType!
    $filters: [String]
    $start: Int = 0
    $page: Int
    $count: Int
    $sortOrder: [SortOrder]
    $solrHandler: String = "/search-select"
  ) {
    getContentOnboarding(
      applicationName: $appName
      query: $query
      requestStatus: $requestStatus
      type: $type
      filters: $filters
      start: $start
      page: $page
      count: $count
      sortOrder: $sortOrder
      solrHandler: $solrHandler
    ) {
      resultCount
      QTime
      requests {
        ...DataCrawlRequest
      }
    }
  }
  ${DataCrawlRequestFragmentDoc}
`;
export function useGetCrawlRequestsQuery(
  baseOptions: Apollo.QueryHookOptions<IGetCrawlRequestsQuery, IGetCrawlRequestsQueryVariables>
) {
  return Apollo.useQuery<IGetCrawlRequestsQuery, IGetCrawlRequestsQueryVariables>(
    GetCrawlRequestsDocument,
    baseOptions
  );
}
export function useGetCrawlRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetCrawlRequestsQuery, IGetCrawlRequestsQueryVariables>
) {
  return Apollo.useLazyQuery<IGetCrawlRequestsQuery, IGetCrawlRequestsQueryVariables>(
    GetCrawlRequestsDocument,
    baseOptions
  );
}
export type GetCrawlRequestsQueryHookResult = ReturnType<typeof useGetCrawlRequestsQuery>;
export type GetCrawlRequestsLazyQueryHookResult = ReturnType<typeof useGetCrawlRequestsLazyQuery>;
export type GetCrawlRequestsQueryResult = Apollo.QueryResult<IGetCrawlRequestsQuery, IGetCrawlRequestsQueryVariables>;
export const UpdateCrawlRequestDocument = gql`
  mutation UpdateCrawlRequest(
    $appName: ApplicationName
    $requestId: String!
    $requestStatus: RequestStatusType!
    $reviewerRemarks: String
    $reviewerName: String
  ) {
    updateContentOnboarding(
      applicationName: $appName
      requestId: $requestId
      requestStatus: $requestStatus
      reviewerRemarks: $reviewerRemarks
      reviewerName: $reviewerName
    ) {
      status
      message
      error
    }
  }
`;
export type IUpdateCrawlRequestMutationFn = Apollo.MutationFunction<
  IUpdateCrawlRequestMutation,
  IUpdateCrawlRequestMutationVariables
>;
export function useUpdateCrawlRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<IUpdateCrawlRequestMutation, IUpdateCrawlRequestMutationVariables>
) {
  return Apollo.useMutation<IUpdateCrawlRequestMutation, IUpdateCrawlRequestMutationVariables>(
    UpdateCrawlRequestDocument,
    baseOptions
  );
}
export type UpdateCrawlRequestMutationHookResult = ReturnType<typeof useUpdateCrawlRequestMutation>;
export type UpdateCrawlRequestMutationResult = Apollo.MutationResult<IUpdateCrawlRequestMutation>;
export type UpdateCrawlRequestMutationOptions = Apollo.BaseMutationOptions<
  IUpdateCrawlRequestMutation,
  IUpdateCrawlRequestMutationVariables
>;
