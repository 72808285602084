import { IFacetValue } from '@amgen/core';
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Dropdown, FormCheck } from 'react-bootstrap';

import './facet-value.module.scss';

export interface NodeProps {
  facet: IFacetValue;
  isSelected: boolean;
  className?: string;
  eventKey?: string;
  displayValue?: string;
}

const Node = (props: NodeProps) => {
  // This fixes the bug if a child is selected followed by the parent, the parent is not marked as checked
  // in the ui even tough the state is updated correctly.
  const [checked, setChecked] = useState(props.isSelected);
  useEffect(() => {
    setTimeout(() => setChecked(props.isSelected), 0);
  }, [props.isSelected]);
  return (
    <Dropdown.Item
      className={classNames('d-flex justify-content-between align-items-center', props.className)}
      eventKey={props.eventKey ?? props.facet.value}
      title={props.displayValue ?? props.facet.value}
    >
      <FormCheck className="w-75">
        <FormCheck.Input type="checkbox" checked={checked} readOnly />
        <FormCheck.Label className="d-block mr-3 w-100 text-overflow-ellipsis">
          {props.displayValue ?? props.facet.value}
        </FormCheck.Label>
      </FormCheck>
    </Dropdown.Item>
  );
};

export interface FacetValueProps extends NodeProps {
  treeNode?: boolean;
  isExpanded?: boolean;
  onExpandToggle?: (expanded: boolean) => void;
}

// TODO: This should be split into two components. `TreeFacetValue` and `FacetValue`
export const FacetValue: React.FC<FacetValueProps> = ({ treeNode, isExpanded, onExpandToggle, ...nodeProps }) => {
  // TODO: Explore if this can be used directly from props.
  const [isOpen, setIsOpen] = useState(isExpanded);
  useEffect(() => setIsOpen(isExpanded), [isExpanded]);

  const handleToggle = useCallback(() => {
    setIsOpen(isOpen => {
      onExpandToggle?.(!isOpen);
      return !isOpen;
    });
  }, [onExpandToggle]);

  return (
    <div className="d-flex justify-content-between align-items-center w-100 pl-2">
      {/* Using visibility instead of conditional rendering to fix alignment of all options */}
      <FontAwesomeIcon
        icon={isOpen ? faCaretDown : faCaretRight}
        className="filter-caret text-grey"
        onClick={handleToggle}
        style={{ visibility: treeNode ? 'visible' : 'hidden' }}
      />

      <Node {...nodeProps} className={classNames(nodeProps.className, 'px-2')} />
    </div>
  );
};

export default FacetValue;
