import * as Types from '@amgen/core';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type IBookmarkFragment = Pick<Types.IBookmark, 'applicationName' | 'assetId' | 'id' | 'name' | 'timestamp'>;

export type IGetBookmarksQueryVariables = Types.Exact<{
  appName: Types.ApplicationName;
  start?: Types.Maybe<Types.Scalars['Int']>;
  page?: Types.Maybe<Types.Scalars['Int']>;
  count?: Types.Maybe<Types.Scalars['Int']>;
}>;

export type IGetBookmarksQuery = { getBookmarks?: Types.Maybe<Array<Types.Maybe<IBookmarkFragment>>> };

export type IBookmarkAssetMutationVariables = Types.Exact<{
  name?: Types.Maybe<Types.Scalars['String']>;
  assetId?: Types.Maybe<Types.Scalars['String']>;
  appName: Types.ApplicationName;
  searchTerm?: Types.Maybe<Types.Scalars['String']>;
}>;

export type IBookmarkAssetMutation = { bookmarkAsset?: Types.Maybe<IBookmarkFragment> };

export const BookmarkFragmentDoc = gql`
  fragment Bookmark on Bookmark {
    applicationName
    assetId
    id
    name
    timestamp
  }
`;
export const GetBookmarksDocument = gql`
  query GetBookmarks($appName: ApplicationName!, $start: Int = 0, $page: Int = 0, $count: Int = 10) {
    getBookmarks(applicationName: $appName, page: $page, start: $start, count: $count) {
      ...Bookmark
    }
  }
  ${BookmarkFragmentDoc}
`;
export function useGetBookmarksQuery(
  baseOptions: Apollo.QueryHookOptions<IGetBookmarksQuery, IGetBookmarksQueryVariables>
) {
  return Apollo.useQuery<IGetBookmarksQuery, IGetBookmarksQueryVariables>(GetBookmarksDocument, baseOptions);
}
export function useGetBookmarksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetBookmarksQuery, IGetBookmarksQueryVariables>
) {
  return Apollo.useLazyQuery<IGetBookmarksQuery, IGetBookmarksQueryVariables>(GetBookmarksDocument, baseOptions);
}
export type GetBookmarksQueryHookResult = ReturnType<typeof useGetBookmarksQuery>;
export type GetBookmarksLazyQueryHookResult = ReturnType<typeof useGetBookmarksLazyQuery>;
export type GetBookmarksQueryResult = Apollo.QueryResult<IGetBookmarksQuery, IGetBookmarksQueryVariables>;
export const BookmarkAssetDocument = gql`
  mutation BookmarkAsset($name: String, $assetId: String, $appName: ApplicationName!, $searchTerm: String) {
    bookmarkAsset(name: $name, assetId: $assetId, applicationName: $appName, searchTerm: $searchTerm) {
      ...Bookmark
    }
  }
  ${BookmarkFragmentDoc}
`;
export type IBookmarkAssetMutationFn = Apollo.MutationFunction<IBookmarkAssetMutation, IBookmarkAssetMutationVariables>;
export function useBookmarkAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<IBookmarkAssetMutation, IBookmarkAssetMutationVariables>
) {
  return Apollo.useMutation<IBookmarkAssetMutation, IBookmarkAssetMutationVariables>(
    BookmarkAssetDocument,
    baseOptions
  );
}
export type BookmarkAssetMutationHookResult = ReturnType<typeof useBookmarkAssetMutation>;
export type BookmarkAssetMutationResult = Apollo.MutationResult<IBookmarkAssetMutation>;
export type BookmarkAssetMutationOptions = Apollo.BaseMutationOptions<
  IBookmarkAssetMutation,
  IBookmarkAssetMutationVariables
>;
