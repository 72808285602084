import React, { useContext } from 'react';
import { Col, Pagination } from 'react-bootstrap';

import './paginator.scss';

import FirstPage from './first-page/first-page';
import NextPage from './next-page/next-page';
import Pages from './pages/pages';
import PreviousPage from './previous-page/previous-page';
import PaginationProvider, { PaginationProviderProps } from './provider';

/* eslint-disable-next-line */
export interface PaginatorProps extends PaginationProviderProps {}

export const Paginator: React.FC<PaginatorProps> = props => {
  return (
    <PaginationProvider {...props}>
      <Col
        xs="12"
        className={
          props.isSearchResultPage
            ? 'd-flex justify-content-center customize-search-page'
            : 'd-flex justify-content-center'
        }
      >
        {!props.conversationalQnAEngaged || window.location.pathname.includes('/data-requests') ? (
          <Pagination>
            {/* <PreviousPages /> */}
            <PreviousPage />
            <FirstPage />
            <Pages />
            {/* <LastPage /> */}
            <NextPage results={props.results} />
            {/* <NextPages /> */}
          </Pagination>
        ) : (
          <></>
        )}
      </Col>
    </PaginationProvider>
  );
};

export default Paginator;
