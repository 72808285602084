const getQuarterNumber = date => {
  const month = date.getMonth() + 1;
  return Math.ceil(month / 3);
};

const getStartDateOfQuarter = (quarter, year) => {
  switch (quarter) {
    case 1:
      return { start: `1/1/${year}`, label: `${year} Q${quarter}` };
    case 2:
      return { start: `4/1/${year}`, label: `${year} Q${quarter}` };
    case 3:
      return { start: `7/1/${year}`, label: `${year} Q${quarter}` };
    case 4:
      return { start: `10/1/${year}`, label: `${year} Q${quarter}` };
    default:
      return { start: `1/1/${year}`, label: `${year} Q${quarter}` };
  }
};

const getQuarterArray = (date1, date2) => {
  const date1Quarter = getQuarterNumber(date1);
  const date2Quarter = getQuarterNumber(date2);

  const date1Year = date1.getFullYear();
  const date2Year = date2.getFullYear();
  // const quarterArray = [];
  const quarterArray: any[] = [];

  for (let i = date1Year; i <= date2Year; i++) {
    if (i === date1Year) {
      for (let j = date1Quarter; j <= 4; j++) {
        quarterArray.push(getStartDateOfQuarter(j, date1Year));
      }
    } else if (i === date2Year) {
      for (let j = 1; j <= date2Quarter; j++) {
        quarterArray.push(getStartDateOfQuarter(j, date2Year));
      }
    } else {
      for (let j = 1; j <= 4; j++) {
        quarterArray.push(getStartDateOfQuarter(j, i));
      }
    }
  }
  return quarterArray.sort((a, b) => a.label.localeCompare(b.label));
};

const today = new Date();
export const QUARTERS = getQuarterArray(
  new Date(2021, 11, 1),
  new Date(today.getFullYear() + 1, today.getMonth() + 3, today.getDay())
);
// export const QUARTERS = getQuarterArray(new Date(2021, 11, 1), new Date(JSON.stringify(endDateRangeRoadmap)));
