import { useCallback, useEffect } from 'react';

export function useAltHotKey(callback: () => void, hotKey = 's', enabled = true) {
  const hotKeyHandler = useCallback(
    (e: any) => {
      if (e.altKey && e.key === hotKey) {
        callback();
      }
    },
    [callback, hotKey]
  );

  useEffect(() => {
    if (enabled) {
      document.addEventListener('keydown', hotKeyHandler);
      return () => {
        document.removeEventListener('keydown', hotKeyHandler);
      };
    }
  }, [enabled, hotKeyHandler]);
}
