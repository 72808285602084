import { useEnvironment } from '@amgen/core';
import { IResultViewOption } from '@amgen/shared';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import React from 'react';
import { Button } from 'react-bootstrap';

import './result-view-option.scss';

export interface ResultViewOptionProps {
  option: IResultViewOption;
  isSelected: boolean;
  onSelect: () => void;
}

export const ResultViewOption: React.FC<ResultViewOptionProps> = props => {
  const { appName } = useEnvironment();
  return (
    <Button
      variant="light"
      className={classnames('cursor-pointer view-btn', { 'b-active text-primary': props.isSelected })}
      title={`Change to ${props.option.view} View`}
      onClick={props.onSelect}
      // disabled={props.option.view === ResultViewType.Table && appName === ApplicationName.EEA}
    >
      <FontAwesomeIcon icon={props.option.icon} />
      <span className="pl-2 d-none d-lg-inline">{props.option.view}</span>
    </Button>
  );
};

export default ResultViewOption;
