import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import './confirm-modal.module.scss';

import { NavLink, Redirect, Link, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

/* eslint-disable-next-line */
export interface ConfirmModalProps {
  message: string;
  show: boolean;
  setShow: (show: boolean) => void;
  onConfirm: () => void;
  onCancel?: () => void;
  confirmButtonText?: string;
  cancelButtonText?: string;
  enabledNavigation?: boolean;
}

export function ConfirmModal(props: ConfirmModalProps) {
  const history = useHistory();
  const { path } = useRouteMatch();
  const url = path.split('/');

  const handleClick = () => {
    props.onConfirm();
    props.setShow(false);
    if (props.enabledNavigation) {
      history.push(`${url[0]}/data-requests/existing-sites`);
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={() => props.setShow(false)}
      backdrop="static"
      keyboard={false}
      className="modal-popup"
    >
      <Modal.Header closeButton>
        <Modal.Title as="h2">Please Confirm !</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ minHeight: 20 }}>{props.message}</Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={() => {
            handleClick();
          }}
        >
          {props.confirmButtonText ? props.confirmButtonText : 'Yes, close'}
        </Button>
        <Button variant="secondary" onClick={() => props.setShow(false)}>
          {props.cancelButtonText ? props.cancelButtonText : 'No'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmModal;
