import { useGetSavedDataSourcesQuery, useGetSavedPreferenceQuery } from '@amgen/api';
import { DataSource, IAssetAttribute, ISelectedDefaultSources, useEnvironment } from '@amgen/core';
import React, { useContext, useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';

import './preference-context-provider.scss';

import { AppContext } from '../../config';
import { MatomoContext, PAGE_LOAD_FEATURE_ACTIONS, SEARCH_APP_FEATURE_LIST } from '../../matomo';
import { ResultViewType } from '../../models';
import { IPreferenceState, PreferenceContext } from './preference-context';

export interface PreferenceContextProviderProps {
  defaultSavedFacet: IAssetAttribute[];
  defaultView: ResultViewType;
  defaultColumns: IAssetAttribute[];
  defaultDataSources: DataSource[];
  skipPreferenceAPI?: boolean;
  defaultSavedExportFields: IAssetAttribute[];
}

export const PreferenceContextProvider: React.FC<PreferenceContextProviderProps> = ({ children, ...props }) => {
  const { appName } = useEnvironment();
  const [isFavSourcesSearch, setIsFavSourcesSearch] = useState(false);
  const [createdCustomSource, setCreatedCustomSource] = useState('');
  const [createdCustomDataSourceName, setCreatedCustomDataSourceName] = useState([]);
  const { trackApplicationFeature } = useContext(MatomoContext);
  const { defaultFacetCategories } = useContext(AppContext);

  const startTime = new Date().getTime();
  const { loading, error, data, refetch: refetchPreferences } = useGetSavedPreferenceQuery({
    variables: { appName },
    skip: props.skipPreferenceAPI,
    onCompleted: () => {
      trackApplicationFeature(
        SEARCH_APP_FEATURE_LIST.PAGE_LOAD_TRACKING,
        PAGE_LOAD_FEATURE_ACTIONS.USER_PREFERENCE,
        (new Date().getTime() - startTime) / 1000
      );
    },
  });
  const { loading: dataSourceLoading, data: sData, refetch: refetchDataSources } = useGetSavedDataSourcesQuery({
    variables: { appName },
    skip: props.skipPreferenceAPI,
  });

  const dataFacetConfig = () => {
    const defaultFacetCategoriesData =
      defaultFacetCategories && defaultFacetCategories.length > 0 ? (defaultFacetCategories as IAssetAttribute[]) : [];
    const facetCategoriesAPIData =
      (data?.facetCategories?.[0]?.values?.length ?? 0) > 0
        ? (data?.facetCategories?.[0]?.values as IAssetAttribute[])
        : [];
    return [...defaultFacetCategoriesData, ...facetCategoriesAPIData];
  };

  const dataFacetConfigList = [...new Set(dataFacetConfig() as IAssetAttribute[])];
  const providedValue = useMemo<IPreferenceState>(
    () => ({
      defaultFacets: props.defaultSavedFacet,
      selectedFacetConfig: dataFacetConfigList,
      selectedResultView:
        (data?.view?.values?.length ?? 0) > 0 ? (data?.view?.values?.[0] as ResultViewType) : props.defaultView,
      selectedDefaultColumns:
        (data?.tableViewColumns?.values?.length ?? 0) > 0
          ? ((data?.tableViewColumns?.values as unknown) as IAssetAttribute[])
          : props.defaultColumns,
      selectedDefaultDataSources: (sData?.getSavedPreference as ISelectedDefaultSources[]) || [],
      selectedExportFacets:
        (data?.exportCategories?.[0]?.values?.length ?? 0) > 0
          ? (data?.exportCategories?.[0]?.values as IAssetAttribute[])
          : props?.defaultSavedExportFields,
      refetchDataSources,
      refetchPreferences,
      dataSourceLoading,
      loading,
      error,
      isFavSourcesSearch,
      setIsFavSourcesSearch,
      createdCustomSource,
      setCreatedCustomSource,
      createdCustomDataSourceName,
      setCreatedCustomDataSourceName,
    }),
    [
      props,
      data,
      loading,
      error,
      sData,
      refetchDataSources,
      dataSourceLoading,
      refetchPreferences,
      isFavSourcesSearch,
      setIsFavSourcesSearch,
      createdCustomSource,
      setCreatedCustomSource,
      createdCustomDataSourceName,
      setCreatedCustomDataSourceName,
    ]
  );

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center row position-absolute loader-position">
        <div className="col-12 text-center">
          <Spinner animation="border" className="p-4 text-primary" /> <br></br>
        </div>
        <div className="col-12 text-center text-primary mt-1">Fetching user preferences...</div>
      </div>
    );
  }

  return <PreferenceContext.Provider value={providedValue}>{children}</PreferenceContext.Provider>;
};

export default PreferenceContextProvider;
