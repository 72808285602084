export const isAmgNum = (value?: string): boolean => {
  return /amg\d\d\d/i.test(value?.toUpperCase() ?? '');
};

export const amgNumFormatter = (amgNum?: string): string => {
  if (amgNum) {
    return `${amgNum.slice(0, 3).toUpperCase()}-${amgNum.slice(3, 6)}`;
  } else {
    console.log('Error displaying AMG number');
    return '';
  }
};
