import { useGetBookmarksQuery } from '@amgen/api';
import { IBookmark, useEnvironment } from '@amgen/core';
import { PAGES, usePageTrackingWithTitle } from '@amgen/shared-kmi';
import React, { useContext, useState } from 'react';

import './bookmark-list-container.scss';

import { BookmarkContext } from '../../context';
import BookmarkList from '../bookmark-list/bookmark-list';

/* eslint-disable-next-line */
export interface BookmarkListContainerProps {}

export const BookmarkListContainer = (props: BookmarkListContainerProps) => {
  const { addToDeleted } = useContext(BookmarkContext);
  const { appName } = useEnvironment();

  const [bookmarks, setBookmarks] = useState<IBookmark[]>([]);
  const [page, setPage] = useState(0);
  const [hasNext, setHasNextPage] = useState(true);

  const resetBookmarks = async (bookmark: IBookmark): Promise<boolean> => {
    setPage(0);
    setHasNextPage(true);
    addToDeleted(bookmark.assetId!);
    await refetchBookmarks();
    return true;
  };

  const { loading, error, refetch: refetchBookmarks } = useGetBookmarksQuery({
    onCompleted: bookmarkData => {
      if (bookmarkData?.getBookmarks) {
        // onfirst page load should refresh the bookmarKs
        page ? setBookmarks([...bookmarks, ...bookmarkData?.getBookmarks]) : setBookmarks(bookmarkData?.getBookmarks);
        if (bookmarkData?.getBookmarks.length === 0) {
          setHasNextPage(false);
        }
      }
    },
    variables: { appName, page, count: 10, start: 0 },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const handleScroll = ({ currentTarget }: React.UIEvent<HTMLDivElement>) => {
    if (
      currentTarget &&
      currentTarget.scrollTop + currentTarget.clientHeight >= currentTarget.scrollHeight &&
      !loading &&
      hasNext
    ) {
      setPage(page + 1);
    }
  };

  usePageTrackingWithTitle(PAGES.BOOKMARKS);

  return (
    <BookmarkList
      onScroll={handleScroll}
      onRetry={() => refetchBookmarks()}
      isLoading={loading}
      bookmarks={bookmarks}
      error={error}
      onRefresh={resetBookmarks}
    />
  );
};

export default BookmarkListContainer;
