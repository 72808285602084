import { IGetRoadmaps } from '@amgen/api';
import { createContext } from 'react';

export interface IRoadmapState {
  loading: boolean;
  likeCountLoading: boolean;
  commentCountLoading: boolean;
  featureIdCommentCountMap: Map<string, number>;
  featureIdLikeCountMap: Map<string, number>;
  featureIdUserLikesMap: Map<string, number>;
  refetchUserMetrics: () => void;
  refetchUserComments: () => void;
  roadmapThemes: IGetRoadmaps[];
  setBlockScroll: (value: boolean) => void;
  width?: number;
}

export const RoadmapContext = createContext<IRoadmapState>(null!);
