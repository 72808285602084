import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Carousel as BsCarousel } from 'react-bootstrap';

import './amgen-carousel.scss';

/* eslint-disable-next-line */
export interface AmgenCarouselProps {
  setClick: any;
}

export const AmgenCarousel: React.FC<AmgenCarouselProps> = ({ children, setClick }) => {
  const slides = React.Children.count(children);
  const hasMultipleSlides = React.Children.count(children) > 1;
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <BsCarousel
      className="amgen-carousel bg-white"
      // nextIcon={
      //   hasMultipleSlides && index !== slides - 1 ? (
      //     <FontAwesomeIcon
      //       onClick={() => setClick(index)}
      //       icon={faChevronRight}
      //       title="Next"
      //       className="text-primary"
      //     />
      //   ) : null
      // }
      // prevIcon={
      //   hasMultipleSlides && index !== 0 ? (
      //     <FontAwesomeIcon
      //       onClick={() => setClick(index)}
      //       icon={faChevronLeft}
      //       title="Previous"
      //       className="text-primary"
      //     />
      //   ) : null
      // }
      fade
      interval={null}
      indicators={hasMultipleSlides ? true : false}
      onSelect={handleSelect}
    >
      {React.Children.map(children, (slide, index) => (
        <BsCarousel.Item className="amgen-carousel-item h-100" key={index}>
          {slide}
        </BsCarousel.Item>
      ))}
    </BsCarousel>
  );
};

export default AmgenCarousel;
