import React from 'react';

import './avatar.scss';

import { generateColor } from '../../utils/generate-color';

export interface AvatarProps {
  userName?: string;
  className: string;
  displayString?: string;
}
export const Avatar: React.FC<AvatarProps> = ({ userName, className, children, displayString }) => {
  return (
    <div
      style={{
        backgroundColor: generateColor(userName ?? displayString ?? Math.random().toString(36).substring(2, 15)),
      }}
      className={`avatar ${className}`}
    >
      {displayString ?? userName?.slice(0, 2).toLocaleUpperCase() ?? children}
    </div>
  );
};

export default Avatar;
