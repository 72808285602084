import * as Types from '@amgen/core';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ISpellcheckQueryVariables = Types.Exact<{
  appName?: Types.Maybe<Types.ApplicationName>;
  query: Types.Scalars['String'];
  solrHandler?: Types.Maybe<Types.Scalars['String']>;
  sourceType?: Types.Maybe<Types.Scalars['String']>;
  userType?: Types.Maybe<Types.Scalars['String']>;
}>;

export type ISpellcheckQuery = Pick<Types.IQuery, 'spellcheck'>;

export const SpellcheckDocument = gql`
  query spellcheck(
    $appName: ApplicationName
    $query: String!
    $solrHandler: String = "/search-spell"
    $sourceType: String
    $userType: String
  ) {
    spellcheck(
      applicationName: $appName
      query: $query
      solrHandler: $solrHandler
      sourceType: $sourceType
      userType: $userType
    )
  }
`;
export function useSpellcheckQuery(baseOptions: Apollo.QueryHookOptions<ISpellcheckQuery, ISpellcheckQueryVariables>) {
  return Apollo.useQuery<ISpellcheckQuery, ISpellcheckQueryVariables>(SpellcheckDocument, baseOptions);
}
export function useSpellcheckLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ISpellcheckQuery, ISpellcheckQueryVariables>
) {
  return Apollo.useLazyQuery<ISpellcheckQuery, ISpellcheckQueryVariables>(SpellcheckDocument, baseOptions);
}
export type SpellcheckQueryHookResult = ReturnType<typeof useSpellcheckQuery>;
export type SpellcheckLazyQueryHookResult = ReturnType<typeof useSpellcheckLazyQuery>;
export type SpellcheckQueryResult = Apollo.QueryResult<ISpellcheckQuery, ISpellcheckQueryVariables>;
