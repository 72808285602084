import { ClinicalContextProvider, PAGES, usePageTrackingWithTitle } from '@amgen/shared';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

import './clinical-details.scss';

import ClinicalDetailsBackBtn from '../clinical-details-back-btn/clinical-details-back-btn';
import { ClinicalDetailsInfo } from '../clinical-details-info/clinical-details-info';

/* eslint-disable-next-line */
export interface ClinicalDetailsProps {}

export function ClinicalDetails(props: ClinicalDetailsProps) {
  usePageTrackingWithTitle(PAGES.CLINICAL_DETAIL);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = 'hidden';
  }, []);

  return (
    <ClinicalContextProvider>
      <Col xs="12" className="back-to-search-container">
        <ClinicalDetailsBackBtn />
      </Col>
      <Col xs="12" className="d-flex clinical-details-container px-4">
        <Row className="w-100 rounded details-row-container">
          <ClinicalDetailsInfo />
          {/* <ClinicalDetailsKnowledgeGraph /> */}
        </Row>
      </Col>
    </ClinicalContextProvider>
  );
}

export default ClinicalDetails;
