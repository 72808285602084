import { ConsentParagraph } from '@amgen/shared';
import { FieldArray, useField } from 'formik';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';

import './consent-input.scss';

import { CONSENT_TEXTS } from '../../models';

/* eslint-disable-next-line */
export interface ConsentInputProps {}

export const ConsentInput = (props: ConsentInputProps) => {
  const [field, meta] = useField<boolean[]>('consent');

  return (
    <Row>
      <Col xs="12">
        <ConsentParagraph showAvePoint />
      </Col>
      <FieldArray name={field.name}>
        {({ replace }) => (
          <Form.Group as={Col}>
            {CONSENT_TEXTS.map((consentText, index) => (
              <Form.Check
                key={index}
                id={consentText}
                type="checkbox"
                className="text-justify font-weight-bold cursor-pointer"
                label={consentText}
                name={`consent.${index}`}
                checked={field.value[index]}
                onChange={() => replace(index, !field.value[index])}
                isInvalid={meta.touched && !!meta.error}
                // feedback={meta.error} comment this out if error feedback is  needed
              />
            ))}
          </Form.Group>
        )}
      </FieldArray>
    </Row>
  );
};

export default ConsentInput;
