import { DropdownContainer, FormLabelWithTooltip, InfoTooltip } from '@amgen/components';
import { ApplicationName, IAsset, IAssetAttribute, useEnvironment } from '@amgen/core';
import { AppliedFiltersBar } from '@amgen/feature-facets';
import { AppContext, FiltersObject, PreferenceContext, useToast } from '@amgen/shared';
import { alertMechanism } from '@amgen/utils';
import { faServer } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState } from 'react';
import { Alert, Badge, Button, Col, Dropdown, Form, FormCheck, Modal, Spinner } from 'react-bootstrap';

import './export-popup.scss';

import {
  KmiAppliedFiltersBar,
} from '../../../../../../apps/kmi-search/src/app/components/kmi-applied-filters-bar/kmi-applied-filters-bar';
import { EXPORT_LIMIT } from '../../config';

export interface ExportPopupProps {
  isOpen: boolean;
  appliedFilters: FiltersObject;
  onCancel: () => void;
  onSuccess: () => void;
  exportFields: Set<IAssetAttribute>;
  attributeList: IAssetAttribute[];
  resultCount: number;
  onExportFieldChange: (exportFields: Set<IAssetAttribute>) => void;
  onExport: (start: number, end: number, preferredFilters: any) => Promise<IAsset[] | undefined>;
  hideStart?: boolean;
}

export const ExportPopup: React.FC<ExportPopupProps> = props => {
  const toastID = 'export-popup-toast';
  const { appName } = useEnvironment();
  const { attributeDisplayNames } = useContext(AppContext);
  const { selectedExportFacets } = useContext(PreferenceContext);
  const defaultSelectedFilters = ['title', 'url'];
  const selectedPreferredFilters = [...selectedExportFacets];
  const [preferredFilters, setPreferredFilters] = useState(new Set(selectedPreferredFilters));
  const [start, setStart] = useState(1);
  const [isSelectAll, setSelectAll] = useState(false);
  const [end, setEnd] = useState(Math.min(Math.max(0, props.resultCount), EXPORT_LIMIT));
  const [loading, setLoading] = useState(false);
  const [validationMessage, setValidationMessage] = useState(props.resultCount > 0 ? '' : 'No results available');
  const [errorMessage, setErrorMessage] = useState('');
  const toast = useToast();
  const otherFields = props.attributeList.filter(el => !selectedPreferredFilters.includes(el));

  const toggleFilterCategory = (category: IAssetAttribute) => {
    // let copy = new Set(props.exportFields);
    let copy = new Set(preferredFilters);
    if (category.toString() === 'selectAll') {
      setSelectAll(!isSelectAll);
      if (!isSelectAll) {
        const allFields = props.attributeList.sort((a, b) =>
          attributeDisplayNames[a].localeCompare(attributeDisplayNames[b])
        );
        copy = new Set(allFields);
      } else {
        copy = new Set([]);
      }
    } else {
      if (copy.has(category)) {
        copy.delete(category);
      } else {
        copy.add(category);
      }
    }

    if (copy.size < 1) {
      setErrorMessage('Please select a field to be exported');
    } else {
      setErrorMessage('');
    }
    setPreferredFilters(copy);
    //props.onExportFieldChange(copy);
  };

  const handleExportClick = async () => {
    setLoading(true);
    try {
      setErrorMessage('Download is in progress now. Please feel free to close this window and continue searching.');
      const data = await props.onExport(start, end, preferredFilters);
      if (!data?.length) {
        alertMechanism('Something went wrong. Please try again later.', setErrorMessage, 3000);
      }
      toast.success({ message: 'File downloaded successfully', id: toastID, timeout: 5000 });

      setLoading(false);
      setErrorMessage('');
    } catch {
      setLoading(false);
      alertMechanism('Something went wrong. Please try again later.', setErrorMessage, 3000);
      toast.danger({ message: 'Unable to download data. Please try again later', id: toastID, timeout: 5000 });
    }
  };

  const handleEndExportCountChange = (value: number) => {
    setValidationMessage(getValidationMessageForRange(value, 'end'));
    setEnd(value);
  };

  const handleKeyDownOnInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // if (e.key === 'Enter' && !isExportRangeValid()) {
    //   handleExportClick();
    //   event.preventDefault();
    // }
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const handleExportClose = () => {
    // if (errorMessage) {
    //   toast.info({ message: 'Data export is in progress', id: toastID });
    // }
    props.onCancel();
  };

  const getValidationMessageForRange = (value: number, type: 'start' | 'end' | 'disabled') => {
    let msg = '';
    if (!(value > 0)) {
      msg = `Please check Export range selected`;
    } else if (value > props.resultCount) {
      msg = `Only  ${props.resultCount} available for export`;
    } else if (value - start + 1 > EXPORT_LIMIT) {
      msg = `max ${EXPORT_LIMIT}, Files that can be exported `;
    } else if ((type === 'end' && value < start) || (type === 'start' && value > end)) {
      msg = 'Start should be less than End';
    }
    return msg;
  };

  const isExportRangeValid = () => !!validationMessage || !!errorMessage;

  return (
    <Modal show={props.isOpen} onHide={handleExportClose} centered size="lg" animation>
      <Modal.Header closeButton className="border-0">
        <Modal.Title as="h1">Export Search Result</Modal.Title>
      </Modal.Header>

      <Modal.Body className="export-popup pt-0">
        <Alert variant="secondary" className="export-popup-info-message d-flex align-items-center py-0">
          <span className="mr-3" style={{ fontSize: 'x-large' }}>
            <FontAwesomeIcon icon={faServer} />
          </span>
          {!props.hideStart ? (
            `Total records for current search-term and filters are ${new Intl.NumberFormat('en-US').format(
              props.resultCount
            )}`
          ) : (
            <>
              Export Q&A's of all the {new Intl.NumberFormat('en-US').format(props.resultCount)} surveys on this page
              <InfoTooltip className="mx-2" style={{ fontSize: 'large' }}>
                Pages can be changed at the bottom of the result page !
              </InfoTooltip>
            </>
          )}
        </Alert>

        <Form className="container px-0">
          <Form.Row>
            <Form.Group as={Col} xs="12" lg="6">
              <FormLabelWithTooltip label="Fields" helpText="Fields in your export .csv" />
              {/* TODO: <AK> Extract this into it's own component. Generalize the FilterCategoryDropdown */}
              <DropdownContainer
                title={
                  <div className="d-flex justify-content-between align-items-center">
                    <span>Select Fields</span>
                    <Badge
                      className="d-inline-flex align-items-center border rounded-pill mx-1 px-2"
                      style={{ backgroundColor: '#e0e0e0', color: '#797979' }}
                    >
                      {preferredFilters.size}
                    </Badge>
                  </div>
                }
                onValueToggle={category => toggleFilterCategory(category as IAssetAttribute)}
              >
                <div className="thin-scrollbar" style={{ maxHeight: 250, overflowY: 'auto' }}>
                  <Dropdown.Item as="li" className="px-3" eventKey="selectAll">
                    <FormCheck>
                      <FormCheck.Input type="checkbox" checked={isSelectAll} />
                      <FormCheck.Label>Select All</FormCheck.Label>
                    </FormCheck>
                  </Dropdown.Item>
                  {/* {defaultSelectedFilters.map(key => {
                    return (
                      <Dropdown.Item
                        as="li"
                        className="px-3"
                        eventKey={key}
                        key={key}
                        disabled={defaultSelectedFilters.includes(key)}
                      >
                        <FormCheck disabled={defaultSelectedFilters.includes(key)}>
                          <FormCheck.Input
                            type="checkbox"
                            checked={defaultSelectedFilters.includes(key)}
                            disabled={defaultSelectedFilters.includes(key)}
                          />
                          <FormCheck.Label>{attributeDisplayNames[key]}</FormCheck.Label>
                        </FormCheck>
                      </Dropdown.Item>
                    );
                  })} */}
                  {selectedExportFacets.map(key => {
                    return (
                      <Dropdown.Item as="li" className="px-3" eventKey={key} key={key}>
                        <FormCheck>
                          <FormCheck.Input type="checkbox" checked={preferredFilters.has(key)} />
                          <FormCheck.Label>{attributeDisplayNames[key]}</FormCheck.Label>
                        </FormCheck>
                      </Dropdown.Item>
                    );
                  })}
                  {otherFields
                    .sort((a, b) => attributeDisplayNames[a].localeCompare(attributeDisplayNames[b]))
                    .map(key => (
                      <Dropdown.Item as="li" className="px-3" eventKey={key} key={key}>
                        <FormCheck>
                          <FormCheck.Input type="checkbox" checked={preferredFilters.has(key)} />
                          <FormCheck.Label>{attributeDisplayNames[key]}</FormCheck.Label>
                        </FormCheck>
                      </Dropdown.Item>
                    ))}
                </div>
              </DropdownContainer>
            </Form.Group>

            <Form.Group as={Col} xs="12" lg="6" controlId="formGridResultCount">
              <FormLabelWithTooltip label="Number of Records" helpText="Number of records to be exported" />

              <Form.Control
                type="number"
                placeholder="End"
                min={start}
                max={props.resultCount}
                value={`${end}`}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleEndExportCountChange(+e.target.value)}
                onKeyDown={handleKeyDownOnInput}
              />
              <Form.Text className="text-muted">
                Max export-limit: <b>{EXPORT_LIMIT}</b> records
              </Form.Text>

              {validationMessage && <Form.Text className="text-danger f-90">{validationMessage}</Form.Text>}
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} xs="12">
              <Form.Label className="col-12 col-lg-3 font-weight-bold px-0">Applied Filters</Form.Label>
              {appName === ApplicationName.KMI ? (
                <KmiAppliedFiltersBar filters={props.appliedFilters} isClearable={false} message="No Applied Filters" />
              ) : (
                <AppliedFiltersBar
                  filters={props.appliedFilters}
                  isClearable={false}
                  isExportOpen
                  message="No Applied Filters"
                />
              )}
            </Form.Group>
            {errorMessage && (
              <Form.Group as={Col} xs="12">
                <Alert className="export-popup-error-message" variant="warning">
                  {errorMessage}
                </Alert>
              </Form.Group>
            )}
          </Form.Row>
        </Form>
      </Modal.Body>

      <Modal.Footer className="justify-content-center">
        <Button
          className="px-5"
          title={loading ? 'Exporting' : isExportRangeValid() ? 'Invalid Parameters' : 'Click to export'}
          onClick={handleExportClick}
          disabled={loading || isExportRangeValid() || preferredFilters.size <= 0}
        >
          {loading ? <Spinner animation="border" size="sm" /> : 'Export'}
        </Button>

        <Button variant="outline-primary" className="px-5" onClick={handleExportClose} disabled={loading}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ExportPopup;
