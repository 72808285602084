import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import React, { BaseSyntheticEvent, useContext, useState } from 'react';
import { Col, ListGroup, Row } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { NavLink, useLocation, useRouteMatch } from 'react-router-dom';

import './hamburger-menu-item.scss';

import { HamburgerMenuContext } from '../hamburger-menu-context';

export interface HamburgerMenuItemProps {
  to?: string;
  icon?: IconDefinition;
  hasDropdown?: boolean;
  content: string | React.ReactNode;
}

export const HamburgerMenuItem: React.FC<HamburgerMenuItemProps> = props => {
  const { pathname } = useLocation();
  const match = useRouteMatch({ path: props.to, exact: false, strict: false });
  const [showDropdown, setShowDropdown] = useState(false);
  const { setShow } = useContext(HamburgerMenuContext);

  // match route, but don't match '/' if there's more to the path
  // without this, "/bookmarks" would match both "/" and "/bookmarks"
  const isMatch = pathname === '/' && props.to === '/' ? true : !!match && props.to !== '/';

  const handleMobileDropdownClick = (e: BaseSyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setShowDropdown(true);
  };

  return (
    <ListGroup.Item
      className={classnames('border-0', isMatch ? 'bg-white' : 'bg-transparent')}
      onMouseEnter={() => setShowDropdown(true)}
      onMouseLeave={() => setShowDropdown(false)}
    >
      {props.to ? (
        <>
          <NavLink exact to={props.to}>
            <span
              className={classnames('d-flex no-gutters', isMatch ? 'text-blue' : 'text-light')}
              onClick={props.hasDropdown && isMobile ? handleMobileDropdownClick : () => setShow(false)}
            >
              <Col xs="2">{props.icon && <FontAwesomeIcon icon={props.icon} className="fa-fw mr-3" />}</Col>
              <Col xs="10">{props.content}</Col>
            </span>
          </NavLink>
          {props.hasDropdown && showDropdown && props.children}
        </>
      ) : (
        <Row noGutters className="empty-route-hamburger-menu-item">
          <Col xs="2">{props.icon && <FontAwesomeIcon icon={props.icon} className="fa-fw mr-3" />}</Col>
          <Col xs="10">{props.content}</Col>
        </Row>
      )}
    </ListGroup.Item>
  );
};

export default HamburgerMenuItem;
