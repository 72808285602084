import { DataSource, IAssetAttribute } from '@amgen/core';

export const DEFAULT_SAVED_FACET: IAssetAttribute[] = ['doc_nav_prefix'];

export const DEFAULT_EXPORT_FIELDS: IAssetAttribute[] = [];

export const DEFAULT_COLUMNS: IAssetAttribute[] = ['extracted_therapeutic_areas', 'account_name'];

export const LEGAL_DEFAULT_COLUMNS: IAssetAttribute[] = [
  'contract_parties',
  'contract_title',
  'contract_effective_date',
  'extracted_amg_drugs',
];

export const DEFAULT_DATA_SOURCE: DataSource[] = [
  DataSource.CDOCS,
  DataSource.CONFLUENCE,
  DataSource.SHAREPOINT,
  DataSource.SMARTSHEET,
  DataSource.TWITTER,
  DataSource.ELN,
];
