import * as Types from '@amgen/core';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ISavedSearchFragment = Pick<
  Types.ISavedSearch,
  | 'applicationName'
  | 'filters'
  | 'id'
  | 'name'
  | 'query'
  | 'timestamp'
  | 'subscrptnIsactive'
  | 'subscrptnStartDate'
  | 'subscrptnEndDate'
  | 'subscrptnType'
  | 'subscrptnMailIds'
  | 'subscrptnRecordCount'
  | 'sourceType'
>;

export type IGetSavedSearchesQueryVariables = Types.Exact<{
  appName: Types.ApplicationName;
  start?: Types.Maybe<Types.Scalars['Int']>;
  page?: Types.Maybe<Types.Scalars['Int']>;
  count?: Types.Maybe<Types.Scalars['Int']>;
}>;

export type IGetSavedSearchesQuery = { getSavedSearches?: Types.Maybe<Array<Types.Maybe<ISavedSearchFragment>>> };

export type ISaveSearchMutationVariables = Types.Exact<{
  appName: Types.ApplicationName;
  issubscribe?: Types.Maybe<Types.Scalars['Boolean']>;
  saveSearch?: Types.Maybe<Types.ISavedSearchInput>;
}>;

export type ISaveSearchMutation = { saveSearch?: Types.Maybe<ISavedSearchFragment> };

export type IUpdateSavedSearchMutationVariables = Types.Exact<{
  appName: Types.ApplicationName;
  issubscribe?: Types.Maybe<Types.Scalars['Boolean']>;
  saveSearch?: Types.Maybe<Types.ISavedSearchInput>;
}>;

export type IUpdateSavedSearchMutation = { updateSavedSearch?: Types.Maybe<ISavedSearchFragment> };

export const SavedSearchFragmentDoc = gql`
  fragment SavedSearch on SavedSearch {
    applicationName
    filters
    id
    name
    query
    timestamp
    subscrptnIsactive
    subscrptnStartDate
    subscrptnEndDate
    subscrptnType
    subscrptnMailIds
    subscrptnRecordCount
    sourceType
  }
`;
export const GetSavedSearchesDocument = gql`
  query GetSavedSearches($appName: ApplicationName!, $start: Int = 0, $page: Int = 0, $count: Int = 10) {
    getSavedSearches(applicationName: $appName, page: $page, start: $start, count: $count) {
      ...SavedSearch
    }
  }
  ${SavedSearchFragmentDoc}
`;
export function useGetSavedSearchesQuery(
  baseOptions: Apollo.QueryHookOptions<IGetSavedSearchesQuery, IGetSavedSearchesQueryVariables>
) {
  return Apollo.useQuery<IGetSavedSearchesQuery, IGetSavedSearchesQueryVariables>(
    GetSavedSearchesDocument,
    baseOptions
  );
}
export function useGetSavedSearchesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetSavedSearchesQuery, IGetSavedSearchesQueryVariables>
) {
  return Apollo.useLazyQuery<IGetSavedSearchesQuery, IGetSavedSearchesQueryVariables>(
    GetSavedSearchesDocument,
    baseOptions
  );
}
export type GetSavedSearchesQueryHookResult = ReturnType<typeof useGetSavedSearchesQuery>;
export type GetSavedSearchesLazyQueryHookResult = ReturnType<typeof useGetSavedSearchesLazyQuery>;
export type GetSavedSearchesQueryResult = Apollo.QueryResult<IGetSavedSearchesQuery, IGetSavedSearchesQueryVariables>;
export const SaveSearchDocument = gql`
  mutation SaveSearch($appName: ApplicationName!, $issubscribe: Boolean, $saveSearch: SavedSearchInput) {
    saveSearch(applicationName: $appName, saveSearch: $saveSearch, IsSubscribed: $issubscribe) {
      ...SavedSearch
    }
  }
  ${SavedSearchFragmentDoc}
`;
export type ISaveSearchMutationFn = Apollo.MutationFunction<ISaveSearchMutation, ISaveSearchMutationVariables>;
export function useSaveSearchMutation(
  baseOptions?: Apollo.MutationHookOptions<ISaveSearchMutation, ISaveSearchMutationVariables>
) {
  return Apollo.useMutation<ISaveSearchMutation, ISaveSearchMutationVariables>(SaveSearchDocument, baseOptions);
}
export type SaveSearchMutationHookResult = ReturnType<typeof useSaveSearchMutation>;
export type SaveSearchMutationResult = Apollo.MutationResult<ISaveSearchMutation>;
export type SaveSearchMutationOptions = Apollo.BaseMutationOptions<ISaveSearchMutation, ISaveSearchMutationVariables>;
export const UpdateSavedSearchDocument = gql`
  mutation updateSavedSearch($appName: ApplicationName!, $issubscribe: Boolean, $saveSearch: SavedSearchInput) {
    updateSavedSearch(applicationName: $appName, saveSearch: $saveSearch, IsSubscribed: $issubscribe) {
      ...SavedSearch
    }
  }
  ${SavedSearchFragmentDoc}
`;
export type IUpdateSavedSearchMutationFn = Apollo.MutationFunction<
  IUpdateSavedSearchMutation,
  IUpdateSavedSearchMutationVariables
>;
export function useUpdateSavedSearchMutation(
  baseOptions?: Apollo.MutationHookOptions<IUpdateSavedSearchMutation, IUpdateSavedSearchMutationVariables>
) {
  return Apollo.useMutation<IUpdateSavedSearchMutation, IUpdateSavedSearchMutationVariables>(
    UpdateSavedSearchDocument,
    baseOptions
  );
}
export type UpdateSavedSearchMutationHookResult = ReturnType<typeof useUpdateSavedSearchMutation>;
export type UpdateSavedSearchMutationResult = Apollo.MutationResult<IUpdateSavedSearchMutation>;
export type UpdateSavedSearchMutationOptions = Apollo.BaseMutationOptions<
  IUpdateSavedSearchMutation,
  IUpdateSavedSearchMutationVariables
>;
