import { useFacetQuery } from '@amgen/api';
import { ApplicationName, IAssetAttribute, IFacetValue } from '@amgen/core';
import { useAppliedFiltersWithPersistentValues } from '@amgen/feature-facets';
import { useCleanSearchQuery } from '@amgen/shared';
import React, { useContext, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';

import './lazy-facet-tree.module.scss';

import FacetValue from '../facet-value/facet-value';

function useFacetValueState(field: IAssetAttribute, value: string, sources: string[]) {
  const categoryItems = sources;
  const isSelected = !!categoryItems?.includes(value);
  const shouldExpand =
    !!categoryItems && categoryItems.some(appliedValue => appliedValue.includes(value.replace(/\d+/, '')));

  return { isSelected, shouldExpand };
}

function useLazyTreeFacetQuery(name: IAssetAttribute, prefix = '0/') {
  const filters = useAppliedFiltersWithPersistentValues();
  const cleanQuery = useCleanSearchQuery();
  //const { currentSource } = useContext(SearchContext);
  const applicationName: ApplicationName = sessionStorage.getItem('appName')
    ? ApplicationName[sessionStorage.getItem('appName')!]
    : ApplicationName.EEA;
  const { data, loading } = useFacetQuery({
    variables: {
      solrHandler: applicationName === 'DVIEW' ? '/dview-search-select' : '/search-select',
      query: cleanQuery,
      filters,
      excludeCount: false,
      fields: [{ facetLimit: -1, minCount: 0, name, prefix }],
      appName: applicationName,
      userType: sessionStorage.getItem('employeeType') || '',
      //sourceType: SOURCE_TYPE.type[currentSource],
    },
  });

  return { data: data?.facet?.[0]?.values ?? [], loading };
}

interface LazyFacetTreeNodeProps {
  facet: IFacetValue;
  category: IAssetAttribute;
  sources: string[];
}

export function LazyFacetTreeNode(props: LazyFacetTreeNodeProps) {
  const { isSelected, shouldExpand } = useFacetValueState(props.category, props.facet.value!, props.sources);
  const [expanded, setExpanded] = useState(shouldExpand);
  const [hasChild, setHasChild] = useState(true);

  const displayValue = props.facet.value?.split('/').pop();
  const prefix = props.facet.value?.replace(/\d+/, n => `${+n + 1}`) ?? '';

  return (
    <>
      <FacetValue
        facet={props.facet}
        isSelected={isSelected}
        isExpanded={expanded}
        onExpandToggle={setExpanded}
        treeNode={hasChild}
        displayValue={displayValue}
      />
      {expanded && (
        <div className="pl-3">
          <LazyFacetTree prefix={prefix} category={props.category} setHasChild={setHasChild} sources={props.sources} />
        </div>
      )}
    </>
  );
}

export interface LazyFacetTreeProps {
  prefix: string;
  category: IAssetAttribute;
  root?: boolean;
  setHasChild?: React.Dispatch<React.SetStateAction<boolean>>; // Not an event, Just a function reference
  sources: string[];
}

export function LazyFacetTree(props: LazyFacetTreeProps) {
  const { data, loading } = useLazyTreeFacetQuery(props.category, props.prefix);
  const { sources } = props;
  useEffect(() => {
    if (!loading) {
      props.setHasChild?.(data.length !== 0);
    }
  }, [data.length, loading, props]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner animation="border" size="sm" className="text-primary" />
      </div>
    );
  }

  if (data.length === 0) {
    return props.root ? <div className="px-3">No Items</div> : null;
  }

  return (
    <>
      {data.map(facetValue => (
        <LazyFacetTreeNode facet={facetValue} category={props.category} key={facetValue.value} sources={sources} />
      ))}
    </>
  );
}

export default LazyFacetTree;
