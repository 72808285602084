import classnames from 'classnames';
import React from 'react';
import { Nav } from 'react-bootstrap';

import './header-center.scss';

export interface HeaderCenterProps {
  className?: string;
}

export const HeaderCenter: React.FC<HeaderCenterProps> = props => {
  return (
    <Nav className={classnames('mx-auto flex-fill align-items-center', props.className)}>
      <Nav.Item className="header-nav">{props.children}</Nav.Item>
    </Nav>
  );
};

export default HeaderCenter;
