import React from 'react';

import './consent-paragraph.module.scss';

import { AVE_POINT_URL, POL_URL } from '../config';

/* eslint-disable-next-line */
export interface ConsentParagraphProps {
  showAvePoint?: boolean;
}
const policyGuide = () => {
  window.open(POL_URL);
};
export function ConsentParagraph(props: ConsentParagraphProps) {
  return (
    <>
      You are responsible for complying with Amgen's Confidential and Proprietary Information Policy{' '}
      <a href={POL_URL} target="_blank" rel="noreferrer" onClick={policyGuide}>
        (POL-401061)
      </a>{' '}
      with respect to accessing Amgen Proprietary Information from any Amgen site. This includes only accessing Amgen
      Proprietary Information on a 'Need-to-Know' basis, which requires you to have a legitimate and demonstrable
      business need to receive the information as part of your defined role(s) or job function(s).
      {props.showAvePoint && (
        <span>
          You are agreeing to make this information available on the Enterprise Search Portal. Please have the site
          owner or contact ensure that Site Attestation (content and access review) is current:{' '}
          <a href={AVE_POINT_URL} target="_blank" rel="noreferrer">
            Ave Point
          </a>
        </span>
      )}
    </>
  );
}

export default ConsentParagraph;
