import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import ReactDOM from 'react-dom';

import './feature-chatbot-confirm-modal.scss';

export interface FeatureChatbotConfirmModalProps {
  message: string;
  show: boolean;
  setShow: (show: boolean) => void;
  onConfirm: () => void;
  onCancel?: () => void;
  confirmButtonText?: string;
  cancelButtonText?: string;
  enabledNavigation?: boolean;
}

export function FeatureChatbotConfirmModal(props: FeatureChatbotConfirmModalProps) {
  const handleClick = () => {
    props.onConfirm();
    props.setShow(false);
  };
  const [container, setContainer] = useState<HTMLElement | null>(null);
  const [domReady, setDomReady] = React.useState(false);
  useEffect(() => {
    setDomReady(true);
    setTimeout(() => {
      setContainer(document.getElementById('va-chatbot'));
    }, 10000);
  }, []);

  return domReady && container && props.show
    ? ReactDOM.createPortal(
        <div>
          <div className="confirmation-box">
            {/* <div className="header-text">Please Confirm !</div>
            <hr></hr> */}
            <div className="confirmation-text">
              <span>{props.message}</span>
            </div>
            <div className="footer-button-section">
              <Button
                variant="primary"
                className="yes-button"
                onClick={() => {
                  handleClick();
                }}
              >
                {'Yes'}
              </Button>
              <Button variant="secondary" className="cancel-button" onClick={() => props.setShow(false)}>
                {'No'}
              </Button>
            </div>
          </div>
        </div>,
        container
      )
    : null;
}

export default FeatureChatbotConfirmModal;
