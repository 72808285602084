import { useGetDataSourceDetailsQuery, useSavePreferenceMutation } from '@amgen/api';
import { SettingsPreferenceType, useEnvironment } from '@amgen/core';
import {
  CUSTOM_SOURCE_CREATION_ACTIONS,
  MatomoContext,
  PreferenceContext,
  SEARCH_APP_FEATURE_LIST,
  useToast,
} from '@amgen/shared';
import { Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { CloseButton, Form, Modal } from 'react-bootstrap';

import './custom-group-modal.scss';

import CustomGroupForm from '../custom-group-form/custom-group-form';
import { ICustomGroupFormValue, validate } from '../custom-group-form/models';

/* eslint-disable-next-line */
export interface CustomGroupModalProps {
  show: boolean;
  onHide: () => void;
  onSuccess?: () => void;
  name?: string;
  mode?: string;
  sources?: string[];
  id?: string;
}

export function CustomGroupModal(props: CustomGroupModalProps) {
  const [show, setShow] = useState(false);
  const { appName } = useEnvironment();
  const toast = useToast();
  const user = JSON.parse(sessionStorage.user);

  const { data, loading, error } = useGetDataSourceDetailsQuery({
    variables: { appName, page: 0, count: 10, userType: sessionStorage.getItem('employeeType') || '' },
  });
  const [createCustomGroup] = useSavePreferenceMutation();
  const { refetchDataSources, setCreatedCustomSource, setCreatedCustomDataSourceName } = useContext(PreferenceContext);
  const { trackApplicationFeature } = useContext(MatomoContext);
  const queryId = props.id ?? '';
  const handleCancel = () => {
    props.onHide();
  };

  return (
    <Modal show={props.show} onHide={() => setShow(true)} scrollable centered restoreFocus={false}>
      <Modal.Header className="flex-column border-primary px-4" style={{ backgroundColor: '#f4f8fc' }}>
        <Modal.Title as="h1" className="text-primary justify-center">
          {props.mode === 'edit' ? 'Edit Custom Group' : 'Create Custom Group'}
        </Modal.Title>
        <CloseButton
          className="mr-2 my-auto position-absolute text-primary custom-group-modal-close-button"
          onClick={handleCancel}
        />
      </Modal.Header>
      <Formik<ICustomGroupFormValue>
        initialValues={{ name: props.name ?? '', sources: props.sources ?? [] }}
        onSubmit={values => {
          if (values.name === `${user.firstName}'s Favorites`) {
            toast.danger({
              message: `Custom group name cannot be ${user.firstName}'s Favorites. Please select a different group name`,
              id: 'custom-group-toaster',
              timeout: 5000,
            });
            return;
          }
          createCustomGroup({
            variables: {
              appName,
              values: values.sources,
              type: SettingsPreferenceType.DATA_SOURCE,
              group: values.name,
              queryId: queryId,
            },
          })
            .then(() => {
              const message =
                queryId === '' ? 'Custom group created successfully.' : 'Custom group edited successfully.';
              props.onHide();
              refetchDataSources();
              setCreatedCustomDataSourceName(values.sources);
              setCreatedCustomSource(values.name);
              queryId === '' &&
                window.location.pathname.includes('/home') &&
                trackApplicationFeature(
                  SEARCH_APP_FEATURE_LIST.CUSTOM_SOURCE_CREATION,
                  CUSTOM_SOURCE_CREATION_ACTIONS.CREATED_FROM_HOME_PAGE
                );
              queryId === '' &&
                !window.location.pathname.includes('/home') &&
                trackApplicationFeature(
                  SEARCH_APP_FEATURE_LIST.CUSTOM_SOURCE_CREATION,
                  CUSTOM_SOURCE_CREATION_ACTIONS.CREATED_FROM_SEARCH_PAGE
                );
              toast.success({
                message: message,
                id: 'custom-group-toaster',
                timeout: 5000,
              });
            })
            .catch(error => {
              toast.danger({
                message: error?.graphQLErrors?.[0]?.exception?.message ?? 'Unable to update. Please try again later.',
                id: 'custom-group-toaster',
                timeout: 5000,
              });
            });
        }}
        validate={validate}
      >
        {({ values, errors, touched, handleSubmit }) => {
          return (
            <Form className="mx-auto" noValidate onSubmit={handleSubmit}>
              <Modal.Body className="p-4 content-sources">
                <CustomGroupForm values={values} handleCancel={handleCancel} />
              </Modal.Body>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default CustomGroupModal;
