import { useKnowledgePanelQuery } from '@amgen/api';
import { useEnvironment } from '@amgen/core';
import React, { useCallback, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import './clinical-context-provider.scss';

import { useSearchQueryValue } from '../../hooks';
import { ClinicalContext, IClinicalState } from './clinical-context';

/* eslint-disable-next-line */
export interface ClinicalContextProviderProps {}

export const ClinicalContextProvider: React.FC<ClinicalContextProviderProps> = ({ children }) => {
  const history = useHistory();
  const { clinicalId, clinicalType } = useParams<{ clinicalType: string; clinicalId: string }>();
  const query = useSearchQueryValue();
  const { product, loading, error } = useKnowledgePanelQuery(clinicalId ?? query);
  const [historyCount, setHistoryCount] = useState(0);
  const [maxHistoryCount, setMaxHistoryCount] = useState(0);
  const { appName } = useEnvironment();

  const id = clinicalId ?? query;
  const type = clinicalType ?? (isNaN(parseInt(id)) ? 'product' : 'study');

  const hasNext = maxHistoryCount > historyCount;
  const hasPrev = historyCount > 0;

  const addGraph = useCallback(
    (type: string, id: string) => {
      setMaxHistoryCount(historyCount + 1);
      setHistoryCount(count => count + 1);
      history.push(`/clinical/${type}/${id}`);
    },
    [history, historyCount]
  );

  const nextGraph = useCallback(() => {
    setHistoryCount(historyCount + 1);
    history.goForward();
  }, [history, historyCount]);

  const prevGraph = useCallback(() => {
    setHistoryCount(historyCount - 1);
    history.goBack();
  }, [history, historyCount]);

  const providedValue = useMemo<IClinicalState>(
    () => ({
      product: !error ? product : null,
      loading,
      clinicalType: type,
      clinicalId: id,
      addGraph,
      nextGraph,
      prevGraph,
      hasNext,
      hasPrev,
      historyCount,
    }),
    [error, appName, product, loading, type, id, addGraph, nextGraph, prevGraph, hasNext, hasPrev, historyCount]
  );

  return <ClinicalContext.Provider value={providedValue}>{children}</ClinicalContext.Provider>;
};

export default ClinicalContextProvider;
