import { DropdownContainer } from '@amgen/components';
import { addDays, addMonths, endOfDay, endOfMonth, endOfWeek, startOfDay, startOfMonth, startOfWeek } from 'date-fns';
import React, { useCallback } from 'react';
import { Dropdown } from 'react-bootstrap';

import './date-range-dropdown.scss';

export interface IDateRangeValue {
  label: string;
  value: [
    {
      startDate: Date;
      endDate: Date;
      key: string;
    }
  ];
}

export interface DateRangeDropdownProps {
  value?: IDateRangeValue | null;
  onValueChange: (value: IDateRangeValue) => void;
  customValueText: string;
}

export const DateRangeDropdown = (props: DateRangeDropdownProps) => {
  const dateRanges = useCallback(() => {
    const today = new Date();
    const dateAttribute = 'selection';
    const ranges: IDateRangeValue[] = [
      {
        label: 'Yesterday',
        value: [
          { startDate: startOfDay(addDays(today, -1)), endDate: endOfDay(addDays(today, -1)), key: dateAttribute },
        ],
      },
      { label: 'This Week', value: [{ startDate: startOfWeek(today), endDate: endOfWeek(today), key: dateAttribute }] },
      {
        label: 'Last Week',
        value: [
          { startDate: startOfWeek(addDays(today, -7)), endDate: endOfWeek(addDays(today, -7)), key: dateAttribute },
        ],
      },
      {
        label: 'This Month',
        value: [{ startDate: startOfMonth(today), endDate: endOfMonth(today), key: dateAttribute }],
      },
      {
        label: 'Last Month',
        value: [
          {
            startDate: startOfMonth(addMonths(today, -1)),
            endDate: endOfMonth(addMonths(today, -1)),
            key: dateAttribute,
          },
        ],
      },
      { label: props.customValueText, value: [{ startDate: today, endDate: today, key: dateAttribute }] },
    ];

    return ranges;
  }, [props.customValueText]);

  return (
    <DropdownContainer
      title={props.value?.label ?? 'Date Range'}
      onValueToggle={value => props.onValueChange(dateRanges().find(r => r.label === value)!)}
      closeAfterSelection={false}
    >
      {dateRanges().map(dateRange => (
        <Dropdown.Item key={dateRange.label} eventKey={dateRange.label}>
          {dateRange.label}
        </Dropdown.Item>
      ))}
    </DropdownContainer>
  );
};

export default DateRangeDropdown;
