import { useDeleteDestinationMutation } from '@amgen/api';
import { IDestinationsData } from '@amgen/core';
import { useToast, useWindowSizeListener } from '@amgen/shared';
import { faEdit, faPaperclip, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { CellProps, Column, useTable } from 'react-table';

import './suggested-app-table.module.scss';

import { safeFormatDate } from '../../../config/utils';
import AttachmentDownloadLink from '../../attachment-download-link/attachment-download-link';
import ConfirmModal from '../../confirm-modal/confirm-modal';
import SuggestedAppTableBody from '../suggested-app-table-body/suggested-app-table-body';
import SuggestedAppTableHead from '../suggested-app-table-head/suggested-app-table-head';

/* eslint-disable-next-line */
export interface SuggestedAppTableProps {
  data: IDestinationsData[];
  loading: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  setRowData: Dispatch<SetStateAction<any>>;
  setSuggestedAppModalMode: Dispatch<SetStateAction<string>>;
  refetch?: any;
}

export const SuggestedAppTable = ({
  data,
  loading,
  setShow,
  setRowData,
  setSuggestedAppModalMode,
  refetch,
}: SuggestedAppTableProps) => {
  const [columns, setColumns] = useState<Column<IDestinationsData>[]>([]);
  const [deleteSuggestedApp] = useDeleteDestinationMutation();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [currentId, setCurrentId] = useState('');
  const toast = useToast();
  const deleteApp = () => {
    deleteSuggestedApp({
      variables: { id: currentId },
    })
      .then(refetch())
      .then(() => {
        toast.success({
          message: 'Suggested App deleted succesfully.',
          id: 'suggested-app-toaster',
          timeout: 5000,
        });
        setShowConfirmModal(false);
      })
      .catch(error => {
        toast.danger({
          message: error?.graphQLErrors?.[0]?.exception?.message ?? 'Unable to delete. Please try again later.',
          id: 'suggested-app-toaster',
          timeout: 5000,
        });
        setShowConfirmModal(false);
      });
  };
  useEffect(() => {
    const col: Column<IDestinationsData>[] = [
      { Header: 'S.No', Cell: ({ row }: CellProps<IDestinationsData>) => row.index + 1 },
      { Header: 'Title', accessor: 'title' },
      { Header: 'Description', accessor: 'content' },
      { Header: 'Destination URL', accessor: 'url' },
      { Header: 'Requestor', accessor: 'requesterId' },
      { Header: 'Created By', accessor: 'createdBy' },

      {
        Header: 'Image',
        Cell: ({ row }: CellProps<IDestinationsData>) =>
          row.original.thumbnailLarge ? (
            <AttachmentDownloadLink s3Location={row.original.thumbnailLarge}>
              <FontAwesomeIcon className="text-primary" icon={faPaperclip} />
            </AttachmentDownloadLink>
          ) : null,
      },

      {
        Header: 'Requested Date',
        accessor: 'createdOn',
        Cell: ({ row }: CellProps<IDestinationsData>) => safeFormatDate(row.values['createdOn']),
      },
      {
        Header: 'Action',
        Cell: ({ row }: CellProps<IDestinationsData>) => (
          <>
            <Button
              variant="link"
              title="Update"
              onClick={() => {
                setShow(true);
                setRowData(row.original);
                setSuggestedAppModalMode('edit');
              }}
            >
              <FontAwesomeIcon className="text-primary" icon={faEdit} />
            </Button>
            <Button
              variant="link"
              title="Delete"
              onClick={() => {
                setShowConfirmModal(true);
                setCurrentId(row.original.id || '');
              }}
            >
              <FontAwesomeIcon className="text-danger" icon={faTrash} />
            </Button>
          </>
        ),
      },
    ];
    setColumns(col);
  }, [data]);
  const table = useTable<IDestinationsData>({ data, columns });
  const isMobile = useWindowSizeListener();

  return (
    <>
      <Table striped bordered hover responsive {...table.getTableProps()} className={isMobile ? 'fs-12' : ''}>
        <thead>
          <SuggestedAppTableHead headerGroups={table.headerGroups} />
        </thead>

        <SuggestedAppTableBody table={table} loading={loading} />
      </Table>
      {!loading && table.rows.length < 1 ? (
        <div className={classNames('py-5 text-center bg-grey mt-n3 mb-3', { 'fs-14': isMobile })}>
          No Results found. Try removing filters or searching for other terms
        </div>
      ) : null}
      <ConfirmModal
        message="Suggested app/destination will be deleted permanently. Are you sure you want to proceed ?"
        show={showConfirmModal}
        setShow={setShowConfirmModal}
        onConfirm={() => deleteApp()}
        confirmButtonText="Yes, Delete"
        cancelButtonText="Cancel"
      />
    </>
  );
};

export default SuggestedAppTable;
