import { IAssetAttribute, IFacetValue } from '@amgen/core';
import { FacetsPanelContext } from '@amgen/shared';
import React, { useContext } from 'react';

import { FacetValue } from '../facet-value/facet-value';
import { ContractPagesData } from './hooks';

/* eslint-disable-next-line */
export interface RangeFacetItemsProps {
  facetValues: IFacetValue[];
  category: IAssetAttribute;
}

export function RangeFacetItems(props: RangeFacetItemsProps) {
  const { selectedFilters } = useContext(FacetsPanelContext);
  const categoryItems = selectedFilters.filters?.[props.category] ?? new Set<string>();
  const {
    dataZeroToNinetyNine,
    dataHundredToThousands,
    dataThousandToTenThousands,
    dataMoreThanTenThousands,
  } = ContractPagesData();

  const checkForSelected = (value: string) => {
    return categoryItems.has(value);
  };

  return (
    <div>
      <FacetValue
        facet={{ value: 'Less than 5', count: dataZeroToNinetyNine?.search?.resultCount }}
        displayValue={'Less than 5'}
        isSelected={checkForSelected('Less than 5')}
      />
      <FacetValue
        facet={{ value: 'Between 5 and 14', count: dataHundredToThousands?.search?.resultCount }}
        displayValue={'Between 5 and 14'}
        isSelected={checkForSelected('Between 5 and 14')}
      />
      <FacetValue
        facet={{ value: 'Between 15 and 59', count: dataThousandToTenThousands?.search?.resultCount }}
        displayValue={'Between 15 and 59'}
        isSelected={checkForSelected('Between 15 and 59')}
      />
      <FacetValue
        facet={{ value: '60 or More', count: dataMoreThanTenThousands?.search?.resultCount }}
        isSelected={checkForSelected('60 or More')}
      />
    </div>
  );
}

export default RangeFacetItems;
