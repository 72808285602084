import { useGetBinaryFeedbackQuery, useGetTemporaryS3FileAccessLazyQuery, useSearchQuery } from '@amgen/api';
import { LoaderErrorContainer } from '@amgen/components';
import { DEFAULT_SEARCH_QUERY, Direction, SortField, useEnvironment } from '@amgen/core';
import { AttachmentDownloadButton } from '@amgen/feature-asset-actions';
import { FeatureBinaryRating } from '@amgen/feature-binary-rating';
import { Asset } from '@amgen/feature-search';
import {
  AppContext,
  AssetContextProvider,
  FacetContext,
  PAGES,
  ResultViewType,
  useCleanSearchQuery,
  usePageTrackingWithTitle,
  useWindowSizeListener,
} from '@amgen/shared';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';

import './asset-detail.scss';

import { PreviewImageDetail } from '../preview-image-detail/preview-image-detail';

export interface AssetDetailProps {
  isALongAttribute?: boolean;
}

export const AssetDetail = (props: AssetDetailProps) => {
  const { internalSources, setCurrentSource, setBackButtonClicked } = useContext(AppContext);

  const { setCustomFilterSearch } = useContext(FacetContext);
  //const { appliedFilters, setAppliedFilters } = useContext(FacetContext);

  const [readMore, setReadMore] = useState(true);
  const { assetId } = useParams<{ assetId: string }>();

  const { goBack } = useHistory();
  const { appName, requestHandler } = useEnvironment();
  const isMobile = useWindowSizeListener();

  usePageTrackingWithTitle(PAGES.DOCUMENT_DETAILS);

  const { data, loading } = useSearchQuery({
    variables: {
      solrHandler: appName === 'DVIEW' ? '/dview-search-select' : '/search-select',
      query: DEFAULT_SEARCH_QUERY,
      filters: [`id : "${decodeURIComponent(assetId || '')}"`],
      page: 0,
      count: 1,

      sort: SortField.MODIFIED_DATE,
      order: Direction.ASC,
      includeDocs: true,
      noShortContent: false,
      addPersonalizationDetails: true,
      appName,
    },
  });

  const asset = data?.search?.docs?.[0] ?? null;
  const [previewImages, setPreviewImages] = useState<string[]>([]);
  const [loadImages, { data: previewImageData, loading: imagesloading }] = useGetTemporaryS3FileAccessLazyQuery({
    variables: {
      paths: asset?.dataAsset?.preview_images ?? [],
    },
    onCompleted: () => {
      if (previewImageData?.getTemporaryS3FileAccess && previewImageData?.getTemporaryS3FileAccess.length) {
        setPreviewImages(previewImageData.getTemporaryS3FileAccess.map(fileAccess => fileAccess?.preSignedUrl ?? ''));
      }
    },
    fetchPolicy: 'network-only',
  });

  const idArray: any = [];
  const idResult = data?.search?.docs?.length ? data?.search?.docs.map(res => idArray.push(res?.dataAsset?.id)) : [];

  const cleanQuery = useCleanSearchQuery();

  const { data: ratingData, refetch } = useGetBinaryFeedbackQuery({
    variables: {
      appName: appName,
      searchTerm: cleanQuery,
      assetId: idArray ? idArray : ['result'],
    },
    notifyOnNetworkStatusChange: true,
  });

  const binaryResponse = ratingData?.getBinaryFeedback?.reduce((acc, data) => {
    if (assetId === data.assetId) {
      acc = {
        id: data?.assetId,
        binaryRating: data?.binaryRating,
      };
    }
    return acc;
  }, {});

  //useEffect(() => (previewImages.length === 0 ? loadImages() : undefined), [loadImages, previewImages.length]);
  useEffect(() => {
    if (previewImages.length === 0) {
      loadImages();
    }
  }, [loadImages, previewImages]);

  return (
    <Row noGutters className="justify-content-center">
      <Col
        xs="12"
        className="px-3 py-2"
        onClick={() => {
          setCustomFilterSearch?.(true);
        }}
      >
        <Button
          variant="link"
          className={classNames('px-2 pt-1 h2 position-absolute', isMobile ? '' : 'ml-4')}
          style={{ left: '2%', top: 25 }}
          onClick={() => {
            goBack();
            setBackButtonClicked(true);
          }}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
          <span className="pl-2 d-none d-sm-inline">Back</span>
        </Button>
        <h1 className="p-3 mb-0 font-weight-normal text-center">
          {/*<FontAwesomeIcon icon={faCommentDots} />
          <span className="px-2">Document Details</span>*/}
        </h1>
      </Col>

      <LoaderErrorContainer loading={loading} errors={[]}>
        <div className="asset-summary-container col-12">
          <div className="col-12 col-md-8 bg-white shadow-sm margin-auto">
            {asset ? (
              <Col xs="12" className={classNames('pt-4 document-detail-title', isMobile ? 'px-3' : 'px-4')}>
                <AssetContextProvider docSolrAsset={asset} isBookmarksDisabled={false} viewType={ResultViewType.List}>
                  <Asset.Header isAssetDetail={true}>
                    <Asset.Title
                      className="h2 text-dark font-family-arial-sans-serif pr-3"
                      disableRouterLink
                      docPosition={0}
                    />

                    {/* //[todo ] clean up the filename in download btn */}
                    <Asset.Actions buttonClass={'download-button'}>
                      <FeatureBinaryRating
                        asset={asset.dataAsset!}
                        detailsPage={true}
                        applicationName={appName}
                        ratingData={binaryResponse}
                        refetch={refetch}
                      />
                      {asset.dataAsset?.filename &&
                        asset.dataAsset?.content_source &&
                        !internalSources?.includes(asset.dataAsset?.content_source) &&
                        (isMobile ? (
                          <AttachmentDownloadButton
                            className="btn-lg image-download mt-1"
                            url={asset.dataAsset.filename}
                            fileName={asset.dataAsset.title}
                            isHideFileName={true}
                            isOnImageView={true}
                          />
                        ) : (
                          <AttachmentDownloadButton
                            className="mr-3 d-flex"
                            url={asset.dataAsset.filename}
                            fileName={asset.dataAsset.title}
                            isHideFileName={true}
                            buttonText="Download"
                            isAssetDetail={true}
                          />
                        ))}
                      {/* Neha - DO NOT REMOVE THIS CODE. only hiding for may 2023 release. Will enable this later */}
                      {/* {isMobile ? (
                        <BookmarkToggleButton />
                      ) : (
                        <BookmarkToggleButton isCustomBookmark isAssetDetail={true} />
                      )} */}
                    </Asset.Actions>
                  </Asset.Header>
                </AssetContextProvider>
              </Col>
            ) : null}
            {asset ? (
              <Col
                xs={props.isALongAttribute ? 12 : 12}
                md={12}
                className="d-flex flex-column align-items-start fs-13 font-family-arial-sans-serif p-md-4"
              >
                {asset.dataAsset?.content_source === 'Cortellis' && asset.dataAsset.summary ? (
                  <div className=" mt-2 w-100">
                    <div className="font-weight-bold mb-2 summary-title">Summary</div>
                    <div className={classNames('asset-detail-summary', isMobile ? 'pb-4' : '')}>
                      {asset.dataAsset.summary}
                    </div>
                    {asset && asset.dataAsset?.preview_images && !isMobile ? (
                      <div className="asset-detail-image-container">
                        <PreviewImageDetail
                          //assetTitle={asset.dataAsset.title}
                          previewImages={previewImages}
                          asset={asset.dataAsset}
                          hasPreviewImages={!!asset?.dataAsset?.preview_images}
                          loading={loading}
                        />
                      </div>
                    ) : null}
                    {/*!readMore && <FullContent assetId={assetId} show={readMore} />*/}
                    {/*<Button variant="link" className="p-0 fs-13" onClick={() => setReadMore(!readMore)}>
                      {readMore ? ' Read More...' : 'Read Less...'}
                    </Button>*/}
                  </div>
                ) : (
                  ''
                )}

                {!!asset?.dataAsset?.id &&
                  asset?.dataAsset?.short_content &&
                  !asset?.dataAsset?.summary &&
                  asset.dataAsset?.content_source !== 'ClinicalTrials.gov' && (
                    <div className="mt-2 w-100 ">
                      {/*<ClamppedContentAttribute
                        assetId={asset.dataAsset.id}
                        value={asset.dataAsset.short_content}
                        isAssetDetail={true}
                     />*/}
                      {asset && asset.dataAsset?.preview_images ? (
                        <div className="asset-detail-image-container">
                          <PreviewImageDetail
                            //assetTitle={asset.dataAsset.title}
                            previewImages={previewImages}
                            asset={asset.dataAsset}
                            hasPreviewImages={!!asset?.dataAsset?.preview_images}
                            loading={loading}
                          />
                        </div>
                      ) : null}
                    </div>
                  )}

                {asset.dataAsset?.content_source === 'ClinicalTrials.gov' && asset.dataAsset?.summary ? (
                  <div className="mt-2 w-100 ">
                    <div className={classNames('asset-detail-summary', isMobile ? 'pb-4' : '')}>
                      {asset.dataAsset.summary}
                    </div>
                    {asset && asset.dataAsset?.preview_images ? (
                      <div className="asset-detail-image-container">
                        <PreviewImageDetail
                          //assetTitle={asset.dataAsset.title}
                          previewImages={previewImages}
                          asset={asset.dataAsset}
                          hasPreviewImages={!!asset?.dataAsset?.preview_images}
                          loading={loading}
                        />
                      </div>
                    ) : null}
                    {/*{!readMore && <FullContent assetId={assetId} show={readMore} />}
                    <Button variant="link" className="p-0 fs-13" onClick={() => setReadMore(!readMore)}>
                      {readMore ? ' Read More...' : 'Read Less...'}
                </Button>*/}
                  </div>
                ) : (
                  ''
                )}

                {!!asset?.dataAsset?.id &&
                  asset?.dataAsset?.short_content &&
                  !asset?.dataAsset?.summary &&
                  asset.dataAsset?.content_source === 'ClinicalTrials.gov' && (
                    <div className="mt-2 w-100 ">
                      {/* <ClamppedContentAttribute
                        assetId={asset.dataAsset.id}
                        value={asset.dataAsset.short_content}
                        isAssetDetail={true}
                     />*/}
                      {asset && asset.dataAsset?.preview_images ? (
                        <div className="asset-detail-image-container">
                          <PreviewImageDetail
                            //assetTitle={asset.dataAsset.title}
                            previewImages={previewImages}
                            asset={asset.dataAsset}
                            hasPreviewImages={!!asset?.dataAsset?.preview_images}
                            loading={loading}
                          />
                        </div>
                      ) : null}
                    </div>
                  )}
              </Col>
            ) : null}
          </div>
          {/* {!isMobile ? (
            <Col xs="12" md="3" className="px-4 border-left bg-white shadow-sm right-content">
              {asset && <AssetDetailTabs asset={asset} />}
            </Col>
          ) : (
            ''
          )} */}
        </div>
        {/* {isMobile ? (
          <Col xs="12" className="px-4 border-left bg-white shadow-sm">
            {asset && <AssetDetailTabs asset={asset} />}
          </Col>
        ) : (
          ''
        )} */}
      </LoaderErrorContainer>
    </Row>
  );
};

export default AssetDetail;
