import classnames from 'classnames';
import React from 'react';
import { Button } from 'react-bootstrap';

import './link-toggler.scss';

export interface LinkTogglerProps extends React.RefAttributes<any> {
  onClick?: any;
  title?: string;
  className?: string;
}

/**
 * can only be used on component with click and title eg. dropdown.toggle
 */
export const LinkToggler: React.ForwardRefExoticComponent<LinkTogglerProps> = React.forwardRef(
  ({ children, onClick, title, className }, ref) => {
    return (
      <Button
        variant="outline"
        className={classnames('pb-1 pt-1 pr-3 pl-2 btn-sm d-flex align-items-center', className)}
        style={{ border: '1px solid #DCDCDC' }}
        type="button"
        ref={ref}
        onClick={(e: any) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {title ? <span className="text-grey">{title} </span> : null}
        {children}
        <div className="dropdown-arrow">&#x25bc;</div>
      </Button>
    );
  }
);

export default LinkToggler;
