import echarts from 'echarts';

export const getEChartSeriesObjectForLine = (
  name: string,
  chartType: string,
  stack: string,
  data: string[] | number[],
  color: string,
  hasAreaGradient?: boolean
) => {
  const areaStyle = hasAreaGradient
    ? {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color,
          },
          {
            offset: 1,
            color,
          },
        ]),
      }
    : undefined;
  return {
    name,
    type: chartType,
    stack,
    data,
    smooth: hasAreaGradient,
    symbol: 'none',
    lineStyle: {
      color,
    },
    itemStyle: {
      color,
    },
    areaStyle,
  };
};
