import { LoaderErrorContainer } from '@amgen/components';
import { PreferenceContext } from '@amgen/shared';
import React, { useContext, useState } from 'react';

import './data-source-section.scss';

import DataSourceSectionBody from './data-source-section-body/data-source-section-body';
import DataSourceSectionHeader from './data-source-section-header/data-source-section-header';
import { LANDING_PAGE_DATA_SOURCE_CATEGORY } from './models';

/* eslint-disable-next-line */
export interface DataSourceSectionProps {
  setBlockScroll: (value: boolean) => void;
}

export const DataSourceSection: React.FC<DataSourceSectionProps> = ({ setBlockScroll }) => {
  const { dataSourceLoading } = useContext(PreferenceContext);
  const [selectedCategory, setSelectedCategory] = useState(LANDING_PAGE_DATA_SOURCE_CATEGORY.ALL);

  return (
    <LoaderErrorContainer loading={dataSourceLoading}>
      <DataSourceSectionHeader selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />
      <DataSourceSectionBody selectedCategory={selectedCategory} setBlockScroll={setBlockScroll} />
    </LoaderErrorContainer>
  );
};
