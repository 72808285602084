import { useGetBinaryFeedbackQuery } from '@amgen/api';
import { ApplicationName } from '@amgen/core';
import {
  AppContext,
  MatomoContext,
  SearchContext,
  useCleanSearchQuery,
  useSearchQueryValue,
  useWindowSizeListener,
} from '@amgen/shared';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import './card-search-results.scss';

import { IResultViewProps } from '../../models';
import { AppSearchCard } from '../app-search-result/app-search-card';
import { CardSearchResult } from '../card-search-result/card-search-result';
import RecommendedResults from '../recommended-results/recommended-results';
import { UserFeedback } from '../search-user-feedback/user-feedback';

const CardSearchResults: React.FC<IResultViewProps> = ({ results, pageNo, ...others }) => {
  const {
    acronymTitleData,
    searchFeedbackData,
    isAllSelected,
    isUserFeedbackCompleted,
    recommendedResultCount,
  } = useContext(SearchContext);
  const abTestingGroup = JSON.parse(sessionStorage.getItem('abTestingGroup') as string);
  const { isCustomSourceActive, selectedResultTab } = useContext(AppContext);

  const { trackApplicationFeature } = useContext(MatomoContext);
  const searchTerm = useSearchQueryValue();
  const cleanQuery = useCleanSearchQuery();
  // const { results: spotLightResults, loading } = useSpotlightResults();
  const [selectedTab, setSelectedTab] = useState(selectedResultTab);
  const [topSearchResultCount, setTopSearchResultCount] = useState(0);
  const [showUserFeedback, setShowUserFeeback] = useState(true);

  const applicationName: ApplicationName = sessionStorage.getItem('appName')
    ? ApplicationName[sessionStorage.getItem('appName')!]
    : ApplicationName.EEA;

  const history = useHistory();

  const idArray: any = [];
  const idResult = results.length ? results.map(res => idArray.push(res?.dataAsset?.id)) : [];

  const { data: ratingData, refetch } = useGetBinaryFeedbackQuery({
    variables: {
      appName: applicationName,
      searchTerm: cleanQuery,
      assetId: idArray ? idArray : ['result'],
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    setSelectedTab(selectedResultTab);
    if (isUserFeedbackCompleted === false) {
      setShowUserFeeback(true);
    } else {
      setShowUserFeeback(false);
    }
  }, [selectedResultTab]);

  let seperateTitles: any;
  if (acronymTitleData && acronymTitleData.getSearchObjects && acronymTitleData.getSearchObjects.docs) {
    seperateTitles = Object.values(
      acronymTitleData?.getSearchObjects?.docs.reduce((acc, x) => {
        acc[x.title] = [...(acc[x.title] || []), x];
        return acc;
      }, {})
    );
  }

  const isMobile = useWindowSizeListener();

  return (
    <ol className="list-unstyled">
      {/* Neha - This is old UI logic for Acronym */}
      {/* {searchTerm !== '*:*' && isAllSelected && seperateTitles?.length > 0 && (
        <div className="mb-4 ml-2 pl-4">
          <div>
            <span className="event-font font-weight-bold ml-1" title="Get the expansion of the acronyms here!">
              Acronym(s) Expansion
            </span>
            <span
              className="custom-span float-right font-weight-bold text-primary cursor-pointer"
              onClick={() => {
                window.open('https://myamgen.amgen.com/myAmgen/s/amgen-acronym-wiki', '_blank');
                trackApplicationFeature(SEARCH_APP_FEATURE_LIST.ACRONYMS, ACRONYMS_ACTIONS.ACRONYM_WIKI_LINK);
              }}
            >
              View Acronym Wiki
            </span>
          </div>
          <AcronymSearchResult seperateTitles={seperateTitles} resultCount={10} />
        </div>
      )} */}

      {/* isAllSelected - if this key is false then we are not showing top results in external sources
      isCustomSourceActive - if this key is true then we are not showing top results in external sources  */}
      {pageNo === 1 && !isCustomSourceActive && searchTerm !== '*:*' && isAllSelected && (
        <>
          <RecommendedResults pageNo={pageNo} hideUserFeedback={true}></RecommendedResults>
          {(searchFeedbackData?.getQueryFeedback?.already_reported === false ||
            searchFeedbackData?.getQueryFeedback?.days_from_last_feedback > 30) &&
            pageNo === 1 &&
            abTestingGroup &&
            showUserFeedback === true &&
            recommendedResultCount >= 1 &&
            results.length === 0 && <UserFeedback assetId={''} value={undefined} />}
        </>
      )}
      <div className="mt-3">
        {results.map((result, i) =>
          abTestingGroup &&
          (result?.dataAsset?.component_type === 'events' || result?.dataAsset?.component_type === 'toplinks') ? (
            <>
              <AppSearchCard
                appData={result?.dataAsset}
                type={result?.dataAsset?.component_type}
                docPosition={i + 1 + recommendedResultCount}
                docSolrAsset={result}
                ratingData={ratingData}
                refetch={refetch}
                applicationName={applicationName}
                {...others}
              />
              {(searchFeedbackData?.getQueryFeedback?.already_reported === false ||
                searchFeedbackData?.getQueryFeedback?.days_from_last_feedback > 30) &&
                pageNo === 1 &&
                abTestingGroup &&
                showUserFeedback === true &&
                (i + 1 + recommendedResultCount === 3 || recommendedResultCount + results?.length < 3) && (
                  <UserFeedback assetId={''} value={undefined} />
                )}
            </>
          ) : (searchFeedbackData?.getQueryFeedback?.already_reported === false ||
              searchFeedbackData?.getQueryFeedback?.days_from_last_feedback > 30) &&
            pageNo === 1 &&
            !abTestingGroup &&
            showUserFeedback === true &&
            (i ===
              (8 - (recommendedResultCount + 2) < 0
                ? !isCustomSourceActive && isAllSelected
                  ? 2
                  : 8
                : 8 - (recommendedResultCount + 2)) ||
              (results.length < 8 - (recommendedResultCount + 2) && results.length - 1 === i)) ? (
            <>
              <CardSearchResult
                isMobile={isMobile}
                pageNo={pageNo}
                docPosition={abTestingGroup ? i + 1 + recommendedResultCount : i + 1}
                docSolrAsset={result}
                ratingData={ratingData}
                refetch={refetch}
                applicationName={applicationName}
                key={result.dataAsset?.id}
                {...others}
              />
              <UserFeedback assetId={''} value={undefined} />
            </>
          ) : (searchFeedbackData?.getQueryFeedback?.already_reported === false ||
              searchFeedbackData?.getQueryFeedback?.days_from_last_feedback > 30) &&
            pageNo === 1 &&
            abTestingGroup &&
            showUserFeedback === true &&
            (i + 1 + recommendedResultCount === 3 || recommendedResultCount + results?.length < 3) ? (
            <>
              <CardSearchResult
                isMobile={isMobile}
                pageNo={pageNo}
                docPosition={abTestingGroup ? i + 1 + recommendedResultCount : i + 1}
                docSolrAsset={result}
                ratingData={ratingData}
                refetch={refetch}
                applicationName={applicationName}
                key={result.dataAsset?.id}
                {...others}
              />
              <UserFeedback assetId={''} value={undefined} />
            </>
          ) : (
            <CardSearchResult
              isMobile={isMobile}
              pageNo={pageNo}
              docPosition={abTestingGroup ? i + 1 + recommendedResultCount : i + 1}
              docSolrAsset={result}
              ratingData={ratingData}
              refetch={refetch}
              applicationName={applicationName}
              key={result.dataAsset?.id}
              {...others}
            />
          )
        )}
      </div>
    </ol>
  );
};

export default CardSearchResults;
