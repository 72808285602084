import { IAssetAttribute } from '@amgen/core';
import { createContext } from 'react';

import { FiltersObject, IPersistentFilters, IQuickRangeOption, MagicLineChartType } from '../../models';

export interface IFacetState {
  appliedFilters: FiltersObject;
  setAppliedFilters: (filters: FiltersObject) => void;
  facetCategories: IAssetAttribute[];
  dashboardFilters: FiltersObject;
  setDashboardFilters: (filters: FiltersObject) => void;
  selectedQuickRange: IQuickRangeOption;
  setSelectedQuickRange: (quickRange: IQuickRangeOption) => void;
  chartType: MagicLineChartType;
  setChartType: (chart: MagicLineChartType) => void;
  clickableAttributes: IAssetAttribute[];
  persistentFilters: IPersistentFilters;
  appliedFilterCategories: Set<IAssetAttribute>;
  setAppliedFilterCategories: (fields: Set<IAssetAttribute>) => void;
  addFilterCategory: (category: IAssetAttribute) => void;
  setCustomFilterSearch?: (flag: boolean) => void;
  isCustomFilterSearch?: boolean;
  setDropdownSearch?: (flag: boolean) => void;
  isDropdownSearch?: boolean;
}

export const FacetContext = createContext<IFacetState>(null!);
