import { useSaveSearchMutation, useUpdateSavedSearchMutation } from '@amgen/api';
import { ApplicationName } from '@amgen/core';
import {
  AppContext,
  FacetContext,
  MatomoContext,
  SAVING_CONFIGURATIONS_ACTIONS,
  SEARCH_APP_FEATURE_LIST,
  useCleanSearchQuery,
  useSearchQueryValue,
  useToast,
} from '@amgen/shared';
import { SOLR_REFRESH_TIME } from '@amgen/utils';
import { format } from 'date-fns';
import { useContext, useMemo, useState } from 'react';

import { NEVER_END_DATE, SaveSubscribeFormValue, SUBSCRIPTION_TYPE, TOP_RESULT_COUNT } from '../model';

export const useSaveAndSubscribe = (onSuccess: (name: string) => void) => {
  const toast = useToast();
  const query = useSearchQueryValue();
  const cleanQuery = useCleanSearchQuery();
  const { trackCustomDimensions, dimensions, trackApplicationFeature } = useContext(MatomoContext);
  const { savedSearch, currentSource } = useContext(AppContext);
  const { appliedFilters } = useContext(FacetContext);
  const [saveSearch, { loading: saveLoading }] = useSaveSearchMutation();
  const [updateSearch, { loading: updateLoading }] = useUpdateSavedSearchMutation();
  const applicationName: ApplicationName = sessionStorage.getItem('appName')
    ? ApplicationName[sessionStorage.getItem('appName')!]
    : ApplicationName.EEA;

  const convertToSOLRTimeStamp = (d?: string) => {
    return d ? format(new Date(d), 'yyyy-MM-dd') + 'T00:00:00Z' : d;
  };

  const handleSaveSearch = (values: SaveSubscribeFormValue) => {
    saveSearch({
      variables: {
        appName: applicationName,
        issubscribe: values.subscrptnIsactive,
        saveSearch: {
          ...values,
          query: cleanQuery,
          subscrptnType: values.subscrptnType as string,
          subscrptnStartDate: convertToSOLRTimeStamp(values.subscrptnStartDate),
          subscrptnEndDate: convertToSOLRTimeStamp(values.subscrptnEndDate) || undefined,
          filters: [appliedFilters.toJSONString()],
          sourceType: currentSource,
          userType: sessionStorage.getItem('employeeType') || '',
        },
      },
    })
      .then(resp => {
        toast(
          {
            type: 'success',
            message: 'Your Search has been saved Successfully',
          },
          'save-search-popup'
        );
        trackCustomDimensions(query, dimensions.SAVED_SEARCH);
        if (values.subscrptnIsactive) {
          trackApplicationFeature(
            SEARCH_APP_FEATURE_LIST.SAVING_CONFIGURATIONS,
            SAVING_CONFIGURATIONS_ACTIONS.SUBSCRIBE_SEARCH
          );
        } else {
          trackApplicationFeature(
            SEARCH_APP_FEATURE_LIST.SAVING_CONFIGURATIONS,
            SAVING_CONFIGURATIONS_ACTIONS.SAVE_SEARCH
          );
        }
        setTimeout(() => {
          if (values.name) onSuccess(values.name);
        }, SOLR_REFRESH_TIME);
      })
      .catch(err => {
        const message = err?.graphQLErrors?.[0]?.exception?.message ?? 'Save search failed';
        toast(
          {
            type: 'danger',
            message,
          },
          'save-search-popup'
        );
      });
  };

  const handleUpdate = (values: SaveSubscribeFormValue) => {
    updateSearch({
      variables: {
        appName: applicationName,
        issubscribe: true,
        saveSearch: {
          ...values,
          name: values.name,
          query: cleanQuery,
          id: savedSearch?.id,
          subscrptnType: values.subscrptnType as string,
          subscrptnStartDate: convertToSOLRTimeStamp(values.subscrptnStartDate),
          subscrptnEndDate: convertToSOLRTimeStamp(values.subscrptnEndDate) || undefined,
          filters: [appliedFilters.toJSONString()],
          sourceType: currentSource,
          userType: sessionStorage.getItem('employeeType') || '',
        },
      },
    })
      .then(resp => {
        toast(
          {
            type: 'success',
            message: 'Your Search has been updated successfully',
          },
          'save-search-popup'
        );
        setTimeout(() => {
          if (values.name) onSuccess(values.name);
          // if (resp.data?.updateSavedSearch) {
          //   setSavedSearch(resp.data.updateSavedSearch);
          // }
        }, SOLR_REFRESH_TIME);
      })
      .catch(err => {
        const message = err?.graphQLErrors?.[0]?.exception?.message ?? 'Save search failed';
        toast(
          {
            type: 'danger',
            message,
          },
          'save-search-popup'
        );
      });
  };

  return { handleSaveSearch, handleUpdate, saveLoading, updateLoading };
};

export const useInitialValuesForSaveSubscribe = (emailId: string) => {
  const { savedSearch } = useContext(AppContext);
  const [isSubscribe, setIsSubscribe] = useState(false);

  const query = useSearchQueryValue();
  const todayString = format(new Date(), 'yyyy-MM-dd') + 'T00:00:00Z';

  const initialValue: SaveSubscribeFormValue = useMemo(() => {
    return savedSearch?.id
      ? {
          id: savedSearch.id,
          name: savedSearch.name,
          query: savedSearch.query,
          filters: savedSearch.filters,
          subscrptnIsactive: !!savedSearch.subscrptnIsactive,
          subscrptnMailIds: savedSearch.subscrptnMailIds ?? (emailId ? [emailId] : []),
          subscrptnRecordCount: savedSearch.subscrptnRecordCount
            ? savedSearch.subscrptnRecordCount
            : TOP_RESULT_COUNT[0],
          subscrptnType: (savedSearch.subscrptnType as SUBSCRIPTION_TYPE) ?? SUBSCRIPTION_TYPE.Daily,
          subscrptnStartDate: savedSearch.subscrptnStartDate
            ? new Date(savedSearch.subscrptnStartDate).toLocaleDateString()
            : todayString,
          subscrptnEndDate: savedSearch.subscrptnEndDate
            ? new Date(savedSearch.subscrptnEndDate).toLocaleDateString()
            : NEVER_END_DATE,
        }
      : {
          name: '',
          query,
          subscrptnIsactive: isSubscribe,
          subscrptnStartDate: todayString,
          subscrptnEndDate: NEVER_END_DATE,
          subscrptnType: SUBSCRIPTION_TYPE.Daily,
          subscrptnMailIds: emailId ? [emailId] : [],
          subscrptnRecordCount: TOP_RESULT_COUNT[0],
        };
  }, [query, savedSearch, todayString, isSubscribe, emailId]);

  return { initialValue, setIsSubscribe, isSubscribe };
};
