import * as Types from '@amgen/core';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ISurveyFragment = Pick<
  Types.IDataAsset,
  | 'account_name'
  | 'content_source'
  | 'id'
  | 'question'
  | 'question_order'
  | 'question_response'
  | 'question_text'
  | 'survey_name'
  | 'survey_id_account_id'
>;

export type IGetSurveysQueryVariables = Types.Exact<{
  appName?: Types.Maybe<Types.ApplicationName>;
  query: Types.Scalars['String'];
  filters?: Types.Maybe<Array<Types.Maybe<Types.Scalars['String']>> | Types.Maybe<Types.Scalars['String']>>;
  start?: Types.Maybe<Types.Scalars['Int']>;
  page?: Types.Maybe<Types.Scalars['Int']>;
  count?: Types.Maybe<Types.Scalars['Int']>;
  sort?: Types.Maybe<Types.SortField>;
  order?: Types.Maybe<Types.Direction>;
  fieldName: Types.Scalars['String'];
  groupLimit?: Types.Maybe<Types.Scalars['Int']>;
  addPersonalizationDetails?: Types.Maybe<Types.Scalars['Boolean']>;
  solrHandler?: Types.Maybe<Types.Scalars['String']>;
}>;

export type IGetSurveysQuery = {
  groupByField?: Types.Maybe<
    Pick<Types.IDataAssetCollection, 'resultCount' | 'QTime'> & {
      docs?: Types.Maybe<Array<Types.Maybe<{ dataAsset?: Types.Maybe<ISurveyFragment> }>>>;
    }
  >;
};

export const SurveyFragmentDoc = gql`
  fragment Survey on DataAsset {
    account_name
    content_source
    id
    question
    question_order
    question_response
    question_text
    survey_name
    survey_id_account_id
  }
`;
export const GetSurveysDocument = gql`
  query GetSurveys(
    $appName: ApplicationName
    $query: String!
    $filters: [String]
    $start: Int = 0
    $page: Int
    $count: Int
    $sort: SortField
    $order: Direction
    $fieldName: String!
    $groupLimit: Int = 1
    $addPersonalizationDetails: Boolean
    $solrHandler: String = "/search-select"
  ) {
    groupByField(
      applicationName: $appName
      query: $query
      filters: $filters
      start: $start
      page: $page
      count: $count
      sort: $sort
      order: $order
      fieldName: $fieldName
      groupLimit: $groupLimit
      solrHandler: $solrHandler
      addPersonalizationDetails: $addPersonalizationDetails
    ) {
      resultCount
      QTime
      docs {
        dataAsset {
          ...Survey
        }
      }
    }
  }
  ${SurveyFragmentDoc}
`;
export function useGetSurveysQuery(baseOptions: Apollo.QueryHookOptions<IGetSurveysQuery, IGetSurveysQueryVariables>) {
  return Apollo.useQuery<IGetSurveysQuery, IGetSurveysQueryVariables>(GetSurveysDocument, baseOptions);
}
export function useGetSurveysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetSurveysQuery, IGetSurveysQueryVariables>
) {
  return Apollo.useLazyQuery<IGetSurveysQuery, IGetSurveysQueryVariables>(GetSurveysDocument, baseOptions);
}
export type GetSurveysQueryHookResult = ReturnType<typeof useGetSurveysQuery>;
export type GetSurveysLazyQueryHookResult = ReturnType<typeof useGetSurveysLazyQuery>;
export type GetSurveysQueryResult = Apollo.QueryResult<IGetSurveysQuery, IGetSurveysQueryVariables>;
