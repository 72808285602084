import { Dispatch } from 'react';

// copied from bootstrap's Alert types
export type AlertMessageType = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light';

export const alertMechanism = (msg: string, setFunc: Dispatch<any>, t = 2000) => {
  setFunc(msg);
  setTimeout(() => {
    setFunc('');
  }, t);
};
