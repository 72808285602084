import {
  IGetCrawlRequestsQueryVariablesCopy,
  IGetDestinationsQueryVariablesCopy,
  useGetCrawlRequestsQuery,
  useGetDestinationsQuery,
} from '@amgen/api';
import { useUsersNames } from '@amgen/auth';
import { Paginator, ResultsPerPageDropdown } from '@amgen/components';
import {
  DEFAULT_SEARCH_QUERY,
  IDataCrawlRequest,
  PageType,
  RequestStatusType,
  SortField,
  useEnvironment,
} from '@amgen/core';
import {
  IGD_ACTIONS,
  MatomoContext,
  PAGES,
  SEARCH_APP_FEATURE_LIST,
  SearchContext,
  usePageTrackingWithTitle,
  useWindowSizeListener,
} from '@amgen/shared';
import classNames from 'classnames';
import React, { useContext, useState } from 'react';
import { Button, Col, Nav, Row } from 'react-bootstrap';
import { NavLink, useHistory } from 'react-router-dom';

import './admin-page.scss';

import { AdminRequestTable, ExportRequestsButton, RequestDetailPopup, RequestSearchBox } from '../../components';
import RequestStatusTab from '../../components/request-status-tab/request-status-tab';
import SuggestedAppModal from '../../components/suggested-apps/suggested-app-modal/suggested-app-modal';
import SuggestedAppTable from '../../components/suggested-apps/suggested-app-table/suggested-app-table';
import { POLL_INTERVAL } from '../../config/constants';
import { SortOrderObject } from '../../models/sort';

/* eslint-disable-next-line */
export interface AdminPageProps {}

export const AdminPage = (props: AdminPageProps) => {
  const { trackApplicationFeature } = useContext(MatomoContext);
  const { conversationalQnAEngaged, results } = useContext(SearchContext);
  const [currentRequest, setCurrentRequest] = useState<IDataCrawlRequest | null>(null);
  const { appName } = useEnvironment();
  const [open, setOpen] = useState(false);
  const [isApproval, setIsApproval] = useState(false);
  const [query, setQuery] = useState('');
  const { fullName } = useUsersNames();
  const [requestStatus, setRequestStatus] = useState(RequestStatusType.PENDING);
  const [count, setCount] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [rowData, setRowData] = useState('');
  const [suggestedAppModalMode, setSuggestedAppModalMode] = useState('add');
  const { push } = useHistory();

  const defaultSort = (s?: RequestStatusType) => {
    const sort = s || requestStatus;
    return sort === RequestStatusType.PENDING
      ? new SortOrderObject().toggleSort(SortField.CREATEDON)
      : new SortOrderObject().toggleSort(SortField.REVIEWEDON);
  };

  const [sortObject, setSortObject] = useState(defaultSort());

  usePageTrackingWithTitle(PAGES.ADMIN_PAGE);

  const variables = {
    type: PageType.IGD_ADMIN,
    query: query || DEFAULT_SEARCH_QUERY,
    sortOrder: sortObject.toSortOrder(),
    appName,
    requestStatus,
    count,
    page: pageNo - 1,
    start: 0,
  };

  const { data, loading, refetch, stopPolling, startPolling, error } = useGetCrawlRequestsQuery({
    variables,
    pollInterval: POLL_INTERVAL,
    fetchPolicy: 'no-cache',
  });
  const {
    data: tabledata,
    loading: tableloading,
    refetch: refetchSuggestedDestinations,
    startPolling: startPollingSuggestedDestinations,
    stopPolling: stopPollingSuggestedDestinations,
  } = useGetDestinationsQuery({
    variables,
    pollInterval: POLL_INTERVAL,
    fetchPolicy: 'no-cache',
  });

  const isMobile = useWindowSizeListener();
  const pageOptionsCount = Math.min(Math.ceil((data?.getContentOnboarding?.resultCount ?? 0) / 10), isMobile ? 2 : 5);

  const refreshStatus = (s: RequestStatusType) => {
    setRequestStatus(s);
    setPageNo(1);
    setSortObject(defaultSort(s));
    restartPolling({ ...variables, requestStatus: s, page: 0, sortOrder: defaultSort(s).toSortOrder() });
  };

  const restartPolling = (v: IGetCrawlRequestsQueryVariablesCopy) => {
    stopPolling();
    refetch(v);
    startPolling(POLL_INTERVAL);
  };
  const restartSuggestedDestinationsPolling = (v: IGetDestinationsQueryVariablesCopy) => {
    stopPollingSuggestedDestinations();
    refetchSuggestedDestinations(v);
    startPollingSuggestedDestinations(POLL_INTERVAL);
  };

  const onCountChange = (c: number) => {
    setCount(c);
    setPageNo(1);
    restartPolling({ ...variables, page: 0, count: c });
  };
  const onSuggestedDestinationsCountChange = (c: number) => {
    setCount(c);
    setPageNo(1);
    restartSuggestedDestinationsPolling({ ...variables, page: 0, count: c });
  };

  const tabs = [
    { title: 'User Requests', tabName: 'user' },
    { title: 'Suggested Apps/Destinations', tabName: 'apps' },
  ];
  const [tabName, setTabName] = useState('user');
  const [show, setShow] = useState(false);

  return (
    <div>
      <Nav className="ag-navbar align-items-center border-bottom-0" variant="tabs">
        {tabs.map(tab => (
          <Nav.Item className="text-center d-md-block" key={tab.title}>
            <NavLink
              to="/data-requests/admin"
              className="nav-link h2 mb-0"
              activeClassName={tabName === tab.tabName ? 'active' : 'inactive-background'}
              onClick={() => setTabName(tab.tabName)}
            >
              {/* <FontAwesomeIcon icon={tab.icon} /> */}
              <span className={classNames('pl-2', { 'fs-13': isMobile })}>{tab.title}</span>
            </NavLink>
          </Nav.Item>
        ))}
      </Nav>
      {tabName === 'user' ? (
        <Row noGutters className="px-3 pt-3">
          <Col
            xs="12"
            className={classNames('d-flex align-items-center justify-content-between', { 'pb-2': isMobile })}
          >
            <h1 className="d-flex flex-column">
              <span className={classNames('mb-1', { 'fs-16': isMobile })}>All User's Data Requests</span>
              <small className={classNames('text-grey', { 'fs-14': isMobile })}>
                View all requests submitted by users
              </small>
            </h1>
            <ExportRequestsButton pageType={PageType.IGD_ADMIN} />
          </Col>

          <Col xs="12" lg="3" className="mb-2">
            <RequestSearchBox
              placeholder="Search for Data Source, Site URL, Space Name"
              query={query}
              onSearch={setQuery}
              isMobile={isMobile}
            />
          </Col>

          <Col
            xs="12"
            lg={{ span: 6, offset: 3 }}
            className="d-flex align-items-center justify-content-between justify-content-lg-end mb-2"
          >
            <RequestStatusTab
              className="mr-lg-2"
              loading={loading}
              status={requestStatus}
              onChange={refreshStatus}
              resultCount={data?.getContentOnboarding?.resultCount}
            />
            {isMobile ? '' : <ResultsPerPageDropdown className="py-2" option={count} onChange={onCountChange} />}
          </Col>

          <Col xs="12">
            <>
              <AdminRequestTable
                data={data?.getContentOnboarding?.requests ?? []}
                status={requestStatus}
                loading={loading}
                sort={sortObject}
                setSort={setSortObject}
                onSelect={(req, isApprove) => {
                  setCurrentRequest(req);
                  setOpen(true);
                  setIsApproval(!!isApprove);
                }}
              />
              <Paginator
                results={results}
                itemsPerPage={count}
                onPageSelect={p => {
                  setPageNo(p);

                  restartPolling({ ...variables, page: p - 1 });
                  trackApplicationFeature(SEARCH_APP_FEATURE_LIST.IGD, IGD_ACTIONS.IGD_PAGINATE);
                }}
                pageNo={pageNo}
                pageOptionsCount={pageOptionsCount}
                totalItems={data?.getContentOnboarding?.resultCount ?? 0}
                conversationalQnAEngaged={conversationalQnAEngaged}
              />
            </>
          </Col>

          <RequestDetailPopup
            show={open}
            isApproval={isApproval}
            request={currentRequest}
            onHide={() => {
              setOpen(false);
              setIsApproval(false);
              setCurrentRequest(null);
            }}
            onAction={() => restartPolling({ ...variables, page: 0 })}
            userName={fullName}
          />
        </Row>
      ) : (
        ''
      )}
      {tabName === 'apps' ? (
        <Row noGutters className="px-3 pt-3">
          <Col
            xs="12"
            className={classNames('d-flex align-items-center justify-content-between', { 'pb-2': isMobile })}
          >
            <h1 className="d-flex flex-column">
              <span className={classNames('mb-1', { 'fs-16': isMobile })}>All Requests for Suggested Destinations</span>
              <small className={classNames('text-grey', { 'fs-14': isMobile })}>View all requests</small>
            </h1>
            <Button
              variant="outline-primary"
              className={classNames('mx-3 ', { 'd-flex': isMobile })}
              style={{ float: 'right' }}
              size="sm"
              onClick={() => {
                setShow(true);
                setSuggestedAppModalMode('add');
              }}
            >
              <span className="ml-2">Add Suggested Apps/Destinations</span>
            </Button>
          </Col>

          <Col xs="12" lg="3" className="mb-2">
            <RequestSearchBox
              placeholder="Search for Title, Description and Destination URL"
              query={query}
              onSearch={setQuery}
              isMobile={isMobile}
            />
          </Col>
          <Col
            xs="12"
            lg={{ span: 6, offset: 3 }}
            className="d-flex align-items-center justify-content-between justify-content-lg-end mb-2"
          >
            {isMobile ? (
              ''
            ) : (
              <>
                <ExportRequestsButton pageType={PageType.IGD_ADMIN} page="suggested" />
                <ResultsPerPageDropdown
                  className="py-2"
                  option={count}
                  onChange={onSuggestedDestinationsCountChange}
                  style={{ float: 'right' }}
                />
              </>
            )}
          </Col>
          <Col xs="12">
            <>
              <SuggestedAppTable
                setShow={setShow}
                data={tabledata?.getDestinations?.requests ?? []}
                loading={tableloading}
                setRowData={setRowData}
                setSuggestedAppModalMode={setSuggestedAppModalMode}
                refetch={refetchSuggestedDestinations}
              />
              <Paginator
                results={results}
                itemsPerPage={count}
                onPageSelect={p => {
                  setPageNo(p);

                  restartPolling({ ...variables, page: p - 1 });
                  trackApplicationFeature(SEARCH_APP_FEATURE_LIST.IGD, IGD_ACTIONS.IGD_PAGINATE);
                }}
                pageNo={pageNo}
                pageOptionsCount={pageOptionsCount}
                totalItems={tabledata?.getDestinations?.resultCount ?? 0}
                conversationalQnAEngaged={conversationalQnAEngaged}
              />
            </>
          </Col>

          <SuggestedAppModal
            show={show}
            onHide={() => setShow(false)}
            onSuccess={() => push('data-requests/my-requests')}
            data={rowData}
            mode={suggestedAppModalMode}
            refetch={refetchSuggestedDestinations}
          />
        </Row>
      ) : (
        ''
      )}
    </div>
  );
};

export default AdminPage;
