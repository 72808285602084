import { FacetContext } from '@amgen/shared';
import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';

import './search-box-search-button.scss';

import { SearchBoxContext } from '../../search-box-context-provider/search-box-context';

/* eslint-disable-next-line */
export interface SearchBoxSearchButtonProps {}

export const SearchBoxSearchButton: React.FC<SearchBoxSearchButtonProps> = ({ children }) => {
  const {
    isSearchExpanded,
    setIsSearchExpanded = () => {},
    showAdvanceSearch,
    applyFilter,
    searchQuery,
    onSearch,
    setReusedQuery,
    onExternalSearch,
    selectedTypeaheadFilters,
    selectedTypeaheadFilterCategories,
    typeaheadRef,
  } = useContext(SearchBoxContext);

  const { setAppliedFilterCategories, setAppliedFilters, appliedFilterCategories } = useContext(FacetContext);
  const moreThanOneChar = () => {
    return typeaheadRef.current?.getInput().value.trim().length < 2;
  };

  return (
    <Button
      variant="outline-secondary"
      className={
        moreThanOneChar()
          ? 'search-button-disabled d-flex align-items-center'
          : 'search-button d-flex align-items-center'
      }
      onClick={() => {
        if (isSearchExpanded) {
          setAppliedFilters(selectedTypeaheadFilters);
          setAppliedFilterCategories(new Set([...appliedFilterCategories, ...selectedTypeaheadFilterCategories]));
          if (showAdvanceSearch) {
            applyFilter(typeaheadRef.current?.getInput().value.trim());
          } else {
            onSearch(typeaheadRef.current?.getInput().value.trim(), '');
            setReusedQuery(true);
            onExternalSearch?.(typeaheadRef.current?.getInput().value.trim(), selectedTypeaheadFilters);
          }
        } else {
          setIsSearchExpanded(true);
        }
      }}
      style={{ border: 'none' }}
      disabled={typeaheadRef.current?.getInput().value.trim().length < 2}
      title={
        typeaheadRef.current?.getInput().value.trim().length < 2
          ? 'Please enter minimum two characters to perform search'
          : ''
      }
    >
      <img src="assets/images/Search.svg" width={'16px'} />
    </Button>
  );
};

export default SearchBoxSearchButton;
