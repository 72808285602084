import { Direction, SortField } from '@amgen/core';
import { AppContext, SearchContext } from '@amgen/shared';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { Table } from 'react-bootstrap';

import './table-search-results.scss';

import { IResultViewProps } from '../../models';
import TableSearchResultRow from '../table-search-result-row/table-search-result-row';

export const TableSearchResults: React.FC<IResultViewProps> = props => {
  const { attributeDisplayNames, pageNo, setPageNo } = useContext(AppContext);
  const { sortOption, sortOrder, setSortOrder, onPageSort, expandPanel, isSearchAPIcompleted, results } = useContext(
    SearchContext
  );
  const legalColumns = ['contract_parties', 'contract_title', 'contract_effective_date', 'extracted_amg_drugs'];
  const legal = new Set(legalColumns);
  const [sortableFields] = ['contract_effective_date', 'title'];

  const sortColumn = (attribute: string, order: Direction) => {
    const sortfield = attribute === 'title' ? SortField.TITLE : SortField.CONTRACT_EFFECTIVE_DATE;
    onPageSort(sortfield as SortField);
    setSortOrder(order);
    setPageNo(1);
  };
  console.log(isSearchAPIcompleted, results, 'loading table');
  return (
    <>
      {/* <AddColumnDropdown
        selectedColumns={props.selectedColumns}
        onColumnToggle={column => {
          if (props.selectedColumns.has(column)) {
            props.selectedColumns.delete(column);
          } else {
            props.selectedColumns.add(column);
          }
          props.onSelectedColumnsChange(new Set(props.selectedColumns));
        }}
      /> */}

      <Table responsive className="table-search-results position-relative">
        <thead>
          <tr>
            <th className="position-sticky fixed-column p-0 shadow-sm fixed-head" scope="col">
              <div className="border-right border-left d-flex w-100 " style={{ padding: '0.75rem' }}>
                {attributeDisplayNames.title}
                {(sortOption === SortField.TITLE && sortOrder === Direction.DESC) ||
                sortOption === SortField.RELEVANCY ||
                sortOption === SortField.MODIFIED_DATE ||
                sortOption === SortField.CONTRACT_EFFECTIVE_DATE ? (
                  <div className="sort-order cursor-pointer table-sort-icon table-up-arrow pl-2">
                    <FontAwesomeIcon
                      title="Sort by Ascending Order"
                      icon={faCaretUp}
                      onClick={() => sortColumn('title', Direction.ASC)}
                    />
                  </div>
                ) : (
                  <div className="sort-order table-sort-icon-disabled table-up-arrow pl-2">
                    <FontAwesomeIcon title="Currently sorted by ascending order" icon={faCaretUp} />
                  </div>
                )}
                {(sortOption === SortField.TITLE && sortOrder === Direction.ASC) ||
                sortOption === SortField.RELEVANCY ||
                sortOption === SortField.MODIFIED_DATE ||
                sortOption === SortField.CONTRACT_EFFECTIVE_DATE ? (
                  <div className="sort-order cursor-pointer table-sort-icon table-down-arrow pl-2">
                    <FontAwesomeIcon
                      title="Sort by Descending Order"
                      icon={faCaretDown}
                      onClick={() => sortColumn('title', Direction.DESC)}
                    />
                  </div>
                ) : (
                  <div className="sort-order table-sort-icon-disabled table-down-arrow pl-2">
                    <FontAwesomeIcon title="Currently sorted by descending order" icon={faCaretDown} />
                  </div>
                )}
              </div>
            </th>
            {/*
            {[...props.selectedColumns].map(attribute => (
              <th key={attribute} className="border-right shadow-sm fixed-head" scope="col">
                {attributeDisplayNames[attribute]}
              </th>
            ))} */}
            {[...legal].map(attribute => (
              <th key={attribute} className="border-right shadow-sm fixed-head" scope="col">
                {attributeDisplayNames[attribute]}
                {!sortableFields.indexOf(attribute) ? (
                  <>
                    {(sortOption === SortField.CONTRACT_EFFECTIVE_DATE && sortOrder === Direction.DESC) ||
                    sortOption === SortField.RELEVANCY ||
                    sortOption === SortField.MODIFIED_DATE ||
                    sortOption === SortField.TITLE ? (
                      <div className="sort-order table-sort-icon table-up-arrow cursor-pointer pl-2">
                        <FontAwesomeIcon
                          title="Sort by Ascending Order"
                          icon={faCaretUp}
                          onClick={() => sortColumn(attribute, Direction.ASC)}
                        />
                      </div>
                    ) : (
                      <div className="sort-order table-sort-icon-disabled table-up-arrow pl-2">
                        <FontAwesomeIcon title="Currently sorted by ascending order" icon={faCaretUp} />
                      </div>
                    )}
                    {(sortOption === SortField.CONTRACT_EFFECTIVE_DATE && sortOrder === Direction.ASC) ||
                    sortOption === SortField.RELEVANCY ||
                    sortOption === SortField.MODIFIED_DATE ||
                    sortOption === SortField.TITLE ? (
                      <div className="sort-order table-sort-icon table-down-arrow cursor-pointer pl-2">
                        <FontAwesomeIcon
                          title="Sort by Descending Order"
                          icon={faCaretDown}
                          onClick={() => sortColumn(attribute, Direction.DESC)}
                        />
                      </div>
                    ) : (
                      <div className="sort-order table-sort-icon-disabled table-down-arrow pl-2">
                        <FontAwesomeIcon title="Currently sorted by descending order" icon={faCaretDown} />
                      </div>
                    )}
                  </>
                ) : (
                  ''
                )}
              </th>
            ))}
          </tr>
        </thead>

        {isSearchAPIcompleted ? (
          props.results.length > 0 ? (
            <tbody className="border-bottom">
              {props.results.map((result, i) => (
                <TableSearchResultRow
                  key={result.dataAsset?.id!}
                  docSolrAsset={result}
                  docPosition={i}
                  isBookmarksDisabled={props.isBookmarksDisabled}
                  hideRatingBtn={props.hideRatingBtn}
                  selectedColumns={props.selectedColumns}
                />
              ))}
            </tbody>
          ) : (
            <tbody>
              <div
                className={
                  expandPanel
                    ? 'd-flex justify-content-center align-items-center row  loader-position-search-result-page-with-panel'
                    : 'd-flex justify-content-center align-items-center row  loader-position-search-result-page'
                }
              >
                <div className="col-12 text-center text-primary mt-1">No Data</div>
              </div>
            </tbody>
          )
        ) : (
          <tbody>
            <div
              className={
                expandPanel
                  ? 'd-flex justify-content-center align-items-center row  loader-position-search-result-page-with-panel'
                  : 'd-flex justify-content-center align-items-center row  loader-position-search-result-page'
              }
            >
              <div className="dot-flashing"></div>
              <br />
              <br />
              <div className="col-12 text-center text-primary mt-1">Fetching search results...</div>
            </div>
          </tbody>
        )}
      </Table>
    </>
  );
};

export default TableSearchResults;
