import { DataSource } from '@amgen/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faExternalLinkSquareAlt, faFilter, faGlobe, faVial } from '@fortawesome/free-solid-svg-icons';

export enum LANDING_PAGE_DATA_SOURCE_CATEGORY {
  ALL = 'All Sources',
  APPS = 'Custom Sources',
  INTERNAL_SOURCES = 'Amgen Sources',
  EXTERNAL_SOURCES = 'External Sources',
}

export const LANDING_PAGE_DATA_SOURCE_CATEGORIES: LANDING_PAGE_DATA_SOURCE_CATEGORY[] = [
  LANDING_PAGE_DATA_SOURCE_CATEGORY.ALL,
  LANDING_PAGE_DATA_SOURCE_CATEGORY.APPS,
  LANDING_PAGE_DATA_SOURCE_CATEGORY.INTERNAL_SOURCES,
  LANDING_PAGE_DATA_SOURCE_CATEGORY.EXTERNAL_SOURCES,
];

export interface IDataSourceHeaderCategory {
  name: LANDING_PAGE_DATA_SOURCE_CATEGORY;
  icon: IconProp;
}

export const categories: IDataSourceHeaderCategory[] = [
  { name: LANDING_PAGE_DATA_SOURCE_CATEGORY.ALL, icon: faGlobe },
  { name: LANDING_PAGE_DATA_SOURCE_CATEGORY.INTERNAL_SOURCES, icon: faVial },
  { name: LANDING_PAGE_DATA_SOURCE_CATEGORY.EXTERNAL_SOURCES, icon: faExternalLinkSquareAlt },
  { name: LANDING_PAGE_DATA_SOURCE_CATEGORY.APPS, icon: faFilter },
];

export const ABcategories: IDataSourceHeaderCategory[] = [
  { name: LANDING_PAGE_DATA_SOURCE_CATEGORY.ALL, icon: faGlobe },
  { name: LANDING_PAGE_DATA_SOURCE_CATEGORY.APPS, icon: faFilter },
];

export const ALL_DATA_SOURCES: DataSource[] = [
  DataSource.TWITTER,
  DataSource.AMGEN_RIM,
  DataSource.AMGEN_WEBSITES,
  DataSource.CDOCS,
  DataSource.CONFLUENCE,
  DataSource.CORTELLIS,
  DataSource.PUBMED,
  DataSource.SMARTSHEET,
  DataSource.SHAREPOINT,
];

export const INTERNAL_DATA_SOURCES: DataSource[] = [
  DataSource.AMGEN_RIM,
  DataSource.AMGEN_WEBSITES,
  DataSource.CDOCS,
  DataSource.SMARTSHEET,
  DataSource.CONFLUENCE,
  DataSource.SHAREPOINT,
];

export const EXTERNAL_DATA_SOURCES: DataSource[] = [DataSource.TWITTER, DataSource.CORTELLIS, DataSource.PUBMED];

export const filteredDataSources = new Map<LANDING_PAGE_DATA_SOURCE_CATEGORY, DataSource[]>([
  [LANDING_PAGE_DATA_SOURCE_CATEGORY.ALL, ALL_DATA_SOURCES],
  [LANDING_PAGE_DATA_SOURCE_CATEGORY.INTERNAL_SOURCES, INTERNAL_DATA_SOURCES],
  [LANDING_PAGE_DATA_SOURCE_CATEGORY.EXTERNAL_SOURCES, EXTERNAL_DATA_SOURCES],
]);
