import React from 'react';

import './content-source-items.module.scss';

import { FacetDropdownItemsProps } from '../facet-dropdown-items/facet-dropdown-items';
import LazyFacetTree from '../lazy-facet-tree/lazy-facet-tree';

/* eslint-disable-next-line */
export interface ContentSourceItemsProps {}

export function ContentSourceItems(props: FacetDropdownItemsProps) {
  return (
    <div className="overflow-auto thin-scrollbar" style={{ maxHeight: '300px' }}>
      <LazyFacetTree category={props.category} showMore={props.showMore} prefix="0/" root />
    </div>
  );
}

export default ContentSourceItems;
