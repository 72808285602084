import { IRoadmapFeatureFragmentCopy, useLikeRoadmapFeatureMutation } from '@amgen/api';
import { MatomoContext, ROADMAP_ACTIONS, SEARCH_APP_FEATURE_LIST } from '@amgen/shared';
import { faCommentDots, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import { faThumbsUp as solidFaThumbsUp } from '@fortawesome/free-solid-svg-icons';
import React, { useContext, useState } from 'react';
import { Spinner } from 'react-bootstrap';

import './roadmap-feature-metric-box.scss';

import { RoadmapContext, ThemeContext } from '../../contexts';
import MetricIcon from '../metric-icon/metric-icon';

/* eslint-disable-next-line */
export interface RoadmapFeatureMetricBoxProps extends IRoadmapFeatureFragmentCopy {
  setShow: (show: boolean) => void;
}

export function RoadmapFeatureMetricBox(props: RoadmapFeatureMetricBoxProps) {
  const { trackApplicationFeature } = useContext(MatomoContext);
  const { color } = useContext(ThemeContext);

  const {
    featureIdCommentCountMap,
    featureIdLikeCountMap,
    featureIdUserLikesMap: userLikes,
    refetchUserMetrics,
  } = useContext(RoadmapContext);

  const [addLike, { loading }] = useLikeRoadmapFeatureMutation();
  const abTestingGroup = JSON.parse(sessionStorage.getItem('abTestingGroup') as string);

  return (
    <div className="roadmap-feature-metric-box d-flex flex-wrap justify-content-around align-items-center py-1 m-1 roadmap-custom-rounded overflow-auto thin-scrollbar">
      {!abTestingGroup ? (
        loading ? (
          <Spinner animation="border" size="sm" color={color} />
        ) : (
          <MetricIcon
            icon={userLikes.get(props.id) ? solidFaThumbsUp : faThumbsUp}
            count={featureIdLikeCountMap.get(props.id)}
            parentclassname="d-none d-lg-block mr-1"
            className={userLikes.get(props.id) ? 'text-primary' : 'text-squid'}
            onClick={() => {
              trackApplicationFeature(SEARCH_APP_FEATURE_LIST.ROADMAP, ROADMAP_ACTIONS.LIKE_FROM_CHART);
              addLike({
                variables: {
                  parentId: props.id,
                },
              })
                .then(refetchUserMetrics)
                .catch(error => console.log(error));
            }}
          />
        )
      ) : (
        <></>
      )}
      <MetricIcon
        icon={faCommentDots}
        parentclassname="d-none d-lg-block mr-1"
        count={featureIdCommentCountMap.get(props.id)}
        className="text-squid"
        onClick={() => {
          props.setShow(true);
          trackApplicationFeature(SEARCH_APP_FEATURE_LIST.ROADMAP, ROADMAP_ACTIONS.VIEW_POPUP_COMMENT);
        }}
      />
      {/* <MetricIcon
        icon={faEllipsisH}
        className="text-squid"
        onClick={() => {
          props.setShow(true);
          trackApplicationFeature(SEARCH_APP_FEATURE_LIST.ROADMAP, ROADMAP_ACTIONS.VIEW_POPUP_MORE_ICON);
        }}
      /> */}
    </div>
  );
}

export default RoadmapFeatureMetricBox;
