import classnames from 'classnames';
import React, { useContext } from 'react';
import { InputGroup } from 'react-bootstrap';

import './search-box-input-group-append.scss';

import { SearchBoxContext } from '../../search-box-context-provider/search-box-context';

/* eslint-disable-next-line */
export interface SearchBoxInputGroupAppendProps {}

export const SearchBoxInputGroupAppend: React.FC<SearchBoxInputGroupAppendProps> = ({ children }) => {
  const { showAdvanceSearch } = useContext(SearchBoxContext);

  return (
    <InputGroup.Append
      className={classnames('advance-search-icon advance-search-append', {
        'non-advance-search': !showAdvanceSearch,
      })}
    >
      {children}
    </InputGroup.Append>
  );
};

export default SearchBoxInputGroupAppend;
