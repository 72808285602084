import { IUserInfo } from '@amgen/core';
import { AppContext } from '@amgen/shared';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Accordion, Card, Form, Modal } from 'react-bootstrap';

import './save-subscribe-modal.scss';

import { useInitialValuesForSaveSubscribe, useSaveAndSubscribe } from '../../hooks';
import { SaveSubscribeFormValue, validate } from '../../model';
import SaveSearchBtnGroup from '../save-search-button-group/save-search-button-group';
import SaveSearchDetails from '../save-search-details/save-search-details';
import SubscribeSearchDetails from '../subscribe-search-details/subscribe-search-details';

export interface SaveSubscribeModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onSuccess: (name: string) => void;
  user: IUserInfo;
  hideUpdateBtn?: boolean;
}

export const SaveSubscribeModal: React.FC<SaveSubscribeModalProps> = props => {
  const { savedSearch } = useContext(AppContext);
  const { initialValue, setIsSubscribe, isSubscribe } = useInitialValuesForSaveSubscribe(props.user.emailId);
  const { handleSaveSearch, handleUpdate, saveLoading, updateLoading } = useSaveAndSubscribe(props.onSuccess);

  const [detailAccordionKey, setDetailAccordionKey] = useState('0');
  const [subscribeAccordionKey, setSubscribeAccordionKey] = useState('0');

  useEffect(() => {
    if (savedSearch) {
      setIsSubscribe(savedSearch.subscrptnIsactive ?? false);
    }
  }, [savedSearch, setIsSubscribe]);

  const handleCancel = () => {
    props.onCancel();
  };

  return (
    <Modal className="save-subscribe-modal mt-0" show={props.isOpen} onHide={handleCancel} centered size="lg" animation>
      <Modal.Header closeButton className="border-0 save-subscribe-modal-header">
        <Modal.Title as="h1" className="mb-0">
          {!isSubscribe ? 'Save Search' : 'Save & Subscribe Search'}
        </Modal.Title>
      </Modal.Header>
      <Formik<SaveSubscribeFormValue>
        initialValues={initialValue}
        onSubmit={values => {
          if (Object.keys(validate(values)).includes('name')) {
            setDetailAccordionKey('0');
          } else if (Object.keys(validate(values)).length) {
            setSubscribeAccordionKey('0');
          }
        }}
        validate={validate}
      >
        {({ values, handleSubmit, isValid }) => (
          <>
            <Modal.Body className="pt-3 save-subscribe-modal-body">
              <div
                className="thin-scrollbar px-2"
                style={{ maxHeight: '76vh', overflowX: 'hidden', overflowY: 'auto' }}
              >
                <Form className="container px-0" noValidate>
                  <Accordion
                    defaultActiveKey="0"
                    className="mb-1"
                    activeKey={detailAccordionKey}
                    onSelect={(e: any) => {
                      setDetailAccordionKey(e);
                    }}
                  >
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="0" className="h2 d-flex justify-content-between">
                        Details
                        <FontAwesomeIcon icon={detailAccordionKey ? faChevronUp : faChevronDown} />
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="0">
                        <SaveSearchDetails
                          onToggleSubscribe={t => {
                            setIsSubscribe(t);
                            setSubscribeAccordionKey('0');
                          }}
                        />
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                  {isSubscribe && (
                    <Accordion
                      defaultActiveKey="0"
                      activeKey={subscribeAccordionKey}
                      onSelect={(e: any) => {
                        setSubscribeAccordionKey(e);
                      }}
                    >
                      <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="0" className="h2 d-flex justify-content-between">
                          Subscribe to Email Notifications
                          <FontAwesomeIcon icon={subscribeAccordionKey ? faChevronUp : faChevronDown} />
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                          <SubscribeSearchDetails isSubscribe />
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  )}
                </Form>
              </div>
            </Modal.Body>

            <Modal.Footer className="border-0 pt-0">
              <SaveSearchBtnGroup
                saveLoading={saveLoading}
                updateLoading={updateLoading}
                handleCancel={handleCancel}
                handleSubmit={() => {
                  handleSubmit();
                  const errors = Object.keys(validate(values));
                  if (errors.includes('name')) {
                    setDetailAccordionKey('0');
                  } else if (errors.length) {
                    setSubscribeAccordionKey('0');
                  } else {
                    handleSaveSearch(values);
                  }
                }}
                handleUpdate={() => {
                  handleSubmit();
                  const errors = Object.keys(validate(values));
                  if (errors.includes('name')) {
                    setDetailAccordionKey('0');
                  } else if (errors.length) {
                    setSubscribeAccordionKey('0');
                  } else {
                    handleUpdate(values);
                  }
                }}
                isSubscribeActive={values.subscrptnIsactive}
                hideUpdateBtn={props.hideUpdateBtn}
              />
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default SaveSubscribeModal;
