import { useAssetTitleUrl } from '@amgen/feature-search';
import { AssetContext } from '@amgen/shared';
import React, { useContext } from 'react';
import { Modal } from 'react-bootstrap';

import './heading.scss';

/* eslint-disable-next-line */
export interface HeadingProps {}

export const Heading = (props: HeadingProps) => {
  const { docSolrAsset } = useContext(AssetContext);
  const { title: assetTitle } = useAssetTitleUrl(docSolrAsset.dataAsset!);

  return (
    <Modal.Header closeButton className="border-0">
      <Modal.Title as="h2">{assetTitle}</Modal.Title>
    </Modal.Header>
  );
};

export default Heading;
