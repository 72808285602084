import { SearchContext, useOutsideClick, useSearchQueryValue } from '@amgen/shared';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useRef, useState } from 'react';

import './feedback-modal.scss';

import {
  DetailedFeedback,
} from './../../../../../feature-result-views/src/lib/components/search-user-feedback/detailed-feedback';
import FeedbackAppQuery from './feedback-app';

export interface FeedbackModalProps {
  showFeedbackBtn?: boolean;
  setShowFeedbackBtn: (showFeedbackBtn: boolean) => void;
}

const FeedbackModal: React.FC<FeedbackModalProps> = ({ showFeedbackBtn, setShowFeedbackBtn }) => {
  const {
    selectedMood,
    setFlag,
    typeOfFeedback,
    setTypeOfFeedback,
    selectMood,
    isUserFeedbackCompleted,
    setOpenFeedbackFromSearchQuery,
    openFeedbackFromSearchQuery,
    setIsUserFeedbackCompleted,
    searchFeedbackData,
    selectedAppMood,
    selectAppMood,
  } = useContext(SearchContext);
  const searchTerm = useSearchQueryValue();
  const [isFeedbackSent, setIsFeedbackSent] = useState(false);
  const modalTimeout = useRef(0);
  // const [typeOfFeedback, setTypeOfFeedback] = useState('');
  const typeOfFeedbackOptions = [
    { key: 'searchResultsFeedback', value: 'Search Results' },
    { key: 'appFeedback', value: 'Application Experience' },
  ];

  const searchFeedbackDataCopy = sessionStorage.getItem('feedbackData') || 'feedbackData';
  const searchFeedbackDetails = {
    already_reported: JSON.parse(searchFeedbackDataCopy)?.already_reported,
    days_from_last_feedback: JSON.parse(searchFeedbackDataCopy)?.days_from_last_feedback,
  };

  useEffect(() => {
    if (typeOfFeedback === '' && searchTerm !== '') {
      if (
        searchFeedbackDetails?.already_reported === false ||
        searchFeedbackDetails?.days_from_last_feedback > 30 ||
        searchFeedbackData?.getQueryFeedback?.already_reported === false ||
        searchFeedbackData?.getQueryFeedback?.days_from_last_feedback > 30
      ) {
        setTypeOfFeedback('searchResultsFeedback');
      } else {
        setTypeOfFeedback('appFeedback');
      }
    }
  }, [
    searchTerm,
    searchFeedbackDetails?.already_reported,
    searchFeedbackDetails?.days_from_last_feedback,
    searchFeedbackData?.getQueryFeedback?.already_reported,
    searchFeedbackData?.getQueryFeedback?.days_from_last_feedback,
  ]);

  const containerRef = useRef<HTMLDivElement | null>(null);
  useOutsideClick(containerRef, () => {
    setShowFeedbackBtn(!showFeedbackBtn);
    setFlag(false);
    selectMood('none');
    selectAppMood('none');
    setTypeOfFeedback('');
    setOpenFeedbackFromSearchQuery(false);
  });

  useEffect(() => {
    if (typeOfFeedback !== '') {
      if (isFeedbackSent || isUserFeedbackCompleted) {
        if (modalTimeout.current) {
          window.clearTimeout(modalTimeout.current);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        modalTimeout.current = window.setTimeout(() => {
          setShowFeedbackBtn(true);
          setFlag(false);
          selectMood('none');
          selectAppMood('none');
          setTypeOfFeedback('');
          setIsUserFeedbackCompleted(isUserFeedbackCompleted || false);
          setOpenFeedbackFromSearchQuery(false);
        }, 5000);
      }
    }
    return () => {
      window.clearTimeout(modalTimeout.current);
    };
  }, [isUserFeedbackCompleted, isFeedbackSent]);

  useEffect(() => {
    if (typeOfFeedback !== '') {
      return () => {
        window.clearTimeout(modalTimeout.current);
      };
    }
  }, [typeOfFeedback]);

  const onChangeValue = event => {
    setTypeOfFeedback(event.target.value);
    setOpenFeedbackFromSearchQuery(false);
  };

  return (
    <div className="feedback-modal position-fixed text-center" ref={containerRef}>
      <div className="bg-white shadow-lg ">
        <div className="modal-header d-flex justify-content-between px-3">
          <div className="ml-2">Feedback</div>
          <div>
            <FontAwesomeIcon
              icon={faTimes}
              // className="w-100 h-100 p-1"
              onClick={() => {
                setShowFeedbackBtn(!showFeedbackBtn);
                setFlag(false);
                selectMood('none');
                setTypeOfFeedback('');
                selectAppMood('none');
                setIsUserFeedbackCompleted(isUserFeedbackCompleted || false);
                setOpenFeedbackFromSearchQuery(false);
              }}
            />
          </div>
        </div>
        <div className="px-3 text-left pt-2 ml-2 fs-14">What type of feedback would you like to provide?</div>
        <div className="px-3 pt-1 text-left ml-2">
          {typeOfFeedbackOptions.map(item => (
            <>
              <input
                type="radio"
                value={item.key}
                name="feedback"
                onChange={onChangeValue}
                checked={typeOfFeedback === item.key}
                id={item.key}
                className={
                  item.key === 'searchResultsFeedback' && searchTerm === '*:*' ? 'cursor-not-allowed' : 'cursor-pointer'
                }
                disabled={item.key === 'searchResultsFeedback' && searchTerm === '*:*'}
              />
              <label
                htmlFor={item.key}
                className={
                  item.key === 'searchResultsFeedback' && searchTerm === '*:*'
                    ? 'cursor-not-allowed feedback-option-text fs-14 pl-2 grey-out'
                    : 'cursor-pointer feedback-option-text fs-14 pl-2'
                }
              >
                {item.value}
              </label>
            </>
          ))}
        </div>
        {typeOfFeedback === 'appFeedback' ? (
          !isFeedbackSent ? (
            <FeedbackAppQuery
              showFeedbackBtn={showFeedbackBtn}
              setShowFeedbackBtn={setShowFeedbackBtn}
              setIsFeedbackSent={setIsFeedbackSent}
            />
          ) : (
            <>
              <img
                className="feedback-sent-img"
                src={!showFeedbackBtn ? 'assets/images/done-feed.gif' : ''}
                alt="feedback sent successfully"
                loading="lazy"
              />
              <div className="text-center feedback-img-text-thanks">
                {selectedAppMood === 'Satisfied' ? (
                  <div className="font-weight-bold">Thank you for your feedback !</div>
                ) : (
                  <>
                    <div className="font-weight-bold">Thank you for your feedback.</div>
                    <div> Our support team will review it for further action.</div>
                  </>
                )}
              </div>
            </>
          )
        ) : searchFeedbackDetails?.already_reported === false ||
          searchFeedbackDetails?.days_from_last_feedback > 30 ||
          searchFeedbackData?.getQueryFeedback?.already_reported === false ||
          searchFeedbackData?.getQueryFeedback?.days_from_last_feedback > 30 ? (
          <DetailedFeedback selectedMood={selectedMood} setFlag={flag => setFlag(flag)} />
        ) : openFeedbackFromSearchQuery ? (
          <>
            <img
              className="feedback-sent-img"
              src={'assets/images/done-feed.gif'}
              alt="feedback sent successfully"
              loading="lazy"
            />
            <div className="text-center feedback-img-text-thanks">
              {selectedMood === 'Satisfied' ? (
                <div className="font-weight-bold">Thank you for your feedback !</div>
              ) : (
                <>
                  <div className="font-weight-bold">Thank you for your feedback.</div>
                  <div> Our support team will review it for further action.</div>
                </>
              )}
            </div>
          </>
        ) : (
          <>
            <img
              className="feedback-sent-img"
              src={'assets/images/done-feed.gif'}
              alt="feedback sent successfully"
              loading="lazy"
            />
            <div className="text-center feedback-img-text-thanks">
              <div className="font-weight-bold">Your Feedback has already been recorded.</div>
              <div> Our support team will review it for further action.</div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default FeedbackModal;
