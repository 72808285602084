import { SearchContext } from '@amgen/shared';
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';

import './application-feedback.scss';

import FeedbackModal from '../feedback-modal/feedback-modal';

/* eslint-disable-next-line */
export interface ApplicationFeedbackProps {}

export const ApplicationFeedback = (props: ApplicationFeedbackProps) => {
  const { showFeedbackBtn, setShowFeedbackBtn } = useContext(SearchContext);
  // const [showFeedbackBtn, setShowFeedbackBtn] = useState<boolean>(true);
  // const [feedbackTextclass, setFeedbackTextclass] = useState<string>('feedback-text-display');
  // useEffect(() => {
  //   setTimeout(() => {
  //     setFeedbackTextclass('feedback-btn-text');
  //   }, 30000);
  // }, []);

  return (
    <div>
      {showFeedbackBtn ? (
        <div className="feedback-container">
          <div className="feedback-btn cursor-pointer" onClick={() => setShowFeedbackBtn(!showFeedbackBtn)}>
            <FontAwesomeIcon icon={faAngleDoubleLeft} className="text-white ml-1" />
            <div className={classNames('feedback-text-display', 'text-white')}>Feedback</div>
          </div>
        </div>
      ) : (
        <FeedbackModal showFeedbackBtn={showFeedbackBtn} setShowFeedbackBtn={setShowFeedbackBtn} />
      )}
    </div>
  );
};

export default ApplicationFeedback;
