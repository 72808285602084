import { AssetContext } from '@amgen/shared';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DOMPurify from 'dompurify';
import React, { useContext } from 'react';

import './view-email-response-button.scss';

import AssetActionsMenu from '../asset-actions-menu/asset-actions-menu';

/* eslint-disable-next-line */
export interface ViewEmailResponseButtonProps {}

export const ViewEmailResponseButton = (props: ViewEmailResponseButtonProps) => {
  const { docSolrAsset } = useContext(AssetContext);

  return (
    <AssetActionsMenu.Item
      actionType={'email-link'}
      href={DOMPurify.sanitize(docSolrAsset.dataAsset?.linktoemailresponse)}
      target="_blank"
      rel="noopener noreferrer"
    >
      {`View Email Response `}
      <FontAwesomeIcon icon={faExternalLinkAlt} className="text-primary" />
    </AssetActionsMenu.Item>
  );
};

export default ViewEmailResponseButton;
