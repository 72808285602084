import classnames from 'classnames';
import React, { useEffect } from 'react';

import './infinite-scroll-div.scss';

import LoaderErrorContainer from '../loader-error-container/loader-error-container';

export interface InfiniteScrollDivProps {
  className?: string;
  loading: boolean;
  hasNext: boolean;
  page: number;
  hasScrolledToEnd: (page: number) => void;
  isEmpty: boolean;
  emptyImgSrc?: string;
}

export const InfiniteScrollDiv: React.FC<InfiniteScrollDivProps> = props => {
  const handleScroll = ({ currentTarget }: React.UIEvent<HTMLDivElement>) => {
    if (
      currentTarget &&
      currentTarget.scrollTop + currentTarget.clientHeight >= currentTarget.scrollHeight &&
      !props.loading &&
      props.hasNext
    ) {
      props.hasScrolledToEnd(props.page + 1);
    }
  };

  return (
    <div className={classnames('overflow-auto w-100', props.className)} onScroll={handleScroll}>
      {props.children}
      <LoaderErrorContainer
        loading={props.loading}
        showEmpty={props.isEmpty}
        spinnerSize="sm"
        emptyImgSrc={props.emptyImgSrc}
      ></LoaderErrorContainer>
    </div>
  );
};

export default InfiniteScrollDiv;
